import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { HardwareAccessLog } from 'lane-shared/domains/hardwareManagement/types/HardwareAccessLog';
import { HardwareAccessLogsResponse } from 'lane-shared/domains/hardwareManagement/types/HardwareAccessLogsResponse';
import {
  HardwarePassError,
  getHardwarePassErrorLabel,
} from 'lane-shared/domains/hardwareManagement/types/HardwarePassError';
import { getHardwareAccessLogs } from 'lane-shared/graphql/hardware/getHardwareAccessLogs';
import { LONG_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import { convertTo62 } from 'uuid-encoding';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { SortDirectionEnum } from 'constants-activate';

import { ChipSelect } from 'components/ads';
import { Table, Column, PageSizeType, Sort, H3 } from 'design-system-web';
import { AdminPage } from 'components/layout';

import { ChannelAdminTeamMemberLink } from '../../components';
import { getAccessLogStatusItem } from '../../types/AccessLogStatus';
import { HardwareType, hardwareType } from '../devices/tabs/hardware/hardware';

import styles from './styles.scss';

export function HardwareAccessLogsPage({ channel }: { channel: Channel }) {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const [sort, setSort] = useState<Sort>({});
  const DEFAULT_SORT = 'accessed_at';

  const getStatusData = (row: HardwareAccessLog) =>
    getAccessLogStatusItem(row.accessSuccess ?? false);

  const columns: Column<HardwareAccessLog>[] = [
    {
      header: t('web.admin.hardwareManagement.accessLog.column.time'),
      key: 'accessedAt',
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, LONG_DATE_TIME)}</span>
      ),
    },
    {
      header: t('web.admin.hardwareManagement.accessLog.column.status'),
      key: 'accessSuccess',
      disableSorting: true,
      renderCell: (_: any, row: HardwareAccessLog) => (
        <ChipSelect.NonInteractive
          key={row.id}
          value={getStatusData(row).label}
          type={getStatusData(row).type}
        />
      ),
    },
    {
      header: t('web.admin.hardwareManagement.accessLog.column.statusDetails'),
      key: 'accessStatusDetail',
      disableSorting: true,
      renderCell: (cell: HardwarePassError | undefined) => (
        <span>{cell ? t(getHardwarePassErrorLabel(cell)) : '–'}</span>
      ),
    },
    {
      header: t('web.admin.hardwareManagement.accessLog.column.visitor'),
      key: 'accessedByName',
      disableSorting: true,
      renderCell: (cell: string | undefined) => (
        <span className={styles.visitorName}>{cell ?? '–'}</span>
      ),
    },
    {
      header: t('web.admin.hardwareManagement.accessLog.column.host'),
      key: 'accessHostId',
      disableSorting: true,
      renderCell: (cell: string | undefined) => {
        if (cell) {
          return (
            <ChannelAdminTeamMemberLink
              userId={convertTo62(cell)}
              channelSlug={channel.slug}
              className={styles.hostName}
            />
          );
        }

        // eslint-disable-next-line react/jsx-no-literals
        return <span>–</span>;
      },
    },
    {
      header: t('web.admin.hardwareManagement.accessLog.column.deviceName'),
      key: 'hardwareName',
      disableSorting: true,
      renderCell: (_: any, row: HardwareAccessLog) => {
        if (row.hardware) {
          return (
            <Link
              to={routes.channelAdminHardwareDetails
                .replace(':id', channel.slug)
                .replace(':hardwareId', row.hardware.id)}
              className={styles.deviceName}
            >
              {row.hardware.name}
            </Link>
          );
        }

        // eslint-disable-next-line react/jsx-no-literals
        return <span>–</span>;
      },
    },
    {
      header: t('web.admin.hardwareManagement.accessLog.column.deviceType'),
      key: 'hardwareType',
      disableSorting: true,
      renderCell: (_: any, row: HardwareAccessLog) => (
        <span>
          {row.hardware?.type
            ? t(hardwareType(row.hardware.type as HardwareType))
            : '–'}
        </span>
      ),
    },
  ];

  const { data, loading, refetch } = useQuery<HardwareAccessLogsResponse>(
    getHardwareAccessLogs,
    {
      variables: {
        channelId: channel._id,
        search: {
          sortBy: { key: DEFAULT_SORT, dir: SortDirectionEnum.Desc },
        },
        pagination: {
          start: page * pageSize,
          perPage: pageSize,
        },
      },
      fetchPolicy: 'network-only',
    }
  );
  const accessLogsData = data?.hardwareAccessLogs?.items || [];
  const totalRows = data?.hardwareAccessLogs?.pageInfo?.total || 0;
  const start = data?.hardwareAccessLogs?.pageInfo?.start || 0;
  const perPage = data?.hardwareAccessLogs?.pageInfo?.perPage || 0;

  useEffect(() => {
    refetch({
      channelId: channel._id,
      search: {
        sortBy: {
          key: DEFAULT_SORT,
          dir: 'id' in sort ? sort.direction : SortDirectionEnum.Desc,
        },
      },
      pagination: {
        start: page * pageSize,
        perPage: pageSize,
      },
    });
  }, [page, pageSize, sort, channel._id, refetch]);

  const viewRecordsString = () =>
    t(
      totalRows === 1
        ? 'web.admin.hardwareManagement.accessLog.viewingRecords'
        : 'web.admin.hardwareManagement.accessLog.viewingRecords.plural',
      {
        start: start + 1,
        end: Math.min(start + perPage, totalRows),
        total: totalRows,
      }
    );

  return (
    <AdminPage>
      <div className={styles.titleContainer}>
        <H3 as="h1">{t('web.admin.hardwareManagement.accessLog.title')}</H3>
      </div>

      {totalRows > 0 && (
        <div className={styles.viewRecordsContainer}>
          <span data-test="viewRecords">{viewRecordsString()}</span>
        </div>
      )}

      <Table
        columns={columns}
        pagination="server"
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        onSortChange={setSort}
        onPageSizeChange={setPageSize}
        data={accessLogsData}
        isLoading={loading}
      />
    </AdminPage>
  );
}
