import { useMemo } from 'react';

import calculateChannelDistance from '../helpers/calculateChannelDistance';
import { CIRCUMFERENCE_OF_EARTH } from '../helpers/constants';
import fuzzyMatch from '../helpers/fuzzyMatch';
import { LocationType } from '../types/LocationType';
import { Channel } from '../types/ChannelType';

const ALL = 'all';

export default function useChannelSelectorSections({
  channels,
  location,
  showAllLocations,
  search,
}: {
  channels: Channel[];
  location?: LocationType;
  showAllLocations?: boolean;
  search?: string;
}) {
  // compute their locations
  // organize the channels into cities.
  // sort by closest section
  const sections = useMemo(() => {
    const cities = {};

    channels.forEach(channel => {
      channel._distance = location
        ? calculateChannelDistance({
            longitude: location.longitude,
            latitude: location.latitude,
            channel,
          })
        : CIRCUMFERENCE_OF_EARTH;

      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      if (!cities[channel.address?.city]) {
        const name = channel.address?.country
          ? `${channel.address?.city}, ${channel.address?.country}`
          : channel.address?.city;

        // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
        cities[channel.address?.city] = {
          city: channel.address?.city,
          data: [channel],
          distance: channel._distance,
          geo: channel.address?.geo,
          name,
        };
      } else {
        // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
        const city = cities[channel.address?.city];

        city.data.push(channel);
        city.distance = Math.min(city.distance, channel._distance);
        city.geo = channel.address?.geo;
      }
    });

    const sections = Object.values(cities);

    sections.sort((a, b) => (a as any).distance - (b as any).distance);

    sections.forEach(section =>
      (section as any).data.sort((a: any, b: any) => a._distance - b._distance)
    );

    return showAllLocations
      ? [
          {
            allLocations: true,
            data: [
              {
                key: 'allLocations',
                allLocations: true,
              },
            ],
            name: ALL,
          },
          ...sections,
        ]
      : sections;
  }, [channels, location]);

  const searchSections = useMemo(
    () =>
      sections
        .map(section => {
          // @ts-expect-error ts-migrate(7030) not all code paths return a value
          const data = (section as any).data.filter((channel: any) => {
            if (!search) {
              return true;
            }

            if (channel.allLocations) {
              return false;
            }

            const matched =
              fuzzyMatch(channel.profile.name, search) ||
              fuzzyMatch(channel.address?.street1, search) ||
              fuzzyMatch(channel.address?.city, search);

            if (matched) {
              return true;
            }
          });

          return {
            // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ...section,
            data,
          };
        })
        .filter(section => section.data.length > 0),

    [sections, search]
  );

  return {
    sections,
    searchSections,
  };
}
