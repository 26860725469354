import { useEffect, useState, useContext } from 'react';

import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { Channel } from 'lane-shared/types/ChannelType';
import { getClient } from 'lane-shared/apollo';
import { getBuildingChannelIntegration } from 'lane-shared/graphql/accessControl';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';

import { UserInfo } from '../types/AccessControl';
import { ActiveChannelTypeEnum } from 'constants-channel';

export const useConnectedBuildingChannel = (
  channelId: string,
  currentAccessUser: UserInfo
) => {
  const currentUserContext = useContext(UserDataContext);
  const { channels } = useContext(ChannelsContext);

  const [loading, setLoading] = useState(false);
  const [connectedChannel, setConnectedChannel] = useState<
    Channel | null | undefined
  >(null);

  const currentChannel = channels.find(channel => channel._id === channelId);

  useEffect(() => {
    (async function () {
      if (
        currentChannel?.type === ActiveChannelTypeEnum.Company &&
        currentAccessUser?.integrationChannelId === channelId
      ) {
        try {
          setLoading(true);
          const { data } = await getClient().query({
            query: getBuildingChannelIntegration,
            variables: {
              channelId,
            },
          });
          const { integration } = data.getBuildingChannelIntegration;
          const buildingChannel = hasPermission(
            currentUserContext.user?.roles,
            [
              PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
            ],
            integration.channelId,
            true
          )
            ? channels.find(channel => channel._id === integration.channelId)
            : null;

          setConnectedChannel(buildingChannel);
        } catch (error) {
          console.error('Error fetching connected building channel:', error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [channelId]);

  return { connectedChannel, loading };
};
