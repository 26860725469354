import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuantityWaitlistEnabled } from 'lane-shared/hooks/useQuantityWaitlistEnabled';
import { Toggle } from 'components/form';
import { FeatureNameEnum , ContentTypeEnum } from 'constants-content';
import { FeatureRendererPropsType } from './FeatureRendererPropsType';
import { QuantityFeatureProperties } from 'lane-shared/types/features/QuantityFeatureProperties';
import { Alert, AlertType } from 'components/lds';

type QuantityWaitlistProps = Pick<
  FeatureRendererPropsType<QuantityFeatureProperties>,
  'onFeatureUpdated' | 'content' | 'contentFeature' | 'feature'
>;

const TRANSLATION_KEYS = Object.freeze({
  cancelableFeatureDisabledWarningMessage:
    'web.admin.content.features.waitlist.cancelationFeatureWarning',
  allowAnonymousEntriesWarningMessage:
    'web.admin.content.features.waitlist.anonymousEntriesFeatureWarning',
  featureExclusivityPopupDescription:
    'web.admin.content.features.featureExclusivity.popup.description',
});

export function QuantityWaitlist({
  content,
  contentFeature,
  feature,
  onFeatureUpdated,
}: QuantityWaitlistProps) {
  const isWaitlistEnabled = useQuantityWaitlistEnabled();
  const { t } = useTranslation();

  // hide waitlist on Pages
  // Pages have content type - Static
  const isStaticContent =
    content && 'type' in content && content.type === ContentTypeEnum.Static;

  if (!isWaitlistEnabled || isStaticContent) {
    return null;
  }

  const isTimeResetActive = isFeatureActive(content, FeatureNameEnum.Reset);
  const isCancellingEnabled = isFeatureActive(
    content,
    FeatureNameEnum.Cancelable
  );

  const numberOfEntriesFeature =
    hasContentFeatures(content) &&
    content.features.find((f: any) => f?.type === FeatureNameEnum.Entries);

  const isAllowAnonymousEnabled =
    numberOfEntriesFeature?.feature?.allowAnonymous;

  const isUseWaitlistEnabled = !!contentFeature.feature.useWaitlist;

  return (
    <>
      {isUseWaitlistEnabled && !isCancellingEnabled ? (
        <Alert type={AlertType.warning}>
          {t(TRANSLATION_KEYS.cancelableFeatureDisabledWarningMessage)}
        </Alert>
      ) : null}

      {isUseWaitlistEnabled && isAllowAnonymousEnabled ? (
        <Alert type={AlertType.warning}>
          {t(TRANSLATION_KEYS.allowAnonymousEntriesWarningMessage)}
        </Alert>
      ) : null}

      <Toggle
        testId="quantityWaitlistToggle"
        value={!!contentFeature.feature.useWaitlist}
        onChange={value => {
          onFeatureUpdated({ useWaitlist: value });
        }}
        disabled={isTimeResetActive}
        text={t(feature.properties.useWaitlist.friendlyName)}
        TooltipComponent={t(
          TRANSLATION_KEYS.featureExclusivityPopupDescription
        )}
      />
    </>
  );
}

export function isFeatureActive(
  content: any,
  featureName: FeatureNameEnum
): boolean {
  return (
    hasContentFeatures(content) &&
    content.features.some((f: any) => f?.type === featureName)
  );
}

function hasContentFeatures(content: any): boolean {
  return content && 'features' in content && Array.isArray(content.features);
}
