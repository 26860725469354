import {
  CONTENT_CARD_STANDARD,
  CONTENT_CARD_HERO,
  CONTENT_CARD_HORIZONTAL,
  PIN_LAYOUTS,
} from 'constants-content';
import { Primitive } from '../../../types/blocks/Primitive';

const ContentBlock: Primitive = {
  name: 'ContentBlock',
  friendlyName: 'Content',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Other'],
  properties: {
    content: {
      type: 'Content',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    cardType: {
      type: 'String',
      validators: [
        {
          name: 'In',
          value: [
            CONTENT_CARD_STANDARD,
            CONTENT_CARD_HERO,
            CONTENT_CARD_HORIZONTAL,
          ],
        },
      ],
    },
    pinType: {
      type: 'String',
      validators: [
        {
          name: 'In',
          value: PIN_LAYOUTS,
        },
      ],
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default ContentBlock;
