import { AppState, Auth0Provider } from '@auth0/auth0-react';
import qs from 'qs';
import { history } from 'helpers';
import { config as sharedConfig } from 'lane-shared/config';
import React, { ReactNode } from 'react';
import { Auth0ContextProvider } from 'lane-shared/contexts/Auth0Context';
import { ENVIRONMENTS } from 'constants-activate';
import { useAuth0AppStateContext } from 'contexts/Auth0AppStateContext';

export const Auth0Wrapper = ({ children }: { children: ReactNode }) => {
  const { domain, clientId, redirectionServiceURL, authorizationParams } =
    sharedConfig.auth0;
  const { setInviteId } = useAuth0AppStateContext();

  if (!redirectionServiceURL) {
    throw new Error('config.auth0.redirectionServiceURL is required');
  }

  const handleRedirectCallback = (appState: AppState | undefined) => {
    setInviteId(appState?.inviteId);
    const contentPath = appState?.contentPath;
    const channelId = appState?.channelId;

    let queryStringVariables: Record<string, string> = {};

    if (contentPath) {
      queryStringVariables = {
        contentPath,
      };
    }

    if (channelId) {
      queryStringVariables = {
        ...queryStringVariables,
        channelId,
      };
    }

    history.push({
      pathname: history.location.pathname,
      search: qs.stringify({ ...queryStringVariables }),
    });
  };

  const isAuth0SimulatorEnabled =
    process.env.APP_ENV === ENVIRONMENTS.LOCAL &&
    process.env.AUTH0_SIMULATOR_ENABLED === 'true';

  const simulatorDomain = 'localhost:4400';
  const auth0Domain = isAuth0SimulatorEnabled ? simulatorDomain : domain;

  const domainURLSearchParams = new URLSearchParams({
    domain,
  });

  const redirectURLSearchParams = new URLSearchParams({
    destination_url: window.location.origin,
  });

  const redirectURL = isAuth0SimulatorEnabled
    ? window.location.origin
    : `https://${redirectionServiceURL}?${redirectURLSearchParams}`;

  const authorizationAttributes = {
    scope: 'openid profile email offline_access',
    audience: authorizationParams.audience,
    redirect_uri: redirectURL,
  };

  return (
    <Auth0ContextProvider>
      <Auth0Provider
        domain={
          isAuth0SimulatorEnabled
            ? auth0Domain
            : `${redirectionServiceURL}/?${domainURLSearchParams}`
        }
        clientId={clientId}
        authorizationParams={authorizationAttributes}
        issuer={`https://${auth0Domain}/`}
        onRedirectCallback={handleRedirectCallback}
        cacheLocation="localstorage"
      >
        {children}
      </Auth0Provider>
    </Auth0ContextProvider>
  );
};
