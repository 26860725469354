import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getChannel } from '../graphql/query';

export function useChannelFromSlug({
  channelSlug,
}: {
  channelSlug: string | null;
}) {
  const {
    data,
    loading,
    error,
    refetch: refetchChannelFromSlug,
  } = useQuery(getChannel, {
    client: getClient(),
    skip: !channelSlug,
    notifyOnNetworkStatusChange: true,
    variables: { slug: channelSlug },
  });

  const hasChannel = data && 'channel' in data;

  return {
    refetchChannelFromSlug,
    channel: hasChannel ? data.channel : data,
    loading,
    error,
  } as const;
}
