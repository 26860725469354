import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { ListProductsResponse } from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'uuid-encoding';
import { ProductType } from 'graphql-query-contracts';

import { listProductsQuery } from 'graphql-queries';
import { Channel } from '../../../types/ChannelType';

interface ListProductsParams {
  page: number;
  pageSize: number;
  channel?: Channel;
  productTypes?: ProductType[];
  productCategoryId?: String;
  productIds?: String[];
}

export function useProductListQuery({
  page,
  pageSize,
  channel,
  productTypes,
  productCategoryId = '',
}: ListProductsParams) {
  const [getProductsList, productsListResponse] = useLazyQuery<{
    listProducts: ListProductsResponse;
  }>(listProductsQuery);

  useEffect(() => {
    if (channel?._id) {
      const parentId = channel.parent?._id;
      const parentChannelIdArray =
        (parentId && [convertToUUID(parentId)]) || [];
      const groupIds = [convertToUUID(channel._id), ...parentChannelIdArray];

      getProductsList({
        variables: {
          filter: {
            groupIds,
            productTypes,
            productCategoryId,
          },
          pagination: {
            start: page * pageSize,
            perPage: pageSize,
          },
        },
      });
    }
  }, [
    channel?._id,
    channel?.parent?._id,
    page,
    pageSize,
    getProductsList,
    productCategoryId,
    JSON.stringify(productTypes),
  ]);

  return productsListResponse;
}

export function useListProductsByProductIdsQuery({
  page,
  pageSize,
  channel,
  productIds = [],
}: ListProductsParams) {
  const [getProductsList, productsListResponse] = useLazyQuery<{
    listProducts: ListProductsResponse;
  }>(listProductsQuery);

  useEffect(() => {
    if (channel?._id && productIds.length > 0) {
      const parentId = channel.parent?._id;
      const parentChannelIdArray =
        (parentId && [convertToUUID(parentId)]) || [];
      const groupIds = [convertToUUID(channel._id), ...parentChannelIdArray];

      getProductsList({
        variables: {
          filter: {
            groupIds,
            productIds,
          },
          pagination: {
            start: page * pageSize,
            perPage: pageSize,
          },
        },
      });
    }
  }, [
    channel?._id,
    channel?.parent?._id,
    page,
    pageSize,
    getProductsList,
    productIds,
  ]);

  return productsListResponse;
}
