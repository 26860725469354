/* istanbul ignore file */
import { createContext } from 'react';

import { env } from '../config';
import { ENVIRONMENTS } from 'constants-activate';
import { InteractionValidationSchema } from '../renderers/v5/validateInteraction';

export type UCIContext = {
  validateAdditional: (additionalSchema: InteractionValidationSchema) => void;
};

const defaultUCIContext: UCIContext = {
  validateAdditional() {
    if ([ENVIRONMENTS.LOCAL].includes(env)) {
      console.warn(
        'UserContentInteractionContext consumed without <UserContentInteractionContext.Provider>'
      );
    }
  },
};

const UserContentInteractionContext = createContext(defaultUCIContext);

export default UserContentInteractionContext;
