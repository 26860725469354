import React from 'react';

import { colors } from 'constants-colors';
import { getPaymentDetailsWithQuote } from 'lane-shared/helpers';
import i18nStub from '../../helpers/i18nStub';
import { MjmlWrapper } from '../MjmlComponentsV2';
import EmailLabelWithValue from './EmailLabelWithValue';

export default function EmailPaymentFeatureQuote({
  i18n = i18nStub,
  userLocale,
  quote,
  cardBrand,
  cardLast4,
}: any) {
  if (!quote) {
    return null;
  }

  const paymentDetails = getPaymentDetailsWithQuote(quote, userLocale);
  const withProducts = quote.products && quote.products.length > 0;
  const quantity = quote.quantity;

  const percentageFormat = new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format;

  return (
    <MjmlWrapper
      backgroundColor={colors.gray4}
      paddingTop={16}
      paddingBottom={16}
      borderRadius="20px"
      border="1px solid #eee"
    >
      {paymentDetails.items && paymentDetails.items.length > 0 && (
        <EmailLabelWithValue
          key={paymentDetails.items[0].key}
          label={
            <div>
              <h3>{paymentDetails.items[0].name}</h3>
              <div style={{ fontSize: '18px', lineHeight: '150%' }}>
                {i18n.t('abp.payment.orderSummary.quantity', { quantity })}
              </div>
            </div>
          }
          backgroundColor={colors.gray4}
        >
          <h3>
            {withProducts
              ? paymentDetails.cashTotal
              : paymentDetails.items[0].amount}
          </h3>
        </EmailLabelWithValue>
      )}

      {!withProducts &&
        paymentDetails.taxes.length > 0 &&
        paymentDetails.taxes.map(tax => (
          <EmailLabelWithValue
            key={tax.key}
            label={
              <h3>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                {tax.name} {percentageFormat(tax.rate)}%
              </h3>
            }
            backgroundColor={colors.gray4}
          >
            <h3>{tax.amount}</h3>
          </EmailLabelWithValue>
        ))}

      <EmailLabelWithValue
        label={
          <h3>
            <b>{i18n.t('Total')}</b>
          </h3>
        }
        backgroundColor={colors.gray4}
      >
        <h3>
          <b>
            {withProducts ? paymentDetails.cashTotal : paymentDetails.total}
          </b>
        </h3>
      </EmailLabelWithValue>

      {/* These values won't exist on Essensys content that use credits */}
      {cardBrand && cardLast4 && (
        <EmailLabelWithValue
          label={<h3>{i18n.t('Card')}</h3>}
          backgroundColor={colors.gray4}
        >
          <h3>{`${cardBrand} **** **** **** ${cardLast4}`}</h3>
        </EmailLabelWithValue>
      )}
    </MjmlWrapper>
  );
}
