import { useState, useEffect, useRef, useContext } from 'react';

import { cloneDeep } from '@apollo/client/utilities';

import { setHeader } from 'lane-shared/apollo/index';
import { InitializeChannelsResult } from 'lane-shared/graphql/query/initializeChannels';
import { HEADER_PRIMARY_CHANNEL_ID } from 'constants-activate';

import { AnalyticsContext } from '../contexts';
import {
  emptyResults,
  defaultContext,
  ChannelsContextType,
} from '../contexts/ChannelsContext';
import { initializeChannels } from '../graphql/query';
import {
  switchChannelQuery,
  SwitchChannelResult,
} from './useUserChannelsDataQuery';
import { isNotLoggedInError, getBestDefaultChannel } from '../helpers';
import Storage, { USER_CHANNELS_DATA_CONTEXT } from '../helpers/Storage';
import emitter, {
  EVENT_SUBSCRIPTIONS_CHANGED,
  EVENT_AUTH_TOKEN_INVALID,
} from '../helpers/emitter';
import { processFocusOnChannels } from '../helpers/user';
import { UserType } from '../types/User';
import useFlag from './useFlag';
import { useMultiLanguage } from './useMultiLanguage';
import usePollingQuery from './usePollingQuery';
import { useAuth0Enabled } from './auth0/useAuth0Enabled';
import { Channel } from '../types/ChannelType';

let instances = 0;

type Data = {
  primaryId: string | null;
  secondaryId: string | null;
  forAdmin: boolean;
};

type Props = {
  user: UserType | null;
  channelId?: string | null;
  onFocusChannelChanged?: (
    primaryChannel: Channel | undefined,
    secondaryChannel: Channel | undefined
  ) => void;
};

export default function useUserChannelsData({
  user,
  channelId,
  onFocusChannelChanged = () => undefined,
}: Props) {
  const isAuth0Enabled = useAuth0Enabled();

  // we don't want all the useEffects firing on initial render
  const statusRef = useRef<{
    // has this component mounted yet
    isSwitching: boolean;
    isMounted: boolean;
    forAdmin: boolean;
    // what channel id are we trying to switch to?
    channelId: string | null;
    // what channel id are we actively switched to.
    primaryId: string | null;
    // what secondary channel id are we actively switched to.
    secondaryId: string | null;
    storageInitialized: boolean;
    userInitialized: boolean;
  }>({
    isSwitching: false,
    forAdmin: false,
    isMounted: false,
    channelId: null,
    primaryId: null,
    secondaryId: null,
    storageInitialized: false,
    userInitialized: false,
  });
  const pollingRef = useRef<{ isStarted: boolean }>({ isStarted: false });

  // setup the default state for first load
  const [userChannels, setUserChannels] = useState<ChannelsContextType>({
    ...defaultContext,
  });

  const { translate } = useMultiLanguage({ user });

  const analytics = useContext(AnalyticsContext);

  // a simple helper function to update the state object.
  function updateState(props: Partial<ChannelsContextType>) {
    setUserChannels(state => ({
      ...state,
      ...props,
    }));
  }

  const channelsQuery = usePollingQuery<InitializeChannelsResult>({
    query: initializeChannels,
    onAfterFetch: data => {
      const channels = data?.me?.channels || emptyResults;

      // we got the channels. set them and this will kick off focus on channels
      updateState({
        channelsInitialized: true,
        channels,
      });
    },
    onError: error => {
      updateState({
        channelsInitialized: false,
      });

      if (isNotLoggedInError(error)) {
        emitter.emit(EVENT_AUTH_TOKEN_INVALID);
      }
    },
  });

  const showSecondaryPages = useFlag(
    'consumer-experience.TM-8806-hide-channel-tabs-from-building',
    true
  );

  // focus on channels will get all the data for the web and mobile app
  // based on what channel the user is currently switched to / focused on
  const focusQuery = usePollingQuery<SwitchChannelResult>({
    shouldThrow: true,
    query: switchChannelQuery,
    fetchPolicy: 'no-cache',
    onAfterFetch: data => {
      const {
        focusOnChannels = emptyResults,
        primaryId,
        secondaryId,
      } = cloneDeep(data.me.switchChannel);

      const forAdmin = statusRef.current.forAdmin;
      const primaryChannel = focusOnChannels.find(c => c._id === primaryId);
      const secondaryChannel = focusOnChannels.find(c => c._id === secondaryId);
      const { contents, notices, interactions, pages } = processFocusOnChannels(
        focusOnChannels,
        primaryId,
        secondaryId,
        showSecondaryPages
      );

      statusRef.current.channelId = primaryId;
      statusRef.current.primaryId = primaryId;
      statusRef.current.secondaryId = secondaryId;
      statusRef.current.isSwitching = false;

      Storage.setItem(USER_CHANNELS_DATA_CONTEXT, {
        primaryId,
        secondaryId,
        forAdmin,
      }).catch(() => null);

      onFocusChannelChanged(primaryChannel, secondaryChannel);

      updateState({
        primaryChannel,
        secondaryChannel,
        primaryId,
        secondaryId,
        contents,
        notices,
        interactions,
        focusOnChannels,
        pages,
        forAdmin,
        channelsInitialized: true,
        primaryChannelInitialized: true,
        switchingChannels: false,
        isReady: true,
      });
    },
    onError: error => {
      statusRef.current.isSwitching = false;

      // if there is an error because the user isn't logged in, fire a
      // log out event.
      if (isNotLoggedInError(error)) {
        emitter.emit(EVENT_AUTH_TOKEN_INVALID);
      }
    },
  });

  async function refetchFocus() {
    await (channelId ? focusQuery.fetch({ channelId }) : focusQuery.fetch());
  }

  async function switchChannel(channelId: string, forAdmin: boolean) {
    if (statusRef.current.isSwitching) {
      return;
    }

    statusRef.current.isSwitching = true;
    statusRef.current.channelId = channelId;
    statusRef.current.forAdmin = forAdmin;

    updateState({
      primaryChannelInitialized: false,
      forAdmin,
    });

    focusQuery.stopPolling();

    await focusQuery.fetch({ channelId: statusRef.current.channelId });
  }

  // when a subscription changed event is fired, reload the list of
  // channels for this user. This will trigger the channelsResults useEffect
  function onSubscriptionsChanged() {
    updateState({
      channelsInitialized: false,
    });

    channelsQuery.fetch();
  }

  // when the users channels are updated, check to see if we need to set
  // a new primary or secondary channel.
  useEffect(() => {
    if (!userChannels.channelsInitialized) {
      return;
    }

    const primaryId = statusRef.current.primaryId;

    // let's check that we still belong to the channel we're focused on,
    // and switch if not!
    if (
      (!primaryId ||
        !userChannels.channels.some(({ _id }) => _id === primaryId)) &&
      !channelId
    ) {
      const primaryChannel = getBestDefaultChannel(userChannels.channels);

      if (!primaryChannel) {
        // we don't belong to ANY channels
        updateState({
          primaryChannelInitialized: true,
          primaryChannel: undefined,
          primaryId: null,
          isReady: true,
        });
      } else {
        switchChannel(primaryChannel._id, statusRef.current.forAdmin);
      }
    } else if (userChannels.primaryChannel?._id !== channelId) {
      // if we aren't focused on the channel selected and channelId was passed, then switch
      // to channelId
      if (channelId) {
        switchChannel(channelId, statusRef.current.forAdmin);
      }
    } else if (userChannels.primaryChannel?._id !== primaryId) {
      // if we aren't focused on the channel selected and channelId was not passed, then switch
      // try to find their chosen primary.
      const primaryChannel = userChannels.channels.find(
        channel => channel._id === primaryId
      );

      if (primaryChannel) {
        switchChannel(primaryChannel._id, statusRef.current.forAdmin);
      }
    }
  }, [
    userChannels.channels,
    userChannels.channelsInitialized,
    userChannels.primaryChannel?._id,
    channelId,
  ]);

  function onLogout() {
    channelsQuery.stopPolling();
    focusQuery.stopPolling();

    // clear the channels in the state.
    updateState({
      ...defaultContext,
      primaryChannelInitialized: true,
    });

    pollingRef.current.isStarted = false;

    statusRef.current.channelId = null;
    statusRef.current.primaryId = null;
    statusRef.current.secondaryId = null;
    statusRef.current.userInitialized = false;
  }

  function startPolling() {
    if (isAuth0Enabled && !pollingRef.current.isStarted) {
      channelsQuery.startPolling();
      pollingRef.current.isStarted = true;
    }
  }

  useEffect(() => {
    // if there is no user object, they have logged out. Clear the state
    // back to default
    if (!user) {
      if (statusRef.current.userInitialized) {
        onLogout();
      }

      return;
    }

    // If Auth0 onboarding is enabled, polling does not need to start when the user is set.
    // When users are redirected back to the app after signing up, they are logged in as Auth0 now manages user authentication.
    // However, authentication does not imply onboarding.
    // We must wait for the user to be onboarded before starting polling for channels.
    // This code block will be removed when the Auth0 onboarding flow will be released.
    if (!isAuth0Enabled) {
      // otherwise this is a new login, trigger the fetching
      channelsQuery.startPolling();
    }

    // user is ready
    statusRef.current.userInitialized = true;

    // if we have a primary id ready, switch to it.
    if (statusRef.current.storageInitialized && statusRef.current.primaryId) {
      switchChannel(statusRef.current.primaryId, statusRef.current.forAdmin);
    }

    // setup any listeners for events that maybe fired.
    emitter.addListener(EVENT_SUBSCRIPTIONS_CHANGED, onSubscriptionsChanged);

    return () => {
      emitter.removeListener(
        EVENT_SUBSCRIPTIONS_CHANGED,
        onSubscriptionsChanged
      );
    };
  }, [user?._id]);

  useEffect(() => {
    // get the stored channel ids on first load
    // this should always finishing executing before channelsQuery.
    async function setupChannelIds() {
      try {
        const data = (await Storage.getItem(
          USER_CHANNELS_DATA_CONTEXT
        )) as Data;

        if (data) {
          updateState({
            ...data,
            storageInitialized: true,
          });

          statusRef.current.primaryId = data.primaryId;
          statusRef.current.secondaryId = data.secondaryId;
          statusRef.current.forAdmin = data.forAdmin;

          // if somehow this finished after user, switch to this channel
          if (statusRef.current.userInitialized && data.primaryId) {
            switchChannel(data.primaryId, data.forAdmin);
          }
        }
        // FIXME: Log error for datadog, missing stack trace
        // Handle unknown errors
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        // errors are ok, means they may not be set.

        statusRef.current.storageInitialized = true;
        updateState({
          storageInitialized: true,
        });
      }
    }

    // listen for login and logout events throughout the app
    emitter.addListener(EVENT_AUTH_TOKEN_INVALID, onLogout);

    setupChannelIds();

    statusRef.current.isMounted = true;

    instances++;

    if (instances > 1) {
      // this is a warning to developers if they use this hook
      console.warn(
        'You should only have one instances of of useUserChannelsData running'
      );
    }

    return () => {
      instances--;
      emitter.removeListener(EVENT_AUTH_TOKEN_INVALID, onLogout);
    };
  }, []);

  useEffect(() => {
    setHeader(
      HEADER_PRIMARY_CHANNEL_ID,
      userChannels.primaryChannel?._id || ''
    );

    if (userChannels.primaryChannel?._id && userChannels.primaryChannel?.name) {
      analytics.setChannel(
        userChannels.primaryChannel._id,
        userChannels.primaryChannel.name
      );
    }
  }, [userChannels.primaryChannel]);
  const translatedInteractions = userChannels.interactions.map(
    (interaction: any) => {
      const interactionChannel = userChannels.channels.find(
        channel => channel._id === interaction.contentData.channel._id
      );

      return {
        ...interaction,
        contentData: translate({
          model: {
            ...interaction.contentData,
            channel: interactionChannel,
          },
          columns: ['name', 'description', 'subtitle'],
        }),
      };
    }
  );

  return {
    ...userChannels,
    interactions: translatedInteractions,
    notices: translate({
      model: userChannels?.notices,
      columns: ['name', 'description', 'subtitle'],
    }),
    contents: translate({
      model: userChannels.contents,
      columns: ['name', 'description', 'subtitle'],
    }),
    pages: translate({ model: userChannels.pages, columns: ['label'] }),
    switchChannel,
    refetch: channelsQuery.fetch,
    refetchFocus,
    startPolling,
  };
}
