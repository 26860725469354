import React from 'react';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { getChannelIntegrations } from 'lane-shared/graphql/query';
import { IntegrationTypeEnum } from 'constants-integrations';

import targetingIntegrations from './targetingIntegrations';

export default function DraftContentTargetingIntegrations({
  hasChanged,
  channel,
  content,
  onContentUpdated,
}: any) {
  const { data } = useQuery(getChannelIntegrations, {
    client: getClient(),
    skip: !channel?._id,
    variables: {
      id: channel?._id,
    },
  });

  // only get integrations of type content targeting.
  const integrations = (data?.channel?.integrations || []).filter(
    (channelIntegration: any) =>
      channelIntegration.integration.type ===
      IntegrationTypeEnum.ContentTargeting
  );

  return integrations.map((channelIntegration: any) => {
    const TargetingIntegration =
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      targetingIntegrations[channelIntegration.integration.name];

    if (!TargetingIntegration) {
      return null;
    }

    return (
      <TargetingIntegration
        key={channelIntegration._id}
        hasChanged={hasChanged}
        channelIntegration={channelIntegration}
        channel={channel}
        content={content}
        onContentUpdated={onContentUpdated}
      />
    );
  });
}
