import { Channel } from '../../types/ChannelType';

import { AddressType } from '../../types/AddressType';
import getDisplayName from '../getDisplayName';
import { constructChannel } from './constructChannel';
import { v4 } from 'uuid';

export default function cloneChannel(
  cloneFrom: Channel,
  location: AddressType,
  parent: Channel,
  userId: string
): Channel {
  // use the construct channel to make a fully formed channel object
  const newChannel = constructChannel({
    userId,
    parent,
  });

  // take the address of the location channel
  newChannel.address = { ...location, _id: v4() };

  // copy the settings, profile from the copyFrom channel
  newChannel.profile = {
    _id: v4(),
    name: `${getDisplayName(cloneFrom)} - Copy`,
    description: cloneFrom.profile?.description ?? '',
    email: cloneFrom.profile?.email ?? '',
    phone: cloneFrom.profile?.phone ?? '',
    backgroundImage: cloneFrom.profile?.backgroundImage ?? '',
    image: cloneFrom.profile?.image ?? '',
    logo: cloneFrom.profile?.logo ?? '',
    wordmark: cloneFrom.profile?.wordmark ?? '',
    color: cloneFrom.profile?.color ?? '',
    backgroundColor: cloneFrom.profile?.backgroundColor ?? '',
    isPublic: cloneFrom.profile?.isPublic ?? false,
    theme: cloneFrom.profile?.theme ?? ({} as any),
    noReplyEmail: cloneFrom.profile?.noReplyEmail ?? '',
    content: cloneFrom.profile?.content,
  };

  /**
   * NOTE: MF - Not really sure why we are maintaining the content and theme ids in the profile.
   *
   * Why are we referencing the previous content and theme ids?
   * A - Themes are often shared across channels.
   *     But then what is the access pattern for those themes? How do we manage them?
   * B - Profile Content would be modified for both channels if we maintain the reference, and one channel is changed?
   */

  // if there is content in the profile, flatten it down.
  if (newChannel.profile?.content) {
    newChannel.profile.content = {
      ...newChannel.profile.content,
      _id: newChannel.profile.content._id,
    };
  }

  if (newChannel.profile?.theme) {
    newChannel.profile.theme = {
      ...newChannel.profile.theme,
      _id: newChannel.profile.theme._id,
    };
  }

  return {
    ...newChannel,
    name: cloneFrom.name,
    type: cloneFrom.type,
    experienceType: cloneFrom.experienceType,
    website: cloneFrom.website,
    description: cloneFrom.description,
    parent: parent ? { _id: parent._id } : null,
    slug: `${cloneFrom.slug}-copy`,
    isPublic: cloneFrom.isPublic,
    isDiscoverable: cloneFrom.isDiscoverable,
    isLive: cloneFrom.isLive,
    isSub: cloneFrom.isSub,
    hasCustomOffboarding: cloneFrom.hasCustomOffboarding,
    inviteOnly: cloneFrom.inviteOnly,
    filters: cloneFrom.filters,
    tags: cloneFrom.tags,
    settings: {
      maxUsers: cloneFrom.settings.maxUsers,
      subscribersJoinParent: cloneFrom.settings.subscribersJoinParent,
      subscribersAreDecoyed: cloneFrom.settings.subscribersAreDecoyed,
      // don't copy join rules. this wouldn't make sense on a new channel
      groupRoleJoinRules: [],
      hasPerksEnabled: cloneFrom.settings.hasPerksEnabled,
      hasBillingPaymentsEnabled: cloneFrom.settings.hasBillingPaymentsEnabled,
      hasWorkOrdersEnabled: false,
      hasWorkOrderServiceRequestsEnabled: false,
      hasWorkOrderPreventiveMaintenanceEnabled: false,
      hasWorkOrderEquipmentEnabled: false,
      hasWorkOrderCrossPropertyEnabled: false,
      hasVisitorManagementEnabled:
        cloneFrom.settings.hasVisitorManagementEnabled,
      hasHardwareManagementEnabled:
        cloneFrom.settings.hasHardwareManagementEnabled,
      hasHardwarePinManagementEnabled:
        cloneFrom.settings.hasHardwarePinManagementEnabled,
      hasReservableManagementEnabled:
        cloneFrom.settings.hasReservableManagementEnabled,
      hasAccessControlEnabled: cloneFrom.settings.hasAccessControlEnabled,
      hasSurveysEnabled: cloneFrom.settings.hasSurveysEnabled,
      areUserTemplatesAllowed: cloneFrom.settings.areUserTemplatesAllowed,
      channelLanguages: cloneFrom.settings.channelLanguages,
      enabledLanguagesForMembers: cloneFrom.settings.enabledLanguagesForMembers,
      multiLanguageEnabled: cloneFrom.settings.multiLanguageEnabled,
      offboardingDelayDays: cloneFrom.settings.offboardingDelayDays,
      hasMessagingAndMarketplaceEnabled:
        cloneFrom.settings.hasMessagingAndMarketplaceEnabled,
      hasSmartUserManagementEnabled:
        cloneFrom.settings.hasSmartUserManagementEnabled,
    },
    stats: {
      subscribers: cloneFrom.stats?.subscribers ?? 1,
      sf: cloneFrom.stats?.sf ?? 1,
    },
  };
}
