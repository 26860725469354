import React from 'react';

import cx from 'classnames';
import {
  Pagination,
  ContentPlacementListItem,
  ContentCard,
  Loading,
} from 'components';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import getContentLink from '../helpers/getContentLink';
import ChannelContentQuery from './ChannelContentQuery';

import styles from './ChannelContentCenterGrid.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { ContentType } from 'lane-shared/types/content/Content';

type PageInfo = {
  start: number;
  total: number;
  perPage: number;
};

type Placements = {
  here: ContentType[];
  groupRole: Record<string, ContentType[]>;
  channel: Record<string, ContentType[]>;
};

type $FixProps = any;

function ChannelContentCentreGrid({
  className,
  style,
  query,
  queryName,
  channel,
  page,
  perPage,
  selectedSort,
  selectedOrder,
  search,
  availableTypes,
  endDate,
  startDate,
  forDrafts,
  goQuery,
  hideOnError,
  primaryId,
}: $FixProps) {
  const isDraftContentStateEnabled = useFlag(
    FeatureFlag.DraftContentState,
    false
  );

  function renderContent(content: ContentType) {
    return (
      <Link
        key={content._id}
        to={
          content.generator
            ? routes.channelAdminGeneratorContent
                .replace(':id', channel.slug)
                .replace(':contentId', content._id)
            : getContentLink({
                slug: channel.slug,
                contentId: content._id,
                forDrafts,
                isDraftContentStateEnabled,
              })
        }
      >
        <ContentCard content={content} className={styles.contentCard} />
      </Link>
    );
  }

  if (!channel) return <Loading />;

  return (
    <div
      className={cx(styles.ChannelContentCenterGrid, className)}
      style={style}
    >
      <ChannelContentQuery
        hideOnError={hideOnError}
        query={query}
        queryName={queryName}
        hasPlacements={!forDrafts}
        channelId={channel?._id}
        page={page}
        perPage={perPage}
        selectedSort={selectedSort}
        selectedOrder={selectedOrder}
        search={search}
        availableTypes={availableTypes}
        endDate={endDate}
        startDate={startDate}
        primaryId={primaryId}
      >
        {({
          placements,
          pageInfo,
        }: {
          placements: Placements;
          pageInfo: PageInfo;
        }) => (
          <>
            <Pagination
              total={pageInfo.total}
              perPage={perPage}
              page={page}
              onPage={page => goQuery({ page: page + 1 })}
            />
            <div className={styles.group}>
              <ContentPlacementListItem channelId={channel?._id} />
              <ul>{placements.here.map(renderContent)}</ul>
            </div>
            {Object.entries(placements.groupRole).map(([key, value]) => (
              <div className={styles.group} key={key}>
                <ContentPlacementListItem groupRoleId={key} />
                <ul>{value.map(renderContent)}</ul>
              </div>
            ))}
            {Object.entries(placements.channel).map(([key, value]) => (
              <div className={styles.group} key={key}>
                <ContentPlacementListItem channelId={key} />
                <ul>{value.map(renderContent)}</ul>
              </div>
            ))}
          </>
        )}
      </ChannelContentQuery>
    </div>
  );
}

export default ChannelContentCentreGrid;
