import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

const YouRHereDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.YouRHere,
  hasCustomUI: false,
  friendlyName: 'YouRHere Displays',
  description: `Defines the properties required for a YouRHere integration.`,
  category: IntegrationCategoryEnum.Displays,
  platforms: [],
  type: IntegrationTypeEnum.ContentTargeting,
  settings: {},
  properties: {
    tags: {
      friendlyName: 'Filters',
      type: 'String',
      isArray: true,
    },
    showDate: {
      friendlyName: 'Show dates on screens',
      type: 'Boolean',
      default: false,
    },
    showQRCode: {
      friendlyName: 'Show QR code on screens',
      type: 'Boolean',
      default: false,
    },
  },
  mobilePermissions: [],
};

export default YouRHereDefinition;
