import { useContext } from 'react';

import { AppContext } from 'lane-shared/contexts';
import { NotImplementedError } from 'activate-errors';
import { canHandleError } from 'lane-shared/helpers/oAuth';
import useOAuthSignUp, {
  OAuthGetIdentityResponse,
} from 'lane-shared/hooks/useOAuthSignUp';
import LaneOAuthHook from 'lane-shared/types/LaneOAuthHook';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import {
  OAUTH_PROVIDERS,
  OAuthProvidersEnum,
  UserLoginProviderEnum,
} from 'constants-user';

import { getOAuthIdentity } from '../utils/oauth';
import { getOktaAccessToken } from '../utils/oauth/okta';

type UseOktaOAuthProps = {
  oAuthConfig: OAuthConfigType;
  onError?: (err: Error | null) => void;
  onSuccess: (signInData?: any) => void;
};

export default function useOktaOAuth({
  oAuthConfig,
  onError,
  onSuccess,
}: UseOktaOAuthProps): LaneOAuthHook {
  const { setIsBlockingLoad } = useContext(AppContext);

  const isEnterprise = Boolean(oAuthConfig.tenantId);

  function handleError(err: Error | null) {
    if (onError && canHandleError(err)) {
      onError(err);
      setIsBlockingLoad(false);
    }
  }

  function handleResume() {
    setIsBlockingLoad(true);
  }

  function handleSuccess(signInData: any) {
    setIsBlockingLoad(false);
    onSuccess(signInData);
  }

  async function getIdentity({
    oAuthConfig,
  }: any): Promise<OAuthGetIdentityResponse> {
    const oAuthIdentity = await getOAuthIdentity({
      oAuthConfig,
      provider: OAuthProvidersEnum.OKTA,
    });

    const { extra } = oAuthIdentity;

    if (extra) {
      const { name, preferred_username, email } = extra;

      return {
        preferredName: name || preferred_username,
        userPrincipalName: email,
      };
    }

    // NOTE/TODO: Best guess of name support for Okta's use of OAuth2 Spec,
    // This is unlikely to be seen, and can be addressed when/as encountered.
    // NI68 was chosen to represent line number at time of writing.
    throw new NotImplementedError(
      'Identity support for Okta is not well defined. NI72'
    );
  }

  const { doSignUp } = useOAuthSignUp(
    getOAuthIdentity,
    getOktaAccessToken,
    getIdentity,
    {
      onSuccess: handleSuccess,
      onError: handleError,
      onResume: handleResume,
      oAuthConfig,
    }
  );

  function onOktaSignUp() {
    handleError(null);
    doSignUp({
      oAuthIdentityProps: {
        oAuthConfig,
        provider: OAuthProvidersEnum.OKTA,
      },
      oAuthGetIdentityProps: {
        oAuthConfig,
      },
      oAuthAccessTokenProps: {
        oAuthConfig,
      },
      legacyOAuthProvider: OAUTH_PROVIDERS.OKTA,
      loginProvider: UserLoginProviderEnum.Okta,
    });
  }

  return {
    authorize: onOktaSignUp,
    isEnabled: Boolean(oAuthConfig?.enabled),
    enterprise: isEnterprise,
  };
}
