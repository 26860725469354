import { useMemo } from 'react';

import { TemplateExportOptionsType, TemplateImportOptionsType } from '.';
import { allFields } from './constants';
import { Channel } from '../../types/ChannelType';

type TemplateImportExportOptionsType =
  | TemplateImportOptionsType
  | TemplateExportOptionsType;

export default function useImportExportInfo({
  options,
  channel,
}: {
  options: TemplateImportExportOptionsType;
  channel: Channel | undefined;
}) {
  const appearanceAvailable = options.appearance !== undefined;

  // Are any additional options available in this import.
  const optionsAvailable =
    options.placements !== undefined ||
    options.data !== undefined ||
    options.notifications !== undefined ||
    options.workflows !== undefined;

  // Are any features available in this import
  const featureCount: number = useMemo(
    () =>
      Object.values(options.features).reduce(
        (count: number, value) => count + (value === undefined ? 0 : 1),
        0
      ),
    [options]
  );

  const availableFields = Object.keys(allFields).filter(
    key => options[key as keyof TemplateImportExportOptionsType] !== undefined
  );

  // Are all available options selected
  const allFieldsSelected = availableFields
    .filter(
      key => options[key as keyof TemplateImportExportOptionsType] !== undefined
    )
    .reduce(
      (selected, key) =>
        selected && !!options[key as keyof TemplateImportExportOptionsType],
      true
    );

  // Only make translations toggle visible if the channel has MLS enabled
  const translationsAvailable =
    channel?.settings?.multiLanguageEnabled || false;

  const fieldsStep = availableFields.length > 0 ? 1 : 0;
  const appearanceStep = fieldsStep + (appearanceAvailable ? 1 : 0);
  const optionsStep = appearanceStep + (optionsAvailable ? 1 : 0);
  const featureStep = optionsStep + (featureCount > 0 ? 1 : 0);
  const translationsStep = featureStep + (translationsAvailable ? 1 : 0);

  return {
    appearanceAvailable,
    optionsAvailable,
    translationsAvailable,
    featureCount,
    availableFields,
    allFieldsSelected,
    fieldsStep,
    appearanceStep,
    translationsStep,
    optionsStep,
    featureStep,
  };
}
