import { SortDirectionEnum } from 'constants-activate';

export enum SortByLabelEnum {
  StartDate = 'Start Date',
  EndDate = 'End Date',
}

export enum SortByEnum {
  StartDate = 'startDate',
  EndDate = 'endDate',
  LiveDate = 'liveDate',
  UnpublishDate = 'unpublishDate',
}

export const SortByMap = new Map<string, string>([
  [SortByLabelEnum.StartDate, SortByEnum.StartDate],
  [SortByLabelEnum.EndDate, SortByEnum.EndDate],
]);

export enum SortDirectionLabelEnum {
  Asc = 'Newest to Oldest',
  Desc = 'Oldest to Newest',
}

export type SortPropertyType = {
  sortBy: SortByEnum;
  sortDirection: SortDirectionEnum;
};

export const SortDirectionMap = new Map<string, string>([
  [SortDirectionLabelEnum.Asc, SortDirectionEnum.Asc],
  [SortDirectionLabelEnum.Desc, SortDirectionEnum.Desc],
]);

export const SortByLabelToSchema = Object.values(SortByLabelEnum).map(
  value => ({
    label: value,
    value: SortByMap.get(value),
  })
);

export const SortDirectionLabelToSchema = Object.values(
  SortDirectionLabelEnum
).map(value => ({
  label: value,
  value: SortDirectionMap.get(value),
}));
