export function isNumberTwoDecimalPlaces(value: number | null): boolean {
  return isNumberDecimalPlace(value, 2);
}

export function isNumberThreeDecimalPlaces(value: number | null): boolean {
  return isNumberDecimalPlace(value, 3);
}

function isNumberDecimalPlace(value: number | null, maximDecimalPlace: number): boolean{
  const decimalValue = value?.toString().split('.')[1];

  if (decimalValue !== undefined && decimalValue.length > maximDecimalPlace) {
    return false;
  }

  return true;
}
