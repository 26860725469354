import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { SIMPLE_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import { ANGUS_ORDER_STATUS_COLOR } from 'constants-integrations';
import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';
import getAngusWorkOrderFromInteractionData from 'lane-shared/helpers/integrations/AngusServiceRequests/getAngusWorkOrderFromInteractionData';

import styles from './RequestCard.scss';

function RequestCard({ style, className, interaction }: any) {
  const request = getAngusWorkOrderFromInteractionData(interaction.data);
  const { t } = useTranslation();

  return (
    <div className={cx(styles.RequestCard, className)} style={style}>
      {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
      <h1>{request.requestTypeDescription}</h1>
      <div
        className={styles.line}
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        style={{ borderColor: ANGUS_ORDER_STATUS_COLOR[request.status] }}
      />
      <p>
        {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
        {t('Request Number')}: <span>{request._id}</span>
      </p>
      <p>
        {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
        {t('Status')}: <span>{request.status}</span>
      </p>
      <p>
        {t('Submitted')}:{' '}
        <span>{dateFormatter(interaction._created, SIMPLE_DATE_TIME)}</span>
      </p>
      <p>
        {t('Updated')}:{' '}
        <span>{dateFormatter(interaction._updated, SIMPLE_DATE_TIME)}</span>
      </p>
    </div>
  );
}

export default RequestCard;
