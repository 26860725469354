import { IntegrationProviderEnum } from 'constants-integrations';
import { ChannelProviderType } from '../types';

export function mapIntegration(
  integrationProvider: ChannelProviderType
): ChannelProviderType {
  if (
    integrationProvider.integration.name ===
    IntegrationProviderEnum.AccessManagement
  ) {
    return {
      ...integrationProvider,
      isLegacy: false,
      integration: {
        ...integrationProvider.integration,
        name: integrationProvider?.settings?.identityProviderService
          ?.name as IntegrationProviderEnum.AccessManagement,
      },
    };
  }

  return { ...integrationProvider, isLegacy: true };
}
