import React from 'react';

import { Toggle } from 'components';

import { Channel } from 'packages/lane-shared/types/ChannelType';

import styles from 'components/lane/ChannelSettingsEdit/ChannelSettings.scss';

type SurveyToggleProps = {
  channel: Partial<Channel> & {
    settings: {
      hasSurveysEnabled: boolean;
    };
  };
  onChannelUpdated: (channel: any) => void;
  t: (key: string, params?: any) => string;
  disabled?: boolean;
};

export function SurveysToggle({
  channel,
  onChannelUpdated,
  disabled = false,
  t,
}: SurveyToggleProps) {
  return (
    <div className={styles.setting}>
      <Toggle
        disabled={disabled}
        className={styles.toggle}
        testId="toggleSurveys"
        value={channel.settings.hasSurveysEnabled || false}
        onChange={enabled =>
          onChannelUpdated({
            settings: {
              ...channel.settings,
              hasSurveysEnabled: enabled,
            },
          })
        }
        text={t('web.content.feature.surveys.toggleFeature.title')}
        description={t('web.content.feature.surveys.toggleFeature.description')}
      />
    </div>
  );
}
