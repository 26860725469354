import React, { useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { useMyPaymentAccountsQuery } from 'lane-shared/hooks';
import { useEssensysInvoicesByAccountId } from 'lane-shared/hooks/useEssensysInvoices';
import { InvoiceAccount } from 'lane-shared/hooks/useEssensysUserMetadata';
import { IntegrationProviderEnum } from 'constants-integrations';
import { PaymentProviderEnum } from 'constants-payments';

import CardContainer from 'components/cards/CardContainer';
import { Loading, ErrorMessage } from 'components/general';
import UserPlacePayPaymentAccount from 'components/payments/UserPlacePayPaymentAccount';
import { H2 } from 'components/typography';

import InvoiceListView from './components/InvoiceListView';

import styles from './InvoicePayment.scss';

type InvoicePaymentProps = {
  account: InvoiceAccount;
};

export default function InvoicePayment({ account }: InvoicePaymentProps) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);

  const { externalPaymentAccounts } = useMyPaymentAccountsQuery();

  const {
    invoices,
    loading: invoicesLoading,
    error: invoicesError,
  } = useEssensysInvoicesByAccountId(account._id);

  const essensysPaymentAccount = externalPaymentAccounts.find(
    paymentAccount =>
      paymentAccount.paymentProcessor?.integrationProvider ===
      IntegrationProviderEnum.Essensys
  );

  const formatCurrency = currencyFormatter({
    currency: account.currencyCode,
    locale: user?.locale,
  });

  return (
    <CardContainer className={styles.container} p size="large">
      <H2 className={styles.accountTitle} mb={3}>
        {account.name}
      </H2>
      <ErrorMessage error={invoicesError} />
      {essensysPaymentAccount && (
        <>
          {essensysPaymentAccount.type === PaymentProviderEnum.PlacePay && (
            <UserPlacePayPaymentAccount
              paymentAccount={essensysPaymentAccount}
              className={styles.placePay}
              accountId={account._id}
            />
          )}
          {/* TODO: LANE-1211 Autopay flow for Stripe */}
          <div className={styles.line} />
        </>
      )}
      <div className={styles.row}>
        <div className={styles.column}>
          <h3>
            {t('Invoices')} <strong>{formatCurrency(account.balance)}</strong>
          </h3>
        </div>
        <div className={cx(styles.column, styles.listViewContainer)}>
          {invoicesLoading ? (
            <Loading />
          ) : (
            invoices?.map(invoice => (
              <div className={styles.listViewWrapper} key={invoice._id}>
                <InvoiceListView invoice={invoice} />
              </div>
            ))
          )}
        </div>
      </div>
    </CardContainer>
  );
}
