import { FeatureNameEnum } from 'constants-content';

/**
 * Options for exporting a template.
 *
 * Uses a trinary for values.
 *  - undefined  not available.
 *  - false available but do not copy.
 *  - true  available and copy.
 */

export type TemplateExportOptionsType = {
  name: boolean | undefined;
  description: boolean | undefined;
  subtitle: boolean | undefined;
  tags: boolean | undefined;
  category: boolean | undefined;
  logo: boolean | undefined;
  color: boolean | undefined;
  backgroundColor: boolean | undefined;
  backgroundImage: boolean | undefined;
  icon: boolean | undefined;
  resetPeriod: boolean | undefined;
  appearance: boolean | undefined;
  translations: boolean | undefined;
  placements: boolean | undefined;
  data: boolean | undefined;
  workflows: boolean | undefined;
  notifications: boolean | undefined;
  metatags: boolean | undefined;
  features: {
    [key in keyof typeof FeatureNameEnum]: boolean | undefined;
  };
  isInteractive: boolean;
};

export const defaultExportOptions: TemplateExportOptionsType = {
  name: true,
  description: true,
  subtitle: true,
  tags: true,
  category: true,
  logo: true,
  color: true,
  backgroundColor: true,
  backgroundImage: true,
  icon: true,
  resetPeriod: true,
  appearance: true,
  translations: false,
  placements: true,
  data: true,
  workflows: true,
  notifications: true,
  metatags: true,
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{ Cancelable: false; Deliverable: false; Ent... Remove this comment to see the full error message
  features: {
    Cancelable: false,
    Deliverable: false,
    Entries: false,
    EssensysProduct: false,
    Inventory: false,
    GuestInvite: false,
    Menu: false,
    Payment: false,
    QRCodeCheckin: false,
    Quantity: false,
    RemoteFetch: false,
    Reservable: false,
    Reset: false,
    Scheduled: false,
    Shipping: false,
    SocialOptions: false,
    Statuses: false,
    SubmitOnBehalfOf: false,
    TimeAvailability: false,
    UseCompanyPermissions: false,
  },
  isInteractive: true,
};
