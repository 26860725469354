import { UserLoginTypeEnum } from 'constants-user';
import getPrimaryLogin, { GetPrimaryLoginType } from './getPrimaryLogin';

export default function getPrimaryEmail<T extends GetPrimaryLoginType>(
  logins: T[] = []
): string | null {
  const login = getPrimaryLogin(logins, UserLoginTypeEnum.Email);

  return login?.key || null;
}
