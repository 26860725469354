import { ObjectValues } from 'type-alias';

import { PERMISSION_KEYS, PermissionKey } from 'constants-permissions';
import { EVENT_KEYS } from 'constants-events';
import { REQUIREMENT_KEYS, RequirementKey } from 'constants-requirements';

export enum ContractUnitEnum {
  SquareFoot = 'SF',
  User = 'User',
  FlatFee = 'Flat Fee',
  SiteLicence = 'Site Licence',
}

export enum ChannelContractNameEnum {
  Platform = 'Platform',
  Whitelabel = 'Whitelabel',
  Perks = 'Perks',
  Other = 'Other',
}

export enum ChannelRelationshipTypeEnum {
  Tenant = 'Tenant',
}

export enum ActiveChannelTypeEnum {
  Company = 'Company',
  Entertainment = 'Entertainment',
  Property = 'Property',
  Restaurant = 'Restaurant',
  Retail = 'Retail',
  Service = 'Service',
}

/** @deprecated - do not use these channel types */
export enum DeprecatedChannelTypeEnum {
  Professional = 'Organization',
  Charity = 'Charity',
  Meta = 'Meta',
  Curated = 'Curated',
}

export const CHANNEL_TYPE_KEYS = Object.freeze({
  ...DeprecatedChannelTypeEnum,
  ...ActiveChannelTypeEnum,
});

export enum ChannelExperienceTypeEnum {
  office = 'office',
  multifamily = 'multifamily',
}

export type ChannelType = ObjectValues<typeof CHANNEL_TYPE_KEYS>;

export enum CHANNEL_DIRECTORY_TYPE {
  Retail = 'Retail',
  Office = 'Office',
  User = 'User',
}

export const CHANNEL_DIRECTORY_TYPES = Object.values(CHANNEL_DIRECTORY_TYPE);

export enum CHANNEL_DIRECTORY_LIST_VIEW {
  Thumbnail = 'Thumbnail',
  List = 'ListView',
}

export const CHANNEL_DIRECTORY_LIST_VIEWS = Object.values(
  CHANNEL_DIRECTORY_LIST_VIEW
);

export enum SPECIAL_GROUP_ROLES {
  ADMIN = 'Admin',
  WORKPLACE_MEMBER = 'Workplace Member',
  ACCESS_MANAGER = 'Access admin',
  COMPANY_ACCESS_ADMIN = 'Access admin (company channel)',
}

export enum STANDARD_GROUP_ROLES_NAMES {
  MARKETING = 'Marketing',
  RETAIL_MANAGER = 'Retail Manager',
  WORKPLACE_ADMIN = 'Workplace Admin',
  WORKPLACE_MEMBER = 'Workplace Member',
}

export enum MULTIFAMILY_USER_TYPE_NAMES {
  PRIMARY_RESIDENT = 'Primary resident',
  SECONDARY_RESIDENT = 'Secondary resident',
  GUARANTOR = 'Guarantor',
}

export enum MULTIFAMILY_GROUP_ROLES_NAMES {
  ADMIN = 'Admin',
  PROPERTY_STAFF = 'Property staff',
  FUTURE_RESIDENT = 'Future',
  CURRENT_RESIDENT = 'Current',
  RESIDENT = 'Resident',
  PAST_RESIDENT = 'Past',
  NOTICE = 'Notice',
  EVICTION = 'Under eviction',
}

type GroupRolePermissionsSetType = {
  permissions: PermissionKey[];
  requirements: RequirementKey[];
  isSystem?: boolean;
};

type ChannelGroupRolePermissionsSetType = Partial<
  Record<STANDARD_GROUP_ROLES_NAMES, GroupRolePermissionsSetType>
>;

type ChannelExperiencePermissionSetType = Partial<
  Record<string, GroupRolePermissionsSetType>
>;

// setup special group role permissions for Admin and Workplace Member
export const SPECIAL_GROUP_ROLE_PERMISSIONS: Readonly<
  Record<SPECIAL_GROUP_ROLES, PermissionKey[]>
> = Object.freeze({
  [SPECIAL_GROUP_ROLES.ADMIN]: [PERMISSION_KEYS.PERMISSION_ADMIN],
  [SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER]: [
    PERMISSION_KEYS.PERMISSION_CONTENT_PROMOTION_READ,
    PERMISSION_KEYS.PERMISSION_CONTENT_READ,
    PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_INVITE,
    PERMISSION_KEYS.PERMISSION_SECTIONS_READ,
  ],
  [SPECIAL_GROUP_ROLES.ACCESS_MANAGER]: [
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_ENABLE,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_DISABLE,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_ASSIGN,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_REVOKE,
    PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS,
    PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS_MOBILE,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_HID,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_CCURE,
    PERMISSION_KEYS.PERMISSION_USERS_DELETE,
    PERMISSION_KEYS.PERMISSION_USERS_READ,
    PERMISSION_KEYS.PERMISSION_USERS_INVITE,
    PERMISSION_KEYS.PERMISSION_USERS_INFO,
    PERMISSION_KEYS.PERMISSION_USERS_SEARCH,
  ],
  [SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN]: [
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_MOBILE_ACCESS_MANAGE_COMPANY,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_GROUPS_MANAGE_COMPANY,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_HID,
    PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_CCURE,
  ],
});

export const SPECIAL_GROUP_ROLE_REQUIREMENTS: Readonly<
  Partial<Record<SPECIAL_GROUP_ROLES, RequirementKey[]>>
> = Object.freeze({
  [SPECIAL_GROUP_ROLES.ADMIN]: [
    REQUIREMENT_KEYS.REQUIREMENT_USER_PRESENCE,
    REQUIREMENT_KEYS.REQUIREMENT_USER_LOCATION,
    REQUIREMENT_KEYS.REQUIREMENT_PROFILE_NAME,
    REQUIREMENT_KEYS.REQUIREMENT_PROFILE_EMAIL,
    REQUIREMENT_KEYS.REQUIREMENT_PROFILE_ADDRESS,
    REQUIREMENT_KEYS.REQUIREMENT_PROFILE_PHONE,
    REQUIREMENT_KEYS.REQUIREMENT_NOTIFICATION_EMAIL,
    REQUIREMENT_KEYS.REQUIREMENT_NOTIFICATION_PUSH,
  ],
  [SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER]: [
    REQUIREMENT_KEYS.REQUIREMENT_USER_PRESENCE,
    REQUIREMENT_KEYS.REQUIREMENT_USER_LOCATION,
    REQUIREMENT_KEYS.REQUIREMENT_PROFILE_NAME,
  ],
});

// standard event subscriptions we should sign up people to.
export const STANDARD_EVENT_SUBSCRIPTIONS = Object.freeze([
  EVENT_KEYS.EVENT_CONTENT_NOTIFICATION,
  EVENT_KEYS.EVENT_CONTENT_STARTED,
  EVENT_KEYS.EVENT_CONTENT_ENDED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_STARTED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_ENDED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_CREATED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_UPDATED,
  EVENT_KEYS.EVENT_CONTENT_INTERACTION_DELETED,
]);

// create some standard group roles that will be added to a channel on create
export const STANDARD_GROUP_ROLES: ChannelGroupRolePermissionsSetType =
  Object.freeze({
    [STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]: {
      permissions: [
        PERMISSION_KEYS.PERMISSION_ANALYTICS,
        PERMISSION_KEYS.PERMISSION_AUDIENCE_CREATE,
        PERMISSION_KEYS.PERMISSION_AUDIENCE_DELETE,
        PERMISSION_KEYS.PERMISSION_AUDIENCE_READ,
        PERMISSION_KEYS.PERMISSION_AUDIENCE_UPDATE_MANAGE,
        PERMISSION_KEYS.PERMISSION_AUDIENCE_UPDATE_USE,
        PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS,
        PERMISSION_KEYS.PERMISSION_CHANNEL_RELATIONSHIP_READ,
        PERMISSION_KEYS.PERMISSION_CHANNEL_RELATIONSHIP_UPDATE,
        PERMISSION_KEYS.PERMISSION_CHANNEL_RELATIONSHIP_CREATE,
        PERMISSION_KEYS.PERMISSION_CHANNEL_RELATIONSHIP_DELETE,
        PERMISSION_KEYS.PERMISSION_CONTENT_CREATE,
        PERMISSION_KEYS.PERMISSION_CONTENT_PUBLISH,
        PERMISSION_KEYS.PERMISSION_CONTENT_UPDATE,
        PERMISSION_KEYS.PERMISSION_CONTENT_READ,
        PERMISSION_KEYS.PERMISSION_CONTENT_DELETE,
        PERMISSION_KEYS.PERMISSION_CONTENT_INTERACTIONS_READ,
        PERMISSION_KEYS.PERMISSION_CONTENT_INTERACTIONS_UPDATE,
        PERMISSION_KEYS.PERMISSION_CONTENT_INTERACTIONS_DELETE,
        PERMISSION_KEYS.PERMISSION_DOCUMENTS_CREATE,
        PERMISSION_KEYS.PERMISSION_DOCUMENTS_DELETE,
        PERMISSION_KEYS.PERMISSION_DOCUMENTS_READ,
        PERMISSION_KEYS.PERMISSION_DOCUMENTS_UPDATE,
        PERMISSION_KEYS.PERMISSION_EMAIL_SEND,
        PERMISSION_KEYS.PERMISSION_EMAIL_CREATE,
        PERMISSION_KEYS.PERMISSION_GROUPS_CREATE,
        PERMISSION_KEYS.PERMISSION_GROUPS_UPDATE,
        PERMISSION_KEYS.PERMISSION_GROUPS_DELETE,
        PERMISSION_KEYS.PERMISSION_LIBRARY_CREATE,
        PERMISSION_KEYS.PERMISSION_LIBRARY_READ,
        PERMISSION_KEYS.PERMISSION_LIBRARY_DELETE,
        PERMISSION_KEYS.PERMISSION_LIBRARY_UPDATE,
        PERMISSION_KEYS.PERMISSION_MEDIA_DELETE,
        PERMISSION_KEYS.PERMISSION_MEDIA_READ,
        PERMISSION_KEYS.PERMISSION_MEDIA_CREATE,
        PERMISSION_KEYS.PERMISSION_MEDIA_UPDATE,
        PERMISSION_KEYS.PERMISSION_PROFILE_UPDATE,
        PERMISSION_KEYS.PERMISSION_SECTIONS_CREATE,
        PERMISSION_KEYS.PERMISSION_SECTIONS_DELETE,
        PERMISSION_KEYS.PERMISSION_SECTIONS_UPDATE,
        PERMISSION_KEYS.PERMISSION_SECTIONS_READ,
        PERMISSION_KEYS.PERMISSION_SECTIONS_FILTERS,
        PERMISSION_KEYS.PERMISSION_TEMPLATES_READ,
        PERMISSION_KEYS.PERMISSION_TEMPLATES_CREATE,
        PERMISSION_KEYS.PERMISSION_USERS_SEARCH,
        PERMISSION_KEYS.PERMISSION_USERS_DELETE,
        PERMISSION_KEYS.PERMISSION_USERS_INFO,
        PERMISSION_KEYS.PERMISSION_USERS_INVITE,
        PERMISSION_KEYS.PERMISSION_USERS_READ,
        PERMISSION_KEYS.PERMISSION_METATAG_READ,
        PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_HID,
        PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_CCURE,
        PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS,
        PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS_MOBILE,
        PERMISSION_KEYS.PERMISSION_ANALYTICS_ADVANCED,
        PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_HOST_SELECT_ALL,
        PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_DOWNLOAD_QR_CODE,
        PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_READ,
        PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_READ_ALL,
        PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_PRINT_VISITOR_PASS,
        PERMISSION_KEYS.PERMISSION_CHANNEL_MODULE_UPDATE,
        PERMISSION_KEYS.PERMISSION_ADDRESS_UPDATE,
        PERMISSION_KEYS.PERMISSION_ANALYTICS_WORK_ORDERS,
        PERMISSION_KEYS.PERMISSION_CHARGE_CODE_MANAGE,
        PERMISSION_KEYS.PERMISSION_BILLING_PAYMENTS_CHARGE_CREATE,
        PERMISSION_KEYS.PERMISSION_BILLING_PAYMENTS_COMPANY_RECURRING_CHARGES,
        PERMISSION_KEYS.PERMISSION_BILLING_PAYMENTS_CHARGE_VOID,
        PERMISSION_KEYS.PERMISSION_CREDITS_MANAGE,
        PERMISSION_KEYS.PERMISSION_PRODUCT_EXCEPTIONS_MANAGE,
        PERMISSION_KEYS.PERMISSION_COMPANY_INVOICES,
        PERMISSION_KEYS.PERMISSION_CAN_CANCEL_MEMBERSHIPS,
        PERMISSION_KEYS.PERMISSION_PRODUCTS_MANAGE,
        PERMISSION_KEYS.PERMISSION_GROUPS_READ,
        PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_CREATE,
        PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_DELETE,
        PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_READ,
        PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_UPDATE,
        PERMISSION_KEYS.PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE,
        PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_CREATE,
        PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_READ,
        PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_DELETE,
        PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_UPDATE,
        PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_COMPANY,
        PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_RETAIL,
        PERMISSION_KEYS.PERMISSION_RESERVATIONS_MODULE_ACCESS,
        PERMISSION_KEYS.PERMISSION_GROUP_PRIMARY_LOCATION_UPDATE,
        PERMISSION_KEYS.PERMISSION_USERS_ROLE_INVITE,
        PERMISSION_KEYS.PERMISSION_USERS_ROLE_READ,
        PERMISSION_KEYS.PERMISSION_USERS_ROLE_SEARCH,
        PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_READ,
        PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_SEARCH,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_MODULE_ACCESS,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_SETTINGS,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_LINK_EQUIPMENT,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_CREATE,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_SETTINGS,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_EQUIPMENT_ADD_EDIT,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_SCHEDULE_VIEW,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_SCHEDULE_CREATE,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_VIEW,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNTEAM,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_ASSIGNEE,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_ATTACH_FILE,
        PERMISSION_KEYS.PERMISSION_WORK_ORDERS_PM_TASK_EDIT_STATUS,
      ],
      requirements: [
        REQUIREMENT_KEYS.REQUIREMENT_NOTIFICATION_PUSH,
        REQUIREMENT_KEYS.REQUIREMENT_NOTIFICATION_EMAIL,
        REQUIREMENT_KEYS.REQUIREMENT_PROFILE_ADDRESS,
        REQUIREMENT_KEYS.REQUIREMENT_PROFILE_EMAIL,
        REQUIREMENT_KEYS.REQUIREMENT_PROFILE_NAME,
        REQUIREMENT_KEYS.REQUIREMENT_PROFILE_PHONE,
        REQUIREMENT_KEYS.REQUIREMENT_USER_PRESENCE,
        REQUIREMENT_KEYS.REQUIREMENT_USER_LOCATION,
      ],
    },
  });

export const CHANNEL_TYPE_GROUP_ROLE_MAP = new Map<
  ChannelType,
  Readonly<ChannelGroupRolePermissionsSetType>
>();

// add in some extra permissions for property channels
CHANNEL_TYPE_GROUP_ROLE_MAP.set(CHANNEL_TYPE_KEYS.Property, {
  [STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]: {
    permissions: [
      ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
        ?.permissions ?? []),
      PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_CREATE,
      PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_READ,
      PERMISSION_KEYS.PERMISSION_PROPERTY_GUESTS_UPDATE,
      PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_RETAIL,
      PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_COMPANY,
      PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_CREATE,
      PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_READ,
      PERMISSION_KEYS.PERMISSION_PROPERTY_MAINTENANCE_UPDATE,
      PERMISSION_KEYS.PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE,
      PERMISSION_KEYS.PERMISSION_EQUIPMENT_CREATE,
      PERMISSION_KEYS.PERMISSION_EQUIPMENT_DELETE,
      PERMISSION_KEYS.PERMISSION_EQUIPMENT_READ,
      PERMISSION_KEYS.PERMISSION_EQUIPMENT_UPDATE,
    ],
    requirements: [
      ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
        ?.requirements ?? []),
    ],
  },

  [STANDARD_GROUP_ROLES_NAMES.RETAIL_MANAGER]: {
    permissions: [
      ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
        ?.permissions ?? []),
      PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_RETAIL,
    ],
    requirements: [
      ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
        ?.requirements ?? []),
    ],
  },
});

export const CHANNEL_EXPERIENCE_TYPE_PERMISSION_GROUP_MAP = new Map<
  ChannelExperienceTypeEnum,
  Readonly<ChannelExperiencePermissionSetType>
>();

const deriveResidentPermissionGroupName = (
  type: string,
  groupRole: string
): string => {
  return `${type} - ${groupRole}`;
};

CHANNEL_EXPERIENCE_TYPE_PERMISSION_GROUP_MAP.set(
  ChannelExperienceTypeEnum.multifamily,
  {
    ...Object.entries(MULTIFAMILY_USER_TYPE_NAMES).reduce(
      // For each user type, generate permission groups for most Multifamily group roles
      (prev, [_, userType]) => {
        return {
          ...prev,
          // Permissions groups except admin and property staff will have CRE workplace member permissions
          // as a placeholder until permissions are decided by product later
          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.FUTURE_RESIDENT
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },

          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.CURRENT_RESIDENT
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },

          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.PAST_RESIDENT
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },

          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.NOTICE
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },

          [deriveResidentPermissionGroupName(
            userType,
            MULTIFAMILY_GROUP_ROLES_NAMES.EVICTION
          )]: {
            permissions:
              SPECIAL_GROUP_ROLE_PERMISSIONS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ],
            requirements: [
              ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
                SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
              ] ?? []),
            ],
            isSystem: true,
          },
        };
      },
      {
        // Property staff and base resident groups are always included, and not replicated per user type
        [MULTIFAMILY_GROUP_ROLES_NAMES.PROPERTY_STAFF]: {
          permissions: [
            ...(CHANNEL_TYPE_GROUP_ROLE_MAP.get(CHANNEL_TYPE_KEYS.Property)?.[
              STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN
            ]?.permissions ?? []),
            PERMISSION_KEYS.PERMISSION_UNIT_MANAGE,
            PERMISSION_KEYS.PERMISSION_FLOOR_MANAGE,
          ],
          requirements: [
            ...(STANDARD_GROUP_ROLES[STANDARD_GROUP_ROLES_NAMES.WORKPLACE_ADMIN]
              ?.requirements ?? []),
          ],
        },
        [MULTIFAMILY_GROUP_ROLES_NAMES.RESIDENT]: {
          permissions:
            SPECIAL_GROUP_ROLE_PERMISSIONS[
              SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
            ],
          requirements: [
            ...(SPECIAL_GROUP_ROLE_REQUIREMENTS[
              SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER
            ] ?? []),
          ],
        },
      }
    ),
  }
);

export const CHILDREN_NAMES: Readonly<Record<ChannelType, string>> =
  Object.freeze({
    [CHANNEL_TYPE_KEYS.Property]: 'Properties',
    [CHANNEL_TYPE_KEYS.Company]: 'Locations',
    [CHANNEL_TYPE_KEYS.Retail]: 'Locations',
    [CHANNEL_TYPE_KEYS.Service]: 'Locations',
    [CHANNEL_TYPE_KEYS.Entertainment]: 'Locations',
    [CHANNEL_TYPE_KEYS.Restaurant]: 'Locations',
    [CHANNEL_TYPE_KEYS.Professional]: 'Chapters',
    [CHANNEL_TYPE_KEYS.Charity]: 'Locations',
    [CHANNEL_TYPE_KEYS.Meta]: 'Meta',
    [CHANNEL_TYPE_KEYS.Curated]: 'Magazine',
  });

export const CHILDREN_NAMES_SINGULAR: Readonly<Record<ChannelType, string>> =
  Object.freeze({
    [CHANNEL_TYPE_KEYS.Property]: 'Property',
    [CHANNEL_TYPE_KEYS.Company]: 'Location',
    [CHANNEL_TYPE_KEYS.Retail]: 'Location',
    [CHANNEL_TYPE_KEYS.Service]: 'Location',
    [CHANNEL_TYPE_KEYS.Entertainment]: 'Location',
    [CHANNEL_TYPE_KEYS.Restaurant]: 'Location',
    [CHANNEL_TYPE_KEYS.Professional]: 'Chapter',
    [CHANNEL_TYPE_KEYS.Charity]: 'Location',
    [CHANNEL_TYPE_KEYS.Meta]: 'Meta',
    [CHANNEL_TYPE_KEYS.Curated]: 'Magazine',
  });

export const TENANT_TYPES: Readonly<ChannelType[]> = Object.freeze([
  CHANNEL_TYPE_KEYS.Retail,
  CHANNEL_TYPE_KEYS.Restaurant,
  CHANNEL_TYPE_KEYS.Company,
  CHANNEL_TYPE_KEYS.Service,
  CHANNEL_TYPE_KEYS.Entertainment,
  CHANNEL_TYPE_KEYS.Professional,
  CHANNEL_TYPE_KEYS.Charity,
]);

export const PROPERTY_TYPES: Readonly<ChannelType[]> = Object.freeze([
  CHANNEL_TYPE_KEYS.Property,
]);

export const OFFICE_TYPES: Readonly<ChannelType[]> = Object.freeze([
  CHANNEL_TYPE_KEYS.Company,
  CHANNEL_TYPE_KEYS.Professional,
  CHANNEL_TYPE_KEYS.Charity,
]);

export const RETAIL_TYPES: Readonly<ChannelType[]> = Object.freeze([
  CHANNEL_TYPE_KEYS.Retail,
  CHANNEL_TYPE_KEYS.Restaurant,
  CHANNEL_TYPE_KEYS.Service,
  CHANNEL_TYPE_KEYS.Entertainment,
]);

export enum CHANNEL_DATA_TAGS {
  demo = 'demo',
  contentDistribution = 'content-distribution',
  physicalBuilding = 'physical-building',
  flexCompany = 'flex-company',
}

export function getSingularChildrenName(parentType: ChannelType): string {
  return CHILDREN_NAMES_SINGULAR[parentType] || '';
}

export function getChildrenName(parentType: ChannelType): string {
  return CHILDREN_NAMES[parentType] || '';
}
