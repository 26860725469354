import { useEffect, useState } from 'react';
import { UserType } from '../../../types/User';
import {
  AddProductToChargeDetails,
  Charge,
  Currency,
  ExternalActorType,
  ExternalEntityType,
  ExternalPayerType,
  ProductItem,
} from '../types';
import { GroupType } from 'graphql-query-contracts';

import { useLazyQuery } from '@apollo/client';
import {
  appendStoredCharge,
  filterStoredCharges,
} from './helpers/chargeLocalStorageMethods';
import {
  createChargeMutation,
  deleteDraftChargeMutation,
  listChargesQuery,
  updateDraftChargeMutation,
  voidChargeMutation,
} from '../queries';
import { convertToUUID } from 'uuid-encoding';
import { convertAddedProductToProductItem } from './helpers/convertAddedProductToProductItem';
import { useTranslation } from 'react-i18next';
import { getClient } from '../../../apollo';
import { getProductItemsTotal, productItemsToQueryFormat } from '../helpers';
import { SupportedLocaleEnum } from 'localization';
import { InvoiceType } from 'graphql-resolver-contracts';
import { v4 as uuid } from 'uuid';
import { Channel } from '../../../types/ChannelType';

export const EMPTY_CHARGE = {
  id: '',
  name: '',
  description: '',
  amount: 0,
  currency: Currency.CURRENCY_USD,
  status: '',
  invoiceId: '',
  externalPayerId: '',
  externalPayerType: ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER,
  groupId: '',
  groupType: GroupType.GroupTypeActivateChannel,
  externalEntityId: '',
  externalEntityType: ExternalEntityType.EXTERNAL_ENTITY_TYPE_ACTIVATE_INVOICE,
  externalActorId: '',
  externalActorType: ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
  items: [],
  metadata: '',
  createdAt: undefined,
  updatedAt: undefined,
  invoice: undefined,
};

export function useChargeManager({
  channel,
  actingUser,
  externalPayerId,
  externalPayerType = ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER,
  currency,
  externalEntityType = ExternalEntityType.EXTERNAL_ENTITY_TYPE_ACTIVATE_INVOICE,
  externalEntityId,
  invoiceType = InvoiceType.InvoiceTypeOneoff,
  chargeName,
  chargeDescription,
  invoiceDueInDays = 30,
  locale,
  onSubmitCharge,
  metadata,
}: {
  channel?: Channel;
  actingUser: UserType | null;
  externalPayerId: string;
  externalPayerType: ExternalPayerType;
  currency: Currency;
  externalEntityType: ExternalEntityType;
  externalEntityId?: string;
  invoiceType: InvoiceType;
  chargeName: string;
  chargeDescription?: string;
  invoiceDueInDays?: number;
  locale: SupportedLocaleEnum;
  onSubmitCharge?: (charge: Charge) => void;
  metadata?: Record<string, any>;
}) {
  const { t } = useTranslation();

  const [charges, setCharges] = useState<Charge[]>([]);
  const [draftCharge, setDraftCharge] = useState<Charge>(EMPTY_CHARGE);
  const [error, setError] = useState<Error>();
  const [validation, setValidation] = useState<string>();
  const [hasChanges, setHasChanges] = useState(false);
  const [loadChargesList, { loading }] = useLazyQuery(listChargesQuery);

  const storageKey = externalEntityId
    ? `charge-manager-${externalEntityId}`
    : `charge-manager-${chargeName}`;

  const getDraftCharge = async () => {
    if (channel) {
      const { data, error } = await loadChargesList({
        variables: {
          listChargesRequest: {
            groupId: convertToUUID(channel._id),
            groupType: GroupType.GroupTypeActivateChannel,
            chargesFilters: {
              externalEntityIds: [externalEntityId],
              externalEntityType,
            },
            isDraft: true,
          },
        },
      });

      if (error) {
        setError(
          new Error(
            'abp.charges.chargeManager.draftCharge.existingDraftChargeError',
            error
          )
        );

        return;
      }

      if (data?.accounts?.listCharges?.charges?.length > 0) {
        if (data.accounts.listCharges.charges[0].items?.length > 0) {
          data.accounts.listCharges.charges[0].items.forEach(
            (item: ProductItem) => {
              item.id = uuid();
            }
          );
        }

        setDraftCharge(data.accounts.listCharges.charges[0]);
      }
    }
  };

  const getCharges = async () => {
    if (channel) {
      const { data, error } = await loadChargesList({
        variables: {
          listChargesRequest: {
            groupId: convertToUUID(channel._id),
            groupType: GroupType.GroupTypeActivateChannel,
            chargesFilters: {
              externalEntityIds: [externalEntityId],
              externalEntityType,
            },
            isDraft: false,
          },
        },
      });

      if (error) {
        setError(
          new Error(
            'abp.charges.chargeManager.draftCharge.getChargesError',
            error
          )
        );

        return;
      }

      if (data?.accounts?.listCharges?.charges?.length > 0) {
        const charges = data.accounts.listCharges.charges as Charge[];

        const storedCharges = await filterStoredCharges(storageKey, charges);

        // combine loaded charges and stored charges
        const allCharges = charges.concat(storedCharges);
        const sortedCharges = [...allCharges].sort(
          (chargeA: Charge, chargeB: Charge) =>
            chargeA.createdAt! > chargeB.createdAt! ? -1 : 1
        ) as Charge[];

        const updatedCharges = sortedCharges.map((charge: Charge) => {
          return {
            ...charge,
            items:
              Array.isArray(charge.items) && charge.items.length > 0
                ? charge.items.map((item: ProductItem) => ({
                    ...item,
                    id: uuid(),
                  }))
                : [],
          };
        });

        setCharges(updatedCharges);
      }
    }
  };

  const updateDraftChargeField = (fieldname: string, value: any) => {
    setDraftCharge(prev => ({
      ...prev,
      [fieldname]: value,
    }));
    setHasChanges(true);
  };

  const addDraftProductItem = (product: AddProductToChargeDetails) => {
    if (channel) {
      const productItem = convertAddedProductToProductItem(
        product,
        channel._id
      );

      productItem.id = uuid();

      const updatedItems = draftCharge.items.concat(productItem);

      updateDraftChargeField('items', updatedItems);
    }
  };

  const updateDraftProductItem = (
    index: number,
    updatedProduct: ProductItem
  ) => {
    const updatedItems = draftCharge.items.map((product, i) => {
      return i === index ? updatedProduct : product;
    });

    updateDraftChargeField('items', updatedItems);
  };

  // delete by index as we could have duplicate items
  const deleteDraftProductItem = (index: number) => {
    const updatedItems = draftCharge.items.filter((_, i) => i !== index);

    updateDraftChargeField('items', updatedItems);
  };

  const voidCharge = async (chargeId: string) => {
    try {
      await getClient().mutate({
        mutation: voidChargeMutation,
        variables: {
          voidChargeRequest: {
            chargeId,
            externalActorId: actingUser?._id,
            externalActorType:
              ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
          },
        },
      });

      // we need to update the status to voided as the projection is not instant
      voidPendingCharge(chargeId);
    } catch (e) {
      setError(
        new Error('abp.charges.chargeManager.draftCharge.voidChargeError', e)
      );
    }
  };

  const createDraftCharge = async () => {
    if (channel && draftCharge && !draftCharge.id) {
      const invoiceDueDate = new Date();

      invoiceDueDate.setDate(invoiceDueDate.getDate() + invoiceDueInDays);

      try {
        const { data } = await getClient().mutate({
          mutation: createChargeMutation,
          variables: {
            createChargeRequest: {
              name: `${chargeName}-draft`,
              description: chargeDescription ?? '',
              amount: getProductItemsTotal(draftCharge.items, locale, currency),
              currency,
              externalPayerId: convertToUUID(externalPayerId),
              externalPayerType,
              groupId: convertToUUID(channel._id),
              groupType: GroupType.GroupTypeActivateChannel,
              externalEntityId,
              externalEntityType,
              externalActorId: convertToUUID(actingUser?._id),
              externalActorType:
                ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
              metadata: JSON.stringify(metadata),
              invoiceType,
              invoiceDueDate,
              items: productItemsToQueryFormat(draftCharge.items),
              isDraft: true,
            },
          },
        });

        if (data) {
          updateDraftChargeField('id', data.accounts.createCharge.chargeId);
          setHasChanges(false);
        }
      } catch (e) {
        setError(
          new Error(
            'abp.charges.chargeManager.draftCharge.createDraftChargeError',
            e
          )
        );
      }
    }
  };

  const submitDraftCharge = async () => {
    if (
      channel &&
      draftCharge &&
      draftCharge.id &&
      draftCharge.items.length > 0
    ) {
      const invoiceDueDate = new Date();

      invoiceDueDate.setDate(invoiceDueDate.getDate() + invoiceDueInDays);

      const numberedChargeName = `${chargeName}-C${charges.length + 1}`;
      const chargeTotal = getProductItemsTotal(
        draftCharge.items,
        locale,
        currency
      );

      try {
        const { data } = await getClient().mutate({
          mutation: createChargeMutation,
          variables: {
            createChargeRequest: {
              name: numberedChargeName,
              description: chargeDescription ?? '',
              amount: chargeTotal,
              currency,
              externalPayerId: convertToUUID(externalPayerId),
              externalPayerType,
              groupId: convertToUUID(channel._id),
              groupType: GroupType.GroupTypeActivateChannel,
              externalEntityId,
              externalEntityType,
              externalActorId: convertToUUID(actingUser?._id),
              externalActorType:
                ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
              metadata: JSON.stringify(metadata),
              invoiceType,
              invoiceDueDate,
              items: productItemsToQueryFormat(draftCharge.items),
              isDraft: false,
            },
          },
        });

        // the new charge cannot be fetched until projection occurs
        // we need to stub it using the existing draft charge data
        if (data) {
          await addPendingCharge(
            data.accounts?.createCharge?.chargeId,
            numberedChargeName,
            chargeTotal
          );

          if (onSubmitCharge) {
            // pass the fake charge so consumers can do whatever with the data
            onSubmitCharge({
              ...draftCharge,
              id: data.accounts?.createCharge?.chargeId,
              name: chargeName,
              status: 'PENDING',
              currency,
              amount: chargeTotal,
            });
          }
        }

        // cleanup draft charge
        await deleteDraftCharge();
      } catch (e) {
        setError(
          new Error(
            'abp.charges.chargeManager.draftCharge.createChargeError',
            e
          )
        );
      }
    }
  };

  const voidPendingCharge = (chargeId: string) => {
    setCharges(prev =>
      prev.map((charge: Charge) => {
        if (charge.id === chargeId) {
          return {
            ...charge,
            status: 'VOIDED',
          };
        }

        return charge;
      })
    );
  };

  const addPendingCharge = async (
    chargeId: string,
    numberedChargeName: string,
    chargeTotal: number
  ) => {
    const pendingCharge = {
      ...draftCharge,
      id: `charge_${chargeId}`,
      name: numberedChargeName,
      status: 'PENDING',
      currency,
      amount: chargeTotal,
      createdAt: new Date(),
    };

    // persist submitted charges as they don't appear in list charges immediately
    await appendStoredCharge(storageKey, pendingCharge);

    setCharges(prev => [pendingCharge, ...prev]);
  };

  const deleteDraftCharge = async () => {
    if (channel && draftCharge && draftCharge.id) {
      try {
        await getClient().mutate({
          mutation: deleteDraftChargeMutation,
          variables: {
            deleteDraftChargeRequest: {
              id: draftCharge.id,
            },
          },
        });
        setDraftCharge(EMPTY_CHARGE);
      } catch (e) {
        setError(
          new Error(
            'abp.charges.chargeManager.draftCharge.deleteDraftChargeError',
            e
          )
        );
      }
    }
  };

  const updateDraftCharge = async () => {
    const hasInvalid = draftCharge.items.some(item => item.quantity === 0);

    if (channel && draftCharge && draftCharge.id && !hasInvalid && hasChanges) {
      try {
        await getClient().mutate({
          mutation: updateDraftChargeMutation,
          variables: {
            updateDraftChargeRequest: {
              chargeId: draftCharge.id,
              amount: getProductItemsTotal(draftCharge.items, locale, currency),
              externalActorId: convertToUUID(actingUser?._id),
              externalActorType:
                ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
              items: productItemsToQueryFormat(draftCharge.items),
            },
          },
        });
      } catch (e) {
        setError(
          new Error(
            'abp.charges.chargeManager.draftCharge.updateDraftChargeError',
            e
          )
        );
      }
    }
  };

  useEffect(() => {
    if (channel) {
      getDraftCharge();
      getCharges();
    }
  }, [channel, externalEntityId]);

  useEffect(() => {
    if (draftCharge && draftCharge.items.some(item => item.quantity === 0)) {
      setValidation(t('abp.charges.chargeManager.draft.emptyQuantityError'));
    } else {
      setValidation(undefined);
    }

    if (!draftCharge.id && draftCharge.items.length > 0) {
      createDraftCharge();
    }
  }, [draftCharge, draftCharge.items]);

  useEffect(() => {
    if (draftCharge.id && draftCharge.items.length > 0 && hasChanges) {
      updateDraftCharge();
    } else if (draftCharge.id && draftCharge.items.length === 0 && hasChanges) {
      // delete the draft charge if it is empty to prevent draft charges from accumulating
      deleteDraftCharge();
    }
  }, [draftCharge.items, hasChanges, validation]);

  return {
    charges,
    draftCharge,
    addDraftProductItem,
    updateDraftProductItem,
    deleteDraftProductItem,
    submitDraftCharge,
    voidCharge,
    error,
    validation,
    loading,
  };
}
