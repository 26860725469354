// Permits importing from modules outside enforced boundaries
import React, { useState } from 'react';

import { H3 } from 'components/typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PERMISSION_KEYS } from 'constants-permissions';
import { routes } from 'lane-shared/config';
import { exportCSV } from '../../../helpers';

import { Table, PageSizeType } from 'design-system-web';

import styles from './styles.scss';
import { Channel } from 'packages/lane-shared/types/ChannelType';

export function HardwarePinCodes({
  channel,
  hasAnyPermission,
}: {
  channel: Channel;
  hasAnyPermission: (hasAnyPermission: string[]) => Boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const isAdminOrSuperUser = hasAnyPermission([
    PERMISSION_KEYS.PERMISSION_ADMIN,
  ]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<PageSizeType>(50);

  const columns = [
    {
      header: t('web.admin.hardware.management.hardware.pin.column.assignedTo'),
      key: 'assignTo',
      renderCell: (cell: string) => {
        return <span className={styles.numberOfCharacters}>{cell}</span>;
      },
    },
    {
      header: t('web.admin.hardware.management.hardware.pin.column.expires'),
      key: 'expiresAt',
      renderCell: (cell: string) => {
        return <span className={styles.numberOfCharacters}>{cell}</span>;
      },
    },
    {
      header: t('web.admin.hardware.management.hardware.pin.column.createdBy'),
      key: 'createdAt',
      renderCell: (cell: string) => {
        return <span className={styles.numberOfCharacters}>{cell}</span>;
      },
    },
    {
      header: t('web.admin.hardware.management.hardware.pin.column.lastUsed'),
      key: 'lastUsedAt',
      renderCell: (cell: string) => {
        return <span className={styles.numberOfCharacters}>{cell}</span>;
      },
    },
  ];

  const rowActions = [
    {
      label: t(
        'web.admin.hardware.management.hardware.pin.column.action.expirePin'
      ),
      onClick: () => {},
    },
  ];

  const totalRows = 0;

  const exportAll = () => {
    exportCSV(
      [],
      columns.filter(column => column.key !== 'unlock'),
      `${t`web.admin.serviceRequest.equipment.csvName`}.csv`
    );
  };

  const exportCurrentPage = (currentPageData: any) => {
    exportCSV(
      currentPageData || [],
      columns.filter(column => column.key !== 'unlock'),
      `${t`web.admin.serviceRequest.equipment.csvName`}.csv`
    );
  };

  const exportOptions = [
    {
      label: t('web.admin.serviceRequest.exportPage'),
      onClick: exportCurrentPage,
    },
    { label: t('web.admin.serviceRequest.exportAll'), onClick: exportAll },
  ];

  return (
    <div className={styles.PinCode}>
      <div className={styles.pinCodeListTitle}>
        <H3>{t('web.admin.hardware.management.hardware.pin.list.title')}</H3>
      </div>
      <div>
        <Table
          columns={columns}
          data={[]}
          {...(isAdminOrSuperUser ||
          hasAnyPermission([
            PERMISSION_KEYS.PERMISSION_PIN_MANAGEMENT_CREATE_EDIT_DELETE,
          ])
            ? {
                onPrimaryAction: () =>
                  history.push(
                    routes.channelVMAddPinCodes.replace(':id', channel.slug)
                  ),
                primaryActionLabel: t(
                  'web.admin.hardware.management.hardware.pin.button.createPinCodes'
                ),
                exportOptions,
                showColumnVisibility: true,
                rowActions,
                hasKeywordFilter: true,
              }
            : {})}
          pagination="client"
          totalRows={totalRows}
          pageSize={pageSize}
          page={page}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
        />
      </div>
    </div>
  );
}
