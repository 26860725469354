import React, { useContext } from 'react';

import { colors } from 'constants-colors';
import { WHITELABEL_LANE } from 'lane-shared/config/whitelabels';
import { ThemeContext } from 'lane-shared/contexts';
import AppContext from 'lane-shared/contexts/AppContext';

import ActivateLogo from 'components/activate/ActivateLogo';

import styles from './PoweredBy.scss';

export default function PoweredBy() {
  const theme = useContext(ThemeContext);
  const { whitelabel } = useContext(AppContext);

  if (theme.instance === WHITELABEL_LANE) {
    return null;
  }

  return (
    <div className={styles.PoweredBy}>
      <a
        href="https://www.vts.com/vts-activate"
        target="_blank"
        rel="noopener noreferrer"
        title={`Powered by ${whitelabel.shortOperatingName}`}
      >
        <span>Powered by</span>
        <ActivateLogo className={styles.logo} color={colors.darkCharcoal} />
      </a>
    </div>
  );
}
