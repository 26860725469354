import React from 'react';

import { Route } from 'react-router-dom';

import { hasPermission } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { PERMISSION_KEYS } from 'constants-permissions';
import { SIDE_BAR_LINKS_ORDER } from 'lane-shared/helpers/constants/sidebarLinksOrder';
import i18n from 'localization';
import { UserType } from 'lane-shared/types/User';

import {
  UserAccessPageConfig,
  AccessControlPage,
  AccessControlGroupsPageConfig,
  AccessControlGroupDetailsPageConfig,
  ManageUserAccessPageConfig,
  NewManageUserAccessPageConfig,
  AccessConfigurationPageConfig,
} from './pageConfig';
import { SidebarLinkType } from 'hooks/useChannelSidebarLinks';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'constants-flags';

const hasAnyPermission = (
  user: UserType,
  permissions: string[],
  channelId: string
) => {
  return (
    user.isSuperUser ||
    (channelId && hasPermission(user.roles, permissions, channelId))
  );
};

const hasAccessConfigurationPermission = (
  user: UserType,
  channelId: string
) => {
  return (
    user.isSuperUser ||
    (channelId &&
      hasPermission(
        user.roles,
        [
          PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_VIEW,
          PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_VIEW,
        ],
        channelId
      ))
  );
};

export const AccessControlRoutes = ({
  urlPath,
  channel,
  user,
}: {
  urlPath: string;
  channel: any;
  user: UserType;
}): JSX.Element | null => {
  const hasAccessControlPageAccess = hasAnyPermission(
    user,
    [
      PERMISSION_KEYS.PERMISSION_ADMIN,
      PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL,
    ],
    channel?._id
  );

  const newManageUserAccessPage = useFlag(
    FeatureFlag.NewManageUserAccessPage,
    false
  );

  const accessConfigurationPageFlag = useFlag(
    FeatureFlag.AccessConfigurationPage,
    false
  );

  const accessConfigurationDefaultAccessFlag = useFlag(
    FeatureFlag.AccessConfigurationPageDefaultAccess,
    false
  );

  const entries: [string, AccessControlPage][] = [];

  if (hasAccessControlPageAccess) {
    entries.push(
      [UserAccessPageConfig.key, UserAccessPageConfig],
      newManageUserAccessPage
        ? [NewManageUserAccessPageConfig.key, NewManageUserAccessPageConfig]
        : [ManageUserAccessPageConfig.key, ManageUserAccessPageConfig],
      [AccessControlGroupsPageConfig.key, AccessControlGroupsPageConfig],
      [
        AccessControlGroupDetailsPageConfig.key,
        AccessControlGroupDetailsPageConfig,
      ]
    );

    const hasAccessConfigurationViewPermission =
      hasAccessConfigurationPermission(user, channel?._id);

    if (
      (accessConfigurationPageFlag || accessConfigurationDefaultAccessFlag) &&
      hasAccessConfigurationViewPermission
    ) {
      entries.push([
        AccessConfigurationPageConfig.key,
        AccessConfigurationPageConfig,
      ]);
    }
  }

  return (
    <>
      {entries.map(([key, { Page, path }]) => {
        return (
          <Route key={key} exact path={`${urlPath}/${path}`}>
            <Page
              channel={channel}
              hasAnyPermission={(permissions: string[]): boolean => {
                return (
                  channel?._id &&
                  hasPermission(user.roles, permissions, channel?._id)
                );
              }}
            />
          </Route>
        );
      })}
    </>
  );
};

interface AccessControlNavigationFeatureFlags {
  userAccessFlag?: boolean | undefined;
  accessConfigurationFlag?: boolean | undefined;
  accessConfigurationDefaultAccessFlag?: boolean | undefined;
}

const buildLinks = (
  url: string,
  channelId: string,
  user: UserType,
  featureFlags: AccessControlNavigationFeatureFlags
) => {
  const links: SidebarLinkType[] = [];

  const hasAccessControlPageAccess = hasAnyPermission(
    user,
    [
      PERMISSION_KEYS.PERMISSION_ADMIN,
      PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL,
    ],
    channelId
  );

  const hasAccessConfigurationViewPermission = hasAccessConfigurationPermission(
    user,
    channelId
  );

  if (hasAccessControlPageAccess) {
    links.push({
      exact: false,
      route: `${url}/${UserAccessPageConfig.path}`,
      name: UserAccessPageConfig.name(),
      headerMessage: UserAccessPageConfig.headerMessage(),
      order: UserAccessPageConfig.order,
    });
  }

  if (hasAccessControlPageAccess) {
    links.push({
      exact: false,
      route: `${url}/${AccessControlGroupsPageConfig.path}`,
      name: AccessControlGroupsPageConfig.name(),
      headerMessage: AccessControlGroupsPageConfig.headerMessage(),
      order: AccessControlGroupsPageConfig.order,
    });
  }

  if (
    hasAccessControlPageAccess &&
    (featureFlags.accessConfigurationFlag ||
      featureFlags.accessConfigurationDefaultAccessFlag) &&
    hasAccessConfigurationViewPermission
  ) {
    links.push({
      exact: false,
      route: `${url}/${AccessConfigurationPageConfig.path}`,
      name: AccessConfigurationPageConfig.name(),
      headerMessage: AccessConfigurationPageConfig.headerMessage(),
      order: AccessConfigurationPageConfig.order,
    });
  }

  return links;
};

export const accessControlNavigation = (
  url: string,
  channelId: string,
  user: UserType,
  featureFlags: AccessControlNavigationFeatureFlags
) => {
  return {
    icon: 'lock-open',
    iconSet: ICON_SET_FONTAWESOME,
    name: i18n.t('web.admin.accessControl.header'),
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.ACCESS_CONTROL,
    links: buildLinks(url, channelId, user, featureFlags),
  };
};
