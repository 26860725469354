import { ContentTypeEnum, FeatureNameEnum } from 'constants-content';

import { permissions } from 'constants-permissions';

import {
  FeatureDefinition,
  ContentTypeRequirementEnum,
} from '../../../types/FeatureDefinition';

import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';

const UseCompanyPermissions: FeatureDefinition = {
  name: FeatureNameEnum.UseCompanyPermissions,
  friendlyName: 'web.renderers.feature.useCompanyPermissions.friendlyName',
  requiresInteraction: true,
  description: 'web.renderers.feature.useCompanyPermissions.description',
  properties: {
    useContentCategories: {
      type: 'Boolean',
      friendlyName:
        'web.renderers.feature.useCompanyPermissions.properties.useContentCategories.friendlyName',
      description:
        'web.renderers.feature.useCompanyPermissions.properties.useContentCategories.description',
      default: false,
      isArray: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    permissions: {
      type: 'String',
      friendlyName:
        'web.renderers.feature.useCompanyPermissions.properties.permissions.friendlyName',
      description:
        'web.renderers.feature.useCompanyPermissions.properties.permissions.description',
      default: [],
      isArray: true,
      validators: [
        {
          name: 'ArrayMin',
          value: 0,
        },
        {
          name: 'ArrayMax',
          value: 10,
        },
        {
          name: 'In',
          value: permissions.map(permission => permission.key),
        },
      ],
    },
  },
  interactionData: {
    company: {
      friendlyName:
        'web.renderers.feature.useCompanyPermissions.interactionData.company.friendlyName',
      type: 'Channel',
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
  },
  contentTypeRequirements: [
    {
      type: ContentTypeEnum.VisitorManagement,
      requirement: ContentTypeRequirementEnum.Required,
    },
  ],
};

export default UseCompanyPermissions;
