import { Channel } from '../../../types/ChannelType';
import { addressCollectables } from './address.collectable';

export const channelCollectables = (channel?: Partial<Channel>) => {
  if (!channel) return null;

  return {
    name: channel.name,
    id: channel._id || (channel as any).id,
    type: channel.type,
    isSub: channel.isSub,
    isParent: !!channel.parentId,
    parentName: channel.parent?.name,
    location: addressCollectables(channel.address),
  };
};
