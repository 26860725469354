import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { SIMPLE_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';
import { IntegrationProviderEnum } from 'constants-integrations';

import styles from './ThreeTenServRequestCard.scss';

export default function ThreeTenServRequestCard({
  className,
  style,
  interaction,
}: any) {
  const request = interaction.data[`_${IntegrationProviderEnum.ThreeTenServ}`];
  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.ThreeTenServRequestCard, className)}
      style={style}
    >
      <h1>310-SERV</h1>
      <p>
        <label>{t('Submitted')}:</label>
        <span>{dateFormatter(interaction._created, SIMPLE_DATE_TIME)}</span>
      </p>
      <p>
        <label>{t('Request Category')}:</label>
        <span>{request.category}</span>
      </p>
      <p>
        <label>{t('Request Type')}:</label>
        <span>{request.type}</span>
      </p>
      <p>
        <label>{t('Description')}:</label>
        <span>{request.description}</span>
      </p>
    </div>
  );
}
