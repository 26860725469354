import { useContext, useMemo } from 'react';

import { UserDataContext } from 'lane-shared/contexts';
import { BlockRenderInterface } from 'lane-shared/types/blocks/BlockInterface';
import { DependencyEnum } from 'lane-shared/types/blocks/DependencyType';
import { IntegrationProviderEnum } from 'constants-integrations';

import useCurrentChannelIntegrations from './useCurrentChannelIntegrations';

export default function useFilterBlockDependencies(
  blocks: BlockRenderInterface[]
): BlockRenderInterface[] {
  const channelIntegrations = useCurrentChannelIntegrations();
  const { user } = useContext(UserDataContext);

  return useMemo(
    () =>
      blocks.filter(block => {
        // has no dependencies so it must be available.
        if (!block.dependencies) {
          return true;
        }

        return Object.entries(block.dependencies).every(
          ([dependency, value]) => {
            switch (dependency) {
              case DependencyEnum.channelIntegrations:
                return (value as IntegrationProviderEnum[])?.some(
                  integrationProvider =>
                    channelIntegrations.some(
                      channelIntegration =>
                        channelIntegration.integration?.name ===
                        integrationProvider
                    )
                );
              case DependencyEnum.isSuperUser:
                return (
                  (value as boolean) !== true || Boolean(user?.isSuperUser)
                );
              default:
                return true;
            }
          }
        );
      }),
    [blocks, channelIntegrations, user?.isSuperUser]
  );
}
