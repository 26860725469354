import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { ANGUS_ORDER_STATUS_LIST } from 'constants-integrations';
import getAngusWorkOrderFromInteractionData from 'lane-shared/helpers/integrations/AngusServiceRequests/getAngusWorkOrderFromInteractionData';
import { useContentInteractionsQuery } from 'lane-shared/hooks';

import WelcomeAngus from 'static/img/angus-welcome.png';

import Button from '../../../general/Button';
import Image from '../../../general/Image';
import AngusUserIntegration from './AngusUserIntegration';
import WorkOrderList from './WorkOrderList';

import styles from './OpenRequests.scss';

function OpenRequests({ content, userIntegration, onLogout }: any) {
  const { t } = useTranslation();
  const [search] = useState({
    sortBy: {
      key: '_updated',
      dir: 'desc',
    },
    content: {
      _id: content._id,
    },
  });

  const [pagination] = useState({
    start: 0,
    perPage: 25,
  });

  const [interactions, , loading] = useContentInteractionsQuery({
    search,
    pagination,
  });

  if ((interactions as any).length === 0 && !loading) {
    return (
      <div className={styles.container}>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'alt' is missing in type '{ src: any; cla... Remove this comment to see the full error message */}
        <Image src={WelcomeAngus} className={styles.image} />
        <h1>{content.name}</h1>
        <div className={styles.line} />
        <h2>{content.description}</h2>
        <Link
          to={{
            pathname: routes.contentInteractionNew.replace(':id', content._id),
          }}
        >
          <Button
            className={styles.button}
            loading={loading}
            variant="contained"
          >
            {t('Create new order')}
          </Button>
        </Link>
        <AngusUserIntegration
          userIntegration={userIntegration}
          content={content}
          onLogout={onLogout}
        />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.buttonContainer}>
        <Link
          to={{
            pathname: routes.contentInteractions.replace(':id', content._id),
          }}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | ContentInteractionsQueryInteractio... Remove this comment to see the full error message */}
          <Button loading={loading} color="primary">
            {t('Order history')}
          </Button>
        </Link>
        <Link
          to={{
            pathname: routes.contentInteractionNew.replace(':id', content._id),
          }}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | ContentInteractionsQueryInteractio... Remove this comment to see the full error message */}
          <Button loading={loading} variant="contained">
            {t('Create new order')}
          </Button>
        </Link>
      </div>
      {ANGUS_ORDER_STATUS_LIST.map(status => (
        <WorkOrderList
          key={status}
          sectionTitle={status}
          interactions={(interactions as any).filter(
            (interaction: any) =>
              getAngusWorkOrderFromInteractionData(interaction.data)?.status ===
              status
          )}
        />
      ))}
      <AngusUserIntegration
        userIntegration={userIntegration}
        content={content}
        onLogout={onLogout}
      />
    </div>
  );
}

export default OpenRequests;
