import { colors } from 'constants-colors';

const chartColors: ChartColorType[] = [
  { base: colors.secondary, start: colors.secondary, stop: colors.darkBlue },
  { base: colors.primary },
  { base: colors.lightOrange },
  { base: colors.secondary, start: colors.secondary, stop: colors.darkBlue },
];

export type ChartColorType = {
  base: string;
  start?: string;
  stop?: string;
};

export default chartColors;
