import { UserType } from 'lane-shared/types/User';
import { UserLoginStatusEnum } from 'constants-user';

export const fillPartialUser = (partial: Partial<UserType> | null) => {
  if (!partial) {
    throw new Error('User not found');
  }

  const primaryLogin = partial?.logins?.find(
    login => login.isPrimary && login.status === UserLoginStatusEnum.Verified
  );

  if (!primaryLogin) {
    throw new Error(`No primary login found for user ${partial?._id}`);
  }

  return {
    ...partial,
    _id: partial?._id ?? '',
    name: partial?.name ?? 'VTS Activate - Setup Required',
    profile: {
      name: partial?.profile?.name ?? 'VTS Activate - Setup Required',
      email: primaryLogin.key,
    },
  } as UserType;
};
