import React, {
  MouseEvent as ReactMouseEvent,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Link, useHistory } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { AnalyticsContext, UserDataContext } from 'lane-shared/contexts';
import ObjectTouchHelper from 'lane-shared/helpers/ObjectTouchHelper';
import { ANALYTIC_KEYS } from 'constants-analytics';
import useHasMetRequirements from 'lane-shared/hooks/features/useHasMetRequirements';
import { ContentType } from 'lane-shared/types/content/Content';
import { IntegrationProviderEnum } from 'constants-integrations';

import RequirementsModal from 'components/renderers/v5/primitives/RequirementsBlock/RequirementsModal';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content: ContentType;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

/**
 * Helpful wrapper for opening Content on the client side.  Some content
 * may open up in an InApp or external link.
 */
export default function ContentLink({
  className,
  style,
  content,
  children,
  onClick,
}: Props) {
  const analytics = useContext(AnalyticsContext);
  const { user } = useContext(UserDataContext);
  const history = useHistory();
  const [isRequirementsModalOpen, setIsRequirementsModalOpen] =
    useState<boolean>(false);

  const hasShopifyIntegration = Boolean(
    content?.integration?.integration?.name ===
      IntegrationProviderEnum.ShopifyMultipass
  );

  const { allRequirementsMet, shouldShowRequirementsFlow } =
    useHasMetRequirements({
      content,
      user,
    });

  function onClickHandler(
    event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    if (shouldShowRequirementsFlow) {
      event.preventDefault();
      setIsRequirementsModalOpen(true);

      return;
    }

    if (onClick) {
      onClick(event);
    }

    if (content?.externalUrl || content?.deepLink) {
      captureAnalytics();
    }
  }

  function captureAnalytics() {
    // capture details event for any external link.
    ObjectTouchHelper.touch(
      content,
      ObjectTouchHelper.mapAction('details'),
      'Content'
    );

    analytics.track(ANALYTIC_KEYS.ANALYTIC_CONTENT_DETAILS, {
      contentId: content?._id,
      channelId: content?.channel?._id,
    });
  }

  function determineDestinationUrl(content: ContentType) {
    if (content?.signedExternalUrl && !hasShopifyIntegration) {
      return content!.signedExternalUrl;
    }

    if (content?.externalUrl?.url && !hasShopifyIntegration) {
      return content.externalUrl!.url;
    }

    // default to app store on browser
    if (content?.deepLink) {
      return content.deepLink.appStoreUrl;
    }

    return routes.content.replace(':id', content?._id);
  }

  const isExternalUrl =
    Boolean(content?.signedExternalUrl) || Boolean(content?.externalUrl?.url);
  const isDeepLink = Boolean(content?.externalUrl?.url || content?.deepLink);

  const target = isExternalUrl || isDeepLink ? '_blank' : undefined;
  const destinationUrl = determineDestinationUrl(content);

  function routeToDestination() {
    if (isExternalUrl || isDeepLink) {
      window.open(destinationUrl);
    } else {
      history.push(destinationUrl);
    }
  }

  useEffect(() => {
    if (isRequirementsModalOpen && allRequirementsMet) {
      setIsRequirementsModalOpen(false);
      routeToDestination();
    }
  }, [allRequirementsMet, isRequirementsModalOpen]);

  return (
    <>
      {(isExternalUrl && !hasShopifyIntegration) || isDeepLink ? (
        <a
          className={className}
          style={style}
          href={destinationUrl}
          title={content?.name}
          target={target}
          onClick={onClickHandler}
        >
          {children}
        </a>
      ) : (
        <Link
          onClick={event => onClickHandler(event)}
          className={className}
          style={style}
          to={destinationUrl}
          title={content?.name}
        >
          {children}
        </Link>
      )}
      {shouldShowRequirementsFlow ? (
        <RequirementsModal
          key={content?._id}
          content={content}
          isOpen={isRequirementsModalOpen}
          onClose={() => setIsRequirementsModalOpen(false)}
        />
      ) : null}
    </>
  );
}
