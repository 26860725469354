import React, { useState } from 'react';

import { AdminPage, TabStrip } from 'components';
import { useTranslation } from 'react-i18next';

import { ChannelExperienceTypeEnum } from 'constants-channel';

import { H3 } from 'components/typography';

import { useCompaniesForChannel, usePendingInvitesCount } from './hooks';
import { AllUsersView, PendingInvitesView } from './view/index';

import styles from './styles.scss';
import { useNewChannelInvitesApi } from 'lane-shared/hooks';
import { PendingInvitesV2 } from './view/PendingInvitesV2';
import { useQuery } from '@apollo/client';
import { getChannelGroupRolesLimitedInfo } from 'lane-shared/graphql/query';
import { useHistory } from 'react-router-dom';
import { Channel } from 'lane-shared/types/ChannelType';

type Props = {
  channel: Channel;
};

export enum UsersPageTabEnum {
  TAB_ACTIVE = 'active',
  TAB_Pending_Invites = 'pending-invites',
}

export function UserManagementUsersPage({ channel }: Props) {
  const isMFExperience =
    channel.experienceType === ChannelExperienceTypeEnum.multifamily;

  const { t } = useTranslation();

  const { data: groupRoles } = useQuery(getChannelGroupRolesLimitedInfo, {
    variables: {
      id: channel._id,
    },
  });

  const { companyOptions } = useCompaniesForChannel(channel._id);

  const { count: pendingInvitesCount, refetch: refetchCount } =
    usePendingInvitesCount(channel?._id);

  const tabs = [
    {
      label: t(
        'web.admin.channel.userManagement.users.view.tabActivity.active'
      ),
      value: UsersPageTabEnum.TAB_ACTIVE,
    },
    {
      label: t(
        'web.admin.channel.userManagement.users.view.tabActivity.pendingInvites',
        { pendingInvitesCount }
      ),
      value: UsersPageTabEnum.TAB_Pending_Invites,
    },
  ];

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const tabFromUrlParam = params.get('tab');
  const preSelectedTab =
    tabs.find(tab => tab.value === tabFromUrlParam) || tabs[0];

  const [selectedTab, setSelectedTab] = useState(preSelectedTab);

  const isNewChannelInvitesApiEnabled = useNewChannelInvitesApi();

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.channelUsers}>
        <div className={styles.header}>
          <div className={styles.subheader}>
            <H3>{t('web.pages.portal.admin.users.index.header')}</H3>
          </div>
          <TabStrip
            tabs={tabs}
            selected={selectedTab}
            onSelectTab={tab => {
              const newUrl = `?tab=${tab.value}`;

              setSelectedTab({
                label: tab.label,
                value: tab.value as UsersPageTabEnum,
              });

              history.replace(newUrl, history?.location?.state);
            }}
            className={styles.tabStrip}
            skipLabelTranslation
            fullWidth
          />
          {selectedTab.value === UsersPageTabEnum.TAB_ACTIVE && (
            <AllUsersView
              groupRoles={groupRoles?.channel?.groupRoles}
              companies={companyOptions
                .filter(company => company.name != null)
                .map(company => ({
                  _id: String(company._id),
                  name: String(company.name),
                }))}
              channelId={channel._id}
              channelSlug={channel.slug}
              isMFExperience={isMFExperience}
            />
          )}

          {selectedTab.value === UsersPageTabEnum.TAB_Pending_Invites &&
            (isNewChannelInvitesApiEnabled ? (
              <PendingInvitesV2
                refetchCount={refetchCount}
                channelId={channel._id}
                companies={companyOptions
                  .filter(company => company.name != null)
                  .map(company => ({
                    _id: String(company._id),
                    name: String(company.name),
                    inviteOnly: company.inviteOnly!,
                    hasFilters: company.hasFilters!,
                  }))}
                groupRoles={groupRoles?.channel?.groupRoles}
                channelSlug={channel?.slug || ''}
                isMFExperience={isMFExperience}
              />
            ) : (
              <PendingInvitesView
                refetchCount={refetchCount}
                channelId={channel._id}
                groupRoles={groupRoles?.channel?.groupRoles.map(
                  (g: { name: string }) => g.name
                )}
                channelSlug={channel?.slug || ''}
                isMFExperience={isMFExperience}
              />
            ))}
        </div>
      </div>
    </AdminPage>
  );
}
