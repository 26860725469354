import * as yup from 'yup';

import {
  ContentCategoryEnum,
  ContentTypeEnum,
  ContentGroupByEnum,
  SectionTypeEnum,
} from 'constants-content';

export default yup.object().shape({
  _id: yup.string().required(),
  name: yup.string().trim(),
  description: yup.string().trim(),
  type: yup.string().oneOf(Object.values(SectionTypeEnum)),
  query: yup
    .object({
      contentCategories: yup.array().of(
        yup.string().oneOf(Object.values(ContentCategoryEnum))
        // .notOneOf([ContentCategoryEnum.None])
      ),
      contentTypes: yup.array(
        yup.string().oneOf(Object.values(ContentTypeEnum))
      ),
      includeChildren: yup.boolean().default(false),
    })
    .nullable()
    .notRequired(),
  object: yup.string(),
  renderType: yup.string(),
  tags: yup.array().of(yup.string()).min(0).max(6),
  backgroundImage: yup.string().nullable(),
  logo: yup.string().nullable(),
  color: yup.string(),
  backgroundColor: yup.string(),
  filters: yup.array().of(yup.string()),
  sorts: yup.array().of(yup.string()),
  groupBy: yup.string().nullable().oneOf(Object.values(ContentGroupByEnum)),
});
