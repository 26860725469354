export enum IntegrationHooksEnum {
  AfterCreateChannelIntegration = 'afterCreateChannelIntegration',
  AfterUpdateChannelIntegration = 'afterUpdateChannelIntegration',
  BeforeCreateChannelIntegration = 'beforeCreateChannelIntegration',
  BeforeUpdateChannelIntegration = 'beforeUpdateChannelIntegration',
  BeforeAddUserGroupRole = 'beforeAddUserGroupRole',
  AfterAddUserGroupRole = 'afterAddUserGroupRole',
  BeforeRemoveUserGroupRole = 'beforeRemoveUserGroupRole',
  AfterRemoveUserGroupRole = 'afterRemoveUserGroupRole',
  BeforeChannelCreated = 'beforeChannelCreated',
  AfterChannelCreated = 'afterChannelCreated',
  BeforeChannelUpdated = 'beforeChannelUpdated',
  AfterChannelUpdated = 'afterChannelUpdated',
  BeforeChannelDeleted = 'beforeChannelDeleted',
  AfterChannelDeleted = 'afterChannelDeleted',
  BeforeUserUpdated = 'beforeUserUpdated',
  AfterUserUpdated = 'afterUserUpdated',
  BeforeUserCreated = 'beforeUserCreated',
  AfterUserCreated = 'afterUserCreated',
  BeforeDraftCreated = 'beforeDraftCreated',
  AfterDraftCreated = 'afterDraftCreated',
  BeforeDraftUpdated = 'beforeDraftUpdated',
  AfterDraftUpdated = 'afterDraftUpdated',
  BeforeDraftPublished = 'beforeDraftPublished',
  AfterDraftPublished = 'afterDraftPublished',
  BeforeDraftUnPublished = 'beforeDraftUnPublished',
  AfterDraftUnPublished = 'afterDraftUnPublished',
  BeforeCreateUserContentInteraction = 'beforeCreateUserContentInteraction',
  BeforeUpdateUserContentInteraction = 'beforeUpdateUserContentInteraction',
  UpdateUserContentInteraction = 'updateUserContentInteraction',
  AfterUpdateUserContentInteraction = 'afterUpdateUserContentInteraction',
  ValidateUserContentInteraction = 'validateUserContentInteraction',
  BeforeGetIntegration = 'beforeGetIntegration',
  BeforeCreateIntegration = 'beforeCreateIntegration',
  BeforeUpdateIntegration = 'beforeUpdateIntegration',
}
