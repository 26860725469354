import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { Button, StandardContentCard } from 'components';
import { useTranslation } from 'react-i18next';

import { spacing } from 'lane-shared/config';
import { colors } from 'constants-colors';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import {
  BurstDraftInteractionData,
  ShopifyBurstInteractionType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import SelectableCardWrapper from 'components/cards/SelectableCardWrapper';
import { Box, Flex } from 'components/layout';
import StandardContentCardSkeleton from 'components/loading/StandardContentCardSkeleton';
import { H5, L, M } from 'components/typography';

import RoomBookingModal from '../../RoomBookingModal';
import handleSave from './helpers/saveRoomHelper';
import useSortedSectionContent from './hooks/useSortedSectionContent';
import { useModalContext } from 'contexts/ModalContext';

import styles from './BurstEditRoom.scss';

type Props = {
  burstData: ShopifyBurstInteractionType;
  onDataUpdated: (data: BurstDraftInteractionData) => void;
  onClose: () => void;
};

export default function BurstEditRoom({
  onDataUpdated,
  burstData,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const { burstIntegrationSettings } = useShopifyBurstContext();
  const [selectedRoom, setSelectedRoom] = useState();
  const { showModal, closeModal } = useModalContext();

  function onSave() {
    if (selectedRoom)
      handleSave({
        data: burstData,
        content: selectedRoom,
        onDataUpdated,
        onClose,
      });
  }

  const { sortedSectionContent, loading } = useSortedSectionContent({
    channelId: burstData?.port?._id,
    sectionId: burstIntegrationSettings?.roomBookingSection?._id,
    dateRange: burstData.dateRange,
  });

  function handleRoomOpenPreview(content: any) {
    showModal(
      <RoomBookingModal
        onClose={closeModal}
        contentId={content._id}
        handleRoomSelect={setSelectedRoom}
      />
    );
  }

  return (
    <Box className={styles.container}>
      <Flex direction="column" mb={2} className={styles.header}>
        <H5 mb={2}>{t('Which team room best suits your burst?')}</H5>
        <M mb={2} variant="secondary">
          {t(
            'Please choose a room that best fits the number of attendees without going over the max capacity.'
          )}
        </M>
      </Flex>
      <Flex gap={4} mb={4} className={styles.list}>
        {loading && <StandardContentCardSkeleton count={3} />}
        {sortedSectionContent.map(content => (
          <SelectableCardWrapper
            key={content?._id}
            onClick={() => handleRoomOpenPreview(content)}
            isSelected={content._id === (selectedRoom as any)?._id}
          >
            <StandardContentCard content={content} />
          </SelectableCardWrapper>
        ))}
        {sortedSectionContent.length === 0 && !loading && (
          <Flex
            p={4}
            direction="column"
            justify="center"
            align="center"
            style={{ width: '100%' }}
          >
            <Icon
              name="calendar-exclamation"
              set="FontAwesome"
              type="far"
              style={{
                margin: spacing[4],
                color: colors.charcoal[6],
              }}
              size="large"
            />
            <L variant="secondary">
              {t('There are no available rooms on selected port and dates.')}
            </L>
          </Flex>
        )}
      </Flex>
      {sortedSectionContent.length !== 0 ? (
        <>
          <hr />
          <Box width="full" className={styles.buttons}>
            <Button variant="outlined" onClick={onClose}>
              {t('Close')}
            </Button>
            <Button
              variant="contained"
              disabled={!selectedRoom}
              onClick={onSave}
            >
              {t('Save')}
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );
}
