import {
  ActiveChannelTypeEnum,
  ChannelType,
  ChannelExperienceTypeEnum,
  DeprecatedChannelTypeEnum,
} from 'constants-channel';

import {
  ChannelInput,
  ChannelType as GraphChannelType,
  ChannelExperienceTypeEnum as GraphChannelExperienceTypeEnum,
  AddressInput,
  ProfileInput,
} from 'graphql-query-contracts';

import { Channel } from '../../types/ChannelType';

const GraphChannelTypeMap: Readonly<Record<ChannelType, GraphChannelType>> =
  Object.freeze({
    [DeprecatedChannelTypeEnum.Charity]: GraphChannelType.Charity,
    [ActiveChannelTypeEnum.Company]: GraphChannelType.Company,
    [DeprecatedChannelTypeEnum.Curated]: GraphChannelType.Curated,
    [ActiveChannelTypeEnum.Entertainment]: GraphChannelType.Entertainment,
    [DeprecatedChannelTypeEnum.Meta]: GraphChannelType.Meta,
    [DeprecatedChannelTypeEnum.Professional]: GraphChannelType.Organization,
    [ActiveChannelTypeEnum.Property]: GraphChannelType.Property,
    [ActiveChannelTypeEnum.Restaurant]: GraphChannelType.Restaurant,
    [ActiveChannelTypeEnum.Retail]: GraphChannelType.Retail,
    [ActiveChannelTypeEnum.Service]: GraphChannelType.Service,
  });

const GraphExperienceTypeMap: Readonly<
  Record<ChannelExperienceTypeEnum, GraphChannelExperienceTypeEnum>
> = Object.freeze({
  [ChannelExperienceTypeEnum.multifamily]:
    GraphChannelExperienceTypeEnum.Multifamily,
  [ChannelExperienceTypeEnum.office]: GraphChannelExperienceTypeEnum.Office,
});

export function toChannelInput(channel: Partial<Channel>): ChannelInput {
  const experienceType = channel?.experienceType
    ? GraphExperienceTypeMap[channel.experienceType]
    : undefined;

  const sanitizedAddress: AddressInput | null = channel?.address
    ? { ...channel.address }
    : null;
  const sanitizedProfile: ProfileInput | null = channel?.profile
    ? { ...channel.profile }
    : null;

  delete sanitizedAddress?._id;
  delete sanitizedProfile?._id;

  return {
    type: GraphChannelTypeMap[channel?.type ?? ActiveChannelTypeEnum.Property],
    name: channel?.name ?? '',
    description: channel?.description ?? null,
    slug: channel?.slug ?? '',
    isPublic: channel?.isPublic ?? false,
    isDiscoverable: channel?.isDiscoverable ?? true,
    isLive: channel?.isLive ?? false,
    isCustomer: channel?.isCustomer ?? false,
    isSub: channel?.isSub ?? false,
    inviteOnly: channel?.inviteOnly ?? false,
    filters: channel?.filters ?? [],
    tags: channel?.tags ?? [],
    experienceType,
    parent: channel?.parent?._id ? { _id: channel.parent._id } : null,
    address: sanitizedAddress,
    profile: sanitizedProfile,
    settings: {
      maxUsers: channel?.settings?.maxUsers ?? 0,
      subscribersJoinParent: channel?.settings?.subscribersJoinParent ?? false,
      subscribersAreDecoyed: channel?.settings?.subscribersAreDecoyed ?? false,
      groupRoleJoinRules: channel?.settings?.groupRoleJoinRules ?? [],
      hasPerksEnabled: channel?.settings?.hasPerksEnabled ?? false,
      hasWorkOrdersEnabled: channel?.settings?.hasWorkOrdersEnabled ?? false,
      hasWorkOrderServiceRequestsEnabled:
        channel?.settings?.hasWorkOrderServiceRequestsEnabled ?? false,
      hasWorkOrderPreventiveMaintenanceEnabled:
        channel?.settings?.hasWorkOrderPreventiveMaintenanceEnabled ?? false,
      hasWorkOrderEquipmentEnabled:
        channel?.settings?.hasWorkOrderEquipmentEnabled ?? false,
      hasWorkOrderCrossPropertyEnabled:
        channel?.settings?.hasWorkOrderCrossPropertyEnabled ?? false,
      hasBillingPaymentsEnabled:
        channel?.settings?.hasBillingPaymentsEnabled ?? false,
      hasVisitorManagementEnabled:
        channel?.settings?.hasVisitorManagementEnabled ?? false,
      hasHardwareManagementEnabled:
        channel?.settings?.hasHardwareManagementEnabled ?? false,
      hasHardwarePinManagementEnabled:
        channel?.settings?.hasHardwarePinManagementEnabled ?? false,
      hasSmartUserManagementEnabled:
        channel?.settings?.hasSmartUserManagementEnabled ?? false,
      hasReservableManagementEnabled:
        channel?.settings?.hasReservableManagementEnabled ?? false,
      hasAccessControlEnabled:
        channel?.settings?.hasAccessControlEnabled ?? false,
      hasSurveysEnabled: channel?.settings?.hasSurveysEnabled ?? true,
      areUserTemplatesAllowed:
        channel?.settings?.areUserTemplatesAllowed ?? false,
      hasMessagingAndMarketplaceEnabled:
        channel?.settings?.hasMessagingAndMarketplaceEnabled ?? false,
    },
  };
}
