export function isColorWhite(color: string): Boolean {
  return (
    [
      'white',
      'rgb(255,255,255)',
      '#ffffff',
      '#fff',
      'rgba(255,255,255,1)',
      'hsl(0,0%,100%)',
      'hsla(0,0%,100%,1)',
    ].indexOf(color.toLowerCase().split(' ').join('')) > -1
  );
}
