import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

import { graphql } from '@apollo/client/react/hoc';

import routes from 'lane-shared/config/routes';
import { getUserLogins } from 'lane-shared/graphql/user';
import { isTokenExpired } from 'lane-shared/helpers/integrations/ProxyClick';
import { UserLoginTypeEnum } from 'constants-user';
import { IntegrationProviderEnum } from 'constants-integrations';

import Button from '../../general/Button';
import Loading from '../../general/Loading';
import UpcomingMeetings from './components/UpcomingMeetings';

import styles from './styles.scss';

function ProxyClick({ userLogins, content, forAdmin }: any) {
  const { t } = useTranslation();

  if (userLogins.loading) {
    return <Loading />;
  }

  const proxyClickLogin = userLogins.me.user.logins.find(
    (l: any) =>
      l.type === UserLoginTypeEnum.OAuth &&
      l.provider === IntegrationProviderEnum.ProxyClick
  );

  if (
    (!proxyClickLogin && !forAdmin) ||
    (proxyClickLogin && isTokenExpired(proxyClickLogin))
  ) {
    return (
      <Redirect
        to={{
          pathname: routes.contentIntegrationLogin.replace(':id', content._id),
        }}
      />
    );
  }

  return (
    <div className={styles.Proxyclick}>
      <UpcomingMeetings content={content} />
      <div className={styles.button}>
        <Link
          to={{
            pathname: routes.contentInteractionNew.replace(':id', content._id),
            state: { content },
          }}
        >
          <Button variant="contained">{t('Create New Visit')}</Button>
        </Link>
      </div>
    </div>
  );
}

export default graphql(getUserLogins, {
  name: 'userLogins',
  options: { fetchPolicy: 'cache-and-network' },
})(ProxyClick);
