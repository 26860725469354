import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { filterUpcomingMeetings } from 'lane-shared/helpers/integrations/ProxyClick';
import { useContentInteractionsQuery } from 'lane-shared/hooks';
import { StringSearchTypeEnum } from 'constants-activate';
import { IntegrationProviderEnum } from 'constants-integrations';

import Line from 'components/general/Line';

import MeetingList from './MeetingList';

import styles from './UpcomingMeetings.scss';

export default function UpcomingMeetings({ content }: any) {
  const [search] = useState({
    sortBy: {
      key: '_updated',
      dir: 'desc',
    },
    // Searching by integration provider to get
    // all proxyclick interactions associated to user
    integrationProvider: {
      type: StringSearchTypeEnum.Equal,
      value: IntegrationProviderEnum.ProxyClick,
    },
  });

  const [pagination] = useState({
    start: 0,
    perPage: 50,
  });

  const [interactions] = useContentInteractionsQuery({
    search,
    pagination,
  });

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean | ContentInteractionsQue... Remove this comment to see the full error message
  const meetings = filterUpcomingMeetings(interactions);
  const { t } = useTranslation();

  function RenderUpcomingMeetings() {
    if (meetings.length > 0) {
      return (
        <>
          <Line />
          <MeetingList interactions={meetings} />
          <div className={styles.alignRight}>
            <Link to={routes.contentInteractions.replace(':id', content._id)}>
              <p>{t('Previous Visits')}</p>
            </Link>
          </div>
        </>
      );
    }

    return (
      <div className={styles.alignCenter}>
        <div className={styles.narrow}>
          <p>{t("You currently don't have any upcoming visits scheduled!")}</p>
          <Link to={routes.contentInteractions.replace(':id', content._id)}>
            <p>{t('View Previous Visits')}</p>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>{t('Upcoming Visits')}</h3>
      <RenderUpcomingMeetings />
    </div>
  );
}
