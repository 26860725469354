import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

export const LIVESAFE_TYPES = ['Emergency', 'Tip'];

const LiveSafeDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.LiveSafe,
  allowsMultipleInstances: true,
  friendlyName: 'LiveSafe',
  hasCustomUI: true,
  description: `Defines the properties required for a LiveSafe integration.`,
  category: IntegrationCategoryEnum.Safety,
  platforms: [PlatformEnum.Mobile],
  type: IntegrationTypeEnum.SDK,
  settings: {},
  properties: {
    type: {
      friendlyName: 'LiveSafe UI to display',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: LIVESAFE_TYPES,
        },
      ],
    },
    orgId: {
      friendlyName: 'LiveSafe OrgId for this location',
      type: 'Number',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    clientKey: {
      friendlyName: 'LiveSafe Client Key',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    secretKey: {
      friendlyName: 'LiveSafe Secret Key',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  mobilePermissions: [],
};

export default LiveSafeDefinition;
