import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';

import { ContentType } from 'lane-shared/types/content/Content';

import { LaneType } from 'common-types';
import { PublicUserType } from '../../hooks/useMyPublicUsersQuery';
import { IWizardState, WizardStepProps } from '../../hooks/useWizard';
import StrictUnion from '../StrictUnion';
import { GeoCoordinateType } from '../baseTypes/GeoTypes';
import { ActiveChannelTypeEnum } from 'constants-channel';

export const SHOPIFY_BURST_RSVP_THRESHOLD = 0.75;

export const SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE = 1;

export const CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION = 2;

export const SHOPIFY_BURST_MIN_RANGE_SIZE = 1;
export const SHOPIFY_BURST_MAX_RANGE_SIZE = 5;

export const GENERATE_ACCESS_BEFORE_START_DATE = 14;

export const SHOPIFY_BURSTING_APP_CHANNEL_ID =
  'cafeb170-d42c-4977-96fe-5ceb84c04a4b';

export type ShopifyChannelType = {
  _id: LaneType.UUID;
  name: string;
  slug: string;
  type: ActiveChannelTypeEnum;
  isPublic: boolean;
  isDiscoverable: boolean;
  inviteOnly: boolean;
  parent?: {
    _id: LaneType.UUID;
  };
  address: {
    _id: LaneType.UUID;
    name: string;
    street1: string;
    street2?: string;
    street3?: string;
    city: string;
    state: string;
    code: string;
    country: string;
    geo: GeoCoordinateType;
    distance: number;
  };
  profile: {
    _id: LaneType.UUID;
    name: string;
    image: LaneType.UUID;
    logo: LaneType.UUID;
  };
  stats: {
    subscribers: number;
  };
};

export type ShopifyBurstInteractionType = {
  name: LaneType.String;
  port?: ShopifyChannelType;
  portTimeZone?: LaneType.String;
  dateRange: LaneType.DateTimeRange;
  breakfastTime?: LaneType.TimeRange;
  lunchTime?: LaneType.TimeRange;
  startTime?: LaneType.TimeRange;
  endTime?: LaneType.TimeRange;
  dailySchedule?: LaneType.UserContentInteraction;
  attendees: PublicUserType[];
  l9?: PublicUserType;
  primaryRoomBooking?: LaneType.UserContentInteraction;
  activitiesBookings: Array<LaneType.UserContentInteraction>;
  updatedAt?: LaneType.DateTime;
  termsAndConditionsRead?: LaneType.Boolean;
  isDiyBurst?: LaneType.Boolean;
  burstNameContent?: ContentType;
  acknowledgementContent?: ContentType;
  /** @deprecated this field was preserved for backward compatability with old mobile versions that work with only v1 schema */
  questionnaire: LaneType.ShopifyBurstQuestionnaireTypeV2;
  questionnaireInteraction?: any;
  questionnaireContent?: ContentType;
  /** @deprecated this field was preserved for backward compatability with old mobile versions that work with only v1 schema */
  specialRequest?: string;
  specialRequestContent?: ContentType;
  specialRequestInteraction?: any;
  diyQuestions?: any;
  diyQuestionsContent?: ContentType;
  forUser?: LaneType.User;
  rsvpContentId?: LaneType.UUID;
  roomId: LaneType.UUID;
  schemaVersion?: number;
};

export type BurstDraftInteractionData = Partial<ShopifyBurstInteractionType>;

export type BurstWizardState = Pick<
  IWizardState<Partial<ShopifyBurstInteractionType>>,
  'data' | 'currentStepIndex'
>;

export enum ShopifyBurstTabsEnum {
  Shopify = 'shopify',
  Selected = 'selected',
}

export type ShopifyBurstCustomPageContentKeys = keyof Pick<
  ShopifyBurstInteractionType,
  | 'questionnaireContent'
  | 'specialRequestContent'
  | 'diyQuestionsContent'
  | 'acknowledgementContent'
  | 'burstNameContent'
>;

export type ShopifyBurstCustomPageInteractionKeys = keyof Pick<
  ShopifyBurstInteractionType,
  'questionnaireInteraction' | 'specialRequestInteraction' | 'diyQuestions'
>;

export enum ShopifyBurstStatusEnum {
  // NOTE: cant use constants because of https://github.com/microsoft/TypeScript/issues/40793
  /** -> team lead submits the burst */
  Waiting = 'Waiting', // INTERACTION_WAITING,
  /** -> assigned to burst user called l9 confirms details and gives it a green-light */
  Approved = 'Approved', // INTERACTION_APPROVED,
  /** -> this means that assigned to burst user called l9 decided the burst should not happen */
  Declined = 'Declined', // INTERACTION_DECLINED,
  /** -> internal shopify team confirms details and gives it a green-light to send RSVPs */
  Accepted = 'Accepted', // INTERACTION_ACCEPTED,
  /** -> internal shopify team decides the burst should not happen */
  Rejected = 'Rejected', // INTERACTION_REJECTED,
  /** -> this means we hit a 75% RSVP YES threshold or the internal shopify team decides to commit to the event */
  Confirmed = 'Confirmed', // INTERACTION_CONFIRMED,
  /** -> this means the internal shopify team deems the RSVPs threshold not high enough and manually cancels the event. */
  Cancelled = 'Cancelled', // INTERACTION_CANCELLED,
}

export type BurstCustomPageRendererProps =
  WizardStepProps<BurstDraftInteractionData> & {
    contentKey: ShopifyBurstCustomPageContentKeys;
    title: string;
    burstName?: string;
    contentContainerStyle?: StyleProp<ViewStyle>;
    progress?: number;
    headerComponent?: React.ReactNode;
    isNextButtonDisabled?: boolean;
    hasPreviousButton?: boolean;
  } & StrictUnion<
      | {
          interactionKey: ShopifyBurstCustomPageInteractionKeys;
        }
      | {
          wizardStateToInteractionAdapter: (
            wizardState: BurstWizardState
          ) => any;
          interactionToWizardStateAdapter: (
            interaction: any
          ) => Partial<ShopifyBurstInteractionType>;
        }
    >;

export enum ShopifyBurstPageTitle {
  BurstQuestionnaire = 'Bursting information',
  BurstSpecialRequest = 'Additional Requests',
  BurstDIYContent = 'DIY Burst Requirements',
  BurstName = 'Name your Burst',
  BurstDateAndTime = 'Set the date & time',
  BurstGuests = 'Add Shopifolk',
  BurstRoomBook = 'Book a room',
  Acknowledgement = 'Acknowledgement',
  SelectM6Approver = 'Select M6+ approver',
  ActionM6Approver = 'Change your M6+ approver',
}

export enum ShopifyBurstReviewSectionTitle {
  BurstQuestionnaire = 'Bursting information',
  BurstSpecialRequest = 'Additional Requests',
  BurstDIYQuestions = 'DIY Questions',
  BurstName = 'Name',
  ReviewAndSubmit = 'Review and submit',
  Location = 'Location',
  Date = 'Date',
  Attendees = 'Attendees',
  Room = 'Room',
  SelectL9Approver = 'Select L9+ approver',
  ActionL9Approver = 'Change your L9+ approver',
  L9Approver = 'L9+ approver',
}
