import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';

const ServiceRequestDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.ServiceRequest,
  hasCustomUI: true,
  friendlyName: 'ServiceRequest',
  description: `Defines the properties required for a ServiceRequest integration.`,
  category: IntegrationCategoryEnum.Maintenance,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {},

  properties: {
    hasReference: {
      friendlyName: 'Has Request Reference',
      description: 'Does this request form have a request reference field',
      default: false,
      type: 'Boolean',
    },
    hasPriority: {
      friendlyName: 'Has Priority',
      description: 'Does this request form have a priority field',
      default: false,
      type: 'Boolean',
    },
    name: {
      friendlyName: 'Display Name',
      type: 'String',
      description: 'The name to display for service request form',
      default: 'Service Request',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 5,
        },
      ],
    },
    subtitle: {
      friendlyName: 'Subtitle',
      type: 'String',
      description: 'The subtitle for this Angus form',
      default: 'Tenant Service Request',
    },
    description: {
      friendlyName: 'Service Description',
      type: 'LongText',
      description:
        'The description to display to the end user for this Service Request form.',
      default: `Service Request Types
 Building Service - Cleaning - Electrical - Elevator - HVAC - Lighting
 - Loading Dock - Locksmith - Maintenance - Plumbing`,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 5,
        },
      ],
    },
    buildings: {
      friendlyName: 'Buildings',
      description:
        'The list of buildings for available for this service request at this location',
      type: 'ThreeTenServBuilding',
      isArray: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    categories: {
      friendlyName: 'Request Category',
      type: 'ThreeTenServCategory',
      isArray: true,
      description: 'The request categories available',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
      ],
    },
    contactEmail: {
      friendlyName: 'Contact Email',
      type: 'Email',
      description:
        'The email to provide to the end user for contacting management.',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    toEmail: {
      friendlyName: 'Destination Email',
      type: 'Email',
      description: 'The email address to send service requests to',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    emailSubject: {
      friendlyName: 'Email Subject',
      type: 'String',
      description: 'The subject of the email to send.',
      default: 'New Service Request',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    emailFormat: {
      friendlyName: 'Email Format',
      type: 'LongText',
      description: 'The email to send.',
      default: `
Created: {{request._created}}
Name: {{request.name}}
Phone#: {{request.phone}}
Email: {{request.email}}
Company/Tenant Name: {{request.company}}\n
Building Address: {{request.address.name}},
{{request.address.street1}}, {{request.address.city}}
{{request.address.state}}, {{request.address.code}}\n
Floor: {{request.floor}}
Suite: {{request.suite}}
Request Category: {{request.category}}
Request Type: {{request.type}}
Detailed Request Description:
{{request.description}}
`,
    },
  },
  interactionData: {
    category: {
      friendlyName: 'Request Category',
      description: 'The request Category.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    type: {
      friendlyName: 'Request Type',
      description: 'The request type.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    address: {
      friendlyName: 'Building Address',
      description: 'The address where the work is to be done.',
      type: 'Address',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    floor: {
      friendlyName: 'Building Floor',
      description: 'The floor where the work is to be done.',
      type: 'String',
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    suite: {
      friendlyName: 'Building Suite',
      description: 'The floor where the work is to be done.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    name: {
      friendlyName: 'Contact Name',
      description: 'The Name of the person making the request.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    email: {
      friendlyName: 'Contact Email',
      description: 'The Email of the person making the request.',
      type: 'Email',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    phone: {
      friendlyName: 'Contact Phone Number',
      description: 'The phone number of the person making the request.',
      type: 'PhoneNumber',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    reference: {
      friendlyName: 'Reference #',
      description: 'The reference # of the request.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    description: {
      friendlyName: 'Request Description',
      description: 'The description of the request.',
      type: 'LongText',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 3,
        },
        {
          name: 'Max',
          value: 2 ** 8,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    company: {
      friendlyName: 'Company',
      description: 'The Company name where work is to be done.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    priority: {
      friendlyName: 'Priority',
      description: 'The priority of the request.',
      type: 'Number',
      default: 3,
      validators: [
        {
          name: 'Min',
          value: 0,
        },
        {
          name: 'Max',
          value: 5,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    sentEmailId: {
      friendlyName: 'Email Transaction Id',
      description: 'The ID of the email sent into the system.',
      type: 'String',
      validators: [],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
  },
  mobilePermissions: [],
};

export default ServiceRequestDefinition;
