import React, { useEffect, useState, useMemo } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { arrayReorder, getCurrencyByGeoLocation } from 'lane-shared/helpers';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import Menu from 'lane-shared/renderers/v5/features/Menu';
import MenuFeatureItem from 'lane-shared/renderers/v5/features/types/MenuFeatureItem';
import { ContentTypeEnum } from 'constants-content';
import {
  MenuFeatureMenuType,
  MenuFeatureItemType,
} from 'lane-shared/types/features/MenuFeatureTypes';

import MenuOrderItem from 'components/features/Menu/MenuOrderItem';
import Input from 'components/form/Input';
import Label from 'components/general/Label';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import ContentSelectorButton from 'components/lane/ContentSelectorButton';
import { FeatureRendererPropsType } from 'components/renderers/features/FeatureRendererPropsType';
import MenuFeatureItemEdit from 'components/renderers/features/components/MenuFeatureItemEdit';

import useUserLocale from 'hooks/useUserLocale';

import styles from './Menu.scss';

const TRANSLATION_KEYS = {
  supportContentFriendlyName:
    'web.content.features.menu.properties.supportContent.friendlyName',
  categoriesFriendlyName:
    'web.content.features.menu.properties.categories.friendlyName',
  categoryName: 'web.content.features.menu.properties.categories.categoryName',
  itemsFriendlyName: 'web.content.features.menu.properties.items.friendlyName',
  removeConfirmTitle:
    'web.content.features.MenuFeatureRenderer.removeConfirmTitle',
  removeButton: 'web.content.features.MenuFeatureRenderer.removeButton',
  removeItemConfirmMessage:
    'web.content.features.MenuFeatureRenderer.removeItemConfirmMessage',
  removeCategoryConfirmMessage:
    'web.content.features.MenuFeatureRenderer.removeCategoryConfirmMessage',
};

export default function MenuFeatureRenderer({
  className,
  style,
  channel,
  content,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<MenuFeatureMenuType>) {
  const locale = useUserLocale();
  const [selectedTab, setSelectedTab] = useState<TabItem | null>(null);
  const [editingItem, setEditingItem] = useState<any>(null);
  const menu: MenuFeatureMenuType = contentFeature?.feature;

  const { t } = useTranslation();

  // if no paymentFeature is defined, find the default currency.
  const { currency, currencyFormat } = useMemo(() => {
    const currency = getCurrencyByGeoLocation({
      latitude: content.geo?.[1],
      longitude: content.geo?.[0],
    });

    const currencyFormat = currencyFormatter({
      locale,
      currency,
    });

    return { currency, currencyFormat };
  }, [content.geo, locale]);

  function addCategory() {
    const newCategory = {
      _id: uuid(),
      _order: menu.categories.length,
      name: '',
    };

    let items = menu.items;

    // if there is no categories yet, move all the items into this new
    // category, and set this to the selectedTab

    if (menu.categories.length === 0) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ value: string; }' is not assig... Remove this comment to see the full error message
      setSelectedTab({ value: newCategory._id });
      items = items.map(item => ({ ...item, category: newCategory._id }));
    }

    onFeatureUpdated({
      items,
      categories: [...menu.categories, newCategory],
    });
  }

  useEffect(() => {
    if (menu?.categories?.length > 0 && !selectedTab) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ value: string; }' is not assig... Remove this comment to see the full error message
      setSelectedTab({ value: menu.categories[0]._id });
    }
  }, [menu?.categories]);

  async function removeItem(item: MenuFeatureItemType) {
    try {
      await window.Alert.confirm({
        title: t(TRANSLATION_KEYS.removeConfirmTitle, {
          name: item.name,
        }),
        message: t(TRANSLATION_KEYS.removeItemConfirmMessage),
        confirmText: t(TRANSLATION_KEYS.removeButton),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled
      return;
    }

    onFeatureUpdated({
      items: menu.items.filter(({ _id }) => _id !== item._id),
    });
  }

  function updateItem(item: any, props: any) {
    Object.entries(props).forEach(([key, value]) => (item[key] = value));

    onFeatureUpdated({
      items: [...menu.items],
    });
  }

  async function removeCategory(category: any) {
    if (menu.items.some(item => category._id === item.category)) {
      try {
        await window.Alert.confirm({
          title: t(TRANSLATION_KEYS.removeConfirmTitle, {
            name: category.name,
          }),
          message: t(TRANSLATION_KEYS.removeCategoryConfirmMessage),
          confirmText: t(TRANSLATION_KEYS.removeButton),
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled
        return;
      }
    }

    onFeatureUpdated({
      items: menu.items.filter(item => item.category !== category._id),
      categories: menu.categories.filter(({ _id }) => _id !== category._id),
    });
  }

  function onCategoriesDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    onFeatureUpdated({
      categories: arrayReorder(
        menu.categories,
        result.source.index,
        result.destination.index
      ),
    });
  }

  function onItemsDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    onFeatureUpdated({
      items: arrayReorder(
        menu.items,
        result.source.index,
        result.destination.index
      ),
    });
  }

  const showItems = menu?.categories?.length === 0 || selectedTab;
  const items = selectedTab
    ? menu?.items?.filter(({ category }) => category === selectedTab.value)
    : menu?.items;

  return (
    <div className={cx(styles.Menu, className)} style={style}>
      <Label h1>{t(Menu.friendlyName || '')}</Label>

      <p>{t(Menu.description)}</p>

      {menu && (
        <section>
          <div className={styles.panel}>
            <Label
              TooltipComponent={t(
                Menu.properties?.supportContent?.description || ''
              )}
            >
              {t(TRANSLATION_KEYS.supportContentFriendlyName)}
            </Label>
            <ContentSelectorButton
              className={styles.contentSelector}
              showSearch
              channelId={channel?._id}
              contentId={menu.supportContent?._id}
              onContentSelected={(content: any) =>
                onFeatureUpdated({
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: any; } | null' is not assignable to t... Remove this comment to see the full error message
                  supportContent: content ? { _id: content._id } : null,
                })
              }
              contentTypes={[ContentTypeEnum.Static]}
            />
          </div>
          <div className={styles.panel}>
            <Label>
              {t(TRANSLATION_KEYS.categoriesFriendlyName)}
              <Icon
                name="plus-circle"
                set={ICON_SET_FONTAWESOME}
                className={styles.addIcon}
                onClick={addCategory}
              />
            </Label>
            <DragDropContext onDragEnd={onCategoriesDragEnd}>
              <Droppable droppableId="categories">
                {provided => (
                  <ul className={styles.categories} ref={provided.innerRef}>
                    {menu.categories?.map((category, index) => (
                      <Draggable
                        key={category._id}
                        draggableId={category._id}
                        index={index}
                        isDragDisabled={false}
                      >
                        {(provided, snapshot) => (
                          <li
                            key={category._id}
                            ref={provided.innerRef}
                            data-is-dragging={snapshot.isDragging}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Icon
                              name="bars"
                              set={ICON_SET_FONTAWESOME}
                              className={styles.iconReorder}
                            />
                            <Input
                              className={styles.input}
                              maxLength={24}
                              placeholder={`${t(
                                TRANSLATION_KEYS.categoryName
                              )}...`}
                              value={category.name}
                              onChange={name => {
                                category.name = name;
                                onFeatureUpdated({
                                  categories: [...menu.categories],
                                });
                              }}
                            />
                            <Icon
                              name="times-circle"
                              set={ICON_SET_FONTAWESOME}
                              className={styles.removeIcon}
                              style={{ marginLeft: '1em' }}
                              onClick={() => removeCategory(category)}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className={styles.panel}>
            <TabStrip
              className={styles.tabStrip}
              tabs={menu.categories?.map(({ _id, name }) => ({
                value: _id,
                label: name,
              }))}
              selected={selectedTab}
              onSelectTab={tab => setSelectedTab(tab)}
            />
            {showItems && (
              <>
                <Label>
                  {t(TRANSLATION_KEYS.itemsFriendlyName)}
                  <Icon
                    name="plus-circle"
                    set={ICON_SET_FONTAWESOME}
                    className={styles.addIcon}
                    onClick={() => {
                      const item = {
                        ...MenuFeatureItem.default,
                        category: selectedTab?.value,
                        _order: menu.items.length,
                      };

                      setEditingItem(item);
                      onFeatureUpdated({
                        items: [...menu.items, item],
                      });
                    }}
                  />
                </Label>
                <DragDropContext onDragEnd={onItemsDragEnd}>
                  <Droppable droppableId="items">
                    {provided => (
                      <ul className={styles.items} ref={provided.innerRef}>
                        {items?.map((item, index) => (
                          <Draggable
                            key={item._id}
                            draggableId={item._id}
                            index={index}
                            isDragDisabled={editingItem?._id === item._id}
                          >
                            {(provided, snapshot) => (
                              <li
                                key={item._id}
                                ref={provided.innerRef}
                                data-is-dragging={snapshot.isDragging}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Icon
                                  name="bars"
                                  set={ICON_SET_FONTAWESOME}
                                  style={{
                                    opacity:
                                      editingItem?._id === item._id ? 0 : 1,
                                  }}
                                  className={styles.iconReorder}
                                />
                                {editingItem?._id === item._id && (
                                  <MenuFeatureItemEdit
                                    currency={currency}
                                    item={item}
                                    channel={channel}
                                    onItemUpdated={(item, props) =>
                                      updateItem(item, props)
                                    }
                                    onItemDone={() => setEditingItem(null)}
                                    onItemRemove={() => removeItem(item)}
                                  />
                                )}
                                {editingItem?._id !== item._id && (
                                  <>
                                    <MenuOrderItem
                                      className={styles.menuOrderItem}
                                      disabled={false}
                                      loading={false}
                                      item={item}
                                      currencyFormat={currencyFormat}
                                      onItemAdded={() => null}
                                      editMode
                                    />
                                    <Icon
                                      name="pencil"
                                      type={FONT_AWESOME_REGULAR}
                                      set={ICON_SET_FONTAWESOME}
                                      className={styles.iconEdit}
                                      onClick={() => setEditingItem(item)}
                                    />
                                  </>
                                )}
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}
          </div>
        </section>
      )}
    </div>
  );
}
