import React from 'react';

import { useTranslation } from 'react-i18next';

import { colors } from 'constants-colors';
import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';
import {
  BurstDraftInteractionData,
  ShopifyBurstReviewSectionTitle,
  ShopifyBurstInteractionType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { Flex, IconButton } from 'components';
import AddShopifolkModal from 'components/integrations/ShopifyBurst/components/AddShopifolkModal';
import { ReviewL9 } from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit';

type Props = {
  burstData: ShopifyBurstInteractionType;
  onDataUpdated: (data: BurstDraftInteractionData) => void;
  closeModal: () => void;
  showModal: (node: React.ReactNode) => void;
  isAdminView?: boolean;
};

export default function BurstEditL9Modal({
  burstData,
  onDataUpdated,
  closeModal,
  showModal,
  isAdminView,
}: Props) {
  const { t } = useTranslation();
  const users = burstData.l9 ? [burstData.l9] : [];

  function updateL9(users: PublicUserType[]) {
    onDataUpdated({
      ...burstData,
      l9: users[0],
    });

    closeModal();
  }

  function openShopifolkModal() {
    showModal(
      <AddShopifolkModal
        modalTitle={t(ShopifyBurstReviewSectionTitle.SelectL9Approver)}
        actionButtonTitle={t(ShopifyBurstReviewSectionTitle.ActionL9Approver)}
        onClose={closeModal}
        updateGuestList={updateL9}
        attendees={users}
        maxCapacity={1}
      />
    );
  }

  return (
    <Flex direction="row" align="center">
      {burstData.l9 && <ReviewL9 l9={burstData.l9} />}
      {isAdminView ? (
        <IconButton
          iconSet="FontAwesome"
          icon="pencil"
          type="fal"
          onClick={openShopifolkModal}
          style={{ color: colors.charcoal[4] }}
        />
      ) : null}
    </Flex>
  );
}
