import { useState, useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { queryChannels } from '../graphql/channel';
import useFlag from './useFlag';
import { FeatureFlag } from 'constants-flags';
import { CHANNEL_TYPE_KEYS } from 'constants-channel';

export default function useChannelsQuery({
  skip = false,
  defaultTypes = [CHANNEL_TYPE_KEYS.Property],
  includeParents = false,
  defaultIsSub = false,
  defaultHierarchyUp = null,
  defaultHierarchyDown = null,
}) {
  const [search, setSearch] = useState('');
  const [isSub, setIsSub] = useState(defaultIsSub);
  const [hierarchyUp, setHierarchyUp] = useState(defaultHierarchyUp);
  const [hierarchyDown, setHierarchyDown] = useState(defaultHierarchyDown);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [types, setTypes] = useState(defaultTypes);
  const [channels, setChannels] = useState([]);
  const [pageInfo, setPageInfo] = useState({ total: 0, start: 0, perPage });
  const isCacheFlagEnabled = useFlag<boolean>(
    FeatureFlag.ContentUsesCache,
    false
  );
  const [fetchChannels, { data, loading, error, fetchMore, called }] =
    useLazyQuery(queryChannels, {
      client: getClient(),
      fetchPolicy: isCacheFlagEnabled ? 'cache-and-network' : 'network-only',
    });

  useEffect(() => {
    if (defaultHierarchyUp) {
      setHierarchyUp(defaultHierarchyUp);
    }
  }, [defaultHierarchyUp]);

  useEffect(() => {
    if (defaultHierarchyDown) {
      setHierarchyDown(defaultHierarchyDown);
    }
  }, [defaultHierarchyDown]);

  function getVariables() {
    const variables = {
      pagination: {
        start: page * perPage,
        perPage,
      },
      search: {
        type: { any: types },
        isSub,
        hierarchyDown,
        hierarchyUp,
      },
    };

    if (search) {
      (variables.search as any).search = { type: 'like', value: search };
    }

    return variables;
  }

  const debouncedDoFetch = useDebouncedCallback(function doFetch() {
    if (skip) {
      return;
    }

    fetchChannels({ variables: getVariables() });
  }, 250).callback;

  useEffect(() => {
    setPage(0);
    debouncedDoFetch();
  }, [skip, search, perPage, types, isSub, hierarchyUp, hierarchyDown]);

  useEffect(() => {
    if (page > 0) {
      doFetchMore();
    }
  }, [page]);

  useEffect(() => {
    if (data?.channels?.items) {
      setChannels(
        data.channels.items.filter((channel: any) =>
          includeParents ? true : !!channel.parent
        )
      );
    }

    if (data?.channels?.pageInfo) {
      setPageInfo(data?.channels?.pageInfo);
    }
  }, [data?.channels]);

  async function doFetchMore() {
    if (loading) {
      return;
    }

    const pageInfo = data?.channels?.pageInfo || {
      start: 0,
      total: 0,
      perPage,
    };

    if (pageInfo.start + perPage > pageInfo.total) {
      // No more results.
      return;
    }

    try {
      await fetchMore({
        variables: { start: pageInfo.start + perPage, perPage },
        updateQuery: (prev, { fetchMoreResult }) => {
          // Don't do anything if there weren't any new items
          // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
          if (!fetchMoreResult?.channels?.items?.length > 0) {
            return prev;
          }

          return {
            // Concatenate the new feed results after the old ones
            channels: {
              __typename: 'channels',
              pageInfo: fetchMoreResult.channels.pageInfo,
              items: [
                ...prev.channels.items,
                ...fetchMoreResult.channels.items,
              ],
            },
          };
        },
      });
      // FIXME: log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // what to do on error?
    }
  }

  function nextPage() {
    setPage(page => page + 1);
  }

  return {
    channels,
    pageInfo,
    loading,
    called,
    error,
    search,
    setSearch,
    isSub,
    setIsSub,
    hierarchyDown,
    setHierarchyDown,
    hierarchyUp,
    setHierarchyUp,
    page,
    setPage,
    perPage,
    setPerPage,
    types,
    setTypes,
    refetch: debouncedDoFetch,
    nextPage,
  };
}
