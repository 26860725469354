import React from 'react';

import { colors } from 'constants-colors';
import { getTextColor } from 'lane-shared/helpers';

import { MjmlText } from '../MjmlComponentsV2';

type Props = {
  children: React.ReactNode;
  backgroundColor?: string;
  lineHeight?: number;
  fontSize?: number;
  align?: 'center' | 'left' | 'right' | undefined;
  fontWeight?: string;
  paddingBottom?: number;
  paddingTop?: number;
  color?: string;
};

export default function EmailText({
  children,
  backgroundColor,
  lineHeight = 20,
  fontSize = 13,
  color,
  ...props
}: Props) {
  const textColor = backgroundColor
    ? getTextColor(backgroundColor)
    : colors.darkCharcoal;

  return (
    <MjmlText
      fontFamily="'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif"
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={color || textColor}
      {...props}
    >
      {children}
    </MjmlText>
  );
}
