import React, { useState, useEffect } from 'react';

import { Icon, Button } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  AttachmentImageContentTypeEnum,
  AttachmentDocumentContentTypeEnum,
  EntityTypeEnum,
  AttachmentResponse,
  AttachmentPreview,
  AttachmentVariantEnum,
} from 'lane-shared/types/attachment';
import { AmazonS3Buckets } from 'lane-shared/config';

import { AttachmentThumbnail } from 'components/cards';
import { AttachmentPreviewModal } from 'components/cards/AttachmentPreviewModal';
import { FileInput } from 'components/form';
import { Loading } from 'components/general';
import { M } from 'components/typography';

import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import { useAttachmentByEntityData } from 'hooks/useAttachmentByEntityData';
import { useAttachmentDelete } from 'hooks/useAttachmentDelete';
import { useAttachmentUpload } from 'hooks/useAttachmentUpload';

import styles from './AddAttachment.scss';

type Props = {
  entityId: LaneType.UUID;
  entityType?: string;
  editMode: boolean;
  afterAttachmentCreated?: (attachments: AttachmentResponse[]) => Promise<void>;
  afterAttachmentDeleted?: (attachmentId: string) => Promise<void>;
  preventDelete?: boolean;
  draftAttachments?: AttachmentPreview[];
  updateAttachmentsUCICreate?: (data: AttachmentPreview[]) => void;
  updateAttachmentsUCIEdit?: (data: AttachmentPreview[]) => void;
  deleteAttachmentUCIEdit?: (
    id: AttachmentResponse[] | AttachmentPreview[]
  ) => void;
  variant?: AttachmentVariantEnum | string;
  canAddAttachments?: boolean;
  acceptedFileTypes?: string[];
  s3Bucket?: string;
  hideAttachmentLabel?: (hideAttachmentLabel: boolean) => void;
};

export function AddAttachment({
  entityId,
  entityType,
  editMode,
  draftAttachments = [],
  afterAttachmentCreated,
  afterAttachmentDeleted,
  preventDelete = false,
  updateAttachmentsUCICreate,
  variant,
  updateAttachmentsUCIEdit,
  deleteAttachmentUCIEdit,
  canAddAttachments = true,
  acceptedFileTypes = Object.values({
    ...AttachmentImageContentTypeEnum,
    ...AttachmentDocumentContentTypeEnum,
  }),
  s3Bucket = AmazonS3Buckets.Lane,
  hideAttachmentLabel,
}: Props) {
  const { t } = useTranslation();

  const [isAttachmentPreviewModalOpen, setIsAttachmentPreviewModalOpen] =
    useState(false);

  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState<
    number | null
  >(null);

  const openPreviewModal = (id: any) => {
    const index: any = previewAttachments.findIndex(
      (attachment: { id: any }) => attachment.id === id
    );

    setCurrentAttachmentIndex(index);
    setIsAttachmentPreviewModalOpen(true);
  };

  const onClose = () => {
    setCurrentAttachmentIndex(null);
    setIsAttachmentPreviewModalOpen(false);
  };

  const entity = {
    type: EntityTypeEnum[entityType as keyof typeof EntityTypeEnum],
    id: entityId,
  };

  const { loading, fetchAttachments, attachments } = useAttachmentByEntityData({
    entityId: entity.id,
    editMode,
  });

  const onAttachmentCreated = async () => {
    await fetchAttachments();

    if (afterAttachmentCreated) await afterAttachmentCreated(attachments);
  };

  // combine draftAttachments and savedAttachments
  const [previewAttachments, setPreviewAttachments] = useState<any>([
    ...draftAttachments,
    ...attachments,
  ]);

  const { uploading, filesSelectedHandler } = useAttachmentUpload({
    onAttachmentCreated,
    entity,
    s3Bucket,
  });

  const { deleting, deleteAttachmentHandler } = useAttachmentDelete({
    fetchAttachments,
  });

  useEffect(() => {
    if (!variant) {
      onAttachmentCreated();
    }

    setPreviewAttachments([...draftAttachments, ...attachments]);
  }, [draftAttachments.length, attachments.length]);

  useEffect(() => {
    if (hideAttachmentLabel) {
      hideAttachmentLabel(!canAddAttachments && !attachments.length);
    }
  }, [attachments.length, canAddAttachments, hideAttachmentLabel]);

  const attachmentsSelectedHandler = (files: File[]) => {
    const selectedFiles = files.map((file: File) => {
      return {
        id: uuid(),
        fileUrl: URL.createObjectURL(file),
        file,
      };
    });

    const data = [...draftAttachments, ...selectedFiles];

    if (updateAttachmentsUCICreate) {
      updateAttachmentsUCICreate(data);
    }

    if (updateAttachmentsUCIEdit) {
      updateAttachmentsUCIEdit(data);
    }
  };

  const deletePreviewAttachmentsHandler = async (attachmentId: any) => {
    // filters the attachments in case user deleted any attachment from array
    const updatedPreviewAttachments = previewAttachments.filter(
      (attachment: any) => attachment.id !== attachmentId
    );

    // Updates the current index of attachment for preview modal when it deletes
    // the highest index item from the list

    if (isAttachmentPreviewModalOpen) {
      const isLastAttachment =
        currentAttachmentIndex === previewAttachments.length - 1;

      if (isLastAttachment) {
        // set updated selectedAttachmentIndex
        setCurrentAttachmentIndex(prevIndex =>
          prevIndex! > 0 ? prevIndex! - 1 : 0
        );
      }

      if (updatedPreviewAttachments.length === 0) {
        // if there is no attachments to view in preview modal
        setCurrentAttachmentIndex(null);
        setIsAttachmentPreviewModalOpen(false);
      }
    }

    // check deleted attachment belongs to draft attachments or saved attachments
    const isDraftAttachment = draftAttachments.some(
      (attachment: any) => attachment.id === attachmentId
    );
    const isSavedAttachment = attachments.some(
      (attachment: any) => attachment.id === attachmentId
    );

    // if it is a draft attachment, remove it from draftAttachments
    if (isDraftAttachment) {
      const updatedDraftAttachments = draftAttachments.filter(
        (attachment: any) => attachment.id !== attachmentId
      );

      if (updateAttachmentsUCICreate) {
        updateAttachmentsUCICreate(updatedDraftAttachments);
      }

      if (updateAttachmentsUCIEdit) {
        updateAttachmentsUCIEdit(updatedDraftAttachments);
      }
    }

    // if it is a saved attachment, remove it from server
    if (isSavedAttachment) {
      if (deleteAttachmentUCIEdit) {
        deleteAttachmentUCIEdit(attachmentId);
      } else {
        deleteAttachmentHandler(attachmentId);
      }

      if (afterAttachmentDeleted) await afterAttachmentDeleted(attachmentId);
    }

    setPreviewAttachments(updatedPreviewAttachments);
  };

  const handlePreviousAttachment = () => {
    const lastItem = previewAttachments.length - 1;
    const previousItem = currentAttachmentIndex! - 1;
    const startPoint = currentAttachmentIndex === 0;

    setCurrentAttachmentIndex(startPoint ? lastItem : previousItem);
  };

  const handleNextAttachment = () => {
    const nextItem = currentAttachmentIndex! + 1;
    const lastItem = previewAttachments.length - 1;
    const firstItem = 0;
    const endPoint = currentAttachmentIndex === lastItem;

    setCurrentAttachmentIndex(endPoint ? firstItem : nextItem);
  };

  if (variant === AttachmentVariantEnum.Receipt) {
    preventDelete = true;
  }

  return (
    <div>
      {variant !== AttachmentVariantEnum.Receipt && canAddAttachments && (
        <div data-test="attachmentFileInput" data-has-file="file">
          <FileInput
            accept={acceptedFileTypes.toString()}
            type={FileReturnTypeEnum.File}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(files: any) => Promise<void>' is not assign... Remove this comment to see the full error message
            onFilesSelected={
              variant === AttachmentVariantEnum.WorkOrder
                ? filesSelectedHandler
                : attachmentsSelectedHandler
            }
            enableMultiUpload
            loading={uploading}
          >
            <Button className={styles.buttonAttachments}>
              <div className={styles.row}>
                <Icon
                  className={styles.emptyImageIcon}
                  set={ICON_SET_FONTAWESOME}
                  name="paperclip"
                  type="fal"
                />
                <M className={styles.buttonAttachmentsLabel}>
                  {t('Attach File or Photo')}
                </M>
              </div>
            </Button>
          </FileInput>
        </div>
      )}

      {(loading || uploading || deleting) && (
        <div className={styles.loader}>
          <Loading />
        </div>
      )}

      {previewAttachments.length > 0 && (
        <div className={styles.attachmentThumbnail}>
          {previewAttachments.map(
            (
              attachment: AttachmentResponse | AttachmentPreview,
              index: number
            ) => {
              return (
                <div key={index} className={styles.attachmentThumbnailItem}>
                  <AttachmentThumbnail
                    attachment={attachment}
                    deleteAttachmentHandler={deletePreviewAttachmentsHandler}
                    preventDelete={preventDelete}
                    onPreviewModal={openPreviewModal}
                  />
                </div>
              );
            }
          )}
        </div>
      )}

      {currentAttachmentIndex !== null && previewAttachments.length > 0 && (
        <AttachmentPreviewModal
          attachment={previewAttachments[currentAttachmentIndex]}
          isOpen={isAttachmentPreviewModalOpen}
          onClose={onClose}
          onDelete={deletePreviewAttachmentsHandler}
          onPrevious={handlePreviousAttachment}
          onNext={handleNextAttachment}
          isDeletable={!variant}
        />
      )}
    </div>
  );
}
