import { useEffect, useContext } from 'react';

import { WHITELABEL_LANE } from 'lane-shared/config/whitelabels';
import ThemeContext from 'lane-shared/contexts/ThemeContext';
import imageUrl from 'lane-shared/helpers/formatters/imageUrl';

import faviconActivate from 'static/favicon/favicon.activate.png';

/**
 * Adds the favicon with different colors depending on environment.
 */
function Favicon() {
  const theme = useContext(ThemeContext);

  useEffect(() => {
    let src;

    if (theme?.instance !== WHITELABEL_LANE) {
      if (!theme?.logo) {
        return;
      }

      src = imageUrl(theme.logo);
    } else {
      src = faviconActivate;
    }

    const link = document.createElement('link');

    link.rel = 'shortcut icon';
    link.href = src;

    document.head.prepend(link);
  }, [theme?.instance]);

  return null;
}

export default Favicon;
