import React from 'react';

import { useTranslation } from 'react-i18next';

import { IntegrationProviderEnum } from 'constants-integrations';

import Checkbox from '../../../../form/Checkbox';

import styles from './styles.scss';

const dataKey = `_${IntegrationProviderEnum.Cineplex}`;

export default function CineplexTargeting({ content, onContentUpdated }: any) {
  const { t } = useTranslation();
  const hasIntegration = content.state && content.state[dataKey];

  return (
    <div className={styles.TargetingIntegration}>
      <h1>{t('web.admin.content.draftContent.target.cineplexDisplays')}</h1>
      <section className={styles.details}>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: b... Remove this comment to see the full error message */}
        <Checkbox
          selected={!!hasIntegration}
          text={t(
            'web.admin.content.draftContent.target.cineplexDisplaysCheckbox'
          )}
          onChange={() => {
            onContentUpdated({
              state: {
                ...(content.state || {}),
                [dataKey]: hasIntegration ? undefined : true,
              },
            });
          }}
        />
      </section>
    </div>
  );
}
