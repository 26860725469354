import { TimeResetFrequencyEnum } from 'constants-content';
import { resetDateFunctions } from '../../renderers/v5/features/Reset';
import parseDate from '../dates/parseDate';

type Args = {
  nextReset: any;
  period: TimeResetFrequencyEnum;
};

/**
 * Gets the reset date/time for a Reset Feature.
 *
 * @param nextReset the last known next reset time
 * @param period the reset feature to base this off of
 * @returns {{start: Date, end: Date}}
 */
export default function getNextResetPeriod({ nextReset, period }: Args) {
  const startDate = parseDate(nextReset) ?? undefined;
  const endDate = resetDateFunctions[period](startDate, 1);

  return {
    startDate,
    endDate,
  };
}
