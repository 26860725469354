import React from 'react';

import { useTranslation } from 'react-i18next';

import env from 'lane-shared/config/getEnv';
import { ENVIRONMENTS } from 'constants-activate';

import { Button } from 'components/general';
import BuildingIllustration from 'components/svg/BuildingsIllustration';
import { L, M } from 'components/typography';

import styles from './FriendlyErrorPage.scss';

const isStaging = env === ENVIRONMENTS.STAGING;

type Props = {
  handleSendReport: () => void;
};

/**
 * When an error occurs in prod, display this page.
 * This won't show any error messages to the user.
 *
 */
function FriendlyErrorPage({ handleSendReport }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.leftColumn}>
          <L bold>{t('Sorry, something went wrong.')}</L>
          <M mb={4}>{t('We are working on fixing the problem.')}</M>
          <div className={styles.buttonContainer}>
            {isStaging && (
              <Button onClick={handleSendReport}>{t('Send Report')}</Button>
            )}
            <Button
              onClick={() => window.location.reload()}
              variant="contained"
            >
              {t('Refresh Page')}
            </Button>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <BuildingIllustration />
        </div>
      </div>
    </div>
  );
}

export default FriendlyErrorPage;
