import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

import { Password } from '../../../properties/baseTypes';

const BuildingEnginesPrismDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.BuildingEnginesPrism,
  hasCustomUI: true,
  friendlyName: 'BuildingEngines: Prism API',
  description:
    'Defines the properties required for a BuildingEngines Prism integration',
  category: IntegrationCategoryEnum.Maintenance,
  platforms: [PlatformEnum.Web, PlatformEnum.Mobile],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    name: {
      friendlyName: 'Display Name',
      type: 'String',
      description: 'The name to display for this BuildingEngines Prism form',
      default: 'BuildingEngines Prism',
    },
    subtitle: {
      friendlyName: 'Subtitle',
      type: 'String',
      description: 'The subtitle for this BuildingEngines Prism form',
      default: 'Tenant Service Request',
    },
    description: {
      friendlyName: 'Service Description',
      type: 'LongText',
      description:
        'The description to display to the end user for this BuildingEngines Prism form.',
      default: 'Submit your BuildingEngines Prism request here.',
    },
    buildingId: {
      friendlyName: 'Building ID',
      type: 'String',
      description: 'Building ID that is stored in BuildingEngines system',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    supportEmail: {
      friendlyName: 'Support Email',
      type: 'Email',
    },
    username: {
      type: String.name,
      friendlyName: 'Username',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    password: {
      type: Password.name,
      friendlyName: 'Password',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  interactionData: {
    spaceId: {
      friendlyName: 'Floor/Space',
      description: 'The unique id for the location of this request.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    requestTypeId: {
      friendlyName: 'Request Type',
      description: 'The unique id for the request type.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    description: {
      friendlyName: 'Description',
      description: 'The description of this work order.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default BuildingEnginesPrismDefinition;
