import { useMutation, useQuery } from '@apollo/client';
import { AdminPage, Flex } from 'components';
import { Chip, ChipStyle } from 'components/ads';
import { Alert, BreadCrumbs, Modal } from 'components/lds';
import { H3, H4, M } from 'components/typography';
import { Button, PopupButton } from 'design-system-web';
import { MenuItemType } from 'design-system-web/components/PopupMenu/PopupMenu';
import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { triggerAccessPoint } from 'lane-shared/graphql/hardware/accessPoint';
import { getRelayOutputById } from 'lane-shared/graphql/hardware/relay/getRelayOutputById';
import { safeConvertToUUID } from 'uuid-encoding';
import { SHORT_TIME, SIMPLE_DATE } from 'lane-shared/helpers/constants/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getStatusTranslation } from '../../../../../helpers';
import styles from './styles.scss';
import { UpdateRelayDetail } from './update-relay-detail';
import { RELAY_MODULE_STATUS_CONNECTED } from '../relay';
import {
  activateRelayModuleMutation,
  deactivateRelayModuleMutation,
} from 'lane-shared/graphql/hardware/relay/updateRelayStatus';

export function RelayDetailPage({ channel }: { channel: Channel }) {
  const { relayId } = useParams<{ relayId: string }>();
  const { data } = useQuery(getRelayOutputById, {
    variables: {
      relayOutputId: relayId,
    },
  });
  const relayData = data?.getRelayOutputById || {};
  const { t } = useTranslation();
  const [showUpdateComponent, setShowUpdateComponent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const location = useLocation();
  const onSave = async () => {
    setIsModalOpen(false);

    if (relayData?.relayModule?.isActive === undefined) {
      await window.Alert.alert({
        title: t(
          'web.admin.hardware.management.relay.activate/deactivate.errorMsg'
        ),
      });

      return;
    }

    if (relayData?.relayModule?.isActive) {
      return deactivateRelayModule();
    }

    return activateRelayModule();
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const toggleRelayActivation = async (
    mutation: Function,
    successMessage: any,
    errorMessage: any
  ) => {
    try {
      const { data } = await mutation({
        variables: {
          input: {
            id: safeConvertToUUID(relayData.relayModule?.id),
            // currently using mock data for relayModuleId which will be removed once actual data is available
            buildingId: safeConvertToUUID(channel?._id),
          },
        },
      });

      setIsModalOpen(false);
      window.Toast.show(
        t(successMessage, {
          name: data?.message,
        })
      );
    } catch (error) {
      setIsModalOpen(false);
      await window.Alert.alert({
        title: t(errorMessage),
        error,
      });
    }
  };

  const [triggerDeactivateRelayModuleMutation] = useMutation(
    deactivateRelayModuleMutation,
    {
      client: getClient(),
    }
  );

  const [triggerActivateRelayModuleMutation] = useMutation(
    activateRelayModuleMutation,
    {
      client: getClient(),
    }
  );

  const deactivateRelayModule = () =>
    toggleRelayActivation(
      triggerDeactivateRelayModuleMutation,
      'web.admin.hardware.management.relay.deactivate.successMsg',
      'web.admin.hardware.management.relay.deactivate.errorMsg'
    );

  const activateRelayModule = () =>
    toggleRelayActivation(
      triggerActivateRelayModuleMutation,
      'web.admin.hardware.management.relay.activate.successMsg',
      'web.admin.hardware.management.relay.activate.errorMsg'
    );

  const toggleActivationKey = (activateKey: string, deActivateKey: string) => {
    return relayData?.relayModule?.isActive ? t(deActivateKey) : t(activateKey);
  };

  const actions = [
    {
      label: toggleActivationKey(
        'web.admin.hardware.management.relay.activate',
        'web.admin.hardware.management.relay.deactivate'
      ),
      onSelect: () => setIsModalOpen(true),
    },
    {
      label: t('web.admin.hardware.management.relay.healthCheck'),
      onSelect: () => {},
    },
    {
      label: t('web.admin.hardware.management.relay.delete'),
      onSelect: () => {},
    },
  ] as MenuItemType[];

  const { goToEdit }: { goToEdit?: boolean } = location.state || {};

  const handleEditClick = () => {
    setShowUpdateComponent(true);
  };

  const [triggerAccessPointMutation] = useMutation(triggerAccessPoint, {
    client: getClient(),
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleUnlockDoor = async () => {
    try {
      const { data } = await triggerAccessPointMutation({
        variables: {
          id: safeConvertToUUID(relayData.accessPoint?.id),
        },
      });

      window.Toast.show(
        t(
          'web.admin.hardware.management.relay.triggerAccessPoint.addSuccessMsg',
          {
            name: data?.message,
          }
        )
      );

      // Disable the button for 10 seconds
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 10000);
    } catch (error) {
      await window.Alert.alert({
        title: t('Error in unlocking the door'),
        error,
      });
    }
  };

  useEffect(() => {
    if (goToEdit) {
      handleEditClick();
    }
  }, [goToEdit]);

  return (
    <AdminPage className={styles.RelayDetail}>
      {hasError && (
        <Alert type="error" className={styles.alert}>
          {t('web.admin.hardware.management.relay.updateErrorMsg')}
        </Alert>
      )}
      <Flex justify="space-between">
        <Flex direction="column">
          <BreadCrumbs
            links={[
              {
                label: t('web.admin.hardware.management.navigation.relayList'),
                url: `${routes.channelAdminHardwareDevices.replace(
                  ':id',
                  channel?.slug
                )}?tab=relay`,
              },
              { label: `${relayData?.name}` },
            ]}
          />
          <H3 data-test="relay-name">{relayData?.name}</H3>
        </Flex>
        <Flex gap={2} className={styles.buttonGroup}>
          <Button
            variant="secondary"
            size="medium"
            className={styles.button}
            onClick={handleUnlockDoor}
            disabled={isButtonDisabled}
          >
            {t('web.admin.hardware.management.relay.unlockDoor')}
          </Button>
          <PopupButton
            items={actions}
            variant="primary"
            size="medium"
            className={styles.popUpButton}
          >
            {t('web.admin.hardware.management.relay.actions')}
          </PopupButton>
        </Flex>
      </Flex>

      <div className={styles.header}>
        <H4 className={styles.H3}>
          {t(
            'web.admin.hardware.management.hardware.profile.device.info.title'
          )}
        </H4>
        <Button
          variant="secondary"
          onClick={handleEditClick}
          testId="editButton"
          size="medium"
        >
          {t('web.admin.hardware.management.hardware.profile.edit')}
        </Button>
      </div>

      {!showUpdateComponent ? (
        <Flex direction="column">
          <Flex>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.name')}:
              </div>
              <div className={styles.itemValue}>{relayData?.name}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.accessPoint')}:
              </div>
              <div className={styles.itemValue}>
                {relayData?.accessPoint?.name ?? '-'}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.notes')}:
              </div>
              <div className={styles.itemValue}>
                {relayData?.description ?? '-'}
              </div>
            </div>
          </Flex>
          <Flex>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.delay')}:
              </div>
              <div data-test="relay-delay" className={styles.itemValue}>
                {relayData?.delay ?? '-'}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.pulseDuration')}:
              </div>
              <div data-test="relay-pulse" className={styles.itemValue}>
                {relayData?.pulse ?? '-'}
              </div>
            </div>
          </Flex>
          <Flex>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.type')}:
              </div>
              <div className={styles.itemValue}>{relayData?.type ?? '-'}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.status')}:
              </div>
              <div>
                <Chip
                  value={getStatusTranslation(
                    relayData?.relayModule?.status,
                    t
                  )}
                  type={
                    relayData?.relayModule?.status ===
                    RELAY_MODULE_STATUS_CONNECTED
                      ? ChipStyle.Green
                      : ChipStyle.Red
                  }
                  doTranslate={false}
                  withStatusIcon
                />
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.lastSeen')}:
              </div>
              <div className={styles.itemValue}>{`${dateFormatter(
                relayData?.relayModule?.lastSeen,
                SHORT_TIME
              )}, ${dateFormatter(
                relayData?.relayModule?.lastSeen,
                SIMPLE_DATE
              )}`}</div>
            </div>
          </Flex>
          <Flex>
            <H4 data-test="relay-device-title" className={styles.H3}>
              {t('web.admin.hardware.management.relay.device')}
            </H4>
          </Flex>
          <Flex>
            <div className={styles.item}>
              <div data-test="relay-deviceId" className={styles.itemName}>
                {t('web.admin.hardware.management.relay.deviceId')}:
              </div>
              <div className={styles.itemValue}>
                {relayData?.relayModule?.id ?? '-'}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.moduleName')}:
              </div>
              <div className={styles.itemValue}>
                {relayData?.relayModule?.name ?? '-'}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemName}>
                {t('web.admin.hardware.management.relay.device.macAddress')}:
              </div>
              <div className={styles.itemValue}>
                {relayData?.relayModule?.macAddress ?? '-'}
              </div>
            </div>
          </Flex>
          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
              }}
              title={toggleActivationKey(
                'web.admin.hardware.management.relay.activate.confirmation.title',
                'web.admin.hardware.management.relay.deactivate.confirmation.title'
              )}
              className={styles.modal}
            >
              <M>
                {toggleActivationKey(
                  'web.admin.hardware.management.relay.activate.confirmation.message',
                  'web.admin.hardware.management.relay.deactivate.confirmation.message'
                )}
              </M>
              <div className={styles.buttonWrapper}>
                <Button
                  variant="primary"
                  onClick={onSave}
                  size="large"
                  className={styles.saveButton}
                >
                  {t('web.admin.serviceRequest.billable.yes')}
                </Button>
                <Button
                  variant="secondary"
                  className={styles.cancelButton}
                  onClick={onClose}
                  size="large"
                >
                  {t('web.admin.serviceRequest.billable.no')}
                </Button>
              </div>
            </Modal>
          )}
        </Flex>
      ) : (
        <UpdateRelayDetail
          setShowUpdateComponent={setShowUpdateComponent}
          channel={channel}
          relayData={relayData}
          setHasError={setHasError}
        />
      )}
    </AdminPage>
  );
}
