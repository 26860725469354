import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button, PopupMenu, Icon } from 'design-system-web';
import { H5, H4 } from 'components/typography';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import styles from './styles.scss';
import { AccessRule } from 'domains/accessControl/types/AccessLocation';
import { UserDataContext } from 'lane-shared/contexts';
import { hasPermissionOrSuperUser } from 'lane-shared/helpers';
import { DeleteAccessRuleDialog } from '../DeleteAccessRuleDialog';
import { AccessControlGroup } from 'domains/accessControl/types/AccessControl';
import { PERMISSION_KEYS, PermissionKey } from 'constants-permissions';
import { ManageBaseChannelAccessRuleModal } from '../ManageBaseChannelAccessRuleModal';

enum DefaultAccessAction {
  Edit = 'Edit',
  Delete = 'Delete',
}

type ModalState = {
  manageAccessRule: boolean;
  deleteAccessRule: boolean;
};

export function BaseChannelAccessRule({
  accessRule,
  channel,
  accessGroups,
  mobileAccess,
  refetch,
}: {
  accessRule: AccessRule | null;
  channel: Channel;
  accessGroups: AccessControlGroup[];
  mobileAccess: boolean;
  refetch: () => void;
}) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const [modalState, setModalState] = useState<ModalState>({
    manageAccessRule: false,
    deleteAccessRule: false,
  });

  const hasEditPermission = (permission: PermissionKey) => {
    return user && hasPermissionOrSuperUser(user, [permission], channel?._id);
  };

  const handleDefaultAccessAction = (action: DefaultAccessAction) => {
    switch (action) {
      case DefaultAccessAction.Edit: {
        toggleManageAccessRuleModal();
        break;
      }

      case DefaultAccessAction.Delete: {
        toggleDeleteAccessRuleModal();
        break;
      }
    }

    return action;
  };

  const toggleManageAccessRuleModal = () => {
    setModalState(prevState => ({
      ...prevState,
      manageAccessRule: !modalState.manageAccessRule,
    }));
  };

  const toggleDeleteAccessRuleModal = () => {
    setModalState(prevState => ({
      ...prevState,
      deleteAccessRule: !modalState.deleteAccessRule,
    }));
  };

  return (
    <Flex
      direction="column"
      gap={4}
      className={styles.buildingAccessRulesContainer}
      data-test="base-channel-access-rule"
    >
      <div>
        <H5>
          {t('web.admin.accessConfiguration.defaultAccess.mobileRules.title', {
            channelName: channel?.name,
          })}
        </H5>
        <p className={styles.description}>
          {t(
            'web.admin.accessConfiguration.defaultAccess.mobileRules.description'
          )}
        </p>
      </div>
      {accessRule ? (
        <Flex direction="row" gap={2} className={styles.ruleContainer}>
          <Flex
            justify="space-between"
            align="center"
            className={styles.accessGroupListContainer}
          >
            <Flex gap={2} align="center">
              <span>
                {accessRule?.accessControlGroups
                  ?.map(group => group?.name)
                  .join(', ')}
              </span>
            </Flex>
          </Flex>
          {hasEditPermission(
            PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_DEFAULT_ACCESS_EDIT
          ) && (
            <div className={styles.AdditionalControl}>
              <PopupMenu
                trigger={
                  <Button
                    variant="secondary"
                    size="large"
                    endIcon={<Icon name="angle-down" />}
                  >
                    {t(
                      'web.admin.accessConfiguration.defaultAccess.mobileRules.actionButton'
                    )}
                  </Button>
                }
                items={Object.keys(DefaultAccessAction).map(type => {
                  return {
                    label: t(
                      `web.admin.accessConfiguration.defaultAccess.mobileRules.actionButton.${type}`
                    ),
                    onSelect: () =>
                      handleDefaultAccessAction(
                        DefaultAccessAction[
                          type as keyof typeof DefaultAccessAction
                        ]
                      ),
                  };
                })}
              />
            </div>
          )}
        </Flex>
      ) : (
        <Flex
          direction="column"
          gap={4}
          className={styles.noRuleContainer}
          justify="center"
          align="center"
        >
          <Flex
            direction="column"
            gap={2}
            align="center"
            justify="center"
            className={styles.noRuleInfo}
          >
            <Flex direction="column" gap={2} align="center">
              <H4>
                {t(
                  'web.admin.accessConfiguration.defaultAccess.mobileRules.setupRule.title'
                )}
              </H4>
              <p className={styles.description}>
                {t(
                  'web.admin.accessConfiguration.defaultAccess.mobileRules.setupRule.description'
                )}
              </p>
            </Flex>
            <Button
              variant="secondary"
              size="large"
              onClick={toggleManageAccessRuleModal}
            >
              {t(
                'web.admin.accessConfiguration.defaultAccess.mobileRules.setupRule.button'
              )}
            </Button>
          </Flex>
        </Flex>
      )}
      {modalState.manageAccessRule && (
        <ManageBaseChannelAccessRuleModal
          channelId={channel?._id}
          accessGroups={accessGroups}
          accessRule={accessRule}
          mobileAccess={mobileAccess}
          isOpen={modalState.manageAccessRule}
          onClose={toggleManageAccessRuleModal}
          onSubmit={refetch}
          testId="manage-base-channel-access-rule-modal"
        />
      )}
      {modalState.deleteAccessRule && accessRule && (
        <DeleteAccessRuleDialog
          channelId={channel?._id}
          channelName={channel?.name || ''}
          accessRuleId={accessRule.id}
          isOpen={modalState.deleteAccessRule}
          onClose={toggleDeleteAccessRuleModal}
          onSubmit={refetch}
          testId="delete-base-channel-access-rule-modal"
        />
      )}
    </Flex>
  );
}
