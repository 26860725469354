import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';
import { PackagedTypeEnum } from '../../../types/properties/PackagedTypeEnum';
import './types/ShopifyBurstDinnerType';
import './types/ShopifyBurstQuestionnaireType';
import './types/ShopifyBurstQuestionnaireTypeV2';

export const ShopifyBurstMaxGuests = 512;

const ShopifyBurstIntegrationDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.ShopifyBurst,
  hasCustomUI: true,
  friendlyName: 'Shopify Burst',
  description:
    'A Shopify Burst is an event for select Shopify teams and members.',
  category: IntegrationCategoryEnum.Custom,
  platforms: [PlatformEnum.Web, PlatformEnum.Mobile],
  type: IntegrationTypeEnum.Custom,
  allowsMultipleInstances: false,
  settings: {
    enableAutoCancelDraftResources: {
      friendlyName: 'Enable Auto Cancel Draft Resources',
      description:
        'Enables the auto cancelling of draft resources that have not been submitted.',
      type: 'Boolean',
      default: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    autoCancelDraftBurstResourcesHours: {
      friendlyName: 'Auto Cancel Draft Resources',
      description:
        'The amount of time in hours to allow to elapse before auto-cancelling any booked resources for Bursts that have not been approved.',
      type: 'Number',
      default: 48,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 24,
        },
        {
          name: 'Max',
          value: 24 * 7,
        },
      ],
    },
  },
  properties: {
    enableAutoCancelDraftBursts: {
      friendlyName: 'Enable Auto Cancel Drafts',
      description:
        'Enables the auto cancelling of draft bursts that have not been submitted.',
      type: 'Boolean',
      default: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    autoCancelDraftBurstHours: {
      friendlyName: 'Auto Cancel Drafts',
      description:
        'The amount of time in hours to allow to elapse before auto-cancelling any draft bursts that have not been submitted.',
      type: 'Number',
      default: 48,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 24,
        },
        {
          name: 'Max',
          value: 24 * 7,
        },
      ],
    },
    enableAutoCancelBursts: {
      friendlyName: 'Enable Auto Cancel Bursts',
      description:
        'Enables the auto cancelling of bursts that have not been confirmed/approved.',
      type: 'Boolean',
      default: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    autoCancelBurstHours: {
      friendlyName: 'Auto Cancel Bursts',
      description:
        'The amount of time in hours to allow to elapse before auto-cancelling any created Bursts that have not been approved.',
      type: 'Number',
      default: 48,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 24,
        },
        {
          name: 'Max',
          value: 24 * 7,
        },
      ],
    },
    title: {
      friendlyName: 'Title',
      description: 'Title for the burst.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    parentChannel: {
      friendlyName: 'Shopify Channel',
      description: 'Parrent Channel with all shopify locations',
      type: 'Channel',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    roomBookingSection: {
      friendlyName: 'Room Bookings Section',
      description: 'The section where Room Bookings exist',
      type: 'Section',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    dailyScheduleOptions: {
      friendlyName: 'Daily schedule options',
      description:
        'Available options for start, lunch, and end hours for each day of the burst.',
      type: 'Section',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    activitiesBookingSection: {
      friendlyName: 'Activities Bookings Section',
      description: 'The section where Shopify Activities exist',
      type: 'Section',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    breakfastMetatag: {
      friendlyName: 'Breakfast Tag',
      description: "What's the metatag containing the breakfast information?",
      type: 'Metatag',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    startMetatag: {
      friendlyName: 'Start Tag',
      description: "What's the metatag containing the start information?",
      type: 'Metatag',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    lunchMetatag: {
      friendlyName: 'Lunch Tag',
      description: "What's the metatag containing the lunch information?",
      type: 'Metatag',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    endMetatag: {
      friendlyName: 'End Metatag',
      description: "What's the metatag containing the end information?",
      type: 'Metatag',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    faqContent: {
      friendlyName: 'FAQ Page',
      description: 'The content piece containing FAQs',
      type: 'Content',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    burstName: {
      friendlyName: 'Burst Name',
      description: 'Burst name step page',
      type: 'Content',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    diyQuestions: {
      friendlyName: 'DIY Questions',
      description: 'DIY Questions step page for DIY Bursts',
      type: 'Content',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    acknowledgement: {
      friendlyName: 'Acknowledgement',
      description: 'Acknowledgement step page',
      type: 'Content',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    questionnaire: {
      friendlyName: 'Burst Information',
      description: 'Bursting Information step page',
      type: 'Content',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    specialRequest: {
      friendlyName: 'Special request',
      description: 'Special request step page',
      type: 'Content',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    rsvpMaxCapacity: {
      friendlyName: 'Default RSVP Max Capacity',
      description:
        'The default maximum amount of people that can attend a Burst. note: this will still be limited by the port capacity',
      type: 'Number',
      default: 36,
      packagedType: PackagedTypeEnum.Slider,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: 512,
        },
      ],
    },
    diyMaxCapacity: {
      friendlyName: 'DIY Burst Max Capacity',
      description: 'The maximum amount of people that can attend a DIY Burst.',
      type: 'Number',
      packagedType: PackagedTypeEnum.Slider,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: 512,
        },
      ],
      default: 200,
    },
    rsvpWorkflows: {
      hidden: true,
      forDisplay: false,
      friendlyName: 'Default RSVP workflows',
      description:
        'The default workflows that will be added to the generated RSVP content',
      type: 'JSON',
      isArray: true,
      default: [],
    },
    diyWorkflows: {
      hidden: true,
      forDisplay: false,
      friendlyName: 'Default DIY workflows',
      description:
        'The default workflows that will be added to the generated DIY content',
      type: 'JSON',
      isArray: true,
      default: [],
    },
    generateMobileAccessEndpoint: {
      friendlyName: 'Endpoint to Genea mobile access',
      type: 'String',
      default: '',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  interactionData: {
    name: {
      _order: 0,
      friendlyName: 'Burst Name',
      hasCustomColumnRenderer: true,
      description: 'Name of the burst event.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 2,
        },
        {
          name: 'Max',
          value: 1024,
        },
      ],
    },
    questionnaire: {
      forDisplay: false,
      friendlyName: 'Questionnaire',
      description: 'A few pre-requisite questions for the burst. (outdated)',
      type: 'ShopifyBurstQuestionnaireType',
      validators: [
        {
          name: 'Required',
          value: false,
        },
      ],
    },
    questionnaireInteraction: {
      forDisplay: false,
      friendlyName: 'Questionnaire Interaction',
      description: 'Interaction data selected in Questionnaire content.',
      type: 'JSON',
      validators: [],
    },
    questionnaireContent: {
      forDisplay: false,
      friendlyName: 'Questionnaire Content',
      description: 'The content piece containing Questionnaire.',
      type: 'Content',
      validators: [],
    },
    port: {
      _order: 1,
      friendlyName: 'Shopify Port',
      description: 'The main location of the event.',
      type: 'Channel',
      validators: [],
    },
    portTimeZone: {
      forDisplay: false,
      friendlyName: 'Time zone of Port',
      description: 'The time zone of the selected Port.',
      type: 'String',
      validators: [],
    },
    dateRange: {
      forDisplay: false,
      friendlyName: 'Start & End Dates',
      description: 'Start and end dates of the burst',
      type: 'DateTimeRange',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    breakfastTime: {
      forDisplay: false,
      friendlyName: 'Breakfast Time',
      description: "What's the Burst's breakfast time?",
      type: 'TimeRange',
    },
    lunchTime: {
      forDisplay: false,
      friendlyName: 'Lunch Time',
      description: "What's the Burst's lunch time?",
      type: 'TimeRange',
    },
    startTime: {
      forDisplay: false,
      friendlyName: 'Start Time',
      description: "What's the Burst's start time?",
      type: 'TimeRange',
    },
    endTime: {
      forDisplay: false,
      friendlyName: 'End Time',
      description: "What's the Burst's end time?",
      type: 'TimeRange',
    },
    dailySchedule: {
      forDisplay: false,
      friendlyName: 'Daily Schedule',
      description: 'Start, lunch, and end hours for each day of the burst.',
      type: 'UserContentInteraction',
    },
    rsvpContentId: {
      forDisplay: false,
      friendlyName: 'Rsvp Content Id',
      type: 'UUID',
      validators: [],
    },
    attendees: {
      forDisplay: false,
      friendlyName: 'Attendees',
      description: 'Shopifolks that are invited to the burst.',
      type: 'User',
      isArray: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMax',
          value: ShopifyBurstMaxGuests,
        },
        {
          name: 'ArrayMin',
          value: 0,
        },
      ],
    },
    l9: {
      forDisplay: false,
      friendlyName: 'L9',
      description: 'Assigned Team Lead responsible for accepting the burst.',
      type: 'User',
    },
    primaryRoomBooking: {
      forDisplay: false,
      friendlyName: 'Room Booking',
      description: 'The primary room booking for the burst.',
      type: 'UserContentInteraction',
      validators: [],
    },
    activitiesBookings: {
      forDisplay: false,
      friendlyName: 'Activities',
      description: 'The activities booked for the burst.',
      type: 'UserContentInteraction',
      isArray: true,
      validators: [
        {
          name: 'ArrayMax',
          value: 128,
        },
        {
          name: 'ArrayMin',
          value: 0,
        },
      ],
    },
    specialRequest: {
      forDisplay: false,
      friendlyName: 'Special Requests',
      description: 'Special requests for burst. (outdated)',
      type: 'LongText',
      validators: [
        {
          name: 'Max',
          value: 2 ** 14,
        },
      ],
    },
    specialRequestInteraction: {
      forDisplay: false,
      friendlyName: 'Special Request Interaction',
      description: 'Interaction data selected in Special Request content.',
      type: 'JSON',
      validators: [],
    },
    specialRequestContent: {
      forDisplay: false,
      friendlyName: 'Special Request Content',
      description: 'The content piece containing Special Request page data.',
      type: 'Content',
    },
    updatedAt: {
      forDisplay: false,
      friendlyName: 'Updated At',
      description: 'Updated At',
      type: 'DateTime',
    },
    termsAndConditionsRead: {
      friendlyName: 'Has read terms and conditions',
      type: 'Boolean',
      default: false,
    },
    isDiyBurst: {
      friendlyName: 'Does this Burst use DIY',
      type: 'Boolean',
      default: false,
    },
    diyQuestions: {
      friendlyName: 'Response to the DIY Questions',
      type: 'JSON',
      hasCustomColumnRenderer: true,
      default: {},
    },
    diyQuestionsContent: {
      forDisplay: false,
      friendlyName: 'DIY Questions Content',
      description: 'The content piece containing DIY Questions page data',
      type: 'Content',
      validators: [],
    },
    forUser: {
      friendlyName: 'Create Burst on users behalf',
      type: 'User',
      default: null,
    },
    schemaVersion: {
      friendlyName: 'Schema version',
      type: 'Number',
    },
  },
  mobilePermissions: [],
};

export default ShopifyBurstIntegrationDefinition;
