import { LaneType } from 'common-types';
import { ContentTypeEnum ,
  CONTENT_CARD_STANDARD,
  CONTENT_CARD_HORIZONTAL,
  NOTICE_CARD,
} from 'constants-content';
import { convertTo62 } from 'uuid-encoding';

function getDefault(type: ContentTypeEnum | null | undefined): string {
  switch (type) {
    case ContentTypeEnum.Notice:
      return NOTICE_CARD;
    case ContentTypeEnum.Static:
      return CONTENT_CARD_HORIZONTAL;
    case ContentTypeEnum.Content:
    case ContentTypeEnum.Promotion:
    case ContentTypeEnum.Perk:
    default:
      return CONTENT_CARD_STANDARD;
  }
}

export default function getCardName(
  content:
    | {
        card: {
          _id: LaneType.UUID;
        };
        cardId: LaneType.UUID;
        type: ContentTypeEnum;
      }
    | null
    | undefined,
  cards: {
    _id: LaneType.UUID;
    name: string;
  }[]
): string {
  const cardId = content?.card?._id || content?.cardId;

  if (!cardId) {
    return getDefault(content?.type);
  }

  const card = cards.find(card => card._id === convertTo62(cardId));

  return card?.name || getDefault(content?.type);
}
