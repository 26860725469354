import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

const BiltDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Bilt,
  hasCustomUI: true,
  friendlyName: 'Bilt',
  description: 'Defines the properties required for a Bilt integration',
  category: IntegrationCategoryEnum.Custom,
  platforms: [],
  type: IntegrationTypeEnum.Api,
  settings: {},
  getChannelIntegrationName(integrationData): string {
    return `Bilt - ${integrationData.settings.pageTitle}`;
  },
  properties: {
    pageTitle: {
      friendlyName: 'Page Title',
      type: 'String',
      description: 'The title of the page',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    description: {
      friendlyName: 'Page Description',
      type: 'LongText',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    propertyId: {
      friendlyName: 'Property ID',
      type: 'String',
      description: 'ID of the property in Activate system',
      validators: [{ name: 'Required', value: true }],
    },
    biltId: {
      friendlyName: 'Bilt ID',
      type: 'String',
      description: 'Bilt ID that is stored in Bilt system',
      validators: [{ name: 'Required', value: true }],
    },
  },
};

export default BiltDefinition;
