import { useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import ObjectTouchHelper from '../../helpers/ObjectTouchHelper';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { ContentType } from '../../types/content/Content';
import SectionType from '../../types/sections/SectionType';
import {
  sectionCollectables,
  contentCollectables,
  Collectable,
} from './collectables';
import { PinsAndCardsType ,
  LIST_OR_FEED_TYPES,
  CONTENT_LISTS_OR_FEEDS_FRIENDLY_NAME,
  SECTION_LISTS_OR_FEEDS_FRIENDLY_NAME,
} from 'constants-content';
import { ContentForCardType } from '../useContentForCardQuery';

export type ListOrFeedNameType =
  (typeof LIST_OR_FEED_TYPES)[keyof typeof LIST_OR_FEED_TYPES];
export type PreviewBlockType = PinsAndCardsType;

type EventType = 'view' | 'details';

const contentEventMap = {
  preview: ANALYTIC_KEYS.ANALYTIC_CONTENT_VIEW,
  details: ANALYTIC_KEYS.ANALYTIC_CONTENT_DETAILS,
};

const sectionEventMap = {
  preview: ANALYTIC_KEYS.ANALYTIC_SECTION_VIEW,
  details: ANALYTIC_KEYS.ANALYTIC_SECTION_DETAILS,
};

// TODO: ObjectTouchHelper.touch has a default value for ObjectType
// We could be passing in 'Section' types here, but the allowed values are 'Content' and 'Channel'
// --> Fix this in ObjectTouchHelper
const batchEvent = ({ content, event }: any) => {
  return ObjectTouchHelper.touch(
    content,
    ObjectTouchHelper.mapAction(event as EventType),
    'Content'
  );
};

/**
 * Used for analytics tracking in mobile or web.
 * Note: unfortunately, this is a combination hook for content and section analytics
 * This is due to the nature of Pin components: they may represent both content and sections
 */
export function useContentOrSectionAnalytics({
  content,
  contentIsSection = false,
  listOrFeedName,
  previewBlock,
  skipAnalytics,
}: {
  content?:
    | ContentForCardType
    | Partial<ContentType>
    | Partial<SectionType>
    | null;
  contentIsSection?: boolean;
  listOrFeedName?: ListOrFeedNameType;
  previewBlock?: PreviewBlockType;
  skipAnalytics?: boolean;
} = {}) {
  const { track } = useContext(AnalyticsContext);

  if (skipAnalytics || !content) {
    return;
  }

  const trackPreview = (isSection: boolean, collectable?: Collectable) => {
    const eventMap = isSection ? sectionEventMap : contentEventMap;

    batchEvent({ content, event: 'view' });
    track(eventMap.preview, collectable as Collectable);
  };

  const trackDetails = (isSection: boolean, collectable?: Collectable) => {
    const eventMap = isSection ? sectionEventMap : contentEventMap;

    batchEvent({ content, event: 'details' });
    track(eventMap.details, collectable as Collectable);
  };

  const collectable = contentIsSection
    ? sectionCollectables(content as Partial<SectionType>)
    : contentCollectables(content as Partial<ContentType>);

  const FRIENDLY_LIST_OR_FEED_NAMES = contentIsSection
    ? SECTION_LISTS_OR_FEEDS_FRIENDLY_NAME
    : CONTENT_LISTS_OR_FEEDS_FRIENDLY_NAME;

  const friendlyListOrFeedName = listOrFeedName
    ? FRIENDLY_LIST_OR_FEED_NAMES[listOrFeedName]
    : undefined;

  const contentOrSectionTracker = {
    View: {
      Preview: () => {
        trackPreview(contentIsSection, {
          ...collectable,
          listOrFeedName: friendlyListOrFeedName,
          previewBlock,
        });
      },
      Details: () => {
        trackDetails(contentIsSection, collectable);
      },
    },
  };

  return { contentOrSectionTracker };
}
