import { useMemo, useRef } from 'react';

import gql from 'graphql-tag';

import {
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from '@apollo/client';

import type { MyPaymentAccountsResponseType } from 'lane-shared/types/payment/PaymentAccount';

import { PaymentAccountFragment } from '../graphql/fragments';
import filterPaymentAccount from '../helpers/filters/paymentAccount';
import { PaymentProviderEnum } from 'constants-payments';

const queryPaymentAccounts = gql`
  ${PaymentAccountFragment}

  query myPaymentAccounts {
    me {
      user {
        _id
        settings
        paymentAccounts {
          ...PaymentAccountFragment
          paymentProcessor {
            publicKey
            type
          }
        }
      }
    }
  }
`;

type Refetch = (
  variables?: OperationVariables | undefined
) => Promise<ApolloQueryResult<any>>;

export default function useMyPaymentAccountsQuery(
  paymentProvider: PaymentProviderEnum = PaymentProviderEnum.Cash
) {
  const refetchRef = useRef<Refetch>();

  // user will default to the currently logged in user on the resolver
  const { data, loading, error, refetch } =
    useQuery<MyPaymentAccountsResponseType>(queryPaymentAccounts, {
      fetchPolicy: 'network-only',
    });

  refetchRef.current = refetch;

  const myPaymentAccounts = useMemo(
    () =>
      (data?.me.user.paymentAccounts || []).filter(paymentAccount =>
        filterPaymentAccount(paymentAccount, paymentProvider)
      ),
    [data?.me?.user?.paymentAccounts, paymentProvider]
  );

  // These are non-lane payment accounts.  i.e. payment accounts with
  // 3rd party payment processors.
  const externalPaymentAccounts = useMemo(
    () =>
      (myPaymentAccounts || []).filter(
        paymentAccount => !!paymentAccount.paymentProcessor
      ),
    [myPaymentAccounts]
  );

  async function refetchMyPaymentAccounts(
    variables?: OperationVariables | undefined
  ) {
    return await refetchRef.current?.(variables);
  }

  return {
    previousPaymentAccountId:
      data?.me?.user?.settings?.previousPaymentAccountId,
    externalPaymentAccounts,
    myPaymentAccounts,
    loading,
    error,
    refetchMyPaymentAccounts,
    refetch: refetchMyPaymentAccounts,
  } as const;
}
