import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { OAUTH_PROVIDERS } from 'constants-user';

import OauthBase from './OauthBase';
import { GOOGLE } from './constants';

import styles from './styles.scss';

export class Google extends OauthBase {
  static propTypes = {
    className: PropTypes.string,
    onFailure: PropTypes.func,
    onSuccess: PropTypes.func,
    onClick: PropTypes.func,
    state: PropTypes.string,
  };

  static defaultProps = {
    className: 'btn-Google',
    state: 'fdsf78fyds7fm',
    onFailure: () => {},
    onSuccess: () => {},
    onClick: () => {},
  };

  getUrl = () => {
    const scopeParam =
      'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/email.profile';

    return `https://accounts.google.com/o/oauth2/v2/auth?scope=${scopeParam}&include_granted_scopes=true&state=state_parameter_passthrough_value&redirect_uri=${GOOGLE.redirectUri}&response_type=token&client_id=${GOOGLE.clientId}`;
  };

  handleMessage = (data: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFailure' does not exist on type 'Reado... Remove this comment to see the full error message
    const { onFailure, onSuccess } = this.props;

    if (data.errorMessage && data.provider === OAUTH_PROVIDERS.GOOGLE) {
      if (onFailure) onFailure(data);

      this.closeWindow();
    } else if (data.access_token && data.provider === OAUTH_PROVIDERS.GOOGLE) {
      if (onSuccess) onSuccess({ code: data.code });

      this.closeWindow();
    }
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className, children, ...other } = this.props;

    if (children) {
      return children;
    }

    return (
      <button
        onClick={this.handleConnectClick}
        className={cx(styles.SocialButton, styles.google, className)}
        {...other}
      >
        <Icon
          className={styles.icon}
          set={ICON_SET_FONTAWESOME}
          name="google"
        />
      </button>
    );
  }
}
export default Google;
