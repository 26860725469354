import { IntegrationProviderEnum } from 'constants-integrations';

import CaptivateTargeting from './CaptivateTargeting';
import CinexplexTargeting from './CineplexTargeting';
import PattisonTargeting from './PattisonTargeting';
import YourhereTargeting from './YourhereTargeting';

export default {
  [IntegrationProviderEnum.Captivate]: CaptivateTargeting,
  [IntegrationProviderEnum.Cineplex]: CinexplexTargeting,
  [IntegrationProviderEnum.YouRHere]: YourhereTargeting,
  [IntegrationProviderEnum.Pattison]: PattisonTargeting,
};
