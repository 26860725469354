import { PresetContentFilter } from 'constants-content';

import { PresetContentFilterAvailable } from '../../types/filters/PresetContentFilterAvailable';
import { PresetContentFilterEventDate } from '../../types/filters/PresetContentFilterEventDate';
import { PresetContentFilterInterface } from '../../types/filters/PresetContentFilterInterface';
import { PresetContentFilterLocation } from '../../types/filters/PresetContentFilterLocation';
import { PresetContentFilterPrice } from '../../types/filters/PresetContentFilterPrice';
import { PresetContentFilterQuantity } from '../../types/filters/PresetContentFilterQuantity';
import { PresetContentFilterTimeRange } from '../../types/filters/PresetContentFilterTimeRange';

type PresetContentFilterOptions = {
  filterByChannelLocations?: PresetContentFilterLocation;
  filterTimeRange?: PresetContentFilterTimeRange;
  filterLocation?: PresetContentFilterLocation;
  filterQuantity?: PresetContentFilterQuantity;
  filterAvailable?: PresetContentFilterAvailable;
  filterPrice?: PresetContentFilterPrice;
  filterByEventDate?: PresetContentFilterEventDate;
};

export default function explodePresetContentFilters(
  filters: PresetContentFilterInterface[] | null | undefined
): PresetContentFilterOptions {
  const results: PresetContentFilterOptions = {
    filterByChannelLocations: undefined,
    filterTimeRange: undefined,
    filterLocation: undefined,
    filterQuantity: undefined,
    filterAvailable: undefined,
    filterPrice: undefined,
    filterByEventDate: undefined,
  };

  if (!filters) {
    return results;
  }

  filters.forEach(obj => {
    switch (obj.type) {
      case PresetContentFilter.AvailableNow:
        results.filterAvailable = obj.filter as PresetContentFilterAvailable;
        break;
      case PresetContentFilter.FeaturePaymentPrice:
        results.filterPrice = obj.filter as PresetContentFilterPrice;
        break;
      case PresetContentFilter.FeatureQuantityRemaining:
        results.filterQuantity = obj.filter as PresetContentFilterQuantity;
        break;
      case PresetContentFilter.FeatureReservableAvailableDays:
      case PresetContentFilter.FeatureReservableAvailableMinutes:
      case PresetContentFilter.FeatureReservableAvailableMonths:
      case PresetContentFilter.FeatureReservableAvailableWeeks:
        results.filterTimeRange = obj.filter as PresetContentFilterTimeRange;
        break;
      case PresetContentFilter.Location:
        results.filterLocation = obj.filter as PresetContentFilterLocation;
        break;
      case PresetContentFilter.ByChannelLocations:
        results.filterByChannelLocations =
          obj.filter as PresetContentFilterLocation;
        break;
      case PresetContentFilter.ByEventDate:
        results.filterByEventDate = obj.filter as PresetContentFilterEventDate;
        break;
    }
  });

  return results;
}
