import React, { useState } from 'react';

import cx from 'classnames';

import { toSchema } from 'lane-shared/helpers';
import {
  CONTENT_LOCATION_PAGE_CENTER,
  CONTENT_LOCATION_NAMES,
  CONTENT_LOCATION_IN_SECTION,
  CONTENT_LOCATION_LIVE,
 ContentTypeEnum } from 'constants-content';

import Dropdown from '../../form/Dropdown';
import LiveContentSearchOnChannel from './LiveContentSearchOnChannel';
import SectionContentSearchOnChannel from './SectionContentSearchOnChannel';

import styles from './ContentSearchOnChannel.scss';

type Props = {
  headerComponent?: React.ReactNode;
  emptyComponent?: React.ReactNode;
  channelId: string;
  types?: ContentTypeEnum[];
  onContentSelected: (content: any) => void;
  className?: string;
  forDrafts?: boolean;
  style?: React.CSSProperties;
  filterIds?: string[];
  contentSearchLocations?: Readonly<(typeof CONTENT_LOCATION_NAMES)[number][]>;
  isOnlyInteractiveContent?: boolean;
  includeGeneratedBatchContent?: boolean;
};

export default function ContentSearchOnChannel({
  headerComponent,
  emptyComponent,
  channelId,
  types = Object.values(ContentTypeEnum),
  onContentSelected,
  className,
  forDrafts = false,
  style,
  filterIds,
  contentSearchLocations = CONTENT_LOCATION_NAMES,
  isOnlyInteractiveContent,
  includeGeneratedBatchContent = false,
}: Props) {
  const [contentLocation, setContentLocation] = useState(
    contentSearchLocations[0]
  );

  return (
    <div className={cx(styles.ContentSearchOnChannel, className)} style={style}>
      {headerComponent}
      {!forDrafts && contentSearchLocations.length > 1 && (
        <Dropdown
          className={styles.dropDown}
          onValueChange={value => setContentLocation(value)}
          items={contentSearchLocations.map(toSchema)}
          value={contentLocation}
        />
      )}

      {[CONTENT_LOCATION_LIVE, CONTENT_LOCATION_PAGE_CENTER].includes(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Content Center" | "Page Center"... Remove this comment to see the full error message
        contentLocation
      ) && (
        <LiveContentSearchOnChannel
          channelId={channelId}
          types={types}
          forDrafts={forDrafts}
          filterIds={filterIds}
          onContentSelected={onContentSelected}
          emptyComponent={emptyComponent}
          includeGeneratedBatchContent={includeGeneratedBatchContent}
          isOnlyInteractiveContent={isOnlyInteractiveContent}
        />
      )}
      {contentLocation === CONTENT_LOCATION_IN_SECTION && (
        <SectionContentSearchOnChannel
          channelId={channelId}
          onContentSelected={onContentSelected}
        />
      )}
    </div>
  );
}
