import { ONE_WEEK, ONE_DAY, ONE_HOUR, ONE_MINUTE } from 'constants-content';

export default function scheduledTime(ms: any) {
  if (ms > ONE_WEEK) {
    const weeks = Math.floor(ms / ONE_WEEK);

    return `${weeks} week${weeks > 1 ? 's' : ''}`;
  }

  if (ms > ONE_DAY) {
    const days = Math.floor(ms / ONE_DAY);

    return `${days} day${days > 1 ? 's' : ''}`;
  }

  if (ms > ONE_HOUR) {
    const hours = Math.floor(ms / ONE_HOUR);

    return `${hours} hour${hours > 1 ? 's' : ''}`;
  }

  const minutes = Math.floor(ms / ONE_MINUTE);

  return `${minutes} minute${minutes > 1 ? 's' : ''}`;
}
