import {
  useRemoveUserFromChannel,
  useInviteUsersRedirect,
  useAllUsers,
} from '../hooks';

import styles from './AllUsers.scss';
import {
  Icon,
  Table,
  Column,
  FilterType,
  NativeFilterTypes,
  Tooltip,
  BulkAction,
  PopupButton,
} from 'design-system-web';
import { ChipSelect, ChipStyle, Chip } from 'components/ads';
import history from 'helpers/history';
import { appUrl, routes } from 'lane-shared/config';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { fromNow } from 'lane-shared/helpers/formatters';
import { DateTime, Duration } from 'luxon';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { useFlag, useUserDataContext } from 'lane-shared/hooks';
import { useUserManagementPolishUsers } from 'lane-shared/hooks/useUserManagementPolishUsers';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { FeatureFlag } from 'constants-flags';
import {
  ResidentStatusEnum,
  ResidentTypeEnum,
  UserStatusEnum,
} from 'constants-user';
import { GroupRole, UserLoginStatusType } from 'graphql-query-contracts';
import Papa from 'papaparse';
import makeFileDownload from 'helpers/makeFileDownload';
import { UpdateUserCompanyModal } from './UpdateUserCompanyModal';
import downloadFile from 'helpers/downloadFile';
import { useQueryString, useChannelAdminContext } from 'hooks';
import { stringify } from 'query-string';
import { PERMISSION_KEYS } from 'constants-permissions';
import { hasPermission } from 'lane-shared/helpers';
import { UserDataContext } from 'lane-shared/contexts';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { VerifyUserEmailModal } from './VerifyUserEmailModal';
import { isBuildingChannel } from 'lane-shared/helpers/channel';
import { difference } from 'lodash';
import { BulkResetPasswordModal } from './BulkResetPasswordModal';
import { BulkVerifyUserEmailModal } from './BulkVerifyUserEmailModal';
import { BulkRemoveUserModal } from './BulkRemoveUserModal';
import { BulkAddCompaniesModal } from './BulkAddCompaniesModal';
import { BulkRemoveCompaniesModal } from './BulkRemoveCompaniesModal';
import { BulkAddTeamsModal } from './BulkAddTeamsModal';
import { BulkRemoveTeamsModal } from './BulkRemoveTeamsModal';
import { getUserCSVExportFilename } from './helpers/getUserCSVExportFilename';

type Props = {
  channelId: string;
  channelSlug: string;
  isMFExperience: boolean;
  groupRoles?: GroupRole[];
  companies: { _id: string; name: string }[];
};

type NameList = { name: string }[];

type Row = {
  id: string;
  name: string;
  units: NameList;
  floors: NameList;
  groupRoles: { _id: string; name: string; userGroupRoleId: string }[];
  status: string;
  type: string;
  activity: string;
  joinedOn: string;
  createdDate: string;
  email: string;
  isEmailVerified: boolean;
  company: { _id: string; name: string }[];
  userLoginId: string;
  userStatus: string;
};

export function AllUsersView({
  channelId,
  channelSlug,
  isMFExperience,
  groupRoles,
  companies,
}: Props) {
  const { handleRemoveUser } = useRemoveUserFromChannel();
  const [isUpdateCompanyModalOpen, setUpdateCompanyModalOpen] = useState(false);
  const [isVerifyEmailModalOpen, setVerifyEmailModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [isBulkVerifyEmailModalOpen, setBulkVerifyEmailModalOpen] =
    useState(false);
  const [isBulkRemoveUserModalOpen, setBulkRemoveUserModalOpen] =
    useState(false);
  const [isBulkAddCompaniesModalOpen, setBulkAddCompaniesModalOpen] =
    useState(false);
  const [isBulkRemoveCompaniesModalOpen, setBulkRemoveCompaniesModalOpen] =
    useState(false);
  const [isBulkAddTeamsModalOpen, setBulkAddTeamsModalOpen] = useState(false);
  const [isBulkRemoveTeamsModalOpen, setBulkRemoveTeamsModalOpen] =
    useState(false);
  const { user: loggedInUser } = useContext(UserDataContext);
  const { t, i18n } = useTranslation();
  const [searchParams] = useQueryString();
  const [selectedUser, setSelectedUser] = useState<
    Pick<Row, 'id' | 'name' | 'email' | 'company' | 'userLoginId'>
  >({
    id: '',
    name: '',
    email: '',
    company: [],
    userLoginId: '',
  });
  const [bulkSelection, setBulkSelection] = useState<
    Pick<
      Row,
      | 'id'
      | 'name'
      | 'email'
      | 'isEmailVerified'
      | 'userLoginId'
      | 'company'
      | 'groupRoles'
    >[]
  >([]);

  const clearSelection = () => {
    setBulkSelection([]);
  };

  const simpleTrack = useSimpleTrack();
  const { user: authUser } = useUserDataContext();
  const { channel } = useChannelAdminContext();

  const isTeamsBulkAddUsersEnabled = useFlag(
    FeatureFlag.TeamsBulkAddUsers,
    false
  );

  const isUpdateUserCompaniesEnabled = useFlag(
    FeatureFlag.UpdateUserCompany,
    false
  );

  const isVerifyUserEmailEnabled = useFlag(FeatureFlag.VerifyUserEmail, false);

  const isUserMgmtPolishUsersEnabled = useUserManagementPolishUsers();

  const canUserResetPassword =
    loggedInUser?.isSuperUser ||
    hasPermission(
      loggedInUser?.roles,
      [PERMISSION_KEYS.PERMISSION_USERS_PASSWORD_RESET],
      channelId
    );

  const canUserAddTenants =
    loggedInUser?.isSuperUser ||
    hasPermission(
      loggedInUser?.roles,
      [PERMISSION_KEYS.PERMISSION_USERS_CAN_ADD_OTHER_USERS_TO_TENANTS],
      channelId
    );

  const canUserRemoveTenants =
    loggedInUser?.isSuperUser ||
    hasPermission(
      loggedInUser?.roles,
      [PERMISSION_KEYS.PERMISSION_USERS_CAN_REMOVE_OTHER_USERS_FROM_TENANTS],
      channelId
    );

  const canUserAddOrRemoveTenants = canUserAddTenants || canUserRemoveTenants;

  const canUserVerifyEmail =
    loggedInUser?.isSuperUser ||
    hasPermission(
      loggedInUser?.roles,
      [PERMISSION_KEYS.PERMISSION_USERS_VERIFY_PRIMARY_EMAIL],
      channelId
    );

  const canUserRemoveUsers =
    loggedInUser?.isSuperUser ||
    hasPermission(
      loggedInUser?.roles,
      [
        PERMISSION_KEYS.PERMISSION_ADMIN,
        PERMISSION_KEYS.PERMISSION_USERS_ROLE_DELETE,
        PERMISSION_KEYS.PERMISSION_USERS_DELETE,
      ],
      channelId
    );

  const { inviteUsersRedirect, inviteUsersLabel } = useInviteUsersRedirect({
    channelSlug,
    isOfficeExperience: !isMFExperience,
  });

  const addUsersRedirect = () => {
    history.push(routes.channelAdminUsersAdd.replace(':id', channelSlug));
  };

  const defaultParams = {
    company: null,
    keyword: null,
    groupRoles: null,
    sortBy: null,
    sortDirection: null,
    page: 0,
    pageSize: 25,
    userStatus: null,
  };

  const [
    {
      company,
      keyword,
      groupRoles: groupRolesSearch,
      sortBy,
      sortDirection,
      page,
      pageSize,
      userStatus,
    },
  ] = useQueryString<{ [x: string]: string }>(defaultParams);

  const isAddManualResidentsEnabled = useFlag(
    FeatureFlag.AddManualResidents,
    false
  );

  const shouldShowUnitsAndFloors =
    isAddManualResidentsEnabled && isMFExperience;

  const shouldShowStatusAndType = isAddManualResidentsEnabled && isMFExperience;

  const shouldIncludeResidentInfo =
    isAddManualResidentsEnabled && isMFExperience;

  const {
    users: usersData,
    total: totalUsers,
    loading,
    refetch,
  } = useAllUsers(
    channelId,
    shouldIncludeResidentInfo,
    {
      company,
      keyword,
      groupRoles: groupRolesSearch,
      userStatus,
      sortBy,
      sortDirection,
    },
    {
      page: page ?? '0',
      pageSize: pageSize ?? '25',
    }
  );

  function activityHelper(lastSeen: string) {
    const lastSeenDt: DateTime = DateTime.fromISO(lastSeen);
    const now: DateTime = DateTime.local();
    const timeDiff: Duration = now.diff(lastSeenDt, ['minute']);

    if ((timeDiff as any).values?.minutes <= 2) {
      return t('web.admin.channel.users.all.table.activity.online');
    }

    return fromNow(lastSeen, undefined, i18n.language);
  }

  function emailHelper(email: string, isVerified: boolean) {
    if (!email) {
      return <span>{t('-')}</span>;
    }

    return (
      <div className={styles.userEmailCell}>
        {email}
        {isVerified && (
          <Icon
            testId="verified-badge-icon"
            set={ICON_SET_FONTAWESOME}
            type="far"
            name="badge-check"
            className={styles.icon}
          />
        )}
      </div>
    );
  }

  function statusHelper(userRow: Row) {
    switch (userRow.status) {
      case ResidentStatusEnum.RESIDENT_STATUS_CURRENT:
        return (
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.status.current')}
            type={ChipStyle.Green}
          />
        );
      case ResidentStatusEnum.RESIDENT_STATUS_FUTURE:
        return (
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.status.future')}
            type={ChipStyle.Yellow}
          />
        );
      case ResidentStatusEnum.RESIDENT_STATUS_PAST:
        return (
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.status.past')}
            type={ChipStyle.Grey}
          />
        );
      case ResidentStatusEnum.RESIDENT_STATUS_NOTICE:
        return (
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.status.onNotice')}
            type={ChipStyle.Green}
          />
        );
      case ResidentStatusEnum.RESIDENT_STATUS_EVICTION:
        return (
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.status.underEviction')}
            type={ChipStyle.Red}
          />
        );
      default:
        return <span>{t('-')}</span>;
    }
  }

  function typeHelper(userRow: Row) {
    switch (userRow.type) {
      case ResidentTypeEnum.PrimaryResident:
        return (
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.type.primary')}
            type={ChipStyle.Green}
          />
        );
      case ResidentTypeEnum.SecondaryResident:
        return (
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.type.secondary')}
            type={ChipStyle.Yellow}
          />
        );
      case ResidentTypeEnum.Guarantor:
        return (
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.type.guarantor')}
            type={ChipStyle.Grey}
          />
        );
      default:
        return <span>{t('-')}</span>;
    }
  }

  function groupRoleHelper(userRow: Row) {
    if (userRow.groupRoles.length > 1) {
      return (
        <Tooltip
          wrapperClassName={styles.alignTooltip}
          placement="top"
          TooltipComponent={
            <ul className={styles.tooltipItems}>
              {userRow.groupRoles.map(groupRole => {
                return (
                  <li key={userRow.id + groupRole._id}>
                    <span>{groupRole.name}</span>
                  </li>
                );
              })}
            </ul>
          }
        >
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.groupRolesAssigned', {
              count: userRow.groupRoles.length,
            })}
            type={ChipStyle.Grey}
            withStatusIcon={false}
            doTranslate={false}
          />
        </Tooltip>
      );
    }

    return (
      <span className={styles.commaSeparatedCell}>
        {(userRow.groupRoles.length > 0 && userRow.groupRoles[0].name) || '--'}
      </span>
    );
  }

  function companyHelper(userRow: Row) {
    if (userRow.company.length > 1) {
      return (
        <Tooltip
          wrapperClassName={styles.alignTooltip}
          placement="top"
          TooltipComponent={
            <ul className={styles.tooltipItems}>
              {userRow.company.map(c => {
                return (
                  <li key={c._id + c.name}>
                    <span>{c.name}</span>
                  </li>
                );
              })}
            </ul>
          }
        >
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t('web.admin.channel.users.all.table.companiesAssigned', {
              count: userRow.company.length,
            })}
            type={ChipStyle.Grey}
            withStatusIcon={false}
            doTranslate={false}
          />
        </Tooltip>
      );
    }

    return (
      <span className={styles.commaSeparatedCell}>
        {(userRow.company.length > 0 && userRow.company[0].name) || '--'}
      </span>
    );
  }

  function userStatusHelper(userRow: Row) {
    if (userRow.userStatus === UserStatusEnum.Disabled) {
      return (
        <Chip
          value="web.admin.channel.users.all.table.userStatus.disabled"
          type={ChipStyle.Grey}
          doTranslate
        />
      );
    }

    return (
      <Chip
        value="web.admin.channel.users.all.table.userStatus.enabled"
        type={ChipStyle.Green}
        doTranslate
      />
    );
  }

  const data: Row[] = usersData?.map(
    user =>
      ({
        id: user._id,
        name: user.name,
        units: (shouldShowUnitsAndFloors && user.person?.units) || [],
        floors:
          (shouldShowUnitsAndFloors &&
            user.person?.units?.flatMap(unit => unit.floors)) ||
          [],
        groupRoles:
          user.roles?.map(gr => ({
            _id: gr.groupRole._id,
            name: gr.groupRole.name,
            userGroupRoleId: gr._id,
          })) ?? [],
        status: user.person?.resident?.status,
        type: user.person?.resident?.type,
        activity: user.lastSeen,
        joinedOn: user._created,
        createdDate: user._created,
        email: (user.logins || []).find(login => login.isPrimary)?.key || '',
        isEmailVerified:
          (user.logins || []).find(login => login.isPrimary)?.status ===
            UserLoginStatusType.Verified || false,
        company:
          user.companies?.map(company => ({
            _id: company._id,
            name: company.profile.name,
          })) ?? [],
        userLoginId:
          (user.logins || []).find(login => login.isPrimary)?._id || '',
        userStatus: user.status,
      }) as Row
  );

  const columns = [
    {
      key: 'name',
      header: t('web.admin.channel.users.all.table.name'),
      renderCell: (name: string, user: Row) => (
        <Link
          className={styles.link}
          to={{
            pathname: routes.channelAdminTeamMember
              .replace(':id', channelSlug)
              .replace(':userId', user.id),
          }}
        >
          {name}
        </Link>
      ),
      renderForCSV: (row: Row) => row.name,
    },
    {
      key: 'email',
      header: t('web.admin.channel.users.all.table.email'),
      renderCell: (email: string, user: Row) =>
        emailHelper(email, user.isEmailVerified),
      renderForCSV: (row: Row) => row.email,
    },
    isUserMgmtPolishUsersEnabled
      ? {
          key: 'userStatus',
          header: t('web.admin.channel.users.all.table.userStatus'),
          renderCell: (_: any, userRow: Row) => {
            return userStatusHelper(userRow);
          },
          renderForCSV: (row: Row) => row.userStatus,
        }
      : null,
    shouldShowUnitsAndFloors
      ? {
          key: 'units',
          header: t('web.admin.channel.users.all.table.units'),
          renderCell: (units: NameList) => {
            return (
              <span>{units.map(unit => unit.name).join(', ') || '--'}</span>
            );
          },
          renderForCSV: (row: Row) =>
            row.units.map(unit => unit.name).join(', '),
        }
      : null,
    shouldShowUnitsAndFloors
      ? {
          key: 'floors',
          header: t('web.admin.channel.users.all.table.floors'),
          renderCell: (floors: NameList) => {
            return (
              <span>{floors.map(floor => floor.name).join(', ') || '--'}</span>
            );
          },
          renderForCSV: (row: Row) =>
            row.floors.map(floor => floor.name).join(', ') || '--',
        }
      : null,
    {
      key: 'groupRoles',
      header: t(
        `web.admin.channel.users.all.table.${
          isMFExperience ? 'permissionGroups' : 'teams'
        }`
      ),
      renderCell: (_: any, userRow: Row) => {
        return groupRoleHelper(userRow);
      },
      disableSorting: true,
      renderForCSV: (row: Row) =>
        row.groupRoles.map(gr => gr.name).join(', ') || '--',
    },
    !isMFExperience
      ? {
          key: 'company',
          header: t('web.admin.channel.users.all.table.company'),
          renderCell: (_: any, userRow: Row) => {
            return companyHelper(userRow);
          },
          disableSorting: true,
          renderForCSV: (row: Row) =>
            row.company.map(c => c.name).join(', ') || '--',
        }
      : null,
    shouldShowStatusAndType
      ? {
          key: 'status',
          header: t('web.admin.channel.users.all.table.status'),
          renderCell: (_: any, userRow: Row) => {
            return statusHelper(userRow);
          },
          renderForCSV: (row: Row) => row.status,
        }
      : null,
    shouldShowStatusAndType
      ? {
          key: 'type',
          header: t('web.admin.channel.users.all.table.type'),
          renderCell: (_: any, userRow: Row) => {
            return typeHelper(userRow);
          },
          renderForCSV: (row: Row) => row.type,
        }
      : null,
    {
      key: 'activity',
      header: t('web.admin.channel.users.all.table.activity'),
      renderCell: (cell: string | undefined) => (
        <span>{cell ? activityHelper(cell) : '--'}</span>
      ),
      type: 'date',
      renderForCSV: (row: Row) => activityHelper(row.activity) || '--',
    },
  ].filter(c => c !== null) as Column<Row[]>[];

  const rowActions = [
    {
      label: t('web.admin.channel.users.all.table.actions.viewProfile'),
      onClick: (user: Row) => {
        const url = routes.channelAdminTeamMember
          .replace(':id', channelSlug)
          .replace(':userId', user.id);

        history.push(url);
      },
    },
    ...(isVerifyUserEmailEnabled && canUserVerifyEmail
      ? [
          {
            label: t('web.admin.channel.users.all.table.actions.verifyEmail'),
            onClick: async (row: Row) => {
              simpleTrack(
                ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_VERIFY_EMAIL_START,
                {
                  channelId: channel?._id,
                  channelName: channel?.name,
                  me: authUser?._id,
                  userId: row.id,
                }
              );
              setSelectedUser({
                id: row.id,
                name: row.name,
                email: row.email,
                company: row.company,
                userLoginId: row.userLoginId,
              });
              setVerifyEmailModalOpen(true);
            },
            isHidden: (row: Row) => row.isEmailVerified,
          },
        ]
      : []),
    ...(isUpdateUserCompaniesEnabled &&
    canUserAddOrRemoveTenants &&
    isBuildingChannel(channel)
      ? [
          {
            label: t('web.admin.channel.users.all.table.actions.updateCompany'),
            onClick: async (row: Row) => {
              simpleTrack(
                ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_UPDATE_COMPANY_START,
                {
                  channelId: channel?._id,
                  channelName: channel?.name,
                  me: authUser?._id,
                  userId: row.id,
                }
              );
              setSelectedUser({
                id: row.id,
                name: row.name,
                email: row.email,
                company: row.company,
                userLoginId: row.userLoginId,
              });
              setUpdateCompanyModalOpen(true);
            },
          },
        ]
      : []),
    {
      label: t('web.admin.channel.users.all.table.actions.removeUser'),
      onClick: async (row: Row) => {
        handleRemoveUser({
          userId: row.id,
          userName: row.name,
          channelId,
          isMFExperience,
          refetch,
        });
      },
    },
  ];

  const unitsOptions = [
    ...new Set(data.map(item => item.units.map(unit => unit.name)).flat(2)),
  ];

  const floorsOptions = [
    ...new Set(data.map(item => item.floors.map(floor => floor.name)).flat(2)),
  ];

  const unitsFilterOptions = unitsOptions.map((unit: string) => {
    return {
      label: unit,
      value: unit,
    };
  });

  const floorsFilterOptions = floorsOptions.map((floor: string) => {
    return {
      label: floor,
      value: floor,
    };
  });

  const permissionGroupFilterOptions: {
    label: string;
    value: string;
  }[] =
    groupRoles?.map(gr => ({
      label: gr.name,
      value: gr._id,
    })) ?? [];

  const companyFilterOptions =
    companies?.map(({ name, _id }) => {
      return {
        label: name,
        value: _id,
      };
    }) ?? [];

  const userStatusFilterOptions = [
    {
      label: t('web.admin.channel.users.all.table.filter.userStatus.enabled'),
      value: UserStatusEnum.Active,
    },
    {
      label: t('web.admin.channel.users.all.table.filter.userStatus.disabled'),
      value: UserStatusEnum.Disabled,
    },
  ];

  const availableFilters: FilterType[] = [
    shouldShowUnitsAndFloors
      ? {
          type: NativeFilterTypes.ArrIncludesSome,
          key: 'units',
          label: 'Units',
          options: unitsFilterOptions,
          customFilterFn: nameListFilterIncludeSome,
        }
      : null,
    shouldShowUnitsAndFloors
      ? {
          type: NativeFilterTypes.ArrIncludesSome,
          key: 'floors',
          label: 'Floors',
          options: floorsFilterOptions,
          customFilterFn: nameListFilterIncludeSome,
        }
      : null,
    {
      type: NativeFilterTypes.ArrIncludesSome,
      key: 'groupRoles',
      label: t(
        `web.admin.channel.users.all.table.filter.${
          isMFExperience ? 'permissionGroup' : 'team'
        }`
      ),
      isPromoted: true,
      options: permissionGroupFilterOptions,
    },
    isUserMgmtPolishUsersEnabled
      ? {
          type: NativeFilterTypes.Multiselect,
          key: 'userStatus',
          label: t(`web.admin.channel.users.all.table.userStatus`),
          isPromoted: true,
          options: userStatusFilterOptions,
        }
      : null,
    !isMFExperience
      ? {
          type: NativeFilterTypes.ArrIncludesSome,
          key: 'company',
          label: t('web.admin.channel.users.all.table.company'),
          options: companyFilterOptions,
          isPromoted: true,
          customFilterFn: nameListFilterIncludeSome,
        }
      : null,
  ].filter(filter => filter !== null) as FilterType[];

  Object.freeze(availableFilters);

  const handleExportToCSV = (users: Row[]) => {
    const csvData: any[] | Papa.UnparseObject<any> = [];
    const headers = columns.map(column => column.header);

    // Custom added columns in the CSV
    headers.push('Verified');

    csvData.push(headers);

    users.forEach(user => {
      const rowData: any[] = [];

      columns.map(column => {
        // @ts-ignore
        const value = column.renderForCSV(user);

        rowData.push(value);
      });

      // Custom added row data in the CSV
      rowData.push(String(user.isEmailVerified));

      csvData.push(rowData);
    });

    const csv = Papa.unparse(csvData);
    let filename = `active-users-${Date.now()}.csv`;

    if (isUserMgmtPolishUsersEnabled) {
      filename = getUserCSVExportFilename(channel);
    }

    makeFileDownload({
      name: filename,
      contents: csv,
      type: 'application/csv',
    });
  };

  const exportAll = async () => {
    window.Alert.loading({
      children: (
        <div style={{ textAlign: 'center', fontSize: '16px' }}>
          {t(
            'web.admin.channel.userManagement.table.export.all.preparingYourFile'
          )}
          <br />
          {t('web.admin.channel.userManagement.table.export.all.itMayTakeTime')}
        </div>
      ),
      disableCloseOnBackgroundClick: true,
    });

    try {
      const url = `${appUrl}${generatePath(routes.exportChannelUsers, {
        channelId,
      })}?${stringify(searchParams)}`;

      if (isUserMgmtPolishUsersEnabled) {
        const customFilename = getUserCSVExportFilename(channel);

        await downloadFile(url, customFilename);

        return;
      }

      await downloadFile(url);
    } finally {
      window.Alert.hide();
    }
  };

  const exportCurrentPage = (currentPageData: Row[] = []) => {
    handleExportToCSV(currentPageData);
  };

  const exportOptions = [
    {
      label: t('web.admin.channel.userManagement.table.export.currentPage'),
      onClick: exportCurrentPage,
    },
    {
      label: t('web.admin.channel.userManagement.table.export.all'),
      onClick: exportAll,
    },
  ];

  const importAction = {
    label: t('Import'),
    onClick: addUsersRedirect,
  };

  const handleCloseEmailVerifyModal = () => {
    setVerifyEmailModalOpen(false);
  };

  const bulkSelectedUnverifiedUsers = bulkSelection.filter(
    ({ isEmailVerified }) => !isEmailVerified
  );
  const shouldShowBulkVerifyEmailAction =
    canUserVerifyEmail && bulkSelectedUnverifiedUsers.length > 0;
  const isAnyCompanyMemberSelected = bulkSelection.some(
    ({ company }) => !!company?.length
  );
  const bulkActions: BulkAction[] = [
    ...(canUserResetPassword
      ? [
          {
            label:
              'web.admin.channel.users.all.table.bulkActions.resetPassword',
            onClick: () => {
              setResetPasswordModalOpen(true);
              simpleTrack(
                ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_RESET_PASSWORD_START,
                {
                  channelId: channel?._id,
                  channelName: channel?.name,
                  me: authUser?._id,
                  userIds: bulkSelection.map(({ id }) => id),
                }
              );
            },
          },
        ]
      : []),
    ...(canUserRemoveUsers
      ? [
          {
            label: 'web.admin.channel.users.all.table.bulkActions.removeUser',
            onClick: () => {
              setBulkRemoveUserModalOpen(true);
              simpleTrack(
                ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_USER_START,
                {
                  channelId: channel?._id,
                  channelName: channel?.name,
                  me: authUser?._id,
                  userIds: bulkSelection.map(({ id }) => id),
                }
              );
            },
          },
        ]
      : []),
    ...(shouldShowBulkVerifyEmailAction
      ? [
          {
            label: 'web.admin.channel.users.all.table.bulkActions.verifyEmail',
            onClick: () => {
              setBulkVerifyEmailModalOpen(true);
              simpleTrack(
                ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_VERIFY_EMAIL_START,
                {
                  channelId: channel?._id,
                  channelName: channel?.name,
                  me: authUser?._id,
                  userIds: bulkSelectedUnverifiedUsers.map(({ id }) => id),
                }
              );
            },
          },
        ]
      : []),
    ...(isBuildingChannel(channel) && canUserAddOrRemoveTenants
      ? [
          {
            renderAction: () => (
              <PopupButton
                style={{ marginRight: '0.5rem' }}
                items={[
                  ...(canUserAddTenants
                    ? [
                        {
                          label: t(
                            'web.admin.channel.users.all.table.bulkActions.company.addCompanies'
                          ),
                          onSelect: () => {
                            setBulkAddCompaniesModalOpen(true);
                            simpleTrack(
                              ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_COMPANIES_START,
                              {
                                channelId: channel?._id,
                                channelName: channel?.name,
                                me: authUser?._id,
                                userIds: bulkSelection.map(({ id }) => id),
                              }
                            );
                          },
                        },
                      ]
                    : []),
                  ...(canUserRemoveTenants
                    ? [
                        {
                          label: t(
                            'web.admin.channel.users.all.table.bulkActions.company.removeCompanies'
                          ),
                          onSelect: () => {
                            simpleTrack(
                              ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_COMPANIES_START,
                              {
                                channelId: channel?._id,
                                channelName: channel?.name,
                                me: authUser?._id,
                                userIds: bulkSelection.map(({ id }) => id),
                              }
                            );
                            setBulkRemoveCompaniesModalOpen(true);
                          },
                          isDisabled: !isAnyCompanyMemberSelected,
                        },
                      ]
                    : []),
                ]}
                variant="secondary"
                size="large"
              >
                {t('web.admin.channel.users.all.table.bulkActions.company')}
              </PopupButton>
            ),
          },
        ]
      : []),
    {
      renderAction: () => (
        <PopupButton
          items={[
            {
              label: t(
                'web.admin.channel.users.all.table.bulkActions.team.addTeams'
              ),
              onSelect: () => {
                setBulkAddTeamsModalOpen(true);
                simpleTrack(
                  ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_ADD_TO_TEAMS_START,
                  {
                    channelId: channel?._id,
                    channelName: channel?.name,
                    me: authUser?._id,
                    userIds: bulkSelection.map(({ id }) => id),
                  }
                );
              },
            },
            {
              label: t(
                'web.admin.channel.users.all.table.bulkActions.team.removeTeams'
              ),
              onSelect: () => {
                setBulkRemoveTeamsModalOpen(true);
                simpleTrack(
                  ANALYTIC_KEYS.ANALYTIC_USER_MANAGEMENT_BULK_REMOVE_FROM_TEAMS_START,
                  {
                    channelId: channel?._id,
                    channelName: channel?.name,
                    me: authUser?._id,
                    userIds: bulkSelection.map(({ id }) => id),
                  }
                );
              },
            },
          ]}
          variant="secondary"
          size="large"
        >
          {t('web.admin.channel.users.all.table.bulkActions.team')}
        </PopupButton>
      ),
    },
  ];

  const handleSelectionChange = (selectedRowIds: string[]) => {
    const bulkSelectedRowIds = bulkSelection.map(({ id }) => id);

    const userIdsToInsert = difference(selectedRowIds, bulkSelectedRowIds);
    const userIdsToRemove = difference(bulkSelectedRowIds, selectedRowIds);
    const usersToInsert = userIdsToInsert
      .map(id => data.find(user => user.id === id))
      .filter(user => !!user) as Row[];
    const bulkSelectionUpdate = bulkSelection.filter(
      user => !userIdsToRemove.includes(user.id)
    );

    bulkSelectionUpdate.push(...usersToInsert);

    setBulkSelection(bulkSelectionUpdate);
  };

  const handleCloseResetPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };

  const handleCloseBulkVerifyEmailModal = () => {
    setBulkVerifyEmailModalOpen(false);
  };

  const handleCloseBulkRemoveUserModal = () => {
    setBulkRemoveUserModalOpen(false);
  };

  const handleCloseBulkAddCompanies = () => {
    setBulkAddCompaniesModalOpen(false);
  };

  const handleBulkAddCompaniesSuccess = (
    addedCompanies: { _id: string; name: string }[]
  ) => {
    setBulkSelection(prevBulkSelection => {
      const userCompanyIds = prevBulkSelection.flatMap(user =>
        user.company.map(({ _id }) => _id)
      );

      addedCompanies = addedCompanies.filter(
        ({ _id }) => !userCompanyIds.includes(_id)
      );

      return prevBulkSelection.map(user => ({
        ...user,
        company: [...user.company, ...addedCompanies],
      }));
    });

    refetch();
  };

  const handleCloseBulkRemoveCompanies = () => {
    setBulkRemoveCompaniesModalOpen(false);
  };

  const handleBulkRemoveCompaniesSuccess = (removedCompanyIds: string[]) => {
    setBulkSelection(prevBulkSelection =>
      prevBulkSelection.map(user => ({
        ...user,
        company: user.company.filter(
          ({ _id }) => !removedCompanyIds.includes(_id)
        ),
      }))
    );
    refetch();
  };

  const handleBulkAddTeamsSuccess = (
    addedGroupRoles: {
      userId: string;
      groupRoles: {
        _id: string;
        name: string;
        userGroupRoleId: string;
      }[];
    }[]
  ) => {
    const addedGroupRolesMap = new Map<
      string,
      (typeof addedGroupRoles)[number]['groupRoles']
    >(addedGroupRoles.map(({ userId, groupRoles }) => [userId, groupRoles]));

    setBulkSelection(prevBulkSelection => {
      return prevBulkSelection.map(user => {
        const userGroupRolesAdded = addedGroupRolesMap.get(user.id);

        if (!userGroupRolesAdded || userGroupRolesAdded.length === 0) {
          return user;
        }

        return {
          ...user,
          groupRoles: [...user.groupRoles, ...userGroupRolesAdded],
        };
      });
    });

    refetch();
  };

  const handleBulkRemoveTeamsSuccess = (removedTeamIds: string[]) => {
    setBulkSelection(prevBulkSelection =>
      prevBulkSelection.map(user => ({
        ...user,
        groupRoles: user.groupRoles.filter(
          ({ _id }) => !removedTeamIds.includes(_id)
        ),
      }))
    );

    refetch();
  };

  const verifiedUsersCount =
    bulkSelection.length - bulkSelectedUnverifiedUsers.length;

  return (
    <div className={styles.allUsers}>
      <Table
        columns={columns}
        data={data}
        pagination="server"
        queryStringsEnabled
        totalRows={totalUsers}
        hasKeywordFilter
        rowActions={rowActions}
        filters={availableFilters}
        tableKey="users.allUsersTable"
        onPrimaryAction={inviteUsersRedirect}
        primaryActionLabel={inviteUsersLabel}
        exportOptions={exportOptions}
        {...(loggedInUser?.isSuperUser && isTeamsBulkAddUsersEnabled
          ? { importAction }
          : {})}
        isLoading={loading}
        isSelectable={isUserMgmtPolishUsersEnabled}
        bulkActions={bulkActions}
        rowSelection={bulkSelection.map(({ id }) => id)}
        onSelectionChange={handleSelectionChange}
        rowSelectionExternal
        getRowId={({ id }) => id}
      />
      <UpdateUserCompanyModal
        isModalOpen={isUpdateCompanyModalOpen}
        setModalClose={setUpdateCompanyModalOpen}
        selectedUser={selectedUser}
        channelId={channelId}
        canUserAddTenants={canUserAddTenants}
        canUserRemoveTenants={canUserRemoveTenants}
        refetch={refetch}
      />
      <VerifyUserEmailModal
        isOpen={isVerifyEmailModalOpen}
        onClose={handleCloseEmailVerifyModal}
        selectedUser={selectedUser}
        channelId={channelId}
        refetch={refetch}
      />
      {isUserMgmtPolishUsersEnabled && (
        <BulkResetPasswordModal
          isOpen={isResetPasswordModalOpen}
          onClose={handleCloseResetPasswordModal}
          bulkSelectedUsers={bulkSelection}
        />
      )}
      {isUserMgmtPolishUsersEnabled && (
        <BulkVerifyUserEmailModal
          isOpen={isBulkVerifyEmailModalOpen}
          onClose={handleCloseBulkVerifyEmailModal}
          unverifiedUsers={bulkSelectedUnverifiedUsers}
          verifiedUsersCount={verifiedUsersCount}
          onSuccess={refetch}
        />
      )}
      {isUserMgmtPolishUsersEnabled && (
        <BulkRemoveUserModal
          isOpen={isBulkRemoveUserModalOpen}
          onClose={handleCloseBulkRemoveUserModal}
          onSuccess={() => {
            clearSelection();
            refetch();
          }}
          bulkSelectedUsers={bulkSelection}
        />
      )}
      {isUserMgmtPolishUsersEnabled &&
        canUserAddTenants &&
        isBuildingChannel(channel) && (
          <BulkAddCompaniesModal
            isOpen={isBulkAddCompaniesModalOpen}
            bulkSelectedUsers={bulkSelection}
            onClose={handleCloseBulkAddCompanies}
            onSuccess={handleBulkAddCompaniesSuccess}
          />
        )}
      {isUserMgmtPolishUsersEnabled &&
        canUserRemoveTenants &&
        isBuildingChannel(channel) && (
          <BulkRemoveCompaniesModal
            isOpen={isBulkRemoveCompaniesModalOpen}
            bulkSelectedUsers={bulkSelection}
            onClose={handleCloseBulkRemoveCompanies}
            onSuccess={handleBulkRemoveCompaniesSuccess}
          />
        )}
      {isUserMgmtPolishUsersEnabled ? (
        <BulkAddTeamsModal
          isOpen={isBulkAddTeamsModalOpen}
          bulkSelectedUsers={bulkSelection}
          onClose={() => setBulkAddTeamsModalOpen(false)}
          onSuccess={handleBulkAddTeamsSuccess}
        />
      ) : null}
      {isUserMgmtPolishUsersEnabled ? (
        <BulkRemoveTeamsModal
          isOpen={isBulkRemoveTeamsModalOpen}
          bulkSelectedUsers={bulkSelection}
          onClose={() => setBulkRemoveTeamsModalOpen(false)}
          onSuccess={handleBulkRemoveTeamsSuccess}
        />
      ) : null}
    </div>
  );
}

const nameListFilterIncludeSome = (
  row: any,
  columnId: string,
  filterValue: any[]
) => {
  if (!filterValue || !filterValue.length) {
    return true;
  }

  const cellValue: NameList = row.getValue(columnId);

  return cellValue.some(value => filterValue.includes(value.name));
};
