import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';

const OutlookCalendar: FeatureDefinition = {
  name: FeatureNameEnum.OutlookCalendar,
  requiresInteraction: true,
  friendlyName: 'web.content.feature.outlookCalendarSync.friendlyName',
  description: 'web.content.feature.outlookCalendarSync.description',
  properties: {
    resourceId: {
      friendlyName:
        'web.content.feature.outlookCalendarSync.properties.resourceId.friendlyName',
      description:
        'web.content.feature.outlookCalendarSync.properties.resourceId.description',
      type: 'String',
      editable: true,
      showControl: true,
      isArray: false,
      forDisplay: true,
      hidden: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default OutlookCalendar;
