export enum UserLoginStatusEnum {
  System = 'System',
  // Active means it is a user login that is in use, but not verified
  Active = 'Active',
  // Pending means it is active and awaiting verification
  Pending = 'Pending',
  // Verified means it is active and verified
  Verified = 'Verified',
  // Disabled means this is not active.
  Disabled = 'Disabled',
  // Bounced delivery failed to this endpoint
  Bounced = 'Bounced',
  // Complaint the user actively complained about delivery to this endpoint
  Complaint = 'Complaint',
  NotDefined = 'NotDefined',
}

export enum UserLoginTypeEnum {
  OAuth = 'OAuth',
  Email = 'Email',
  Phone = 'Phone',
  MagicLink = 'MagicLink',
  ShopifyMultipass = 'ShopifyMultipass',
  Auth0 = 'Auth0',
  NotDefined = 'NotDefined',
}

// this needs to match IntegrationProviderEnum
export enum UserLoginProviderEnum {
  Essensys = 'Essensys',
  LinkedIn = 'LinkedIn',
  ParkWhiz = 'ParkWhiz',
  Microsoft = 'Microsoft',
  ProxyClick = 'ProxyClick',
  Safetrust = 'Safetrust',
  AzureAD = 'MicrosoftAzure',
  Google = 'GoogleWorkplace',
  Okta = 'Okta',
  Apple = 'Apple',
  Auth0 = 'Auth0',
  NotDefined = 'NotDefined',
}

export enum UserHeaderAuthType {
  // jwt uses jwt with token and jti in Authorization heaader
  JWT = 'JWT',
  // bearer uses apiKey in Authorization header
  BEARER = 'Bearer',
}
