import React from 'react';

import { v4 as uuid } from 'uuid';

import { BLOCK_FOR } from 'constants-content';

import Dropdown from '../form/Dropdown';

type OwnProps = {
  onChange?: (...args: any[]) => any;
  onValueChange?: (...args: any[]) => any;
  name?: string;
  color?: string;
  schema?: {
    label?: string;
    value?: string;
  };
  for?: any[]; // TODO: PropTypes.oneOf(Object.values(BLOCK_FOR))
  initialValue?: string | null;
  placeholder?: string;
  value?: string | number | null;
};

type Props = OwnProps;

function ContentBlockDropdown({
  name = `Dropdown-${uuid()}`,
  color = 'primary',
  schema = {
    label: 'label',
    value: 'value',
  },
  for: propFor = [BLOCK_FOR.CONTENT],
  initialValue = null,
  placeholder = 'Select Card',
  value = null,
  onChange = () => {},
  onValueChange = () => {},
  ...restProps
}: Props) {
  const props = {
    ...restProps,
    name,
    color,
    schema,
    for: propFor,
    initialValue,
    placeholder,
    value,
    onChange,
    onValueChange,
  };

  const items = (props as any).blocks
    .filter((card: any) => (props as any).for.includes(card.for))
    .map((card: any) => ({
      label: card.name,
      value: card._id,
    }));

  // @ts-expect-error
  return <Dropdown {...props} items={items} />;
}

export default ContentBlockDropdown;
