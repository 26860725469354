import React from 'react';

import { useTranslation } from 'react-i18next';

import parseValue from 'lane-shared/renderers/v5/parseValue';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import {
  TAnyFeatureType,
  FeatureInstance,
  FeatureProperties,
} from 'lane-shared/types/FeatureInstance';
import { ContentType } from 'lane-shared/types/content/Content';
import { PropertyType } from 'lane-shared/types/properties/Property';

import Property from 'components/builder/properties/input/Property';
import Label from 'components/general/Label';

import styles from './FeatureAutoRenderer.scss';

type PropertyRendererProps<T extends FeatureProperties = TAnyFeatureType> = {
  properties: { [key: string]: PropertyType };
  timeZone: string;
  channel: Channel;
  content: ContentType;
  contentFeature: FeatureInstance<T>;
  onFeatureUpdated: (nextT: Partial<T>) => void;
};

export default function PropertyRenderer<
  T extends FeatureProperties = TAnyFeatureType,
>({
  properties,
  timeZone,
  content,
  contentFeature,
  onFeatureUpdated,
}: PropertyRendererProps<T>) {
  const renderableProperties = Object.entries(properties)
    .filter(([, property]) =>
      // TODO: `parseValue` has weak type definitions
      parseValue({
        value: property.showControl || true,
        props: contentFeature.feature,
        propDefs: properties,
      } as any)
    )
    .filter(([, property]) => !property.hidden);

  const { t } = useTranslation();

  return (
    <section className={styles.values}>
      {renderableProperties.map(([key, property]) => (
        <div key={key} className={styles.property}>
          {!property.isArray && (
            <Label TooltipComponent={t(property.description || '')}>
              {t(property.friendlyName || key)}
            </Label>
          )}
          <Property
            timeZone={timeZone}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<Channel>' is not assignable to t... Remove this comment to see the full error message
            channel={content.channel}
            property={property}
            value={contentFeature.feature[key]}
            onChange={value => onFeatureUpdated({ [key]: value } as Partial<T>)}
          />
        </div>
      ))}
    </section>
  );
}
