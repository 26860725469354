import React, { useState, useEffect, useCallback, useContext } from 'react';

import { Icon, Button, Loading } from 'design-system-web';
import cx from 'classnames';
import { useChannelProfileQuery, useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { hasPermission } from 'lane-shared/helpers';
import { safeConvertToUUID } from 'uuid-encoding';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';
import {
  getChannelAccessControlGroups,
  getChannelsForAccessManager,
  getChannelsForAccessUser,
  updateUserAccessControlGroups,
  getCredentialGroups,
} from 'lane-shared/graphql/accessControl';
import { getConsolidatedChannelUsers } from 'lane-shared/graphql/user';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { Channel } from 'packages/lane-shared/types/ChannelType';

import CardContainer from 'components/cards/CardContainer';
import KeyCardDetails from '../../components/manage-user-access/keyCardDetails';
import KeyCardActions from '../../components/manage-user-access/keyCardActions';
import CircleListView from 'components/lane/CircleListView';
import { AdminPage, PageHeader } from 'components/layout';
import { H4, H5, XS, M } from 'components/typography';

import { ErrorModal } from '../../components/user-access/ErrorModal';
import { UnsavedChangesPrompt } from '../../components/user-access/UnsavedChangesPrompt';
import { UserAccessMultiselect } from '../../components/user-access/UserAccessMultiselect';
import { computeSelectedACGs } from '../../helpers/computeSelectedACGs';
import { isAccessControlGroupsListSame } from '../../helpers/isAccessControlGroupsListSame';
import { buildLocationData } from '../../helpers/buildLocationData';
import {
  UserInfo,
  AccessControlGroup,
  AccessGroupSelectItem,
  CredentialGroup,
  ChannelKeycardData,
  KeycardPickerData,
  CredentialGroupStatus,
} from '../../types/AccessControl';

import styles from './styles.scss';
import { convertToUserAccessGroupSelectItem } from '../../helpers/convertToUserAccessGroupSelectItem';
import { Location } from '../../components/user-access/UserAccessLocationPicker';

import { PERMISSION_KEYS } from 'constants-permissions';
import { Label } from 'components';
import { ChannelProfileType } from 'lane-shared/hooks/useChannelProfileQuery';

interface ChannelToUsers {
  [key: string]: {
    currentUser: UserInfo;
    user: UserInfo;
    accessGroups: Map<string, AccessControlGroup>;
  };
}

enum ManageUserTabs {
  location = 'location',
  mobileKeycards = 'mobileKeycards',
}

interface ChannelToSelectedAccessGroups {
  [key: string]: AccessGroupSelectItem[];
}

type ChannelLoadingState = {
  [channelId: string]: boolean;
};

const hasAnyPermission = (
  user: any,
  permissions: string[],
  channelId: string
) => {
  return (
    user.isSuperUser ||
    (channelId && hasPermission(user.roles, permissions, channelId))
  );
};

export function ManageUserAccessPage({
  channel,
}: {
  channel: Channel | ChannelProfileType;
}) {
  const { userId, selectedChannelId } = useParams<{
    userId: string;
    selectedChannelId: string;
  }>();

  // here we are fetching the channel info which is passed as query param in the url
  const { channel: userChannel } = useChannelProfileQuery({
    channelId: selectedChannelId,
  });

  // this is the existing in the url
  const routeChannel = { ...channel };

  channel = userChannel || channel;

  const { t } = useTranslation();
  const isKeycardFeatureEnabled = useFlag(FeatureFlag.AccessKeycard, false);

  const [loading, setLoading] = useState(true);
  const [saveChangesLoading, setSaveChangesLoading] =
    useState<ChannelLoadingState>({});
  const [user, setUser] = useState<null | UserInfo>(null);

  const [selectedAcgs, setSelectedAcgs] =
    useState<ChannelToSelectedAccessGroups>(
      {} as ChannelToSelectedAccessGroups
    );
  const [hasError, setHasError] = useState<{
    hasError: boolean;
    channelId: string;
  }>({ hasError: false, channelId: '' });
  const [currentUser, setCurrentUser] = useState<UserInfo | null>(null);
  const [channelsToUsers, setChannelsToUsers] = useState<ChannelToUsers>({});
  const [keyCardData, setKeyCardData] = useState<Partial<CredentialGroup>>({});
  const [keyCardPickerData, setKeyCardPickerData] = useState<{
    [key in string]: KeycardPickerData;
  }>({});
  const [keyCardChannelData, setKeyCardChannelData] =
    useState<ChannelKeycardData>({});
  const [credentialGroups, setCredentialGroups] = useState<CredentialGroup[]>(
    []
  );
  const [selectedTab, setSelectedTab] = useState<ManageUserTabs>(
    ManageUserTabs.location
  );
  const currentUserContext = useContext(UserDataContext);
  const { channels } = useContext(ChannelsContext);

  const fetchUser = async (channelId: string, userId: string) => {
    try {
      const { data } = await getClient().query({
        query: getConsolidatedChannelUsers,
        variables: {
          channelId,
          userIds: [userId, currentUserContext?.user?._id],
        },
        fetchPolicy: 'no-cache',
      });

      const users: UserInfo[] = data.getChannelUsers;

      return {
        currentUser:
          users.find(user => user._id === currentUserContext.user?._id) || null,
        user: users.find(user => user._id === userId) || null,
      };
    } catch (err) {
      console.error(err);
    }

    return { currentUser: null, user: null };
  };

  const fetchAccessUserChannels = async (userId: string) => {
    try {
      const { data } = await getClient().query({
        query: getChannelsForAccessUser,
        variables: {
          userId,
        },
        fetchPolicy: 'no-cache',
      });

      return data.getChannelsForAccessUser;
    } catch (err) {
      console.error(err);
    }

    return [];
  };

  const fetchAccessControlGroups = async (channelId: string) => {
    const acgMap = new Map<string, AccessControlGroup>([]);

    try {
      const { data } = await getClient().query({
        query: getChannelAccessControlGroups,
        variables: {
          channelId,
        },
        fetchPolicy: 'no-cache',
      });

      data.getChannelAccessControlGroups.forEach((acg: AccessControlGroup) => {
        acgMap.set(acg.id, acg);
      });

      return acgMap;
    } catch (err) {
      console.error(err);
    }

    return acgMap;
  };

  const fetchData = useCallback(async () => {
    if (!channel?._id || !userId) return;

    setLoading(true);
    const channelsToUsers: ChannelToUsers = {};
    const authorizedChannels = await fetchAccessUserChannels(userId);

    for (const authorizedChannel of authorizedChannels) {
      const { currentUser, user } = await fetchUser(
        authorizedChannel.channelId,
        userId
      );

      if (currentUser && user) {
        const accessGroups = await fetchAccessControlGroups(
          authorizedChannel.channelId
        );

        channelsToUsers[authorizedChannel.channelId] = {
          currentUser,
          user,
          accessGroups,
        };
      }
    }

    const currentChannelToUsers =
      channelsToUsers[channel._id] ?? Object.values(channelsToUsers)[0];

    getChannelOnPermissions(currentChannelToUsers?.user, channel);
    setChannelsToUsers(channelsToUsers);
    setUser(currentChannelToUsers?.user);
    setCurrentUser(currentChannelToUsers?.currentUser);
    userAccessControlGroups(channelsToUsers);

    setLoading(false);
  }, [channel?._id, userId]);

  const userAccessControlGroups = (channelsToUsers: any) => {
    const channelsToSelectedAccessGroups: any = {};

    Object.keys(channelsToUsers).forEach(channelId => {
      if (channelsToUsers[channelId].user) {
        const user = channelsToUsers[channelId].user;
        const accessGroups = channelsToUsers[channelId].accessGroups;
        const userAcgs = user.accessControlGroupIds
          .filter((id: string) => accessGroups.has(id))
          .map((acg: string) => {
            const acgInfo = accessGroups.get(acg);

            return {
              label: acgInfo?.name || '',
              value: acg,
              provider: acgInfo?.provider || '',
              channelId: acgInfo?.channelId || '',
            } as AccessGroupSelectItem;
          });

        channelsToSelectedAccessGroups[channelId] = userAcgs;
      }
    });

    setSelectedAcgs(channelsToSelectedAccessGroups);
  };

  const fetchAllChannels = async (channelId: string) => {
    try {
      const { data } = await getClient().query({
        query: getChannelsForAccessManager,
        variables: {
          channelId,
        },
        fetchPolicy: 'no-cache',
      });

      return buildLocationData(data.getChannelsForAccessManager, channel._id);
    } catch (err) {
      console.error(err);
    }

    return [];
  };

  const getChannelOnPermissions = async (
    selectedUser: any,
    channel: Channel | ChannelProfileType
  ) => {
    const hasAccessManagerPermissions = hasAnyPermission(
      currentUserContext.user,
      [
        PERMISSION_KEYS.PERMISSION_ADMIN,
        PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL,
      ],
      channel?._id
    );

    if (hasAccessManagerPermissions) {
      const channels: Location[] = await fetchAllChannels(channel?._id);

      handleChannelsData(channels);

      if (channels.length) {
        const orgIds = channels.map(channel => channel?.id);

        fetchCredentialGroups(selectedUser?._id, orgIds);
      }
    }
  };

  const fetchCredentialGroups = async (
    vtsUserId: string,
    vtsOrganizationIds: string[]
  ) => {
    try {
      const { data } = await getClient().query({
        query: getCredentialGroups,
        variables: {
          vtsUserId,
          vtsOrganizationIds,
        },
        fetchPolicy: 'no-cache',
      });

      setCredentialGroups(data?.getCredentialGroups);
    } catch (err) {
      console.error(err);
    }
  };

  const getSelectedAccessControlGroups = (
    accessGroups: Map<string, AccessControlGroup>,
    channelId: string
  ): AccessControlGroup[] => {
    return Array.from(accessGroups.values()).filter(
      acg =>
        selectedAcgs[channelId] ??
        [].some((selected: any) => selected.value === acg.id)
    );
  };

  const handleAccessGroupSelect =
    (channelId: string) => (acgs: AccessGroupSelectItem[]) => {
      setSelectedAcgs({
        ...selectedAcgs,
        [channelId]: acgs,
      });
    };

  const onACGUpdateSubmit = async (channelId: string) => {
    if (!channelsToUsers[channelId]?.user) return;

    const updatedAccessControlGroupList = computeSelectedACGs(
      channelsToUsers[channelId].accessGroups,
      selectedAcgs[channelId],
      channelsToUsers[channelId].user
    );

    if (
      !updatedAccessControlGroupList.assignedAccessControlGroups.length &&
      !updatedAccessControlGroupList.revokedAccessControlGroups.length
    )
      return;

    try {
      setSaveChangesLoading(prevState => ({
        ...prevState,
        [channelId]: true,
      }));
      await getClient().mutate({
        mutation: updateUserAccessControlGroups,
        variables: {
          channelId,
          userId,
          assignedAccessControlGroups:
            updatedAccessControlGroupList.assignedAccessControlGroups,
          revokedAccessControlGroups:
            updatedAccessControlGroupList.revokedAccessControlGroups,
        },
      });

      setChannelsToUsers(prevState => ({
        ...prevState,
        [channelId]: {
          ...prevState[channelId],
          user: {
            ...prevState[channelId].user,
            accessControlGroupIds: selectedAcgs[channelId].map(
              selectedAcg => selectedAcg.value
            ),
          },
        },
      }));

      window.Toast.show(
        t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroupsSuccess',
          { userName: channelsToUsers[channelId].user?.name }
        )
      );
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      setHasError({ hasError: true, channelId });
    } finally {
      setSaveChangesLoading(prevState => ({
        ...prevState,
        [channelId]: false,
      }));
    }
  };

  const handleErrorModalRetry = (channelId: string) => {
    setHasError({ hasError: false, channelId: '' });
    onACGUpdateSubmit(channelId);
  };

  const onButtonPress = (type: 'save' | 'discard') => {
    if (type === 'save') {
      Object.keys(channelsToUsers).map(channelId => {
        onACGUpdateSubmit(channelId);
      });
    }
  };

  useEffect(() => {
    if (channel?._id && userId && !selectedChannelId) {
      fetchData();
    }
  }, [channel?._id, userId, fetchData]);

  useEffect(() => {
    if (userChannel?._id && userId) {
      fetchData();
    }
  }, [userChannel?._id, userId, fetchData]);

  useEffect(() => {
    if (credentialGroups?.length && Object.keys(keyCardChannelData).length) {
      handleKeyCardPickerData();
    }
  }, [keyCardChannelData, credentialGroups]);

  const pageHeaderProps = {
    header: user
      ? t('web.admin.accessControl.manageUserAccess.userMobileAccess', {
          userName: user.name,
        })
      : t('web.admin.accessControl.manageUserAccess.breadcrumb.title'),
    breadcrumbs: [
      {
        label: t('web.admin.accessControl.header'),
        url: routes.channelAdminUserAccess.replace(':id', routeChannel?._id),
      },
      {
        label: t('web.admin.accessControl.userAccess.table.mobileAccess'),
        url: routes.channelAdminUserAccess.replace(':id', routeChannel?._id),
      },
      {
        label: t('web.admin.accessControl.manageUserAccess.breadcrumb.title'),
      },
    ],
  };

  const tabs: TabItem[] = [
    {
      value: ManageUserTabs.location,
      label: 'web.admin.accessControl.manageUserAccess.tabs.Locations',
    },
    ...(isKeycardFeatureEnabled
      ? [
          {
            value: ManageUserTabs.mobileKeycards,
            label:
              'web.admin.accessControl.manageUserAccess.tabs.MobileKeycards',
          },
        ]
      : []),
  ];

  const handleTabChange = (tab: ManageUserTabs) => {
    setSelectedTab(tab);
  };

  const status = '●';

  const handleChannelsData = (channels: Location[]) => {
    const channelData: ChannelKeycardData = {};

    channels.forEach(channel => {
      channelData[safeConvertToUUID(channel.id)] = {
        name: channel.name,
        logo: channel.logo,
        keyCardName: `${channel.name} ${t(
          'web.admin.accessControl.manageUserAccess.keycardText'
        )}`,
      };
    });
    setKeyCardChannelData(channelData);
  };

  const handleOnSelectKeyCard = (keycard: any) => {
    setKeyCardData(keycard);
  };

  const handleKeyCardPickerData = useCallback(() => {
    const pickerData: { [key in string]: KeycardPickerData } = {
      [CredentialGroupStatus.ACTIVE]: {
        status: t(
          'web.admin.accessControl.manageUserAccess.credential.status.active'
        ),
        color: '#A3D982',
        keyCards: [],
      },
      [CredentialGroupStatus.ISSUING]: {
        status: t(
          'web.admin.accessControl.manageUserAccess.credential.status.issuing'
        ),
        color: '#FFD87F',
        keyCards: [],
      },
      [CredentialGroupStatus.SUSPENDED]: {
        status: t(
          'web.admin.accessControl.manageUserAccess.credential.status.suspended'
        ),
        color: '#FFD87F',
        keyCards: [],
      },
      [CredentialGroupStatus.REVOKED]: {
        status: t(
          'web.admin.accessControl.manageUserAccess.credential.status.revokedOrExpired'
        ),
        color: '#B5BFCA',
        keyCards: [],
      },
      [CredentialGroupStatus.UNKNOWN]: {
        status: t(
          'web.admin.accessControl.manageUserAccess.credential.status.unknown'
        ),
        color: '#B5BFCA',
        keyCards: [],
      },
    };

    credentialGroups.forEach((group: CredentialGroup) => {
      const credentialModel = {
        id: group.id,
        status: group.status,
        vtsOrganizationId: group.vtsOrganizationId,
        provider: group.provider,
        logo: keyCardChannelData?.[group.vtsOrganizationId]?.logo || '',
        name: `${keyCardChannelData?.[group.vtsOrganizationId]?.name} ${t(
          'web.admin.accessControl.manageUserAccess.keycardText'
        )}`,
        credentialMappings: group.credentials,
      };

      if (group.status) {
        if (
          !Object.keys(keyCardData).length &&
          keyCardChannelData?.[group.vtsOrganizationId]?.name
        ) {
          setKeyCardData(credentialModel);
        }

        let status: string = group.status;

        if (group.status === CredentialGroupStatus.EXPIRED) {
          status = CredentialGroupStatus.REVOKED;
        }

        if (!pickerData[status]) {
          status = CredentialGroupStatus.UNKNOWN;
        }

        pickerData[status]?.keyCards?.push(credentialModel);
      }
    });

    // Remove statuses if no key card in that category
    Object.keys(pickerData).forEach(key => {
      if (pickerData[key].keyCards.length === 0) {
        delete pickerData[key];
      }
    });

    setKeyCardPickerData(pickerData);
  }, [credentialGroups, keyCardChannelData, keyCardData, t]);

  const evaluateKeyCardPicker = () => {
    return Object.keys(keyCardPickerData).map(function (statusType) {
      return keyCardPickerTemplate(statusType);
    });
  };

  const evaluateCredentialKeycardList = (keyCards: any) => {
    return keyCards.map((keyCard: any) => {
      return keyCardListTemplate(keyCard);
    });
  };

  const keyCardPickerTemplate = (statusType: string) => (
    <div className={styles.CredentialTypeBlock}>
      <div className={styles.CredentialStatusBar}>
        <span
          className={styles.CredentialStatusIndicator}
          style={{ color: keyCardPickerData[statusType].color }}
        >
          {status}
        </span>
        <span className={styles.CredentialStatusText}>
          {keyCardPickerData[statusType].status}
        </span>
      </div>

      {evaluateCredentialKeycardList(keyCardPickerData[statusType].keyCards)}
    </div>
  );

  const keyCardListTemplate = (keyCard: any) => (
    <div
      className={cx(
        styles.CredentialsGroupBox,
        keyCard?.id === keyCardData?.id ? styles.Selected : ''
      )}
    >
      <div className={styles.CredentialGroupBar}>
        <div className={styles.CredentialsTextBox}>
          <button
            key={keyCard.vtsOrganizationId}
            className={cx(styles.UserAccessLocationPickerButton)}
            onClick={() => handleOnSelectKeyCard(keyCard)}
            data-test={`user-access-location-picker-${keyCard.id}`}
          >
            <div className={styles.ButtonContent}>
              <div>
                <M className={styles.TruncateKeyCardName}>{keyCard?.name}</M>
                <XS variant="secondary">
                  {keyCard?.credentialMappings?.length}{' '}
                  {keyCard?.credentialMappings?.length === 1
                    ? t(
                        'web.admin.accessControl.manageUserAccess.credentialText'
                      )
                    : t(
                        'web.admin.accessControl.manageUserAccess.credentialsText'
                      )}
                </XS>
              </div>
              {(keyCard.status === CredentialGroupStatus.ACTIVE ||
                keyCard.status === CredentialGroupStatus.SUSPENDED) && (
                <KeyCardActions keyCard={keyCard as CredentialGroup} />
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );

  return user && currentUser && !loading ? (
    <AdminPage>
      <UnsavedChangesPrompt
        when={Object.keys(selectedAcgs)
          .map(channelId => {
            return !isAccessControlGroupsListSame(
              channelsToUsers[channelId].user.accessControlGroupIds,
              selectedAcgs[channelId] ?? []
            );
          })
          .reduce((acc, curr) => acc || curr, false)}
        onButtonPress={onButtonPress}
        path={routes.channelAdminUserAccess.replace(':id', channel?._id)}
      />
      <ErrorModal
        title={t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroups.error.title'
        )}
        description={t(
          'web.admin.accessControl.manageUserAccess.updateAccessControlGroupsFailure',
          {
            userName: user.name,
          }
        )}
        buttonTitle={t('Retry')}
        iconName="x-circle"
        iconColor="#CD4747"
        isOpen={hasError.hasError}
        onClose={() => setHasError({ hasError: false, channelId: '' })}
        onClick={() => handleErrorModalRetry(hasError.channelId)}
      />
      <div className={cx(styles.ManageUserAccessHeader)}>
        <PageHeader {...pageHeaderProps} headerLevel="h3" />
      </div>
      <CardContainer style={{ marginBottom: '24px' }}>
        <div style={{ display: 'flex' }}>
          <CircleListView
            image={imageUrl(user.image)}
            logo={imageUrl(user.logo)}
            name={user.name}
            className={cx(styles.ManageUserAccessUserProfile)}
          >
            <H5>{user.name}</H5>
            <XS variant="secondary">
              {user.companies.length
                ? `${user.companies
                    .map(company => company.name)
                    .join(', ')} | ${user.email}`
                : user.email}
            </XS>
          </CircleListView>
          <Link
            to={routes.channelAdminTeamMember
              .replace(':id', channel._id)
              .replace(':userId', userId)}
            target="_blank"
            style={{ paddingTop: '10px', marginLeft: 'auto' }}
          >
            <div style={{ display: 'inline-flex' }}>
              <div style={{ padding: '0.15rem' }}>
                {t('web.admin.accessControl.manageUserAccess.viewUserProfile')}
              </div>
              <Icon name="ExternalLink" />
            </div>
          </Link>
        </div>
      </CardContainer>
      <TabStrip
        tabs={tabs}
        selected={{ value: selectedTab }}
        onSelectTab={tab => handleTabChange(tab.value as ManageUserTabs)}
        className={styles.ManageUserAccessTabStrip}
      />
      {selectedTab === 'mobileKeycards' &&
        (credentialGroups.length ? (
          <div className={cx(styles.UserAccessContentContainer)}>
            <div className={styles.UserAccessContentContainerRow}>
              <div className={cx(styles.UserAccessContentContainerLeft)}>
                {evaluateKeyCardPicker()}
              </div>
              <KeyCardDetails
                keyCard={keyCardData}
                keyCardChannelData={keyCardChannelData}
              />
            </div>
          </div>
        ) : (
          <div className={cx(styles.NoKeyCardContainer)}>
            {t(
              'web.admin.accessControl.manageUserAccess.credentialMapping.noKeyCards'
            )}
          </div>
        ))}
      {selectedTab === 'location' && (
        <CardContainer>
          <H4 style={{ paddingBottom: '16px' }}>
            {t('web.admin.accessControl.manageUserAccess.assignedAccessGroups')}
          </H4>
          <div className={cx(styles.ManageUserAccessContainer)}>
            {Object.keys(channelsToUsers).map(channelId => {
              const channelName = channels.find(
                channel => channel._id === channelId
              )?.name;

              return (
                channelsToUsers[channelId]?.user &&
                channelsToUsers[channelId]?.accessGroups && (
                  <div
                    className={cx(styles.ManageUserAccessDropdownLabelWrapper)}
                    key={channelId}
                  >
                    {channelName && (
                      <Label
                        className={cx(styles.ManageUserAccessDropdownLabel)}
                      >
                        {channelName}
                      </Label>
                    )}
                    <div
                      className={cx(styles.ManageUserAccessDropdownContainer)}
                    >
                      <div className={cx(styles.ManageUserAccessDropdown)}>
                        <UserAccessMultiselect
                          items={convertToUserAccessGroupSelectItem(
                            Array.from(
                              channelsToUsers[channelId].accessGroups.values()
                            ),
                            channelsToUsers[channelId].currentUser!
                              .accessControlGroupIds,
                            currentUserContext.user?.roles || [],
                            channelId,
                            getSelectedAccessControlGroups(
                              channelsToUsers[channelId].accessGroups,
                              channelId
                            )
                          )}
                          onChange={handleAccessGroupSelect(channelId)}
                          values={selectedAcgs[channelId] ?? []}
                          isFullWidth
                        />
                      </div>
                      <div
                        className={cx(
                          styles.ManageUserAccessConfirmButtonContainer
                        )}
                      >
                        <Button
                          variant="primary"
                          onClick={() => onACGUpdateSubmit(channelId)}
                          disabled={isAccessControlGroupsListSame(
                            channelsToUsers[channelId].user
                              .accessControlGroupIds,
                            selectedAcgs[channelId] ?? []
                          )}
                          size="large"
                          loading={saveChangesLoading[channelId] ?? false}
                        >
                          {t(
                            'web.admin.accessControl.manageUserAccess.saveChanges'
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </CardContainer>
      )}
    </AdminPage>
  ) : (
    <AdminPage>
      <Loading
        testId="manage-user-access-spinner-icon"
        className={styles.loading}
      />
    </AdminPage>
  );
}
