import { LocationType } from '../types/LocationType';
import calculateChannelDistance from './calculateChannelDistance';
import { isBuildingChannel, isTenantLocationChannel } from './channel';
import { TENANT_TYPES } from 'constants-channel';
import { byDistance } from './sort';

function isSubTenantLocationChannel(channel: any) {
  return TENANT_TYPES.includes(channel.type) && channel.isSub && channel.parent;
}

/**
 * Tries to find the best channel for the user, if they have nothing selected
 */
export default function getBestDefaultChannel(
  channels: any,
  location?: LocationType
) {
  function applyDistance(channel: any) {
    return {
      ...channel,
      distance: location
        ? calculateChannelDistance({
            ...location,
            channel,
          })
        : Number.MAX_SAFE_INTEGER,
    };
  }

  // First try child buildings
  const buildings = channels
    .filter(isBuildingChannel)
    .map(applyDistance)
    .sort(byDistance);

  if (buildings.length > 0) {
    return buildings[0];
  }

  // Then try child companies
  const companies = channels
    .filter(isTenantLocationChannel)
    .map(applyDistance)
    .sort(byDistance);

  if (companies.length > 0) {
    return companies[0];
  }

  const subCompanies = channels
    .filter(isSubTenantLocationChannel)
    .map(applyDistance)
    .sort(byDistance);

  if (subCompanies.length > 0) {
    return subCompanies[0];
  }
}
