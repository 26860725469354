import { colors } from 'constants-colors';
import React from 'react';

export default function IconFileAlt() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="url(#paint0_linear)"
        d="M28.5 23.625c0-.281-.281-.563-.563-.563h-7.875a.57.57 0 00-.562.563v1.313c0 .328.234.562.563.562h7.875a.57.57 0 00.562-.563v-1.312zM27.937 27h-7.875a.57.57 0 00-.562.563v1.312c0 .328.234.563.563.563h7.875a.57.57 0 00.562-.563v-1.313c0-.28-.281-.562-.563-.562zM33 18.187c0-.562-.281-1.171-.703-1.593l-3.89-3.89c-.423-.423-1.032-.704-1.595-.704H17.25A2.25 2.25 0 0015 14.25v19.5c0 1.266.984 2.25 2.25 2.25h13.5A2.25 2.25 0 0033 33.75V18.187zm-6-3.75L30.563 18H27v-3.563zm3.75 19.313h-13.5v-19.5h7.5v4.875c0 .656.469 1.125 1.125 1.125h4.875v13.5z"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          x2="47.973"
          y1="48"
          y2="49.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.laneGreen} />
          <stop offset="1" stopColor="#528FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
