import { ContentCategoryEnum } from 'constants-content';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';

export default function getUnitTime(
  reservationType: ReservableFeatureProperties,
  contentCategory: ContentCategoryEnum
): string {
  if (contentCategory === ContentCategoryEnum.DayPass) {
    return 'day pass';
  }

  switch (reservationType?.unitType) {
    case 'Minutes':
      return `hr`;
    case 'Days':
      return reservationType?.units === 1
        ? 'day'
        : `${reservationType?.units} days`;
    default:
      return 'unknown';
  }
}
