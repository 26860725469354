import { DAY_IN_MINUTES } from '../../../helpers/timeRangeSlider';
import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';
import {
  ReservableUnitTypesEnum,
  ReservableUiTypesEnum,
} from '../../../types/features/ReservableFeatureProperties';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import BufferTimeType from './types/BufferTime';
import RelatedSpaces from './types/RelatedSpaces';
import ReservableFeatureRule from './types/ReservableFeatureRule';
import UserNotes from './types/UserNotes';

// need some kind of max, this should be reasonable enough.
const MAX_QUANTITY = 4096;

const Reservable: FeatureDefinition = {
  superUserOnly: false,
  name: FeatureNameEnum.Reservable,
  requiresInteraction: true,
  friendlyName: 'web.content.features.reservable.friendlyName',
  description: 'web.content.features.reservable.description',
  properties: {
    _version: {
      friendlyName: 'Feature version',
      type: 'Number',
      editable: false,
      forDisplay: false,
      default: 2,
    },
    splitReservation: {
      friendlyName: 'Split Reservation',
      description:
        'web.content.features.reservable.properties.splitReservation.description',
      type: 'Boolean',
      editable: true,
      default: false,
    },
    splitReservationSlots: {
      friendlyName: 'Split Reservation Size',
      description:
        'Splits the reservation into chunks of this size based on slot size.',
      type: 'Number',
      editable: true,
      default: 1,
    },
    unitType: {
      friendlyName: 'Time Unit',
      description: `This is the unit type that the time slots will be created in.`,
      type: 'String',
      editable: true,
      default: ReservableUnitTypesEnum.Minutes,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: [
            // TODO: LANE-1446 re-enable Weeks/Months once they are built out
            ReservableUnitTypesEnum.Minutes,
            ReservableUnitTypesEnum.Days,
          ],
        },
      ],
    },
    units: {
      friendlyName: 'Units per slot',
      description:
        'web.content.features.reservable.properties.units.description',
      type: 'Number',
      editable: true,
      default: 30,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: DAY_IN_MINUTES,
        },
      ],
    },
    uiType: {
      friendlyName: 'Display Type',
      description: 'What kind of display do you want to show the end user?',
      type: 'String',
      default: ReservableUiTypesEnum.TimeSlider,
      validators: [
        {
          name: 'In',
          value: Object.values(ReservableUiTypesEnum),
        },
      ],
    },
    maxQuantityPerSlot: {
      friendlyName: 'Max quantity per slot',
      description:
        'web.content.features.reservable.properties.maxQuantityPerSlot.description',
      type: 'Number',
      editable: true,
      default: 1,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: MAX_QUANTITY,
        },
      ],
    },
    maxQuantityPerSlotPerUser: {
      friendlyName: 'Max reservations per user',
      description:
        'web.content.features.reservable.properties.maxQuantityPerSlotPerUser.description',
      type: 'Number',
      editable: true,
      default: 1,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        },
        {
          name: 'Max',
          value: MAX_QUANTITY,
        },
      ],
    },
    dateRange: {
      friendlyName: 'Availability',
      description:
        'web.content.features.reservable.properties.dateRange.description',
      default: null,
      type: 'DateRange',
    },
    mainRule: {
      friendlyName: 'Main Reservable Rules',
      type: 'ReservableFeatureRule',
      default: ReservableFeatureRule.default,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    rules: {
      friendlyName: 'Main Reservable Rules',
      type: 'ReservableFeatureRule',
      default: [],
      isArray: true,
      validators: [
        {
          name: 'ArrayMax',
          value: 16,
        },
      ],
    },
    useCustomTimeSlots: {
      friendlyName: 'Enable Custom Time Slots',
      description:
        'Allows adding custom time slots for this reservable feature',
      type: 'Boolean',
      default: false,
    },
    useWaitlist: {
      friendlyName:
        'web.admin.content.features.reservable.appearance.waitlist.friendlyName',
      description:
        'web.admin.content.features.reservable.appearance.waitlist.description',
      type: 'Boolean',
      default: false,
    },
    customTimeSlots: {
      friendlyName: 'Custom Time Slots',
      type: 'TimeRange',
      default: [],
      isArray: true,
      validators: [
        {
          name: 'ArrayMax',
          value: 128,
        },
      ],
    },
    bufferTime: {
      friendlyName:
        'web.content.features.reservable.properties.bufferTime.friendlyName',
      type: 'BufferTime',
      description:
        'web.content.features.reservable.properties.bufferTime.description',
      default: BufferTimeType.default,
      validators: [
        {
          name: 'Required',
          value: 'false',
        },
      ],
    },
    relatedSpaces: {
      friendlyName:
        'web.content.features.reservable.properties.relatedSpaces.friendlyName',
      type: 'RelatedSpaces',
      description:
        'web.content.features.reservable.properties.relatedSpaces.description',
      default: RelatedSpaces.default,
      validators: [
        {
          name: 'Required',
          value: 'false',
        },
      ],
    },
    userNotes: {
      friendlyName:
        'web.content.features.reservable.properties.userNotes.friendlyName',
      type: 'UserNotes',
      description:
        'web.content.features.reservable.properties.userNotes.description',
      default: UserNotes.default,
      validators: [
        {
          name: 'Required',
          value: 'false',
        },
      ],
    },
  },
  interactionData: {
    reservation: {
      friendlyName:
        'web.content.features.reservable.interactionData.reservation.friendlyName',
      type: 'DateTimeRange',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    reservationId: {
      forDisplay: false,
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
      friendlyName: 'Reservation Id',
      type: 'String',
    },
    userNotes: {
      friendlyName:
        'web.content.features.reservable.interactionData.userNotes.friendlyName',
      type: 'String',
    },
  },
};

export default Reservable;
