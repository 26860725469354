import { v4 as uuid } from 'uuid';

import { DEFAULT_WORK_ORDER_FEATURES } from 'lane-shared/domains/workOrder';
import { objectToArray } from 'lane-shared/helpers';
import { constructProperty } from 'lane-shared/helpers/content';
import Features from 'lane-shared/renderers/v5/features';
import {
  ContentTypeRequirement,
  ContentTypeRequirementEnum,
  FeatureDefinition,
} from 'lane-shared/types/FeatureDefinition';
import { ContentTypeEnum } from 'constants-content';
import { PropertyType } from 'lane-shared/types/properties/Property';

export function featureRequirements() {
  const featuresArray = objectToArray<FeatureDefinition>(Features);
  const requiredFeatures = featuresArray.filter(isFeatureRequired);

  return [...requiredFeatures, ...DEFAULT_WORK_ORDER_FEATURES].map(
    adaptFeature
  );
}

function isFeatureRequired(feature: FeatureDefinition) {
  return (
    !!feature.contentTypeRequirements &&
    !!feature.contentTypeRequirements.find(
      (requirement: ContentTypeRequirement) =>
        requirement.type === ContentTypeEnum.WorkOrder &&
        requirement.requirement === ContentTypeRequirementEnum.Required
    )
  );
}

function adaptFeature(feature: any) {
  const data: {
    [key: string]: PropertyType;
  } = {};

  if (feature.properties) {
    Object.entries(feature.properties).forEach(
      ([key, property]) =>
        (data[key] = constructProperty(property as PropertyType))
    );
  }

  if (feature.feature) {
    return {
      _id: uuid(),
      ...feature,
    };
  }

  return {
    _id: uuid(),
    type: feature.name,
    feature: {
      ...feature,
    },
  };
}
