import * as yup from 'yup';

import { BLOCK_LINK_TYPES, ContentTypeEnum } from 'constants-content';

import slugify from '../helpers/formatters/slugify';
import { frequencies } from '../renderers/v5/features/Reset';
import validateBlockURI from './validateBlockURI';
import validateNotification from './validateCreateContentNotification';
import validateCreateContentPlacement from './validateCreateContentPlacement';
import validateFeature from './validateFeature';
import validateGeo from './validateGeo';
import validateProperty from './validateProperty';

const dateTypes = [
  ContentTypeEnum.Content,
  ContentTypeEnum.Notice,
  ContentTypeEnum.Perk,
  ContentTypeEnum.Promotion,
];

export default yup.object().shape({
  __typename: yup.string().strip(true),
  _id: yup.string().required(),
  _order: yup.number().min(0),
  name: yup.string().trim().min(3).max(75),
  description: yup.string().trim().max(255),
  slug: yup
    .string()
    .trim()
    .nullable()
    .notRequired()
    .test('is-slug', '${path} is not a valid slug', value =>
      value ? value === slugify(value) : true
    ),
  type: yup.string().trim(),
  card: yup.object().shape({
    __typename: yup.string().strip(true),
    _id: yup.string(),
  }),
  channel: yup.object().shape({
    __typename: yup.string().strip(true),
    _id: yup.string(),
  }),
  tags: yup.array().of(yup.string()).max(6),
  isSyndicateable: yup.boolean(),
  color: yup.string(),
  backgroundColor: yup.string(),
  liveDate: yup
    .date()
    .test(
      'liveDate',
      '${path} required for this Content type.',
      function (value) {
        return dateTypes.includes(this.parent.type) ? !!value : true;
      }
    ),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  unpublishDate: yup.date().nullable(),
  interactionEndDate: yup.date().nullable().notRequired(),
  resetPeriod: yup.string().oneOf(frequencies),
  lastReset: yup.date(),
  nextReset: yup.date(),
  geo: validateGeo,
  version: yup.number().min(0).max(1024),
  renderer: yup.number().min(0).max(256),
  // todo: block validation.
  block: yup
    .object()
    .default(null)
    .nullable()
    .test(
      'valid-block',
      'The Block has some invalid properties',
      (value: any) => {
        if (!value) return true;

        const children = value?.properties?.children ?? [];

        for (const child of children) {
          if (child.link && child.link.type === BLOCK_LINK_TYPES.WEB_IN_APP)
            validateBlockURI.validateSync(child.link.uri);
        }

        return true;
      }
    ),
  properties: yup
    .object()
    .test(
      'valid-properties',
      '${path} has invalid property defined.',
      value => {
        if (value) {
          Object.entries(value).forEach(([, property]) =>
            validateProperty.validateSync(property)
          );
        }

        return true;
      }
    ),
  features: yup.array().when('version', {
    is: 5,
    then: yup.array().of(validateFeature),
    otherwise: yup.array(),
  }),
  placements: yup.array().nullable().of(validateCreateContentPlacement),
  // todo: data validation.
  data: yup.object(),
  // todo: state validation
  state: yup.object(),
  // todo: settings validation
  settings: yup.object(),

  integration: yup
    .object()
    .shape({
      __typename: yup.string().strip(true),
      _id: yup.string(),
    })
    .nullable(),

  notifications: yup.array().of(validateNotification),
});
