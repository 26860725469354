import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';

const Inventory: FeatureDefinition = {
  name: FeatureNameEnum.Inventory,
  friendlyName: 'Inventory',
  description: 'Adds tracked inventory to this.',
  requiresInteraction: true,
  superUserOnly: true,
  properties: {
    returnable: {
      friendlyName: 'Returnable',
      description:
        'Can these inventory items be returned? And then given out again.',
      type: 'Boolean',
      editable: true,
      default: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    inventory: {
      friendlyName: 'Item List',
      description: 'A simple list of the inventory items.',
      type: 'Option',
      editable: true,
      isArray: true,
      default: [],
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMax',
          value: 128,
        },
        {
          name: 'ArrayMin',
          value: 1,
        },
      ],
    },
  },
  interactionData: {
    name: {
      friendlyName: 'Inventory Item',
      type: 'String',
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    returned: {
      friendlyName: 'Inventory Returned',
      type: 'Boolean',
      default: false,
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.Source,
          },
        ],
        read: [
          {
            type: SecurityRuleTypeEnum.Source,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.Source,
          },
        ],
      },
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    inventoryId: {
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.Source,
          },
        ],
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
      friendlyName: 'Inventory Item Id',
      type: 'String',
    },
  },
};

export default Inventory;
