import React from 'react';

import { IconButton } from 'components';
import { useTranslation } from 'react-i18next';

import { colors } from 'constants-colors';

import FileInput from '../form/FileInput';
import Button from '../general/Button';

export default function FileSelectorButton({
  className,
  style,
  accept,
  type,
  onFileSelected,
  value,
}: any) {
  const { t } = useTranslation();

  return (
    <div className={className} style={style}>
      <FileInput accept={accept} type={type} onFileSelected={onFileSelected}>
        <Button variant="contained">{t('Add File')}</Button>
      </FileInput>
      {value && (
        <IconButton
          icon="times"
          onClick={() => onFileSelected(null)}
          style={{ border: `1px ${colors.charcoal[6]} solid` }}
        />
      )}
    </div>
  );
}
