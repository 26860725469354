import { useMemo } from 'react';

import { LaneType } from 'common-types';
import { useSortFn } from 'lane-shared/hooks';
import useSectionContentQuery from 'lane-shared/hooks/integrations/shopify/useSectionContentQuery';
import {
  PresentContentFilterTimeEnum,
  PresetContentFilter,
} from 'constants-content';
import { SortDirectionEnum } from 'constants-activate';
import { SortByEnum } from 'lane-shared/types/blocks/Sort';

type Props = {
  channelId: string | undefined;
  sectionId: string | undefined;
  dateRange: LaneType.DateTimeRange;
};

function useSortedSectionContent({ channelId, sectionId, dateRange }: Props) {
  const searchOptions = {
    channelId,
    areFiltersApplied: true,
    filters: [
      {
        type: PresetContentFilter.FeatureReservableAvailableDays,
        filter: {
          ...dateRange,
          enabled: PresentContentFilterTimeEnum.Enabled,
        },
      },
    ],
  };

  const { sectionContent, loading } = useSectionContentQuery({
    sectionId,
    fetchPolicy: 'no-cache',
    requireChannelId: true,
    searchOptions,
  });

  const { sortFn } = useSortFn({
    sortBy: SortByEnum.EndDate,
    sortDirection: SortDirectionEnum.Asc,
  });

  const sortedSectionContent = useMemo(
    () =>
      sectionContent
        .map(({ content }) => content) // unnest the content to sort properly
        .sort(sortFn),
    [sectionContent]
  );

  return { sortedSectionContent, loading };
}

export default useSortedSectionContent;
