import { v4 as uuid } from 'uuid';

import { MetatagFilter } from '../types/filters/MetatagFilter';
import { META_TAG_TYPE_SIMPLE } from 'constants-content';
import constructProperty from './content/constructProperty';

export default function constructFiltersFromMetatags(
  metatags: any[] = []
): MetatagFilter[] {
  return metatags.map(metatag => {
    let value;
    let values;

    if (metatag.type === META_TAG_TYPE_SIMPLE) {
      value = constructProperty(metatag.properties);
    } else {
      values = [];
    }

    return {
      _id: uuid(),
      metatagId: metatag._id,
      metatagType: metatag.type,
      valueType: META_TAG_TYPE_SIMPLE ? metatag.properties.type : undefined,
      value,
      values,
    };
  });
}
