import i18next from 'i18next';
import { EVENT_KEYS } from 'constants-events';
import {
  WorkflowTargetEnum,
  WorkflowWhenContextEnum,
  EVENT_VERBS,
} from 'constants-workflows';
import { extractChannelLanguagesArray } from 'lane-shared/helpers/dynamicLanguages';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import {
  ContentWorkflowPayloadType,
  Workflow,
} from 'lane-shared/types/ContentWorkflowType';

type Props = {
  channel: Channel | undefined;
  contentName: string;
  workflow: Workflow;
  userName: string;
};

export const getStandardDefaultTextPayload = ({
  channel,
  contentName,
  workflow,
  userName,
}: Props) => {
  const channelLanguagesArr = extractChannelLanguagesArray(channel);

  const statusPlaceholder = i18next.t(
    'web.admin.channel.content.workflow.editor.emailBuilder.intro.statusPill.text'
  );

  const forAdmin = ![
    WorkflowTargetEnum.InteractionCreator,
    WorkflowTargetEnum.GuestInviteFeature,
  ].includes(workflow?.target);

  const getDefaultText = (languageCode: string) => {
    if (workflow?.whenContext === WorkflowWhenContextEnum.Content) {
      return i18next.t(
        `{{contentName}} ${
          EVENT_VERBS[workflow.event as keyof typeof EVENT_VERBS]
        }`,
        {
          ...(languageCode && { lng: languageCode }),
          contentName,
        }
      );
    }

    if (
      workflow?.whenContext === WorkflowWhenContextEnum.UserContentInteraction
    ) {
      if (
        workflow.event === EVENT_KEYS.EVENT_CONTENT_INTERACTION_STATUSCHANGE
      ) {
        // add translation
        return i18next.t(
          `{{name}} changed {{contentName}} from {{oldValue}} to {{newValue}}`,
          {
            ...(languageCode && { lng: languageCode }),
            name: userName,
            contentName,
            oldValue: workflow.workflow?.statusFrom || statusPlaceholder,
            newValue: workflow.workflow?.statusTo || statusPlaceholder,
          }
        );
      }

      if (forAdmin) {
        return i18next.t(
          `{{userName}} ${
            EVENT_VERBS[workflow.event as keyof typeof EVENT_VERBS]
          } {{contentName}}`,
          {
            ...(languageCode && { lng: languageCode }),
            userName,
            contentName,
          }
        );
      }

      return i18next.t(
        `{{contentName}} ${
          EVENT_VERBS[workflow.event as keyof typeof EVENT_VERBS]
        }`,
        {
          ...(languageCode && { lng: languageCode }),
          contentName,
        }
      );
    }

    return '';
  };

  const textPayload: Pick<ContentWorkflowPayloadType, 'text' | 'text_l10n'> = {
    text: '',
    text_l10n: {},
  };

  channelLanguagesArr.forEach(({ languageCode, isPrimary }) => {
    const interpolatedText = getDefaultText(languageCode);

    if (languageCode) {
      textPayload.text_l10n![languageCode] = interpolatedText;
    }

    if (isPrimary) {
      textPayload.text = interpolatedText;
    }
  });

  return textPayload;
};
