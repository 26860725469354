import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

const EssensysIntegration: IntegrationDefinition = {
  name: IntegrationProviderEnum.Essensys,
  hasCustomUI: false,
  friendlyName: 'Essensys',
  description: '',
  category: IntegrationCategoryEnum.FlexSpace,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    locationId: {
      friendlyName: 'Location ID',
      description:
        'The location ID of the main Essensys organization (i.e. the clients account like Studio).  This is only set for main locations.',
      type: 'String',
      validators: [],
    },
    accountLocationId: {
      friendlyName: 'Account Location ID',
      description: 'What location does this account belong to.',
      type: 'String',
      validators: [],
    },
    accountId: {
      friendlyName: 'Account ID',
      description:
        'The account ID, this will be set for Channels that have a relationship with the Channel that has a location id set.',
      type: 'String',
      validators: [],
    },
  },
  mobilePermissions: [],
};

export default EssensysIntegration;
