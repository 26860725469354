import { SUPPORTED_SSO_PROVIDERS } from 'constants-user';
import getAzureADUserPhoto from './getAzureADUserPhoto';

export default async function getOAuthProfilePhoto<T>(
  oAuth: T & { loginProvider: string }
): Promise<Blob | undefined> {
  switch (oAuth.loginProvider) {
    case SUPPORTED_SSO_PROVIDERS.MICROSOFT:
      return getAzureADUserPhoto();
    default:
      return undefined;
  }
}
