import { useContext } from 'react';

import { useQuery } from '@apollo/client';

import { ChannelsContext } from '../contexts';
import { userChannelSettingsForMultipleChannels } from '../graphql/user';
import { Channel, ChannelSettingsType } from '../types/ChannelType';
import { UserChannelSettingsType, UserType } from '../types/User';
import useUserDataContext from './useUserDataContext';
import { deriveChannelFromModelItem } from '../helpers/channel/deriveChannelFromModelItem';
import { localizeIndividualModelItem } from '../helpers/dynamicLanguages/localizeIndividualModelItem';
import { getChannelIdFromModelItem } from '../helpers/getChannelIdFromModelItem';
import { useCurrentChannel } from './useCurrentChannel';

export const useMultiLanguage = ({
  user,
  channels,
}: { user?: UserType | null; channels?: Channel[] | null } = {}) => {
  const { channels: channelsFromContext } = useContext(ChannelsContext);
  const { user: userFromContext } = useUserDataContext();
  const userCurrentChannel = useCurrentChannel();

  const { data, loading, error } = useQuery<{
    userChannelSettingsForMultipleChannels: UserChannelSettingsType[];
  }>(userChannelSettingsForMultipleChannels, {
    variables: {
      userId: userFromContext?._id || user?._id,
      channelIds: [],
    },
    skip: !userFromContext?._id && !user?._id,
  });
  const channelSettingsCache: { [channelId: string]: ChannelSettingsType } = {};

  const t = function t({
    model,
    columns,
    debugFunction = () => null,
    user = null,
    undoTranslation = false,
    previewLanguage,
  }: {
    model: any;
    columns?: string[];
    debugFunction?: (printable: string) => void;
    user?: UserType | null;
    undoTranslation?: boolean;
    previewLanguage?: string;
  }) {
    if (loading) {
      debugFunction(`Currently loading data`);
    }

    if (error) {
      debugFunction(
        `Error while loading userChannelSettingsForMultiple Channels: ${JSON.stringify(
          error
        )}`
      );
    }

    if (Array.isArray(model)) {
      return model.map(modelItem =>
        localizeIndividualModelItem({
          modelItem,
          columns,
          user: userFromContext || user,
          debugFunction,
          userChannelSettings:
            data?.userChannelSettingsForMultipleChannels || [],
          channel:
            userCurrentChannel ||
            deriveChannelFromModelItem({
              modelItem,
              channels: channelsFromContext || channels,
              channelSettingsCache,
              debugFunction,
              channelIdToDeriveSettingsFor:
                getChannelIdFromModelItem(modelItem),
            }),
          undoTranslation,
          previewLanguage,
        })
      );
    }

    return localizeIndividualModelItem({
      modelItem: model,
      columns,
      user: userFromContext || user,
      debugFunction,
      userChannelSettings: data?.userChannelSettingsForMultipleChannels || [],
      channel:
        userCurrentChannel ||
        deriveChannelFromModelItem({
          modelItem: model,
          channels: channelsFromContext || channels,
          channelSettingsCache,
          debugFunction,
          channelIdToDeriveSettingsFor: getChannelIdFromModelItem(model),
        }),
      undoTranslation,
      previewLanguage,
    });
  };

  return {
    translate: t,
  };
};
