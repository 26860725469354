import React from 'react';

import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { IntegrationProviderEnum } from 'constants-integrations';

import Line from 'components/general/Line';

import MeetingCard from './MeetingCard';

export default function MeetingList({ interactions }: any) {
  return interactions.map((interaction: any) => {
    const meeting = interaction.data[`_${IntegrationProviderEnum.ProxyClick}`];

    return (
      <div key={interaction._id}>
        <Link
          to={{
            pathname: routes.interaction.replace(':id', interaction._id),
          }}
        >
          <MeetingCard meeting={meeting} status={interaction.status} />
          <Line />
        </Link>
      </div>
    );
  });
}
