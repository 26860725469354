export const flattenColorsObject = (obj: Record<string, any>, prefix = '') => {
  return Object.keys(obj).reduce((acc: Record<string, any>, k) => {
    const pre = prefix.length ? `${prefix}-` : '';

    if (typeof obj[k] === 'object')
      Object.assign(acc, flattenColorsObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];

    return acc;
  }, {});
};
