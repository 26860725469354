import { makeFileDownload } from 'helpers';
import Papa from 'papaparse';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import {
  SHORT_TIME_WITH_TZ,
  SIMPLE_DATE,
} from 'lane-shared/helpers/constants/dates';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { Channel } from 'packages/lane-shared/types/ChannelType';

export const buildCSVRows = (
  rows: any[],
  columns: any[],
  channel: Channel,
  filename: string
) => {
  const csvRows = [];
  const timeZone = getTimeZoneByGeoLocation({
    latitude: channel?.address?.geo[1],
    longitude: channel?.address?.geo[0],
  });
  const header = columns?.map(({ header }) => header) || [];

  csvRows.push(header);
  rows.forEach(row => {
    const values = columns?.map(({ key, renderForCSV }) => {
      const cellValue = row[key];

      switch (key) {
        case 'endDatetime':
        case 'startDatetime':
        case 'checkedInDatetime':
        case 'checkedOutDatetime':
          if (key === 'startDatetime' && filename.includes('past')) {
            return dateFormatter(cellValue, SIMPLE_DATE, timeZone);
          }

          return dateFormatter(cellValue, SHORT_TIME_WITH_TZ, timeZone);
        case 'startDate':
          return dateFormatter(
            renderForCSV(cellValue, row),
            SIMPLE_DATE,
            timeZone
          );
        default:
          return renderForCSV(cellValue, row);
      }
    });

    csvRows.push(values);
  });

  return csvRows;
};

export const exportCSV = (
  rows: any[],
  columns: any[],
  filename: string,
  channel: Channel
) => {
  const csvRows = buildCSVRows(rows, columns, channel, filename);

  const csv = Papa.unparse(csvRows);

  makeFileDownload({
    name: `${filename}`,
    contents: csv,
    type: 'application/csv',
  });

  return csv;
};
