import React, { useContext } from 'react';
import { UserDataContext } from 'lane-shared/contexts';

import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useDeleteGroupRole } from '../hooks';

import { routes } from 'lane-shared/config';
import { useChannelGroupRolesQuery } from 'lane-shared/hooks';
import { hasPermission } from 'lane-shared/helpers';
import { isChannelForCRE } from 'lane-shared/helpers/channel';
import { PERMISSION_KEYS } from 'constants-permissions';

import { simpleDate } from 'lane-shared/helpers/formatters';
import { H3, M } from 'components/typography';

import styles from './styles.scss';
import { Table } from 'design-system-web';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type Row = {
  _id: string;
  name: string;
  stats?: {
    count?: number;
  };
};

export const PermissionGroupsList = ({ channel }: { channel: Channel }) => {
  const { user } = useContext(UserDataContext);

  const { groupRoles } = useChannelGroupRolesQuery({
    channelId: channel?._id,
    includeCounts: true,
    includeWorkplaceMember: true,
  });

  const channelIsOfficeExperience = isChannelForCRE(channel?.experienceType);

  const { handleDelete } = useDeleteGroupRole();

  const { t } = useTranslation();
  const history = useHistory();

  const createRedirect = () => {
    history.push(
      channelIsOfficeExperience
        ? routes.channelAdminTeamsCreate.replace(':id', channel.slug)
        : routes.channelAdminPermissionGroupCreate.replace(':id', channel.slug)
    );
  };

  const isEditPermissionsDisabled = (groupRoleName: string) => {
    return (
      groupRoleName === 'Admin' ||
      (!user?.isSuperUser &&
        !hasPermission(
          user?.roles,
          [
            PERMISSION_KEYS.PERMISSION_ADMIN,
            PERMISSION_KEYS.PERMISSION_GROUPS_UPDATE,
          ],
          channel._id
        ))
    );
  };

  const isDeleteDisabled = ({
    memberCount = 0,
    groupRoleName,
  }: {
    memberCount: number | undefined;
    groupRoleName: string;
  }) => {
    return (
      memberCount !== 0 ||
      groupRoleName === 'Admin' ||
      (!hasPermission(
        user?.roles,
        [
          PERMISSION_KEYS.PERMISSION_ADMIN,
          PERMISSION_KEYS.PERMISSION_GROUPS_DELETE,
        ],
        channel._id
      ) &&
        !user?.isSuperUser)
    );
  };

  const rowActions = [
    {
      label: t('web.pages.portal.admin.channel.team.actions.view'),
      onClick: (row: Row) => {
        const url = (
          channelIsOfficeExperience
            ? routes.channelAdminTeamView
            : routes.channelAdminPermissionGroupView
        )
          .replace(':id', channel.slug)
          .replace(':groupRoleId', row._id);

        history.push(url);
      },
    },
    {
      label: t('web.pages.portal.admin.channel.team.actions.editPermissions'),
      onClick: (row: Row) => {
        const url = (
          channelIsOfficeExperience
            ? routes.channelAdminTeamEdit
            : routes.channelAdminPermissionGroupEdit
        )
          .replace(':id', channel.slug)
          .replace(':groupRoleId', row._id);

        history.push(url);
      },
      isDisabled: (row: Row) => isEditPermissionsDisabled(row.name),
    },
    {
      label: t('web.pages.portal.admin.channel.team.actions.delete'),
      onClick: async (row: Row) => {
        handleDelete({ id: row._id, groupRoleName: row.name });
      },
      isDisabled: (row: Row) =>
        isDeleteDisabled({
          memberCount: row.stats?.count,
          groupRoleName: row.name,
        }),
    },
  ];

  return (
    <div className={styles.GroupRoles}>
      <div className={styles.header}>
        <H3>{t('web.pages.portal.admin.channel.team.pageHeader')}</H3>
        <M>{t('web.pages.portal.admin.channel.team.description')}</M>
      </div>
      <Table
        sorting={{ id: 'name', direction: 'asc' }}
        columns={[
          {
            header: t('web.pages.portal.admin.channel.team.teamHeader'),
            key: 'name',
            renderCell: (groupRoleName, row) => (
              <Link
                to={(channelIsOfficeExperience
                  ? routes.channelAdminTeamView
                  : routes.channelAdminPermissionGroupView
                )
                  .replace(':id', channel.slug)
                  .replace(':groupRoleId', row._id)}
              >
                {groupRoleName}
              </Link>
            ),
          },
          {
            header: t('web.pages.portal.admin.channel.team.createdHeader'),
            key: '_created',
            type: 'date',
            renderCell: (cell: string | undefined) => (
              <span>{cell ? simpleDate(cell) : '--'}</span>
            ),
          },
          {
            header: t('web.pages.portal.admin.channel.team.updatedHeader'),
            key: '_updated',
            type: 'date',
            renderCell: (cell: string | undefined) => (
              <span>{cell ? simpleDate(cell) : '--'}</span>
            ),
          },
          {
            header: t('web.pages.portal.admin.channel.team.members'),
            key: 'stats.count',
            renderCell: count => count || 0,
          },
        ]}
        data={groupRoles}
        pagination="client"
        tableKey="teamTable"
        hasKeywordFilter
        onPrimaryAction={createRedirect}
        primaryActionLabel={t('web.pages.portal.admin.channel.team.newTeam')}
        rowActions={rowActions}
      />
    </div>
  );
};
