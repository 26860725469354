import { PresetContentFilter } from 'constants-content';

import { PresetContentFilterInterface } from 'lane-shared/types/filters/PresetContentFilterInterface';
import { PresetContentFilterLocation } from 'lane-shared/types/filters/PresetContentFilterLocation';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';

export function updateSearchOptionsWithNewLocationFilter(
  searchOptions: SearchOptions,
  newFilter: PresetContentFilterLocation
): PresetContentFilterInterface[] {
  const newLocationFilter = {
    type: PresetContentFilter.ByChannelLocations,
    filter: newFilter,
  };
  let existingFiltersCopy = { ...searchOptions }.filters;

  if (!existingFiltersCopy) {
    existingFiltersCopy = [newLocationFilter];
  } else {
    const existingLocationFilterIndex = existingFiltersCopy.findIndex(
      filter => filter.type === PresetContentFilter.ByChannelLocations
    );

    const LOCATION_FILTER_EXISTS = existingLocationFilterIndex !== -1;

    if (LOCATION_FILTER_EXISTS) {
      const existingLocationFilter =
        existingFiltersCopy[existingLocationFilterIndex];

      if (existingLocationFilter) existingLocationFilter.filter = newFilter;
    } else {
      existingFiltersCopy.push(newLocationFilter);
    }
  }

  return existingFiltersCopy;
}
