import { FeatureInstance } from '../../types/FeatureInstance';
import { ContentTypeEnum } from 'constants-content';
import hasDates from '../content/hasDates';
import explodeFeatures from '../features/explodeFeatures';
import { simpleDate, simpleDateTime } from '../formatters';
import {
  StandardColumnType,
  SORT_STATUS,
  SORT_USER,
  SORT_EMAIL,
  SORT_START,
  SORT_UPDATED,
  SORT_END,
  SORT_CREATED,
} from './types';

export default function getContentStandardColumns(
  content?: {
    type: ContentTypeEnum;
    features?: FeatureInstance[];
  },
  opts?: { timeZone?: string; locale?: string }
): StandardColumnType[] {
  if (!content) {
    return [];
  }

  const columns: StandardColumnType[] = [
    {
      label: 'Created',
      sort: SORT_CREATED,
      formatter: interaction =>
        simpleDate(interaction._created, opts?.timeZone),
      textFormatter: interaction =>
        simpleDate(interaction._created, opts?.timeZone),
    },
    {
      label: 'Updated',
      sort: SORT_UPDATED,
      formatter: interaction =>
        simpleDate(interaction._updated, opts?.timeZone),
      textFormatter: interaction =>
        simpleDate(interaction._updated, opts?.timeZone),
    },
  ];

  const { reservableFeature } = explodeFeatures(content.features);

  columns.push({
    label: 'Status',
    sort: SORT_STATUS,
    formatter: interaction => interaction.status,
    textFormatter: interaction => interaction.status,
  });

  columns.push({
    label: 'User',
    sort: SORT_USER,
    disableSort: true,
    formatter: interaction => interaction.user?.name || interaction.user?._id,
    textFormatter: interaction =>
      interaction.user?.name || interaction.user?._id,
  });

  columns.push({
    label: 'Primary Email',
    sort: SORT_EMAIL,
    disableSort: true,
    formatter: interaction => interaction.user?.profile?.email,
    textFormatter: interaction => interaction.user?.profile?.email,
  });

  if (reservableFeature || hasDates([content.type])) {
    columns.push(
      {
        label: 'Starts',
        sort: SORT_START,
        formatter: interaction =>
          simpleDateTime(interaction.startDate, opts?.timeZone),
        textFormatter: interaction =>
          simpleDateTime(interaction.startDate, opts?.timeZone),
      },
      {
        label: 'Ends',
        sort: SORT_END,
        formatter: interaction =>
          simpleDateTime(interaction.endDate, opts?.timeZone),
        textFormatter: interaction =>
          simpleDateTime(interaction.endDate, opts?.timeZone),
      }
    );
  }

  return columns;
}
