import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

export const SwiftConnectDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.SwiftConnect,
  hasCustomUI: true,
  friendlyName: 'SwiftConnect Access Control',
  description: `Defines the properties required for a SwiftConnect integration.`,
  category: IntegrationCategoryEnum.AccessControl,
  platforms: [PlatformEnum.Mobile],
  type: IntegrationTypeEnum.SDK,
  settings: {},
  properties: {},
};
