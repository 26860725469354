import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';

const GoogleCalendar: FeatureDefinition = {
  name: FeatureNameEnum.GoogleCalendar,
  requiresInteraction: true,
  friendlyName: 'web.content.feature.googleCalendarSync.friendlyName',
  description: 'web.content.feature.googleCalendarSync.description',
  properties: {
    resourceId: {
      friendlyName:
        'web.content.feature.googleCalendarSync.properties.resourceId.friendlyName',
      description:
        'web.content.feature.googleCalendarSync.properties.resourceId.description',
      type: 'String',
      editable: true,
      showControl: true,
      isArray: false,
      forDisplay: true,
      hidden: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    hangoutsMeet: {
      friendlyName:
        'web.content.feature.googleCalendarSync.properties.hangoutsMeet.friendlyName',
      description:
        'web.content.feature.googleCalendarSync.properties.hangoutsMeet.description',
      type: 'Boolean',
      editable: true,
      default: false,
    },
  },
};

export default GoogleCalendar;
