import React, { memo } from 'react';

import cx from 'classnames';

import { colors } from 'constants-colors';
import { imageUrl } from 'lane-shared/helpers/formatters';

import { Image } from 'components/general';
import { S } from 'components/typography';

import styles from './ImagePinView.scss';

type Props = {
  name: string;
  backgroundColor?: string;
  backgroundImage?: any;
  className?: string;
  imageStyle?: React.CSSProperties;
};

function ImagePinView({
  name,
  backgroundColor = colors.laneWhite,
  backgroundImage,
  className,
  imageStyle = {},
}: Props) {
  return (
    <div className={cx(styles.ImagePinView, className)}>
      {backgroundImage ? (
        <Image
          className={styles.image}
          style={{ ...imageStyle }}
          src={imageUrl(backgroundImage)}
          alt="image-pin"
        />
      ) : (
        <div
          className={cx(styles.image, styles.addBorder)}
          style={{ ...imageStyle, backgroundColor }}
        />
      )}
      <S mt={2} className={styles.text}>
        {name}
      </S>
    </div>
  );
}

export default memo(ImagePinView);
