import { useEffect } from 'react';

import { useChannelAnalytics } from './useChannelAnalytics';
import { Channel } from '../../types/ChannelType';

export function useTrackChannelView(channel: Channel) {
  const { trackChannelView } = useChannelAnalytics();

  useEffect(() => {
    if (channel?._id) {
      trackChannelView(channel);
    }
  }, [channel?._id]);
}
