import { LaneType } from 'common-types';
import { UserGroupRoleType } from '../types/UserGroupRole';
import { PERMISSION_KEYS, PermissionKey } from 'constants-permissions';
import { UserType } from '../types/User';

const PERMISSION_HASH = Object.values(PERMISSION_KEYS).reduce(
  (o, key) => {
    o[key] = true;

    return o;
  },
  {} as Record<PermissionKey, boolean>
);

const stringIsPermission = (s: string): s is PermissionKey =>
  PERMISSION_HASH[s as PermissionKey] !== undefined;

/**
 * Filter down to the roles that have the listed permissions.
 */
export default function hasPermission(
  userGroupRoles: UserGroupRoleType[] = [],
  permissions: PermissionKey | string | PermissionKey[] | string[] = [],
  channelId?: LaneType.UUID,
  includeAdmin: boolean = true
): boolean {
  let validPermissions: PermissionKey[];

  // Wrap the array, allows for passing in just one permission more easily.
  if (!Array.isArray(permissions)) {
    permissions = [permissions];
  }

  if (!permissions.every(stringIsPermission)) {
    throw new Error(`Invalid permission keys: ${permissions}`);
  } else {
    validPermissions = permissions as PermissionKey[];
  }

  return userGroupRoles?.some(userGroupRole => {
    if (
      channelId &&
      !(
        userGroupRole.groupRole?.channel?.hierarchy?.hierarchyDown?.includes(
          channelId
        ) || channelId === userGroupRole.groupRole?.channel?._id
      )
    ) {
      return false;
    }

    return checkRolePermissions(userGroupRole, validPermissions, includeAdmin);
  });
}

/**
 * Check if a user has permissions on a specific role
 */
export function hasPermissionOnSpecificRole(
  userGroupRoles: UserGroupRoleType[] = [],
  permissions: PermissionKey | PermissionKey[] = [],
  roleId: LaneType.UUID,
  includeAdmin: boolean = true
): boolean {
  // Wrap the array, allows for passing in just one permission more easily.
  if (!Array.isArray(permissions)) {
    permissions = [permissions];
  }

  const userGroupRole = userGroupRoles.find(
    userGroupRole => userGroupRole.groupRole._id === roleId
  );

  if (!userGroupRole) {
    return false;
  }

  return checkRolePermissions(userGroupRole, permissions, includeAdmin);
}

function checkRolePermissions(
  userGroupRole: UserGroupRoleType,
  permissions: PermissionKey[],
  includeAdmin: boolean
): boolean {
  const rolePermissions = userGroupRole.groupRole?.permissions || [];

  if (
    includeAdmin &&
    rolePermissions.includes(PERMISSION_KEYS.PERMISSION_ADMIN)
  ) {
    // Admin basically does mean match any or all permissions.
    return true;
  }

  return (permissions as any).some((permission: any) =>
    rolePermissions.includes(permission)
  );
}

export function hasPermissionOrSuperUser(
  user: UserType,
  permissions: PermissionKey[],
  channelId: string
): boolean {
  return (
    user.isSuperUser ||
    (Boolean(channelId) && hasPermission(user.roles, permissions, channelId))
  );
}
