import React, { useState, useEffect, useMemo, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { LaneType } from 'common-types';
import { POLL_INTERVAL } from '../../helpers/constants';
import { convertTo62 } from 'uuid-encoding';
import { FloorMapsSettingsType } from '../../helpers/integrations/FloorMaps/definition';
import { FloorMapType } from '../../helpers/integrations/FloorMaps/types/FloorMap';
import { LocationType } from '../../types/LocationType';
import { MetatagFilter } from '../../types/filters/MetatagFilter';
import {
  PresentContentFilterTimeEnum,
  PresetContentFilter,
  PresetContentSort,
} from 'constants-content';
import { PresetContentFilterInterface } from '../../types/filters/PresetContentFilterInterface';
import { SearchOptions } from '../../types/filters/SearchOptions';
import useSectionQuery from '../useSectionQuery';
import searchCombinedSectionsContentQuery from '../useSectionQuery/searchCombinedSectionsContentQuery';
import { UseSectionQueryReturn } from '../useSectionQuery/types';

type SectionProcessorProps = {
  sectionId: LaneType.UUID;
  location?: LocationType;
  setSectionHookResult: (result: UseSectionQueryReturn) => void;
};

function SectionProcessor({
  sectionId,
  location,
  setSectionHookResult,
}: SectionProcessorProps) {
  const result = useSectionQuery({
    sectionId,
    pollInterval: POLL_INTERVAL,
    defaultSearchOptions: {
      areFiltersApplied: true,
      filters: [
        {
          type: PresetContentFilter.AvailableNow,
          filter: {
            enabled: PresentContentFilterTimeEnum.Enabled,
          },
        },
      ],
    },
    autoSearch: false,
    location,
  });

  setSectionHookResult(result);

  return null;
}

type AggregatedSearchOptions = {
  search: string | undefined;
  filters: Map<PresetContentFilter, PresetContentFilterInterface>;
  metatagFilters: Map<string, MetatagFilter>;
  sorts: PresetContentSort[];
};

function aggregateSearchOptions(
  aggregatedSearchOptions: AggregatedSearchOptions,
  searchOptions: SearchOptions
) {
  const { filters, metatagFilters, sorts } = aggregatedSearchOptions;

  searchOptions.filters.forEach(item => {
    if (filters.has(item.type)) {
      return;
    }

    filters.set(item.type, cloneDeep(item));
  });

  searchOptions.metatagFilters.forEach(item => {
    if (metatagFilters.has(item.metatagId)) {
      return;
    }

    // turn off default metatag filter
    if (item.enabled === undefined) {
      item.enabled = false;
    }

    metatagFilters.set(item.metatagId, cloneDeep(item));
  });

  if (searchOptions.sorts) {
    const sortsSet = new Set([...sorts, ...searchOptions.sorts]);

    sorts.length = 0;
    sorts.push(...Array.from(sortsSet));
  }
}

function applyFloorOnlySearchOptions(
  draftFloorSearchOptions: AggregatedSearchOptions,
  floorInitialSearchOptions: AggregatedSearchOptions,
  buildingSearchOptions: AggregatedSearchOptions
) {
  floorInitialSearchOptions.filters.forEach(item => {
    draftFloorSearchOptions.filters.set(
      item.type,
      cloneDeep(buildingSearchOptions.filters.get(item.type)!)
    );
  });
  floorInitialSearchOptions.metatagFilters.forEach(item => {
    draftFloorSearchOptions.metatagFilters.set(
      item._id,
      cloneDeep(buildingSearchOptions.metatagFilters.get(item.metatagId)!)
    );
  });

  const sorts = [...floorInitialSearchOptions.sorts];

  if (
    buildingSearchOptions.sorts.length > 0 &&
    sorts.includes(buildingSearchOptions.sorts[0]!)
  ) {
    sorts.unshift(buildingSearchOptions.sorts[0] as PresetContentSort);
  }

  const sortsSet = new Set(sorts);

  draftFloorSearchOptions.sorts.length = 0;
  draftFloorSearchOptions.sorts.push(...Array.from(sortsSet));

  draftFloorSearchOptions.search = buildingSearchOptions.search;
}

function convertAggregatedToSectionSearchOptions(
  aggregatedSearchOptions: AggregatedSearchOptions
): SearchOptions {
  return {
    areFiltersApplied: true,
    filters: Array.from(aggregatedSearchOptions.filters.values()),
    metatagFilters: Array.from(aggregatedSearchOptions.metatagFilters.values()),
    sorts: aggregatedSearchOptions.sorts,
    search: aggregatedSearchOptions.search?.toLowerCase(),
    // @ts-ignore
    _updated: undefined,
  };
}

function extractSearchOptionsForServer(
  aggregatedSearchOptions: AggregatedSearchOptions
): SearchOptions {
  return {
    ...convertAggregatedToSectionSearchOptions(aggregatedSearchOptions),
    metatagFilters: Array.from(
      aggregatedSearchOptions.metatagFilters.values()
    ).filter(item => item.enabled),
  };
}

type Props = {
  settings?: FloorMapsSettingsType;
  selectedFloor?: FloorMapType;
  location?: any;
};

export default function useFloorContent({
  settings,
  selectedFloor,
  location,
}: Props) {
  const [allSectionContent, setAllSectionContent] = useState<any[]>([]);
  const [sectionIds, setSectionIds] = useState<LaneType.UUID[]>([]);
  const [selectedContentId, setSelectedContentId] = useState<string | null>(
    null
  );
  // this state is used just to trigger rendering
  const [, setRefreshTimestamp] = useState(Date.now());
  const [isSearchApplied, setIsSeachApplied] = useState(false);
  const buildingSearchOptions = useRef<AggregatedSearchOptions>({
    filters: new Map(),
    metatagFilters: new Map(),
    sorts: [],
    search: '',
  });
  const floorInitialSearchOptions = useMemo<AggregatedSearchOptions>(
    () => ({
      filters: new Map(),
      metatagFilters: new Map(),
      sorts: [],
      search: '',
    }),
    [selectedFloor]
  );
  const draftFloorSearchOptions = useMemo<AggregatedSearchOptions>(
    () => ({
      filters: new Map(),
      metatagFilters: new Map(),
      sorts: [],
      search: '',
    }),
    [selectedFloor]
  );
  const useSectionQueryMap = useMemo(
    () => new Map<LaneType.UUID, UseSectionQueryReturn>(),
    [selectedFloor]
  );

  useEffect(() => {
    setSelectedContentId(null);
  }, [selectedFloor]);

  const [floorSearchOptions, setFloorSearchOptions] = useState<SearchOptions>(
    convertAggregatedToSectionSearchOptions(floorInitialSearchOptions)
  );
  const sectionProcessors = useMemo(() => {
    let refresh: ReturnType<typeof setTimeout>;

    return selectedFloor?.sectionIds.map(id => (
      <SectionProcessor
        key={id}
        sectionId={id}
        location={location}
        setSectionHookResult={result => {
          useSectionQueryMap.set(id, result);

          // refresh hook on the next render loop
          clearTimeout(refresh);
          refresh = setTimeout(() => {
            setRefreshTimestamp(Date.now());
          }, 0);
        }}
      />
    ));
  }, [selectedFloor]);

  // make sure the metatagid is in a base62 format
  const metatagId = settings?.metatag?._id
    ? convertTo62(settings?.metatag?._id)
    : undefined;

  function updateSearchOptions(options: Partial<SearchOptions>) {
    if (options.filters) {
      options.filters.forEach(item => {
        buildingSearchOptions.current.filters.set(item.type, item);
      });
    }

    if (options.metatagFilters) {
      options.metatagFilters.forEach(item => {
        buildingSearchOptions.current.metatagFilters.set(item.metatagId, item);
      });
    }

    if (
      (options.search || options.search === '') &&
      typeof options.search !== 'undefined'
    ) {
      buildingSearchOptions.current.search = options.search;
    }

    if (options.sorts) {
      const allowedSorts = options.sorts.filter(sort =>
        floorInitialSearchOptions.sorts.includes(sort)
      );
      const sortsSet = new Set([
        ...allowedSorts,
        ...buildingSearchOptions.current.sorts,
      ]);

      buildingSearchOptions.current.sorts.length = 0;
      buildingSearchOptions.current.sorts.push(...Array.from(sortsSet));
    }

    applyFloorOnlySearchOptions(
      draftFloorSearchOptions,
      floorInitialSearchOptions,
      buildingSearchOptions.current
    );
    setRefreshTimestamp(Date.now());
  }

  function resetSearchOptions() {
    updateSearchOptions(
      convertAggregatedToSectionSearchOptions({
        ...floorInitialSearchOptions,
        search: undefined,
      })
    );

    setRefreshTimestamp(Date.now());
  }

  function applySearchOptions() {
    setIsSeachApplied(false);
    setFloorSearchOptions(
      convertAggregatedToSectionSearchOptions(draftFloorSearchOptions)
    );
    setSelectedContentId(null);
  }

  function selectSectionIds(ids: LaneType.UUID[]) {
    setSectionIds(ids);
    applySearchOptions();
  }

  useEffect(() => {
    if (!selectedFloor) {
      return;
    }

    setAllSectionContent([]);
    setSectionIds(selectedFloor.sectionIds);
    applySearchOptions();
  }, [selectedFloor?.sectionIds]);

  const sectionsData = Array.from(useSectionQueryMap.values());
  const loading = Boolean(sectionsData.find(item => item.loading));

  useEffect(() => {
    if (loading) {
      return;
    }

    const sortsSet = new Set<PresetContentSort>();

    sectionsData.forEach(item => {
      if (!item.initialSearchOptions) {
        return;
      }

      if (item.initialSearchOptions.sorts) {
        item.initialSearchOptions.sorts.forEach(sort => sortsSet.add(sort));
      }

      aggregateSearchOptions(
        floorInitialSearchOptions,
        item.initialSearchOptions
      );
      aggregateSearchOptions(
        buildingSearchOptions.current,
        item.initialSearchOptions
      );
    });

    const sorts = Array.from(sortsSet);

    floorInitialSearchOptions.sorts.length = 0;
    floorInitialSearchOptions.sorts.push(...sorts);

    const buildingSortsSet = new Set([
      ...buildingSearchOptions.current.sorts,
      ...sorts,
    ]);

    buildingSearchOptions.current.sorts.length = 0;
    buildingSearchOptions.current.sorts.push(...Array.from(buildingSortsSet));

    applyFloorOnlySearchOptions(
      draftFloorSearchOptions,
      floorInitialSearchOptions,
      buildingSearchOptions.current
    );
  }, [sectionsData, loading]);

  const skip = sectionIds.length === 0 || isSearchApplied || loading;

  useQuery(searchCombinedSectionsContentQuery, {
    skip,
    fetchPolicy: 'cache-and-network',
    pollInterval: 0,
    variables: {
      sectionIds,
      searchOptions: skip
        ? {}
        : extractSearchOptionsForServer(draftFloorSearchOptions),
    },
    onCompleted(data) {
      setIsSeachApplied(true);

      if (!data.combinedSectionsContent) {
        return;
      }

      setAllSectionContent(data.combinedSectionsContent);
      setFloorSearchOptions(
        convertAggregatedToSectionSearchOptions(draftFloorSearchOptions)
      );
    },
  });

  const floorContent = useMemo(
    () =>
      allSectionContent.reduce((acc, { content }) => {
        const isAvailable = content?.contentMetatags?.some(
          ({ metatag: { _id } }: any) => _id === metatagId
        );

        if (isAvailable) {
          acc.push(content);
        }

        return acc;
      }, []),
    [allSectionContent]
  );

  const metatags: any[] = [];

  sectionsData.reduce((acc, current) => {
    const sectionMetatags = current.section?.sectionMetatags;

    if (sectionMetatags) {
      acc.push(...sectionMetatags.map(item => item.metatag));
    }

    return acc;
  }, metatags);

  // select content
  function selectContent(contentId: string | null) {
    if (contentId === selectedContentId) {
      setSelectedContentId(null);
    } else {
      setSelectedContentId(contentId);
    }
  }

  return {
    sectionProcessors,
    loading,
    error: sectionsData?.find(item => item.error),
    sectionsData,
    floorInitialSearchOptions: convertAggregatedToSectionSearchOptions(
      floorInitialSearchOptions
    ),
    // save applied floorSearchOptions
    floorSearchOptions,
    draftFloorSearchOptions: convertAggregatedToSectionSearchOptions(
      draftFloorSearchOptions
    ),
    hasFilters: Boolean(sectionsData?.find(item => item.hasFilters)),
    hasSearch: Boolean(sectionsData?.find(item => item.hasSearch)),
    floorContent,
    metatags,
    metatagId,
    updateSearchOptions,
    resetSearchOptions,
    applySearchOptions,
    selectSectionIds,
    selectedSectionIds: sectionIds,
    selectContent,
    selectedContentId,
  };
}
