import React, { useState } from 'react';

import { ValidationError } from 'yup';

import { toSchema } from 'lane-shared/helpers';
import { AddNotificationType } from 'lane-shared/hooks/useCreateDraft';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { DraftContentType } from 'lane-shared/types/content/DraftContent';

import TabStrip from '../../general/TabStrip';
import ContentUCIs from '../../interactions/ContentUCIs';
import { DraftContentNotificationsTab } from './DraftContentNotificationsTab';

import styles from './DraftContentPublish.scss';
import type { StepperTrackerType } from 'lane-shared/hooks/analytics/useDraftContentAnalytics';

const tabs = ['Notifications', 'Table'];
const [TAB_NOTIFICATIONS, TAB_TABLE] = tabs;

type Props = {
  forCreate?: boolean;
  timeZone?: string;
  content: DraftContentType;
  liveContent: DraftContentType;
  validation?: ValidationError[] | null;
  onContentUpdated: (
    content: Partial<DraftContentType>
  ) => Partial<DraftContentType>;
  onAddNotification: AddNotificationType;
  updateTimezone: (timezone: string) => void;
  channel?: Channel | null;
  stepperTracker?: StepperTrackerType;
};

function DraftContentPublish({
  forCreate,
  timeZone,
  content,
  liveContent,
  validation,
  onContentUpdated,
  onAddNotification,
  updateTimezone,
  channel,
  stepperTracker,
}: Props) {
  const [selectedTab, setTab] = useState(TAB_NOTIFICATIONS);
  const availableTabs = [TAB_NOTIFICATIONS];

  if (!forCreate && content.isInteractive) {
    availableTabs.push(TAB_TABLE);
  }

  return (
    <div className={styles.contents}>
      {availableTabs.length > 1 && (
        <TabStrip
          tabs={availableTabs.map(toSchema)}
          selected={toSchema(selectedTab)}
          onSelectTab={tab => setTab(tab.value)}
        />
      )}
      {selectedTab === TAB_NOTIFICATIONS && (
        <DraftContentNotificationsTab
          timeZone={timeZone}
          content={content}
          liveContent={liveContent}
          validation={validation}
          onContentUpdated={onContentUpdated}
          onAddNotification={onAddNotification}
          updateTimezone={updateTimezone}
          channel={channel}
          stepperTracker={stepperTracker}
        />
      )}

      {selectedTab === TAB_TABLE && (
        <section className={styles.table}>
          <ContentUCIs
            className={styles.interactionsOnContent}
            content={liveContent}
            timeZone={timeZone!}
          />
        </section>
      )}
    </div>
  );
}

export default DraftContentPublish;
