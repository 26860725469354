import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';
import './types/QRCodeCheckinFeatureCheckInOutRule';
import './types/QRCodeCheckinFeatureRule';

const QRCodeCheckin: FeatureDefinition = {
  superUserOnly: false,
  description: 'web.content.features.qrCodeScanning.description',
  name: FeatureNameEnum.QRCodeCheckin,
  friendlyName: 'web.content.features.qrCodeScanning.friendlyName',
  requiresInteraction: true,
  properties: {
    rules: {
      type: 'QRCodeCheckinFeatureRule',
      friendlyName: 'Rules',
      description:
        'web.content.features.qrCodeScanning.properties.rules.description',
      default: [],
      isArray: true,
    },
    checkIn: {
      type: 'QRCodeCheckinFeatureCheckInOutRule',
      friendlyName: 'Check In',
      description: 'The rule applied to end user check in.',
      default: null,
    },
    checkOut: {
      type: 'QRCodeCheckinFeatureCheckInOutRule',
      friendlyName: 'Check Out',
      description: 'The rule applied to end user check out.',
      default: null,
    },
  },
};

export default QRCodeCheckin;
