import { useState, useEffect, useMemo } from 'react';

import gql from 'graphql-tag';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { PaymentAccountFragment } from '../graphql/fragments';
import pause from '../helpers/pause';
import sortByName from '../helpers/sort/byName';
import { IntegrationProviderEnum } from 'constants-integrations';
import { PaymentProviderEnum } from 'constants-payments';

const queryChannelPaymentAccounts = gql`
  ${PaymentAccountFragment}

  query getChannelPaymentAccounts($channelId: UUID!) {
    channel(_id: $channelId) {
      _id
      paymentAccounts {
        ...PaymentAccountFragment
      }
    }
  }
`;

const mutationUpdatePaymentAccount = gql`
  ${PaymentAccountFragment}

  mutation updatePaymentAccount($paymentAccount: PaymentAccountInput!) {
    payments {
      updatePaymentAccount(paymentAccount: $paymentAccount) {
        ...PaymentAccountFragment
      }
    }
  }
`;

const mutationDeletePaymentAccount = gql`
  mutation deletePaymentAccount($paymentAccountId: UUID!) {
    payments {
      deletePaymentAccount(paymentAccountId: $paymentAccountId)
    }
  }
`;

export default function useChannelForPaymentAccounts({ channelId, type }: any) {
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [fetchPaymentAccounts, { data, error, loading }] = useLazyQuery(
    queryChannelPaymentAccounts,
    {
      client: getClient(),
      fetchPolicy: 'cache-and-network',
    }
  );

  const paymentAccounts = useMemo(() => {
    if (data?.channel?.paymentAccounts) {
      return data.channel.paymentAccounts
        .filter((paymentAccount: any) => {
          // filter out only Essensys linked payment processors.
          if (type === PaymentProviderEnum.Essensys) {
            return (
              paymentAccount.paymentProcessor?.integrationProvider ===
              IntegrationProviderEnum.Essensys
            );
          }

          return true;
        })
        .sort(sortByName);
    }

    return [];
  }, [data?.channel?.paymentAccounts]);

  function refetchPaymentAccounts() {
    if (!loading) {
      fetchPaymentAccounts({
        variables: { channelId },
      });
    }
  }

  async function updatePaymentAccount(paymentAccount: any) {
    setUpdating(true);
    setUpdateError(null);

    try {
      await pause();
      await getClient().mutate({
        mutation: mutationUpdatePaymentAccount,
        variables: {
          paymentAccount: {
            _id: paymentAccount._id,
            name: paymentAccount.name,
            description: paymentAccount.description,
          },
        },
      });
    } catch (err) {
      setUpdateError(err);
    }

    setUpdating(false);
  }

  async function deletePaymentAccount(paymentAccount: any) {
    setUpdating(true);
    setUpdateError(null);

    try {
      await pause();
      await getClient().mutate({
        refetchQueries: ['getChannelPaymentAccounts'],
        mutation: mutationDeletePaymentAccount,
        variables: {
          paymentAccountId: paymentAccount._id,
        },
      });
    } catch (err) {
      setUpdateError(err);
    }

    setUpdating(false);
  }

  async function updatePaymentAccountRule(paymentAccount: any, rule: any) {
    setUpdating(true);

    const ruleData = {
      ...rule,
      __updated: undefined,
      contentCategories: {
        set: rule.contentCategories,
      },
    };

    try {
      await pause();
      await getClient().mutate({
        mutation: mutationUpdatePaymentAccount,
        variables: {
          paymentAccount: {
            _id: paymentAccount._id,
            rules: [ruleData],
          },
        },
      });
    } catch (err) {
      setUpdateError(err);
    }

    setUpdating(false);
  }

  async function deletePaymentAccountRule(paymentAccount: any, rule: any) {
    setUpdating(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: mutationUpdatePaymentAccount,
        variables: {
          paymentAccount: {
            _id: paymentAccount._id,
            rules: [
              {
                _id: rule._id,
                _pull: true,
              },
            ],
          },
        },
      });
    } catch (err) {
      setUpdateError(err);
    }

    setUpdating(false);
  }

  async function createPaymentAccountRule(paymentAccount: any, rule: any) {
    setUpdating(true);

    const ruleData = {
      ...rule,
      _id: undefined,
      __updated: undefined,
      contentCategories: {
        set: rule.contentCategories,
      },
    };

    try {
      await pause();
      await getClient().mutate({
        mutation: mutationUpdatePaymentAccount,
        variables: {
          paymentAccount: {
            _id: paymentAccount._id,
            rules: [ruleData],
          },
        },
      });
    } catch (err) {
      setUpdateError(err);
    }

    setUpdating(false);
  }

  useEffect(() => {
    if (channelId) {
      refetchPaymentAccounts();
    }
  }, [channelId]);

  return {
    paymentAccounts,
    error: error || updateError,
    loading,
    updating,
    updatePaymentAccount,
    deletePaymentAccount,
    createPaymentAccountRule,
    updatePaymentAccountRule,
    deletePaymentAccountRule,
  };
}
