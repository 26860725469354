import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';

const SubmitOnBehalfOf: FeatureDefinition = {
  description: 'web.content.features.submitOnBehalfOf.description',
  name: FeatureNameEnum.SubmitOnBehalfOf,
  requiresInteraction: true,
  friendlyName: 'web.content.features.submitOnBehalfOf.friendlyName',
  interactionData: {
    user: {
      forDisplay: false,
      showControl: false,
      friendlyName: 'User',
      type: 'User',
    },
  },
};

export default SubmitOnBehalfOf;
