import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { Query } from '@apollo/client/react/components';

import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';

import Input from '../form/Input';
import Loading from '../general/Loading';
import ChannelDirectoryListView from './ChannelDirectoryListView';

import styles from './RetailOnChannel.scss';
import {
  ActiveChannelTypeEnum,
  DeprecatedChannelTypeEnum,
} from 'constants-channel';

const options = ['Food', 'Shops', 'Services'];
const [OPTION_FOOD, OPTION_SHOPS, OPTION_SERVICES] = options;

const mapTitlesToTypes = {
  [OPTION_SHOPS]: [ActiveChannelTypeEnum.Retail],
  [OPTION_FOOD]: [ActiveChannelTypeEnum.Restaurant],
  [OPTION_SERVICES]: [
    DeprecatedChannelTypeEnum.Professional,
    ActiveChannelTypeEnum.Service,
    ActiveChannelTypeEnum.Entertainment,
  ],
};

const iconMap = {
  [OPTION_SHOPS]: {
    icon: 'shopping-cart',
    iconSet: 'FontAwesome',
  },
  [OPTION_FOOD]: {
    icon: 'ios-restaurant',
    iconSet: 'Ion',
  },
  [OPTION_SERVICES]: {
    icon: 'bell',
    iconSet: 'FontAwesome',
  },
};

const PAGE = 0;
const PER_PAGE = 300;

export default function RetailOnChannel({ channel }: any) {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const { t } = useTranslation();

  const defaultOption = OPTION_FOOD;

  const [query, goToUrl] = useQueryString({
    option: defaultOption,
  });

  const selectedOption = query.option || defaultOption;

  if (!channel) {
    return <Loading />;
  }

  function goQuery(props: any) {
    goToUrl(props);
  }

  const channelVariables = {
    pagination: { start: PAGE * PER_PAGE, perPage: PER_PAGE },
    search: {
      relatedTo: { _id: channel._id },
      channel: {
        name: debouncedSearch
          ? { type: 'like', value: debouncedSearch }
          : undefined,
        sortBy: { key: 'name', dir: 'asc' },
        type: {
          // @ts-expect-error ts-migrate(2538) FIXME: Type '(string | number | boolean)[]' cannot be use... Remove this comment to see the full error message
          any: mapTitlesToTypes[selectedOption],
        },
        isPublic: true,
      },
    },
  };

  return (
    <div className={styles.RetailOnChannel}>
      <menu className={styles.search}>
        <Input
          className={styles.searchInput}
          icon="search"
          value={search}
          showClear
          onChange={search => setSearch(search)}
          placeholder={t('Search for {{selectedOption}}…', { selectedOption })}
        />
      </menu>

      <h2>{t('Directory')}</h2>
      <menu className={styles.directoryMenu}>
        {options.map(option => (
          <button
            key={option}
            data-is-selected={query.option === option}
            onClick={() =>
              goQuery({
                option,
              })
            }
          >
            <Icon
              className={styles.icon}
              name={iconMap[option].icon}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              set={iconMap[option].iconSet}
            />
            <h2>{t(option)}</h2>
          </button>
        ))}
      </menu>

      <section>
        <h2>{t('{{selectedOption}} Directory', { selectedOption })}</h2>
        <ul>
          <Query
            query={queryChannelsByRelationship}
            variables={channelVariables}
          >
            {({ data }: any) => {
              if (!data || !data.channelsByRelationship) {
                return <Loading />;
              }

              const relationships = data.channelsByRelationship.items || [];

              if (!relationships.length) {
                return <p>{t('No retailers found.')}</p>;
              }

              return relationships.map((relationship: any) => (
                <Link
                  className={styles.channelLink}
                  to={`/l/channel/${relationship.channel.slug}`}
                  key={relationship.channel._id}
                >
                  <ChannelDirectoryListView
                    className={styles.channel}
                    channel={relationship.channel}
                  />
                </Link>
              ));
            }}
          </Query>
        </ul>
      </section>
    </div>
  );
}
