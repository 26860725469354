import React, { useContext } from 'react';

import cx from 'classnames';
import urlJoin from 'url-join';

import { colors } from 'constants-colors';
import { AppContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl, prettyPhoneNumber } from 'lane-shared/helpers/formatters';

import Thumbnail from '../general/Thumbnail';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'qrco... Remove this comment to see the full error message
import QRCode from 'qrcode.react';

import styles from './ProfileQRCard.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  user: DocumentType;
  url?: string;
  hideQR?: boolean;
};

export default function ProfileQRCard({
  className,
  style,
  user,
  url,
  hideQR,
}: Props) {
  const { whitelabel } = useContext(AppContext);

  const name = getDisplayName(user);
  const email = (user as any)?.profile?.email;
  const phone = (user as any)?.profile?.phone;
  const image = (user as any)?.profile?.image;

  const showQRCode = !hideQR;

  const qrCodeUrl =
    url || urlJoin(whitelabel.url, `/l/user/${(user as any)?._id}`);

  return (
    <div className={cx(styles.ProfileQRCard, className)} style={style}>
      <div className={styles.thumbnail}>
        <Thumbnail
          className={styles.avatar}
          src={imageUrl(image)}
          name={name}
        />
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.extraInfo}>
        {email && (
          <a href={`mailto:${email}`} data-test="profileEmailLink">
            {email}
          </a>
        )}
        {phone && <a href={`tel://${phone}`}>{prettyPhoneNumber(phone)}</a>}
      </div>
      {showQRCode && <QRCode value={qrCodeUrl} fgColor={colors.black} />}
    </div>
  );
}
