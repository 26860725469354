import { v4 as uuid } from 'uuid';

import { LaneType } from '../../__generated__/staticTypes';
import {
  ActiveChannelTypeEnum,
  ChannelExperienceTypeEnum,
} from 'constants-channel';
import constructAddress from '../constructAddress';
import constructProfile from '../constructProfile';
import { Channel } from '../../types/ChannelType';

export function constructChannel({
  userId,
  parent,
  ...otherProps
}: Partial<Channel> & {
  userId?: LaneType.UUID;
  parent?: { _id: LaneType.UUID; type: ActiveChannelTypeEnum };
}): Channel {
  return {
    _id: uuid(),
    _created: new Date(),
    _updated: new Date(),
    _createdBy: userId,
    _updatedBy: userId,
    name: '',
    type: parent?.type ?? ActiveChannelTypeEnum.Property,
    description: null,
    parent: parent ? { _id: parent._id, name: parent?.name } : null,
    slug: '',
    isPublic: false,
    isDiscoverable: true,
    isLive: false,
    isCustomer: parent ? parent.isCustomer : false,
    isSub: false,
    inviteOnly: false,
    filters: [],
    tags: [],
    experienceType:
      otherProps.experienceType ??
      parent?.experienceType ??
      ChannelExperienceTypeEnum.office,
    settings: {
      maxUsers: 0,
      subscribersJoinParent: false,
      subscribersAreDecoyed: false,
      groupRoleJoinRules: [],
      hasPerksEnabled: false,
      hasWorkOrdersEnabled: false,
      hasWorkOrderServiceRequestsEnabled: false,
      hasWorkOrderPreventiveMaintenanceEnabled: false,
      hasWorkOrderEquipmentEnabled: false,
      hasWorkOrderCrossPropertyEnabled: false,
      hasBillingPaymentsEnabled: false,
      hasVisitorManagementEnabled: false,
      hasHardwareManagementEnabled: false,
      hasHardwarePinManagementEnabled: false,
      hasSmartUserManagementEnabled: false,
      hasReservableManagementEnabled: false,
      hasAccessControlEnabled: false,
      hasSurveysEnabled: true,
      areUserTemplatesAllowed: false,
      hasMessagingAndMarketplaceEnabled: false,
    },
    stats: {
      subscribers: 0,
      sf: 0,
    },
    address: constructAddress({ userId }),
    profile: constructProfile({ userId }),
    subs: [],
    channelLocations: [],
    roles: [],
    groupRoles: [],
    channelModules: [],
    effectiveParentRequirements: {
      profilePictureRequired: false,
      fullNameRequired: false,
      phoneNumberRequired: false,
      companyNameRequired: false,
      verifiedEmailRequired: false,
      contentInteractionRequired: [],
      multiLanguageEnabled: false,
      channelLanguages: {},
      enabledLanguagesForMembers: {},
    },
    ...otherProps,
  };
}
