import { ContentTypeEnum } from 'constants-content';
import { Primitive } from '../../../types/blocks/Primitive';

const ContentOnChannelBlock: Primitive = {
  name: 'ContentOnChannelBlock',
  friendlyName: 'Content Feed',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Other'],
  properties: {
    channel: {
      friendlyName: 'Channel',
      type: 'Channel',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    contentType: {
      friendlyName: 'Type',
      type: 'String',
      validators: [
        {
          name: 'In',
          value: [
            ContentTypeEnum.Content,
            ContentTypeEnum.Perk,
            ContentTypeEnum.Promotion,
          ],
        },
      ],
    },
    header: {
      friendlyName: 'Title',
      type: 'String',
      validators: [
        {
          name: 'Max',
          value: 80,
        },
      ],
    },
    autoHide: {
      friendlyName: 'Hide when no results',
      type: 'Boolean',
      default: true,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default ContentOnChannelBlock;
