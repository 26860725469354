import React from 'react';

import { Toggle } from 'components';
import { useTranslation } from 'react-i18next';

import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import {
  ReservableFeatureProperties,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import Dropdown from 'components/form/Dropdown';
import Label from 'components/general/Label';
import { Flex, Box } from 'components/layout';
import { S, H5, P } from 'components/typography';

import ReservableFeatureSlider from './ReservableFeatureSlider';
import {
  minUnits,
  maxSlotUnits,
  enabledTypeOptions,
  formatters,
} from './reservableFeatureValues';

import styles from './styles.scss';

const TRANSLATION_KEYS = {
  unitOfTime: 'web.content.features.reservable.ReservableTabSetup.unitOfTime',
  unitOfTimeDescription:
    'web.content.features.reservable.ReservableTabSetup.unitOfTime.description',
  selectUnit: 'web.content.features.reservable.ReservableTabSetup.selectUnit',
  yourSetup: 'web.content.features.reservable.ReservableTabSetup.yourSetup',
  yourSetupDescription:
    'web.content.features.reservable.ReservableTabSetup.yourSetup.description',
  minBookable: 'web.content.features.reservable.ReservableTabSetup.minBookable',
  minBookableDescription:
    'web.content.features.reservable.ReservableTabSetup.minBookable.description',
  maxBookable: 'web.content.features.reservable.ReservableTabSetup.maxBookable',
  maxBookableDescription:
    'web.content.features.reservable.ReservableTabSetup.maxBookable.description',
  maxBookableTooltip:
    'web.content.features.reservable.ReservableTabSetup.maxBookable.tooltip',
  splitReservations:
    'web.content.features.reservable.ReservableTabSetup.splitReservations',
  splitReservationsDescription:
    'web.content.features.reservable.ReservableTabSetup.splitReservations.description',
  splitSlots: 'web.content.features.reservable.ReservableTabSetup.splitSlots',
  splitSlotsDescription:
    'web.content.features.reservable.ReservableTabSetup.splitSlots.description',
};

export default function ReservableTabSetup({
  feature,
  maxMaxSlots = 1,
  settings,
  onFeatureUpdated,
}: {
  feature: FeatureDefinition;
  maxMaxSlots: number;
  settings: ReservableFeatureProperties;
  onFeatureUpdated: (feature: Partial<ReservableFeatureProperties>) => void;
}) {
  const { t } = useTranslation();
  const maxSlots = settings?.mainRule?.maxSlots
    ? Math.min(maxMaxSlots, settings?.mainRule?.maxSlots)
    : maxMaxSlots;
  const unitType = settings?.unitType || ReservableUnitTypesEnum.Minutes;

  function enforceUnits(units: any) {
    onFeatureUpdated({
      units,
      mainRule: {
        ...settings.mainRule,
        maxSlots,
      },
    });
  }

  return (
    <>
      <Flex justify="space-between" align="start">
        <fieldset>
          <Flex
            direction="column"
            justify="space-between"
            className={styles.timeUnit}
          >
            <Box>
              <H5 mb={2}>{t(TRANSLATION_KEYS.unitOfTime)}</H5>
              <S>{t(TRANSLATION_KEYS.unitOfTimeDescription)}</S>
            </Box>

            <Dropdown
              onValueChange={unitType =>
                onFeatureUpdated({
                  unitType,
                  units: minUnits[unitType],
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ unitType: ReservableUnitTypesE... Remove this comment to see the full error message
                  maxSlots: 1,
                  bufferTime: {
                    isEnabled:
                      unitType !== ReservableUnitTypesEnum.Minutes
                        ? false
                        : settings?.bufferTime?.isEnabled,
                  },
                })
              }
              items={enabledTypeOptions}
              initialValue={unitType}
              isSearchable={false}
              placeholder={t(TRANSLATION_KEYS.selectUnit)}
              value={unitType}
              testId="timeUnitDropdown"
            />
          </Flex>
        </fieldset>

        <fieldset className={styles.info}>
          <Label>{t(TRANSLATION_KEYS.yourSetup)}</Label>
          <Label>
            {t(TRANSLATION_KEYS.yourSetupDescription, {
              limit: formatters[unitType](maxSlots * settings.units),
            })}
          </Label>
        </fieldset>
      </Flex>

      <ReservableFeatureSlider
        TooltipComponent={t(feature.properties!.units.description || '')}
        label={t(TRANSLATION_KEYS.minBookable)}
        description={t(TRANSLATION_KEYS.minBookableDescription)}
        onChange={enforceUnits}
        min={minUnits[unitType]}
        max={maxSlotUnits[unitType]}
        step={minUnits[unitType]}
        value={Number(settings.units)}
        formatLabel={formatters[unitType]}
      />

      <ReservableFeatureSlider
        TooltipComponent={t(TRANSLATION_KEYS.maxBookableTooltip)}
        disabled={maxMaxSlots < 2}
        label={t(TRANSLATION_KEYS.maxBookable)}
        description={t(TRANSLATION_KEYS.maxBookableDescription)}
        onChange={maxSlots =>
          onFeatureUpdated({
            mainRule: {
              ...settings.mainRule,
              maxSlots,
            },
          })
        }
        min={1}
        max={maxMaxSlots}
        value={Number(maxSlots)}
      />

      <Box>
        <Label
          TooltipComponent={t(
            feature.properties!.splitReservation?.description || ''
          )}
          className={styles.label}
        >
          <H5 mb={2}>{t(TRANSLATION_KEYS.splitReservations)}</H5>
        </Label>

        <P mb={2}>{t(TRANSLATION_KEYS.splitReservationsDescription)}</P>
        <Toggle
          value={settings.splitReservation || false}
          onChange={splitReservation => {
            onFeatureUpdated({
              splitReservationSlots: 1,
              splitReservation,
            });
          }}
        />

        {settings.splitReservation && (
          <ReservableFeatureSlider
            TooltipComponent={t(
              feature.properties!.splitReservation?.description || ''
            )}
            disabled={maxSlots < 2}
            label={t(TRANSLATION_KEYS.splitSlots)}
            description={t(TRANSLATION_KEYS.splitSlotsDescription)}
            onChange={splitReservationSlots =>
              onFeatureUpdated({
                splitReservationSlots,
              })
            }
            min={1}
            max={maxSlots}
            value={Number(settings.splitReservationSlots)}
          />
        )}
      </Box>
    </>
  );
}
