import { useContext } from 'react';

import { AppContext } from 'lane-shared/contexts';
import {
  OAUTH_PROVIDERS,
  OAuthProvidersEnum,
  UserLoginProviderEnum,
} from 'constants-user';
import { canHandleError } from 'lane-shared/helpers/oAuth';
import useOAuthSignUp, {
  OAuthGetIdentityResponse,
} from 'lane-shared/hooks/useOAuthSignUp';
import LaneOAuthHook from 'lane-shared/types/LaneOAuthHook';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';

import { getOAuthIdentity } from '../utils/oauth';
import {
  getAzureADProfile,
  getAzureADAccessToken,
} from '../utils/oauth/azureAD';

type UseAzureADOAuthProps = {
  oAuthConfig: OAuthConfigType;
  onError?: (err: Error | null) => void;
  onSuccess: (signInData?: any) => void;
};

export default function useAzureADOAuth({
  oAuthConfig,
  onError,
  onSuccess,
}: UseAzureADOAuthProps): LaneOAuthHook {
  const { setIsBlockingLoad } = useContext(AppContext);

  const isEnterprise = Boolean(oAuthConfig.tenantId);

  function handleError(err: Error | null) {
    if (onError && canHandleError(err)) {
      onError(err);
      setIsBlockingLoad(false);
    }
  }

  function handleResume() {
    setIsBlockingLoad(true);
  }

  function handleSuccess(signInData: any) {
    setIsBlockingLoad(false);
    onSuccess(signInData);
  }

  async function getIdentity({
    oAuthConfig,
  }: any): Promise<OAuthGetIdentityResponse> {
    const oAuthIdentity = await getOAuthIdentity({
      oAuthConfig,
      provider: OAuthProvidersEnum.AZURE_AD,
    });

    const { name, preferred_username, email } = oAuthIdentity?.extra || {};

    try {
      const { displayName, userPrincipalName, givenName, surname } =
        await getAzureADProfile(oAuthConfig);

      return {
        preferredName:
          displayName ||
          `${givenName} ${surname}`.trim() ||
          name ||
          preferred_username ||
          email,
        userPrincipalName: userPrincipalName || email,
      };
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return {
        preferredName: name || preferred_username || email,
        userPrincipalName: email,
      };
    }
  }

  const { doSignUp } = useOAuthSignUp(
    getOAuthIdentity,
    getAzureADAccessToken,
    getIdentity,
    {
      onSuccess: handleSuccess,
      onError: handleError,
      onResume: handleResume,
      oAuthConfig,
    }
  );

  function onAzureADSignUp() {
    handleError(null);
    doSignUp({
      oAuthIdentityProps: {
        oAuthConfig,
        provider: OAuthProvidersEnum.AZURE_AD,
      },
      oAuthGetIdentityProps: {
        oAuthConfig,
      },
      oAuthAccessTokenProps: {
        oAuthConfig,
      },
      legacyOAuthProvider: OAUTH_PROVIDERS.AZURE_AD,
      loginProvider: UserLoginProviderEnum.AzureAD,
    });
  }

  return {
    authorize: onAzureADSignUp,
    isEnabled: Boolean(oAuthConfig?.enabled),
    enterprise: isEnterprise,
  };
}
