import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import Features from '../../renderers/v5/features';
import { DEFAULT_BACKGROUND, DEFAULT_COLOR } from '../constants';
import {
  TimeResetFrequencyEnum,
  ContentCategoryEnum,
  ContentTypeEnum,
  CONTENT_CARD_HERO,
  CONTENT_CARD_HORIZONTAL,
  CONTENT_CARD_ID,
  CONTENT_CARD_STANDARD,
  NOTICE_CARD,
} from 'constants-content';
import { EARLIEST_DATE, LATEST_DATE } from './getResetTimes';
import { SubtitleOptions } from './subtitleHelpers';

type Params = {
  contentType: ContentTypeEnum;
  channel: {
    address: { geo: [number, number] };
    _id: string;
    profile: {
      _id: string;
      theme: { _id: string };
    };
  };
  baseBlock?: any;
};

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export default function constructTemplate({
  contentType,
  channel,
  baseBlock,
}: Params) {
  const contentId = uuid();

  const contentValues = {
    actions: [],
    card: {
      _id: null,
    },
    channel: channel && {
      _id: channel._id,
    },
    category: ContentCategoryEnum.None,
    reportingGroup: ContentCategoryEnum.None,
    data: {},
    description: '',
    features: [],
    geo: undefined,
    isPublished: false,
    isSyndicateable: false,
    lastReset: EARLIEST_DATE,
    logo: null,
    name: '',
    nextReset: LATEST_DATE,
    notifications: [],
    properties: {},
    renderer: 5,
    resetPeriod: TimeResetFrequencyEnum.Never,
    slug: null,
    theme: channel?.profile?.theme
      ? {
          _id: channel?.profile?.theme?._id,
        }
      : null,
    state: {},
    tags: [],
    isEvent: false,
    subtitle: SubtitleOptions.NONE,
    version: 0,
  };

  const startOfHour = DateTime.local().startOf('hour');

  // Templates are similar to content, but have a couple extra properties
  // and minus a couple properties.
  const templateValues = {
    notes: '',
    templateName: '',
    isPublished: undefined,
    notifications: undefined,
    placements: undefined,
    isSyndicateable: undefined,
    slug: undefined,
  };

  switch (contentType) {
    case ContentTypeEnum.Notice:
      return {
        ...contentValues,
        _id: contentId,
        color: DEFAULT_COLOR,
        backgroundColor: DEFAULT_BACKGROUND,
        backgroundImage: null,
        tags: ['Notice'],
        isInteractive: false,
        liveDate: startOfHour.toJSDate(),
        unpublishDate: startOfHour.plus({ days: 1 }).toJSDate(),
        type: ContentTypeEnum.Notice,
        block: {},
        card: {
          _id: CONTENT_CARD_ID[NOTICE_CARD],
        },
        ...templateValues,
      };
    case ContentTypeEnum.Content:
      return {
        ...contentValues,
        _id: contentId,
        color: DEFAULT_COLOR,
        backgroundColor: DEFAULT_BACKGROUND,
        backgroundImage: null,
        isInteractive: false,
        liveDate: startOfHour.toJSDate(),
        unpublishDate: startOfHour.plus({ days: 1 }).toJSDate(),
        type: ContentTypeEnum.Content,
        block: { ...baseBlock },
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_STANDARD],
        },
        ...templateValues,
      };
    case ContentTypeEnum.Perk:
      return {
        ...contentValues,
        _id: contentId,
        color: DEFAULT_COLOR,
        backgroundColor: DEFAULT_BACKGROUND,
        backgroundImage: null,
        isInteractive: true,
        liveDate: startOfHour.toJSDate(),
        unpublishDate: startOfHour.plus({ days: 1 }).toJSDate(),
        type: ContentTypeEnum.Perk,
        block: { ...baseBlock },
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_HERO],
        },
        features: [
          {
            type: Features.Entries.name,
            feature: {
              allowAnonymous: false,
              unlimited: false,
              entries: 1,
            },
          },
        ],
        ...templateValues,
      };
    case ContentTypeEnum.Promotion:
      return {
        ...contentValues,
        _id: contentId,
        color: DEFAULT_COLOR,
        backgroundColor: DEFAULT_BACKGROUND,
        backgroundImage: null,
        isInteractive: true,
        liveDate: startOfHour.toJSDate(),
        unpublishDate: startOfHour.plus({ days: 1 }).toJSDate(),
        type: ContentTypeEnum.Promotion,
        block: { ...baseBlock },
        features: [
          {
            type: Features.Entries.name,
            feature: {
              allowAnonymous: false,
              unlimited: false,
              entries: 1,
            },
          },
        ],
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_HERO],
        },
        ...templateValues,
      };
    case ContentTypeEnum.Static: {
      const data = {
        ...contentValues,
        _id: contentId,
        color: '#ffffff',
        backgroundColor: '#666666',
        backgroundImage: null,
        isInteractive: false,
        type: ContentTypeEnum.Static,
        block: { ...baseBlock },
        card: {
          _id: CONTENT_CARD_ID[CONTENT_CARD_HORIZONTAL],
        },
        ...templateValues,
      };

      return data;
    }
  }
}
