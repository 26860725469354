import React from 'react';

import urlJoin from 'url-join';

import { routes } from '../../../../config';
import { convertTo62 } from 'uuid-encoding';
import i18nStub from '../../../../helpers/i18nStub';
import {
  MjmlColumn,
  MjmlSection,
  MjmlSocial,
  MjmlSocialElement,
  MjmlWrapper,
} from '../../../MjmlComponentsV2';
import { EmailFooterProps } from '../../EmailFooter';
import EmailText from '../../EmailText';
import { bucketUrl } from './bucketUrl';
import { colors } from 'constants-colors';

const STUDIO_FOOTER_IMAGE_URL = `${bucketUrl}/Studio_EmailTemplate_Footer.jpg`;

export default function EmailFooter({
  whiteLabel,
  i18n = i18nStub,
  unsubscribe,
}: EmailFooterProps) {
  return (
    <>
      <MjmlWrapper padding="20px 20px">
        <MjmlSection
          backgroundUrl={STUDIO_FOOTER_IMAGE_URL}
          backgroundRepeat="no-repeat"
        >
          <MjmlColumn width={150} verticalAlign="middle">
            <MjmlSocial mode="horizontal" iconSize="25px" padding="0px">
              <MjmlSocialElement
                name="instagram"
                backgroundColor="#000000"
                href="https://www.instagram.com/studiobytishmanspeyer/"
              />
              <MjmlSocialElement
                name="facebook-noshare"
                backgroundColor="#000000"
                href="https://www.facebook.com/studiobytishmanspeyer/"
              />
              <MjmlSocialElement
                name="linkedin-noshare"
                backgroundColor="#000000"
                href="https://www.linkedin.com/company/studiobytishmanspeyer/"
              />
            </MjmlSocial>
          </MjmlColumn>
          <MjmlColumn width={300} verticalAlign="middle">
            <EmailText>
              <a
                style={{ color: 'white' }}
                href="https://yourstudio.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                yourstudio.com
              </a>
            </EmailText>
          </MjmlColumn>
        </MjmlSection>
      </MjmlWrapper>
      <MjmlSection paddingTop={20} paddingBottom={20}>
        <MjmlColumn>
          {unsubscribe && (
            <EmailText fontSize={10}>
              <a
                href={urlJoin(
                  whiteLabel.baseUrl,
                  routes.unsubscribe.replace(':guid', convertTo62(unsubscribe))
                )}
              >
                {i18n.t('Unsubscribe')}
              </a>
            </EmailText>
          )}
          <EmailText fontSize={8} color={colors.gray1}>
            {i18n.t('Powered by')}{' '}
            <a
              href={whiteLabel?.baseUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {whiteLabel?.shortOperatingName}
            </a>{' '}
            ©{new Date().getFullYear()} {whiteLabel?.operatingName}
            ., All rights reserved.
          </EmailText>
        </MjmlColumn>
      </MjmlSection>
    </>
  );
}
