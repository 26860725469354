import { ContentTypeEnum } from './ContentTypeEnum';
import { STEP_EDITOR, STEP_INFO, STEP_PUBLISH, STEP_TARGETING } from './steps';

export const ONE_MINUTE = 1000 * 60; // ms
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;

export const CONTENT_LOCATION_NAMES = [
  'Content Center',
  'Page Center',
  'From a Section',
] as const;

export const [
  CONTENT_LOCATION_LIVE,
  CONTENT_LOCATION_PAGE_CENTER,
  CONTENT_LOCATION_IN_SECTION,
] = CONTENT_LOCATION_NAMES;

export const CONTENT_LOCATIONS = [
  {
    label: CONTENT_LOCATION_LIVE,
    value: CONTENT_LOCATION_LIVE,
  },
  {
    label: CONTENT_LOCATION_PAGE_CENTER,
    value: CONTENT_LOCATION_PAGE_CENTER,
  },
  {
    label: CONTENT_LOCATION_IN_SECTION,
    value: CONTENT_LOCATION_IN_SECTION,
  },
];

export const CONTENT_SEARCH_TYPES = ['channel'] as const;
export const [CONTENT_SEARCH_TYPE_CHANNEL] = CONTENT_SEARCH_TYPES;

export const CONTENT_SEARCH_TYPES_ITEMS = [
  {
    label: 'On this Channel…',
    value: CONTENT_SEARCH_TYPE_CHANNEL,
  },
];

export const BUILDER_STEPS = {
  [ContentTypeEnum.Static]: [STEP_INFO, STEP_EDITOR, STEP_TARGETING],
  [ContentTypeEnum.Content]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [ContentTypeEnum.Notice]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [ContentTypeEnum.ScheduledNotice]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [ContentTypeEnum.ScheduledContent]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [ContentTypeEnum.Promotion]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [ContentTypeEnum.Perk]: [
    STEP_INFO,
    STEP_EDITOR,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [ContentTypeEnum.WorkOrder]: [
    STEP_EDITOR,
    STEP_INFO,
    STEP_TARGETING,
    STEP_PUBLISH,
  ],
  [ContentTypeEnum.VisitorManagement]: [STEP_INFO, STEP_EDITOR, STEP_TARGETING],
};

export const AVAILABLE_TEMPLATE_TYPES = [
  ContentTypeEnum.Static,
  ContentTypeEnum.Content,
  ContentTypeEnum.Perk,
  ContentTypeEnum.Promotion,
];

// todo: this will change in the future as we use the rendering engine to display cards.
export const CARD_LAYOUTS = [
  'Standard Card',
  'Hero Card',
  'Horizontal Card',
  'Notice Card',
  'Message Card',
] as const;

export const PIN_LAYOUTS = [
  'Standard Pin',
  'Circle Pin',
  'Square Pin',
  'List View Pin',
  'Image Pin',
] as const;

export const [
  CONTENT_CARD_STANDARD,
  CONTENT_CARD_HERO,
  CONTENT_CARD_HORIZONTAL,
  NOTICE_CARD,
  MESSAGE_CARD,
] = CARD_LAYOUTS;

// todo: this will change in the future and be pulled from the database
// but at the moment these values never change and there is a small amount
// of them
export const CONTENT_CARD_ID = {
  [CONTENT_CARD_STANDARD]: '4Hj2AbFK28ddUz8myGPPLd',
  [CONTENT_CARD_HORIZONTAL]: '0MDtYW6AbxQd5b2Zx7VcV1',
  [CONTENT_CARD_HERO]: '5jDlkbaSaeXMraWQdFo18H',
  [NOTICE_CARD]: '2MdkTpqSsGLVg5Ur8IWdki',
  [MESSAGE_CARD]: '49Dwxk2d8LIfSlA8YNUqPY',
};

export const [PIN_STANDARD, PIN_CIRCLE, PIN_SQUARE, PIN_LIST_VIEW, PIN_IMAGE] =
  PIN_LAYOUTS;

export type PinsAndCardsType =
  | (typeof CARD_LAYOUTS)[number]
  | (typeof PIN_LAYOUTS)[number];

export const CONTENT_TYPES_WITH_PIN_LAYOUT = [
  ContentTypeEnum.Static,
  ContentTypeEnum.VisitorManagement,
  ContentTypeEnum.WorkOrder,
];
