import { useUserData } from 'lane-shared/hooks';
import hasPermission from 'lane-shared/helpers/hasPermission';
import { PERMISSION_KEYS } from 'constants-permissions';

export function useInvoicePermission(groupId: string) {
  const { user } = useUserData();

  const isSuperUserOrHasInvoicePermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_KEYS.PERMISSION_COMPANY_INVOICES],
      groupId
    );

  return isSuperUserOrHasInvoicePermission;
}
