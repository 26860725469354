import { LocationType } from '../../types/LocationType';
import { UserGroupRoleType } from '../../types/UserGroupRole';
import calculateChannelDistance from '../calculateChannelDistance';
import { CIRCUMFERENCE_OF_EARTH } from '../constants';
import fuzzyMatch from '../fuzzyMatch';
import hasAdminOnChannel from './hasAdminOnChannel';
import { Channel } from '../../types/ChannelType';

export type ChannelHierarchyReturnType = Channel & {
  isTopLevel: boolean;
  subs: ChannelHierarchyReturnType[];
  channelLocations: ChannelHierarchyReturnType[];
};

type Props = {
  roles?: UserGroupRoleType[];
  channels: Channel[];
  forMobile?: boolean;
  location?: LocationType;
  search?: string;
  // the result can be returned as a nested hierarchy, or a flattened list
  // in order.  a flat list is better on mobile to render data.
  makeFlat: boolean;
};

function sortByDistance(a: any, b: any) {
  return a._distance - b._distance;
}

function flattenReducer(arr: any, channel: any) {
  return [
    ...arr,
    channel,
    ...channel.channelLocations.reduce(flattenReducer, []),
    ...channel.subs.reduce(flattenReducer, []),
  ];
}

/**
 * Builds a hierarchy of the channels this user belongs to.
 */
export default function buildUserChannelHierarchy({
  roles = [],
  channels,
  forMobile = false,
  location,
  search = '',
  makeFlat = false,
}: Props): ChannelHierarchyReturnType[] {
  function filterChannel(channel: any) {
    if (!search) {
      return true;
    }

    const matched =
      fuzzyMatch(channel.profile.name, search) ||
      fuzzyMatch(channel.address.street1, search) ||
      fuzzyMatch(channel.address.city, search);

    if (matched) {
      return true;
    }

    return channel.subs.length !== 0 || channel.channelLocations.length !== 0;
  }

  function setupChildren(channel: Channel): ChannelHierarchyReturnType {
    const parent: ChannelHierarchyReturnType = {
      ...channel,
      isTopLevel: false,
      subs: [],
      channelLocations: [],
    };

    parent._distance = calculateChannelDistance({
      latitude: location?.latitude,
      longitude: location?.longitude,
      channel,
    });

    // get all the user roles at this channel.
    parent.roles = roles
      ?.filter(role => role.groupRole?.channel?._id === parent._id)
      .map(role => ({ ...role }));

    // find all the sub channels of this channel.
    parent.subs = channels
      .filter(channel => channel.parent?._id === parent._id && channel.isSub)
      .map(setupChildren)
      .filter(filterChannel);

    // sort by distance.
    parent.subs.sort(sortByDistance);

    // find all the channelLocations at this channel

    parent.channelLocations = channels
      .filter(channel => channel.parent?._id === parent._id && !channel.isSub)
      .map(setupChildren)
      .filter(filterChannel);

    // sort by distance
    parent.channelLocations.sort(sortByDistance);

    // get the shortest distance.
    parent._shortestDistance = Math.min(
      parent._distance,
      parent.channelLocations?.[0]?._distance || CIRCUMFERENCE_OF_EARTH,
      parent.subs?.[0]?._distance || CIRCUMFERENCE_OF_EARTH
    );

    // use has admin rights on this channel if they have some non-subscriber
    parent._hasAdmin = hasAdminOnChannel(roles, parent, forMobile);

    return parent;
  }

  // start with highest levels, and clone the objects so we aren't modifying
  // original objs.
  const channelIds = channels.map(channel => channel?._id).filter(Boolean);

  const parents = channels
    .filter(
      channel =>
        !channel.parent ||
        (channel.parent._id && !channelIds.includes(channel.parent._id))
    )
    .map(channel => ({ ...channel, isTopLevel: true }))
    .map(setupChildren)
    .filter(filterChannel);

  parents.sort(sortByDistance);

  if (makeFlat) {
    return parents.reduce(flattenReducer, []);
  }

  return parents;
}
