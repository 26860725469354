import React, { useRef } from 'react';

import cx from 'classnames';
import { useRippleEffect } from 'hooks';
import { usePopper } from 'react-popper';

import { Placement } from '@popperjs/core';

import { colors } from 'constants-colors';

import { useClickOutside } from 'lane-shared/hooks';

import styles from './DropdownContextMenu.scss';

interface IProps {
  title: React.ReactElement;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  placement?: Placement;
  offset?: { horizontal?: number; vertical?: number };
  hasRippleEffect?: boolean;
  rippleEffectColor?: string;
}

export default function DropdownContextMenu({
  title,
  placement = 'bottom-start',
  offset,
  children,
  isOpen,
  onClose,
  hasRippleEffect = false,
  rippleEffectColor = colors.charcoal[9],
}: IProps) {
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const dropdownRef = useRef(null);
  const rippleContainerRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(dropdownRef, () => {
    onClose();
  });

  const popper = usePopper(referenceRef.current, popperRef.current, {
    placement,
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [offset?.horizontal || 0, offset?.vertical || 8],
        },
      },
      {
        name: 'flip',
        enabled: true,
      },
    ],
  });

  useRippleEffect(
    dropdownRef,
    rippleContainerRef,
    hasRippleEffect ? rippleEffectColor : 'transparent'
  );

  return (
    <div
      ref={isOpen ? dropdownRef : null}
      className={styles.DropdownContextMenu}
    >
      <div ref={referenceRef}>{title}</div>
      <div
        ref={popperRef}
        className={cx(styles.dropdown)}
        data-hidden={!isOpen}
        style={popper.styles.popper}
        {...popper.attributes.popper}
      >
        {children}
      </div>
      <div ref={rippleContainerRef} className={styles.RippleContainer} />
    </div>
  );
}
