import React, { useContext } from 'react';

import { generatePath, Redirect, Route } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { UserDataContext } from 'lane-shared/contexts';
import { PERMISSION_KEYS } from 'constants-permissions';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

export default function ProtectedRoute({
  children,
  permissions,
  ...props
}: any) {
  const { hasAnyPermission } = useContext(UserDataContext);
  const { channel } = useChannelAdminContext();

  if (!channel) {
    return null;
  }

  if (hasAnyPermission(permissions, channel._id)) {
    return <Route {...props}>{children}</Route>;
  }

  // No permission! redirect to appropriate page
  let redirectTarget;

  if (hasAnyPermission([PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS], channel._id)) {
    redirectTarget = routes.channelAdminDashboard;
  } else {
    redirectTarget = routes.channel;
  }

  const redirectTo = generatePath(redirectTarget, { id: channel.slug });

  return (
    <Redirect to={{ pathname: redirectTo, state: { from: props.location } }} />
  );
}
