/**
 * Possible sorts that sections or content blocks can turn on to allow
 * sorting of the results.  These sorts are pre-built UI/UX that will
 * appear when added.
 *
 * I.e. allow a section to sort by location.
 */
export enum PresetContentSort {
  None = 'None',
  Name = 'Name',
  TimeStarting = 'TimeStarting',
  TimeEnding = 'TimeEnding',
  Location = 'Location',
  FeaturePaymentPrice = 'FeaturePaymentPrice',
  FeatureQuantityRemaining = 'FeatureQuantityRemaining',
}

export enum ContentGroupByEnum {
  None = 'None',
  Channel = 'Channel',
  Category = 'Category',
}

export const PresetContentSortFriendlyName = {
  [PresetContentSort.None]: 'None',
  [PresetContentSort.Name]: 'Sort by name',
  [PresetContentSort.TimeStarting]: 'Sort by start time',
  [PresetContentSort.TimeEnding]: 'Sort by end time',
  [PresetContentSort.Location]: 'Sort by distance',
  [PresetContentSort.FeaturePaymentPrice]: 'Sort by price',
  [PresetContentSort.FeatureQuantityRemaining]: 'Sort by quantity remaining',
};

export const PresetContentSortDescription = {
  [PresetContentSort.Name]: 'Allows the user to sort the results by name.',
  [PresetContentSort.TimeStarting]:
    'Allows the user to sort the results based on when they start.',
  [PresetContentSort.TimeEnding]:
    'Allows the user to sort the results based on when they are ending.',
  [PresetContentSort.Location]:
    'Allows the user to sort the results based on the distance from their current location.',
  [PresetContentSort.FeaturePaymentPrice]:
    'Allows the user to sort the results based on the price of each item.',
  [PresetContentSort.FeatureQuantityRemaining]:
    'Allows the user to sort results based on the quantity remaining of each item',
};
