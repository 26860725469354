import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'yup';

import { UserContentInteractionContext } from '../../../contexts';
import { ContentTypeEnum } from 'constants-content';
import { VisitorType } from '../types/VisitorType';
import { useTenantInfo as useTenantChannel } from './useTenantInfo';

type Props = {
  visitors: VisitorType[] | null;
  bulkVisitors?: VisitorType[] | null;
  onBulkVisitorsChange?: (bulkVisitors: VisitorType[]) => void;
  onChange: (visitor: VisitorType[]) => void;
  onAddSuccess?: (visitor: VisitorType) => void;
  onEditSaveSuccess?: (visitor: VisitorType) => void;
  onDeleteSuccess?: (visitor: VisitorType) => void;
  contentType: ContentTypeEnum | undefined;
  channelId: string | undefined;
};

export default function useVisitorsList({
  visitors: visitorsList,
  bulkVisitors: bulkVisitorList,
  onBulkVisitorsChange,
  onChange,
  onAddSuccess,
  onEditSaveSuccess,
  onDeleteSuccess,
  contentType,
  channelId,
}: Props) {
  const [visitors, setVisitors] = useState(
    nonBulkVisitors(visitorsList, bulkVisitorList)
  );
  const tenantChannelInfo = useTenantChannel(channelId);

  let bulkVisitors: VisitorType[] = bulkVisitorList || [];

  const { validateAdditional } = useContext(UserContentInteractionContext);
  const { t } = useTranslation();

  useEffect(() => {
    setVisitors(nonBulkVisitors(visitorsList, bulkVisitorList));
  }, [visitorsList, bulkVisitorList]);

  const getVisitorIndex = (visitor: VisitorType): number => {
    return visitors.findIndex(v => v._identifier === visitor._identifier);
  };

  const handleAdd = (visitor: VisitorType): void => {
    const visitorToAdd = {
      ...visitor,
      _identifier: visitors.length + 1,
      tenantId: tenantChannelInfo?.id,
    };

    onChange([...visitors, ...bulkVisitors, visitorToAdd]);

    if (onAddSuccess) {
      onAddSuccess(visitorToAdd);
    }
  };

  const resetForm = (): void => {
    if (onBulkVisitorsChange) {
      onBulkVisitorsChange([]);
    }
  };

  const handleBulkAdd = (visitors: VisitorType[]): void => {
    if (!onBulkVisitorsChange) {
      return;
    }

    const visitorsToAdd = visitors.map((visitor, index) => {
      const originalLength = visitors.length;

      return {
        ...visitor,
        _identifier: originalLength + index + 1,
        tenantId: tenantChannelInfo?.id,
      };
    });

    bulkVisitors = [...bulkVisitors, ...visitorsToAdd];
    onBulkVisitorsChange(bulkVisitors);

    const existingVisitors = nonBulkVisitors(visitorsList, bulkVisitors);

    setVisitors(existingVisitors);
    onChange([...existingVisitors, ...bulkVisitors]);
  };

  const handleEditSave = (visitor: VisitorType): void => {
    const visitorIndex = getVisitorIndex(visitor);

    if (visitorIndex === -1) return;

    visitors[visitorIndex] = visitor;
    onChange([...visitors, ...bulkVisitors]);

    if (onEditSaveSuccess) {
      onEditSaveSuccess(visitor);
    }
  };

  const handleDelete = (visitor: VisitorType): void => {
    const visitorIndex = getVisitorIndex(visitor);

    if (visitorIndex === -1) return;

    visitors.splice(visitorIndex, 1);
    onChange([...visitors, ...bulkVisitors]);

    if (onDeleteSuccess) {
      onDeleteSuccess(visitor);
    }
  };

  const handleBulkDelete = (visitors: VisitorType[]): void => {
    if (!onBulkVisitorsChange) {
      return;
    }

    onBulkVisitorsChange([]);

    onChange(nonBulkVisitors(visitorsList, visitors));
  };

  useEffect(() => {
    if (contentType === ContentTypeEnum.VisitorManagement) {
      const additionalSchema = object({
        features: object({
          VisitorsList: object().test(
            'minimumVisitors',
            t(
              'web.content.feature.visitorManagement.visitorInvite.form.minimumVisitors'
            ),
            () => {
              return visitors.length > 0 || bulkVisitors.length > 0;
            }
          ),
        }),
      });

      validateAdditional(additionalSchema);
    }
  }, [
    validateAdditional,
    visitors.length,
    bulkVisitorList?.length,
    contentType,
    t,
  ]);

  function isSameVisitor(visitor: VisitorType, bulkVisitor: VisitorType) {
    return (
      visitor.firstName === bulkVisitor.firstName &&
      visitor.lastName === bulkVisitor.lastName &&
      visitor.email === bulkVisitor.email &&
      visitor.company === bulkVisitor.company &&
      visitor.phone === bulkVisitor.phone
    );
  }

  function nonBulkVisitors(
    visitors: VisitorType[] | null,
    bulkVisitors?: VisitorType[] | null
  ) {
    return visitors
      ? visitors.filter(
          visitor =>
            !bulkVisitors?.some(bulkVisitor =>
              isSameVisitor(visitor, bulkVisitor)
            )
        )
      : [];
  }

  return {
    visitors,
    handleAdd,
    handleBulkAdd,
    handleEditSave,
    handleDelete,
    handleBulkDelete,
    resetForm,
  };
}
