import { getOrganizedPermissions } from 'lane-shared/helpers/getOrganizedPermissions';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { useReservableManagementCalendarV2Enabled } from 'lane-shared/hooks/useReservableManagementCalendarV2Enabled';
import { PERMISSION_KEYS } from 'constants-permissions';

export function useFilteredPermissions(hiddenPermissionDetails: string[] = []) {
  const pinManagementPermissions = useFlag(
    FeatureFlag.HardwarePinManagement,
    false
  );
  const isReservableManagementCalendarV2Enabled =
    useReservableManagementCalendarV2Enabled();

  if (!pinManagementPermissions) {
    hiddenPermissionDetails.push(
      PERMISSION_KEYS.PERMISSION_PIN_MANAGEMENT_VIEW
    );
    hiddenPermissionDetails.push(
      PERMISSION_KEYS.PERMISSION_PIN_MANAGEMENT_CREATE_EDIT_DELETE
    );
  }

  if (!isReservableManagementCalendarV2Enabled) {
    hiddenPermissionDetails.push(
      PERMISSION_KEYS.PERMISSION_RESERVATIONS_MODULE_ACCESS
    );
  }

  const startsWithKeywords = (permission: string, keywords: string[]) =>
    keywords.some(keyword => permission.startsWith(keyword));

  const permissions = getOrganizedPermissions();

  const filteredPermissions = permissions.map(
    (permission: { key: string; value: any }) => ({
      ...permission,
      value: permission.value.filter(
        (detail: { key: string }) =>
          !startsWithKeywords(detail.key, hiddenPermissionDetails)
      ),
    })
  );

  return filteredPermissions;
}
