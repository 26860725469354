import { isEqual } from 'lodash';

import { PresetContentFilter } from 'constants-content';
import { PresetContentFilterTimeRange } from 'lane-shared/types/filters/PresetContentFilterTimeRange';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';

import { parseDateTime } from '../dates';

export default function getChangedFilters(
  searchOptions: SearchOptions | null | undefined,
  initialSearchOptions: SearchOptions | null | undefined
): PresetContentFilter[] {
  if (!searchOptions || !initialSearchOptions) {
    return [];
  }

  return searchOptions.filters
    .filter(filter => {
      const initialFilter = initialSearchOptions.filters.find(
        f => f.type === filter.type
      );

      if (initialFilter) {
        // compare date filters separately
        if (
          [
            PresetContentFilter.FeatureReservableAvailableMinutes,
            PresetContentFilter.FeatureReservableAvailableDays,
          ].includes(filter.type)
        ) {
          const filterContent =
            filter.filter as PresetContentFilterTimeRange | null;
          const initialFilterContent =
            initialFilter.filter as PresetContentFilterTimeRange | null;

          const startDateA = parseDateTime(filterContent?.startDate);
          const startDateB = parseDateTime(initialFilterContent?.startDate);

          const endDateA = parseDateTime(filterContent?.endDate);
          const endDateB = parseDateTime(initialFilterContent?.endDate);

          const dateHashChanged =
            (startDateA && startDateB && !startDateA.equals(startDateB)) ||
            (endDateA && endDateB && !endDateB.equals(endDateB));

          if (filterContent?.enabled && dateHashChanged) {
            return true;
          }
        } else if (!isEqual(initialFilter.filter, filter.filter)) {
          return true;
        }
      }

      return false;
    })
    .map(filter => filter.type);
}
