import React from 'react';

import { IntegrationProviderEnum } from 'constants-integrations';

import Checkbox from '../../../../form/Checkbox';

import styles from './styles.scss';

const dataKey = `_${IntegrationProviderEnum.YouRHere}`;

export default function YourhereTargeting({ content, onContentUpdated }: any) {
  const hasIntegration = content.state && content.state[dataKey];

  return (
    <div className={styles.TargetingIntegration}>
      <h1>YouRHere Displays</h1>
      <section className={styles.details}>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: b... Remove this comment to see the full error message */}
        <Checkbox
          selected={!!hasIntegration}
          text="Display on YouRHere screens."
          onChange={() => {
            onContentUpdated({
              state: {
                ...(content.state || {}),
                [dataKey]: hasIntegration ? undefined : true,
              },
            });
          }}
        />
      </section>
    </div>
  );
}
