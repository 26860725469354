import React, { useMemo } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import {
  IconButton,
  Pagination,
  ContentPlacementListItem,
  Loading,
  Flex,
} from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import {
  FRIENDLY_CONTENT_TYPES,
  SectionTypeEnum,
  ContentTypeEnum,
} from 'constants-content';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import hasDates from 'lane-shared/helpers/content/hasDates';
import { shortDate, simpleDate, fromNow } from 'lane-shared/helpers/formatters';

import GeneratorContentCount from '../../batch-content/components/GeneratorContentCount';
import {
  SORT_ASC,
  headers,
  SORT_LIVE_DATE,
  CONTENT_WITH_SECTIONS,
} from '../helpers/constants';
import getContentLink from '../helpers/getContentLink';
import ChannelContentQuery from './ChannelContentQuery';

import styles from './ChannelContentCenterTable.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { ContentType } from 'lane-shared/types/content/Content';

type PageInfo = {
  start: number;
  total: number;
  perPage: number;
};

type Placements = {
  here: ContentType[];
  groupRole: Record<string, ContentType[]>;
  channel: Record<string, ContentType[]>;
};

const TRANSLATION_KEYS = {
  dateToDateLabel: 'web.admin.channel.contentCenter.table.dateToDateLabel',
};

type SectionContent = {
  _id: string;
  section: {
    name: string;
  };
};

type TableProps = {
  makeUrl: (props: Record<string, any>) => string;
  forDrafts: boolean;
  selectedSort: string;
  selectedOrder: string;
  reverseOrder: string;
  contents: ContentType[];
  channel: any;
  availableTypes: ContentTypeEnum[];
};

function Table({
  makeUrl,
  forDrafts,
  selectedSort,
  selectedOrder,
  reverseOrder,
  contents,
  channel,
  availableTypes,
}: TableProps) {
  const { t } = useTranslation();
  const contentHasDates = hasDates(availableTypes);
  let shownHeaders = headers;
  const isDraftContentStateEnabled = useFlag(
    FeatureFlag.DraftContentState,
    false
  );

  if (!contentHasDates) {
    shownHeaders = shownHeaders.filter(h => h.sort !== SORT_LIVE_DATE);
  }

  const contentHasSections = useMemo(() => {
    return availableTypes.some(type => CONTENT_WITH_SECTIONS.includes(type));
  }, [availableTypes]);

  if (!contentHasSections) {
    shownHeaders = shownHeaders.filter(h => h.label !== 'Sections');
  }

  const getContentsSections = (
    sectionContent: SectionContent[] | undefined,
    content: ContentType
  ) => {
    const { type: contentType, category: contentCategory } = content;
    const contentsSections = [];

    if (sectionContent?.length) {
      contentsSections.push(
        sectionContent.map(({ section }) => section.name).join(', ')
      );
    }

    for (let i = 0; i < channel.sections?.length; i++) {
      const { name, query, type } = channel.sections[i] || {};

      if (type !== SectionTypeEnum.Dynamic) continue;

      if (
        query?.contentTypes.includes(contentType) &&
        query?.contentCategories.includes(contentCategory)
      ) {
        contentsSections.push(name);
      }
    }

    return [...new Set(contentsSections)].join(', ');
  };

  return (
    <table>
      <thead>
        <tr>
          {shownHeaders.map(header => (
            <th key={header.label}>
              <Flex align="center">
                <span>{t(header.translation_key)}</span>
                {header.sort && (
                  <Link
                    to={makeUrl({
                      sort: header.sort,
                      order: reverseOrder,
                    })}
                  >
                    <IconButton
                      inverted
                      iconSet={ICON_SET_FONTAWESOME}
                      icon={
                        selectedOrder === SORT_ASC
                          ? 'sort-amount-down-alt'
                          : 'sort-amount-up'
                      }
                      className={cx(styles.sortButton, {
                        [styles.selected]: selectedSort === header.sort,
                      })}
                      selected={selectedSort === header.sort}
                    />
                  </Link>
                )}
              </Flex>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {contents.map(content => (
          <tr key={content._id}>
            <td>
              <div className={styles.flexWrapper}>
                <Link
                  key={content._id}
                  to={
                    content.generator
                      ? routes.channelAdminGeneratorContent
                          .replace(':id', channel.slug)
                          .replace(':contentId', content._id)
                      : getContentLink({
                          slug: channel.slug,
                          contentId: content._id,
                          forDrafts,
                          isDraftContentStateEnabled,
                        })
                  }
                >
                  {content.name}
                </Link>
                {content?.generatedChildren?.pageInfo &&
                  content.generatedChildren.pageInfo.total > 0 && (
                    <GeneratorContentCount
                      count={content.generatedChildren.pageInfo.total}
                    />
                  )}
              </div>
            </td>
            <td>{FRIENDLY_CONTENT_TYPES[content.type]}</td>
            {contentHasSections && (
              <td>{getContentsSections(content.sectionContent, content)}</td>
            )}
            <td>{content.tags && content.tags.join(', ')}</td>
            <td>{content.isInteractive && <Icon name="check" />}</td>
            {contentHasDates && (
              <td>
                {t(TRANSLATION_KEYS.dateToDateLabel, {
                  firstDate: shortDate(content.liveDate, undefined),
                  secondDate: shortDate(
                    content.unpublishDate || content.endDate,
                    undefined
                  ),
                })}
              </td>
            )}
            <td>{simpleDate(content._created)}</td>
            <td>{fromNow(content._updated)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

type $FixProps = any;

function ChannelContentCenterTable({
  className,
  style,
  query,
  queryName,
  channel,
  page,
  perPage,
  selectedSort,
  selectedOrder,
  search,
  availableTypes,
  endDate,
  startDate,
  reverseOrder,
  forDrafts,
  goQuery,
  makeUrl,
  hideOnError,
}: $FixProps) {
  if (!channel) return <Loading />;

  return (
    <div
      className={cx(styles.ChannelContentCentreTable, className)}
      style={style}
    >
      <ChannelContentQuery
        query={query}
        queryName={queryName}
        hasPlacements={!forDrafts}
        channelId={channel._id}
        page={page}
        perPage={perPage}
        selectedSort={selectedSort}
        selectedOrder={selectedOrder}
        search={search}
        availableTypes={availableTypes}
        endDate={endDate}
        startDate={startDate}
        hideOnError={hideOnError}
      >
        {({
          placements,
          pageInfo,
        }: {
          placements: Placements;
          pageInfo: PageInfo;
        }) => (
          <>
            <Pagination
              total={pageInfo.total}
              perPage={perPage}
              page={page}
              onPage={page => goQuery({ page: page + 1 })}
            />
            <div className={styles.group}>
              <ContentPlacementListItem channelId={channel._id} />
              <Table
                makeUrl={makeUrl}
                forDrafts={forDrafts}
                selectedSort={selectedSort}
                selectedOrder={selectedOrder}
                reverseOrder={reverseOrder}
                contents={placements.here}
                channel={channel}
                availableTypes={availableTypes}
              />
            </div>
            {Object.entries(placements.groupRole).map(([key, value]) => (
              <div className={styles.group} key={key}>
                <ContentPlacementListItem groupRoleId={key} />
                <Table
                  makeUrl={makeUrl}
                  forDrafts={forDrafts}
                  selectedSort={selectedSort}
                  selectedOrder={selectedOrder}
                  reverseOrder={reverseOrder}
                  contents={value}
                  channel={channel}
                  availableTypes={availableTypes}
                />
              </div>
            ))}
            {Object.entries(placements.channel).map(([key, value]) => (
              <div className={styles.group} key={key}>
                <ContentPlacementListItem channelId={key} />
                <Table
                  makeUrl={makeUrl}
                  forDrafts={forDrafts}
                  selectedSort={selectedSort}
                  selectedOrder={selectedOrder}
                  reverseOrder={reverseOrder}
                  contents={value}
                  channel={value}
                  availableTypes={availableTypes}
                />
              </div>
            ))}
          </>
        )}
      </ChannelContentQuery>
    </div>
  );
}

export default ChannelContentCenterTable;
