import { colors } from 'constants-colors';

export default {
  shadow1: {
    elevation: 8,
    shadowColor: colors.charcoal[1],
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.08,
    shadowRadius: 8,
  },
  shadow2: {
    elevation: 8,
    shadowColor: colors.charcoal[1],
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.16,
    shadowRadius: 8,
  },
  shadow3: {
    elevation: 8,
    shadowColor: colors.charcoal[1],
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.24,
    shadowRadius: 8,
  },
  shadow4: {
    elevation: 10,
    shadowColor: colors.charcoal[1],
    shadowOffset: { width: 8, height: 16 },
    shadowOpacity: 0.13,
    shadowRadius: 10,
  },
  shadow5: {
    elevation: 6,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 7,
  },
  shadow6: {
    elevation: 4,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  shadow7: {
    elevation: 4,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
};
