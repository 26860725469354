import { DateTime } from 'luxon';

export function createDefaultDateTime(
  hours: number,
  minutes: number,
  timeZone?: string
): Date {
  // If no timezone is provided, use UTC
  // This ensures the VisitorManagement feature's default times are in UTC
  const zone = timeZone || 'UTC';
  const dateTime = DateTime.fromObject({ zone }).set({
    hour: hours,
    minute: minutes,
    second: 0,
    millisecond: 0,
  });

  // If we're using UTC, return as is
  if (zone === 'UTC') {
    return dateTime.toJSDate();
  }

  // If a timezone was provided, convert to UTC before returning
  return dateTime.toUTC().toJSDate();
}
