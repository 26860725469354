import { DateTime } from 'luxon';
import { DateTimeUnitEnum } from './constants';

const FORWARD = 1;
const BACKWARD = -1;

/**
 * Computes a future date by adding a specified number of days or months to a given start date.
 * Will respect last day of month when adding months.
 *
 * @param {Date} start - The starting date from which to calculate the future date.
 * @param {number} change - The number of units (days or months) to add to the start date.
 * @param {DateTimeUnitEnum} unit - The unit of time to use for the calculation (Day or Month).
 * @returns {Date} - The computed future date.
 *
 * @example
 * // Add 5 days to the current date
 * const futureDate = computeFutureDate(new Date(), 5, DateTimeUnitEnum.Day);
 * console.log(futureDate);
 *
 * @example
 * // Add 2 months to a specific date
 * const futureDate = computeFutureDate(new Date('2023-01-01'), 2, DateTimeUnitEnum.Month);
 * console.log(futureDate);
 */
export function computeFutureDate(
  start: Date,
  change: number,
  unit: DateTimeUnitEnum
): Date {
  return computeDate(start, change, unit, FORWARD);
}

/**
 * Computes a past date by subtracting a specified number of days or months from a given start date.
 * Will respect last day of month when subtracting months.
 *
 * @param {Date} start - The starting date from which to calculate the past date.
 * @param {number} change - The number of units (days or months) to subtract from the start date.
 * @param {DateTimeUnitEnum} unit - The unit of time to use for the calculation (Day or Month).
 * @returns {Date} - The computed past date.
 *
 * @example
 * // Subtract 3 days from the current date
 * const pastDate = computePastDate(new Date(), 3, DateTimeUnitEnum.Day);
 * console.log(pastDate);
 *
 * @example
 * // Subtract 1 month from a specific date
 * const pastDate = computePastDate(new Date('2023-05-01'), 1, DateTimeUnitEnum.Month);
 * console.log(pastDate);
 */
export function computePastDate(
  start: Date,
  change: number,
  unit: DateTimeUnitEnum
): Date {
  return computeDate(start, change, unit, BACKWARD);
}

function computeDate(
  start: Date,
  change: number,
  unit: DateTimeUnitEnum,
  direction: number
) {
  let date = DateTime.fromJSDate(start);

  switch (unit) {
    case DateTimeUnitEnum.Day:
      date =
        direction === FORWARD
          ? date.plus({ days: change })
          : date.minus({ days: change });
      break;
    case DateTimeUnitEnum.Month: {
      // Store the original day and whether it was the last day of the month
      const originalDay = date.day;
      const wasLastDayOfMonth = date.day === date.daysInMonth;

      // First move to the first day of the month to avoid any day-of-month issues
      date = date.set({ day: 1 });

      // Then move to the target month
      date =
        direction === FORWARD
          ? date.plus({ months: change })
          : date.minus({ months: change });

      // If we were on the last day of the original month, move to the last day of the target month
      if (wasLastDayOfMonth) {
        date = date.set({ day: date.daysInMonth });
      } else {
        // Otherwise, try to preserve the original day, but if it's too large for the target month,
        // move to the last day of the target month
        const targetDay = Math.min(originalDay, date.daysInMonth);
        date = date.set({ day: targetDay });
      }
      break;
    }

    default:
      break;
  }

  return date.toJSDate();
}
