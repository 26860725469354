import { useContext, useState } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { claimInvite as claimInviteMutation } from 'lane-shared/graphql/mutation';

import { LaneType } from 'common-types';
import { getClient } from '../apollo';
import { AppContext, ChannelsContext } from '../contexts';
import { SignUpContextType } from '../contexts/SignUpContext/SignUpContextType';
import ChannelFragment from '../graphql/fragments/ChannelFragment';
import { AddressFragment, ProfileFragment } from '../graphql/fragments';
import { Channel } from '../types/ChannelType';

const getInviteQuery = gql`
  ${ChannelFragment}
  ${AddressFragment}
  ${ProfileFragment}

  query getInviteQuery($inviteId: UUID!) {
    invite(_id: $inviteId) {
      _id
      name
      email
      groupRole {
        _id
      }
      channel {
        ...ChannelFragment
      }
      company {
        ...ChannelFragment
      }
    }
  }
`;

export type InviteType = {
  _id: LaneType.UUID;
  name: string;
  email: string;
  groupRole: {
    _id: LaneType.UUID;
  };
  channel: Channel;
  company?: Channel;
};

export default function useInvites({ inviteId }: any) {
  const { refetch, refetchFocus, switchChannel } = useContext(ChannelsContext);
  const { transition } = useContext(AppContext);
  const [claiming, setClaiming] = useState(false);
  const [claimError, setClaimError] = useState<Error | null>(null);
  const { data, loading, error, called } = useQuery(getInviteQuery, {
    variables: { inviteId },
    fetchPolicy: 'no-cache',
    skip: !inviteId,
  });

  const invite: InviteType | undefined = data?.invite;

  function constructSignUpData(): Partial<SignUpContextType> {
    const updatedSignUp: Partial<SignUpContextType> = {
      inviteId: invite?._id,
      building: null,
      company: null,
      shouldSkipCompanySelection: false,
      name: invite?.name,
      email: invite?.email,
    };

    if (invite?.channel) {
      updatedSignUp.shouldSkipCompanySelection = true;

      if (invite?.company) {
        updatedSignUp.building = invite.channel;
        updatedSignUp.company = invite.company;
      } else {
        updatedSignUp.building = invite.channel;
      }
    }

    return updatedSignUp;
  }

  async function claimInvite(
    inviteId: LaneType.UUID,
    channelId: LaneType.UUID
    // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  ): Promise<LaneType.UUID | undefined> {
    setClaiming(true);
    setClaimError(null);

    try {
      const { data } = await getClient().mutate({
        mutation: claimInviteMutation,
        variables: { _id: inviteId },
      });

      await refetch();
      await refetchFocus();
      await switchChannel(channelId);
      transition();

      return data.me.claimInvite._id;
    } catch (err) {
      setClaimError(err);
    } finally {
      setClaiming(false);
    }
  }

  return {
    invite,
    loading,
    error,
    called,
    claiming,
    claimError,
    claimInvite,
    constructSignUpData,
  };
}
