import { v4 as uuid } from 'uuid';

import { BLOCK_LINK_TYPES } from 'constants-content';

export default function convertLink(oldLink: any) {
  if (!oldLink) {
    return undefined;
  }

  let type;

  switch (oldLink.type) {
    case 'Phone':
      type = BLOCK_LINK_TYPES.PHONE;
      break;
    case 'Web':
      type = BLOCK_LINK_TYPES.WEB;
      break;
    case 'Email':
      type = BLOCK_LINK_TYPES.EMAIL;
      break;
  }

  return {
    _id: uuid(),
    name: '',
    uri: oldLink?.url?.en,
    type,
  };
}
