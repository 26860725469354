import { hasPermission, hasPermissionOnSpecificRole } from '..';
import { UserType } from '../../types/User';
import { UserGroupRoleType } from '../../types/UserGroupRole';
import { SPECIAL_GROUP_ROLES } from 'constants-channel';
import { PERMISSION_KEYS } from 'constants-permissions';

export default function canDeleteUserRole(
  role: UserGroupRoleType,
  loggedInUser: UserType,
  groupRoleUser: UserType
) {
  if (loggedInUser?.isSuperUser) {
    return true;
  }

  if (role.groupRole.name === SPECIAL_GROUP_ROLES.ADMIN) {
    return hasPermission(
      loggedInUser?.roles,
      [PERMISSION_KEYS.PERMISSION_ADMIN],
      role.groupRole.channel?._id
    );
  }

  // check if the user is trying to delete their role
  if (loggedInUser?._id === groupRoleUser?._id) {
    return true;
  }

  // check if the user has PERMISSION_KEYS.PERMISSION_USERS_DELETE on channel level
  if (
    hasPermission(
      loggedInUser?.roles,
      [PERMISSION_KEYS.PERMISSION_USERS_DELETE],
      role.groupRole.channel?._id
    )
  ) {
    return true;
  }

  // check if the user has PERMISSION_KEYS.PERMISSION_USERS_ROLE_DELETE on role/team level
  if (
    hasPermissionOnSpecificRole(
      loggedInUser?.roles,
      [PERMISSION_KEYS.PERMISSION_USERS_ROLE_DELETE],
      role.groupRole._id
    )
  ) {
    return true;
  }

  // check if the user has PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_DELETE for workplace member roles
  if (role.groupRole.name === SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER) {
    return hasPermission(
      loggedInUser?.roles,
      [PERMISSION_KEYS.PERMISSION_USERS_WORKPLACE_MEMBERS_DELETE],
      role.groupRole.channel?._id
    );
  }

  return false;
}
