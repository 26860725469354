import { Channel } from '../../types/ChannelType';

export function getFocusOnChannels(
  parentChannels: Channel[],
  result: Channel[]
) {
  parentChannels.forEach(channel => {
    if (!channel) return;

    result.push(channel);

    if (channel.channelLocations && channel.channelLocations.length > 0) {
      getFocusOnChannels(channel.channelLocations, result);
    }

    if (channel.subs && channel.subs.length > 0) {
      getFocusOnChannels(channel.subs, result);
    }
  });

  return result;
}
