import React, { createContext, useState } from 'react';

import { ContentTypeEnum, ContentModalTypeEnum } from 'constants-content';

export function useTemplatePickerModal() {
  const [modalType, setModalType] = useState<ContentModalTypeEnum | null>(null);
  const [templateType, setTemplateType] = useState<ContentTypeEnum | null>(
    ContentTypeEnum.Content
  );
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  return {
    templateType,
    setTemplateType,
    selectedTemplate,
    setSelectedTemplate,
    modalType,
    setModalType,
  };
}

export type TemplatePickerModalContextType = {
  templateType: ContentTypeEnum;
  setTemplateType: React.Dispatch<React.SetStateAction<ContentTypeEnum>>;
  selectedTemplate: any;
  modalType: ContentModalTypeEnum | null;
  setModalType: React.Dispatch<
    React.SetStateAction<ContentModalTypeEnum | null>
  >;
};

export const TemplatePickerModalContext = createContext(
  {} as TemplatePickerModalContextType
);
