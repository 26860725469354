import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'design-system-web';
import { UserSyncJobConfiguration } from '../../../types/AccessControl';
import styles from './styles.scss';
import { useUserSyncJobConfiguration } from '../../../hooks/useUserSyncJobConfiguration';
import { UserDataContext } from 'lane-shared/contexts';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';
import { UserType } from 'lane-shared/types/User';

type UserSyncPauseResumeProps = {
  accessConfiguration: UserSyncJobConfiguration;
  onResumeAction: (isResumeInProgress: boolean) => void;
  onPauseResumeSuccess: () => void;
};

export function UserSyncPauseResume({
  accessConfiguration,
  onResumeAction,
  onPauseResumeSuccess,
}: UserSyncPauseResumeProps) {
  const { t } = useTranslation();

  const [hasEditPermission, setHasEditPermission] = useState(false);

  const { loading, pauseUserSyncJobSchedule, resumeUserSyncJobSchedule } =
    useUserSyncJobConfiguration();

  const { user } = useContext(UserDataContext);

  const hasAnyPermission = (
    user: UserType,
    permissions: string[],
    channelId: string
  ) => {
    return (
      user.isSuperUser ||
      (channelId && hasPermission(user.roles, permissions, channelId))
    );
  };

  useEffect(() => {
    if (user) {
      setHasEditPermission(
        !!hasAnyPermission(
          user,
          [
           PERMISSION_KEYS.PERMISSION_ACCESS_CONTROL_ACCESS_CONFIGURATION_ADVANCED_SETTINGS_EDIT,
          ],
          accessConfiguration.channelId
        )
      );
    }
  }, [user]);

  useEffect(() => {
    onResumeAction(loading);
  }, [loading]);

  const handleResume = async () => {
    const response = await resumeUserSyncJobSchedule(
      accessConfiguration?.channelId,
      accessConfiguration?.provider
    );

    if (response?.success) {
      onPauseResumeSuccess();
      window.Toast.show(
        t(
          'web.admin.accessConfiguration.manageUserAccessSync.settings.resume.success'
        )
      );
    } else {
      window.Toast.show(
        t(
          'web.admin.accessConfiguration.manageUserAccessSync.settings.resume.error'
        )
      );
    }
  };

  const handlePause = async () => {
    const response = await pauseUserSyncJobSchedule(
      accessConfiguration?.channelId,
      accessConfiguration?.provider
    );

    if (response?.success) {
      onPauseResumeSuccess();
      window.Toast.show(
        t(
          'web.admin.accessConfiguration.manageUserAccessSync.settings.pause.success'
        )
      );
    } else {
      window.Toast.show(
        t(
          'web.admin.accessConfiguration.manageUserAccessSync.settings.pause.error'
        )
      );
    }
  };

  return (
    <div className={styles.syncPlayPauseContainer}>
      <div className={styles.form}>
        {!accessConfiguration?.isActive ? (
          <Button
            variant="secondary"
            size="large"
            onClick={handleResume}
            disabled={loading || !hasEditPermission}
            testId="buttonResumeSync"
            loading={loading}
          >
            {loading
              ? 'Resuming...'
              : t(
                  'web.admin.accessConfiguration.manageUserAccessSync.settings.resume.sync'
                )}
          </Button>
        ) : (
          <Button
            variant="secondary"
            size="large"
            onClick={handlePause}
            disabled={loading || !hasEditPermission}
            testId="buttonPauseSync"
            loading={loading}
          >
            {loading
              ? 'Pausing...'
              : t(
                  'web.admin.accessConfiguration.manageUserAccessSync.settings.pause.sync'
                )}
          </Button>
        )}
      </div>
    </div>
  );
}
