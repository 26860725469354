import React from 'react';

import { useTranslation } from 'react-i18next';

import { INVITE_STATUS } from 'constants-channel';
import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';
import { ChipSelect, ChipStyle } from 'components/ads';

import { GroupRole } from 'graphql-query-contracts';

import {
  Table,
  FilterType,
  NativeFilterTypes,
  Tooltip,
} from 'design-system-web';
import {
  useResendInvite,
  useRevokeInvite,
  useInviteUsersRedirect,
} from '../hooks';
import styles from './PendingInvites.scss';
import Papa from 'papaparse';
import { makeFileDownload } from 'helpers';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import type { PendingInvitesCountResponse } from 'lane-shared/graphql/query/getPendingInvitesCount';
import { useNewPendingInvitesV2 } from '../hooks/useNewPendingInvitesV2';
import { useQueryString } from 'hooks';

export type ChannelInviteRecord = {
  _id: string;
  name: string;
  email: string;
  groupRole: GroupRole;
  groupRoles: GroupRole[];
  _created: Date | string;
  status: string;
  _updated: Date | string;
  expires: Date | string;
  company?: { _id: string };
};

export type PendingInvitesV2Props = {
  channelId: string;
  groupRoleId?: string;
  refetchCount: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<PendingInvitesCountResponse>>;
  groupRoles?: Partial<GroupRole>[];
  inDetailsPage?: boolean;
  channelSlug: string;
  isMFExperience?: boolean;
  onAddUser?: () => void | undefined;
  companies?: {
    _id: any;
    name: string;
    inviteOnly: boolean;
    hasFilters: boolean;
  }[];
};

type Row = {
  id: string;
  name: string;
  email: string | undefined;
  permissionGroup: string;
  permissionGroups: string[];
  createdDate: Date;
  status: string;
  lastEmailSentOn: Date;
  expiresIn: Date;
  company: string;
};

export const PendingInvitesV2 = ({
  channelId,
  groupRoleId,
  companies,
  refetchCount,
  groupRoles,
  inDetailsPage = false,
  channelSlug,
  isMFExperience,
  onAddUser,
}: PendingInvitesV2Props) => {
  const { t } = useTranslation();
  const { handleResendInvite } = useResendInvite();
  const { handleRevokeInvite } = useRevokeInvite();
  const { inviteUsersRedirect, inviteUsersLabel } = useInviteUsersRedirect({
    channelSlug,
    isOfficeExperience: isMFExperience !== undefined && !isMFExperience,
  });

  const [
    {
      page,
      pageSize,
      permissionGroups,
      sortBy,
      sortDirection,
      createdDate,
      keyword,
      company,
    },
  ] = useQueryString<{
    [x: string]: string;
  }>({
    sortBy: 'createdDate',
    sortDirection: 'desc',
  });

  let groupRoleIdsFilter = null;

  if (permissionGroups) {
    groupRoleIdsFilter = [...(permissionGroups?.split(',') || [])];
  }

  if (groupRoleId) {
    groupRoleIdsFilter = [...(groupRoleIdsFilter ?? []), groupRoleId];
  }

  const createdDateFilter = createdDate?.split('_');
  const startDateFilter = createdDateFilter?.[0] ?? null;
  const endDateFilter = createdDateFilter?.[1] ?? null;
  const keywordFilter = keyword?.trim();
  const companyFilter = company?.trim();

  const apiFilters = {
    start: page ? Number(page) : 0,
    perPage: pageSize ? Number(pageSize) : 10,
    search: keywordFilter,
    sortBy: sortBy ?? null,
    sortDirection: sortDirection ?? null,
    groupRoleIds: groupRoleIdsFilter,
    companyIds: companyFilter ? [companyFilter] : null,
    createdDateRange: {
      startDate: startDateFilter,
      endDate: endDateFilter,
    },
  };

  const {
    channelInvites: invites,
    loading: channelInvitesLoading,
    refetch: refetchPendingInvites,
    total,
  } = useNewPendingInvitesV2(channelId, apiFilters);

  const refetch = async () => {
    await Promise.all([refetchCount(), refetchPendingInvites()]);
  };

  function groupRoleHelper(userRow: Row) {
    if (userRow.permissionGroups.length > 1) {
      return (
        <Tooltip
          wrapperClassName={styles.alignTooltip}
          placement="top"
          TooltipComponent={
            <ul className={styles.tooltipItems}>
              {userRow.permissionGroups.map(groupRole => {
                return (
                  <li key={userRow.id + groupRole}>
                    <span>{groupRole}</span>
                  </li>
                );
              })}
            </ul>
          }
        >
          <ChipSelect.NonInteractive
            key={userRow.id}
            value={t(
              'web.admin.channel.users.pendingInvites.companiesAssigned',
              {
                count: userRow.permissionGroups.length,
              }
            )}
            type={ChipStyle.Grey}
            withStatusIcon={false}
            doTranslate={false}
          />
        </Tooltip>
      );
    }

    return (
      <span className={styles.commaSeparatedCell}>
        {(userRow.permissionGroups.length > 0 && userRow.permissionGroups[0]) ||
          '--'}
      </span>
    );
  }

  const columns = [
    {
      key: 'name',
      header: t('web.admin.channel.users.pendingInvites.table.name'),
      type: 'text',
      renderCell: (name: string) => <span>{name || '--'}</span>,
      renderForCSV: (row: Row) => row.name,
    },
    {
      key: 'email',
      header: t('web.admin.channel.users.pendingInvites.table.email'),
      type: 'text',
      renderCell: (email: string) => <span>{email || '--'}</span>,
      renderForCSV: (row: Row) => row.email,
    },
    ...(!inDetailsPage
      ? [
          {
            key: 'permissionGroups',
            header: t(
              isMFExperience
                ? 'web.admin.channel.users.pendingInvites.table.permissionGroups'
                : 'web.admin.channel.users.pendingInvites.table.teams'
            ),
            type: 'text',
            renderCell: (_: any, userRow: Row) => {
              return groupRoleHelper(userRow);
            },
            disableSorting: true,
            renderForCSV: (row: Row) => row.permissionGroups.join(', ') || '--',
          },
        ]
      : []),
    ...(!isMFExperience
      ? [
          {
            key: 'company',
            header: t('web.admin.channel.users.pendingInvites.table.company'),
            renderCell: (company: string) => {
              return <span>{company || '--'}</span>;
            },
            renderForCSV: (row: Row) => row.company || '--',
            disableSorting: true,
          },
        ]
      : []),
    {
      key: 'createdDate',
      header: t('web.admin.channel.users.pendingInvites.table.createdDate'),
      type: 'date',
      renderCell: (cell: string) => <span>{simpleDate(cell) || '--'}</span>,
      renderForCSV: (row: Row) => simpleDate(row.createdDate) || '--',
    },
    {
      key: 'status',
      header: t('web.admin.channel.users.pendingInvites.table.inviteStatus'),
      type: 'text',
      renderCell: (cell: string) => <span>{cell || '--'}</span>,
      renderForCSV: (row: Row) => row.status,
    },
    {
      key: 'lastEmailSentOn',
      header: t('web.admin.channel.users.pendingInvites.table.lastInviteSent'),
      type: 'date',
      renderCell: (cell: string) => <span>{simpleDate(cell) || '--'}</span>,
      renderForCSV: (row: Row) => simpleDate(row.lastEmailSentOn) || '--',
      disableSorting: true,
    },
    {
      key: 'expiresIn',
      header: t('web.admin.channel.users.pendingInvites.table.expires'),
      type: 'text',
      renderCell: (cell: string) => <span>{fromNow(cell) || '--'}</span>,
      renderForCSV: (row: Row) => fromNow(row.expiresIn) || '--',
      disableSorting: true,
    },
  ];

  const data: Row[] | [] = invites
    ? invites.map(invite => {
        const lastEmailSentOn =
          invite.status === INVITE_STATUS.RESENT
            ? new Date(invite._updated)
            : new Date(invite._created);

        return {
          id: invite._id,
          name: invite.name,
          email: invite.email,
          permissionGroup: invite.groupRole.name,
          permissionGroups: invite.groupRoles.map(
            (g: { _id: string }) =>
              groupRoles?.find(gr => gr._id === g._id)?.name || ''
          ),
          createdDate: new Date(invite._created),
          status: invite.status,
          lastEmailSentOn,
          expiresIn: new Date(invite.expires),
          company:
            companies?.find(c => c._id === invite?.company?._id)?.name || '',
        };
      })
    : [];

  const rowActions = [
    {
      label: t(
        'web.admin.channel.users.pendingInvites.table.actions.resendInvite'
      ),
      onClick: async (invite: Row) => {
        handleResendInvite({
          inviteId: invite.id,
          refetch,
        });
      },
    },
    {
      label: t(
        'web.admin.channel.users.pendingInvites.table.actions.revokeInvite'
      ),
      onClick: async (invite: Row) => {
        handleRevokeInvite({
          invite: {
            id: invite.id,
            name: invite.name,
            groupRole: invite.permissionGroup,
          },
          refetch,
        });
      },
    },
  ];

  const companyOptions = () => {
    return companies?.map(company => {
      return {
        label: company.name,
        value: company._id,
      };
    });
  };

  const availableFilters = [
    ...(!inDetailsPage
      ? [
          {
            type: NativeFilterTypes.ArrIncludesSome,
            key: 'permissionGroups',
            label: t(
              isMFExperience
                ? 'web.admin.channel.users.pendingInvites.table.permissionGroups'
                : 'web.admin.channel.users.pendingInvites.table.teams'
            ),
            isPromoted: true,
            options:
              groupRoles?.map(g => {
                return {
                  label: g.name,
                  value: g._id,
                };
              }) || [],
          },
        ]
      : []),
    ...(!isMFExperience
      ? [
          {
            type: NativeFilterTypes.Select,
            key: 'company',
            label: t('web.admin.channel.users.pendingInvites.table.company'),
            isPromoted: true,
            options: companyOptions(),
          },
        ]
      : []),
    {
      type: NativeFilterTypes.DateRange,
      key: 'createdDate',
      label: 'Created between',
    },
  ] as FilterType[];

  Object.freeze(availableFilters);

  const handleExportToCSV = (invites: Row[]) => {
    const csvData = [];
    const headers = columns.map(column => column.header);

    csvData.push(headers);

    invites.forEach(user => {
      const rowData: any[] = [];

      columns.map(column => {
        // @ts-ignore
        const value = column.renderForCSV(user);

        rowData.push(value);
      });

      csvData.push(rowData);
    });

    const csv = Papa.unparse(csvData);
    const filename = `pending-invites-${Date.now()}.csv`;

    makeFileDownload({
      name: filename,
      contents: csv,
      type: 'application/csv',
    });
  };

  // TODO: Implement export all https://viewthespace.atlassian.net/browse/TS-10188
  // const exportAll = () => {
  //   const invites = data || [];

  //   handleExportToCSV(invites);
  // };

  const exportCurrentPage = (currentPageData: Row[] = []) => {
    handleExportToCSV(currentPageData);
  };

  const exportOptions = [
    {
      label: t('web.admin.channel.userManagement.table.export.currentPage'),
      onClick: exportCurrentPage,
    },
    // TODO: Implement export all https://viewthespace.atlassian.net/browse/TS-10188
    // {
    //   label: t('web.admin.channel.userManagement.table.export.all'),
    //   onClick: exportAll,
    // },
  ];

  return (
    <div className={styles.pendingInvites}>
      <Table
        columns={columns}
        totalRows={total}
        isLoading={channelInvitesLoading}
        data={data}
        pagination="server"
        hasKeywordFilter
        rowActions={rowActions}
        filters={availableFilters}
        tableKey="users.pendingInvitesTable"
        onPrimaryAction={!inDetailsPage ? inviteUsersRedirect : onAddUser}
        primaryActionLabel={
          !inDetailsPage
            ? inviteUsersLabel
            : t('web.pages.portal.admin.channel.team.addUserButton')
        }
        exportOptions={exportOptions}
        queryStringsEnabled
        // @ts-ignore
        pageSize={10}
      />
    </div>
  );
};
