import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { getDisplayName } from 'lane-shared/helpers';
import { useChannelProfileQuery } from 'lane-shared/hooks';

import ChannelCircleListView from 'components/lane/ChannelCircleListView';

import Loading from '../general/Loading';
import ModalBackground from '../general/ModalBackground';
import ResizableWindow from '../general/ResizableWindow';
import { ChannelSearchHierarchiesEnum } from './ChannelSearch';
import ChannelSelector, { ChannelSelectorModesEnum } from './ChannelSelector';

import styles from './ChannelSelectorButton.scss';
import {
  ActiveChannelTypeEnum,
  ChannelType,
  DeprecatedChannelTypeEnum,
} from 'constants-channel';
import { Channel } from 'packages/lane-shared/types/ChannelType';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  showSearch?: boolean;
  channelId?: string | null;
  channelSlug?: string | null;
  placeholder?: string;
  modes?: ChannelSelectorModesEnum[];
  hierarchies?: ChannelSearchHierarchiesEnum[];
  types?: ChannelType[];
  storageKey?: string;
  disableStorage?: boolean;
  onChannelSelected: (channel: Channel | null) => void;
  renderChannel?: (channel: Channel) => React.ReactNode;
  relatedToChannelId?: string;
  isCardView?: boolean;
  doTranslate?: boolean;
};

type Props = OwnProps;

export default function ChannelSelectorButton({
  className,
  style,
  disabled,
  channelId = null,
  channelSlug = null,
  placeholder = 'Select a Channel',
  onChannelSelected = () => null,
  modes = [
    ChannelSelectorModesEnum.Subscriptions,
    ChannelSelectorModesEnum.Geo,
    ChannelSelectorModesEnum.Search,
  ],
  types = [
    ActiveChannelTypeEnum.Property,
    ActiveChannelTypeEnum.Company,
    ActiveChannelTypeEnum.Service,
    ActiveChannelTypeEnum.Restaurant,
    ActiveChannelTypeEnum.Retail,
    ActiveChannelTypeEnum.Entertainment,
    DeprecatedChannelTypeEnum.Professional,
    DeprecatedChannelTypeEnum.Charity,
    DeprecatedChannelTypeEnum.Meta,
    DeprecatedChannelTypeEnum.Curated,
  ],
  hierarchies = [
    ChannelSearchHierarchiesEnum.All,
    ChannelSearchHierarchiesEnum.Parent,
    ChannelSearchHierarchiesEnum.Location,
    ChannelSearchHierarchiesEnum.SubChannel,
  ],
  storageKey = 'ChannelSelectorButton',
  disableStorage = false,
  renderChannel,
  relatedToChannelId,
  isCardView = false,
  doTranslate = true,
}: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { channel, loading } = useChannelProfileQuery({
    channelId,
    channelSlug,
  });
  const isSelected = !!(channelId || channelSlug);

  function render() {
    if (isSelected) {
      return (
        <>
          {renderChannel ? (
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ChannelProfileType | null' is no... Remove this comment to see the full error message
            renderChannel(channel)
          ) : (
            <span>{getDisplayName(channel)}</span>
          )}
          {!disabled ? (
            <Icon
              className={styles.remove}
              name="times"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: any) => void' is not assignable to type ... Remove this comment to see the full error message
              onClick={(e: any) => {
                onChannelSelected(null);
                e.stopPropagation();
              }}
            />
          ) : null}
        </>
      );
    }

    return (
      <>
        <Icon className={styles.search} name={t('search')} />
        <span>{doTranslate ? t(placeholder) : placeholder}</span>
      </>
    );
  }

  return (
    <>
      <div
        className={cx(styles.ChannelSelectorButton, className, {
          [styles.isCardView]: isCardView,
        })}
        data-is-selected={isSelected}
        role="button"
        data-test="channelSelectorButton"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && setIsOpen(true)}
        onClick={() => !disabled && setIsOpen(true)}
        style={style}
      >
        {!isCardView && isSelected && loading && !channel && (
          <Loading className={styles.loader} />
        )}

        {isCardView ? <ChannelCircleListView channel={channel} /> : render()}
      </div>

      <ModalBackground
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        className={styles.background}
      >
        <ResizableWindow
          name={storageKey}
          className={styles.window}
          onClose={() => setIsOpen(false)}
          defaultPosition={ResizableWindow.centerPosition()}
          showHeader
        >
          <ChannelSelector
            className={styles.channelSelector}
            storageKey={storageKey}
            disableStorage={disableStorage}
            modes={modes}
            types={types}
            relatedToChannelId={relatedToChannelId}
            hierarchies={hierarchies}
            onChannelSelected={(channel: any) => {
              onChannelSelected(channel);
              setIsOpen(false);
            }}
          />
        </ResizableWindow>
      </ModalBackground>
    </>
  );
}
