import { BLOCK_CONTENT_QUERY } from 'constants-content';
import { Primitive } from '../../../types/blocks/Primitive';

const ContentListBlock: Primitive = {
  name: 'ContentListBlock',
  friendlyName: 'Content List',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Other'],
  properties: {
    header: {
      friendlyName: 'Title',
      type: 'String',
      validators: [
        {
          name: 'Max',
          value: 80,
        },
      ],
    },
    contentType: {
      friendlyName: 'Type',
      type: 'String',
      validators: [
        {
          name: 'In',
          value: [
            BLOCK_CONTENT_QUERY.THINGS_COMING_UP,
            BLOCK_CONTENT_QUERY.TODAY_FEED,
            BLOCK_CONTENT_QUERY.PINNED_CONTENT,
            BLOCK_CONTENT_QUERY.NOTICES,
          ],
        },
      ],
    },
    perPage: {
      type: 'Number',
      friendlyName: 'Items per page',
      default: 5,
      validators: [
        {
          name: 'Min',
          value: 5,
        },
        {
          name: 'Max',
          value: 15,
        },
      ],
    },
    autoHide: {
      friendlyName: 'Hide when no results',
      type: 'Boolean',
      default: true,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default ContentListBlock;
