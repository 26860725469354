import { useContext, useMemo } from 'react';

import AppContext from '../contexts/AppContext';
import mergeThemes from '../helpers/content/mergeThemes';
import { ThemeType } from '../types/Theme';
import { Channel } from '../types/ChannelType';

/**
 * Builds out a theme based on what is set in the channel, set on the white
 * label and also based on setting values from defaults for missing values.
 */
export default function useChannelTheme(
  channel: Pick<Channel, 'profile'> | null | undefined,
  otherTheme?: ThemeType
): ThemeType {
  const { whitelabel } = useContext(AppContext);

  return useMemo(
    () =>
      mergeThemes([
        whitelabel?.profile?.theme,
        channel?.profile?.theme,
        otherTheme,
      ]),
    [channel, whitelabel, otherTheme, channel?.profile?.theme?._updated]
  );
}
