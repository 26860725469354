/**
 * Different types of payment providers.  These represent the possible sources
 * or destinations of where a payment will be made.
 */
export enum PaymentProviderEnum {
  Cash = 'Cash',
  Stripe = 'Stripe',
  PlacePay = 'PlacePay',
  Essensys = 'Essensys',
}

/**
 * Different types of payment.  This is used to indicate what are valid
 * types of payment to accept or to request.  I.e. I have created content
 * and I will accept Cash for this amount, EssensysCredits for this amount,
 * FunBucks for this amount.
 */
export enum PaymentTypeEnum {
  Cash = 'Cash',
  EssensysCredits = 'EssensysCredits',
  MoneyMovement = 'MoneyMovement', // @deprecated Left for backwards compatibility, use Cash
}

export enum PaymentCurrencyEnum {
  USD = 'USD',
  CAD = 'CAD',
  AUD = 'AUD',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  HUF = 'HUF',
  BRL = 'BRL',
  SGD = 'SGD',
  INR = 'INR',
  THB = 'THB',
  EssensysCredits = 'EssensysCredits',
}
