import React from 'react';

import { useTranslation } from 'react-i18next';

import { CONTENT_CATEGORIES, ContentCategoryEnum } from 'constants-content';

import Dropdown from '../../form/Dropdown';

type Props = {
  className?: string | string[];
  errors?: string[] | null;
  onValueChange: (category: ContentCategoryEnum) => void;
  value: ContentCategoryEnum | null | undefined;
};

export default function CategorySelector({
  errors,
  onValueChange,
  className,
  value,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dropdown
      id="dropdownCategorySelector"
      className={className}
      initialValue={ContentCategoryEnum.None}
      value={value}
      errors={errors}
      onValueChange={onValueChange}
      placeholder={t('Select category')}
      items={CONTENT_CATEGORIES}
    />
  );
}
