import env from './getEnv';
import { ENVIRONMENTS } from 'constants-activate';

export const APP_URLS = {
  [ENVIRONMENTS.PRODUCTION]: 'https://app.joinlane.com',
  [ENVIRONMENTS.STAGING]: 'https://s.app.joinlane.com',
  [ENVIRONMENTS.DEMO]: 'https://demo.joinlane.com',
  [ENVIRONMENTS.DEVELOPMENT]: 'https://d.app.joinlane.com',
  [ENVIRONMENTS.ESSENSYS]: 'https://essensys-test.dev.joinlane.com',
  [ENVIRONMENTS.LOCAL]: 'http://localhost:5000',
  [ENVIRONMENTS.TEST]: 'http://localhost:5000',
};

const { APP_URL } = process.env;

const appUrl = APP_URL || APP_URLS[env];

export const isHttps = Boolean(appUrl.startsWith('https://'));

export default appUrl;
