import React, { MutableRefObject, useEffect, useRef, memo } from 'react';

import cx from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ICON_SET_MATERIAL,
  ICON_SET_ION,
  ICON_SET_FEATHER,
  ICON_SET_REMIX,
  ICON_SET_FONTAWESOME,
  IconSet,
  FONT_AWESOME_REGULAR,
  FontAwesomeType,
} from 'lane-shared/helpers/constants/icons';
import { camelize } from 'lane-shared/helpers/formatters';

import IconList from '../../helpers/FontAwesomeProIcons';
import * as FontAwesomeIcons from 'react-icons/fa';
import * as FeatherIcons from 'react-icons/fi';
import * as IonIcons from 'react-icons/io';
import * as MaterialIcons from 'react-icons/md';
import * as RemixIcons from 'react-icons/ri';

import styles from './style.scss';
import { colors } from 'constants-colors';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  name: string;
  type?: FontAwesomeType;
  set?: IconSet;
  disabled?: boolean;
  testId?: string;
  size?: 'small' | 'medium' | 'large';
  withGradient?: boolean; // enable option of using gradient icon
  gradientColor?: [string, string]; // using for make icon with gradient color
  onClick?: () => void;
};

function BaseIcon({
  name,
  set = ICON_SET_FONTAWESOME,
  style,
  className,
  disabled = false,
  testId,
  type = FONT_AWESOME_REGULAR,
  size = 'small',
  onClick = () => {},
  withGradient,
  gradientColor = [colors.laneGreen, '#528fff'],
}: Props) {
  const iconRef = useRef(null);
  const gradientId = useRef(`${Math.random()}gradient`);

  function makeIconWithGradientColor(
    iconRef: MutableRefObject<null>,
    gradientColorRef: MutableRefObject<string>,
    gradientColor: [string, string]
  ) {
    // this function can help to make each icon with gradient color
    // default color: $lane-rainfall  [ '#00ce79', '#528fff']

    const nameSpaceURL = 'http://www.w3.org/2000/svg';
    const iconChild = (iconRef?.current as any)?.children[0];
    // creating defs of each svg icon
    const defs = document.createElementNS(nameSpaceURL, 'defs');
    const linearGradient = document.createElementNS(
      nameSpaceURL,
      'linearGradient'
    );

    linearGradient.setAttributeNS(null, 'id', gradientColorRef?.current);
    // choosing a first color of gradient
    const stopLeft = document.createElementNS(nameSpaceURL, 'stop');

    stopLeft.setAttributeNS(null, 'stop-color', gradientColor[0]);
    stopLeft.setAttributeNS(null, 'offset', '2.3%');
    // choosing a second color of gradient
    const stopRight = document.createElementNS(nameSpaceURL, 'stop');

    stopRight.setAttributeNS(null, 'offset', '100%');
    stopRight.setAttributeNS(null, 'stop-color', gradientColor[1]);
    defs.appendChild(linearGradient);
    linearGradient.appendChild(stopLeft);
    linearGradient.appendChild(stopRight);

    if (iconChild) {
      iconChild.appendChild(defs);
    }

    return true;
  }

  useEffect(() => {
    makeIconWithGradientColor(iconRef, gradientId, gradientColor);
  }, [withGradient]);

  useEffect(() => {
    const iconChild = (iconRef?.current as any)?.children[0];

    if (!iconChild) return;

    if (withGradient) {
      iconChild.setAttributeNS(null, 'fill', `url(#${gradientId?.current})`);
    } else {
      iconChild.setAttributeNS(null, 'fill', 'currentColor');
    }
  }, [withGradient]);

  let iconSet;
  let iconSize;
  let prefix;

  switch (size) {
    case 'large':
      iconSize = 'lg';
      break;
    case 'medium':
      iconSize = 'md';
      break;
    case 'small':
    default:
      iconSize = 'sm';
      break;
  }

  switch (set) {
    case ICON_SET_ION:
      iconSet = IonIcons;
      prefix = 'Io';
      break;
    case ICON_SET_MATERIAL:
      prefix = 'Md';
      iconSet = MaterialIcons;
      break;
    case ICON_SET_FONTAWESOME:
      prefix = 'Fa';
      iconSet = FontAwesomeIcons;
      break;
    case ICON_SET_REMIX:
      prefix = 'Ri';
      iconSet = RemixIcons;
      break;
    case ICON_SET_FEATHER:
    default:
      prefix = 'Fi';
      iconSet = FeatherIcons;
      break;
  }

  if (!name) {
    return <div className={className} style={style} />;
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const TheIcon = iconSet[prefix + camelize(name, true)];

  // this allows us to display all FontAwesome weights (light, regular, etc...)
  if (set === ICON_SET_FONTAWESOME && type) {
    const TheFAIcon =
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      IconList[`${type}-${prefix.toLowerCase()}${camelize(name, true)}`];

    return TheFAIcon ? (
      <div
        className={cx(className, styles.iconContainer)}
        data-size={iconSize}
        data-icon-name={TheFAIcon!.iconName}
        ref={iconRef}
        data-test={testId}
      >
        <FontAwesomeIcon
          icon={[TheFAIcon!.prefix, TheFAIcon!.iconName]}
          className={styles.icon}
          style={style}
          data-disabled={disabled}
          onClick={disabled ? () => null : onClick}
        />
      </div>
    ) : null;
  }

  if (!TheIcon) {
    console.warn(
      `Invalid name supplied to Icon: "${set}":"${name}" ${
        prefix + camelize(name, true)
      }`
    );

    return <div className={className} style={style} />;
  }

  if (set === ICON_SET_REMIX) {
    return (
      <TheIcon
        className={cx(className, 'icon', styles.remixIcon)}
        style={style}
        data-disabled={disabled}
        onClick={disabled ? null : onClick}
        data-test={testId}
      />
    );
  }

  if (withGradient) {
    return (
      <div className={cx(styles.iconBox, className)} ref={iconRef}>
        <TheIcon
          className="icon"
          style={style}
          data-disabled={disabled}
          onClick={disabled ? null : onClick}
          data-test={testId}
        />
      </div>
    );
  }

  return (
    <TheIcon
      className={cx(className, 'icon')}
      style={style}
      data-disabled={disabled}
      onClick={disabled ? null : onClick}
      data-test={testId}
    />
  );
}

export const Icon = memo(BaseIcon);
