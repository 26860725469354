import currencyCodes from 'currency-codes';

import { CURRENCY_USD } from 'constants-payments';
import getCountryByGeoLocation from './getCountryByGeoLocation';

export default function getCurrencyByGeoLocation({ latitude, longitude }: any) {
  if (!latitude || longitude) {
    return CURRENCY_USD;
  }

  const country = getCountryByGeoLocation({ latitude, longitude });
  const currency = currencyCodes.number(country?.iso_n3);

  return currency?.code;
}
