/* eslint jsx-a11y/mouse-events-have-key-events:0, jsx-a11y/no-static-element-interactions:0 */
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { cloneDeep } from 'lodash';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { ValidationError } from 'yup';

import { useQuery } from '@apollo/client';

import { ContentRendererContext } from 'lane-shared/contexts';
import RendererContext from 'lane-shared/contexts/RendererContext';
import { getChannelIntegrations } from 'lane-shared/graphql/query';
import { hasPermission, objectToArray } from 'lane-shared/helpers';
import {
  BLOCK_CONTAINERS,
  BLOCK_LOCK_MODES,
  BLOCK_TYPES,
  ContentTypeEnum,
  FeatureNameEnum,
} from 'constants-content';
import {
  ROW_CONTAINER_BLOCK_UUID_BASE_64,
  PlatformEnum,
  ExternalUrlOpeningModeEnum,
} from 'constants-activate';
import { PERMISSION_KEYS } from 'constants-permissions';
import { explodeFeatures } from 'lane-shared/helpers/features';
import getIntegrationDefinition from 'lane-shared/helpers/integrations/getIntegrationDefinition';
import { useContentTheme, useFlag, useMultiLanguage } from 'lane-shared/hooks';
import useContentRendererBlockContext from 'lane-shared/hooks/contentRenderer/useContentRendererBlockContext';
import useFeatureFlags from 'lane-shared/hooks/useFeatureFlags';
import { UrlType } from 'lane-shared/properties/baseTypes/Url';
import createBlockInstance from 'lane-shared/renderers/v5/createBlockInstance';
import Features from 'lane-shared/renderers/v5/features';
import generateHierarchy from 'lane-shared/renderers/v5/generateHierarchy';
import getBlockKey from 'lane-shared/renderers/v5/getBlockKey';
import * as Primitives from 'lane-shared/renderers/v5/primitives';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { UserType } from 'lane-shared/types/User';
import { BlockInstanceType } from 'lane-shared/types/blocks/BlockInterface';
import { ContentType } from 'lane-shared/types/content/Content';
import { LibraryType } from 'lane-shared/types/libraries';

import { getSurveyContentToRemove } from 'domains/surveys/helpers';

import BlockRenderer from 'components/lane/BlockRenderer';
import findTargetTopBlockId from 'components/renderers/helpers/findTargetTopBlockId';

import getBoundingClientRect from '../../helpers/getBoundingClientRect';
import Checkbox from '../form/Checkbox';
import ButtonStrip from '../general/ButtonStrip';
import ChannelIntegrationDropdown from '../lane/ChannelIntegrationDropdown';
import ScrollPanel from '../layout/ScrollPanel';
import BlockDropFrame, { Position } from './BlockDropFrame';
import BlockEditFrame from './BlockEditFrame';
import BlockHierarchy from './BlockHierarchy';
import NewBlockMenu from './NewBlockMenu';
import DeepLink from './deepLinkModal';
import { findBlock, removeBlock } from './helpers';
import defaultInteractiveBlock, {
  REQUIRED_INTERACTIVE_BLOCK_NAME,
} from './helpers/defaultInteractiveBlock';
import defaultPaymentBlock, {
  REQUIRED_PAYMENT_BLOCK_NAME,
} from './helpers/defaultPaymentBlock';
import { ExternalLink } from './ExternalLink';

import styles from './BlockBuilder.scss';
import { LanguagePreviewSelector } from 'components/general/LanguagePreviewSelector';
import { Flex } from 'design-system-web';
import { FeatureFlag } from 'constants-flags';

const DROP_AREAS = ['top', 'left', 'bottom', 'right', 'any'] as const;
const [DROP_TOP, DROP_LEFT, DROP_BOTTOM, DROP_RIGHT, DROP_ANY] = DROP_AREAS;

const THROTTLE = 10;

type DropArea = (typeof DROP_AREAS)[number];
type DropFrameState = {
  rect: Position;
  area: DropArea;
};

let lastBlockDragOver = Date.now();
let lastMouseOver = Date.now();

const togglesHiddenForContentTypes: ContentTypeEnum[] = [
  ContentTypeEnum.VisitorManagement,
  ContentTypeEnum.WorkOrder,
];

// these are needed by the renderer. usually these would be actual values,
// but this is showing an example render.
const editMode = true;
const loading = false;
const disabled = false;
const interaction = {};
const dataValidation = null;
const submitAttempted = false;
const onInteractionUpdated = () => null;
const onSubmit = () => null;
const onLink = () => null;
const onClick = () => null;

function applyDefaultInteractionBlock(
  content: any,
  blocks: any,
  requiresDefaultBlock: boolean,
  defaultBlock: any,
  defaultBlockName: string
): boolean {
  const interactiveBlock = findBlock({
    content,
    blocks,
    key: 'name',
    value: defaultBlockName,
  });

  if (requiresDefaultBlock) {
    if (!interactiveBlock) {
      content.block.properties.children.push({ ...defaultBlock });

      return true;
    }

    if (interactiveBlock?.revision !== defaultBlock.revision) {
      removeBlock(content, interactiveBlock, blocks);
      content.block.properties.children.push({ ...defaultBlock });

      return true;
    }
  } else if (interactiveBlock) {
    removeBlock(content, interactiveBlock, blocks);

    return true;
  }

  return false;
}

function removeInteractiveFeatureBlocks(content: any, blocks: any) {
  content.features.forEach((feature: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const featureDefinition = Features[feature.type];

    if (
      featureDefinition.requiresInteraction &&
      featureDefinition.interactionData
    ) {
      // Remove blocks that were added by this feature
      Object.keys(featureDefinition.interactionData).forEach(
        interactionName => {
          const block = findBlock({
            content,
            blocks,
            key: 'for',
            value: `${featureDefinition.name}.${interactionName}`,
          });

          if (block) {
            removeBlock(content, block, blocks);
          }
        }
      );
    }
  });
}

function hideTogglesForContentType(contentType: ContentTypeEnum) {
  return togglesHiddenForContentTypes.includes(contentType);
}

type DraggingBlock = { block: BlockInstanceType; action: 'data' };

export function BlockBuilder({
  channel,
  library,
  user,
  content,
  onContentUpdated,
  forTemplate,
  onDataValidation,
}: {
  channel?: Channel;
  library?: LibraryType;
  user?: UserType;
  content: ContentType;
  // TODO: the function param "content"s type here should be Partial<ContentType>.
  // update the type and resolve the typescript errors
  onContentUpdated: (content: any /* Partial<ContentType> */) => void;
  forTemplate?: boolean;
  onDataValidation: (error?: ValidationError) => void;
}) {
  const { t } = useTranslation();
  const { blocks } = useContext(RendererContext);
  const [draggingBlock, setDraggingBlock] = useState<DraggingBlock>(); // the block that is currently being dragged
  const [focusBlock, setFocusBlock] = useState(null); // the block that is currently being focused on (eg: during mouseOver) (blue border)
  const [activeBlock, setActiveBlock] = useState(null); // the block that is currently selected for editing (green border)
  const [dragOverBlock, setDragOverBlock] = useState(null); // the block that the draggingBlock is currently hovering over
  const [dragOverParentBlock, setDragOverParentBlock] = useState(null); // the parent of the block that the draggingBlock is currently hovering over
  const dropFrameRef = useRef(null) as MutableRefObject<any>; // the frame where we will drop the dragged block. a ref is used to prevent lots of rerenders
  const [dropFrameState, setDropFrameState] = useState<DropFrameState>(); // the frame where we will drop the dragged block
  const [platform, setPlatform] = useState(PlatformEnum.Mobile);
  const builderRef = useRef(null);
  const theme = useContentTheme(content, channel?.profile?.theme);
  const { enableWebviews } = useFeatureFlags();
  const { blockContext, updateBlockContext } = useContentRendererBlockContext();
  const isWorkOrderContent = content.type === ContentTypeEnum.WorkOrder;
  const { translate } = useMultiLanguage();
  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );
  const shouldDisplayLanguagePreviewSelector =
    channel?.settings?.multiLanguageEnabled &&
    isMLSLanguagePreviewSelectorEnabled;

  const channelDefaultLanguage = channel?.settings?.multiLanguageEnabled
    ? (channel?.settings?.channelLanguages?.primary as string)
    : '';

  const [previewLanguage, setPreviewLanguage] = useState(
    channelDefaultLanguage
  );

  const providerValue = useMemo(
    () => ({
      loading,
      disabled,
      content,
      interaction,
      dataValidation,
      submitAttempted,
      onInteractionUpdated,
      onSubmit,
      onLink,
      onClick,
      editMode,
      previewLanguage,
      theme,
      blockContext,
      updateBlockContext,
    }),
    [
      loading,
      disabled,
      content,
      interaction,
      dataValidation,
      submitAttempted,
      onInteractionUpdated,
      onSubmit,
      onLink,
      onClick,
      editMode,
      previewLanguage,
      theme,
      blockContext,
      updateBlockContext,
    ]
  );

  const { data } = useQuery(getChannelIntegrations, {
    skip: !channel,
    variables: { id: channel?._id },
    fetchPolicy: 'network-only',
  });

  // workorder will always be interactive
  if (isWorkOrderContent && !content.isInteractive) {
    toggleInteractive();
  }

  const integrationsList = data?.channel.integrations || [];
  const permittedToSeeIntegrationsCheckbox =
    user?.isSuperUser ||
    hasPermission(
      user?.roles || [],
      [
        PERMISSION_KEYS.PERMISSION_ADMIN,
        PERMISSION_KEYS.PERMISSION_INTEGRATION_READ,
      ],
      channel?._id
    );
  // are there any integrations attached to this content?
  const integrationProvider = content?.integration?.integration?.name;
  const hasCustomUI =
    integrationProvider &&
    getIntegrationDefinition(integrationProvider).hasCustomUI;

  if (!content.block) {
    content.block = {} as unknown as BlockInstanceType;
  }

  const hierarchy = useMemo(
    () => generateHierarchy({ blocks, content }),
    [content]
  );

  async function contentWillUpdate() {
    await onContentUpdated({
      block: { ...content.block },
    });

    setFocusBlock(focusBlock => {
      if ((focusBlock as any)?.frame?.target) {
        return {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...focusBlock,
          frame: {
            ...(focusBlock as any)?.frame,
            rect: getBoundingClientRect((focusBlock as any)?.frame?.target),
          },
        };
      }

      return focusBlock;
    });
  }

  const platformButtons = [
    {
      value: PlatformEnum.Mobile,
      name: t(
        'web.admin.channel.content.layout.editor.blockBuilder.mobileButton'
      ),
      icon: 'mobile',
    },
    {
      value: PlatformEnum.Web,
      name: t('web.admin.channel.content.layout.editor.blockBuilder.webButton'),
      icon: 'desktop',
    },
  ];

  useEffect(() => {
    // enforce some rules on content when it has updated.
    // this will re-run twice at most, because it changes content, which causes
    // itself to re-load.

    let shouldUpdate = false;

    // If there is no content, or there is an integration don't look for blocks
    if (!content || !content.block || hasCustomUI) {
      return;
    }

    const { paymentFeature } = explodeFeatures(content?.features);

    const requiresPurchaseButton = !!paymentFeature && content.isInteractive;

    shouldUpdate ||= applyDefaultInteractionBlock(
      content,
      blocks,
      requiresPurchaseButton,
      defaultPaymentBlock,
      REQUIRED_PAYMENT_BLOCK_NAME
    );

    const requiresSubmitButton = !paymentFeature && content.isInteractive;

    shouldUpdate ||= applyDefaultInteractionBlock(
      content,
      blocks,
      requiresSubmitButton,
      defaultInteractiveBlock,
      REQUIRED_INTERACTIVE_BLOCK_NAME
    );

    if (shouldUpdate) {
      contentWillUpdate();
    }
  }, [content, blocks]);

  function insertBlocksToBottom(instance: any) {
    const { children } = content.block.properties;

    let i = children.length;

    // iterate from the bottom up to find the first place where a block may be inserted.
    for (; i > 0; i--) {
      const child = children[i - 1];

      if (
        !child.lock ||
        !(
          child.lock.includes(BLOCK_LOCK_MODES.ALL) ||
          child.lock.includes(BLOCK_LOCK_MODES.POSITION)
        )
      ) {
        break;
      }
    }

    children.splice(i, 0, ...instance);

    contentWillUpdate();
  }

  function findTarget(blockId: any) {
    // passing react refs around through components is not ideal,
    // will use a good old fashion query selector to find the target instead
    return (builderRef.current as any)?.querySelector(
      `*[data-block-id="${blockId}"]`
    );
  }

  function clearSelection() {
    setDraggingBlock(undefined);
    dropFrameRef.current = null;
    setDropFrameState(undefined);
    setFocusBlock(null);
    setActiveBlock(null);
    setDragOverBlock(null);
  }

  useEffect(() => {
    setPreviewLanguage(channelDefaultLanguage);
  }, [channelDefaultLanguage]);

  const onNewBlockDragStart = useCallback(
    ({ block, action }: DraggingBlock) => {
      setDraggingBlock({ block, action });
    },
    []
  );

  const onNewBlockDragEnd = useCallback(() => {
    clearSelection();
  }, []);

  const onBlockMouseOver = (e: any) => {
    if (activeBlock) {
      // a block is currently active, we don't care about onMouseOver
      return;
    }

    const blockId = findTargetTopBlockId(e.target);

    if (blockId === (focusBlock as any)?.block?._id) {
      // only update if we are now focused on a new block
      // we are in the same block, nothing more to do here...
      return;
    }

    if (lastMouseOver + THROTTLE > Date.now()) {
      return;
    }

    lastMouseOver = Date.now();

    const target = findTarget(blockId);

    if (!target) {
      return;
    }

    // if we get this far, set a new focus block
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    const block = findBlock({ content, blocks, value: blockId });

    const editFrame = {
      target,
      rect: getBoundingClientRect(target),
      offset: { y: e.nativeEvent.offsetY, x: e.nativeEvent.offsetX },
    };

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ block: any; frame: { target: a... Remove this comment to see the full error message
    setFocusBlock({ block, frame: editFrame });
  };

  function onBackgroundClick(e: any) {
    if (e.target.id === 'background') {
      clearSelection();
    }
  }

  const onBlockClick = useCallback(
    (e: any) => {
      const blockId = findTargetTopBlockId(e.target);

      if (!blockId) return;

      const block = findBlock({ content, blocks, value: blockId });

      setActiveBlock(activeBlock ? null : block);
    },
    [content, activeBlock]
  );

  const onBlockDragStart = useCallback(
    (e: any) => {
      if (!e.nativeEvent.data) {
        return;
      }

      const { blockId, action } = e.nativeEvent.data;
      const block = findBlock({ content, blocks, value: blockId });

      setDraggingBlock({
        block,
        action,
      });

      e.stopPropagation();
    },
    [content]
  );

  const onBlockDragEnd = () => {
    clearSelection();
  };

  function findContainer(blockId: any, includeMe = true) {
    // get the closet parent container of this block.
    let container;

    function findParentContainer(block: any, id: any, localContainer: any) {
      if (block.blockType === BLOCK_TYPES.CONTAINER) {
        if (!localContainer) {
          container = block;
        }

        localContainer = block;
      }

      const children = block.children || [];

      for (const child of children) {
        if (child._id === id) {
          if (includeMe && child.blockType === BLOCK_TYPES.CONTAINER) {
            container = child;

            return;
          }

          container = localContainer;

          return;
        }

        findParentContainer(child, id, localContainer);
      }
    }

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    findParentContainer(hierarchy, blockId);

    // what kind of container is it?
    const ContainerBlock = blocks[getBlockKey(container)];

    return { container, ContainerBlock };
  }

  const findDropPosition = useCallback(
    (e: any) => {
      if (!dragOverParentBlock || !dragOverBlock) return;

      const target = findTarget((dragOverBlock as any)?._id);

      let area;
      let vertical = true;

      if (
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        dragOverParentBlock.blockId === ROW_CONTAINER_BLOCK_UUID_BASE_64 ||
        (dragOverParentBlock as any)?.properties?.flexDirection === 'row'
      ) {
        vertical = false;
      }

      const { offsetX, offsetY } = e.nativeEvent;
      const { clientHeight, clientWidth } = target;

      if (offsetY > clientHeight - 20) {
        area = DROP_BOTTOM;
      } else if (offsetY < 20) {
        area = DROP_TOP;
      } else if (
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        !dragOverParentBlock.properties.children ||
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        dragOverParentBlock.properties.children.length === 0
      ) {
        area = DROP_ANY;
      } else if (vertical && offsetY > clientHeight / 2) {
        area = DROP_BOTTOM;
      } else if (vertical && offsetY < clientHeight / 2) {
        area = DROP_TOP;
      } else if (!vertical && offsetX > clientWidth / 2) {
        area = DROP_RIGHT;
      } else {
        area = DROP_LEFT;
      }

      const rect = getBoundingClientRect(target);

      if (!rect) return;

      if (
        JSON.stringify(dropFrameRef.current?.rect) !== JSON.stringify(rect) || // TODO: consider using node package fast-deep-equal
        dropFrameRef.current?.area !== area
      ) {
        setDropFrameState({ rect, area });
      }

      dropFrameRef.current = {
        rect,
        area,
      };
    },
    [dragOverBlock]
  );

  const onBlockDragOver = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      const blockId = findTargetTopBlockId(e.target);

      if (lastBlockDragOver + THROTTLE > Date.now()) {
        return;
      }

      lastBlockDragOver = Date.now();

      const target = findTarget(blockId);

      if (!target) {
        return;
      }

      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      const block = findBlock({ content, blocks, value: blockId });

      setDragOverBlock(block);

      // this block is position locked, can't be dropped on
      if (
        block.lock &&
        block.lock.some((lock: any) =>
          [BLOCK_LOCK_MODES.ALL, BLOCK_LOCK_MODES.POSITION].includes(lock)
        )
      ) {
        return;
      }

      // find the closest container up the chain
      const { container } = findContainer(blockId);
      const containerBlock = findBlock({
        content,
        blocks,
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        value: container._id,
      });

      setDragOverParentBlock(containerBlock);
      findDropPosition(e);
    },
    [content, dragOverBlock]
  );

  const onNewBlockAdd = useCallback(
    ({ block, action }: DraggingBlock) => {
      // get the closet container up the tree
      switch (action) {
        case 'data':
          insertBlocksToBottom(block);
          break;
        // @ts-expect-error ts-migrate(2678) FIXME: Type '"new"' is not comparable to type '"data"'.
        case 'new': {
          insertBlocksToBottom([createBlockInstance(block)]);
          break;
        }
      }

      dropFrameRef.current = null;
      setDropFrameState(undefined);
      setFocusBlock(null);
      setActiveBlock(null);
    },
    [content]
  );

  function onBlockDragLeave(e: any) {
    dropFrameRef.current = null;
    setDropFrameState(undefined);
    e.stopPropagation();
  }

  function onBlockDrop(e: any) {
    if (!dropFrameRef.current) {
      e.stopPropagation();

      return;
    }

    let blockId = findTargetTopBlockId(e.target);

    // get the closest container up the tree
    let container = findBlock({
      content,
      blocks,
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      value: dragOverParentBlock._id,
    });

    const ContainerBlock = blocks[getBlockKey(container)];

    // if this is a row container, and we are trying to drop top or bottom
    // move up a level. or a column container and we are trying to drop left
    // right
    if (
      (ContainerBlock.def.name === BLOCK_CONTAINERS.BLOCK_CONTAINER_ROW &&
        [DROP_TOP, DROP_BOTTOM].includes(dropFrameRef.current.area)) ||
      (ContainerBlock.def.name === BLOCK_CONTAINERS.BLOCK_CONTAINER_COLUMN &&
        [DROP_LEFT, DROP_RIGHT].includes(dropFrameRef.current.area))
    ) {
      // move up one container level.
      blockId = container._id;
      const obj = findContainer(container._id, false);

      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      container = findBlock({ content, blocks, value: obj.container._id });
    }

    let instance = [];

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    switch (draggingBlock.action) {
      case 'data':
        // @ts-expect-error ts-migrate(2740) FIXME: Type 'BlockInstanceType' is missing the following ... Remove this comment to see the full error message
        instance = draggingBlock.block;
        break;
      // @ts-expect-error ts-migrate(2678) FIXME: Type '"move"' is not comparable to type '"data"'.
      case 'move':
        // clone this block, so changes don't effect it.
        instance = [
          {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            ...findBlock({ content, blocks, value: draggingBlock.block._id }),
          },
        ];
        // find where the block currently is and remove it.
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        removeBlock(content, draggingBlock.block, blocks);
        break;
      // @ts-expect-error ts-migrate(2678) FIXME: Type '"new"' is not comparable to type '"data"'.
      case 'new': {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        instance = [createBlockInstance(draggingBlock.block)];
        break;
      }
    }

    if (!container.properties) {
      container.properties = {
        children: [],
      };
    }

    if (!container.properties.children) {
      container.properties.children = [];
    }

    // insert at the new location.

    if (dropFrameRef.current.area === DROP_ANY) {
      container.properties.children.push(...instance);
    } else {
      // find the position of the block in reference.
      const position = container.properties.children.findIndex(
        (b: any) => b._id === blockId
      );

      switch (dropFrameRef.current.area) {
        case DROP_TOP:
        case DROP_LEFT:
          container.properties.children.splice(
            Math.max(position, 0),
            0,
            ...instance
          );
          break;
        case DROP_BOTTOM:
        case DROP_RIGHT:
          container.properties.children.splice(
            Math.min(position + 1, container.properties.children.length),
            0,
            ...instance
          );
          break;
      }
    }

    contentWillUpdate();
    dropFrameRef.current = null;
    setDropFrameState(undefined);
    setFocusBlock(null);
    setActiveBlock(null);
    e.stopPropagation();
  }

  function onBlockClone({ block }: any) {
    function generateNewIds(b: any) {
      b._id = uuid();

      if (b.children) {
        b.children.forEach(generateNewIds);
      }

      if (b.properties && b.properties.children) {
        b.properties.children.forEach(generateNewIds);
      }
    }

    const originalBlock = findBlock({ content, blocks, value: block._id });

    // deep clone the block.
    const newBlock = JSON.parse(JSON.stringify(originalBlock));

    // create new uuids.
    newBlock._id = uuid();
    generateNewIds(newBlock);

    insertBlocksToBottom([newBlock]);
  }

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  function onBlockMove(block: any, up: any) {
    // get the closet container up the tree
    let { container } = findContainer(block._id, false);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    let containerBlock = findBlock({ content, blocks, value: container._id });
    let position = containerBlock.properties.children.findIndex(
      (b: any) => b._id === block._id
    );

    // if this is in the first position. moving up will bump it to a parent container
    // or if this is in the last position, moving down will bump it to a parent container.
    // (if a parent container exists)

    const lastContainer = container;

    // keep looping until the top most container is found.
    while (
      (up && position === 0) ||
      (!up && position === containerBlock.properties.children.length - 1)
    ) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const parentContainer = findContainer(container._id, false);

      container = parentContainer.container;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      containerBlock = findBlock({ content, blocks, value: container._id });

      position = containerBlock.properties.children.findIndex(
        (b: any) => b._id === containerBlock._id
      );

      // we haven't moved anywhere, time to break out
      if (container === lastContainer) {
        return;
      }
    }

    // clone this block, so changes don't effect it.
    const instance = {
      ...findBlock({ content, blocks, value: block._id }),
    };

    if (!containerBlock.properties) {
      containerBlock.properties = {
        children: [],
      };
    }

    if (!containerBlock.properties.children) {
      containerBlock.properties.children = [];
    }

    const newPosition = position + (up ? -1 : 1);
    // check out the block locks at the position we want to move it to
    const dest = containerBlock.properties.children[newPosition];

    if (dest && dest.lock) {
      if (
        dest.lock.includes(BLOCK_LOCK_MODES.ALL) ||
        dest.lock.includes(BLOCK_LOCK_MODES.POSITION)
      ) {
        window.Toast.show(
          t('web.admin.channel.content.layout.editor.blockBuilder.lockedBlock')
        );

        return false;
      }
    }

    // find where the block currently is and remove it.
    removeBlock(content, block, blocks);

    // insert at the new location.
    containerBlock.properties.children.splice(newPosition, 0, instance);

    contentWillUpdate();
  }

  function onBlockMoveUp(block: any) {
    return onBlockMove(block, true);
  }

  function onBlockMoveDown(block: any) {
    return onBlockMove(block, false);
  }

  async function onDeleteBlock(block: any) {
    let originalBlock = findBlock({ content, blocks, value: block._id });

    // This is for a data block.  Special remove case.
    if (originalBlock.dataBlock) {
      originalBlock = findBlock({
        content,
        blocks,
        value: originalBlock.dataBlock,
      });
    }

    const Block = originalBlock.primitive
      ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        Primitives[originalBlock.primitive]
      : blocks[getBlockKey(originalBlock)];

    const name = originalBlock.name || Block.blockName || Block.primitive;

    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.content.layout.editor.blockBuilder.delete.title',
          { name }
        ),
        message: t(
          'web.admin.channel.content.layout.editor.blockBuilder.delete.message'
        ),
      });

      removeBlock(content, originalBlock, blocks);
      clearSelection();
      contentWillUpdate();
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // User clicked cancel
    }
  }

  function onContentMouseLeave() {
    if (!activeBlock) {
      // todo: activeBlock expects focusBlock to be the same block. would be nice to separate these concerns
      setFocusBlock(null);
    }
  }

  function notLink() {
    return !content.externalUrl && !content.deepLink;
  }

  async function toggleExternal() {
    if (content.externalUrl) {
      try {
        await window.Alert.confirm({
          title: t(
            'web.admin.channel.content.layout.editor.removeExternalLink.title'
          ),
          message: t(
            'web.admin.channel.content.layout.editor.removeExternalLink.message'
          ),
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled out.
        return;
      }

      onContentUpdated({
        externalUrl: null,
      });
    } else {
      try {
        await window.Alert.confirm({
          title: t(
            'web.admin.channel.content.layout.editor.addExternalLink.title'
          ),
          message: t(
            'web.admin.channel.content.layout.editor.addExternalLink.message'
          ),
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled out.
        return;
      }

      onContentUpdated({
        externalUrl: {
          _id: uuid(),
          url: 'https://www.example.com',
          openingMode: ExternalUrlOpeningModeEnum.InApp,
        },
      });
      onDataValidation();
    }
  }

  async function toggleDeepLink() {
    if (content.deepLink) {
      try {
        await window.Alert.confirm({
          title: t(
            'web.admin.channel.content.layout.editor.removeDeepLink.title'
          ),
          message: t(
            'web.admin.channel.content.layout.editor.removeDeepLink.message'
          ),
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        return;
      }

      onContentUpdated({
        deepLink: null,
      });
    } else {
      try {
        await window.Alert.confirm({
          title: t('web.admin.channel.content.layout.editor.addDeepLink.title'),
          message: t(
            'web.admin.channel.content.layout.editor.addDeepLink.message'
          ),
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        return;
      }

      onContentUpdated({
        deepLink: {
          _id: uuid(),
          deepLinkUrl: 'ExampleApp://example/path/here',
          appStoreUrl:
            'https://apps.apple.com/ca/app/lane-smart-workplaces/id1049113820',
          googlePlayStoreUrl:
            'https://play.google.com/store/apps/details?id=com.lane.lane',
        },
      });
      onDataValidation();
    }
  }

  const urlTypeSchema = new UrlType().schema;
  const [externalUrlValidationError, setExternalUrlValidationError] =
    useState<ValidationError | null>(null);

  async function toggleInteractive() {
    if (content.isInteractive) {
      try {
        await window.Alert.confirm({
          title: t(
            'web.admin.channel.content.layout.editor.removeInteractivity.title'
          ),
          message: t(
            'web.admin.channel.content.layout.editor.removeInteractivity.message'
          ),
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled out.
        return;
      }

      // remove all input blocks from content,
      const dataDefinitions = objectToArray(content.data);

      dataDefinitions.forEach(field => {
        const existingBlock = findBlock({
          content,
          blocks,
          key: 'for',
          value: field.name!,
        });

        if (existingBlock) {
          removeBlock(content, existingBlock, blocks);
        }
      });

      const isSurveysEnabled = content.features.some(
        ({ type }) => type === FeatureNameEnum.Surveys
      );

      const update = {
        // remove features that require interaction.
        features: content.features
          ? content.features.filter(
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              (feature: any) => !Features[feature.type].requiresInteraction
            )
          : [],
        // remove all data,
        data: {},
        // remove survey related blocks if Surveys was enabled
        ...(isSurveysEnabled && {
          block: getSurveyContentToRemove({
            content,
          })?.block,
        }),
        isInteractive: false,
      };

      removeInteractiveFeatureBlocks(content, blocks);
      onContentUpdated(update);
    } else {
      const update = {
        features: [
          ...(content.features ? content.features : []),
          {
            _id: uuid(),
            type: FeatureNameEnum.UseCompanyPermissions,
            feature: {
              useContentCategories: false,
              permissions: [],
            },
          },
        ],
        data: {},
        isInteractive: true,
      };

      onContentUpdated(update);
    }
  }

  async function toggleIntegration() {
    // if turning on, warn user that they will loose their content.
    // if turning off warn user that they will loose their integration.

    if (content.integration) {
      try {
        await window.Alert.confirm({
          title: t(
            'web.admin.channel.content.layout.editor.removeIntegration.title'
          ),
          message: t(
            'web.admin.channel.content.layout.editor.removeIntegration.message'
          ),
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled.
        return;
      }

      onContentUpdated({
        integration: null,
      });
    } else {
      try {
        await window.Alert.confirm({
          title: t(
            'web.admin.channel.content.layout.editor.addIntegration.title'
          ),
          message: t(
            'web.admin.channel.content.layout.editor.addIntegration.message'
          ),
        });
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        // user cancelled;
        return;
      }

      onContentUpdated({
        integration: {
          _id: null,
        },
      });
    }
  }

  let contentForPreview: ContentType | undefined;

  if (shouldDisplayLanguagePreviewSelector && previewLanguage) {
    contentForPreview = translate({
      model: cloneDeep(content),
      previewLanguage,
    });
  }

  return (
    <div
      data-test="builderPage"
      className={cx(
        styles.BlockBuilder,
        !notLink() ? styles.BlockBuilderVertical : ''
      )}
      onClick={onBackgroundClick}
      onDragStart={onBlockDragStart}
      onDragEnd={onBlockDragEnd}
      onDragLeave={onBlockDragLeave}
      onDrop={onBlockDrop}
    >
      <div data-test="blocks" className={styles.blocks}>
        {!hideTogglesForContentType(content.type) && (
          <div className={styles.checkboxes}>
            {enableWebviews && !content.deepLink && (
              <Checkbox
                className={styles.checkbox}
                text={t(
                  'web.admin.channel.content.layout.editor.checkboxes.externalLink'
                )}
                selected={!!content.externalUrl}
                onChange={toggleExternal}
                testId="checkboxExternal"
                value
              />
            )}

            {enableWebviews && !content.externalUrl && (
              <Checkbox
                className={styles.checkbox}
                text={t(
                  'web.admin.channel.content.layout.editor.checkboxes.deepLink'
                )}
                selected={!!content.deepLink}
                onChange={toggleDeepLink}
                testId="checkboxDeep"
                value
              />
            )}

            {notLink() && (
              <>
                <Checkbox
                  className={styles.checkbox}
                  text={t(
                    'web.admin.channel.content.layout.editor.checkboxes.interactive'
                  )}
                  selected={content.isInteractive}
                  onChange={toggleInteractive}
                  testId="checkboxInteractive"
                  value
                />

                {!forTemplate && permittedToSeeIntegrationsCheckbox && (
                  <Checkbox
                    data-test="checkboxIntegration"
                    className={styles.checkbox}
                    text={t(
                      'web.admin.channel.content.layout.editor.checkboxes.integration'
                    )}
                    selected={!!content.integration}
                    onChange={toggleIntegration}
                    value
                  />
                )}
              </>
            )}
          </div>
        )}

        {notLink() && !hasCustomUI && (
          <ScrollPanel className={styles.window}>
            <div className={styles.options}>
              <NewBlockMenu
                content={content}
                onNewBlockDragEnd={onNewBlockDragEnd}
                onNewBlockDragStart={onNewBlockDragStart}
                onNewBlockAdd={onNewBlockAdd}
              />
            </div>
          </ScrollPanel>
        )}
      </div>

      <section
        data-test="blockBackground"
        id="background"
        className={styles.fullWidth}
      >
        {content && !!content.integration && (
          <ChannelIntegrationDropdown
            className={styles.integrationDropDown}
            channelId={channel?._id}
            value={content.integration._id}
            onValueChange={(value: any) => {
              content.integration._id = value;
              contentWillUpdate();
            }}
          />
        )}

        {notLink() && content && !hasCustomUI && (
          <>
            <Flex
              direction="row"
              justify={
                shouldDisplayLanguagePreviewSelector
                  ? 'space-between'
                  : 'center'
              }
              className={styles.toolBar}
              data-platform={platform}
            >
              <ButtonStrip
                className={styles.buttonStrip}
                buttons={platformButtons}
                onClick={value => setPlatform(value)}
                selected={platform}
                doTranslate={false}
              />
              {shouldDisplayLanguagePreviewSelector && (
                <LanguagePreviewSelector
                  previewLanguage={previewLanguage}
                  setPreviewLanguage={setPreviewLanguage}
                />
              )}
            </Flex>

            <div className={styles.contentWrapper}>
              <div
                data-test="blockDroppable"
                ref={builderRef}
                className={styles.content}
                data-platform={platform}
                onClick={onBlockClick}
                onMouseOver={onBlockMouseOver}
                onMouseLeave={onContentMouseLeave}
                onDragOver={onBlockDragOver}
              >
                {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ loading: boolean; disabled: boolean; conte... Remove this comment to see the full error message */}
                <ContentRendererContext.Provider value={providerValue}>
                  <BlockRenderer
                    top={contentForPreview?.block ?? content.block}
                    isTop
                    content={content}
                    blocks={blocks}
                  />
                </ContentRendererContext.Provider>
              </div>
            </div>
          </>
        )}

        {!!content.externalUrl && (
          <ExternalLink
            content={content}
            channel={channel!}
            integrationsList={integrationsList}
            onDataValidation={onDataValidation}
            urlTypeSchema={urlTypeSchema}
            setExternalUrlValidationError={setExternalUrlValidationError}
            externalUrlValidationError={externalUrlValidationError}
            onContentUpdated={onContentUpdated}
          />
        )}

        {!!content.deepLink && (
          <DeepLink
            content={content}
            onDataValidation={onDataValidation}
            urlTypeSchema={urlTypeSchema}
            onContentUpdated={onContentUpdated}
          />
        )}

        {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ loading: boolean; disabled: boolean; conte... Remove this comment to see the full error message */}
        <ContentRendererContext.Provider value={providerValue}>
          <BlockEditFrame
            channel={channel}
            library={library}
            user={user}
            content={content}
            blocks={blocks}
            forTemplate={forTemplate}
            block={(focusBlock as any)?.block}
            position={(focusBlock as any)?.frame?.rect}
            theme={theme}
            onDeleteBlock={onDeleteBlock}
            onBlockClone={onBlockClone}
            onBlockMoveUp={onBlockMoveUp}
            onBlockMoveDown={onBlockMoveDown}
            onClose={clearSelection}
            onBlockUpdated={contentWillUpdate}
            onContentUpdated={contentWillUpdate}
            isActive={
              (focusBlock as any)?.block?._id === (activeBlock as any)?._id
            }
          />
        </ContentRendererContext.Provider>

        {dropFrameState && (
          <BlockDropFrame
            position={dropFrameState.rect}
            dropAny={dropFrameState.area === DROP_ANY}
            dropTop={dropFrameState.area === DROP_TOP}
            dropRight={dropFrameState.area === DROP_RIGHT}
            dropBottom={dropFrameState.area === DROP_BOTTOM}
            dropLeft={dropFrameState.area === DROP_LEFT}
          />
        )}
      </section>

      {notLink() && !hasCustomUI && (
        <div
          onClick={onBlockClick}
          onMouseOver={onBlockMouseOver}
          onMouseLeave={onContentMouseLeave}
          onDragOver={onBlockDragOver}
        >
          <ScrollPanel
            className={cx(styles.window, styles.blockHierarchy)}
            header={t(
              'web.admin.channel.content.layout.editor.blockBuilder.outline'
            )}
          >
            <BlockHierarchy
              hierarchy={hierarchy}
              focusBlock={(focusBlock as any)?.block}
              activeBlock={activeBlock}
              dragOverBlock={dragOverBlock}
            />
          </ScrollPanel>
        </div>
      )}
    </div>
  );
}
