import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';
import { PERMISSION_KEYS } from 'constants-permissions';

export type GoogleCalendarSubscriptionType = {
  id: string;
  expiration: number;
  googleResourceId: string;
};

export type GoogleCalendarSettingsSubscriptionsType = {
  [resourceId: string]: GoogleCalendarSubscriptionType;
};

export type GoogleCalendarSettingsType = {
  privateKey: string;
  email: string;
  impersonationUser: string;
  subscriptions: GoogleCalendarSettingsSubscriptionsType;
};

const GoogleCalendarDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.GoogleCalendar,
  hasCustomUI: false,
  friendlyName: 'Google Calendar',
  description: `Go to Google Cloud Platform and set up a new project.
    Then you would be able to create a service account for the project.
    Then you could download your crendetials as keyFile.json for the service account.
    Paste all required fields below.`,
  category: IntegrationCategoryEnum.Events,
  platforms: [],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    email: {
      friendlyName: 'Service account email',
      type: 'String',
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_KEYS.PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    privateKey: {
      friendlyName: 'Service account private key',
      type: 'String',
      secure: {
        read: [
          {
            type: SecurityRuleTypeEnum.ChannelPermission,
            value: PERMISSION_KEYS.PERMISSION_INTEGRATION_READ,
          },
        ],
      },
    },
    impersonationUser: {
      friendlyName: 'Google user account to impersonate by service account',
      type: 'String',
    },
    subscriptions: {
      hidden: true,
      editable: false,
      type: 'Any',
      default: {},
    },
  },
  mobilePermissions: [],
};

export default GoogleCalendarDefinition;
