import React from 'react';

import { Channel } from 'packages/lane-shared/types/ChannelType';
import { FeatureNameEnum } from 'constants-content';
import { RequirementsFeatureProperties } from 'lane-shared/types/features/RequirementsFeatureProperties';

import FeatureAutoRenderer from 'components/renderers/features/components/FeatureAutoRenderer';

type Props = {
  onChangeRequirements: (nextState: object) => void;
  requirements: RequirementsFeatureProperties | {} | undefined;
  channel: Channel;
};

export default function ChannelRequirementsSettings({
  channel,
  onChangeRequirements,
  requirements = {},
}: Props) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'feature' is missing in type '{ featureNa... Remove this comment to see the full error message
    <FeatureAutoRenderer
      featureName={FeatureNameEnum.Requirements}
      onFeatureUpdated={onChangeRequirements}
      contentFeature={{ feature: requirements }}
      timeZone={timeZone}
      content={{
        channel,
      }}
    />
  );
}
