import React from 'react';

import cx from 'classnames';

import { Channel } from 'packages/lane-shared/types/ChannelType';
import { MetatagType } from 'constants-content';
import {
  MetatagFilter,
  MetatagFilterUI,
} from 'lane-shared/types/filters/MetatagFilter';

import MetatagComplexOption from './MetatagComplexOption';
import MetatagSimpleOption from './MetatagSimpleOption';

import styles from './MetatagFilterMenu.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  metatags?: MetatagFilterUI[];
  filters: MetatagFilter[];
  channel?: Channel;
  allowDisableFilter?: boolean;
  onFiltersUpdated: (update: any) => void;
};

export default function MetatagFilterMenu({
  className,
  style,
  metatags,
  filters,
  channel,
  allowDisableFilter = false,
  onFiltersUpdated,
}: Props) {
  function updateFilter(filter: any) {
    onFiltersUpdated([
      ...filters.filter(({ _id }) => _id !== filter?._id),
      filter,
    ]);
  }

  return (
    <menu className={cx(styles.MetatagFilterMenu, className)} style={style}>
      {metatags?.map(metatag => {
        const filter = filters?.find(
          filter => filter.metatagId === metatag._id
        );

        if (metatag.type === MetatagType.Simple) {
          return (
            <MetatagSimpleOption
              key={metatag._id}
              metatag={metatag}
              channel={channel}
              value={filter?.value}
              isEnabled={filter?.enabled}
              allowDisableFilter={allowDisableFilter}
              onChange={value => {
                updateFilter({
                  ...filter,
                  value,
                });
              }}
              onEnable={enabled => {
                updateFilter({
                  ...filter,
                  enabled,
                });
              }}
            />
          );
        }

        return (
          <MetatagComplexOption
            key={metatag._id}
            metatag={metatag}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[] | undefined' is not assignable to t... Remove this comment to see the full error message
            values={filter?.values}
            isEnabled={filter?.enabled}
            allowDisableFilter={allowDisableFilter}
            onChange={values => {
              updateFilter({
                ...filter,
                values,
              });
            }}
            onEnable={enabled => {
              updateFilter({
                ...filter,
                enabled,
              });
            }}
          />
        );
      })}
    </menu>
  );
}
