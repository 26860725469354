import { PaymentCurrencyEnum } from 'constants-payments';

/**
 * Formats to currency in the format of ${symbol}${amount} ${currency code}.
 * Ex. $123.23 USD
 * @param amount
 * @param currency - a currency code
 * @returns a formatted currency in the format of ${symbol}${amount} ${currency code}
 */
export default function simpleCurrencyFormatter(
  amount: number,
  currency: PaymentCurrencyEnum = PaymentCurrencyEnum.USD
) {
  const numberFormat = new Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
  });

  const symbol = numberFormat.formatToParts(amount)[0];

  return `${currency} ${symbol.value}${amount.toFixed(2)}`;
}
