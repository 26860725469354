import { PUBLIC_USER_FIELDS } from 'constants-user';

/**
 * Takes a queryField object for a user field and secures those fields
 * only allowing the publically available fields to be returned.
 *
 * @param queryFields
 * @returns {{}|null}
 */
export default function secureUserQueryFields(queryFields: any) {
  if (!queryFields) {
    return null;
  }

  const securedQuery = {};

  PUBLIC_USER_FIELDS.forEach(key => {
    if (queryFields[key]) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      securedQuery[key] = queryFields[key];
    }
  });

  return securedQuery;
}
