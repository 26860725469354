import * as yup from 'yup';

import { PlatformEnum } from 'constants-activate';

import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

export default yup.object().shape({
  name: yup
    .string()
    .trim()
    .oneOf(Object.values(IntegrationProviderEnum))
    .required(),
  category: yup
    .string()
    .trim()
    .oneOf(Object.values(IntegrationCategoryEnum))
    .required(),
  type: yup
    .string()
    .trim()
    .oneOf(Object.values(IntegrationTypeEnum))
    .required(),
  platforms: yup
    .array()
    .of(yup.string().trim().oneOf(Object.values(PlatformEnum)))
    .min(0)
    .max(Object.values(PlatformEnum).length),
  settings: yup.object(),
  properties: yup.object().nullable(),
  workflows: yup.array().nullable(),
});
