import {
  SYSTEM_LIBRARY_ID,
  LANE_TEMPLATE_LIBRARY_ID,
} from 'constants-activate';

import { LibraryTypeEnum } from 'constants-content';

import { LibraryType } from '../../types/libraries/LibraryType';

import { convertTo62 } from 'uuid-encoding';

export const LANE_TEMPLATE_LIBRARY: LibraryType = {
  type: LibraryTypeEnum.Library,
  _id: convertTo62(LANE_TEMPLATE_LIBRARY_ID),
  name: 'Lane Templates',
};

export const LANE_MEDIA_LIBRARY: LibraryType = {
  type: LibraryTypeEnum.Library,
  _id: SYSTEM_LIBRARY_ID,
  name: 'Lane Media',
};
