import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChipSelect, ChipStyle } from 'components/ads';

import { chipListColours } from './helpers/constants';
import { WorkflowStatusEnum, WorkflowStatusLabels } from 'constants-workflows';
import { Workflow } from 'packages/lane-shared/types/ContentWorkflowType';

type Props = {
  workflow: Workflow;
  onWorkflowUpdated: (updates: Partial<Workflow>) => void;
  isEditing: boolean | undefined;
};

export function WorkflowStatus({
  workflow,
  onWorkflowUpdated,
  isEditing,
}: Props) {
  const { t } = useTranslation();

  const getChipType = (status: WorkflowStatusEnum) =>
    chipListColours[status] || ChipStyle.Red;

  const statusOptions = Object.values(WorkflowStatusEnum).map(status => ({
    label: t(WorkflowStatusLabels[status]),
    value: status,
    type: getChipType(status),
  }));

  return (
    <ChipSelect
      list={statusOptions}
      value={workflow.workflow?.value || ''}
      isDisabled={!isEditing}
      onChange={selectedStatus =>
        onWorkflowUpdated({
          workflow: { on: 'status', value: selectedStatus },
        })
      }
    />
  );
}
