import { ContentTypeEnum , FeatureNameEnum } from 'constants-content';

import { FeatureInstance } from './FeatureInstance';
import { PropertyType } from './properties/Property';

/**
 * Definitions for validators that can be attached to properties.
 *
 * These validators should be pretty simple and minimal, if more advanced validation is required,
 * consider making a new Type definition.
 *
 * i.e. see the Email type.
 *
 * Consider that these are created to for the end users. Not for developers.
 */

export enum ContentTypeRequirementEnum {
  Forbidden = 'Forbidden',
  Optional = 'Optional',
  Required = 'Required',
}

export type ContentTypeRequirement = {
  type: ContentTypeEnum;
  requirement: ContentTypeRequirementEnum;
};

export type FeatureDefinition = {
  // a name for this feature
  name: FeatureNameEnum;
  // an end user friendly name for the UI for this feature
  friendlyName?: string;
  // a description for the end user to describe this feature
  description: string;
  // is this feature for super users only
  superUserOnly?: boolean;
  // does this feature require the content to be interactive?
  requiresInteraction?: boolean;
  // is there a required permission for this feature?
  permission?: string;
  // the required data that must be input to attach this feature to
  //  a piece of content.
  properties?: {
    [key: string]: PropertyType;
  };
  // the required data that must be included in an interaction when the
  //  end user is interacting with content that has this feature attached
  interactionData?: {
    [key: string]: PropertyType;
  };
  getInteractionData?(featureInstance: FeatureInstance): {
    [key: string]: PropertyType;
  };
  contentTypeRequirements?: ContentTypeRequirement[];
};
