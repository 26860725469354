import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  ICON_SET_FONTAWESOME,
  IconSet,
  FontAwesomeType,
} from 'lane-shared/helpers/constants/icons';

import styles from './LinkButton.scss';

type OwnLinkButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  iconName: string;
  iconSet?: IconSet;
  doTranslate?: boolean;
  iconType?: FontAwesomeType;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

type LinkButtonProps = OwnLinkButtonProps;

export default function LinkButton({
  className,
  style,
  disabled = false,
  loading = false,
  onClick = () => {},
  iconName,
  iconSet = ICON_SET_FONTAWESOME,
  iconType,
  doTranslate = false,
  children,
}: LinkButtonProps) {
  const { t } = useTranslation();

  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={cx(styles.LinkButton, className)}
      style={style}
    >
      <Icon
        className={styles.icon}
        name={iconName}
        set={iconSet}
        type={iconType}
      />
      <span>
        {doTranslate && typeof children === 'string' ? t(children) : children}
      </span>
    </button>
  );
}
