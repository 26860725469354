import { useContext } from 'react';

import { AppContext } from 'lane-shared/contexts';
import {
  OAuthProvidersEnum,
  OAUTH_PROVIDERS,
  UserLoginProviderEnum,
} from 'constants-user';
import { canHandleError } from 'lane-shared/helpers/oAuth';
import useOAuthSignUp, {
  OAuthGetIdentityResponse,
} from 'lane-shared/hooks/useOAuthSignUp';
import LaneOAuthHook from 'lane-shared/types/LaneOAuthHook';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';

import { getOAuthIdentity } from '../utils/oauth';
import { getGoogleAccessToken } from '../utils/oauth/google';

type UseGoogleOAuthProps = {
  oAuthConfig: OAuthConfigType;
  onError?: (err: Error | null) => void;
  onSuccess: (signUpData?: any) => void;
};

export default function useGoogleOAuth({
  oAuthConfig,
  onError,
  onSuccess,
}: UseGoogleOAuthProps): LaneOAuthHook {
  const { setIsBlockingLoad } = useContext(AppContext);

  function handleError(err: Error | null) {
    if (onError && canHandleError(err)) {
      onError(err);
    }

    setIsBlockingLoad(false);
  }

  function handleResume() {
    setIsBlockingLoad(true);
  }

  function handleSuccess(signInData: any) {
    setIsBlockingLoad(false);
    onSuccess(signInData);
  }

  async function getIdentity({
    oAuthConfig,
  }: any): Promise<OAuthGetIdentityResponse> {
    const oAuthIdentity = await getOAuthIdentity({
      oAuthConfig,
      provider: OAuthProvidersEnum.GOOGLE,
    });

    const preferredName =
      oAuthIdentity?.extra?.name ||
      `${oAuthIdentity?.extra?.given_name} ${oAuthIdentity?.extra?.family_name}` ||
      oAuthIdentity?.extra?.email ||
      'Guest';
    const userPrincipalName = oAuthIdentity?.extra?.email;

    return {
      preferredName,
      userPrincipalName,
    };
  }

  const { doSignUp } = useOAuthSignUp(
    getOAuthIdentity,
    getGoogleAccessToken,
    getIdentity,
    { onSuccess: handleSuccess, onError: handleError, onResume: handleResume }
  );

  function onGoogleSignUp() {
    handleError(null);
    doSignUp({
      oAuthIdentityProps: {
        oAuthConfig,
        provider: OAuthProvidersEnum.GOOGLE,
      },
      oAuthGetIdentityProps: {
        oAuthConfig,
      },
      oAuthAccessTokenProps: {
        oAuthConfig,
      },
      legacyOAuthProvider: OAUTH_PROVIDERS.GOOGLE,
      loginProvider: UserLoginProviderEnum.Google,
    });
  }

  return {
    isEnabled: Boolean(oAuthConfig?.enabled),
    authorize: onGoogleSignUp,
  };
}
