import React from 'react';

import { Toggle } from 'components';

import { Channel } from 'packages/lane-shared/types/ChannelType';

import styles from 'components/lane/ChannelSettingsEdit/ChannelSettings.scss';

type props = {
  channel: Partial<Channel> & {
    settings: {
      hasReservableManagementEnabled: boolean;
    };
  };
  onChannelUpdated: (channel: any) => void;
  t: (key: string, params?: any) => string;
};

export function ReservableManagementToggle({
  channel,
  onChannelUpdated,
  t,
}: props) {
  const handleChange = (enabled: boolean) => {
    onChannelUpdated({
      settings: {
        ...channel.settings,
        hasReservableManagementEnabled: enabled,
      },
    });
  };

  return (
    <div className={styles.setting}>
      <Toggle
        className={styles.toggle}
        testId="toggleReservableManagement"
        value={channel.settings.hasReservableManagementEnabled || false}
        onChange={handleChange}
        text={t('web.admin.channel.reservableManagement.toggleTitle')}
        description={t(
          'web.admin.channel.reservableManagement.toggleDescription'
        )}
      />
    </div>
  );
}
