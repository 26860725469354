import { UserGroupRoleType } from '../../types/UserGroupRole';
import { PERMISSION_KEYS } from 'constants-permissions';
import { Channel } from 'lane-shared/types/ChannelType';

export default function hasAdminOnChannel(
  roles: UserGroupRoleType[] = [],
  channel: Channel,
  forMobile: boolean = false
): boolean {
  return roles?.some(
    ({ groupRole }) =>
      groupRole.channel?.hierarchy?.hierarchyDown?.includes(channel?._id) &&
      groupRole.permissions.includes(
        forMobile
          ? PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS_MOBILE
          : PERMISSION_KEYS.PERMISSION_PORTAL_ACCESS
      )
  );
}
