import React, { useContext, useMemo } from 'react';

import { RendererContext, ContentRendererContext } from 'lane-shared/contexts';
import { useContentQuantityState } from 'lane-shared/hooks/contentState';
import { useContentTheme, useChannelTheme } from 'lane-shared/hooks';

import useContentRendererBlockContext from 'lane-shared/hooks/contentRenderer/useContentRendererBlockContext';
import { ContentType } from 'lane-shared/types/content/Content';
import { IntegrationProviderEnum } from 'constants-integrations';
import { useSAMLGraphqlEnabled } from 'lane-shared/hooks/useSAMLGraphqlEnabled';

import Loading from '../general/Loading';
import AngusServiceRequests from '../integrations/AngusServiceRequests/Welcome';
import Auth0SamlWebviewPage from '../integrations/Auth0SamlWebview/Auth0SamlWebviewPage';
import BuildingEnginesClassic from '../integrations/BuildingEnginesClassic/Welcome';
import BuildingEnginesPrism from '../integrations/BuildingEnginesPrism/Welcome';
import BuildingEnginesPrismVisitors from '../integrations/BuildingEnginesPrismVisitors/Welcome';
import Essensys from '../integrations/Essensys/Home';
import FloorMaps from '../integrations/FloorMaps/FloorMapsContentRenderer';
import LiveSafe from '../integrations/LiveSafe';
import MobileAccess from '../integrations/MobileAccess';
import ProxyClick from '../integrations/ProxyClick';
import SamlWebviewPage from '../integrations/SamlWebview/SamlWebviewPage';
import SamlWebRedirectPage from '../integrations/SamlWebview/SamlWebRedirectPage';
import OIDCWebviewPage from '../integrations/OIDCWebview/OIDCWebviewPage';
import ServiceRequest from '../integrations/ServiceRequest';
import ShopifyBurstIndex from '../integrations/ShopifyBurst';
import ShopifyMultiPass from '../integrations/ShopifyMultipass/ShopifyMultipassPage';
import ShopifyRsvp from '../integrations/ShopifyRsvp';
import ThreeTenServ from '../integrations/ThreeTenServ';
import ContentV1 from '../renderers/v1';
import BlockRenderer from './BlockRenderer';
import Bilt from '../integrations/Bilt';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  forAdmin: boolean;
  loading?: boolean;
  content?: ContentType;
  editMode?: boolean;
  previewLanguage?: string;
  interaction?: {};
  dataValidation?: {} | null;
  submitAttempted?: boolean;
  onSubmit?: (_: any) => any;
  submissionCompletedAt?: Date;
  onLink?: (_: any) => any;
  onClick?: (e: any) => any;
  onInteractionUpdated?: (_: any) => any;
  preventSubmission?: boolean;
  hideSubmissionBlocks?: boolean;
  isPreview?: boolean;
};

export default function ContentRenderer({
  forAdmin,
  className,
  style,
  loading = false,
  content,
  interaction = {},
  onInteractionUpdated = () => {},
  editMode = false,
  previewLanguage,
  dataValidation = null,
  submitAttempted = false,
  onSubmit = () => {},
  onLink = () => {},
  onClick = () => {},
  preventSubmission = false,
  hideSubmissionBlocks = false,
  submissionCompletedAt,
  isPreview = false,
}: Props) {
  const { blocks } = useContext(RendererContext);
  // (potentially) remove this theming logic here 78/79
  const channelTheme = useChannelTheme(content?.channel); // why we inherited from parents channel
  const theme = useContentTheme(content, channelTheme);
  const { blockContext, updateBlockContext } = useContentRendererBlockContext();
  const isSAMLGraphqlEnabled = useSAMLGraphqlEnabled();

  const { isButtonDisabled, loading: isContentRendererLoading } =
    useContentQuantityState(content, submitAttempted);

  const isLoading = loading || isContentRendererLoading;

  const providerValue = useMemo(
    () => ({
      loading: isLoading,
      disabled: isButtonDisabled,
      content,
      interaction,
      dataValidation,
      submitAttempted,
      onInteractionUpdated,
      onSubmit,
      onLink,
      onClick,
      theme,
      editMode,
      previewLanguage,
      blockContext,
      updateBlockContext,
      preventSubmission,
      hideSubmissionBlocks,
      submissionCompletedAt,
    }),
    [
      isLoading,
      isButtonDisabled,
      content,
      interaction,
      dataValidation,
      submitAttempted,
      onInteractionUpdated,
      onSubmit,
      onLink,
      onClick,
      editMode,
      previewLanguage,
      blockContext,
      updateBlockContext,
      preventSubmission,
      hideSubmissionBlocks,
      submissionCompletedAt,
    ]
  );

  // Are there any integrations attached to this content?
  // If so run that custom logic now.
  if (content?.integration) {
    switch (content.integration.integration.name) {
      case IntegrationProviderEnum.ParkWhiz:
        return <h1>ParkWhiz</h1>;
      case IntegrationProviderEnum.SAML:
        if (isSAMLGraphqlEnabled) {
          return <SamlWebRedirectPage content={content} />;
        }

        return <SamlWebviewPage content={content} />;
      case IntegrationProviderEnum.Auth0SAML:
        return <Auth0SamlWebviewPage content={content} />;
      case IntegrationProviderEnum.OIDC:
        return <OIDCWebviewPage content={content} />;
      case IntegrationProviderEnum.AngusServiceRequests:
        return <AngusServiceRequests content={content} />;
      case IntegrationProviderEnum.ProxyClick:
        return (
          <ProxyClick
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ content: any; className: string | undefine... Remove this comment to see the full error message
            content={content}
            className={className}
            style={style}
            forAdmin={forAdmin}
          />
        );
      case IntegrationProviderEnum.LiveSafe:
        return <LiveSafe />;
      case IntegrationProviderEnum.ServiceRequest:
        return <ServiceRequest content={content} />;
      case IntegrationProviderEnum.ThreeTenServ:
        return <ThreeTenServ content={content} />;
      case IntegrationProviderEnum.Essensys:
        return <Essensys />;
      case IntegrationProviderEnum.Safetrust:
      case IntegrationProviderEnum.HID:
      case IntegrationProviderEnum.Openpath:
      case IntegrationProviderEnum.Kastle:
      case IntegrationProviderEnum.AccessManagement:
      case IntegrationProviderEnum.SaltoSVN:
      case IntegrationProviderEnum.Gallagher:
      case IntegrationProviderEnum.SwiftConnect:
        return <MobileAccess />;
      case IntegrationProviderEnum.FloorMaps:
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'style' is missing in type '{ content: an... Remove this comment to see the full error message
        return <FloorMaps content={content} />;
      case IntegrationProviderEnum.ShopifyBurst:
        return <ShopifyBurstIndex content={content} />;
      case IntegrationProviderEnum.ShopifyRsvp:
        return <ShopifyRsvp content={content} interaction={interaction} />;
      case IntegrationProviderEnum.BuildingEnginesPrism:
        return <BuildingEnginesPrism content={content} />;
      case IntegrationProviderEnum.BuildingEnginesPrismVisitors:
        return <BuildingEnginesPrismVisitors content={content} />;
      case IntegrationProviderEnum.BuildingEnginesClassic:
        return <BuildingEnginesClassic content={content} />;
      case IntegrationProviderEnum.ShopifyMultipass:
        return <ShopifyMultiPass content={content} />;
      case IntegrationProviderEnum.Bilt:
        return <Bilt content={content} />;
      default:
        break;
    }
  }

  if (!content) {
    return null;
  }

  // Other wise, determine which rendering engine to display
  // this content with.
  switch (content?.renderer) {
    case 1:
      return <ContentV1 content={content} />;
    case 5:
      return (
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'scrollRef' is missing in type '{ loading... Remove this comment to see the full error message
        <ContentRendererContext.Provider value={providerValue}>
          <BlockRenderer
            top={content.block}
            isTop
            className={className}
            content={content}
            blocks={blocks}
            isPreview={isPreview}
          />
        </ContentRendererContext.Provider>
      );
    case 0:
    default:
      return (
        <div className={className} style={style}>
          <Loading />
        </div>
      );
  }
}
