import { FeatureNameEnum } from 'constants-content';

import { ServiceRequestFeatureSettingsRenderer } from 'domains/workOrder/feature/components/featureSettings';

import { SurveysFeatureRenderer } from '../../../domains/surveys';
import { VisitorManagementFeatureRenderer } from '../../../domains/visitorManagement/feature';
import EssensysProduct from './EssensysProduct';
import { FeatureRendererPropsType } from './FeatureRendererPropsType';
import GuestInvite from './GuestInvite';
import Menu from './Menu';
import Payment from './Payment';
import QRCodeCheckin from './QRCodeCheckin';
import Quantity from './Quantity';
import RequirementsFeatureRenderer from './RequirementsFeatureRenderer';
import Reservable from './Reservable';
import Scheduled from './Scheduled';
import TimeAvailability from './TimeAvailability';
import UseCompanyPermissions from './UseCompanyPermissions';
import FeatureAutoRenderer from './components/FeatureAutoRenderer';

// to implement a specific UI for any feature, replace the FeatureAutoRenderer
// with a component.
const Cancelable = FeatureAutoRenderer;
const Deliverable = FeatureAutoRenderer;
const Entries = FeatureAutoRenderer;
const Inventory = FeatureAutoRenderer;
const RemoteFetch = FeatureAutoRenderer;
const Reset = FeatureAutoRenderer;
const Shipping = FeatureAutoRenderer;
const SocialOptions = FeatureAutoRenderer;
const Statuses = FeatureAutoRenderer;
const SubmitOnBehalfOf = FeatureAutoRenderer;
const OutlookCalendar = FeatureAutoRenderer;
const GoogleCalendar = FeatureAutoRenderer;
const Requirements = RequirementsFeatureRenderer;

const FeatureRenderers: {
  [Key in `${FeatureNameEnum}`]: (
    props: FeatureRendererPropsType<any, 'manual'>
  ) => JSX.Element;
} = {
  Cancelable,
  Deliverable,
  Entries,
  EssensysProduct,
  GuestInvite,
  Inventory,
  Menu,
  OutlookCalendar,
  GoogleCalendar,
  Payment,
  QRCodeCheckin,
  Quantity,
  RemoteFetch,
  Reservable,
  Reset,
  Scheduled,
  Shipping,
  SocialOptions,
  Statuses,
  SubmitOnBehalfOf,
  TimeAvailability,
  UseCompanyPermissions,
  Requirements,
  VisitorManagement: VisitorManagementFeatureRenderer,
  WorkOrder: ServiceRequestFeatureSettingsRenderer,
  Surveys: SurveysFeatureRenderer,
} as const;

export default FeatureRenderers;
