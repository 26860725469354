import { LAST_SEEN_ONLINE_THRESHOLD } from 'constants-user';
import { parseDate } from '../dates';

export default function isUserOnline(
  user:
    | {
        online?: boolean;
        lastSeen?: Date | string;
      }
    | null
    | undefined
): boolean {
  if (!user) {
    return false;
  }

  if (user.online !== undefined) {
    return user.online;
  }

  if (!user.lastSeen) {
    return false;
  }

  const lastSeen = parseDate(user.lastSeen);

  if (!lastSeen) {
    return false;
  }

  return lastSeen.getTime() + LAST_SEEN_ONLINE_THRESHOLD > Date.now();
}
