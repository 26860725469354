import { EventStatus } from 'lane-shared/domains/visitorManagement/types';
import { DateTime } from 'luxon';
import type { OrganizationSettings } from 'lane-shared/domains/visitorManagement/types/OrganizationSettingsType';
import type { VisitorPassQueryResponse } from 'lane-shared/domains/visitorManagement/types';
import { StatusListItems } from '../../types/EventStatus';
import type { ChipListItem } from 'components/ads';

// VALIDATED //
const allowedStatusesForValidated = [
  { status: EventStatus.EVENT_STATUS_UPCOMING, rules: [isWithinTime] },
  { status: EventStatus.EVENT_STATUS_NO_SHOW, rules: [isWithinTimeOrExpired] },
  { status: EventStatus.EVENT_STATUS_CHECKED_IN, rules: [isWithinTime] },
  { status: EventStatus.EVENT_STATUS_CANCELLED },
  // Needs to include itself for the dropdown
  { status: EventStatus.EVENT_STATUS_VALIDATED },
];

// UPCOMING //
const allowedStatusesForUpcoming = [
  {
    status: EventStatus.EVENT_STATUS_CHECKED_IN,
    rules: [isWithinTime, isVisitorValidationDisabled],
  },
  { status: EventStatus.EVENT_STATUS_NO_SHOW, rules: [isWithinTimeOrExpired] },
  { status: EventStatus.EVENT_STATUS_CANCELLED },
  {
    status: EventStatus.EVENT_STATUS_VALIDATED,
    rules: [isNotExpired, isVisitorValidationEnabled],
  },
  // Needs to include itself for the dropdown
  { status: EventStatus.EVENT_STATUS_UPCOMING },
];

// NO SHOW //
const allowedStatusesForNoShow = [
  { status: EventStatus.EVENT_STATUS_UPCOMING, rules: [isNotExpired] },
  {
    status: EventStatus.EVENT_STATUS_CHECKED_IN,
    rules: [isWithinTime, isVisitorValidationDisabled],
  },
  { status: EventStatus.EVENT_STATUS_CANCELLED },
  // Needs to include itself for the dropdown
  { status: EventStatus.EVENT_STATUS_NO_SHOW },
];

// WITHDRAWN //
const allowedStatusesForWithdrawn = [
  {
    status: EventStatus.EVENT_STATUS_CHECKED_IN,
    rules: [isWithinTime],
  },
  // Needs to include itself for the dropdown
  { status: EventStatus.EVENT_STATUS_WITHDRAWN },
];

const mapVisitorPassStatusToAllowedStatuses = {
  [EventStatus.EVENT_STATUS_VALIDATED]: allowedStatusesForValidated,
  [EventStatus.EVENT_STATUS_UPCOMING]: allowedStatusesForUpcoming,
  [EventStatus.EVENT_STATUS_NO_SHOW]: allowedStatusesForNoShow,
  [EventStatus.EVENT_STATUS_WITHDRAWN]: allowedStatusesForWithdrawn,
};
type AllowedStatuses = keyof typeof mapVisitorPassStatusToAllowedStatuses;

export function getAllowedStatuses(
  visitorPass: VisitorPassQueryResponse,
  organizationSettings: OrganizationSettings
): EventStatus[] | undefined {
  const allowedStatuses =
    mapVisitorPassStatusToAllowedStatuses[
      visitorPass.status as AllowedStatuses
    ];
  if (!allowedStatuses) {
    return undefined;
  }

  const allowedStatusesFiltered = filterAllowedStatuses(
    allowedStatuses,
    visitorPass,
    organizationSettings
  );

  return allowedStatusesFiltered;
}

function filterAllowedStatuses(
  allowedStatuses: any[],
  visitorPass: VisitorPassQueryResponse,
  organizationSettings: OrganizationSettings
): EventStatus[] {
  const validStatuses = allowedStatuses
    .filter(({ rules }) => {
      return (
        !rules ||
        rules.every((rule: Function) => rule(visitorPass, organizationSettings))
      );
    })
    .map(({ status }) => status);

  return validStatuses;
}

// Status Rules Functions //

export function isWithinTime(
  visitorPass: VisitorPassQueryResponse,
  organizationSettings: OrganizationSettings
): boolean {
  const bufferTime = organizationSettings?.bufferTime || 0;
  const { startDatetime, endDatetime } = visitorPass;
  const currentTime = new Date().getTime();

  let start = DateTime.fromISO(
    startDatetime ?? new Date().toISOString()
  ).toMillis();
  const end = DateTime.fromISO(
    endDatetime ?? new Date().toISOString()
  ).toMillis();

  const bufferTimeInMilliseconds = bufferTime * 1000;

  start -= bufferTimeInMilliseconds;

  return start <= currentTime && currentTime < end;
}

export function isExpired(
  visitorPass: VisitorPassQueryResponse,
  _?: any
): boolean {
  const { endDatetime } = visitorPass;
  const currentTime = new Date().getTime();

  const end = DateTime.fromISO(
    endDatetime ?? new Date().toISOString()
  ).toMillis();

  return currentTime > end;
}

export function isWithinTimeOrExpired(
  visitorPass: VisitorPassQueryResponse,
  organizationSettings: OrganizationSettings
): boolean {
  return (
    isWithinTime(visitorPass, organizationSettings) || isExpired(visitorPass)
  );
}

export function isNotExpired(
  visitorPass: VisitorPassQueryResponse,
  _?: any
): boolean {
  return !isExpired(visitorPass);
}

export function isVisitorValidationEnabled(
  _?: any,
  organizationSettings?: OrganizationSettings
): boolean {
  return Boolean(organizationSettings?.visitorValidation);
}

export function isVisitorValidationDisabled(
  _?: any,
  organizationSettings?: OrganizationSettings
): boolean {
  return !isVisitorValidationEnabled(undefined, organizationSettings);
}

// //

export function convertStatusesToChipListItems(
  statuses: EventStatus[],
  t: any
): ChipListItem[] {
  const filteredStatusListItems = StatusListItems.filter(statusListItem =>
    statuses.includes(statusListItem.value as EventStatus)
  ).map(item => ({ ...item, label: t(item.label) }));
  return filteredStatusListItems;
}
