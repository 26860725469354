import { useState, useEffect } from 'react';

import { WatchQueryFetchPolicy, useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { getUserLogins } from '../graphql/user';

import {
  UserLoginProviderEnum,
  UserLoginStatusEnum,
  UserLoginTypeEnum,
} from 'constants-user';

const DEFAULT_POLL_INTERVAL = 30000; // 30s

type UseUserLoginsQueryProps = {
  fetchPolicy?: WatchQueryFetchPolicy;
  pollInterval?: number;
};

type ShortUserLogin = {
  _id: string;
  _created: Date;
  _updated: Date;
  type: UserLoginTypeEnum;
  provider: UserLoginProviderEnum;
  isPrimary: boolean;
  key: string;
  status: UserLoginStatusEnum;
};

type UserLoginsQueryResponse = {
  me: {
    user: {
      _id: string;
      logins: ShortUserLogin[];
    };
  };
};

/**
 * A useful hook for getting current users logins.
 */
export default function useUserLoginsQuery({
  fetchPolicy = 'network-only',
  pollInterval = DEFAULT_POLL_INTERVAL,
}: UseUserLoginsQueryProps = {}) {
  const { data, loading, error, refetch } = useQuery<UserLoginsQueryResponse>(
    getUserLogins,
    {
      client: getClient(),
      fetchPolicy,
      pollInterval,
    }
  );

  const [userLogins, setUserLogins] = useState<ShortUserLogin[]>([]);

  useEffect(() => {
    if (data && data.me && data.me.user) {
      setUserLogins(data.me.user.logins);
    }
  }, [data]);

  return [userLogins, loading, error, refetch] as const;
}
