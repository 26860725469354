import React from 'react';

import { isEmpty } from 'lodash';

import { colors } from 'constants-colors';
import { SpacingType } from 'lane-shared/config/spacing';
import { useLocationNameInReadOnlyView } from 'lane-shared/hooks/useLocationNameInReadOnlyView';
import { ShopifyRsvpContentStateType } from 'lane-shared/types/integrations/ShopifyRsvp';

import { IconButton } from 'components/general';
import {
  ReviewAttendees,
  ReviewDate,
  ReviewDeparture,
  ReviewLocation,
  ReviewName,
  ReviewRoom,
} from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit';
import ReviewRequest from 'components/integrations/ShopifyBurst/pages/burstWizardPages/ReviewRequest';
import { Flex } from 'components/layout';

import BurstAttendeesModal from '../../ShopifyBurst/components/BurstAttendeesModal';
import { useModalContext } from 'contexts/ModalContext';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  mb?: SpacingType;
  burstData: ShopifyRsvpContentStateType;
  rsvpData?: {
    specialRequests: string;
  };
};

export default function RsvpReadOnlyView({
  mb = 9,
  className,
  burstData,
  rsvpData,
  style,
}: Props) {
  const { showModal, closeModal } = useModalContext();

  function openAttendeeModal() {
    showModal(
      <BurstAttendeesModal
        onClose={closeModal}
        attendees={burstData.attendees || []}
      />
    );
  }

  const { address } = useLocationNameInReadOnlyView({ burstData });

  return (
    <Flex direction="column" mb={mb} className={className} style={style}>
      <ReviewName burstName={burstData.name} />

      <ReviewDate
        starts={burstData.dateRange.startDate}
        ends={burstData.dateRange.endDate}
        timeZone={burstData.portTimeZone}
      />

      <ReviewLocation
        primaryRoomBooking={burstData.primaryRoomBooking!}
        port={(burstData.port || { address }) as any}
      />

      <Flex direction="row" align="center">
        <ReviewAttendees
          numberOfAttendees={
            burstData.attendeesCount || burstData.attendees.length
          }
        />
        <IconButton
          iconSet="FontAwesome"
          icon="chevron-right"
          type="fal"
          onClick={openAttendeeModal}
          style={{ color: colors.charcoal[4] }}
        />
      </Flex>

      {burstData.primaryRoomBooking ? (
        <ReviewRoom primaryRoomBooking={burstData.primaryRoomBooking!} />
      ) : null}

      {rsvpData?.specialRequests && (
        <ReviewRequest specialRequest={rsvpData.specialRequests} />
      )}

      {!isEmpty((burstData as any).departingFrom) && (
        <ReviewDeparture
          city={(burstData as any).departingFrom?.city}
          country={(burstData as any).departingFrom?.country}
        />
      )}
    </Flex>
  );
}
