import currencyCodes from 'currency-codes';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { CURRENCY_USD , PaymentTypeEnum } from 'constants-payments';
import Types from '../../../../properties/Types';
import createShapeFromProperties from '../../../../properties/createShapeFromProperties';
import { ObjectTypeBase } from '../../../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../../../types/properties/Property';

// $99,999 is a pretty high amount, it should be high enough?
export const PAYMENT_FEATURE_HIGHEST_AMOUNT = 9999900;

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    get default(): any {
      return uuid();
    },
  },
  amount: {
    type: 'Currency',
    friendlyName: 'Price',
    description: 'The amount (in cents) that will be charged before taxes.',
    default: 0,
    editable: true,
    isArray: false,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Min',
        value: 0,
      },
      {
        name: 'Max',
        value: PAYMENT_FEATURE_HIGHEST_AMOUNT,
      },
    ],
  },
  currency: {
    friendlyName: 'Currency',
    description: 'What currency this will be charged in.',
    type: 'String',
    default: CURRENCY_USD,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: currencyCodes.codes(),
      },
    ],
  },
  type: {
    friendlyName: 'Payment Type',
    description: 'What kind of payment type is this?',
    type: 'String',
    default: PaymentTypeEnum.Cash,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: Object.values(PaymentTypeEnum),
      },
    ],
  },
};

export class PaymentFeaturePaymentAmountType extends ObjectTypeBase {
  name = 'PaymentFeaturePaymentAmount';

  friendlyName = 'Payment Amount';

  contexts = [];

  get example() {
    return {
      _id: uuid(),
      amount: 100,
      currency: CURRENCY_USD,
      type: PaymentTypeEnum.Cash,
    };
  }

  get default(): any {
    return {
      _id: uuid(),
      amount: 100,
      currency: CURRENCY_USD,
      type: PaymentTypeEnum.Cash,
    };
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new PaymentFeaturePaymentAmountType();

Types.addType(definition);
export default definition;
