import React, { useContext, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext, AnalyticsContext } from 'lane-shared/contexts';
import { updateUserLoginsMutation } from 'lane-shared/graphql/user';
import { pause } from 'lane-shared/helpers';
import { emitEmailAddress } from 'lane-shared/helpers/analytics/emitEmailAddress';
import { UserLoginTypeEnum } from 'constants-user';

import Button from '../general/Button';
import Box from '../lds/Box';
import { H5 } from '../typography/index';
import UserLoginEdit from './UserLoginEdit';

import styles from './ProfileLoginSettings.scss';

function ProfileLoginSettings({ onAddLogin }: any) {
  const { user, refetch } = useContext(UserDataContext);
  const analytics = useContext(AnalyticsContext);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  async function deleteLogin(login: any) {
    if (login.isPrimary) {
      window.Alert.alert({
        title: t('Primary email'),
        message: t("You can't delete your primary email."),
      });

      return;
    }

    try {
      await window.Alert.confirm({
        title: t('Delete email?'),
        message: `${t('Are you sure you want to delete login email')} "${
          login.key
        }"?`,
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled.
      return;
    }

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: updateUserLoginsMutation,
        variables: {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          userId: user._id,
          userLogin: {
            _id: login._id,
            _pull: true,
          },
        },
      });
      await refetch();
      emitEmailAddress({
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        userId: user._id,
        analytics,
        selectedLoginId: login._id,
      });
      window.Toast.show(t('Email deleted.'));
    } catch (ex) {
      await window.Alert.alert({
        title: t('Unable to delete email.'),
        message: t(
          "I wasn't able to delete this email, please see the error below and try again."
        ),
        error: ex,
      });
    }

    setLoading(false);
  }

  async function makeLoginPrimary(login: any) {
    try {
      await window.Alert.confirm({
        title: t('Change primary email?'),
        message: `${t(
          'Are you sure you want to change your primary email to'
        )} "${login.key}"?`,
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled.
      return;
    }

    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        mutation: updateUserLoginsMutation,
        variables: {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          userId: user._id,
          userLogin: {
            _id: login._id,
            isPrimary: true,
          },
        },
      });
      await refetch();
      window.Toast.show(t('Primary email set!'));
    } catch (ex) {
      await window.Alert.alert({
        title: t('Unable to set primary email.'),
        message: t(
          "I wasn't able to set this as your primary email, please see the error below and try again."
        ),
        error: ex,
      });
    }

    setLoading(false);
  }

  function renderFooter() {
    if (isEdit) {
      return (
        <>
          <Link
            className={cx(styles.link)}
            to={{}}
            onClick={() => onAddLogin()}
          >
            {t('Add new Login')}
          </Link>

          <hr />

          <Button
            variant="contained"
            loading={loading}
            onClick={() => setIsEdit(false)}
          >
            Done
          </Button>
        </>
      );
    }

    return (
      <Link className={cx(styles.link)} to={{}} onClick={() => setIsEdit(true)}>
        {t('Manage Logins')}
      </Link>
    );
  }

  return (
    <Box className={styles.ProfileLoginSettings}>
      <H5 bold>{t('Login Management')}</H5>
      <div className={styles.form}>
        <div className={styles.label}>{t('Email')}</div>
        {user?.logins
          ?.filter(login => login?.type === UserLoginTypeEnum.Email)
          .map(login => (
            <UserLoginEdit
              key={login._id}
              login={login}
              loading={loading}
              isEdit={isEdit}
              onLoginDelete={deleteLogin}
              onLoginUpdated={makeLoginPrimary}
            />
          ))}
      </div>
      <div className={styles.footer}>{renderFooter()}</div>
    </Box>
  );
}

export default ProfileLoginSettings;
