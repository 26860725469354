import { v4 as uuid } from 'uuid';

import { FeatureNameEnum } from 'constants-content';

export const DEFAULT_WORK_ORDER_FEATURES = [
  {
    type: FeatureNameEnum.Requirements,
    feature: {
      fullNameRequired: true,
      companyNameRequired: true,
      phoneNumberRequired: true,
      verifiedEmailRequired: true,
      profilePictureRequired: false,
      contentInteractionRequired: [],
    },
  },
  {
    type: FeatureNameEnum.SubmitOnBehalfOf,
    feature: {},
  },
  {
    type: FeatureNameEnum.UseCompanyPermissions,
    feature: {},
  },
  {
    type: FeatureNameEnum.Statuses,
    feature: {
      rules: [
        {
          _id: uuid(),
          status: 'Created',
          security: [
            {
              type: 'All',
            },
          ],
          nextStatuses: ['Cancelled', 'Declined', 'Accepted', 'On Hold'],
        },
        {
          _id: uuid(),
          status: 'Accepted',
          security: [
            {
              type: 'All',
            },
          ],
          nextStatuses: ['Cancelled', 'In Progress', 'On Hold'],
        },
        {
          _id: uuid(),
          status: 'In Progress',
          security: [
            {
              type: 'All',
            },
          ],
          nextStatuses: ['Complete', 'On Hold'],
        },
        {
          _id: uuid(),
          status: 'Complete',
          security: [
            {
              type: 'All',
            },
          ],
          nextStatuses: ['Closed'],
        },
        {
          _id: uuid(),
          status: 'Declined',
          security: [
            {
              type: 'All',
            },
          ],
          nextStatuses: ['Accepted'],
        },
        {
          _id: uuid(),
          status: 'Cancelled',
          security: [
            {
              type: 'All',
            },
          ],
          nextStatuses: ['Created'],
        },
        {
          _id: uuid(),
          status: 'On Hold',
          security: [
            {
              type: 'All',
            },
          ],
          nextStatuses: [
            'In Progress',
            'Cancelled',
            'Declined',
            'Accepted',
            'Complete',
            'Closed',
          ],
        },
      ],
    },
  },
];

export const DEFAULT_WORK_ORDER_BLOCK = {
  _id: uuid(),
  blockId: '5304ee1c-3e1a-4c2c-a3e7-791a7b06720b',
  properties: {
    children: [
      {
        _id: uuid(),
        blockId: '3IHP56bNukaLFqlDqyFkFI',
        blockVersion: 0,
        properties: {
          backgroundColor: '#000',
          images: ['7xNDJZ9AS8shEpHKSggfux'],
          size: 'Medium',
          textColor: '#FFFFFF',
          useGradient: true,
        },
      },
      {
        _id: uuid(),
        blockId: '3MySOQd6QCKAv9HiktQe1w',
        blockVersion: 0,
        properties: {
          header: 'Submit a Service Request',
          text: 'Please complete the following form to submit a Service Request to the building Facilities Team.\n\nSubmissions will be processed within 24 business hours. For questions or additional needs, please reach out to the Property Team.',
          textAlign: 'left',
        },
      },
      {
        _id: uuid(),
        children: [
          {
            _id: uuid(),
            primitive: 'PropertyInputBlock',
            properties: {
              featureKey: 'WorkOrder',
              propertyKey: 'ServiceRequest',
              value: {
                _bind: true,
                featureName: 'WorkOrder',
                name: 'ServiceRequest',
                source: 'feature',
              },
            },
            subscribe: {
              primitive: {
                _bind: true,
                featureName: 'WorkOrder',
                name: 'ServiceRequest',
                source: 'feature',
              },
            },
          },
        ],
        editable: true,
        for: 'WorkOrder.ServiceRequest',
        lock: ['Clone'],
        name: 'Service request Input',
        primitive: 'ViewBlock',
        style: {
          paddingBottom: '1em',
          paddingLeft: '2em',
          paddingRight: '2em',
          paddingTop: '1em',
        },
      },
      {
        _id: uuid(),
        lock: ['All'],
        name: 'Required Interactive Block',
        style: {
          gap: '1em',
          display: 'flex',
          alignItems: 'center',
          paddingTop: '2em',
          paddingLeft: '2em',
          paddingRight: '2em',
          flexDirection: 'column',
          paddingBottom: '2em',
        },
        children: [
          {
            _id: uuid(),
            style: {},
            primitive: 'RequirementsBlock',
            subscribe: {
              click: {
                _bind: true,
                publish: 'click',
              },
            },
            properties: {},
          },
          {
            _id: uuid(),
            style: {
              flex: 1,
            },
            primitive: 'ButtonBlock',
            subscribe: {
              submit: {
                _bind: true,
                publish: 'submit',
              },
            },
            properties: {
              text: 'Submit',
              isSubmit: true,
            },
          },
        ],
        revision: 1,
        primitive: 'ViewBlock',
        publishes: {
          click: {
            type: 'Event',
          },
          submit: {
            type: 'Event',
          },
        },
      },
    ],
    fullWidth: false,
    resizeMode: 'Contain',
  },
  blockVersion: 0,
};
