export enum MediaTypeEnum {
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio',
  Document = 'Document',
}

export enum MediaImageContentTypeEnum {
  png = 'image/png',
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
}

export enum MediaAudioContentTypeEnum {
  mp3 = 'audio/mpeg',
  ogg = 'audio/ogg',
  aac = 'audio/aac',
  webm = 'audio/webm',
  wav = 'audio/wav',
  flac = 'audio/flac',
}

export enum MediaDocumentContentTypeEnum {
  json = 'application/json',
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export enum MediaVideoContentTypeEnum {
  mp4 = 'video/mp4',
}

export type MediaContentType =
  | MediaImageContentTypeEnum
  | MediaVideoContentTypeEnum
  | MediaDocumentContentTypeEnum
  | MediaAudioContentTypeEnum;
