import { FRIENDLY_CONTENT_TYPES } from 'constants-content';
import type { UserContentInteractionType } from '../../../types/UserContentInteraction';
import { contentDataFieldCollectables } from './contentDataField.collectable';
import _ from 'lodash';
import { contentFeatureCollectables } from './contentFeature.collectable';

export const interactionCollectables = (
  interaction?: Partial<UserContentInteractionType>
) => {
  if (!interaction) return;

  const content = interaction.contentData;

  return {
    interactionId: interaction._id,
    userId: interaction.userId,
    status: interaction.status,
    startDate: interaction.startDate,
    endDate: interaction.endDate,
    contentId: content?._id,
    contentName: content?.name,
    contentType: content?.type
      ? FRIENDLY_CONTENT_TYPES[content.type]
      : undefined,
    // FIX ME: flatten content features
    contentFeatures: contentFeatureCollectables(content?.features || []),
    // FIX ME: flatten data fields
    dataFields: contentDataFieldCollectables(interaction.data),
    channelId:
      interaction.channelId ||
      content?.channel?._id ||
      (interaction as any).channel,
  };
};
