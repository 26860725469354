import { useEffect, useState } from 'react';

import { useDebounce } from 'use-debounce';

import { useQuery, useLazyQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { queryChannels } from 'lane-shared/graphql/channel';
import { pagination } from 'lane-shared/helpers/constants';
import { convertToUUID } from 'uuid-encoding';

import { adminSearchServiceRequestsQuery } from 'graphql-queries';
import { Channel } from 'packages/lane-shared/types/ChannelType';

const DEBOUNCE_THROTTLE = 500;
const FETCH_POLICY = 'network-only';

export const useSearchAdminServiceRequestsQuery = (
  channelId: string,
  contentId: string,
  perPage: number = pagination.perPage,
  isCrossProperty: boolean = false,
  searchParams: any = {}
) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);

  const [fetchServiceRequests, { data, loading, error, fetchMore, called }] =
    useLazyQuery(adminSearchServiceRequestsQuery, {
      fetchPolicy: FETCH_POLICY,
      client: getClient(),
    });

  const subChannelsResult = useQuery(queryChannels, {
    variables: {
      pagination: {
        start: 0,
        perPage: 100,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        parent: {
          _id: channelId,
        },
      },
    },
  });

  const getSearchVariables = () => ({
    ...(debouncedSearch
      ? {
          search: {
            type: 'like',
            value: search,
          },
        }
      : {}),
  });

  const getChannelVariables = () => {
    const subChannelIds = isCrossProperty
      ? subChannelsResult?.data?.channels?.items?.map((channel: Channel) =>
          convertToUUID(channel._id)
        )
      : [];

    return [convertToUUID(channelId), ...subChannelIds];
  };

  const getQueryVariables = () => ({
    contentId,
    channelIds: getChannelVariables(),
    search: getSearchVariables(),
    pagination: {
      start: (page - 1) * perPage,
      perPage,
    },
    filter: {
      /// @deprecated
      company: searchParams?.company, // Deprecated
      /// @deprecated
      category: searchParams?.category, // Deprecated
      /// @deprecated
      status: searchParams?.status, // Deprecated
      /// @deprecated
      issue: searchParams?.issue, // Deprecated
      /// @deprecated
      equipment: searchParams?.equipment, // Deprecated
      /// @deprecated
      location: searchParams?.location, // Deprecated
      /// @deprecated
      assignee: searchParams?.assignee, // Deprecated
      companies: searchParams?.companies,
      categories: searchParams?.categories,
      statuses: searchParams?.statuses,
      issues: searchParams?.issues,
      equipments: searchParams?.equipments,
      isBillable: searchParams?.isBillable,
      isEscalated: searchParams?.isEscalated,
      locations: searchParams?.locations,
      assignees: searchParams?.assignees,
      assigneeGroups: searchParams?.assigneeGroups,
      createDateStart: searchParams?.createDateStart,
      createDateEnd: searchParams?.createDateEnd,
    },
  });

  useEffect(() => {
    if (called) {
      try {
        fetchMore({
          variables: getQueryVariables(),
          updateQuery: (previousQueryResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousQueryResult;
            }

            return {
              adminSearchServiceRequests: {
                __typename: 'ServiceRequestSearchResults',
                pageInfo: {
                  ...fetchMoreResult.adminSearchServiceRequests.pageInfo,
                  total:
                    previousQueryResult.adminSearchServiceRequests.pageInfo
                      .total,
                },
                serviceRequests: [
                  ...previousQueryResult.adminSearchServiceRequests
                    .serviceRequests,
                  ...fetchMoreResult.adminSearchServiceRequests.serviceRequests,
                ],
              },
            };
          },
        });
      } catch (err) {
        console.error(err);
        // do nothing
      }
    }
  }, [page]);

  useEffect(() => {
    if (channelId && !called) {
      fetchServiceRequests({
        variables: getQueryVariables(),
      });
    }
  }, [channelId]);

  useEffect(() => {
    if (channelId) {
      fetchServiceRequests({
        variables: getQueryVariables(),
      });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (channelId) {
      fetchServiceRequests({
        variables: getQueryVariables(),
      });
    }
  }, [searchParams]);

  const refetch = () => {
    if (!loading && channelId) {
      fetchServiceRequests({
        variables: getQueryVariables(),
      });
    }
  };

  const items = data?.adminSearchServiceRequests?.serviceRequests || [];
  const pageInfo = data?.adminSearchServiceRequests?.pageInfo || {
    total: 0,
    start: 0,
    perPage: 0,
  };

  return {
    items,
    pageInfo,
    loading,
    error,
    page,
    setPage,
    search,
    setSearch,
    refetch,
  };
};
