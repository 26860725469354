import { Channel } from '../../types/ChannelType';
import { UserChannelSettingsType, UserType } from '../../types/User';
import { ChannelDataType } from '../../types/server/WorkflowTypes';
import { applyDynamicTranslation } from './applyDynamicTranslation';
import { undoDynamicTranslation } from './undoDynamicTranslation';

// NOTE: This function should in principle not be used to localize data directly,
// but rather through the useMultiLanguage hook which handles an ARRAY of model items
export const localizeIndividualModelItem = ({
  modelItem,
  columns,
  user,
  debugFunction = (_printable: any) => ({}),
  userChannelSettings,
  channel,
  undoTranslation = false,
  previewLanguage,
}: {
  modelItem: any;
  columns?: string[];
  user?: UserType | null;
  debugFunction?: (printable: string) => void;
  userChannelSettings?: UserChannelSettingsType[] | null;
  channel?:
    | Pick<Channel, 'settings' | '_id'>
    | Pick<ChannelDataType, 'settings' | '_id'>
    | null;
  undoTranslation?: boolean;
  previewLanguage?: string;
}) => {
  if (!modelItem) {
    debugFunction(`No model item found`);

    return modelItem;
  }

  debugFunction(
    `Translating model with ${columns?.[0]} of ${
      modelItem[(columns?.[0] as any) || '']
    } and id of ${modelItem._id || modelItem.id}`
  );

  if (!channel) {
    debugFunction(`Cannot find channel from model`);

    return modelItem;
  }

  debugFunction(`channel settings are ${JSON.stringify(channel.settings)}`);

  if (!channel.settings?.multiLanguageEnabled) {
    debugFunction(
      `Multi language is disabled in channel settings: ${channel.settings?.multiLanguageEnabled}`
    );

    return modelItem;
  }

  const userChannelSetting = userChannelSettings?.find(
    i => i.channelId === channel._id
  );

  debugFunction(
    `Received user channel settings of ${JSON.stringify(userChannelSetting)}`
  );

  if (undoTranslation) {
    return undoDynamicTranslation({
      model: modelItem,
      columns,
      channelSettings: channel.settings,
    });
  }

  const {
    translatedModel,
    translationErrors,
    translationKeyPerColumn,
    translationMethodPerColumn,
  } = applyDynamicTranslation({
    model: modelItem,
    columns,
    channelSettings: channel.settings,
    userSelectedChannelLanguage: userChannelSetting?.language || null,
    userLocale: user?.locale || '',
    previewLanguageKey: previewLanguage,
  });

  if (translationErrors?.length)
    debugFunction(`Translation errors: ${JSON.stringify(translationErrors)}`);

  if (translationKeyPerColumn?.length)
    debugFunction(
      `Translation key per column: ${JSON.stringify(translationKeyPerColumn)}`
    );

  if (translationMethodPerColumn?.length)
    debugFunction(
      `Translation method per column: ${JSON.stringify(
        translationMethodPerColumn
      )}`
    );

  return translatedModel;
};
