import { SupportedLocalesFriendlyNames } from 'localization';
import { Channel } from '../../types/ChannelType';

/**
 * Goes through languages selected for the channel, and returns
 * an array of all languages, with the primary language always being
 * the first element on the array.
 * If the channel doesn't have multiple languages, this will return
 * an array of length of 1.
 */
export const extractChannelLanguagesArray = (
  channel?: Pick<Channel, 'settings'>
) => {
  if (
    !channel?.settings?.multiLanguageEnabled ||
    !channel.settings.channelLanguages
  ) {
    return [
      {
        isPrimary: true,
        languageCode: '',
        friendlyName: '',
      },
    ];
  }

  const nonPrimaryChannelLanguagesArray = Object.values({
    ...channel.settings.channelLanguages,
    primary: null,
  }).filter(Boolean) as string[];
  const mappedNonPrimaryChannelLanguages = nonPrimaryChannelLanguagesArray.map(
    (languageCode: string) => ({
      isPrimary: false,
      languageCode,
      friendlyName: (SupportedLocalesFriendlyNames as any)[languageCode],
    })
  );

  return [
    {
      isPrimary: true,
      languageCode: channel.settings.channelLanguages.primary!,
      friendlyName: (SupportedLocalesFriendlyNames as any)[
        channel.settings.channelLanguages.primary!
      ] as string,
    },
  ].concat(...mappedNonPrimaryChannelLanguages);
};
