/* NOTE: Many resources were used in the construction of this module in order to support a
 * more level experience between providers.
 * 1. https:*betterprogramming.pub/building-secure-login-flow-with-oauth-2-openid-in-react-apps-ce6e8e29630a
 *    Guide describing various strategies in implementing OAuth2 flows
 * 2. https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow
 *    Documentation from microsoft on the usage of their particular endpoints (informed by OIDC standards)
 * 3. https://tonyxu-io.github.io/pkce-generator/
 *    Tool to generate verifier codes and their associated challenges (code challenges have caused me 3+ hours of heartache)
 * 4. https://developer.okta.com/blog/2019/08/22/okta-authjs-pkce
 *    Description by Okta of the OAuth2 flow using PKCE
 */
import { UAParser } from 'ua-parser-js';

import * as Sentry from '@sentry/browser';

import { routes } from 'lane-shared/config';
import {
  OAuthClientSecretError,
  OAuthConfigError,
  ContinuedAuthForbiddenError,
} from 'activate-errors';
import { OAUTH_PROVIDERS, UserLoginProviderEnum } from 'constants-user';
import { OAuthConfigShape } from 'lane-shared/helpers/oAuth';
import { OAuthUser } from 'lane-shared/types/oauth';

import {
  getAccessToken,
  generateRandomID,
  getOpenIdConfiguration,
  OpenIDConfiguration,
  TokenResponse,
} from './helpers';
import { compressData } from './helpers/compressData';

const PERMITTED_CHARACTERS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

export type AuthorizeResult = {
  accessToken: string;
  accessTokenExpirationDate: string;
  authorizeAdditionalParameters?: { [name: string]: string };
  tokenAdditionalParameters?: { [name: string]: string };
  idToken: string;
  refreshToken: string;
  tokenType: string;
  scopes: string[];
  authorizationCode: string;
  codeVerifier?: string;
  user?: OAuthUser;
};

export function createTokenResponse(
  tokenResponse: TokenResponse,
  authorizationCode: string,
  initialScope: string,
  user?: OAuthUser
): AuthorizeResult {
  const {
    access_token: accessToken,
    expires_in,
    id_token: idToken,
    refresh_token: refreshToken,
    scope,
    token_type: tokenType,
  } = tokenResponse;

  const accessTokenExpirationDate = new Date();

  accessTokenExpirationDate.setSeconds(
    accessTokenExpirationDate.getSeconds() + expires_in
  );

  const scopes = scope ?? initialScope;

  return {
    accessToken,
    accessTokenExpirationDate: accessTokenExpirationDate.toISOString(),
    idToken,
    refreshToken,
    scopes: scopes.split(' '),
    tokenType,
    authorizationCode,
    user,
  };
}

function generateCodeVerifier() {
  const codeVerifier: string[] = [];

  for (let i = 0; i < 128; i++) {
    codeVerifier.push(
      PERMITTED_CHARACTERS.charAt(
        Math.floor(Math.random() * PERMITTED_CHARACTERS.length)
      )
    );
  }

  return codeVerifier.join('');
}

type Props = {
  config: OAuthConfigShape;
  getClientSecret?: () => Promise<string>;
  codeVerifier?: string;
};

type RedirectResponse = Partial<{
  code: string;
  secret?: string;
  id_token?: string;
  user?: string;
}>;

export class OAuthService {
  private _config: NonNullable<OAuthConfigShape>;

  private _codeVerifier: string;

  private _openIdConfiguration: OpenIDConfiguration | undefined;

  private _getClientSecret?: () => Promise<string>;

  private _clientSecret?: string;

  private _userAgent: UAParser;

  private _isMobile: boolean;

  constructor({ config, getClientSecret, codeVerifier }: Props) {
    if (!config) {
      throw new OAuthConfigError();
    }

    if (!navigator.userAgent || !window) {
      throw new Error('This operation can only be completed on a web browser.');
    }

    this._config = config;
    this._clientSecret = this._config.clientSecret;

    const hasSecret = getClientSecret || this._clientSecret;

    if (config.provider === OAUTH_PROVIDERS.APPLE && !hasSecret) {
      throw new OAuthClientSecretError();
    }

    this._getClientSecret = getClientSecret;
    this._codeVerifier = codeVerifier ?? generateCodeVerifier();
    this._openIdConfiguration = undefined;
    this._userAgent = new UAParser(navigator.userAgent);
    const uaResult = this._userAgent.getResult();

    this._isMobile = uaResult.device.is('mobile');
  }

  getScopes(): string {
    return this._config.scopes.join(' ');
  }

  async getCodeChallenge(): Promise<string> {
    const hash = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(this._codeVerifier)
    );
    const hashArray = new Uint8Array(hash);

    return btoa(String.fromCharCode(...hashArray))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }

  async loadOpenIdConfiguration() {
    if (this._openIdConfiguration) {
      return;
    }

    const issuer = this._config.issuer;

    this._openIdConfiguration = await getOpenIdConfiguration(issuer);
  }

  async getState(): Promise<string> {
    return compressData({
      ...this._config,
      codeVerifier: this._codeVerifier,
    });
  }

  getProviderParams(): Array<[string, string]> {
    switch (this._config.provider) {
      case OAUTH_PROVIDERS.OKTA:
        return [['prompt', 'login']];
      case OAUTH_PROVIDERS.APPLE:
        return [
          ['grant_type', 'authorization_code'],
          ['response_mode', 'form_post'],
        ];
      default:
        return [['prompt', 'select_account']];
    }
  }

  async makeAuthUrl(codeChallenge: string): Promise<string> {
    await this.loadOpenIdConfiguration();
    const authorizationUrl = this._openIdConfiguration!.authorization_endpoint;
    const providerParams = this.getProviderParams();

    if (this._getClientSecret && !this._clientSecret) {
      this._clientSecret = await this._getClientSecret();
    }

    const state = await this.getState();

    providerParams.push(['state', state]);

    if (this._clientSecret) {
      providerParams.push(['client_secret', this._clientSecret]);
    }

    const requestID = generateRandomID();

    const queryParams = [
      ['client_id', this._config.clientId],
      ['response_type', 'code id_token'],
      ['redirect_uri', this._config.redirectUrl],
      ['scope', this.getScopes()],
      ['nonce', requestID],
      ['code_challenge_method', 'S256'],
      ['code_challenge', codeChallenge],
      ...providerParams,
    ]
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `${authorizationUrl}?${queryParams}`;
  }

  async requestAccessToken(
    code: string,
    secret?: string
  ): Promise<TokenResponse> {
    await this.loadOpenIdConfiguration();
    let tokenUrl = this._openIdConfiguration!.token_endpoint;
    const clientSecret =
      secret ?? this._clientSecret ?? (await this._getClientSecret?.());

    const urlencoded = new URLSearchParams();

    urlencoded.append('client_id', this._config.clientId!);
    urlencoded.append('scope', this.getScopes());
    urlencoded.append('code', code);
    urlencoded.append('redirect_uri', this._config.redirectUrl);
    urlencoded.append('grant_type', 'authorization_code');
    urlencoded.append('code_verifier', this._codeVerifier);
    urlencoded.append('token_url', tokenUrl);

    if (clientSecret) {
      urlencoded.append('client_secret', clientSecret);
    }

    if (
      this._isMobile &&
      this._config.provider === UserLoginProviderEnum.AzureAD
    ) {
      urlencoded.delete('client_secret');
    }

    if (this._config.provider === OAUTH_PROVIDERS.APPLE) {
      urlencoded.delete('code_verifier');
      urlencoded.delete('scope');
      tokenUrl = routes.oauth2AccessToken;
    }

    try {
      const data = await getAccessToken(tokenUrl, urlencoded);

      return data;
    } catch (err) {
      Sentry.captureException(err);

      throw err;
    }
  }

  public async launchNewWindowRedirect(url: any): Promise<Window | null> {
    const win = window.open(url, '_blank');

    if (win) {
      win.opener = window;
    }

    return win;
  }

  parseWindowEventMessage(data: string): any | undefined {
    try {
      return Object.fromEntries(
        Object.entries(JSON.parse(data)).map(([key, value]) => [
          decodeURIComponent(key),
          decodeURIComponent(value as string).replace(/\+/g, ' '),
        ])
      );
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return undefined;
    }
  }

  public awaitWindowResponse(): Promise<RedirectResponse> {
    return new Promise((resolve, reject) => {
      const windowEventHandler = (event: MessageEvent) => {
        const redirectResponse: Partial<{
          type: string;
          code: string;
          user: string;
          error: string;
          error_description: string;
        }> = this.parseWindowEventMessage(event.data);

        if (!redirectResponse || redirectResponse.type !== 'OAUTH_COMPLETE') {
          return;
        }

        try {
          if (redirectResponse.error) {
            throw new Error(
              `${redirectResponse.error}: ${redirectResponse.error_description}`
            );
          }

          resolve(redirectResponse);
        } catch (err) {
          reject(err);
        }

        window.removeEventListener('message', windowEventHandler);
      };

      window.addEventListener('message', windowEventHandler, false);
    });
  }

  public getUserFromAuthorizationRedirectResponse(
    responseUser?: string
  ): OAuthUser | undefined {
    if (responseUser) {
      const { name, email } = JSON.parse(responseUser);

      return { firstName: name.firstName, lastName: name.lastName, email };
    }

    return undefined;
  }

  public findTokenInHash(hash: string): string | null {
    const matchedResult = hash.match(/access_token=([^&]+)/);

    return matchedResult && matchedResult[1];
  }

  async authorizeFromRedirectResponse(redirectResponse: RedirectResponse) {
    // Apple's specific - it returns the user's information on the first signup call only
    const user = this.getUserFromAuthorizationRedirectResponse(
      redirectResponse.user
    );

    const tokenResponse = await this.requestAccessToken(
      redirectResponse.code!,
      redirectResponse.secret
    );

    return createTokenResponse(
      tokenResponse,
      redirectResponse.code!,
      this.getScopes(),
      user
    );
  }

  private async _authorizeWithPopOut(authorizationUrl: string) {
    this.launchNewWindowRedirect(authorizationUrl);

    const authorizationRedirectResponse = await this.awaitWindowResponse();

    return this.authorizeFromRedirectResponse(authorizationRedirectResponse);
  }

  public async authorize(): Promise<AuthorizeResult> {
    await this.loadOpenIdConfiguration();
    const codeChallenge = await this.getCodeChallenge();
    const authorizationUrl = await this.makeAuthUrl(codeChallenge);

    if (this._isMobile) {
      window.location.assign(authorizationUrl);

      throw new ContinuedAuthForbiddenError();
    }

    return this._authorizeWithPopOut(authorizationUrl);
  }

  public async refresh(
    refreshToken: string
  ): Promise<AuthorizeResult | undefined> {
    await this.loadOpenIdConfiguration();
    const tokenUrl = this._openIdConfiguration!.token_endpoint;

    const urlencoded = new URLSearchParams();

    urlencoded.append('client_id', this._config.clientId!);
    urlencoded.append('scope', this.getScopes());
    urlencoded.append('refresh_token', refreshToken);
    urlencoded.append('grant_type', 'refresh_token');

    const tokenResponse = await fetch(tokenUrl!, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
      },
      body: urlencoded,
      redirect: 'follow',
    });

    if (!tokenResponse.ok) {
      return undefined;
    }

    return createTokenResponse(
      await tokenResponse.json(),
      '',
      this.getScopes()
    );
  }
}

export default OAuthService;
