import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { matchPath } from 'react-router';

import {
  CONTENT_LOCATION_LIVE,
  CONTENT_LOCATION_PAGE_CENTER,
  ContentTypeEnum,
} from 'constants-content';
import { linkRouteOptions } from 'lane-shared/types/links/LinkRoute';
import { LinkRouteEnum } from 'lane-shared/types/links/LinkRouteEnum';

import Dropdown from '../../form/Dropdown';
import ChannelSelectorButton from '../../lane/ChannelSelectorButton';
import ContentSelectorButton from '../../lane/ContentSelectorButton';
import SectionSelectorButton from '../../lane/SectionSelectorButton';
import UserSelectorButton from '../../lane/UserSelectorButton';

import styles from './BlockRouteLinkEdit.scss';

type Props = {
  className?: string;
  style?: any;
  channelId?: string;
  channelSlug?: string;
  uri?: string;
  onUriUpdated: Function;
};

const routeOptions = linkRouteOptions.map(option => ({
  label: option.type,
  value: option.type,
  route: option.route,
}));

export default function BlockRouteLinkEdit({
  className,
  style,
  channelId,
  channelSlug,
  uri,
  onUriUpdated,
}: Props) {
  const [type, setType] = useState<LinkRouteEnum | null>(null);
  const [obj, setObj] = useState<any>(null);

  useEffect(() => {
    if (!uri && !type) {
      return;
    }

    for (const option of linkRouteOptions) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      const match = matchPath(uri, {
        path: option.route,
        exact: true,
        strict: false,
      });

      if (match) {
        setType(option.type);

        switch (option.type) {
          case LinkRouteEnum.SectionRoute:
            setObj({ _id: (match.params as any).sectionId });
            break;
          case LinkRouteEnum.LiveContentRoute:
          case LinkRouteEnum.PageRoute:
          case LinkRouteEnum.UserRoute:
          case LinkRouteEnum.InteractionsOnContentRoute:
          case LinkRouteEnum.ChannelRoute:
          case LinkRouteEnum.TodayFeedOnChannelRoute:
          case LinkRouteEnum.SectionsOnChannelRoute:
          case LinkRouteEnum.RetailOnChannelRoute:
          case LinkRouteEnum.OfficeOnChannelRoute:
          case LinkRouteEnum.UserDirectoryOnChannelRoute:
            // all these routes have :id in them
            setObj({ _id: (match.params as any).id });
            break;
          case LinkRouteEnum.SettingsRoute:
          case LinkRouteEnum.WalletRoute:
          case LinkRouteEnum.RecentActivityRoute:
          case LinkRouteEnum.SubscriptionsRoute:
          case LinkRouteEnum.QRCodeRoute:
          case LinkRouteEnum.QRCodeScanner:
          default:
            // all these routes are just routes
            setObj(null);
            break;
        }
      }
    }
  }, [uri]);

  useEffect(() => {
    if (!type) {
      return;
    }

    // build the route as the selection changes.
    const option = routeOptions.find(option => option.label === type);

    switch (type) {
      case LinkRouteEnum.SectionRoute:
        if (!obj) {
          return;
        }

        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        onUriUpdated(option.route.replace(':sectionId', obj._id));
        break;
      case LinkRouteEnum.LiveContentRoute:
      case LinkRouteEnum.PageRoute:
      case LinkRouteEnum.UserRoute:
      case LinkRouteEnum.InteractionsOnContentRoute:
      case LinkRouteEnum.ChannelRoute:
      case LinkRouteEnum.TodayFeedOnChannelRoute:
      case LinkRouteEnum.SectionsOnChannelRoute:
      case LinkRouteEnum.RetailOnChannelRoute:
      case LinkRouteEnum.OfficeOnChannelRoute:
      case LinkRouteEnum.UserDirectoryOnChannelRoute:
        if (!obj) {
          return;
        }

        // all these routes have :id in them
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        onUriUpdated(option.route.replace(':id', obj._id));
        break;
      case LinkRouteEnum.SettingsRoute:
      case LinkRouteEnum.WalletRoute:
      case LinkRouteEnum.RecentActivityRoute:
      case LinkRouteEnum.SubscriptionsRoute:
      case LinkRouteEnum.QRCodeRoute:
      case LinkRouteEnum.QRCodeScanner:
      default:
        // all these routes are just routes
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        onUriUpdated(option.route);
        break;
    }
  }, [type, obj]);

  return (
    <div className={cx(styles.BlockRouteLinkEdit, className)} style={style}>
      <Dropdown
        className={styles.dropDown}
        items={routeOptions}
        placeholder="Select something"
        value={type}
        onValueChange={type => {
          setType(type);
          setObj(null);
        }}
      />
      {type === LinkRouteEnum.SectionRoute && (
        <SectionSelectorButton
          sectionId={obj?._id}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          channelId={channelId}
          channelSlug={channelSlug}
          onSectionSelected={(section: any) => setObj(section)}
        />
      )}
      {type === LinkRouteEnum.LiveContentRoute && (
        <ContentSelectorButton
          channelId={channelId}
          contentId={obj?._id}
          onContentSelected={(content: any) => setObj(content)}
          contentSearchLocations={[CONTENT_LOCATION_LIVE]}
          contentTypes={[
            ContentTypeEnum.Perk,
            ContentTypeEnum.Promotion,
            ContentTypeEnum.Content,
          ]}
        />
      )}
      {type === LinkRouteEnum.PageRoute && (
        <ContentSelectorButton
          channelId={channelId}
          contentId={obj?._id}
          onContentSelected={(content: any) => setObj(content)}
          contentSearchLocations={[CONTENT_LOCATION_PAGE_CENTER]}
          contentTypes={[ContentTypeEnum.Static]}
        />
      )}
      {type === LinkRouteEnum.UserRoute && (
        <UserSelectorButton
          channelId={channelId}
          userId={obj?._id}
          onUserSelected={user => setObj(user)}
        />
      )}
      {[
        LinkRouteEnum.ChannelRoute,
        LinkRouteEnum.RetailOnChannelRoute,
        LinkRouteEnum.OfficeOnChannelRoute,
        LinkRouteEnum.UserDirectoryOnChannelRoute,
        LinkRouteEnum.TodayFeedOnChannelRoute,
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'LinkRouteEnum | null' is not ass... Remove this comment to see the full error message
      ].includes(type) && (
        <ChannelSelectorButton
          channelId={obj?._id || channelId}
          onChannelSelected={(channel: any) => setObj(channel)}
        />
      )}
      {type === LinkRouteEnum.InteractionsOnContentRoute && (
        <ContentSelectorButton
          channelId={channelId}
          contentId={obj?._id}
          onContentSelected={(content: any) => setObj(content)}
          contentSearchLocations={[
            CONTENT_LOCATION_PAGE_CENTER,
            CONTENT_LOCATION_LIVE,
          ]}
          contentTypes={[
            ContentTypeEnum.Static,
            ContentTypeEnum.Perk,
            ContentTypeEnum.Promotion,
            ContentTypeEnum.Content,
          ]}
        />
      )}
    </div>
  );
}
