import { ActiveChannelTypeEnum } from 'constants-channel';
import { CIRCUMFERENCE_OF_EARTH } from 'lane-shared/helpers/constants';
import useLocation from 'lane-shared/hooks/location/useLocation';
import useChannelsSearchQuery from 'lane-shared/hooks/useChannelsSearchQuery';

type Props = {
  search: any;
  skip?: boolean;
  types?: ActiveChannelTypeEnum[];
};

export default function useChannelSearch({ search, skip, types }: Props) {
  const { location } = useLocation();

  const variables: any = {
    pagination: { start: 0, perPage: 25 },
    search: {
      sortBy: { key: 'name', dir: 'asc' },
      isSub: false,
      search: { type: 'like', value: search },
      parent: null,
    },
  };

  if (types) {
    variables.search.type = { any: types };
  }

  // If we have valid location data, add search variable
  if (location.latitude !== 0 && location.longitude !== 0) {
    variables.search.address = {
      geo: {
        latitude: location.latitude,
        longitude: location.longitude,
        distance: CIRCUMFERENCE_OF_EARTH,
      },
    };
  }

  // useChannelsSearchQuery will check to see if variables changed before
  // doing a re-render, so we don't need to ref or set variables in state
  return useChannelsSearchQuery({
    variables,
    skip: !(search?.length > 2) || skip,
  });
}
