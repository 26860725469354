import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';

const ParkWhizDefintion: IntegrationDefinition = {
  name: IntegrationProviderEnum.ParkWhiz,
  hasCustomUI: true,
  friendlyName: 'ParkWhiz',
  description: `Defines the properties required for a ParkWhiz integration.`,
  category: IntegrationCategoryEnum.Parking,
  platforms: [PlatformEnum.Web, PlatformEnum.Mobile],
  type: IntegrationTypeEnum.Api,
  settings: {
    baseUrl: {
      friendlyName: 'Base Url',
      description: 'The base URL of the ParkWhiz API endpoint',
      type: 'Url',
      default: 'https://api.sandbox.parkwhiz.com/v4/',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    clientId: {
      friendlyName: 'Client ID',
      description: 'The Client ID for ParkWhiz',
      type: 'String',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    clientSecret: {
      friendlyName: 'Client Secret',
      description: 'The Client Secret for ParkWhiz',
      type: 'String',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  properties: {},
  interactionData: {
    quote_id: {
      friendlyName: 'Quote ID',
      description: 'The ID of the quote.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    final_price: {
      friendlyName: 'Final Price',
      description: 'The final price of the quote..',
      type: 'Currency',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    plate_number: {
      friendlyName: 'Plate Number',
      description: 'The plate number',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    coupon_code: {
      friendlyName: 'Coupon Code',
      description: 'The coupon code for this parking request',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    startDate: {
      friendlyName: 'Start Date',
      description: 'The start date for this parking ticket.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    endDate: {
      friendlyName: 'End Date',
      description: 'The end date for this parking ticket.',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      secure: {
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
  },
  mobilePermissions: [],
};

export default ParkWhizDefintion;

// todo: add more parking ticket data. See ParkingTicket.js
