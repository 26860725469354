export enum DymoStatus {
  SDK_LOADED = 'SDK_LOADED',
  PERMISSION_MISSING = 'PERMISSION_KEYS.PERMISSION_MISSING',
  SDK_ALREADY_LOADED = 'SDK_ALREADY_LOADED',
  ERROR_LOADING_SDK = 'ERROR_LOADING_SDK',
}

export type PrinterInfo = {
  isConnected: boolean;
  isLocal: boolean;
  modelName: string;
  name: string;
  printerType: string;
};

export type PassCredentialResponse = {
  credential: {
    credential: string;
    credentialId: string;
    uri: string;
  };
};
