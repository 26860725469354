import React from 'react';

import cx from 'classnames';
import {
  useGoogleOAuth,
  useAzureADOAuth,
  useOktaOAuth,
  useAppleOAuth,
} from 'hooks';
import { useTranslation } from 'react-i18next';

import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { SUPPORTED_SSO_PROVIDERS } from 'constants-user';
import LaneOAuthHook from 'lane-shared/types/LaneOAuthHook';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';

import VariantIconButton from 'components/general/variant/VariantIconButton';
import { S } from 'components/typography';

import appleLogo from 'static/img/apple-logo.svg';
import googleLogo from 'static/img/google-logo.svg';
import microsoftLogo from 'static/img/microsoft-logo.svg';
import oktaLogo from 'static/img/okta-logo.png';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import Image from '../general/Image';

type LoginSSOButtonProps = {
  name: string;
  image: string;
  oAuthHook: (...args: any[]) => LaneOAuthHook;
  oAuthConfig: OAuthConfigType;
  onError?: (err: Error | null) => void;
  onSuccess: (signUpData?: any) => void;
};

type Props = {
  className?: string;
  showSeparator?: boolean;
  showTopSeparator?: boolean;
  onError?: (err: Error | null) => void;
  mode?: 'LogIn' | 'SignUp';
  onSuccess: (signUpData?: any) => void;
  oAuthConfig: OAuthConfigType[] | undefined;
};

function LoginSSOButton({
  name,
  image,
  oAuthHook,
  oAuthConfig,
  onError,
  onSuccess,
}: LoginSSOButtonProps) {
  const { t } = useTranslation();
  const { authorize, isEnabled } = oAuthHook({
    oAuthConfig,
    onSuccess,
    onError,
  });

  if (!isEnabled) {
    return null;
  }

  return (
    <VariantIconButton
      key={name}
      onClick={authorize}
      label={t(oAuthConfig.buttonLabel || `Continue with {{name}}`, {
        name,
      })}
      image={
        <Image
          src={image}
          className={welcomeFormStyles.oAuthImage}
          alt={name}
        />
      }
    />
  );
}

export default function LoginSSOButtons({
  className,
  onError,
  onSuccess,
  oAuthConfig,
  showSeparator = true,
}: Props) {
  const { inviteId } = useSignUpContext();
  const buttonInfos = oAuthConfig
    ?.map(cfg => ({ ...cfg, inviteId }))
    .map(cfg => {
      switch (cfg.provider) {
        case SUPPORTED_SSO_PROVIDERS.GOOGLE:
          return {
            name: 'Google',
            image: googleLogo,
            oAuthHook: useGoogleOAuth,
            oAuthConfig: cfg,
          };
        case SUPPORTED_SSO_PROVIDERS.MICROSOFT:
          return {
            name: 'Microsoft',
            image: microsoftLogo,
            oAuthHook: useAzureADOAuth,
            oAuthConfig: cfg,
          };
        case SUPPORTED_SSO_PROVIDERS.OKTA:
          return {
            name: 'Okta',
            image: oktaLogo,
            oAuthHook: useOktaOAuth,
            oAuthConfig: cfg,
          };
        case SUPPORTED_SSO_PROVIDERS.APPLE:
          return {
            name: 'Apple',
            image: appleLogo,
            oAuthHook: useAppleOAuth,
            oAuthConfig: cfg,
          };

        default:
          return {
            name: 'FAILED',
            image: 'FAILED',
            oAuthHook: () => ({
              authorize: () => undefined,
              isEnabled: false,
            }),
            oAuthConfig: cfg,
          };
      }
    });

  if (!buttonInfos || buttonInfos.length === 0) {
    return null;
  }

  return (
    <>
      <div className={cx(welcomeFormStyles.oAuthMenu, className)}>
        {buttonInfos.map(buttonInfo => (
          <LoginSSOButton
            {...buttonInfo}
            key={buttonInfo.oAuthConfig._id || buttonInfo.name}
            onSuccess={onSuccess}
            onError={onError}
          />
        ))}
      </div>
      {showSeparator ? (
        <div className={welcomeFormStyles.separator}>
          <div className={welcomeFormStyles.separatorLine} />
          <S>or</S>
          <div className={welcomeFormStyles.separatorLine} />
        </div>
      ) : undefined}
    </>
  );
}
