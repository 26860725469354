import { UserLoginStatusEnum, UserLoginTypeEnum } from 'constants-user';
import { UserLoginType } from 'lane-shared/types/UserLogin';

export type GetPrimaryLoginType = {
  isPrimary?: boolean;
  key?: string;
  status?: UserLoginStatusEnum;
  type?: UserLoginTypeEnum;
};

export default function getPrimaryLogin<T = UserLoginType>(
  logins: GetPrimaryLoginType[] | null | undefined,
  loginType: UserLoginTypeEnum = UserLoginTypeEnum.Email
): T | null {
  let login;

  if (!logins) {
    return null;
  }

  // Try to find a verified and primary login first.
  login = logins.find(
    login =>
      login.isPrimary &&
      login.status === UserLoginStatusEnum.Verified &&
      login.type === loginType
  );

  if (login) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'GetPrimaryLoginType' is not assignable to ty... Remove this comment to see the full error message
    return login;
  }

  // Otherwise try to find any login that is verified.
  login = logins.find(
    login =>
      login.type === loginType && login.status === UserLoginStatusEnum.Verified
  );

  if (login) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'GetPrimaryLoginType' is not assignable to ty... Remove this comment to see the full error message
    return login;
  }

  // Otherwise just try to find any login at all that is not bounced
  // or complaint status.
  login = logins.find(
    login =>
      login.status &&
      login.type === loginType &&
      ![UserLoginStatusEnum.Complaint, UserLoginStatusEnum.Bounced].includes(
        login.status
      )
  );

  if (login) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'GetPrimaryLoginType' is not assignable to ty... Remove this comment to see the full error message
    return login;
  }

  return null;
}
