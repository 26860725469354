import { useContext, useState } from 'react';

import { ValidationError } from 'yup';

import { LaneType, LaneIntegrationType } from 'common-types';
import {
  INTERACTION_ACCEPTED,
  INTERACTION_DECLINED,
} from 'constants-interactions';

import ChannelsContext from '../../../contexts/ChannelsContext';
import { constructInteraction } from '../../../helpers/content';
import createUserContentInteraction from '../../../helpers/content/createUserContentInteraction';
import { ContentType } from '../../../types/content/Content';
import { IntegrationProviderEnum } from 'constants-integrations';
import updateRSVPdata from './helpers/updateRSVPdata';
import useShopifyRsvpContext from './useShopifyRsvpContext';

type UpdateRsvpInteractionModel = LaneIntegrationType.ShopifyRsvpInteraction & {
  rsvpUserContentInteraction?: LaneType.UserContentInteraction;
};
type CreateRsvpInteractionModel = LaneIntegrationType.ShopifyRsvpInteraction & {
  rsvpContent?: ContentType;
};
export type RsvpModel = CreateRsvpInteractionModel | UpdateRsvpInteractionModel;

export default function useShopifyRsvpInteraction() {
  const { primaryId } = useContext(ChannelsContext);

  const [validation, _setValidation] = useState<ValidationError | null>(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const { rsvpSubmitOnBehalfOfUser } = useShopifyRsvpContext();

  function validate(
    _rsvpInteraction: LaneIntegrationType.ShopifyRsvpInteraction
  ) {
    // todo: do the validation
    return true;
  }

  async function submitRsvpInteraction(rsvpModel: RsvpModel) {
    if ((rsvpModel as UpdateRsvpInteractionModel).rsvpUserContentInteraction) {
      const { rsvpUserContentInteraction, ...rsvpInteractionData } =
        rsvpModel as UpdateRsvpInteractionModel;

      await updateRsvpInteraction(
        rsvpUserContentInteraction,
        rsvpInteractionData
      );
    } else {
      const { rsvpContent, ...rsvpInteraction } =
        rsvpModel as CreateRsvpInteractionModel;

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ContentType | undefined' is not ... Remove this comment to see the full error message
      await createRsvpInteraction(rsvpContent, rsvpInteraction);
    }
  }

  async function createRsvpInteraction(
    rsvpContent: ContentType,
    rsvpInteraction: LaneIntegrationType.ShopifyRsvpInteraction
  ) {
    if (!validate(rsvpInteraction)) {
      return;
    }

    setHasAttemptedSubmit(true);

    const interaction: any = constructInteraction(rsvpContent);

    interaction.data._ShopifyRsvp = rsvpInteraction;

    if (rsvpSubmitOnBehalfOfUser) {
      interaction.user = { _id: rsvpSubmitOnBehalfOfUser._id };
      interaction.features.SubmitOnBehalfOf.user = {
        _id: rsvpSubmitOnBehalfOfUser._id,
      };
    }

    await createUserContentInteraction({
      refetchQueries: ['searchContentInteractions'],
      content: rsvpContent,
      meChannelId: primaryId,
      interaction,
    });
  }

  async function updateRsvpInteraction(
    rsvpInteraction: any,
    rsvpInteractionData: LaneIntegrationType.ShopifyRsvpInteraction
  ) {
    if (!validate(rsvpInteractionData)) {
      return;
    }

    setHasAttemptedSubmit(true);

    await updateRSVPdata({
      interaction: {
        ...rsvpInteraction,
        ...(rsvpSubmitOnBehalfOfUser ? { user: rsvpSubmitOnBehalfOfUser } : {}),
      },
      rsvpInteractionData: {
        [`_${IntegrationProviderEnum.ShopifyRsvp}`]: {
          ...rsvpInteractionData,
        },
      },
      status: rsvpInteractionData.isAttending
        ? INTERACTION_ACCEPTED
        : INTERACTION_DECLINED,
    });
  }

  return {
    validation,
    hasAttemptedSubmit,
    submitRsvpInteraction,
  };
}
