import React, { useMemo } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { DocumentType } from 'lane-shared/types/DocumentType';
import {
  PropertySecurityRule,
  SecurityRuleTypeEnum,
  SECURITY_TYPE_DESCRIPTIONS,
} from 'lane-shared/types/properties/PropertySecurity';

import Dropdown from '../form/Dropdown';
import IconButton from '../general/IconButton';
import ChannelSelectorButton from './ChannelSelectorButton';
import GroupRoleSelector from './GroupRoleSelector';

import styles from './SecurityInput.scss';

const initialSecurityOptions = Object.keys(SecurityRuleTypeEnum)
  .filter(type => SECURITY_TYPE_DESCRIPTIONS[type])
  .map(type => ({
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    label: `${SecurityRuleTypeEnum[type]} - ${SECURITY_TYPE_DESCRIPTIONS[type]}`,
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    value: SecurityRuleTypeEnum[type],
  }));

type Props = {
  channel: DocumentType;
  security: PropertySecurityRule;
  onUpdated: (security: PropertySecurityRule) => void;
  onRemoved?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  allowedOptions?: SecurityRuleTypeEnum[];
  disableChannelSelection?: boolean;
};

export default function SecurityInput({
  channel,
  security,
  onUpdated,
  onRemoved,
  disabled,
  style,
  className,
  allowedOptions,
  disableChannelSelection,
}: Props) {
  const type = security?.type || null;
  const value = (security?.value as any)?._id || security?.value || null;

  function updateSecurity(update: Partial<PropertySecurityRule>) {
    onUpdated({
      ...security,
      ...update,
    });
  }

  const securityOptions = useMemo(() => {
    if (!allowedOptions || !allowedOptions.length) {
      return initialSecurityOptions;
    }

    return initialSecurityOptions.filter(opt =>
      allowedOptions.includes(opt.value)
    );
  }, [allowedOptions]);

  return (
    <div className={cx(styles.SecurityInput, className)} style={style}>
      <Dropdown
        disabled={disabled}
        value={type}
        placeholder="Security Type"
        onValueChange={type =>
          updateSecurity({
            type,
            value: undefined,
          })
        }
        items={securityOptions}
      />

      {type === SecurityRuleTypeEnum.Channel && (
        <div className={styles.row}>
          <Icon name="level-down" className={styles.levelDownIcon} />
          <ChannelSelectorButton
            disabled={disabled}
            className={styles.channelSelector}
            channelId={value}
            onChannelSelected={(channel: any) =>
              updateSecurity({ value: channel?._id })
            }
          />
        </div>
      )}

      {type === SecurityRuleTypeEnum.GroupRole && (
        <div className={styles.row}>
          <Icon name="level-down" className={styles.levelDownIcon} />
          <GroupRoleSelector
            disableChannelSelection={disableChannelSelection}
            disabled={disabled}
            className={styles.groupRoleSelector}
            channelId={channel?._id}
            groupRoleId={value}
            onGroupRoleSelected={groupRole =>
              updateSecurity({ value: groupRole?._id })
            }
          />
        </div>
      )}

      {onRemoved && (
        <IconButton
          icon="times"
          disabled={disabled}
          className={styles.removeButton}
          onClick={onRemoved}
        />
      )}
    </div>
  );
}
