import React from 'react';

import { FeatureInstance } from '../../types/FeatureInstance';
import { FeatureNameEnum } from 'constants-content';
import { IntegrationProviderEnum } from 'constants-integrations';
import { PropertyType } from '../../types/properties/Property';

export const sortOrders = ['asc', 'desc'] as const;
export const [SORT_ASC, SORT_DESC] = sortOrders;

export const sorts = [
  '_created',
  '_updated',
  'status',
  'user',
  'email',
  'startDate',
  'endDate',
] as const;

export const [
  SORT_CREATED,
  SORT_UPDATED,
  SORT_STATUS,
  SORT_USER,
  SORT_EMAIL,
  SORT_START,
  SORT_END,
] = sorts;

export type ColumnFormatterType = {
  /** custom UI/UX output for a UCI row for a feature or integration */
  formatter?: (data: any, property?: PropertyColumnType) => React.ReactNode;
  /** custom text (i.e. CSV) output for a UCI row for a feature or integration */
  textFormatter?: (data: any, property?: PropertyColumnType) => string;
};

export type StandardColumnType = ColumnFormatterType & {
  label: string;
  sort: string;
  disableSort?: boolean;
};

export type IntegrationColumnsType = {
  integrationProvider: IntegrationProviderEnum | null;
  integrationColumns: [string, PropertyColumnType][];
};

export type ContentFeatureColumnsType = {
  feature: FeatureInstance;
  columns: PropertyColumnType[];
  type: FeatureNameEnum;
  friendlyName: string;
};

export type PropertyColumnType = PropertyType & ColumnFormatterType;
