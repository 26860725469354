import React from 'react';
import cx from 'classnames';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { PresetContentFilter, PresetContentSort } from 'constants-content';
import Alert from '../general/Alert';
import { useTranslation } from 'react-i18next';

/**
 * Shows the status of whether this content piece can support the given
 * filters and meta tag filters.
 */
function ContentFilterStatus({
  className,
  style,
  content,
  filters,
  sorts,
  metatags,
}: any) {
  const {
    paymentFeature,
    timeAvailabilityFeature,
    reservableFeature,
    quantityFeature,
  } = explodeFeatures(content.features);

  const alerts = [];
  const { t } = useTranslation();

  filters.forEach((filter: any) => {
    switch (filter) {
      case PresetContentFilter.AvailableNow:
        if (!timeAvailabilityFeature || !reservableFeature) {
          alerts.push(
            <Alert color="warning" key="availableNowWarning">
              {t(
                'web.admin.channel.content.layout.editor.components.contentFilterStatus.warning.availableNow'
              )}
            </Alert>
          );
        }

        break;
      case PresetContentFilter.FeaturePaymentPrice:
        if (!paymentFeature) {
          alerts.push(
            <Alert color="warning" key="paymentWarning">
              {t(
                'web.admin.channel.content.layout.editor.components.contentFilterStatus.warning.payment'
              )}
            </Alert>
          );
        }

        break;
      case PresetContentFilter.FeatureQuantityRemaining:
        if (!quantityFeature) {
          alerts.push(
            <Alert color="warning" key="quantityWarning">
              {t(
                'web.admin.channel.content.layout.editor.components.contentFilterStatus.warning.quantity'
              )}
            </Alert>
          );
        }

        break;
      case PresetContentFilter.FeatureReservableAvailableDays:
      case PresetContentFilter.FeatureReservableAvailableMonths:
      case PresetContentFilter.FeatureReservableAvailableMinutes:
      case PresetContentFilter.FeatureReservableAvailableWeeks:
        if (!reservableFeature) {
          alerts.push(
            <Alert color="warning" key="reservableWarning">
              {t(
                'web.admin.channel.content.layout.editor.components.contentFilterStatus.warning.reservable'
              )}
            </Alert>
          );
        }

        break;
      case PresetContentFilter.ByEventDate:
        if (!content.startDate || !content.endDate) {
          alerts.push(
            <Alert color="warning" key="eventDateWarning">
              {t(
                'web.admin.channel.content.layout.editor.components.contentFilterStatus.warning.eventDate'
              )}
            </Alert>
          );
        }

        break;
    }
  });

  if (sorts.includes(PresetContentSort.FeatureQuantityRemaining)) {
    alerts.push(
      <Alert color="warning" key="quantityWarning">
        {t(
          'web.admin.channel.content.layout.editor.components.contentFilterStatus.warning.quantitySort'
        )}
      </Alert>
    );
  }

  if (sorts.includes(PresetContentSort.FeaturePaymentPrice)) {
    alerts.push(
      <Alert color="warning" key="priceSortWarning">
        {t(
          'web.admin.channel.content.layout.editor.components.contentFilterStatus.warning.priceSort'
        )}
      </Alert>
    );
  }

  metatags?.forEach((metatag: any, index: number) => {
    if (
      !content.contentMetatags?.some(
        (contentMetatag: any) => contentMetatag.metatag._id === metatag._id
      )
    ) {
      alerts.push(
        <Alert color="warning" key={`metaTagWarning${index}`}>
          {t(
            'web.admin.channel.content.layout.editor.components.contentFilterStatus.warning.metatag',
            { name: metatag.name }
          )}
        </Alert>
      );
    }
  });

  return (
    <div className={cx(className)} style={style}>
      {alerts}
    </div>
  );
}

export default ContentFilterStatus;
