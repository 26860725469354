import React, { useState } from 'react';

import { Icon, Table, NativeFilterTypes } from 'design-system-web';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useQuery } from '@apollo/client';
import { routes } from 'lane-shared/config';
import {
  ActivateVisitor,
  EventStatus,
  VisitorPassQueryResponse,
} from 'lane-shared/domains/visitorManagement/types';
import { getScheduledVisits as getScheduledVisitsQuery } from 'lane-shared/graphql/visitorManagement';
import { getTimeZoneByGeoLocation, hasPermission } from 'lane-shared/helpers';
import {
  SHORT_TIME_WITH_TZ,
  SIMPLE_DATE,
} from 'lane-shared/helpers/constants/dates';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { convertTo62, convertToUUID, convertIdToUUID } from 'uuid-encoding';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { useDymoPrinter, useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';

import {
  VisitorPassStatusSelect,
  VisitorLogTableFilters,
} from '../../../../components';
import { useAfterRefresh } from '../../../../hooks/useAfterRefresh';
import { useStatusSelector } from '../../../../hooks/useStatusSelector';
import {
  StatusListItems,
  StatusKeyToLabelForExport,
} from '../../../../types/EventStatus';
import type { StatusListItem } from '../../../../types/EventStatus';
import history from 'helpers/history';

import styles from './styles.scss';
import { ColumnFiltersState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { exportCSV } from '../../../../helpers/exportCSV';
import { Tooltip } from 'components';
import useThemeColors from 'lane-shared/hooks/useThemeColors';
import { PERMISSION_KEYS } from 'constants-permissions';
import { UserType } from 'lane-shared/types/User';
import { useOrganizationSettingsData } from 'lane-shared/domains/visitorManagement/hooks/useOrganizationSettingsData';
import { PrinterSelectionModal } from '../../modals/printer-selection/PrinterSelectionModal';
import { getScheduledVisits } from './helpers/getScheduledVisits';
import { useDownloadQRCode } from 'hooks/visitorManagement/useDownloadQRCode';
import { useVisitorValidationEnabled } from 'react-hooks';
import { InternalError } from 'activate-errors';
import { Channel } from 'packages/lane-shared/types/ChannelType';

const TRANSLATION_KEYS = {
  recurrenceTooltip:
    'web.admin.channel.visitor.log.scheduled.recurrence.tooltip',
};

export type ScheduledVisitsResponse = {
  scheduledVisits: {
    result: {
      visits: VisitorPassQueryResponse[];
      eventNames: string[];
      tenantNames: string[];
    };
    totalCount: number;
  };
};

type ColumnVisibilityState = Record<string, boolean>;

export type VisitorRow = Pick<
  ActivateVisitor,
  'firstName' | 'lastName' | 'company' | 'email'
>;

export type VisitorPassRow = Pick<
  VisitorPassQueryResponse,
  | 'companyName'
  | 'createdAtDatetime'
  | 'endDatetime'
  | 'eventName'
  | 'hostName'
  | 'tenantName'
  | 'floor'
  | 'id'
  | 'staffNote'
  | 'sourceId'
  | 'startDatetime'
  | 'status'
  | 'submissionId'
  | 'submittedBy'
> & { visitor: VisitorRow };

const unavailableFilters = [
  EventStatus.EVENT_STATUS_PENDING.toString(),
  EventStatus.EVENT_STATUS_EXPIRED.toString(),
];

const statusListItems = StatusListItems.filter(
  (statusListItem: StatusListItem) =>
    !unavailableFilters.includes(statusListItem.value)
);
let searchKeyValue: string = '';
export function VisitorScheduledComponent({
  user,
  channel,
}: {
  user: UserType;
  channel: Channel;
}) {
  const { t } = useTranslation();
  const [, channelSlug] = useIsAdminView();
  const { handleRefresh } = useAfterRefresh();

  const timeZone = getTimeZoneByGeoLocation({
    latitude: channel?.address?.geo[1],
    longitude: channel?.address?.geo[0],
  });
  const currentTime = DateTime.fromJSDate(new Date(), {
    zone: timeZone,
  }).startOf('day');
  const [startDate, setStartDate] = useState<Date>(currentTime.toJSDate());
  const [isSelectPrintersModalOpen, setIsSelectPrintersModalOpen] =
    useState(false);
  const [selectedVisitorPass, setSelectedVisitorPass] =
    useState<VisitorPassQueryResponse | null>(null);

  const { downloadQRCode } = useDownloadQRCode();
  const isVisitorValidationEnabled = useVisitorValidationEnabled();

  const oneYearFromNow = currentTime
    .plus({
      year: 1,
    })
    .startOf('day')
    .toJSDate();
  const endOfDaySelectedFilterDate = DateTime.fromJSDate(startDate, {
    zone: timeZone,
  })
    .endOf('day')
    .toJSDate()
    .toISOString();

  const { data, loading, refetch } = useQuery<ScheduledVisitsResponse>(
    getScheduledVisitsQuery,
    {
      variables: {
        channelId: channel._id,
        startDate: startDate.toISOString(),
        endDate: endOfDaySelectedFilterDate,
      },
      // Fetch from network first time and store in cache
      fetchPolicy: 'network-only',
      /**
       * Fetch from cache first subsequently.
       * Calling refetch uses the initial fetchPolicy: "network-only".
       */
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        handleRefresh(data.scheduledVisits.result.visits.map(p => p.id));
      },
    }
  );

  const { organizationSettings } = useOrganizationSettingsData(
    convertToUUID(channel?._id)
  );

  const { updateStatus, loadingStates } = useStatusSelector();
  const refresh = useDebouncedCallback(() => refetch(), 500).callback;
  const visitorNotesEnabled = useFlag(
    FeatureFlag.VisitorManagementVisitorNotes,
    false
  );

  const hasAnyPermission = (
    user: UserType,
    permissions: string[],
    channelId: string
  ) => {
    return (
      user.isSuperUser ||
      (channelId && hasPermission(user.roles, permissions, channelId))
    );
  };

  const canPrintVisitorPass = hasPermission(
    user.roles,
    [PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_PRINT_VISITOR_PASS],
    channel?._id,
    false
  );

  const canDownloadQRCode = hasAnyPermission(
    user,
    [PERMISSION_KEYS.PERMISSION_VISITOR_MANAGEMENT_LOGS_DOWNLOAD_QR_CODE],
    channel?._id
  );

  const { printPass, connectedPrinters, getPrinters } = useDymoPrinter(
    channel,
    canPrintVisitorPass
  );

  const [activeFilters, setActiveFilters] = useState<ColumnFiltersState>([
    {
      id: 'startDatetime',
      value: startDate,
    },
  ]);

  const [visibleColumns, setVisibleColumns] = useState<ColumnVisibilityState>();

  const handleDateFilterChange = (date: Date) => {
    const newStartDate = DateTime.fromJSDate(date, { zone: timeZone })
      .startOf('day')
      .toJSDate();

    setStartDate(newStartDate);
  };

  const handleUpdateStatus = async (
    status: string,
    pass: VisitorPassQueryResponse
  ): Promise<void> => {
    try {
      await updateStatus(status, pass);
    } catch (err) {
      const error = err as Error;

      window.Toast.show(error.message, 5000);
    }
  };

  const openPrinterSelectionModal = (row: VisitorPassQueryResponse) => {
    setSelectedVisitorPass(row);
    setIsSelectPrintersModalOpen(true);
  };

  const handlePrintPass = (row: VisitorPassQueryResponse) => {
    const printers = getPrinters();

    if (!printers.length) {
      return alert(t('web.admin.channel.visitor.log.printVisitorPass.error'));
    }

    if (printers.length === 1) {
      return printPass(row);
    }

    openPrinterSelectionModal(row);
  };

  const handleDownloadQRCode = async (row: VisitorPassQueryResponse) => {
    window.Alert.loading({
      title: `${t('web.admin.channel.visitor.log.rowActions.downloadQRCode.preparingYourFile')}`,
      disableCloseOnBackgroundClick: true,
    });

    try {
      const visitorPassUUID = convertIdToUUID(row?.id);
      await downloadQRCode(visitorPassUUID);
    } catch (err) {
      if (err instanceof InternalError) {
        window.Toast.show(
          t(
            'web.admin.channel.visitor.log.rowActions.downloadQRCode.internalError'
          ),
          5000
        );
      } else {
        window.Toast.show(
          t('web.admin.channel.visitor.log.rowActions.downloadQRCode.error'),
          5000
        );
      }
    }

    setTimeout(() => window.Alert.hide(), 500);
  };

  const getFullName = (cell: ActivateVisitor) => {
    return `${cell.firstName} ${cell.lastName}`;
  };

  const mandatoryKeys = new Set(['startDate', 'startDatetime', 'endDatetime']);

  const getTenantFloor = (tenantName: string, floor?: string) => {
    return floor ? `${tenantName} - ${floor}` : tenantName;
  };

  const showVisitorExtraInfo = useFlag(
    FeatureFlag.VisitorManagementVisitorLogInfo,
    false
  );

  const visitorColumns = [
    {
      header: t('web.admin.channel.visitor.log.columns.visitor'),
      key: 'visitor',
      renderCell: (cell: ActivateVisitor) => (
        <span className={styles.visitorName}>{getFullName(cell)}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        `${visitorPass.visitor.firstName} ${visitorPass.visitor.lastName}`,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.visitorCompany'),
      key: 'visitor_company',
      renderCell: (_: any, row: VisitorPassQueryResponse) => (
        <span className={styles.visitorName}>{row.visitor.company}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.visitor.company,
    },
  ];

  if (showVisitorExtraInfo) {
    visitorColumns.push({
      header: t('web.admin.channel.visitor.log.columns.visitorEmail'),
      key: 'visitor_email',
      renderCell: (_: any, row: VisitorPassQueryResponse) => (
        <span className={styles.visitorName}>{row.visitor.email}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.visitor.email,
    });

    visitorColumns.push({
      header: t('web.admin.channel.visitor.log.columns.visitorPhoneNumber'),
      key: 'visitor_phone',
      renderCell: (_: any, row: VisitorPassQueryResponse) => (
        <span className={styles.visitorName}>{row.visitor.phone}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.visitor.phone,
    });
  }

  let columns = [
    {
      header: t('web.admin.channel.visitor.log.columns.status'),
      key: 'status',
      renderCell: (_: any, row: VisitorPassQueryResponse) => (
        <VisitorPassStatusSelect
          key={row.id}
          visitorPass={row}
          loading={loadingStates[row.id]}
          updateStatus={handleUpdateStatus}
          organizationSettings={organizationSettings}
        />
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        t(StatusKeyToLabelForExport[visitorPass.status]),
    },
    {
      header: t('web.admin.channel.visitor.log.columns.date'),
      key: 'startDate',
      renderCell: (_: any, row: VisitorPassQueryResponse) => (
        <span>{dateFormatter(row.startDatetime, SIMPLE_DATE, timeZone)}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.startDatetime,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.expectedArrival'),
      key: 'startDatetime',
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, SHORT_TIME_WITH_TZ, timeZone)}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.startDatetime,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.expectedDeparture'),
      key: 'endDatetime',
      renderCell: (cell: string, row: VisitorPassQueryResponse) => (
        <span className={styles.expectedDeparture}>
          {dateFormatter(cell, SHORT_TIME_WITH_TZ, timeZone)}
          <IconSet recurrenceId={row.recurrenceId} />
        </span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.endDatetime,
    },
    ...visitorColumns,
    {
      header: t('web.admin.channel.visitor.log.columns.noteToStaff'),
      key: 'staffNote',
      renderCell: (_: string, row: VisitorPassQueryResponse) => (
        <span className={styles.staffNote}>{row?.staffNote}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.visitorNote,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.host'),
      key: 'hostName',
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.hostName,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.hostCompany'),
      key: 'tenantName',
      renderCell: (cell: string, row: VisitorPassQueryResponse) => (
        <span>{getTenantFloor(cell, row?.floor)}</span>
      ),
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.tenantName,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.submittedBy'),
      key: 'submittedBy',
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.submittedBy,
    },
    {
      header: t('web.admin.channel.visitor.log.columns.registeredFrom'),
      key: 'eventName',
      renderForCSV: (_: any, visitorPass: VisitorPassQueryResponse) =>
        visitorPass.eventName,
    },
  ];

  if (!visitorNotesEnabled) {
    columns = columns.filter(column => column.key !== 'staffNote');
  }

  const rowActions = [
    {
      label: t(
        'web.admin.channel.visitor.log.rowActions.viewVisitorPassDetails'
      ),
      onClick: (row: VisitorPassQueryResponse) => {
        const base62VisitorPassId = convertTo62(row?.id);
        const url = routes.visitorManagementVisitorPassDetails
          .replace(':id', channelSlug || '')
          .replace(':visitorPassId', base62VisitorPassId);

        history.push(url);
      },
    },
    {
      label: t(
        'web.admin.channel.visitor.log.rowActions.viewSubmissionReceipt'
      ),
      onClick: (row: VisitorPassQueryResponse) => {
        const base62SubmissionId = convertTo62(row?.submissionId);
        const url = routes.channelAdminInteraction
          .replace(':id', channelSlug || '')
          .replace(':interactionId', base62SubmissionId);

        history.push(url);
      },
    },
    ...(canPrintVisitorPass
      ? [
          {
            label: t(
              'web.admin.channel.visitor.log.rowActions.printVisitorPass'
            ),
            isDisabled: (row: VisitorPassQueryResponse) =>
              row?.status === EventStatus.EVENT_STATUS_PENDING,
            onClick: (row: VisitorPassQueryResponse) => handlePrintPass(row),
          },
        ]
      : []),
    ...(canDownloadQRCode && isVisitorValidationEnabled
      ? [
          {
            label: t('web.admin.channel.visitor.log.rowActions.downloadQRCode'),
            isDisabled: (row: VisitorPassQueryResponse) =>
              row?.status === EventStatus.EVENT_STATUS_PENDING,
            onClick: (row: VisitorPassQueryResponse) =>
              handleDownloadQRCode(row),
          },
        ]
      : []),
  ];

  const onFilterChange = (filters: ColumnFiltersState) => {
    setActiveFilters(filters);
    searchKeyValue = '';

    for (const filter of filters) {
      if (filter.id === 'startDatetime') {
        handleDateFilterChange(filter.value as Date);
      }
    }
  };

  const onColumnVisibilityChange = (
    columnVisibility: ColumnVisibilityState
  ) => {
    setVisibleColumns(columnVisibility);
  };

  const filterVisitorPasses = (
    visitorPasses: VisitorPassQueryResponse[],
    filters: ColumnFiltersState
  ) => {
    return visitorPasses.filter((visitorPass: VisitorPassQueryResponse) => {
      return filters.every(filter => {
        const { id, value } = filter;
        const key = id as keyof VisitorPassQueryResponse;

        if (id in visitorPass) {
          if (Array.isArray(value)) {
            return value.includes(visitorPass[key]);
          }

          if (value instanceof Date) {
            const visitorPassDate = dateFormatter(
              visitorPass.startDatetime,
              SIMPLE_DATE,
              timeZone
            );

            return (
              new Date(visitorPassDate).toDateString() ===
              (value as Date).toDateString()
            );
          }

          return visitorPass[key] === value;
        }

        return false;
      });
    });
  };

  const filterData = (data: VisitorPassRow[], searchKey: string) => {
    return data.filter(row => {
      return keywordFilter(row, searchKey);
    });
  };

  const exportCurrentPageAsCSV = () => {
    let filteredDataWithoutsearch: VisitorPassQueryResponse[] = [];

    if (
      data &&
      data.scheduledVisits &&
      data.scheduledVisits.result &&
      data.scheduledVisits.result.visits
    ) {
      filteredDataWithoutsearch = filterVisitorPasses(
        data.scheduledVisits.result.visits,
        activeFilters
      );
    }

    const filteredData = filterData(filteredDataWithoutsearch, searchKeyValue);

    const filteredColumn = visibleColumns
      ? columns.filter(
          item => visibleColumns[item.key] || mandatoryKeys.has(item.key)
        )
      : columns;

    exportCSV(
      filteredData,
      filteredColumn,
      `scheduled-visitor-current-page-logs-${Date.now()}.csv`,
      channel
    );
  };

  const exportAllAsCSV = async () => {
    try {
      const scheduledVisitsData = await getScheduledVisits(
        startDate,
        oneYearFromNow,
        channel._id
      );
      exportCSV(
        scheduledVisitsData,
        columns,
        `scheduled-visitor-all-logs-${Date.now()}.csv`,
        channel
      );
    } catch (err) {
      console.error(err);
      window.Toast.show(err.message, 5000);
    }
  };

  const exportOptions = [
    {
      label: t('web.admin.visitorManagement.exportSelection'),
      onClick: exportCurrentPageAsCSV,
    },
    {
      label: t('web.admin.visitorManagement.exportAll'),
      onClick: exportAllAsCSV,
    },
  ];

  return (
    <>
      <Table
        isLoading={loading}
        columns={columns}
        data={data?.scheduledVisits?.result?.visits || []}
        pagination="client"
        hasKeywordFilter
        customKeywordFilterFn={keywordFilter}
        onColumnVisibilityChange={onColumnVisibilityChange}
        rowActions={rowActions}
        onFilterChange={onFilterChange}
        onRefreshClick={refresh}
        filters={VisitorLogTableFilters({
          dateFilter: NativeFilterTypes.Date,
          statusListItems,
          hostName: data?.scheduledVisits?.result?.tenantNames || [],
          registeredFrom: data?.scheduledVisits?.result?.eventNames || [],
          minDate: currentTime.toJSDate(),
          maxDate: oneYearFromNow,
          timeZone,
          t,
        })}
        activeFilters={activeFilters}
        exportOptions={exportOptions}
        showColumnVisibility
      />

      {isSelectPrintersModalOpen && selectedVisitorPass && (
        <PrinterSelectionModal
          isOpen={isSelectPrintersModalOpen}
          onClose={() => {
            setIsSelectPrintersModalOpen(false);
            setSelectedVisitorPass(null);
          }}
          connectedPrinters={connectedPrinters}
          visitorPass={selectedVisitorPass}
          printPass={printPass}
        />
      )}
    </>
  );
}

function IconSet({ recurrenceId }: { recurrenceId: string | undefined }) {
  const { t } = useTranslation();
  const theme = useThemeColors();

  return (
    <span className={styles.iconSet}>
      {recurrenceId ? (
        <Tooltip
          placement="bottom"
          TooltipComponent={t(TRANSLATION_KEYS.recurrenceTooltip)}
        >
          <Icon
            name="repeat"
            set={ICON_SET_FONTAWESOME}
            type="far"
            style={{ color: theme.secondary, cursor: 'pointer' }}
          />
        </Tooltip>
      ) : (
        <Icon name="repeat" set={ICON_SET_FONTAWESOME} type="far" />
      )}
      <Icon name="file-alt" set={ICON_SET_FONTAWESOME} type="far" />
      <Icon name="exclamation-circle" set={ICON_SET_FONTAWESOME} type="far" />
    </span>
  );
}

export function keywordFilter(row: VisitorPassRow, keyword: string): boolean {
  const searchableString = [
    row.visitor.firstName,
    row.visitor.lastName,
    row.visitor.company,
    row.visitor.email,
    row.companyName,
    row.submittedBy,
    row.eventName,
    row.hostName,
    row.tenantName,
    row.floor,
    row.staffNote,
  ]
    .join(' ')
    .toLowerCase();

  searchKeyValue = keyword;

  return searchableString.includes(keyword.toLowerCase());
}
