import { colors } from 'constants-colors';

export default function createGradient(
  startColor: string = colors.laneGreen,
  endColor = '#528fff'
) {
  const svgns = 'http://www.w3.org/2000/svg';

  const gradientElement = document.createElementNS(svgns, 'linearGradient');
  const stopElement1 = document.createElementNS(svgns, 'stop');
  const stopElement2 = document.createElementNS(svgns, 'stop');

  gradientElement.setAttribute('id', 'gradient');
  gradientElement.setAttribute('x1', '0%');
  gradientElement.setAttribute('y1', '0%');
  gradientElement.setAttribute('x2', '100%');
  gradientElement.setAttribute('y2', '0%');
  stopElement1.setAttribute('offset', '0%');
  stopElement1.setAttribute('stop-color', startColor);
  stopElement2.setAttribute('offset', '100%');
  stopElement2.setAttribute('stop-color', endColor);
  gradientElement.appendChild(stopElement1);
  gradientElement.appendChild(stopElement2);

  return gradientElement;
}
