export type PageInfoType = {
  start: number;
  total: number;
  perPage: number;
};

export enum SearchModeEnum {
  Or = 'or',
  And = 'and',
}

export enum SortDirectionEnum {
  Asc = 'Ascending',
  Desc = 'Descending',
}

export enum NumericSearchTypeEnum {
  GreaterThanOrEqual = 'gte',
  LessThanOrEqual = 'lte',
  GreaterThan = 'gt',
  LessThan = 'lt',
  Equal = 'eq',
  NotEqual = 'ne',
  Between = 'in',
  Overlap = 'overlap',
}

export enum StringSearchTypeEnum {
  Like = 'like',
  Equal = 'eq',
  NotEqual = 'ne',
  Any = 'any',
}

export type FloatSearchType = {
  type: NumericSearchTypeEnum;
  value: number;
  valueB?: number;
};

export type IntSearchType = {
  type: NumericSearchTypeEnum;
  value: number;
  valueB?: number;
  any?: number[];
};

export type DateTimeSearchType = {
  type: NumericSearchTypeEnum;
  value: Date;
  valueB?: Date;
};

export type StringSearchType = {
  type: StringSearchTypeEnum;
  value: string;
  any?: string[];
};

export type GeoSearchType = {
  longitude: number;
  latitude: number;
  distance: number;
};

type ListSearchType = {
  all?: string[];
  any?: string[];
  not?: string[];
};

export type StringListSearchType = ListSearchType;
export type UUIDListSearchType = ListSearchType;
export type PermissionListSearchType = ListSearchType;
export type RequirementListSearchType = ListSearchType;

export type SortByType = {
  key: string;
  dir: SortDirectionEnum;
};
