import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ValidationError } from 'yup';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { Button, Flex, H4, H5, H6, Icon } from 'design-system-web';

import { useFlag } from 'lane-shared/hooks';
import { arrayReorder } from 'lane-shared/helpers';
import { byOrder } from 'lane-shared/helpers/sort';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import type {
  ContentWorkflowType,
  Workflow,
} from 'lane-shared/types/ContentWorkflowType';
import { FeatureFlag } from 'constants-flags';
import type { Channel } from 'lane-shared/types/ChannelType';
import { ContentTypeEnum } from 'constants-content';

import { WorkflowDetailsV2 } from 'components/workflows/WorkflowDetailsV2';
import ContentWorkflowDetails from 'components/workflows/ContentWorkflowDetails';

import styles from './WorkflowBuilder.scss';

type Content = {
  actions: ContentWorkflowType[];
  isInteractive: boolean;
  type: ContentTypeEnum;
};

type ValidationsErrorType = {
  [id: string]: ValidationError;
};

export default function WorkflowBuilder({
  title,
  secondaryButton,
  channel,
  content,
  timeZone,
  workflows: unsortedWorkflows,
  onAddWorkflow,
  onRemoveWorkflow,
  onCloneWorkflow,
  onReorderWorkflow,
  onUpdateWorkflow,
  onSaveWorkflow,
  className,
  translationKeys,
}: {
  title: string;
  secondaryButton?: JSX.Element;
  channel: Channel;
  content?: Content;
  timeZone: string;
  workflows: Workflow[];
  onAddWorkflow: () => Promise<Workflow | void>;
  onRemoveWorkflow: (id: string) => void;
  onCloneWorkflow: (id: string, workflowNickName?: string) => void;
  onReorderWorkflow: (reorderedWorkflows: Workflow[]) => void;
  onUpdateWorkflow: (index: number, updatedWorkflow: Workflow) => void;
  onSaveWorkflow?: (
    allWorkflows: Workflow[],
    workflowToSave?: Workflow
  ) => Promise<any>;
  className?: string;
  translationKeys?: {
    addWorkflowButton: string;
    noWorkflowsToDisplayDescription: string;
    noWorkflowsToDisplayText: string;
    workflowSaved: string;
    workflowDuplicated: string;
    workflowDeleted: string;
  };
}) {
  const isNewWorkflowsUIEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportNewWorkflowsUI,
    false
  );
  const isWONotificationsCrudStrategyV2Enabled = useFlag(
    FeatureFlag.NotificationsCrudStrategyV2,
    false
  );
  const { t } = useTranslation();
  const [createdWorkflowId, setCreatedWorkflowId] = useState<string | null>(
    null
  );
  const [allWorkflows, setAllWorkflows] =
    useState<Workflow[]>(unsortedWorkflows);

  useEffect(() => {
    setAllWorkflows(unsortedWorkflows);
  }, [unsortedWorkflows]);

  const [workflowValidations, setWorkflowValidations] =
    useState<ValidationsErrorType>({});

  const TRANSLATION_KEYS = {
    addWorkflowButton:
      translationKeys?.addWorkflowButton ||
      'web.admin.channel.content.workflow.buttons.add',
    noWorkflowsToDisplayDescription:
      translationKeys?.noWorkflowsToDisplayDescription ||
      'web.admin.channel.content.workflow.noWorkflows.description',
    noWorkflowsToDisplayText:
      translationKeys?.noWorkflowsToDisplayText ||
      'web.admin.channel.content.workflow.noWorkflows.text',
  };

  const prioritizeWorkflowInCreateMode = (workflows: Workflow[]) => {
    return workflows.sort((a, b) => {
      if (a._id === createdWorkflowId) {
        return -1;
      }

      if (b._id === createdWorkflowId) {
        return 1;
      }

      return 0;
    });
  };

  const workflows = useMemo(() => {
    const sortedWorkflowsByOrder = (unsortedWorkflows || []).sort(byOrder);

    if (isNewWorkflowsUIEnabled) {
      // sorting the actions by order and then we are placing the new workflow at the top
      return prioritizeWorkflowInCreateMode(sortedWorkflowsByOrder);
    }

    return sortedWorkflowsByOrder;
  }, [unsortedWorkflows, isNewWorkflowsUIEnabled, createdWorkflowId]);

  const handleAddWorkflow = async () => {
    if (isWONotificationsCrudStrategyV2Enabled && createdWorkflowId) {
      return;
    }

    const workflow = await onAddWorkflow();

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'void' is not assignable to type 'ContentWorkflowType'
    setAllWorkflows([...allWorkflows, workflow]);
    setCreatedWorkflowId(workflow?._id ?? null);
  };

  const handleReorderWorkflows = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedWorkflows = arrayReorder(
      workflows,
      result.source.index,
      result.destination.index
    );

    onReorderWorkflow(reorderedWorkflows);
  };

  const handleUpdateWorkflow = (id: string, update: Partial<Workflow>) => {
    const index = workflows.findIndex((workflow: any) => workflow._id === id);
    const originalWorkflow = workflows[index];
    const updatedWorkflow = { ...originalWorkflow, ...update };

    return onUpdateWorkflow(index, updatedWorkflow);
  };

  const handleDeleteWorkflow = (id: string) => {
    if (isWONotificationsCrudStrategyV2Enabled && createdWorkflowId === id) {
      setCreatedWorkflowId(null);
    }

    onRemoveWorkflow(id);
  };

  const addWorkflowButton = (
    <Button testId="addWorkflow" size="large" onClick={handleAddWorkflow}>
      {t(TRANSLATION_KEYS.addWorkflowButton)}
    </Button>
  );

  return content?.type !== ContentTypeEnum.WorkOrder &&
    isNewWorkflowsUIEnabled ? (
    <div className={cx(styles.WorkflowBuilder, className)}>
      <div className={styles.titleDiv}>
        <H4 className={styles.title} data-margin-bottom={5}>
          {title}
        </H4>
        {workflows.length > 0 && (
          <Flex gap={3}>
            {secondaryButton && secondaryButton}
            <div>{addWorkflowButton}</div>
          </Flex>
        )}
      </div>
      {workflows.length === 0 ? (
        <div className={styles.noWorkflowsContainer}>
          <div className={styles.noWorkflows}>
            <H5 mb={2}>{t(TRANSLATION_KEYS.noWorkflowsToDisplayText)}</H5>
            <H6 mb={5}>
              {t(TRANSLATION_KEYS.noWorkflowsToDisplayDescription)}
            </H6>
            <Flex gap={3}>
              {secondaryButton && secondaryButton}
              {addWorkflowButton}
            </Flex>
          </div>
        </div>
      ) : null}
      {workflows.length > 0 && (
        <DragDropContext onDragEnd={handleReorderWorkflows}>
          <Droppable droppableId="properties">
            {provided => (
              <div className={styles.workflows} ref={provided.innerRef}>
                {workflows.map((workflow: Workflow, i: number) => (
                  <Draggable
                    key={workflow._id}
                    draggableId={workflow._id}
                    index={i}
                    isDragDisabled={false}
                  >
                    {(provided, snapshot) => (
                      <div
                        data-test="workflow"
                        key={workflow._id}
                        className={styles.workflow}
                        ref={provided.innerRef}
                        data-is-dragging={snapshot.isDragging}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={styles.item}>
                          <WorkflowDetailsV2
                            key={workflow._id}
                            content={content}
                            channel={channel}
                            className={styles.workflowDetails}
                            storedWorkflow={workflow}
                            storedWorkflows={workflows}
                            onWorkflowUpdated={handleUpdateWorkflow}
                            onWorkflowCloned={onCloneWorkflow}
                            onWorkflowDeleted={handleDeleteWorkflow}
                            isNewWorkflowsUIEnabled={isNewWorkflowsUIEnabled}
                            timeZone={timeZone}
                            createMode={workflow._id === createdWorkflowId}
                            setCreatedWorkflowId={setCreatedWorkflowId}
                            saveContent={onSaveWorkflow}
                            allWorkflows={allWorkflows}
                            setAllWorkflows={setAllWorkflows}
                            setWorkflowValidations={setWorkflowValidations}
                            validation={workflowValidations[workflow._id]}
                            translationKeys={translationKeys}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  ) : (
    <div className={cx(styles.WorkflowBuilderLegacy, className)}>
      <section className={styles.workflows}>
        <h1 data-margin-bottom={5}>
          <span>{title}</span>
          <Icon
            testId="addWorkflow"
            name="plus-circle"
            set={ICON_SET_FONTAWESOME}
            className={styles.addIcon}
            onClick={() => onAddWorkflow()}
          />
        </h1>
        <DragDropContext onDragEnd={handleReorderWorkflows}>
          <Droppable droppableId="properties">
            {provided => (
              <ul className={styles.workflows} ref={provided.innerRef}>
                {workflows.map((workflow: any, i: number) => (
                  <Draggable
                    key={workflow._id}
                    draggableId={workflow._id}
                    index={i}
                    isDragDisabled={false}
                  >
                    {(provided, snapshot) => (
                      <li
                        data-test="workflow"
                        key={workflow._id}
                        className={styles.workflow}
                        ref={provided.innerRef}
                        data-is-dragging={snapshot.isDragging}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Icon
                          name="bars"
                          set={ICON_SET_FONTAWESOME}
                          className={styles.iconReorder}
                        />
                        <ContentWorkflowDetails
                          key={workflow._id}
                          content={content}
                          channel={channel}
                          timeZone={timeZone}
                          className={styles.workflowDetails}
                          workflow={workflow}
                          onWorkflowUpdated={handleUpdateWorkflow}
                          onWorkflowCloned={onCloneWorkflow}
                          onWorkflowDeleted={handleDeleteWorkflow}
                        />
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </div>
  );
}
