import { Input } from 'components';
import { Alert } from 'components/lds';
import { Button, Flex, Modal } from 'design-system-web';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import styles from './style.scss';
import { createAccessPointMutation } from 'lane-shared/graphql/hardware/accessPoint';
import { useMutation } from '@apollo/client';
import { getClient } from 'lane-shared/apollo';
import { safeConvertToUUID } from 'uuid-encoding';
import { spacing } from 'lane-shared/config';
import { useDebouncedCallback } from 'use-debounce';
import { Channel } from 'packages/lane-shared/types/ChannelType';

export interface AccessPointDetails {
  building_id: string;
  name: string;
  description: string;
}

type Props = {
  modalVisible: boolean;
  onClose: () => void;
  onSave: () => void;
  name?: string;
  testId?: string;
  channel: Channel;
};
/**
 * Modal variant of @function CreateAccessPoint
 */
export function CreateAccessPointModal({
  modalVisible,
  onClose,
  onSave,
  name = '',
  testId,
  channel,
}: Props) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, t('web.admin.hardware.management.relay.accessPoint.nameMinError'))
      .max(
        200,
        t('web.admin.hardware.management.relay.accessPoint.nameMaxError')
      )
      .required(
        t('web.admin.hardware.management.relay.accessPoint.nameRequiredError')
      ),
    description: Yup.string()
      .min(
        1,
        t('web.admin.hardware.management.relay.accessPoint.descriptionMinError')
      )
      .max(
        200,
        t('web.admin.hardware.management.relay.accessPoint.descriptionMaxError')
      )
      .required(
        t(
          'web.admin.hardware.management.relay.accessPoint.descriptionRequiredError'
        )
      ),
  });

  const [newAccessPointDetails, setNewAccessPointDetails] =
    useState<AccessPointDetails>({
      building_id: safeConvertToUUID(channel?._id),
      name,
      description: '',
    });

  const [hasError, setHasError] = useState<boolean>(false);
  const [saveIsDisabled, setSaveIsDisabled] = useState<boolean>(true);

  const [createAccessPoint] = useMutation(createAccessPointMutation, {
    client: getClient(),
  });

  const createNewAccessPoint = async (
    accessPointDetails: AccessPointDetails
  ) => {
    try {
      const response = await createAccessPoint({
        variables: {
          input: {
            buildingId: safeConvertToUUID(accessPointDetails.building_id),
            name: accessPointDetails.name,
            description: accessPointDetails.description,
          },
        },
      });

      const createdAccessPoint = response?.data?.createAccessPoint;

      setHasError(false);
      setSaveIsDisabled(false);
      window.Toast.show(
        t('web.admin.hardware.management.relay.accessPoint.addSuccessMsg', {
          name: createdAccessPoint?.name,
        })
      );
      onSave();
      onClose();
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      setHasError(true);
      setSaveIsDisabled(true);
    }
  };

  // TODO: Under Discussion will clear after https://viewthespace.atlassian.net/browse/TM-18584
  // const spaceDropdownItems = spaceList.map(space => ({
  //   label: space?.name,
  //   value: space?.building_id,
  // }));
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleFieldUpdate = (value: string, fieldName: string) => {
    setNewAccessPointDetails((prev: AccessPointDetails) => {
      const updatedDetails = {
        ...prev,
        [fieldName]: value,
      };

      validateForm(updatedDetails);

      return updatedDetails;
    });
  };

  const validateForm = async (updatedDetails: AccessPointDetails) => {
    try {
      await validationSchema.validate(updatedDetails, {
        abortEarly: false,
      });

      setValidationErrors({});
      setSaveIsDisabled(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors: { [key: string]: string } = {};

        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });

        setValidationErrors({ ...validationErrors });
        setSaveIsDisabled(true);
      }
    }
  };

  const debounceCreateNewAccessPoint = useDebouncedCallback(async () => {
    await createNewAccessPoint(newAccessPointDetails);
  }, 300).callback;

  const handleSaveAccessPoint = async () => {
    await validateForm(newAccessPointDetails);

    if (validationErrors) {
      debounceCreateNewAccessPoint();
    }
  };

  return (
    <Modal
      isOpen={modalVisible}
      onClose={onClose}
      testId={testId}
      closeButtonSize="large"
      size="large"
      disableContentPadding
      title={t('web.admin.hardware.management.relay.createAccessPoint')}
      style={{
        width: spacing[14],
        maxWidth: spacing[14],
        paddingBottom: spacing[5],
        borderRadius: 12,
      }}
    >
      <Flex
        direction="column"
        gap={5}
        p={[0, 5, 5, 5]}
        className={styles.formBorderBottom}
      >
        {hasError && (
          <Alert type="error" fullWidth style={{ margin: 0 }}>
            {t('web.admin.hardware.management.relay.accessPoint.addErrorMsg')}
          </Alert>
        )}
        <Input
          type="text"
          label={t('web.admin.hardware.management.relay.accessPointName')}
          placeholder={t(
            'web.admin.hardware.management.relay.accessPoint.placeholderName'
          )}
          testId="input-access-point-name"
          fixedLabel
          onChange={(value: string) => {
            handleFieldUpdate(value, 'name');
          }}
          fieldName="name"
          error={validationErrors.name ? [validationErrors.name] : null}
          isRequired
          value={newAccessPointDetails.name}
        />
        <Input
          type="text"
          label={t(
            'web.admin.hardware.management.relay.accessPointDescription'
          )}
          placeholder={t(
            'web.admin.hardware.management.relay.accessPoint.placeholderDescription'
          )}
          testId="input-access-point-description"
          fixedLabel
          onChange={(value: string) => {
            handleFieldUpdate(value, 'description');
          }}
          isRequired
          fieldName="description"
          error={
            validationErrors.description ? [validationErrors.description] : null
          }
          value={newAccessPointDetails.description}
        />
        {/* TODO: Under Discussion will clear after https://viewthespace.atlassian.net/browse/TM-18584 */}
        {/* <Dropdown
          items={spaceDropdownItems}
          testId="dropdown-spaces"
          value={newAccessPointDetails.building_id}
          isRequired
          onChange={selectedItem => {
            handleFieldUpdate(selectedItem.value, 'building_id');
          }}
          label={t('web.admin.hardware.management.relay.accessPoint.space')}
          fixedLabel
          invalid={!!validationErrors.building_id}
          errors={
            validationErrors.building_id ? [validationErrors.building_id] : null
          }
          isFullWidth
        /> */}
      </Flex>
      <Flex gap={3} p={[5, 5, 0, 5]}>
        <Button
          type="submit"
          disabled={saveIsDisabled}
          onClick={handleSaveAccessPoint}
          size="large"
          variant="primary"
          testId="btn-save-access-point"
        >
          {t('web.admin.hardware.management.relay.accessPointModal.save')}
        </Button>
        <Button
          type="button"
          onClick={() => onClose()}
          size="large"
          variant="secondary"
          testId="btn-cancel-access-point"
        >
          {t('web.admin.hardware.management.relay.accessPointModal.cancel')}
        </Button>
      </Flex>
    </Modal>
  );
}
