import React, { useContext, useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import {
  ApolloError,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from '@apollo/client';

import { LaneType } from 'common-types';
import { UserDataContext } from 'lane-shared/contexts';
import { errorCodes } from 'activate-errors';
import {
  resendVerificationEmail,
  updateUserLoginsMutation,
} from 'lane-shared/graphql/user';
import { getValidationMessages } from 'lane-shared/helpers';
import { shopifyCustomer } from 'lane-shared/helpers/integrations/ShopifyMultipass';
import { getPrimaryEmail } from 'lane-shared/helpers/user';
import { useUserLoginsQuery } from 'lane-shared/hooks';
import { UserLoginStatusEnum, UserLoginTypeEnum } from 'constants-user';
import { validateEmail } from 'lane-shared/validation';

import Input from 'components/form/Input';
import { Button, ErrorMessage } from 'components/general';
import { Modal } from 'components/lds';
import { H3, Text } from 'components/typography';

import styles from './EmailVerificationModal.scss';
import { UserLoginType } from 'packages/lane-shared/types/UserLogin';

type Props = {
  channelIntegrationId: LaneType.UUID;
  isOpen: boolean;
  unverifiedEmailError: boolean | undefined;
  onClose: () => void;
  onExit: () => void;
  onCreateNewAccount: () => void;
};

enum ModalView {
  EMAIL_FORM = 'Email Form',
  VERIFY_EMAIL = 'Verify Email',
  VERIFICATION_SENT = 'Verification Sent',
}

export default function EmailVerificationModal({
  channelIntegrationId,
  unverifiedEmailError,
  isOpen,
  onClose,
  onExit,
  onCreateNewAccount,
}: Props) {
  const { user } = useContext(UserDataContext);
  const [userLogins] = useUserLoginsQuery();
  const [email, setEmail] = useState<string | null>(null);
  const [verifiedEmail, setVerifiedEmail] = useState<string | null>(null);
  const [updateUserError, setUpdateUserError] = useState<ApolloError | null>(
    null
  );
  const [shopifyCustomerError, setShopifyCustomerError] = useState<
    string | null
  >(null);
  const [loginNotVerifiedError, setLoginNotVerifiedError] = useState<
    string | null
  >(null);
  const [validation, setValidation] = useState<string[] | null>(null);
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const [currentModalView, setCurrentModalView] = useState<ModalView>(
    unverifiedEmailError ? ModalView.VERIFY_EMAIL : ModalView.EMAIL_FORM
  );
  const userLoginEmails: string[] = userLogins.map(userLogin => userLogin.key);
  const primaryEmailAddress = getPrimaryEmail(userLogins);

  const [sendEmail, { loading }] = useMutation(resendVerificationEmail, {
    onCompleted() {
      setCurrentModalView(ModalView.VERIFICATION_SENT);
    },
  });

  const [updateUserLogins] = useMutation(updateUserLoginsMutation, {
    refetchQueries: ['getUserLogins'],
    onError(error) {
      setUpdateUserError(error);
    },
    onCompleted() {
      setCurrentModalView(ModalView.VERIFICATION_SENT);
    },
  });

  const [checkShopifyCustomer, { loading: isExistingCustomerCheckLoading }] =
    useLazyQuery(shopifyCustomer, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError(error) {
        setShopifyCustomerError(error.message);
      },
      onCompleted(data) {
        const customerId = data?.shopifyCustomer?.shopifyCustomerId;

        if (!customerId) {
          // if we're checking a shopifyCustomer without an email
          // it means that we already went thru the verification process
          // for the user's primary email. Let's create a new account for them
          setShopifyCustomerError(
            t(errorCodes.shopifyMultipassUserNotFound.message)
          );

          if (!email) {
            setShopifyCustomerError(null);
            setValidation(null);
            setUpdateUserError(null);
            setCurrentModalView(ModalView.EMAIL_FORM);
          }
        } else if (!email || email === verifiedEmail) {
          // if we have a new or existent verified email that exists on shopify we can close modal
          // and let the hook handle the shopify authentication
          if (onClose) {
            onClose();
          }
        } else if (userLoginEmails.includes(email)) {
          sendVerificationEmail(email);
        } else {
          updateUserLogins({
            variables: {
              userId: user?._id,
              userLogin: {
                key: email,
                type: UserLoginTypeEnum.Email,
              },
            },
          });
        }
      },
    });

  useEffect(() => {
    if (unverifiedEmailError) {
      setCurrentModalView(ModalView.VERIFY_EMAIL);
    }
  }, [unverifiedEmailError]);

  useEffect(() => {
    if (currentModalView === ModalView.VERIFICATION_SENT) {
      setLoginNotVerifiedError(null);
    }
  }, [currentModalView]);

  async function validate(): Promise<boolean> {
    try {
      setUpdateUserError(null);
      setShopifyCustomerError(null);
      await validateEmail.validate({ email });
      setValidation(null);

      return true;
    } catch (emailValidation) {
      setValidation(emailValidation);

      return false;
    }
  }

  useEffect(() => {
    validate();
  }, [email]);

  function getErrorForInput() {
    let errors = [];

    if (shopifyCustomerError) {
      errors.push(shopifyCustomerError);
    }

    if (updateUserError) {
      errors.push(updateUserError.message);
    }

    if (email) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[] | null' is not assignab... Remove this comment to see the full error message
      const validationErrors = getValidationMessages(validation, 'email');

      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (validationErrors?.length > 0) {
        // @ts-expect-error ts-migrate(2488) FIXME: Type 'string[] | null' must have a '[Symbol.iterat... Remove this comment to see the full error message
        errors = [...errors, ...validationErrors];
      }
    }

    return errors.length > 0 ? errors : null;
  }

  async function onEmailSubmit() {
    setShopifyCustomerError(null);
    setLoginNotVerifiedError(null);
    await checkShopifyCustomer({
      variables: { channelIntegrationId, email },
    });
  }

  function sendVerificationEmail(email: string) {
    sendEmail({
      variables: {
        email,
      },
    });
  }

  function onExitModal() {
    setCurrentModalView(
      unverifiedEmailError ? ModalView.VERIFY_EMAIL : ModalView.EMAIL_FORM
    );
    setEmail(null);
    setVerifiedEmail(null);
    setUpdateUserError(null);
    setValidation(null);
    setShopifyCustomerError(null);
    setLoginNotVerifiedError(null);

    if (onExit) {
      onExit();
    }
  }

  async function onDoneVerificationClick() {
    const [userLoginsResponse] = await apolloClient.refetchQueries({
      include: ['getUserLogins'],
    });

    const updatedUserLogins: UserLoginType[] =
      userLoginsResponse?.data?.me?.user?.logins;
    const verifiedEmail = (email || primaryEmailAddress) as string;

    const userLogin = updatedUserLogins?.find(
      userLogin => userLogin.key === verifiedEmail
    );

    if (userLogin?.status === UserLoginStatusEnum.Verified) {
      setVerifiedEmail(verifiedEmail);
      await checkShopifyCustomer({
        variables: { channelIntegrationId, email: verifiedEmail },
      });
    } else {
      setLoginNotVerifiedError(
        t`Please verify your email to access Lane Perks.`
      );
    }
  }

  const inputErrors = getErrorForInput();

  const submitEmailButton = (
    <Button
      variant="contained"
      disabled={Boolean(!email || (inputErrors && inputErrors?.length > 0))}
      className={styles.confirmButton}
      loading={loading}
      onClick={onEmailSubmit}
      testId="modalButtonConfirm"
      size="large"
    >
      {t('Connect my accounts')}
    </Button>
  );

  const sendButton = (
    <Button
      size="medium"
      className={styles.sendButton}
      variant="contained"
      loading={loading}
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      onClick={() => sendVerificationEmail(primaryEmailAddress)}
      testId="sendVerificationButton"
    >
      {t`Send verification`}
    </Button>
  );

  const resendButton = (
    <Button
      size="medium"
      className={styles.resendButton}
      variant="outlined"
      loading={loading}
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      onClick={() => sendVerificationEmail(email || primaryEmailAddress)}
      testId="resendVerificationButton"
    >
      {t`Resend verification`}
    </Button>
  );

  const doneButton = (
    <Button
      size="medium"
      className={styles.doneButton}
      variant="contained"
      onClick={onDoneVerificationClick}
      loading={isExistingCustomerCheckLoading}
    >
      {t`Done`}
    </Button>
  );

  const views = {
    [ModalView.EMAIL_FORM]: {
      content: (
        <>
          <H3 className={styles.h2}>{t`Have you used Lane Perks before?`}</H3>
          <p>
            {t`If yes, please enter the email you used for Lane Perks below and we'll connect your accounts.`}
          </p>
          <Input
            testId="lanePerksEmailInput"
            className={styles.input}
            value={email}
            fieldName="email"
            label="email"
            onChange={email => setEmail(email)}
            error={getErrorForInput()}
          />
          <div className={styles.footer}>
            <p className={styles.footerText}>
              {t`Haven't used Lane Perks before?`}
            </p>
            <a role="link" tabIndex={0} onClick={onCreateNewAccount}>
              <Text className={styles.link}>{t`Get started`}</Text>
            </a>
          </div>
        </>
      ),
      actions: [submitEmailButton],
    },
    [ModalView.VERIFY_EMAIL]: {
      content: (
        <>
          <H3>{t`Verify your email`}</H3>
          <p>{t`In order to use Lane Perks, your email must be verified.`}</p>
          <div className={styles.emailContainer}>
            <Icon
              size="medium"
              className={styles.errorIcon}
              name="times-circle"
              type="far"
              set="FontAwesome"
            />
            {primaryEmailAddress}
          </div>
        </>
      ),
      actions: [sendButton],
    },
    [ModalView.VERIFICATION_SENT]: {
      content: (
        <>
          <Icon className={styles.sentIcon} name="paper-plane" size="large" />
          <H3>{t`Verification sent`}</H3>
          <p>
            {t`A verification email has been sent to your email. Please click on the link in the email to complete verification.`}
          </p>
          {loginNotVerifiedError && (
            <ErrorMessage error={[loginNotVerifiedError]} />
          )}
        </>
      ),
      actions: [resendButton, doneButton],
    },
  };

  const { content, actions } = views[currentModalView];

  return (
    <Modal
      className={styles.emailVerificationModal}
      size="small"
      isOpen={isOpen}
      onClose={onExitModal}
      actions={actions}
    >
      <div className={styles.modalContent}>{content}</div>
    </Modal>
  );
}
