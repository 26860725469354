import { IntegrationProviderEnum } from 'constants-integrations';

export enum DependencyEnum {
  channelIntegrations = 'channelIntegrations',
  isSuperUser = 'isSuperUser',
}

export type DependencyType = {
  channelIntegrations?: IntegrationProviderEnum[];
  isSuperUser?: boolean;
};
