import { ChannelExperienceTypeEnum } from 'constants-channel';

export const isChannelForCRE = (
  experienceType: ChannelExperienceTypeEnum | null | undefined
): boolean => {
  if (
    // experienceType is nullable so unless the experience type is explicitly set to multifaly,
    // a channel is a CRE channel
    experienceType === ChannelExperienceTypeEnum.office ||
    !experienceType
  ) {
    return true;
  }

  return false;
};
