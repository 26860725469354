import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import { cloneDeep } from 'lodash';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';
import { YardiResidentialProperties } from '../../constants/integrations/Yardi';

const definition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Yardi,
  hasCustomUI: true,
  friendlyName: IntegrationProviderEnum.Yardi,
  description: `Defines the properties required for Yardi integration.`,
  category: IntegrationCategoryEnum.Accounting,
  platforms: [],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: cloneDeep(YardiResidentialProperties),
};

export { definition };
