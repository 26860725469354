import {
  NotFoundError,
  NotImplementedError,
  OAuthConfigError,
} from 'activate-errors';
import { Storage } from 'lane-shared/helpers';
import { OAuthProvidersEnum } from 'constants-user';
import getOAuthIdentity from 'lane-shared/helpers/getOAuthIdentity';
import { oAuthConfigScheme, OAuthConfigShape } from 'lane-shared/helpers/oAuth';
import { OAuthConfigType } from 'lane-shared/types/OAuthConfigType';
import { OAuthIdentity } from 'lane-shared/types/oauth';

import OAuthService, { AuthorizeResult } from './OAuth.service';

const DEFAULT_SCOPES = ['openid', 'profile', 'email'];

const OKTA_AUTH_STATE = `${OAuthProvidersEnum.OKTA}:AUTH_STATE`;

function getOktaOAuthConfig(
  oAuthConfig: OAuthConfigType | any = {},
  scopes: string[]
): OAuthConfigShape {
  const {
    tenantId,
    webClientId: clientId,
    webRedirectUrl: redirectUrl,
  } = oAuthConfig;

  if (!tenantId) {
    // NOTE: If you need to resolve this error it means that
    // the tenantId in OAuth config is required, in this case
    // it will represent the Okta `issuer` in accordance with
    // OAuth2 specs.
    throw new OAuthConfigError('Tenant ID Error');
  }

  try {
    return oAuthConfigScheme.validateSync({
      ...oAuthConfig,
      issuer: tenantId,
      clientId,
      redirectUrl,
      scopes,
      provider: oAuthConfig?.provider,
    });
    // FIXME: Log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    throw new OAuthConfigError();
  }
}

type GetOktaAccessTokenProps = {
  oAuthConfig: OAuthConfigType | undefined;
  options?: { scopes?: string[]; skipStorage?: boolean };
};

export async function getOktaAccessToken({
  oAuthConfig,
  options: { scopes = DEFAULT_SCOPES, skipStorage = false } = {
    scopes: DEFAULT_SCOPES,
    skipStorage: false,
  },
}: GetOktaAccessTokenProps): Promise<AuthorizeResult> {
  const oktaConfig = getOktaOAuthConfig(oAuthConfig, scopes);

  const oktaOAuthService = new OAuthService({ config: oktaConfig });

  if (!skipStorage) {
    try {
      const knownState = (await Storage.getItem(
        OKTA_AUTH_STATE
      )) as AuthorizeResult;

      const accessTokenExpirationDate = new Date(
        knownState.accessTokenExpirationDate
      );

      if (new Date() < accessTokenExpirationDate) {
        return knownState;
      }

      if (knownState.refreshToken) {
        const refreshResult = await oktaOAuthService.refresh(
          knownState.refreshToken
        );

        if (refreshResult) {
          Storage.setItem(OKTA_AUTH_STATE, refreshResult);

          return refreshResult;
        }

        // NOTE: Indicates failure to refresh, fallback
        // to `authorize` flow, remove known storage object
        // to prevent repeat events.
        await Storage.removeItem(OKTA_AUTH_STATE);
      }
    } catch (err) {
      // NOTE: Swallow NotFoundError thrown by `getItem`
      // it will be set by `setItem` below.
      if (!(err instanceof NotFoundError)) {
        throw err;
      }
    }
  }

  const authState = await oktaOAuthService.authorize();

  Storage.setItem(OKTA_AUTH_STATE, authState);

  return authState;
}

export async function getOktaOAuthIdentity(
  oAuthConfig: OAuthConfigType | undefined,
  options: { scopes?: string[]; mode: 'LogIn' | 'SignUp' }
): Promise<OAuthIdentity> {
  const tokens = await getOktaAccessToken({ oAuthConfig, options });

  if (!tokens) {
    throw Error('The Okta user could not be verified.');
  }

  return getOAuthIdentity(tokens.idToken);
}

export async function getOktaProfile(_oAuthConfig: any): Promise<any> {
  throw new NotImplementedError();
}
