import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

export const CAPTIVATE_RESOLUTIONS = [
  '640x480',
  '1024x576',
  '1280x720',
] as const;
export const [
  CAPTIVATE_RESOLUTION_640x480,
  CAPTIVATE_RESOLUTION_1024x576,
  CAPTIVATE_RESOLUTION_1280x720,
] = CAPTIVATE_RESOLUTIONS;

export const integrationDefaults = {
  name: IntegrationProviderEnum.Captivate,
  type: IntegrationTypeEnum.ContentTargeting,
  platforms: [],
  category: IntegrationCategoryEnum.Displays,
  properties: {
    tags: {
      type: 'String',
      isArray: true,
      validation: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    showQRCode: {
      type: 'Boolean',
      default: false,
    },
    showDate: {
      type: 'Boolean',
      default: false,
    },
  },
};
