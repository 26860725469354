import React, { useState, useEffect, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { RendererContext } from 'lane-shared/contexts';
import { cloneBlockWithNewUUIDS } from 'lane-shared/helpers';
import { removeTranslationsFromContent } from 'lane-shared/helpers/content';
import { explodeFeatures } from 'lane-shared/helpers/features';
import {
  defaultExportOptions,
  getKeyValue,
  TemplateExportOptionsType,
  useImportExportInfo,
  updateMetaData,
} from 'lane-shared/helpers/templates';
import { useCurrentChannel } from 'lane-shared/hooks';
import Features from 'lane-shared/renderers/v5/features';
import { FeatureNameEnum } from 'constants-content';

import Checkbox from 'components/form/Checkbox';
import Toggle from 'components/form/Toggle';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import { findBlock, removeBlock } from 'components/renderers/helpers';

import makeFileDownload from '../../helpers/makeFileDownload';

import styles from './TemplateExportModal.scss';
import { useDraftContentAnalytics } from 'lane-shared/hooks/analytics';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  content: any;
  mode?: 'Content' | 'ContentTemplate';
  forEdit?: boolean;
};

export default function TemplateExportModal({
  style,
  className,
  isOpen,
  onClose,
  content,
  mode,
  forEdit,
}: Props) {
  const { blocks } = useContext(RendererContext);
  const { draftContentTracker } = useDraftContentAnalytics({
    draftContent: content,
  });

  const [options, setOptions] = useState<TemplateExportOptionsType>({
    ...defaultExportOptions,
  });
  const currentChannel = useCurrentChannel();

  const {
    appearanceAvailable,
    optionsAvailable,
    translationsAvailable,
    featureCount,
    availableFields,
    allFieldsSelected,
    fieldsStep,
    appearanceStep,
    translationsStep,
    optionsStep,
    featureStep,
  } = useImportExportInfo({ options, channel: currentChannel });

  const { t } = useTranslation();

  function selectSource(content: any) {
    const options = { ...defaultExportOptions };

    // examine the content being set and determine if its available
    options.name = getKeyValue(content, 'name');
    options.description = getKeyValue(content, 'description');
    options.subtitle = getKeyValue(content, 'subtitle');
    options.tags = getKeyValue(content, 'tags');
    options.category = getKeyValue(content, 'category');
    options.logo = getKeyValue(content, 'logo');
    options.color = getKeyValue(content, 'color');
    options.backgroundColor = getKeyValue(content, 'backgroundColor');
    options.backgroundImage = getKeyValue(content, 'backgroundImage');
    options.icon = getKeyValue(content, 'icon');

    options.appearance = getKeyValue(content, 'block');
    options.data = getKeyValue(content, 'properties');
    options.workflows = getKeyValue(content, 'actions');
    options.translations =
      currentChannel?.settings?.multiLanguageEnabled || false;

    // not available options on ContentTemplate
    options.placements =
      mode === 'ContentTemplate'
        ? undefined
        : getKeyValue(content, 'placements');

    options.notifications =
      mode === 'ContentTemplate'
        ? undefined
        : getKeyValue(content, 'notifications');

    const features = explodeFeatures(content?.features);

    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ Cancelable: true | undefined; Deliverable:... Remove this comment to see the full error message
    options.features = {
      Cancelable: features.cancelableFeature ? true : undefined,
      Deliverable: features.deliverableFeature ? true : undefined,
      Entries: features.entriesFeature ? true : undefined,
      EssensysProduct: features.essensysProductFeature ? true : undefined,
      Inventory: features.inventoryFeature ? true : undefined,
      GuestInvite: features.guestInviteFeature ? true : undefined,
      Menu: features.menuFeature ? true : undefined,
      Payment: features.paymentFeature ? true : undefined,
      QRCodeCheckin: features.qrCodeCheckinFeature ? true : undefined,
      Quantity: features.quantityFeature ? true : undefined,
      RemoteFetch: features.remoteFetchFeature ? true : undefined,
      Reservable: features.reservableFeature ? true : undefined,
      Reset: features.resetFeature ? true : undefined,
      Scheduled: features.scheduledFeature ? true : undefined,
      Shipping: features.shippingFeature ? true : undefined,
      SocialOptions: features.socialOptionsFeature ? true : undefined,
      Statuses: features.statusesFeature ? true : undefined,
      SubmitOnBehalfOf: features.submitOnBehalfOfFeature ? true : undefined,
      TimeAvailability: features.timeAvailabilityFeature ? true : undefined,
      UseCompanyPermissions: features.useCompanyPermissionsFeature
        ? true
        : undefined,
    };

    setOptions(options);
  }

  useEffect(() => {
    if (content?._id) {
      selectSource(content);
    }
  }, [content?._id]);

  function doExport() {
    function makeFeature(type: any, feature: any) {
      // rebuild the feature from its data
      if (!toExport.features) {
        toExport.features = [];
      }

      // clone the feature and add into the features array
      toExport.features.push({
        _id: uuid(),
        type,
        feature: JSON.parse(JSON.stringify(feature)),
      });
    }

    function makeNotification(notification: any) {
      // first clone the object
      const exportNotification = JSON.parse(JSON.stringify(notification));

      // if importing this into new content, this will point
      // to the wrong content, so remove it.
      delete exportNotification.content;
      exportNotification._id = uuid();

      return exportNotification;
    }

    function makeWorkflow(workflow: any) {
      // first clone the object
      const exportWorkflow = JSON.parse(JSON.stringify(workflow));

      // if importing this into new content, this will point
      // to the wrong content, so remove it.
      delete exportWorkflow.content;
      exportWorkflow._id = uuid();

      return exportWorkflow;
    }

    function makePlacement(placement: any) {
      // first clone the object
      const exportPlacement = JSON.parse(JSON.stringify(placement));

      // if importing this into new content, this will point
      // to the wrong content, so remove it.
      delete exportPlacement.content;
      exportPlacement._id = uuid();

      return exportPlacement;
    }

    const toExport: any = {};

    // use JSON.parse + JSON.stringify below to do a deep clone,
    // since we will be updating UUIDs with new ones.

    Object.entries(options).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      switch (key) {
        // these guys all map directly to a key
        case 'name':
        case 'description':
        case 'subtitle':
        case 'category':
        case 'logo':
        case 'color':
        case 'backgroundColor':
        case 'backgroundImage':
        case 'resetPeriod':
        case 'isInteractive':
          toExport[key] = content[key];
          break;
        // these guys require a bit more logic
        case 'icon':
          toExport.icon = content.icon;
          toExport.iconSet = content.iconSet;
          break;
        case 'appearance':
          toExport.block = cloneBlockWithNewUUIDS(content.block);
          toExport.renderer = content.renderer;
          break;
        case 'tags':
          toExport.tags = content.tags;
          toExport.contentTags = content.contentTags;
          break;
        case 'translations':
          toExport.name_l10n = content.name_l10n;
          toExport.description_l10n = content.description_l10n;
          toExport.subtitle_l10n = content.subtitle_l10n;
          break;
        case 'placements':
          toExport.placements = content.placements
            ? content.placements.map(makePlacement)
            : [];
          break;
        case 'data':
          toExport.data = JSON.parse(JSON.stringify(content.data));
          toExport.properties = JSON.parse(JSON.stringify(content.properties));
          toExport.state = JSON.parse(JSON.stringify(content.state));
          toExport.propertiesOptions = JSON.parse(
            JSON.stringify(content.propertiesOptions || {})
          );
          break;
        case 'workflows':
          toExport.actions = content.actions
            ? content.actions.map(makeWorkflow)
            : [];
          break;
        case 'notifications':
          toExport.notifications = content.notifications
            ? content.notifications.map(makeNotification)
            : [];
          break;
        case 'features':
          {
            const features = explodeFeatures(content.features);

            Object.entries(options.features).forEach(([key, value]) => {
              if (!value) {
                return;
              }

              switch (key) {
                case FeatureNameEnum.Cancelable:
                  makeFeature(key, features.cancelableFeature);
                  break;
                case FeatureNameEnum.Deliverable:
                  makeFeature(key, features.deliverableFeature);
                  break;
                case FeatureNameEnum.Entries:
                  makeFeature(key, features.entriesFeature);
                  break;
                case FeatureNameEnum.EssensysProduct:
                  makeFeature(key, features.essensysProductFeature);
                  break;
                case FeatureNameEnum.Inventory:
                  makeFeature(key, features.inventoryFeature);
                  break;
                case FeatureNameEnum.GuestInvite:
                  makeFeature(key, features.guestInviteFeature);
                  break;
                case FeatureNameEnum.Menu:
                  makeFeature(key, features.menuFeature);
                  break;
                case FeatureNameEnum.Payment:
                  makeFeature(key, features.paymentFeature);
                  break;
                case FeatureNameEnum.QRCodeCheckin:
                  makeFeature(key, features.qrCodeCheckinFeature);
                  break;
                case FeatureNameEnum.Quantity:
                  makeFeature(key, features.quantityFeature);
                  break;
                case FeatureNameEnum.RemoteFetch:
                  makeFeature(key, features.remoteFetchFeature);
                  break;
                case FeatureNameEnum.Reservable:
                  makeFeature(key, features.reservableFeature);
                  break;
                case FeatureNameEnum.Reset:
                  makeFeature(key, features.resetFeature);
                  break;
                case FeatureNameEnum.Scheduled:
                  makeFeature(key, features.scheduledFeature);
                  break;
                case FeatureNameEnum.Shipping:
                  makeFeature(key, features.shippingFeature);
                  break;
                case FeatureNameEnum.SocialOptions:
                  makeFeature(key, features.socialOptionsFeature);
                  break;
                case FeatureNameEnum.Statuses:
                  makeFeature(key, features.statusesFeature);
                  break;
                case FeatureNameEnum.SubmitOnBehalfOf:
                  makeFeature(key, features.submitOnBehalfOfFeature);
                  break;
                case FeatureNameEnum.TimeAvailability:
                  makeFeature(key, features.timeAvailabilityFeature);
                  break;
                case FeatureNameEnum.UseCompanyPermissions:
                  makeFeature(key, features.useCompanyPermissionsFeature);
                  break;
              }
            });
          }

          break;
        case 'metatags':
        default:
          // todo: not implemented yet
          break;
      }
    });

    if (content.externalUrl) {
      toExport.externalUrl = content.externalUrl;
    }

    // if options.data is set to false, that means there are data fields
    // but user is choosing to not export them.  we should remove them
    // from the appearance, or this will break during import.

    if (options.data === false && options.appearance) {
      const dataFieldBlocks = Object.keys(content.data);

      dataFieldBlocks.forEach(field => {
        const block = findBlock({
          content: toExport,
          blocks,
          key: 'for',
          value: field,
        });

        if (block) {
          removeBlock(toExport, block, blocks);
        }
      });
    }

    if (options.translations === false) {
      removeTranslationsFromContent(toExport);
    }

    // version is needed on import validation
    toExport.version = 0;
    const contents = JSON.stringify(updateMetaData(toExport));

    if (forEdit) {
      draftContentTracker.Update.Export(content);
    } else {
      draftContentTracker.Create.Export(content);
    }

    makeFileDownload({
      name: `${content.name}.json`,
      contents,
      type: 'application/json',
    });
  }

  function toggleAllOptions() {
    const update = {};

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    availableFields.forEach(key => (update[key] = !allFieldsSelected));

    updateOptions(update);
  }

  function updateOptions(props: Partial<TemplateExportOptionsType>) {
    setOptions({
      ...options,
      ...props,
    });
  }

  function renderOption(key: any, name: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return options[key] !== undefined ? (
      <li>
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ name: any; ... Remove this comment to see the full error message */}
        <Checkbox
          name={key}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          onChange={() => updateOptions({ [key]: !options[key] })}
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          selected={options[key]}
          text={t(name)}
        />
      </li>
    ) : null;
  }

  function renderFeatureOptions() {
    return (
      <ul>
        {Object.values(FeatureNameEnum).map(featureName => {
          if (options.features[featureName] === undefined) {
            return null;
          }

          return (
            <li key={featureName}>
              {/* @ts-expect-error ts-migrate(2322) FIXME: ts: Property 'value' is missing in type  */}
              <Checkbox
                name={featureName}
                onChange={() =>
                  updateOptions({
                    features: {
                      ...options.features,
                      [featureName]: !options.features[featureName],
                    },
                  })
                }
                selected={options.features[featureName]}
                text={t(Features[featureName].friendlyName || '')}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <ModalBackground
      onClose={onClose}
      isOpen={isOpen}
      className={styles.background}
    >
      <ResizableWindow
        showHeader
        onClose={onClose}
        name="templateExportModal"
        className={styles.window}
        contentContainerClassName={styles.windowContainer}
        defaultPosition={ResizableWindow.mostlyFullScreen()}
      >
        <div className={cx(styles.TemplateExport, className)} style={style}>
          <h1>{t('web.components.lane.ContentExporter.title')}</h1>

          {content && (
            <>
              <h2>
                <span className={styles.step}>{t(`${fieldsStep}`)}</span>
                {t('web.components.lane.ContentExporter.fieldsToImport.title')}
              </h2>

              <div className={styles.options}>
                <div className={styles.option}>
                  <h1>
                    {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: b... Remove this comment to see the full error message */}
                    <Checkbox
                      selected={allFieldsSelected}
                      text={t(
                        'web.admin.content.draftContent.info.generalInfo.text'
                      )}
                      onChange={toggleAllOptions}
                    />
                  </h1>

                  <ul>
                    {renderOption('name', 'Name')}
                    {renderOption('description', 'Description')}
                    {renderOption(
                      'subtitle',
                      'web.components.lane.ContentImporter.fieldsToImport.subtitle'
                    )}
                    {renderOption('tags', 'Tags')}
                    {renderOption('category', 'Category')}
                    {renderOption(
                      'logo',
                      'web.components.lane.ContentImporter.fieldsToImport.logo'
                    )}
                    {renderOption('backgroundImage', 'Background Image')}
                    {renderOption(
                      'color',
                      'web.components.lane.ContentImporter.fieldsToImport.textColor'
                    )}
                    {renderOption(
                      'backgroundColor',
                      'web.components.lane.ContentImporter.fieldsToImport.backgroundColor'
                    )}
                    {renderOption(
                      'icon',
                      'web.components.lane.ContentImporter.fieldsToImport.icon'
                    )}
                  </ul>
                </div>
              </div>

              {appearanceAvailable && (
                <>
                  <h2>
                    <span className={styles.step}>
                      {t(`${appearanceStep}`)}
                    </span>
                    {t('web.components.lane.ContentExporter.appearance.title')}
                  </h2>

                  <div className={styles.options}>
                    {options.appearance !== undefined && (
                      <div className={styles.option}>
                        <Toggle
                          value={options.appearance}
                          onChange={() =>
                            updateOptions({
                              appearance: !options.appearance,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </>
              )}

              {optionsAvailable && (
                <>
                  <h2>
                    <span className={styles.step}>{t(`${optionsStep}`)}</span>
                    {t(
                      'web.components.lane.ContentExporter.optionsToExport.title'
                    )}
                  </h2>

                  <div className={cx(styles.options, styles.row)}>
                    {options.placements !== undefined && (
                      <div className={styles.option}>
                        <h1>
                          {t(
                            'web.admin.serviceRequest.settings.tabs.targeting'
                          )}
                        </h1>
                        <Toggle
                          value={options.placements}
                          onChange={placements => updateOptions({ placements })}
                        />
                      </div>
                    )}

                    {options.data !== undefined && (
                      <div className={styles.option}>
                        <h1>{t('web.admin.content.editor.data.title')}</h1>
                        <Toggle
                          value={options.data}
                          onChange={data => updateOptions({ data })}
                        />
                      </div>
                    )}

                    {options.notifications !== undefined && (
                      <div className={styles.option}>
                        <h1>
                          {t(
                            'web.admin.visitorManagement.configuration.tab.notifications'
                          )}
                        </h1>
                        <Toggle
                          value={options.notifications}
                          onChange={notifications =>
                            updateOptions({ notifications })
                          }
                        />
                      </div>
                    )}

                    {options.workflows !== undefined && (
                      <div className={styles.option}>
                        <h1>
                          {t(
                            'shared.admin.channel.content.menuTabs.label.workflows'
                          )}
                        </h1>
                        <Toggle
                          value={options.workflows}
                          onChange={workflows => updateOptions({ workflows })}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}

              {featureCount > 0 && (
                <>
                  <h2>
                    <span className={styles.step}>{t(`${featureStep}`)}</span>
                    {t(
                      'web.components.lane.ContentExporter.featuresToExport.title'
                    )}
                  </h2>
                  <div className={styles.options}>
                    <div className={styles.option}>
                      {renderFeatureOptions()}
                    </div>
                  </div>
                </>
              )}

              {translationsAvailable && (
                <>
                  <h2>
                    <span className={styles.step}>
                      {t(`${translationsStep}`)}
                    </span>
                    {t(
                      'web.components.lane.ContentExporter.translations.title'
                    )}
                  </h2>

                  <div className={styles.options}>
                    {options.translations !== undefined && (
                      <div className={styles.option}>
                        <Toggle
                          value={options.translations}
                          onChange={() =>
                            updateOptions({
                              translations: !options.translations,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <ControlMenu className={styles.bottomMenu}>
          <hr />
          <Button onClick={onClose}>{t('Cancel')}</Button>

          <Button
            testId="buttonExport"
            variant="contained"
            onClick={() => {
              doExport();
              onClose();
            }}
          >
            {t('Export')}
          </Button>
        </ControlMenu>
      </ResizableWindow>
    </ModalBackground>
  );
}
