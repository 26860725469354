import React, { useContext, useEffect } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { colors } from 'constants-colors';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { convertTo62 } from 'uuid-encoding';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import useShopifyRsvpContentsQuery from 'lane-shared/hooks/integrations/shopify/useShopifyRsvpContentsQuery';
import useBurstAutoSaveAlert from 'lane-shared/hooks/useBurstAutoSaveAlert';
import { IntegrationProviderEnum } from 'constants-integrations';
import { ShopifyRsvpStatusEnum } from 'lane-shared/types/integrations/ShopifyRsvp';

import Loading from 'components/general/Loading';
import {
  HomeSections,
  BurstSection,
} from 'components/integrations/ShopifyBurst/pages';
import { SquarePin, UserContentInteractionCard } from 'components/lane';
import { Box, Flex } from 'components/layout';
import Alert, { AlertType } from 'components/lds/Alert';
import Typography from 'components/lds/Typography';
import { H3, H4 } from 'components/typography';

import styles from './Homepage.scss';

/**
 * Homepage for the Shopify Burst integration
 */
export default function Homepage() {
  const { t } = useTranslation();

  const {
    loading,
    burstWizardState,
    burstInteractions,
    burstIntegrationSettings,
    contentId,
    isTeamLead,
    refetchDraftBurstInteractions,
  } = useShopifyBurstContext();

  const { primaryChannel } = useContext(ChannelsContext);
  const { rsvpContentsWithInteractions } = useShopifyRsvpContentsQuery(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    primaryChannel?._id
  );
  const { showAlert, onFocus, onBlur } = useBurstAutoSaveAlert({
    burstWizardState,
    refetchDraftBurstInteractions,
  });

  useEffect(() => {
    onFocus();

    return () => onBlur();
  }, []);

  const noBurstsExist =
    !burstWizardState?.data &&
    burstInteractions.length === 0 &&
    rsvpContentsWithInteractions.length === 0;

  return (
    <div>
      <Box p={[7, 5, 5, 5]} className={styles.header}>
        <H3 mt={2}>Bursts</H3>
      </Box>
      {showAlert && (
        <Flex justify="center">
          <Alert type={AlertType.success} className={styles.alert}>
            {t(
              'Changes to {{burstName}} have been saved as a draft for 48 hours.',
              { burstName: burstWizardState?.data?.name }
            )}
          </Alert>
        </Flex>
      )}
      <Flex p={5} gap={4} justify="center">
        {HomeSections.map(section => {
          const isBurstCreate = section.name === BurstSection.name;

          if (isBurstCreate && !isTeamLead) {
            return null;
          }

          const toContent = isBurstCreate
            ? contentId
            : burstIntegrationSettings?.faqContent?._id;

          return (
            <Link
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              to={section?.route?.replace(':contentId', toContent)}
              key={section.name}
            >
              <SquarePin
                content={{
                  name: section.name,
                  icon: section.icon,
                  iconWeight: section.type,
                  iconSet: 'FontAwesome',
                  backgroundColor: colors.laneWhite,
                }}
                className={styles.squarePin}
              />
            </Link>
          );
        })}
      </Flex>
      {loading && !burstWizardState?.data && <Loading />}
      {!loading && noBurstsExist && (
        <Flex
          direction="column"
          justify="center"
          align="center"
          className={styles.noBurst}
        >
          <Icon
            className={styles.calendar}
            name="calendar-alt"
            type="fal"
            // @ts-expect-error ts-migrate(2322) FIXME: Type '"lg"' is not assignable to type '"small" | "... Remove this comment to see the full error message
            size="lg"
            set={ICON_SET_FONTAWESOME}
          />
          <Typography type="m" mode="primary">
            You don't have any Bursts at the moment
          </Typography>
        </Flex>
      )}
      {!loading && burstInteractions.length > 0 && (
        <Box p={6}>
          <H4 mb={2}>Created Bursts</H4>
          <Flex className={styles.burstList} gap={4}>
            {burstInteractions.map(interaction => {
              const burst =
                interaction.data[`_${IntegrationProviderEnum.ShopifyBurst}`];
              const interactionData = {
                ...interaction,
                contentData: {
                  ...interaction.contentData,
                  geo: null,
                  subtitle: burst.port?.name,
                  name: burst.name,
                },
                user: interaction._createdBy,
              };

              return (
                <Link
                  to={routes.interaction.replace(':id', interaction._id)}
                  key={interaction._id}
                >
                  <UserContentInteractionCard
                    className={styles.UCICard}
                    interaction={interactionData}
                  />
                </Link>
              );
            })}
          </Flex>
        </Box>
      )}

      {rsvpContentsWithInteractions.length > 0 && (
        <Box p={6}>
          <H4 mb={2}>RSVP</H4>
          <Flex className={styles.rsvpInteractions} gap={4}>
            {rsvpContentsWithInteractions.map((rsvp: any) => {
              const burst = rsvp.content?.state?._ShopifyBurst;

              const interactionData = {
                ...rsvp?.interaction,
                user: rsvp?.content?.publishedBy,
                contentData: {
                  ...rsvp?.interaction?.contentData,
                  geo: rsvp?.content?.geo,
                  name: burst?.name,
                  subtitle: burst.port?.name,
                },
                status: rsvp?.interaction
                  ? rsvp.interaction.status
                  : ShopifyRsvpStatusEnum.Awaiting,
                ...burst.dateRange,
              };

              return (
                <Link
                  to={routes.content.replace(
                    ':id',
                    convertTo62(rsvp.content._id)
                  )}
                  className={styles.link}
                  key={rsvp.content._id}
                >
                  <UserContentInteractionCard
                    className={styles.UCICard}
                    interaction={interactionData}
                    hideLocation
                  />
                </Link>
              );
            })}
          </Flex>
        </Box>
      )}
    </div>
  );
}
