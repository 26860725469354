import React, { useCallback } from 'react';

import { v4 as uuid } from 'uuid';

import type { Channel } from 'lane-shared/types/ChannelType';
import type { ContentWorkflowType } from 'lane-shared/types/ContentWorkflowType';

import { ContentTypeEnum } from 'constants-content';

import WorkflowBuilder from './WorkflowBuilder';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from 'constants-flags';
import { useFlag } from 'lane-shared/hooks';
import { Workflow } from 'packages/lane-shared/types/ContentWorkflowType';

type Content = {
  actions: ContentWorkflowType[];
  isInteractive: boolean;
  type: ContentTypeEnum;
};

export function ContentWorkflowBuilder({
  title,
  channel,
  content,
  timeZone,
  onAddWorkflow,
  onContentUpdated,
  onSaveContentClicked,
  className,
}: {
  title: string;
  channel: Channel;
  content: Content;
  timeZone: string;
  onAddWorkflow: () => ContentWorkflowType | void;
  onContentUpdated: (content: any) => void;
  onSaveContentClicked?: () => Promise<any>;
  className?: string;
}) {
  const { t } = useTranslation();

  const isNewWorkflowsUIEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportNewWorkflowsUI,
    false
  );

  const onWorkflowAdd = async () => await onAddWorkflow();

  const onWorkflowRemove = useCallback(
    (id: string) =>
      onContentUpdated({
        actions: content.actions.filter((n: any) => n._id !== id),
      }),
    [content.actions]
  );

  const onWorkflowUpdate = useCallback(
    (index: number, updatedWorkflow: Workflow) => {
      content.actions[index] = { ...(updatedWorkflow as ContentWorkflowType) };
      onContentUpdated({
        actions: [...content.actions],
      });
    },
    [content.actions]
  );

  const onWorkflowClone = useCallback(
    (id: string, workflowNickName?: string) => {
      // find the cloned workflow
      const workflow = content.actions.find(
        (workflow: any) => workflow._id === id
      );

      const nameOfClonedWorkflow = t(
        'web.admin.channel.content.workflow.editor.v2.workflow.duplicate.workflowName',
        {
          workflowName: workflow?.name ? workflow.name : workflowNickName,
        }
      );

      onContentUpdated({
        actions: [
          ...content.actions,
          {
            ...workflow,
            _id: uuid(),
            ...(isNewWorkflowsUIEnabled && { name: nameOfClonedWorkflow }),
          },
        ],
      });
    },
    [content.actions]
  );

  const onWorkflowReorder = useCallback(
    (reorderedWorkflows: Workflow[]) => {
      onContentUpdated({
        actions: reorderedWorkflows as ContentWorkflowType[],
      });
    },
    [onContentUpdated]
  );

  if (!content.isInteractive) {
    return null;
  }

  return (
    <WorkflowBuilder
      title={title}
      channel={channel}
      content={content}
      timeZone={timeZone}
      workflows={content.actions}
      onAddWorkflow={onWorkflowAdd}
      onRemoveWorkflow={onWorkflowRemove}
      onCloneWorkflow={onWorkflowClone}
      onReorderWorkflow={onWorkflowReorder}
      onUpdateWorkflow={onWorkflowUpdate}
      onSaveWorkflow={onSaveContentClicked}
      className={className}
    />
  );
}
