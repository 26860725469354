import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

const RealPageDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.RealPage,
  friendlyName: IntegrationProviderEnum.RealPage,
  description: 'Defines the properties required for RealPage integration.',
  category: IntegrationCategoryEnum.Accounting,
  platforms: [],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    pmcId: {
      friendlyName: 'PMC ID',
      type: 'String',
      description: 'PMC ID',
      validators: [{ name: 'Required', value: true }],
    },
    siteId: {
      friendlyName: 'Site ID',
      type: 'String',
      description: 'Site ID',
      validators: [{ name: 'Required', value: true }],
    },
    syncPastUsersEnabled: {
      friendlyName: 'Sync Past Residents',
      type: 'Boolean',
      default: false,
    },
    daysPostMoveOut: {
      friendlyName: 'Import Past Residents: Enter days post Move-Out Date',
      type: 'Number',
      default: undefined,
      validators: [],
    },
    workOrdersEnabled: {
      friendlyName: 'Work orders',
      type: 'Boolean',
      default: false,
    },
  },
  hasCustomUI: true,
};
export default RealPageDefinition;
