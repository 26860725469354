import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Flex, Label } from 'components';

import useTimeUnits, { TimeUnitEnum } from '../useTimeUnits';
import styles from '../WorkflowDetailsV2.scss';
import { prefixLabelsV2, whenContextLabelsV2 } from '../workflowLabels';
import { WorkflowStatus } from '../WorkflowStatus';
import { Workflow } from 'packages/lane-shared/types/ContentWorkflowType';

type Props = {
  workflow: Workflow;
  onWorkflowUpdated: (updates: Partial<Workflow>) => void;
  isEditing: boolean | undefined;
};

export function WorkflowEscalationConditions({
  workflow,
  onWorkflowUpdated,
  isEditing,
}: Props) {
  const { t } = useTranslation();

  const {
    timeUnit,
    timeValue,
    timeOptions,
    timeUnitOptions,
    updateTimeUnit,
    updateTimeUnitValue,
  } = useTimeUnits({
    when: workflow.when,
    time: workflow.time,
    onTimeUpdated: time => onWorkflowUpdated({ time }),
  });

  const indexOfMonth = timeUnitOptions.findIndex(
    timeUnitOption => timeUnitOption.value === TimeUnitEnum.Months
  );

  if (indexOfMonth !== -1) {
    timeUnitOptions.splice(indexOfMonth, 1);
  }

  return (
    <div>
      <Label className={styles.conditionsLabel}>
        {t('web.admin.channel.content.workflow.editor.v2.conditions.title')}
      </Label>
      <Flex className={styles.conditionsWrapper} align="center">
        {prefixLabelsV2[workflow.whenContext] && (
          <span>{t(prefixLabelsV2[workflow.whenContext])}</span>
        )}
        <WorkflowStatus
          workflow={workflow}
          onWorkflowUpdated={onWorkflowUpdated}
          isEditing={isEditing}
        />
        <span>{t(whenContextLabelsV2[workflow.whenContext])}</span>
        {timeOptions[timeUnit] &&
          timeOptions[timeUnit].some(option => option.value === timeValue) && (
            <>
              <Dropdown
                testId="workflowTimeDropdown"
                disabled={!isEditing}
                className={
                  isEditing
                    ? styles.smallDropdownInEditMode
                    : styles.smallDropdown
                }
                items={timeOptions[timeUnit]}
                value={timeValue}
                doTranslation={false}
                onValueChange={updateTimeUnitValue}
              />
              <Dropdown
                testId="workflowTimeUnitDropdown"
                className={
                  isEditing
                    ? styles.smallDropdownInEditMode
                    : styles.smallDropdown
                }
                disabled={!isEditing}
                doTranslation={false}
                items={timeUnitOptions.map(option => ({
                  value: option.value,
                  label: t(option.label),
                }))}
                value={timeUnit}
                onValueChange={updateTimeUnit}
              />
            </>
          )}
      </Flex>
    </div>
  );
}
