import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';
import './types';

const FCPBotDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.FCPBot,
  hasCustomUI: false,
  friendlyName: 'FCPBot Agility CMS',
  description: `The FCPBot is an agility CMS integration inherited from v4`,
  category: IntegrationCategoryEnum.Content,
  platforms: [],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    baseUrl: {
      friendlyName: 'Base URL',
      type: 'Url',
      default: 'https://myfirstcanadianplace.ca',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    mapApiUrl: {
      friendlyName: 'Map API URL',
      type: 'Url',
      default: 'http://myfirstcanadianplace.ca/api/map',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    storesApiUrl: {
      friendlyName: 'Stores API Url',
      type: 'Url',
      default: 'http://myfirstcanadianplace.ca/api/stores?e=true&all=true',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    links: {
      friendlyName: 'Lane / FCP ID Links',
      type: 'FCPBotLink',
      isArray: true,
      default: [],
    },
  },
  mobilePermissions: [],
};

export default FCPBotDefinition;
