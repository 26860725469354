import React, { useContext, useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { RendererContext } from 'lane-shared/contexts';
import { contentMenuTabLabels, ContentMenuTabsEnum } from 'constants-content';

import { menuTabsForContentType } from 'components/renderers/helpers/contentMenuTabs';

import ErrorMessage from 'components/general/ErrorMessage';
import { AlertType } from 'components/lds/Alert';

import { useQueryString } from '../../hooks';
import Button from '../general/Button';
import TabStrip from '../general/TabStrip';
import ContentRenderer from '../lane/ContentRenderer';
import { BlockBuilder } from './BlockBuilder';
import DataBuilder from './DataBuilder';
import { ContentWorkflowBuilder } from './ContentWorkflowBuilder';
import FeatureBuilder from './features/FeatureBuilder';
import getDependentFeatureWarnings from './helpers/getDependentFeatureWarnings';
import getMissingInputs from './helpers/getMissingInputs';
import getWorkflowWarnings from './helpers/getWorkflowWarnings';
import useCheckRelatedSpacesDiscrepancies from 'hooks/useCheckRelatedSpacesDiscrepancies';
import { ALL_SURVEY_QUESTIONS } from 'lane-shared/domains/surveys/constants/questions';

import styles from './Builder.scss';

function Builder({
  className,
  style,
  timeZone,
  channel,
  library,
  user,
  content,
  forTemplate,
  onContentUpdated,
  onContentUpgrade,
  onContentMigrate,
  onAddWorkflow,
  onSaveContentClicked,
  onDataValidation,
  stepperTracker,
}: any) {
  const { t } = useTranslation();
  const { blocks } = useContext(RendererContext);

  useEffect(() => {
    stepperTracker?.Editor(content);
  }, []);

  const { hasRelatedSpacesDiscrepancies } =
    useCheckRelatedSpacesDiscrepancies(content);

  const [query, goToUrl] = useQueryString({ menu: ContentMenuTabsEnum.Layout });

  if (content.renderer < 5) {
    return (
      <div className={cx(styles.Builder, className)} style={style}>
        <div className={styles.legacy}>
          <p>{t('web.admin.content.builder.legacy.warning')}</p>

          <Button onClick={onContentUpgrade} className={styles.updateButton}>
            {t('web.admin.content.builder.legacy.button.create')}
          </Button>

          <Button
            onClick={() => onContentMigrate(content)}
            className={styles.updateButton}
          >
            {t('web.admin.content.builder.legacy.button.migrate')}
          </Button>

          {content.block && (
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'forAdmin' is missing in type '{ content:... Remove this comment to see the full error message
            <ContentRenderer content={content} interaction={{}} />
          )}
        </div>
      </div>
    );
  }

  if (!content.block?._id) {
    return (
      <div className={styles.Builder}>
        <div className={styles.legacy}>
          <p>{t('web.admin.content.builder.legacy.noProfile.warning')}</p>

          <Button
            onClick={() => onContentUpgrade(content)}
            className={styles.updateButton}
          >
            {t('web.admin.content.builder.legacy.noProfile.button.create')}
          </Button>
        </div>
      </div>
    );
  }

  const missingInputs = content.isInteractive
    ? getMissingInputs(content, blocks, channel)
    : [];

  const dependentFeatureWarnings = content.isInteractive
    ? getDependentFeatureWarnings(content.features, t)
    : [];

  const dependentWorkflowWarnings = getWorkflowWarnings(
    content.actions,
    content.startDate,
    content.endDate
  );

  const shouldDisplayWarnings =
    missingInputs.length ||
    dependentFeatureWarnings.length ||
    dependentWorkflowWarnings.length ||
    hasRelatedSpacesDiscrepancies;

  const menuTabs = menuTabsForContentType(
    content.type,
    content.isInteractive,
    forTemplate
  );

  return (
    <div className={cx(styles.Builder, className)} style={style}>
      {shouldDisplayWarnings ? (
        <div className={styles.errors}>
          <ErrorMessage
            error={[
              ...missingInputs.map((field: any) => {
                const fieldName = t(field.friendlyName || field.name);

                const missingLayoutKey = ALL_SURVEY_QUESTIONS.includes(
                  fieldName
                )
                  ? 'warning'
                  : 'error';

                return t(
                  `web.admin.content.builder.${missingLayoutKey}.missingLayout`,
                  {
                    fieldName,
                  }
                );
              }),
              ...dependentFeatureWarnings,
              ...dependentWorkflowWarnings,
              hasRelatedSpacesDiscrepancies &&
                t('web.admin.content.builder.error.dependencyWarning'),
            ]}
            color={AlertType.warning}
          />
        </div>
      ) : null}
      <TabStrip
        tabs={menuTabs.map(menuTab => ({
          label: contentMenuTabLabels[menuTab],
          value: menuTab,
        }))}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number | boolean | Date | (string |... Remove this comment to see the full error message
        selected={{ value: query.menu }}
        onSelectTab={menu => goToUrl({ menu: menu.value })}
      />

      <section>
        {query.menu === ContentMenuTabsEnum.Data && (
          <DataBuilder
            channel={channel}
            user={user}
            content={content}
            onContentUpdated={onContentUpdated}
          />
        )}
        {query.menu === ContentMenuTabsEnum.Features && (
          <FeatureBuilder
            timeZone={timeZone}
            channel={channel}
            user={user}
            content={content}
            onContentUpdated={onContentUpdated}
          />
        )}
        {query.menu === ContentMenuTabsEnum.Layout && (
          <BlockBuilder
            channel={channel}
            library={library}
            user={user}
            content={content}
            onContentUpdated={onContentUpdated}
            forTemplate={forTemplate}
            onDataValidation={onDataValidation}
          />
        )}
        {query.menu === ContentMenuTabsEnum.Workflows && (
          <ContentWorkflowBuilder
            title={t('web.admin.channel.content.workflow.add.title')}
            timeZone={timeZone}
            content={content}
            channel={channel}
            onContentUpdated={onContentUpdated}
            onAddWorkflow={onAddWorkflow}
            onSaveContentClicked={onSaveContentClicked}
          />
        )}
      </section>
    </div>
  );
}

export default Builder;
