import gql from 'graphql-tag';

import {
  ProfileFragment,
  AddressFragment,
  ChannelFragment,
} from '../fragments';

import { Channel } from '../../types/ChannelType';

export type InitializeChannelsResult = {
  me: {
    channels: Channel[];
  };
};

export default gql`
  ${ProfileFragment}
  ${AddressFragment}
  ${ChannelFragment}

  query initializeChannels {
    me {
      channels {
        ...ChannelFragment
      }
    }
  }
`;
