import { actionLabels, actionLabelsV2 } from './workflowLabels';
import { WorkflowActionEnum, WorkflowTypeEnum } from 'constants-workflows';
import { ContentTypeEnum } from 'constants-content';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type OptionType = {
  value: WorkflowActionEnum;
  label: string;
};

type Props = {
  contentType?: ContentTypeEnum;
  workflowType?: WorkflowTypeEnum;
  isNewWorkflowsUIEnabled?: boolean;
};

export default function useWorkflowActionOptions({
  contentType,
  workflowType,
  isNewWorkflowsUIEnabled,
}: Props) {
  const { t } = useTranslation();

  return useMemo(() => {
    let options: OptionType[] = [
      {
        value: WorkflowActionEnum.Notification,
        label: isNewWorkflowsUIEnabled
          ? t(actionLabelsV2[WorkflowActionEnum.Notification])
          : t(actionLabels[WorkflowActionEnum.Notification]),
      },
      {
        value: WorkflowActionEnum.Email,
        label: isNewWorkflowsUIEnabled
          ? t(actionLabelsV2[WorkflowActionEnum.Email])
          : t(actionLabels[WorkflowActionEnum.Email]),
      },
      {
        value: WorkflowActionEnum.SMS,
        label: isNewWorkflowsUIEnabled
          ? t(actionLabelsV2[WorkflowActionEnum.SMS])
          : t(actionLabels[WorkflowActionEnum.SMS]),
      },
      {
        value: WorkflowActionEnum.Webhook,
        label: isNewWorkflowsUIEnabled
          ? t(actionLabelsV2[WorkflowActionEnum.Webhook])
          : t(actionLabels[WorkflowActionEnum.Webhook]),
      },
    ];

    if (contentType === ContentTypeEnum.WorkOrder) {
      options.filter(option => option.value !== WorkflowActionEnum.Email);
    }

    if (
      workflowType === WorkflowTypeEnum.ServiceRequest ||
      workflowType === WorkflowTypeEnum.Task ||
      workflowType === WorkflowTypeEnum.ServiceRequestEscalation
    ) {
      // filter out everything but Email and Notification
      options = options.filter(
        option =>
          option.value === WorkflowActionEnum.Email ||
          option.value === WorkflowActionEnum.Notification
      );
      options.push({
        value: WorkflowActionEnum.NotificationAndEmail,
        label: isNewWorkflowsUIEnabled
          ? t(actionLabelsV2[WorkflowActionEnum.NotificationAndEmail])
          : t(actionLabels[WorkflowActionEnum.NotificationAndEmail]),
      });
    }

    return options;
  }, []);
}
