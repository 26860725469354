export const HEADER_WHITELABEL = 'X-Lane-Instance';
export const HEADER_GEO_LOCATION = 'X-Geo-Location';
export const HEADER_PLATFORM = 'X-Platform';
export const HEADER_PRIMARY_CHANNEL_ID = 'X-Primary-Channel-Id';
export const HEADER_CLIENT_VERSION = 'X-Client-Version';
export const HEADER_OPTION_OTHER = 'other';

/**
 * If you add a new header, remember to allow it in the cors config file.
 */

export const HEADER_PLATFORM_OPTIONS = [
  'web',
  'mobile',
  'tablet',
  'api',
  HEADER_OPTION_OTHER,
] as const;

export const [
  HEADER_PLATFORM_OPTION_WEB,
  HEADER_PLATFORM_OPTION_MOBILE,
  HEADER_PLATFORM_OPTION_TABLET,
  HEADER_PLATFORM_OPTION_API,
] = HEADER_PLATFORM_OPTIONS;

export const HEADER_DEVICE = 'X-Device';
export const HEADER_DEVICE_OPTIONS = [
  'ios',
  'android',
  'ie',
  'firefox',
  'chrome',
  'safari',
  HEADER_OPTION_OTHER,
] as const;

export const [
  HEADER_DEVICE_OPTION_IOS,
  HEADER_DEVICE_OPTION_ANDROID,
  HEADER_DEVICE_OPTION_IE,
  HEADER_DEVICE_OPTION_FIREFOX,
  HEADER_DEVICE_OPTION_CHROME,
  HEADER_DEVICE_OPTION_SAFARI,
] = HEADER_DEVICE_OPTIONS;

export const HEADER_VERSION = 'X-OS-Version';

export enum ExternalUrlOpeningModeEnum {
  InApp = 'InApp',
  InAppWithSso = 'InAppWithSso',
  External = 'External',
  Ritual = 'Ritual',
}
