import React, { useContext, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import ChannelsContext from 'lane-shared/contexts/ChannelsContext';
import { updateUserContentInteraction } from 'lane-shared/graphql/mutation';
import { commonInteractionQueries } from 'lane-shared/helpers/constants';
import {
  INTERACTION_CANCELLED,
  INTERACTION_CLOSED_STATES,
} from 'constants-interactions';
import { PROXYCLICK_VISITOR_STATUS } from 'lane-shared/helpers/constants/proxyclick';
import { parseDateTime } from 'lane-shared/helpers/dates';
import { shortDateTime, shortTime } from 'lane-shared/helpers/formatters';
import { IntegrationProviderEnum } from 'constants-integrations';

import history from '../../../../helpers/history';
import Button from '../../../general/Button';
import Image from '../../../general/Image';

import buttonStyle from '../../../general/Button.scss';
import styles from './MeetingDetails.scss';

function getStatusMessage(proxyclickStatus: any) {
  switch (proxyclickStatus) {
    case PROXYCLICK_VISITOR_STATUS.CHECK_IN: {
      return 'checked in';
    }

    case PROXYCLICK_VISITOR_STATUS.CHECK_OUT: {
      return 'checked out';
    }

    case PROXYCLICK_VISITOR_STATUS.CANCELLED: {
      return 'cancelled';
    }

    case PROXYCLICK_VISITOR_STATUS.CLOSED: {
      return 'closed';
    }

    default: {
      return 'upcoming';
    }
  }
}

export default function MeetingDetails({ interaction }: any) {
  const { t } = useTranslation();
  const meeting = interaction.data[`_${IntegrationProviderEnum.ProxyClick}`];
  const interactionId = interaction._id;
  const { host, company } = meeting;
  // We do this because there is currently an issue where the data is duplicated
  // across different keys (interaction.data.visitors and interaction.data.proxyclick.visitors)
  const visitors = interaction.data.visitors || meeting.visitors;
  const [loading, setLoading] = useState(false);
  const { primaryId } = useContext(ChannelsContext);

  const isMeetingDone =
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    parseDateTime(interaction.endDate) < Date.now() ||
    INTERACTION_CLOSED_STATES.includes(interaction.status);

  async function cancelMeeting() {
    try {
      await window.Alert.confirm({
        title: t('Cancel Meeting'),
        message: t('Are you sure you want to delete this meeting?'),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      return;
    }

    try {
      setLoading(true);

      await getClient().mutate({
        refetchQueries: commonInteractionQueries,
        mutation: updateUserContentInteraction,
        variables: {
          interaction: {
            _id: interactionId,
            status: INTERACTION_CANCELLED,
          },
          meChannelId: primaryId,
        },
      });
      // TODO: better solution (in case comes to the page by pasting url in browser)
      history.goBack();
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // mutation failed
      await window.Alert.alert({
        title: t(
          'Your meeting could not be cancelled. Please try again later.'
        ),
      });
    }

    setLoading(false);
  }

  return (
    <div className={styles.proxyClick}>
      <div className={styles.info}>
        <h2>{meeting.title}</h2>
        <h3>{interaction.status}</h3>
        <p>
          {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1. */}
          {shortDateTime(meeting.startAt)} - {shortTime(meeting.endAt)}
        </p>
        <p>{meeting.description}</p>
      </div>
      <div className={styles.visitors}>
        <h3>{t('Visitors')}</h3>
        {visitors.map((visitor: any) => (
          <div className={styles.visitor} key={visitor.id}>
            <Image
              className={styles.image}
              src={visitor.picture64}
              alt="visitor-picture"
            />
            <p>
              {visitor.firstName} {visitor.lastName}
            </p>
            <span className={cx(styles.visitorStatus)}>
              {t(getStatusMessage(interaction.status))}
            </span>
          </div>
        ))}
      </div>
      <div className={styles.host}>
        <Image
          className={styles.image}
          src={host.picture64}
          alt="host-picture"
        />
        <div className={styles.text}>
          <h3>{t('Host')}</h3>
          <p>
            {host.firstName} {host.lastName}
          </p>
          <p>{host.email}</p>
        </div>
      </div>
      <hr />
      {company && (
        <div className={styles.host}>
          <Image
            className={styles.image}
            src={company.logoOriginal}
            alt="logo-company"
          />
          <div className={styles.text}>
            <h3>{t('Company')}</h3>
            <p>{company.name}</p>
          </div>
        </div>
      )}

      {!isMeetingDone && (
        <>
          <div className={styles.buttons}>
            <Link
              className={buttonStyle.Button}
              key={meeting._id}
              to={{
                pathname: routes.interactionEdit.replace(':id', interactionId),
              }}
            >
              <Icon name="edit" />
              {t('Edit Meeting')}
            </Link>
          </div>
          <div className={styles.buttons}>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: (TFunctionResult | Element)[]; l... Remove this comment to see the full error message */}
            <Button loading={loading} warning onClick={cancelMeeting}>
              <Icon name="trash-alt" />
              {t('Cancel Meeting')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
