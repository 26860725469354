import React, { useEffect, useState } from 'react';

import { H3, Icon } from 'design-system-web';
import cx from 'classnames';
import currencyCodes from 'currency-codes';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { toSchema } from 'lane-shared/helpers';
import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { explodeFeatures } from 'lane-shared/helpers/features';
import Option from 'lane-shared/properties/baseTypes/Option';
import PaymentFeaturePaymentAmount, {
  PAYMENT_FEATURE_HIGHEST_AMOUNT,
} from 'lane-shared/renderers/v5/features/types/PaymentFeaturePaymentAmount';
import PaymentFeatureRule from 'lane-shared/renderers/v5/features/types/PaymentFeatureRule';
import PriceTimeRange from 'lane-shared/renderers/v5/features/types/PriceTimeRange';
import { PaymentFeatureProperties } from 'lane-shared/types/features/PaymentFeatureProperties';
import { PaymentTypeEnum } from 'constants-payments';

import CurrencyInput from 'components/form/CurrencyInput';
import Dropdown from 'components/form/Dropdown';
import Input from 'components/form/Input';
import Toggle from 'components/form/Toggle';
import ErrorMessage from 'components/general/ErrorMessage';
import Label from 'components/general/Label';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';

import { FeatureRendererPropsType } from './FeatureRendererPropsType';
import WeeklyPriceTimeRange from './components/WeeklyPriceTimeRange';

import styles from './Payment.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { PaymentV2Description } from './PaymentV2/PaymentV2Description';
import { PaymentV2, Subscription } from './PaymentV2';
import {
  AddProductToContentDetails,
  Product,
  TaxPolicy,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { useChannelAdminContext } from 'hooks';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import {
  useBillingPaymentSettingsForChannel,
  useListProductsByProductIdsQuery,
} from 'lane-shared/domains/billingPayments/hooks';
import useUserLocale from 'hooks/useUserLocale';

const TRANSLATION_KEYS = {
  paymentAccounts: 'web.content.features.payment.paymentAccounts',
  paymentAmountError: 'web.content.features.payment.paymentAmount.error',
  paymentTypePlaceholder:
    'web.content.features.payment.paymentType.placeholder',
  currency: 'web.content.features.payment.currency',
  currencyPlaceholder: 'web.content.features.payment.currencyPlaceholder',
  amount: 'web.content.features.payment.amount',
  tax: 'web.content.features.payment.tax',
  advancedTules: 'web.content.features.payment.advancedTules',
  canOverridePrice: 'web.content.features.payment.canOverridePrice',
  canOverridePriceDescription:
    'web.content.features.payment.canOverridePriceDescription',
  hours: 'web.content.features.payment.hours',
  hoursDescription: 'web.content.features.payment.hoursDescription',
  ratio: 'web.content.features.payment.ratio',
  ratioDescription: 'web.content.features.payment.ratioDescription',
  team: 'web.content.features.payment.team',
  type: 'web.content.features.payment.type',
  amountPlaceholder: 'web.content.features.payment.amountPlaceholder',
  percentagePlaceholder: 'web.content.features.payment.percentagePlaceholder',
  taxPlaceholder: 'web.content.features.payment.taxPlaceholder',
};

const currencies = currencyCodes
  .codes()
  .map(value => ({ label: value, value }));

function Payment({
  className,
  style,
  feature,
  content,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<PaymentFeatureProperties>) {
  const paymentFeature: PaymentFeatureProperties | null =
    contentFeature?.feature;

  const { t } = useTranslation();

  const { essensysProductFeature, reservableFeature } = explodeFeatures(
    content.features
  );

  const taxes = paymentFeature?.taxes || [];
  const rules = paymentFeature?.rules || [];
  const weekTimeRules = paymentFeature?.weekTimeRules || {};
  const amounts = paymentFeature?.amounts || [];
  const productIds = paymentFeature?.productIds || [];

  const { channel } = useChannelAdminContext();

  const { data } = useListProductsByProductIdsQuery({
    page: 0,
    pageSize: 100,
    productIds,
    channel,
  });

  const [listOfProducts, setListOfProducts] = useState<
    AddProductToContentDetails[]
  >([]);

  const locale = useUserLocale();
  const { currency, taxDetails } = useBillingPaymentSettingsForChannel({
    channelId: channel?._id,
  });
  const inclusivePricing = taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE;

  useEffect(() => {
    if (data) {
      const products = data?.listProducts?.products;

      if (products) {
        const listOfSavedProducts = products.map(product => {
          const priceComponents = productToPriceComponents(
            product as unknown as Product
          );
          // eslint-disable-next-line prefer-const
          let { netPrice, listPrice, grossTax, rate } = getPricing({
            currency: currencyToPaymentCurrency(currency),
            locale,
            priceComponents,
          });

          if (inclusivePricing) {
            listPrice += grossTax;
          }

          return {
            productId: product.id,
            productCategoryId: product.productCategory?.productCategoryId,
            productCategoryType: product.productCategory?.productType,
            productCategoryName: product.productCategory?.name,
            productGroupId: product.groupId,
            name: product.name,
            description: product.description,
            rate: rate.toString(),
            markup: product?.markup?.value
              ? {
                  value: product.markup.value.toString(),
                  type: product.markup.markupAmountType,
                }
              : undefined,
            tax: product?.tax?.value
              ? {
                  value: product.tax.value.toString(),
                  type: product.tax.taxAmountType,
                }
              : undefined,
            listPrice: listPrice.toString(),
            grossTax: grossTax.toString(),
            netPrice: netPrice.toString(),
            total: netPrice.toString(),
            creditAmount: product?.creditAmount?.toString(),
          } as AddProductToContentDetails;
        });

        setListOfProducts(listOfSavedProducts);
      }
    }
  }, [currency, data?.listProducts?.products, inclusivePricing, locale]);

  const onProductAddition = (product: AddProductToContentDetails) => {
    setListOfProducts([...listOfProducts, product]);
    paymentFeature.productIds = [
      ...(paymentFeature.productIds ?? []),
      product.productId,
    ];
    onFeatureUpdated({
      productIds: paymentFeature.productIds,
    });
  };

  const onProductRemoval = async (product: AddProductToContentDetails) => {
    setListOfProducts([...listOfProducts.filter(p => p !== product)]);
    paymentFeature.productIds = [
      ...(paymentFeature.productIds ?? []).filter(p => p !== product.productId),
    ];
    onFeatureUpdated({
      productIds: paymentFeature.productIds,
    });
  };

  function onChangeTaxField({ index, key, value }: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    contentFeature.feature.taxes[index][key] = value;

    onFeatureUpdated({
      taxes: contentFeature.feature.taxes,
    });
  }

  function onAddTaxRow() {
    onFeatureUpdated({
      taxes: [...taxes, { ...Option.default, _id: uuid() }],
    });
  }

  function onRemoveTaxRow({ index }: any) {
    onFeatureUpdated({
      taxes: taxes.filter((v, ix) => ix !== index),
    });
  }

  // Time Rule Stuff
  function onToggleOpen({ dayKey, value }: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    weekTimeRules[dayKey].isOpen = value;

    onFeatureUpdated({
      weekTimeRules: { ...weekTimeRules },
    });
  }

  function onTimeRangeSelect({ value, dayKey, index }: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    weekTimeRules[dayKey].priceTimeRanges[index] = {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ...weekTimeRules[dayKey].priceTimeRanges[index],
      ...value,
    };

    onFeatureUpdated({
      weekTimeRules: { ...weekTimeRules },
    });
  }

  function onChangePriceRatio({ value, dayKey, index }: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    weekTimeRules[dayKey].priceTimeRanges[index].priceRatio = value;

    onFeatureUpdated({
      weekTimeRules: { ...weekTimeRules },
    });
  }

  function onAddTimeRange({ dayKey }: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    weekTimeRules[dayKey].priceTimeRanges.push({
      ...PriceTimeRange.default,
      _id: uuid(),
    });

    onFeatureUpdated({
      weekTimeRules: { ...weekTimeRules },
    });
  }

  function onRemoveTimeRange({ dayKey, removeIndex }: any) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    weekTimeRules[dayKey].priceTimeRanges = weekTimeRules[
      dayKey
    ].priceTimeRanges.filter((v: any, ix: any) => ix !== removeIndex);

    onFeatureUpdated({
      weekTimeRules: { ...weekTimeRules },
    });
  }

  // payment amounts stuff
  function onAddPaymentAmount() {
    onFeatureUpdated({
      amounts: [...amounts, PaymentFeaturePaymentAmount.default],
    });
  }

  function onRemovePaymentAmount(index: any) {
    onFeatureUpdated({
      amounts: amounts.filter((v, ix) => ix !== index),
    });
  }

  function onUpdateAmount(ix: any, props: any) {
    amounts[ix] = { ...amounts[ix], ...props };

    onFeatureUpdated({
      amounts: [...amounts],
    });
  }

  // Rules stuff
  function onAddRule() {
    onFeatureUpdated({
      rules: [...rules, { ...PaymentFeatureRule.default, _id: uuid() }],
    });
  }

  function onRemoveRule(index: any) {
    onFeatureUpdated({
      rules: rules.filter((v, ix) => ix !== index),
    });
  }

  function onRuleFieldChange({ value, key, index }: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    rules[index][key] = value;

    onFeatureUpdated({
      rules: [...rules],
    });
  }

  function onRuleToggleOpen(ruleIndex: any) {
    return ({ dayKey, value }: any) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      rules[ruleIndex].weekTimeRules[dayKey].isOpen = value;

      onFeatureUpdated({
        rules: [...rules],
      });
    };
  }

  function onRuleTimeRangeSelect(ruleIndex: any) {
    return ({ value, dayKey, index }: any) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      rules[ruleIndex].weekTimeRules[dayKey].priceTimeRanges[index].startTime =
        value.startTime;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      rules[ruleIndex].weekTimeRules[dayKey].priceTimeRanges[index].endTime =
        value.endTime;

      onFeatureUpdated({
        rules: [...rules],
      });
    };
  }

  function onRuleChangePriceRatio(ruleIndex: any) {
    return ({ value, dayKey, index }: any) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      rules[ruleIndex].weekTimeRules[dayKey].priceTimeRanges[index].priceRatio =
        value;

      onFeatureUpdated({
        rules: [...rules],
      });
    };
  }

  function onRuleAddTimeRange(ruleIndex: any) {
    return ({ dayKey }: any) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      rules[ruleIndex].weekTimeRules[dayKey].priceTimeRanges = [
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        ...rules[ruleIndex].weekTimeRules[dayKey].priceTimeRanges,
        { ...PriceTimeRange.default, _id: uuid() },
      ];

      onFeatureUpdated({
        rules: [...rules],
      });
    };
  }

  function onRuleRemoveTimeRange(ruleIndex: any) {
    return ({ dayKey, removeIndex }: any) => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      rules[ruleIndex].weekTimeRules[dayKey].priceTimeRanges = rules[
        ruleIndex
      ].weekTimeRules[dayKey].priceTimeRanges.filter(
        (v: any, ix: any) => ix !== removeIndex
      );

      onFeatureUpdated({
        rules: [...rules],
      });
    };
  }

  // Only one type of each payment amount is allowed
  const paymentAmountError = amounts.some(amountA =>
    amounts.some(
      amountB => amountA !== amountB && amountA.type === amountB.type
    )
  );

  // only show Essensys Credits if the essensys feature is enabled.
  const paymentAmountItems = Object.values(PaymentTypeEnum)
    .filter(value => {
      if (value === PaymentTypeEnum.EssensysCredits) {
        return !!essensysProductFeature;
      }

      if (value === PaymentTypeEnum.MoneyMovement) {
        return false;
      }

      return true;
    })
    .map(toSchema);

  const ProductsInContent = useFlag(FeatureFlag.ProductsInContent, false);
  const subscriptionsInContentFlag = useFlag(
    FeatureFlag.SubscriptionsInContent,
    false
  );
  const isSubscriptionsInContentEnabled =
    subscriptionsInContentFlag && !reservableFeature;

  // if subscriptions had been toggled on and then reservations are added or the flag is turned off it should disable the subscription feature
  if (
    paymentFeature.subscriptionEnabled &&
    (reservableFeature || !isSubscriptionsInContentEnabled)
  ) {
    onFeatureUpdated({
      subscriptionEnabled: false,
    });
  }

  return (
    <div className={cx(styles.Payment, className)} style={style}>
      {ProductsInContent ? (
        <H3>{t(feature.friendlyName)}</H3>
      ) : (
        <Label h1>{t(feature.friendlyName)}</Label>
      )}
      {ProductsInContent ? (
        <PaymentV2Description />
      ) : (
        <p>{t(feature.description)}</p>
      )}
      {contentFeature && (
        <div className={styles.paymentContainer}>
          {ProductsInContent ? (
            <>
              {paymentAmountError && (
                <ErrorMessage error={t(TRANSLATION_KEYS.paymentAmountError)} />
              )}
              <PaymentV2
                listOfProducts={listOfProducts}
                handleProductAddition={onProductAddition}
                handleProductRemoval={onProductRemoval}
                inclusivePricing={inclusivePricing}
              />
              {isSubscriptionsInContentEnabled && (
                <Subscription
                  paymentFeature={paymentFeature}
                  onFeatureUpdated={onFeatureUpdated}
                />
              )}
            </>
          ) : (
            <>
              {/* Default availability */}
              <Label h2>
                {t(TRANSLATION_KEYS.paymentAccounts)}
                <Icon
                  name="plus-circle"
                  set={ICON_SET_FONTAWESOME}
                  className={styles.paymentAddIcon}
                  onClick={onAddPaymentAmount}
                />
              </Label>
              {paymentAmountError && (
                <ErrorMessage error={t(TRANSLATION_KEYS.paymentAmountError)} />
              )}

              {amounts.map((amount, i) => (
                <div className={styles.paymentAmount} key={amount._id}>
                  <div className={styles.inputInline}>
                    <Label className={styles.inputLabel}>
                      {t(TRANSLATION_KEYS.type)}
                    </Label>
                    <Dropdown
                      className={styles.longDropdown}
                      onValueChange={type => onUpdateAmount(i, { type })}
                      items={paymentAmountItems}
                      initialValue={amount.type}
                      isSearchable
                      placeholder={t(TRANSLATION_KEYS.paymentTypePlaceholder)}
                      value={amount.type}
                    />
                  </div>

                  {amount.type === PaymentTypeEnum.Cash && (
                    <div className={styles.inputInline}>
                      <Label className={styles.inputLabel}>
                        {t(TRANSLATION_KEYS.currency)}
                      </Label>
                      <Dropdown
                        className={styles.longDropdown}
                        placeholder={t(TRANSLATION_KEYS.currencyPlaceholder)}
                        onValueChange={currency =>
                          onUpdateAmount(i, { currency })
                        }
                        value={amount.currency}
                        items={currencies}
                      />
                    </div>
                  )}

                  <div className={styles.inputInline}>
                    <Label className={styles.inputLabel}>
                      {t(TRANSLATION_KEYS.amount)}
                    </Label>
                    {amount.type === PaymentTypeEnum.Cash && (
                      <CurrencyInput
                        currency={amount.currency}
                        value={amount.amount}
                        placeholder={t(TRANSLATION_KEYS.amount)}
                        min={0}
                        max={PAYMENT_FEATURE_HIGHEST_AMOUNT}
                        onValueChange={amount => onUpdateAmount(i, { amount })}
                      />
                    )}
                    {amount.type === PaymentTypeEnum.EssensysCredits && (
                      <Input
                        value={amount.amount / CURRENCY_CONVERSION}
                        type="number"
                        placeholder={t(TRANSLATION_KEYS.percentagePlaceholder)}
                        onChange={amount =>
                          onUpdateAmount(i, { amount: Number(amount) * 100 })
                        }
                      />
                    )}
                    {amount.type !== PaymentTypeEnum.Cash &&
                      amount.type !== PaymentTypeEnum.EssensysCredits && (
                        <Input
                          value={amount.amount}
                          type="number"
                          placeholder={t(
                            TRANSLATION_KEYS.percentagePlaceholder
                          )}
                          onChange={amount => onUpdateAmount(i, { amount })}
                        />
                      )}
                    {i !== 0 && (
                      <Icon
                        name="times-circle"
                        set={ICON_SET_FONTAWESOME}
                        className={styles.removeIcon}
                        onClick={() => onRemovePaymentAmount(i)}
                      />
                    )}
                  </div>
                </div>
              ))}

              <div className={styles.inputRow}>
                <div
                  className={styles.inputInline}
                  style={{ alignItems: 'flex-start' }}
                >
                  <Label className={styles.inputLabel}>
                    {t(TRANSLATION_KEYS.tax)}
                  </Label>
                  <div>
                    {taxes.length === 0 && (
                      <div className={styles.taxRow}>
                        <Icon
                          name="plus-circle"
                          set={ICON_SET_FONTAWESOME}
                          className={styles.taxAddIcon}
                          onClick={() => onAddTaxRow()}
                        />
                      </div>
                    )}

                    {taxes.map((tax, index) => (
                      <div key={tax._id} className={styles.taxRow}>
                        <Input
                          value={tax.name}
                          fieldName="name"
                          placeholder={t(TRANSLATION_KEYS.taxPlaceholder)}
                          className={styles.taxName}
                          onChange={(value, key) =>
                            onChangeTaxField({ index, key, value })
                          }
                        />
                        <Input
                          value={String(tax.value)}
                          fieldName="value"
                          type="number"
                          icon="Percent"
                          iconRight
                          placeholder={t(
                            TRANSLATION_KEYS.percentagePlaceholder
                          )}
                          className={cx(styles.taxValue, styles.percent)}
                          onChange={(value, key) =>
                            onChangeTaxField({ index, key, value })
                          }
                        />
                        <Icon
                          name="times-circle"
                          set={ICON_SET_FONTAWESOME}
                          className={styles.removeIcon}
                          onClick={() => onRemoveTaxRow({ index })}
                        />
                        {index === taxes.length - 1 && (
                          <Icon
                            name="plus-circle"
                            set={ICON_SET_FONTAWESOME}
                            className={styles.taxAddIcon}
                            onClick={() => onAddTaxRow()}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Hours */}
          <div className={styles.inputRow} style={{ alignItems: 'flex-start' }}>
            <Label className={styles.inputLabel}>
              {t(TRANSLATION_KEYS.hours)}
            </Label>
            <WeeklyPriceTimeRange
              baseAmount={(contentFeature.feature as any).amount}
              weekTimeRules={contentFeature.feature.weekTimeRules}
              weekTimeRulesEnabled={contentFeature.feature.weekTimeRulesEnabled}
              onAddTimeRange={onAddTimeRange}
              onRemoveTimeRange={onRemoveTimeRange}
              onTimeRangeSelect={onTimeRangeSelect}
              onChangePriceRatio={onChangePriceRatio}
              onToggleOpen={onToggleOpen}
              onToggleHours={weekTimeRulesEnabled =>
                onFeatureUpdated({ weekTimeRulesEnabled })
              }
            />
          </div>

          <hr style={{ margin: '1em 0' }} />

          {/* Additional rules per groupRole */}
          <Label>
            <span style={{ lineHeight: '1em' }}>
              {t(TRANSLATION_KEYS.advancedTules)}
            </span>
            <Icon
              name="plus-circle"
              set={ICON_SET_FONTAWESOME}
              className={styles.addIcon}
              onClick={onAddRule}
            />
          </Label>
          <div className={styles.paymentRulesContainer}>
            {rules.map((rule, index) => (
              <div key={(rule as any)._id} className={styles.paymentRule}>
                <div className={styles.inputRow}>
                  <Label className={styles.inputLabel}>
                    {t(TRANSLATION_KEYS.team)}
                  </Label>
                  <GroupRoleSelector
                    className={styles.groupRoleSelector}
                    groupRoleId={rule.groupRole?._id}
                    channelId={content?.channel?._id}
                    onGroupRoleSelected={groupRole =>
                      onRuleFieldChange({
                        value: groupRole ? { _id: groupRole._id } : null,
                        key: 'groupRole',
                        index,
                      })
                    }
                  />
                </div>
                <div className={styles.inputRow}>
                  <Label
                    className={styles.inputLabel}
                    TooltipComponent={t(
                      TRANSLATION_KEYS.canOverridePriceDescription
                    )}
                  >
                    <span>{t(TRANSLATION_KEYS.canOverridePrice)}</span>
                  </Label>
                  <Toggle
                    value={rule.canOverridePrice}
                    onChange={() =>
                      onRuleFieldChange({
                        value: !rule.canOverridePrice,
                        key: 'canOverridePrice',
                        index,
                      })
                    }
                  />
                </div>
                <div className={styles.inputRow}>
                  <Label
                    className={styles.inputLabel}
                    TooltipComponent={
                      <p>{t(TRANSLATION_KEYS.ratioDescription)}</p>
                    }
                  >
                    <span>{t(TRANSLATION_KEYS.ratio)}</span>
                  </Label>
                  <Input
                    value={rules[index]?.priceRatio}
                    fieldName="priceRatio"
                    type="number"
                    icon="Percent"
                    iconRight
                    placeholder="e.g. 30"
                    className={styles.percent}
                    onChange={(value, key) =>
                      onRuleFieldChange({ value, key, index })
                    }
                  />
                </div>
                <div
                  className={styles.inputRow}
                  style={{ alignItems: 'flex-start' }}
                >
                  <Label
                    className={styles.inputLabel}
                    TooltipComponent={
                      <p>{t(TRANSLATION_KEYS.hoursDescription)}</p>
                    }
                  >
                    <span>{t(TRANSLATION_KEYS.hours)}</span>
                  </Label>
                  <WeeklyPriceTimeRange
                    // @ts-expect-error ts-migrate(2551) FIXME: Property 'amount' does not exist on type 'PaymentF... Remove this comment to see the full error message
                    baseAmount={contentFeature.feature.amount}
                    weekTimeRules={rule.weekTimeRules}
                    weekTimeRulesEnabled={rule.weekTimeRulesEnabled}
                    onChangePriceRatio={onRuleChangePriceRatio(index)}
                    onAddTimeRange={onRuleAddTimeRange(index)}
                    onRemoveTimeRange={onRuleRemoveTimeRange(index)}
                    onTimeRangeSelect={onRuleTimeRangeSelect(index)}
                    onToggleOpen={onRuleToggleOpen(index)}
                    onToggleHours={value =>
                      onRuleFieldChange({
                        key: 'weekTimeRulesEnabled',
                        value,
                        index,
                      })
                    }
                  />
                </div>
                <Icon
                  name="times-circle"
                  set={ICON_SET_FONTAWESOME}
                  className={styles.removeIcon}
                  onClick={() => onRemoveRule(index)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Payment;
