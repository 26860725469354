// adding a feature? make sure to
//  - update packages/lane-shared/helpers/features/explodeFeatures.js
//  - add the name in packages/lane-shared/types/features/FeatureNameEnum.ts
//  - add a renderer for inputting your feature here packages/lane-web/src/components/renderers/features/FeatureRenderers.js
//  - add it into the order of operations here packages/lane-shared/helpers/features/index.js
//  - you may need to create some custom components to display it?
//       see PropertyInputBlock on web and mobile.
import { surveysFeatureDefinition } from 'lane-shared/domains/surveys';
import { visitorManagementFeatureDefinition } from 'lane-shared/domains/visitorManagement';
import { workOrderFeatureDefinition } from 'lane-shared/domains/workOrder';
import { FeatureDefinition } from 'lane-shared/types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';

import Cancelable from './Cancelable';
import Deliverable from './Deliverable';
import Entries from './Entries';
import EssensysProduct from './EssensysProduct';
import GoogleCalendar from './GoogleCalendar';
import GuestInvite from './GuestInvite';
import Inventory from './Inventory';
import Menu from './Menu';
import OutlookCalendar from './OutlookCalendar';
import Payment from './Payment';
import QRCodeCheckin from './QRCodeCheckin';
import Quantity from './Quantity';
import RemoteFetch from './RemoteFetch';
import Requirements from './Requirements';
import Reservable from './Reservable';
import Reset from './Reset';
import Scheduled from './Scheduled';
import Shipping from './Shipping';
import SocialOptions from './SocialOptions';
import Statuses from './Statuses';
import SubmitOnBehalfOf from './SubmitOnBehalfOf';
import TimeAvailability from './TimeAvailability';
import UseCompanyPermissions from './UseCompanyPermissions';

const Features: {
  [Key in `${FeatureNameEnum}` | FeatureNameEnum]: FeatureDefinition;
} = {
  Cancelable,
  Deliverable,
  Entries,
  EssensysProduct,
  GuestInvite,
  Inventory,
  Menu,
  OutlookCalendar,
  GoogleCalendar,
  Payment,
  QRCodeCheckin,
  Quantity,
  RemoteFetch,
  Reservable,
  Requirements,
  Reset,
  Scheduled,
  Shipping,
  SocialOptions,
  Statuses,
  SubmitOnBehalfOf,
  Surveys: surveysFeatureDefinition,
  TimeAvailability,
  UseCompanyPermissions,
  VisitorManagement: visitorManagementFeatureDefinition,
  WorkOrder: workOrderFeatureDefinition,
};

export default Features;
