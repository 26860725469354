import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { AppContext } from 'lane-shared/contexts';
import { getValidationMessages, getTextColor } from 'lane-shared/helpers';
import { BLOCK_FOR , MESSAGE_CARD } from 'constants-content';
import { getCardName } from 'lane-shared/helpers/content';
import {
  SubtitleOptions,
  isSubtitleCustom,
  isSubtitleDate,
} from 'lane-shared/helpers/content/subtitleHelpers';
import { extractChannelLanguagesArray } from 'lane-shared/helpers/dynamicLanguages';
import { useChannelTheme, usePublicUserQuery } from 'lane-shared/hooks';
import { SupportedLocaleEnum } from 'localization';

import ThemePaletteColorSelectorButton from 'components/lane/ThemePaletteColorSelectorButton';
import { H5 } from 'components/typography';

import TextArea from '../../form/TextArea';
import ButtonStrip from '../../general/ButtonStrip';
import ContentBlockDropdown from '../ContentBlockDropdown';
import UserCircleListView from '../UserCircleListView';

import styles from './DraftNoticeInfo.scss';

export default function DraftNoticeInfo({
  channel,
  user,
  content,
  validation,
  onContentUpdated,
}: any) {
  const { t } = useTranslation();
  const { cards } = useContext(AppContext);
  const theme = useChannelTheme(channel);
  const cardName = getCardName(content, cards);
  const { user: userData } = usePublicUserQuery({
    userId: user?._id,
  });

  function setBackgroundColor(backgroundColor: any) {
    const color = getTextColor(backgroundColor);

    onContentUpdated({ backgroundColor, color });
  }

  const getSubtitleOptions = () => {
    const subtitleOptionsDisplayText = Object.keys(
      SubtitleOptions
    ) as (keyof typeof SubtitleOptions)[];

    return subtitleOptionsDisplayText.filter(
      subtitleDisplayText =>
        !isSubtitleDate(SubtitleOptions[subtitleDisplayText])
    );
  };

  const translatedSubtitleOptions = getSubtitleOptions().map(
    subtitleDisplayText => ({
      name: t(
        `web.admin.content.draftContent.subtitleOptions.${subtitleDisplayText.toLowerCase()}`
      ),
      value: SubtitleOptions[subtitleDisplayText],
    })
  );

  const channelLanguagesArray = extractChannelLanguagesArray(channel);

  const NoticeLocalesTranslationKeys = {
    [SupportedLocaleEnum.ChineseTraditional]:
      'web.admin.content.notice.multilanguageBox.header.ChineseTraditional',
    [SupportedLocaleEnum.Dutch]:
      'web.admin.content.notice.multilanguageBox.header.Dutch',
    [SupportedLocaleEnum.English]: 'English',
    [SupportedLocaleEnum.EnglishUK]:
      'web.admin.content.notice.multilanguageBox.header.EnglishUK',
    [SupportedLocaleEnum.French]:
      'web.admin.content.notice.multilanguageBox.header.French',
    [SupportedLocaleEnum.FrenchCanadian]:
      'web.admin.content.notice.multilanguageBox.header.FrenchCanadian',
    [SupportedLocaleEnum.German]:
      'web.admin.content.notice.multilanguageBox.header.German',
    [SupportedLocaleEnum.GermanAustrian]:
      'web.admin.content.notice.multilanguageBox.header.GermanAustrian',
    [SupportedLocaleEnum.Italian]:
      'web.admin.content.notice.multilanguageBox.header.Italian',
    [SupportedLocaleEnum.Japanese]:
      'web.admin.content.notice.multilanguageBox.header.Japanese',
    [SupportedLocaleEnum.Portuguese]:
      'web.admin.content.notice.multilanguageBox.header.Portuguese',
    [SupportedLocaleEnum.PortugueseBrazil]:
      'web.admin.content.notice.multilanguageBox.header.PortugueseBrazil',
    [SupportedLocaleEnum.SpanishSpain]:
      'web.admin.content.notice.multilanguageBox.header.SpanishSpain',
    [SupportedLocaleEnum.SpanishMexico]:
      'web.admin.content.notice.multilanguageBox.header.SpanishMexico',
    [SupportedLocaleEnum.SpanishCatalan]:
      'web.admin.content.notice.multilanguageBox.header.SpanishCatalan',
    [SupportedLocaleEnum.Thai]:
      'web.admin.content.notice.multilanguageBox.header.Thai',
  };

  return (
    <div className={styles.DraftNoticeInfo}>
      <section className={styles.edit}>
        <ContentBlockDropdown
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never'.
          blocks={cards}
          id="noticeTypeDrop"
          className={styles.dropDown}
          placeholder={t('Select a layout…')}
          errors={getValidationMessages(validation, 'card')}
          for={[BLOCK_FOR.NOTICE]}
          value={content.card._id}
          onValueChange={(_id: any) =>
            onContentUpdated({
              card: {
                _id,
              },
            })
          }
        />

        <label>{t('Subtitle')}</label>
        <ButtonStrip
          className={styles.buttonStrip}
          buttons={translatedSubtitleOptions}
          selected={
            isSubtitleCustom(content.subtitle)
              ? ''
              : (content.subtitle as '\t' | null)
          }
          onClick={value => onContentUpdated({ subtitle: value })}
        />

        <label>{t('Color')}</label>
        <ThemePaletteColorSelectorButton
          value={content.backgroundColor}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ThemeColorPaletteType | undefined' is not as... Remove this comment to see the full error message
          palette={theme.palette}
          onColorSelected={setBackgroundColor}
        />
      </section>

      <section style={{ marginTop: '1.5em' }}>
        {channelLanguagesArray.map(
          ({ isPrimary, languageCode }, _index, array) => {
            const shouldSeeMultiLanguage = array.length > 1;

            return (
              <div className={styles.previewBox} key={languageCode}>
                {shouldSeeMultiLanguage && (
                  <H5 className={styles.previewBoxTitle}>
                    {
                      // @ts-ignore-error
                      t(NoticeLocalesTranslationKeys[languageCode])
                    }
                  </H5>
                )}

                <section
                  className={styles.preview}
                  style={{
                    color: content.color,
                    backgroundColor: content.backgroundColor,
                  }}
                >
                  {isPrimary ? (
                    <div key={languageCode}>
                      <TextArea
                        placeholder={t(
                          'web.admin.content.notice.title.placeholder'
                        )}
                        testId="title"
                        className={styles.name}
                        value={content.name}
                        errors={getValidationMessages(validation, 'name')}
                        maxLength={75}
                        onChange={name => {
                          onContentUpdated({
                            name,
                            name_l10n: {
                              ...content.name_l10n,
                              ...(languageCode ? { [languageCode]: name } : {}),
                            },
                          });
                        }}
                        containerClassName={styles.textAreaContainer}
                      />
                      <hr style={{ backgroundColor: content.color }} />
                      {isSubtitleCustom(content?.subtitle) && (
                        <TextArea
                          placeholder={t('Subtitle…')}
                          testId="subtitle"
                          className={styles.dates}
                          value={content.subtitle}
                          errors={getValidationMessages(validation, 'subtitle')}
                          maxLength={75}
                          onChange={subtitle => {
                            onContentUpdated({
                              subtitle,
                              subtitle_l10n: {
                                ...content.subtitle_l10n,
                                ...(languageCode
                                  ? { [languageCode]: subtitle }
                                  : {}),
                              },
                            });
                          }}
                        />
                      )}
                      <TextArea
                        placeholder={t(
                          'web.admin.content.notice.description.placeholder'
                        )}
                        testId="description"
                        className={styles.description}
                        value={content.description}
                        errors={getValidationMessages(
                          validation,
                          'description'
                        )}
                        maxLength={255}
                        onChange={description => {
                          onContentUpdated({
                            description,
                            description_l10n: {
                              ...content.description_l10n,
                              ...(languageCode
                                ? { [languageCode]: description }
                                : {}),
                            },
                          });
                        }}
                        containerClassName={styles.textAreaContainer}
                      />
                    </div>
                  ) : (
                    <div key={languageCode}>
                      <TextArea
                        placeholder={t(
                          'web.admin.content.notice.title.placeholder'
                        )}
                        testId="title_l10n"
                        className={styles.name}
                        value={(content.name_l10n || {})[languageCode]}
                        maxLength={75}
                        onChange={name => {
                          const contentUpdates = {
                            name_l10n: {
                              ...content.name_l10n,
                              [languageCode]: name,
                            },
                          };

                          onContentUpdated(contentUpdates);
                        }}
                        containerClassName={styles.textAreaContainer}
                      />
                      <hr style={{ backgroundColor: content.color }} />
                      {isSubtitleCustom(content?.subtitle) && (
                        <TextArea
                          placeholder={t('Subtitle…')}
                          testId="subtitle_l10n"
                          className={styles.dates}
                          value={
                            (content.subtitle_l10n || {})[languageCode] || ''
                          }
                          maxLength={75}
                          onChange={subtitle_l10n => {
                            const contentUpdates = {
                              subtitle_l10n: {
                                ...content.subtitle_l10n,
                                [languageCode]: subtitle_l10n,
                              },
                            };

                            onContentUpdated(contentUpdates);
                          }}
                        />
                      )}
                      <TextArea
                        placeholder={t(
                          'web.admin.content.notice.description.placeholder'
                        )}
                        testId="description_l10n"
                        className={styles.description}
                        value={
                          (content.description_l10n || {})[languageCode] || ''
                        }
                        maxLength={255}
                        onChange={description => {
                          const contentUpdates = {
                            description_l10n: {
                              ...content.description_l10n,
                              [languageCode]: description,
                            },
                          };

                          onContentUpdated(contentUpdates);
                        }}
                        containerClassName={styles.textAreaContainer}
                      />
                    </div>
                  )}
                </section>
              </div>
            );
          }
        )}

        <div className={styles.spacer} />
        {cardName === MESSAGE_CARD && (
          <div className={styles.userWrapper}>
            <UserCircleListView
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'PublicUserType | undefined' is not assignabl... Remove this comment to see the full error message
              user={userData}
              style={{ color: content.color }}
              className={styles.user}
            />
          </div>
        )}
      </section>
    </div>
  );
}
