import React, { useState, useEffect } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { useLazyQuery, OperationVariables } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import {
  queryContentOnChannel,
  queryDraftContentOnChannel,
} from 'lane-shared/graphql/channel';
import { ContentTypeEnum } from 'constants-content';

import ContentCard from '../../cards/ContentCard';
import DateRangePickerButton from '../../form/DatePickers/DateRangePickerButton';
import Input from '../../form/Input';
import ControlMenu from '../../general/ControlMenu';
import ErrorMessage from '../../general/ErrorMessage';
import Pagination from '../../general/Pagination';

import styles from './ContentSearchOnChannel.scss';

const DEBOUNCE_THROTTLE = 500;
const PER_PAGE = 50;

type Props = {
  channelId: string;
  types: ContentTypeEnum[];
  forDrafts: boolean;
  filterIds?: string[];
  emptyComponent?: React.ReactNode;
  onContentSelected: (content: any) => void;
  isOnlyInteractiveContent?: boolean;
  includeGeneratedBatchContent?: boolean;
};

export default function LiveContentSearchOnChannel({
  channelId,
  types,
  forDrafts,
  filterIds = [],
  emptyComponent,
  onContentSelected,
  isOnlyInteractiveContent,
  includeGeneratedBatchContent = false,
}: Props) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [variables, setVariables] = useState<undefined | OperationVariables>(
    undefined
  );
  const [startDate, setStartDate] = useState(
    DateTime.local().minus({ months: 1 }).toJSDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);

  // Disable the ability to search by date if we are searching for Static content
  const hasDates = !types.includes(ContentTypeEnum.Static);

  useEffect(() => {
    const variables = {
      pagination: {
        start: page * PER_PAGE,
        perPage: PER_PAGE,
      },
      search: {
        sortBy: {
          key: 'name',
        },
        includeGeneratedBatchContent,
      },
      channelId,
    };

    if (debouncedSearch) {
      (variables.search as any).name = { type: 'like', value: debouncedSearch };
    }

    if (hasDates) {
      (variables.search as any).liveDate = {
        type: 'lt',
        value: endDate,
      };

      (variables.search as any).endDate = {
        type: 'gt',
        value: startDate,
      };
    }

    if (types) {
      (variables.search as any).type = { any: types };
    }

    if (isOnlyInteractiveContent) {
      (variables.search as any).isInteractive = true;
    }

    setVariables(variables);
  }, [channelId, page, startDate, endDate, debouncedSearch]);

  useEffect(() => {
    if (forDrafts) {
      fetchDrafts({ variables });
    } else {
      fetchContents({ variables });
    }
  }, [variables]);
  const [fetchDrafts, draftResults] = useLazyQuery(queryDraftContentOnChannel, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    variables,
  });
  const [fetchContents, contentResults] = useLazyQuery(queryContentOnChannel, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    variables,
  });

  useEffect(() => {
    setPage(0);
  }, [search]);

  function explodeContent() {
    return {
      contents: contentResults?.data?.contentOnChannel?.items || [],
      pageInfo: contentResults?.data?.contentOnChannel?.pageInfo || {
        total: 0,
      },
      loading: contentResults?.loading,
      error: contentResults?.error,
    };
  }

  function explodeDrafts() {
    return {
      contents: draftResults?.data?.draftContentOnChannel?.items || [],
      pageInfo: draftResults?.data?.draftContentOnChannel?.pageInfo || {
        total: 0,
      },
      loading: draftResults?.loading,
      error: draftResults?.error,
    };
  }

  const { contents, pageInfo, loading, error } = forDrafts
    ? explodeDrafts()
    : explodeContent();

  return (
    <>
      <ControlMenu>
        {hasDates && (
          <DateRangePickerButton
            startDate={startDate}
            endDate={endDate}
            onChange={({ startDate, endDate }: any) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
        )}
        <Input
          className={styles.searchInput}
          value={search}
          testId="searchContent"
          icon="search"
          onChange={search => setSearch(search)}
          placeholder={t('Search…')}
          showClear
        />
      </ControlMenu>
      <ErrorMessage error={error} />
      <Pagination
        pagesToShow={3}
        loading={loading}
        perPage={PER_PAGE}
        page={page}
        total={pageInfo.total}
        onPage={setPage}
      />
      <ul>
        {contents
          .filter((content: any) => !filterIds.includes(content._id))
          .map((content: any) => (
            <button
              key={content._id}
              onClick={() => onContentSelected(content)}
            >
              <ContentCard content={content} className={styles.content} />
            </button>
          ))}

        {!loading && contents.length === 0 && emptyComponent}
      </ul>
    </>
  );
}
