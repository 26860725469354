import * as yup from 'yup';

import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { MediaDocumentContentTypeEnum, MediaTypeEnum } from 'constants-media';
import { PropertiesInterface } from '../../types/properties/Property';
import { TypeContextEnum } from '../../types/properties/TypeContextEnum';
import Types from '../Types';
import { TYPE_GROUP_LANE } from '../constants';
import createShapeFromProperties from '../createShapeFromProperties';

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  type: {
    type: 'String',
    default: MediaTypeEnum.Document,
    validators: [
      {
        name: 'In',
        value: [MediaTypeEnum.Document],
      },
    ],
  },
  contentType: {
    type: 'String',
    validators: [
      {
        name: 'In',
        value: Object.values(MediaDocumentContentTypeEnum),
      },
    ],
  },
};

export class DocumentType extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Document';

  superUserOnly = true;

  friendlyName = 'Document';

  contexts = [TypeContextEnum.Block, TypeContextEnum.Content];

  get default(): any {
    return {
      _id: null,
    };
  }

  buildSchema() {
    return yup
      .object()
      .nullable()
      .default(null)
      .shape(createShapeFromProperties(properties));
  }

  properties = properties;
}

const definition = new DocumentType();

Types.addBaseType(definition);
export default definition;
