import React from 'react';

import cx from 'classnames';

import { PaymentProviderEnum } from 'constants-payments';

import { ManagePaymentAccountProps } from './ManagePaymentAccountProps';
import ManagePlacePayAccount from './ManagePlacePayAccounts';
import ManageStripeAccounts from './ManageStripeAccounts';
import ManageUnhandledPaymentAccounts from './ManageUnhandledPaymentAccounts';

import styles from './ManagePaymentAccounts.scss';

type ManagePaymentAccountComponent<T> = React.ComponentType<
  ManagePaymentAccountProps<T>
>;

const PAYMENT_TYPE_MANAGER_COMPONENT: {
  [key in PaymentProviderEnum]: ManagePaymentAccountComponent<any>;
} = {
  [PaymentProviderEnum.Cash]: ManageUnhandledPaymentAccounts,
  [PaymentProviderEnum.Essensys]: ManageUnhandledPaymentAccounts,
  [PaymentProviderEnum.PlacePay]: ManagePlacePayAccount,
  [PaymentProviderEnum.Stripe]: ManageStripeAccounts,
};

export default function ManagePaymentAccounts<T>(
  props: ManagePaymentAccountProps<T>
) {
  return (
    <div className={cx(styles.managePaymentAccounts)}>
      {Object.keys(PAYMENT_TYPE_MANAGER_COMPONENT).map(key => {
        const ManagePaymentAccount: React.ComponentType<
          ManagePaymentAccountProps<T>
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        > = PAYMENT_TYPE_MANAGER_COMPONENT[key];

        return <ManagePaymentAccount key={key} {...props} />;
      })}
    </div>
  );
}
