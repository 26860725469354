import React, { useEffect } from 'react';

import { Redirect, useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { config as sharedConfig, routes } from 'lane-shared/config';
import getContentChannelIntegration from 'lane-shared/helpers/integrations/getContentChannelIntegration';
import getSamlData from 'lane-shared/helpers/integrations/SAML/getSamlData';
import { handleRedirect } from './handleRedirect';

import useIsAdminView from 'hooks/useIsAdminView';
import { ErrorMessage } from 'components/general';
import style from './style.scss';

export default function SamlWebRedirectPage({ content }: any) {
  const history = useHistory();
  const [isAdminView] = useIsAdminView();
  const { data: channelIntegrationData, loading } = useQuery(
    getContentChannelIntegration,
    {
      variables: { id: content._id },
      fetchPolicy: 'network-only',
    }
  );

  const {
    data: samlData,
    loading: samlLoading,
    error,
  } = useQuery(getSamlData, {
    variables: channelIntegrationData
      ? { integrationId: channelIntegrationData.me.content.integration._id }
      : undefined,
    fetchPolicy: 'network-only',
    skip: !channelIntegrationData,
  });

  useEffect(() => {
    if (
      isAdminView ||
      loading ||
      !channelIntegrationData ||
      samlLoading ||
      !samlData
    ) {
      return;
    }

    handleRedirect(samlData.samlData);
    history.goBack();
  }, [loading, samlLoading, samlData, isAdminView]);

  if (error) {
    return <ErrorMessage error={error.message} className={style.CenterAlign} />;
  }

  if (!loading && !channelIntegrationData && !samlLoading && !samlData) {
    return (
      <Redirect
        to={{
          pathname: `${routes.login}?${
            sharedConfig.redirectUrlQueryParameterName
          }=${routes.content.replace(':id', content._id)}`,
        }}
      />
    );
  }

  return null;
}
