import * as yup from 'yup';

import { META_TAG_TYPES, META_TAG_TYPE_SIMPLE } from 'constants-content';

import validateProperty from './validateProperty';

export default yup.object().shape({
  _id: yup.string().trim().required(),
  name: yup.string().trim().max(256),
  description: yup.string().trim().max(4096).notRequired(),
  type: yup.string().nullable().oneOf(META_TAG_TYPES),
  properties: yup
    .object()
    .when('type', {
      is: META_TAG_TYPE_SIMPLE,
      then: validateProperty,
      otherwise: yup.object(),
    })
    .nullable()
    .notRequired(),
});
