import { colors } from 'constants-colors';

export const INTERACTION_CREATED = 'Created';
export const INTERACTION_ACCEPTED = 'Accepted';
export const INTERACTION_APPROVED = 'Approved';
export const INTERACTION_OPEN = 'Open';
export const INTERACTION_REOPEN = 'Re-opened';
export const INTERACTION_UPCOMING = 'Upcoming';
export const INTERACTION_IN_PROGRESS = 'In Progress';
export const INTERACTION_ASSIGNED = 'Assigned';
export const INTERACTION_CONFIRMED = 'Confirmed';
export const INTERACTION_UNASSIGNED = 'Un-Assigned';
export const INTERACTION_ARRIVED = 'Arrived';
export const INTERACTION_ON_HOLD = 'On Hold';
export const INTERACTION_WAITING = 'Waiting';
export const INTERACTION_AWAITING_RESPONSE = 'Awaiting Response';
export const INTERACTION_NEEDS_MORE_INFO = 'Needs More Info';
export const INTERACTION_REJECTED = 'Rejected';
export const INTERACTION_DECLINED = 'Declined';
export const INTERACTION_FAILED = 'Failed';
export const INTERACTION_RESENT = 'Resent';
export const INTERACTION_COMPLETE = 'Complete';
export const INTERACTION_CLOSED = 'Closed';
export const INTERACTION_DELETED = 'Deleted';
export const INTERACTION_CANCELLED = 'Cancelled';
export const INTERACTION_ATTENDING = 'Attending';
export const INTERACTION_NOT_ATTENDING = 'Not Attending';
export const ALL_INTERACTIONS = 'All';

export const INTERACTION_STATES = [
  INTERACTION_ACCEPTED,
  INTERACTION_APPROVED,
  INTERACTION_ARRIVED,
  INTERACTION_ASSIGNED,
  INTERACTION_ATTENDING,
  INTERACTION_NOT_ATTENDING,
  INTERACTION_CANCELLED,
  INTERACTION_CLOSED,
  INTERACTION_COMPLETE,
  INTERACTION_CONFIRMED,
  INTERACTION_CREATED,
  INTERACTION_DECLINED,
  INTERACTION_DELETED,
  INTERACTION_FAILED,
  INTERACTION_IN_PROGRESS,
  INTERACTION_NEEDS_MORE_INFO,
  INTERACTION_ON_HOLD,
  INTERACTION_OPEN,
  INTERACTION_REJECTED,
  INTERACTION_REOPEN,
  INTERACTION_RESENT,
  INTERACTION_UNASSIGNED,
  INTERACTION_UPCOMING,
  INTERACTION_WAITING,
];

export const INTERACTION_OPEN_STATES = [
  INTERACTION_OPEN,
  INTERACTION_CREATED,
  INTERACTION_ACCEPTED,
  INTERACTION_NEEDS_MORE_INFO,
  INTERACTION_ON_HOLD,
  INTERACTION_IN_PROGRESS,
  INTERACTION_ASSIGNED,
  INTERACTION_WAITING,
  INTERACTION_REOPEN,
  INTERACTION_APPROVED,
  INTERACTION_UPCOMING,
  INTERACTION_CONFIRMED,
  INTERACTION_UNASSIGNED,
  INTERACTION_ARRIVED,
  INTERACTION_RESENT,
];

INTERACTION_OPEN_STATES.sort();

export const INTERACTION_INTERMEDIATE_STATES = [
  INTERACTION_ACCEPTED,
  INTERACTION_APPROVED,
  INTERACTION_OPEN,
  INTERACTION_REOPEN,
  INTERACTION_UPCOMING,
  INTERACTION_IN_PROGRESS,
  INTERACTION_ASSIGNED,
  INTERACTION_CONFIRMED,
  INTERACTION_UNASSIGNED,
  INTERACTION_ON_HOLD,
  INTERACTION_ARRIVED,
  INTERACTION_WAITING,
  INTERACTION_NEEDS_MORE_INFO,
  INTERACTION_RESENT,
];

INTERACTION_INTERMEDIATE_STATES.sort();

export const INTERACTION_CLOSED_STATES = [
  INTERACTION_CLOSED,
  INTERACTION_COMPLETE,
  INTERACTION_FAILED,
  INTERACTION_REJECTED,
  INTERACTION_DECLINED,
  INTERACTION_DELETED,
  INTERACTION_CANCELLED,
];

INTERACTION_CLOSED_STATES.sort();

export const INTERACTION_POSITIVE_STATES = [
  INTERACTION_ACCEPTED,
  INTERACTION_APPROVED,
  INTERACTION_COMPLETE,
];

INTERACTION_POSITIVE_STATES.sort();

export const INTERACTION_NEGATIVE_STATES = [
  INTERACTION_FAILED,
  INTERACTION_REJECTED,
  INTERACTION_DECLINED,
  INTERACTION_DELETED,
  INTERACTION_CANCELLED,
];

INTERACTION_NEGATIVE_STATES.sort();

export const INTERACTION_COLORS = {
  [INTERACTION_CREATED]: colors.colorDimPurple,
  [INTERACTION_ACCEPTED]: colors.colorSuccessLight,
  [INTERACTION_APPROVED]: colors.colorSuccessLight,
  [INTERACTION_OPEN]: colors.colorDimYellow,
  [INTERACTION_REOPEN]: colors.colorDimYellow,
  [INTERACTION_IN_PROGRESS]: colors.laneRainfallLight,
  [INTERACTION_ASSIGNED]: colors.colorWarningLight,
  [INTERACTION_ON_HOLD]: colors.colorDimBlue,
  [INTERACTION_WAITING]: colors.colorWarningLight,
  [INTERACTION_NEEDS_MORE_INFO]: colors.colorWarningLight,
  [INTERACTION_REJECTED]: colors.colorDimGray,
  [INTERACTION_FAILED]: colors.colorErrorLight,
  [INTERACTION_RESENT]: colors.colorWarningLight,
  [INTERACTION_COMPLETE]: colors.colorDimGreen,
  [INTERACTION_CLOSED]: colors.colorDimGray,
  [INTERACTION_CANCELLED]: colors.colorDimGray,
  [INTERACTION_UPCOMING]: colors.colorPrimaryLight,
  [INTERACTION_CONFIRMED]: colors.colorSuccessLight,
  [INTERACTION_UNASSIGNED]: colors.colorWarningLight,
  [INTERACTION_ARRIVED]: colors.laneRainfallLight,
  [INTERACTION_DELETED]: colors.colorErrorLight,
  [INTERACTION_DECLINED]: colors.colorErrorLightHex,
};

export const BORDER_INTERACTION_COLORS = {
  [INTERACTION_CREATED]: colors.colorDimPurpleStroke,
  [INTERACTION_ACCEPTED]: colors.success,
  [INTERACTION_APPROVED]: colors.success,
  [INTERACTION_OPEN]: colors.colorDimYellowStroke,
  [INTERACTION_REOPEN]: colors.colorDimYellowStroke,
  [INTERACTION_IN_PROGRESS]: colors.laneDarkGreen,
  [INTERACTION_ASSIGNED]: colors.warning,
  [INTERACTION_ON_HOLD]: colors.colorDimBlueStroke,
  [INTERACTION_WAITING]: colors.warning,
  [INTERACTION_NEEDS_MORE_INFO]: colors.warning,
  [INTERACTION_REJECTED]: colors.colorDimGrayStroke,
  [INTERACTION_FAILED]: colors.error,
  [INTERACTION_RESENT]: colors.warning,
  [INTERACTION_COMPLETE]: colors.colorDimGreenStroke,
  [INTERACTION_CLOSED]: colors.colorDimGrayStroke,
  [INTERACTION_CANCELLED]: colors.colorDimGrayStroke,
  [INTERACTION_UPCOMING]: colors.interactiveBlue,
  [INTERACTION_CONFIRMED]: colors.success,
  [INTERACTION_UNASSIGNED]: colors.warning,
  [INTERACTION_ARRIVED]: colors.laneDarkGreen,
  [INTERACTION_DELETED]: colors.error,
  [INTERACTION_DECLINED]: colors.error,
};
