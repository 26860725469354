import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';
import './types/CancelableFeatureRule';
import './types/ContentInteractionRequirementType';

const Requirements: FeatureDefinition = {
  name: FeatureNameEnum.Requirements,
  friendlyName: 'web.content.features.requirements.friendlyName',
  requiresInteraction: true,
  description: 'web.content.features.requirements.description',
  properties: {
    fullNameRequired: {
      friendlyName:
        'web.content.features.requirements.properties.fullNameRequired.friendlyName',
      type: 'Boolean',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      default: false,
    },
    verifiedEmailRequired: {
      friendlyName:
        'web.content.features.requirements.properties.verifiedEmailRequired.friendlyName',
      type: 'Boolean',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      default: false,
      showControl: false,
    },
    profilePictureRequired: {
      friendlyName:
        'web.content.features.requirements.properties.profilePictureRequired.friendlyName',
      type: 'Boolean',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      default: false,
    },
    phoneNumberRequired: {
      friendlyName:
        'web.content.features.requirements.properties.phoneNumberRequired.friendlyName',
      type: 'Boolean',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      default: false,
    },
    companyNameRequired: {
      friendlyName:
        'web.content.features.requirements.properties.companyNameRequired.friendlyName',
      type: 'Boolean',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      default: false,
    },
    contentInteractionRequired: {
      friendlyName:
        'web.content.features.requirements.properties.contentInteractionRequired.friendlyName',
      type: 'ContentInteractionRequirementType',
      isArray: true,
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default Requirements;
