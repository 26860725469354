import { PaymentCurrencyEnum } from './constants-payments';

/**
 * @deprecated use `PaymentCurrencyEnum` instead
 */
export const CURRENCY_USD = PaymentCurrencyEnum.USD;
/**
 * @deprecated use `PaymentCurrencyEnum` instead
 */
export const CURRENCY_CAD = PaymentCurrencyEnum.CAD;
