import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { permissions } from 'constants-permissions';
import { UseCompanyPermissionsFeatureProperties } from 'lane-shared/types/features/UseCompanyPermissionsFeatureProperties';

import MultiselectField from 'components/form/MultiselectField';
import Toggle from 'components/form/Toggle';
import Label from 'components/general/Label';
import { FeatureRendererPropsType } from 'components/renderers/features/FeatureRendererPropsType';

import styles from './UseCompanyPermissions.scss';

function toSchema({ key, description }: any) {
  return {
    value: key,
    label: description,
  };
}

export default function UseCompanyPermissionsFeatureRenderer({
  className,
  style,
  feature,
  contentFeature,
  onFeatureUpdated,
}: FeatureRendererPropsType<UseCompanyPermissionsFeatureProperties>) {
  const settings: UseCompanyPermissionsFeatureProperties | null =
    contentFeature?.feature;

  const { t } = useTranslation();

  return (
    <div className={cx(styles.UseCompanyPermissions, className)} style={style}>
      <Label h1>{t(feature.friendlyName)}</Label>

      <p>{t(feature.description)}</p>

      {settings && (
        <section>
          <fieldset>
            <Label
              TooltipComponent={t(
                feature.properties.useContentCategories.description
              )}
            >
              {t(feature.properties.useContentCategories.friendlyName)}
            </Label>
            <Toggle
              onChange={() =>
                onFeatureUpdated({
                  useContentCategories: !settings.useContentCategories,
                  permissions: [],
                })
              }
              value={contentFeature.feature.useContentCategories}
            />
          </fieldset>

          {!settings.useContentCategories && (
            <fieldset>
              <Label
                TooltipComponent={t(feature.properties.permissions.description)}
              >
                {t(feature.properties.permissions.friendlyName)}
              </Label>

              <MultiselectField
                className={styles.multiSelect}
                onChange={values => {
                  onFeatureUpdated({
                    permissions: values.map(({ value }) => value),
                  });
                }}
                items={permissions.map(toSchema)}
                isSearchable
                value={permissions
                  .filter(permission =>
                    settings?.permissions?.includes(permission.key)
                  )
                  .map(toSchema)}
              />
            </fieldset>
          )}
        </section>
      )}
    </div>
  );
}
