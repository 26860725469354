import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';

const Entries: FeatureDefinition = {
  name: FeatureNameEnum.Entries,
  friendlyName: 'web.content.feature.entries.friendlyName',
  requiresInteraction: true,
  description: 'web.content.feature.entries.description',
  properties: {
    allowAnonymous: {
      friendlyName:
        'web.content.feature.entries.properties.allowAnonymous.friendlyName',
      description:
        'web.content.feature.entries.properties.allowAnonymous.description',
      type: 'Boolean',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      default: false,
    },
    unlimited: {
      friendlyName:
        'web.content.feature.entries.properties.unlimited.friendlyName',
      description:
        'web.content.feature.entries.properties.unlimited.description',
      type: 'Boolean',
      editable: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      default: false,
    },
    entries: {
      showControl: {
        _bind: true,
        op: '!props.unlimited',
      },
      friendlyName:
        'web.content.feature.entries.properties.entries.friendlyName',
      description: 'web.content.feature.entries.properties.entries.description',
      type: 'Number',
      editable: true,
      validators: [
        {
          name: 'Max',
          value: 100,
        },
        {
          name: 'Min',
          value: 1,
        },
      ],
      default: 1,
    },
  },
};

export default Entries;
