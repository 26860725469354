import React from 'react';

import cx from 'classnames';

import { LaneType } from 'common-types';
import { getPackagedType } from 'lane-shared/helpers/properties';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { MetatagFilterUI } from 'lane-shared/types/filters/MetatagFilter';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';

import { Toggle } from 'components/form';
import { XS } from 'components/typography';

import PropertyInput from '../builder/properties/input/PropertyInput';

import styles from './MetatagSimpleOption.scss';

const SINGLE_ROW_FIELDS = [PackagedTypeEnum.Quantity];

type Props = {
  className?: string;
  style?: React.CSSProperties;
  metatag: MetatagFilterUI;
  channel?: Channel;
  value?: LaneType.UUID;
  isEnabled?: boolean;
  allowDisableFilter?: boolean;
  onChange?: (value: any) => void;
  onEnable?: (value: boolean) => void;
};

function MetatagSimpleOption({
  className,
  style,
  metatag,
  channel,
  value,
  isEnabled = true,
  allowDisableFilter = false,
  onChange = () => null,
  onEnable = () => null,
}: Props) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PropertiesInterface<PropertyOpti... Remove this comment to see the full error message
  const packagedType = getPackagedType(metatag.properties);
  const singleRow = SINGLE_ROW_FIELDS.includes(packagedType);

  return (
    <div
      className={cx(
        styles.MetatagSimpleOption,
        singleRow && styles.singleRow,
        className
      )}
      style={style}
    >
      <div className={styles.name}>
        <h1>
          {allowDisableFilter ? (
            <Toggle
              doTranslate
              value={isEnabled}
              text={metatag.name}
              onChange={value => {
                onEnable(value);
              }}
            />
          ) : (
            metatag.name
          )}
        </h1>
        {metatag.showDescription && <XS>{metatag.description}</XS>}
      </div>
      <PropertyInput
        property={metatag.properties}
        value={value}
        onChange={onChange}
        channel={channel}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ property: PropertiesInterface<PropertyOpti... Remove this comment to see the full error message
        geo={channel?.address?.geo}
      />
    </div>
  );
}

export default MetatagSimpleOption;
