import React from 'react';

import { isNode } from '../../config';
import {
  Mjml,
  MjmlHead,
  MjmlTitle,
  MjmlPreview,
  MjmlBody,
  MjmlStyle,
} from '../MjmlComponentsV2';
import { colors } from 'constants-colors';

let styles = '';

if (isNode) {
  styles = `
    p {
      margin: 10px 0;
      padding: 0;
    }
    table {
      border-collapse: collapse;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: block;
      margin: 0;
      padding: 0;
    }
    img,
    a img {
      border: 0;
      height: auto;
      outline: none;
      text-decoration: none;
    }
    img {
      -ms-interpolation-mode: bicubic;
    }
    table {
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }
    p,
    a,
    li,
    td,
    blockquote {
      mso-line-height-rule: exactly;
    }
    a[href^='tel'],
    a[href^='sms'] {
      color: inherit;
      cursor: default;
      text-decoration: none;
    }
    p,
    a,
    li,
    td,
    body,
    table,
    blockquote {
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
    }
    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }

    body,
    p {
      font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
      font-size: 13px;
      line-height: 150%;
      text-align: left;
    }

    h1 {
      font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
      font-size: 48px;
      font-style: normal;
      font-weight: bold;
      line-height: 125%;
      letter-spacing: normal;
      text-align: left;
    }
    h2 {
      font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: bold;
      line-height: 125%;
      letter-spacing: normal;
      text-align: left;
    }
    h3 {
      font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      line-height: 150%;
      letter-spacing: normal;
      text-align: left;
    }
    h4 {
      font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      line-height: 200%;
      letter-spacing: normal;
      text-align: left;
    }

    .mobile-images {
      display:none;
    }
    @media only screen and (max-width: 480px) {
      .web-images {
      display:none;
      }
      .mobile-images {
        display:block;
      }
    }

  `;
}

type EmailBodyProps = {
  title: string;
  children: React.ReactNode;
  HeaderComponent?: React.ReactElement | null;
};

export default function EmailBody({
  title,
  children,
  HeaderComponent = null,
}: EmailBodyProps) {
  return (
    <Mjml>
      <MjmlHead>
        <MjmlStyle>{styles}</MjmlStyle>
        <MjmlTitle>{title}</MjmlTitle>
        <MjmlPreview>{title}</MjmlPreview>
        {HeaderComponent}
      </MjmlHead>
      <MjmlBody width={500} backgroundColor={colors.white}>
        {children}
      </MjmlBody>
    </Mjml>
  );
}
