import { FeatureNameEnum } from 'constants-content';
import calculatePaymentAmount from './calculatePaymentAmount';
import canUserInteractWithTimeAvailability from './canUserInteractWithTimeAvailability';
import constructMenuFeatureOrderItem from './constructMenuFeatureOrderItem';
import convertToHourly from './convertToHourly';
import explodeFeatures from './explodeFeatures';
import generateMenuFeatureQuote from './generateMenuFeatureQuote';
import { getChargeStatus } from './getChargeStatus';
import getDateTimesFromTimeRange from './getDateTimesFromTimeRange';
import getDayUnavailableDateRangesFromTimeAvailability from './getDayUnavailableDateRangesFromTimeAvailability';
import getDefaultDatesForReservable from './getDefaultDatesForReservable';
import getDefaultSearchWindowForReservable from './getDefaultSearchWindowForReservable';
import { getInvoiceStatus } from './getInvoiceStatus';
import getMenuFeatureItemConfiguredPrice from './getMenuFeatureItemConfiguredPrice';
import getMenuFeatureMaxPreparationTime from './getMenuFeatureMaxPreparationTime';
import getMenuFeatureSelectedOptions from './getMenuFeatureSelectedOptions';
import getMonthUnavailableDateRangesFromTimeAvailability from './getMonthUnavailableDateRangesFromTimeAvailability';
import getPaymentFeatureCurrency from './getPaymentFeatureCurrency';
import getReservableBestRule from './getReservableBestRule';
import getReservableFeatureNumberOfSlots from './getReservableFeatureNumberOfSlots';
import getReservableMinutesFromUnits from './getReservableMinutesFromUnits';
import getResetPeriodFormattedDate from './getResetPeriodFormattedDate';
import getUnitTime from './getUnitTime';
import groupInteractionsByStatus from './groupInteractionsByStatus';
import isAvailabilityValid from './isAvailabilityValid';
import isFeatureVisible from './isFeatureVisible';
import isFreeOfCharge from './isFreeOfCharge';
import { isInvoicePayable } from './isInvoicePayable';
import isMenuFeatureOrderItemValid from './isMenuFeatureOrderItemValid';
import isPaymentAccountValidClientSide from './isPaymentAccountValidClientSide';

// The order of operations to apply feature when creating a UCI
export const CREATE_ORDER_OF_OPERATIONS = [
  FeatureNameEnum.Reset,
  FeatureNameEnum.Cancelable,
  FeatureNameEnum.TimeAvailability,
  FeatureNameEnum.UseCompanyPermissions,
  FeatureNameEnum.Entries,
  FeatureNameEnum.Statuses,
  FeatureNameEnum.SubmitOnBehalfOf,
  FeatureNameEnum.Quantity,
  FeatureNameEnum.Deliverable,
  FeatureNameEnum.Inventory,
  FeatureNameEnum.QRCodeCheckin,
  FeatureNameEnum.RemoteFetch,
  FeatureNameEnum.Scheduled,
  FeatureNameEnum.Reservable,
  FeatureNameEnum.OutlookCalendar,
  FeatureNameEnum.GoogleCalendar,
  FeatureNameEnum.Shipping,
  FeatureNameEnum.GuestInvite,
  FeatureNameEnum.VisitorManagement,
  FeatureNameEnum.Surveys,
  FeatureNameEnum.Menu,
  FeatureNameEnum.Payment,
  FeatureNameEnum.EssensysProduct,
];

// The order of operations to apply features when updating a UCI
export const UPDATE_ORDER_OF_OPERATIONS = [
  FeatureNameEnum.Reset,
  FeatureNameEnum.Cancelable,
  FeatureNameEnum.TimeAvailability,
  FeatureNameEnum.UseCompanyPermissions,
  FeatureNameEnum.Entries,
  FeatureNameEnum.Statuses,
  FeatureNameEnum.SubmitOnBehalfOf,
  FeatureNameEnum.Quantity,
  FeatureNameEnum.Deliverable,
  FeatureNameEnum.Inventory,
  FeatureNameEnum.QRCodeCheckin,
  FeatureNameEnum.RemoteFetch,
  FeatureNameEnum.Scheduled,
  FeatureNameEnum.Reservable,
  FeatureNameEnum.OutlookCalendar,
  FeatureNameEnum.GoogleCalendar,
  FeatureNameEnum.Shipping,
  FeatureNameEnum.GuestInvite,
  FeatureNameEnum.VisitorManagement,
  FeatureNameEnum.Surveys,
  FeatureNameEnum.Menu,
  FeatureNameEnum.Payment,
  FeatureNameEnum.EssensysProduct,
];

export {
  calculatePaymentAmount,
  canUserInteractWithTimeAvailability,
  constructMenuFeatureOrderItem,
  convertToHourly,
  explodeFeatures,
  generateMenuFeatureQuote,
  getDateTimesFromTimeRange,
  getDefaultDatesForReservable,
  getDefaultSearchWindowForReservable,
  getMenuFeatureItemConfiguredPrice,
  getMenuFeatureMaxPreparationTime,
  getMenuFeatureSelectedOptions,
  getPaymentFeatureCurrency,
  getResetPeriodFormattedDate,
  getReservableBestRule,
  getReservableMinutesFromUnits,
  getReservableFeatureNumberOfSlots,
  getDayUnavailableDateRangesFromTimeAvailability,
  getMonthUnavailableDateRangesFromTimeAvailability,
  getUnitTime,
  groupInteractionsByStatus,
  isAvailabilityValid,
  isMenuFeatureOrderItemValid,
  isPaymentAccountValidClientSide,
  isFreeOfCharge,
  isFeatureVisible,
  getChargeStatus,
  getInvoiceStatus,
  isInvoicePayable,
};
