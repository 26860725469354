import { TimeResetFrequencyEnum } from 'constants-content';
import { dateFormatter } from '../formatters';

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export default function getResetPeriodFormattedDate({ period, date }: any) {
  switch (period) {
    case TimeResetFrequencyEnum.Hourly:
      return dateFormatter(date, 'MMM d h:mm a, yyyy');
    case TimeResetFrequencyEnum.Daily:
      return dateFormatter(date, 'MMM d, yyyy');
    case TimeResetFrequencyEnum.Weekly:
      return dateFormatter(date, 'MMM d, yyyy');
    case TimeResetFrequencyEnum.Monthly:
      return dateFormatter(date, 'MMM yyyy');
    case TimeResetFrequencyEnum.Yearly:
      return dateFormatter(date, 'yyyy');
    case TimeResetFrequencyEnum.Never:
      return '';
  }
}
