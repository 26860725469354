import React, { memo, useContext, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { RendererContext } from 'lane-shared/contexts';
import createBlockInstance from 'lane-shared/renderers/v5/createBlockInstance';
import { ContentTypeEnum } from 'constants-content';

import { S } from 'components/typography';

import useFilterBlockDependencies from 'hooks/useFilterBlockDependencies';
import Input from '../form/Input';
import NewBlockMenuItem from './NewBlockMenuItem';

import DataBlockMenu from './DataBlockMenu';
import styles from './NewBlockMenu.scss';

type NewBlock = {
  _id: LaneType.UUID;
  block: ReturnType<typeof createBlockInstance>;
};

function NewBlockMenu({
  content,
  onNewBlockDragEnd,
  onNewBlockDragStart,
  onNewBlockAdd,
}: any) {
  const { t } = useTranslation();
  const { availableBlocks, tags } = useContext(RendererContext);
  const [collapsed] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 100);
  const filteredBlocks = useFilterBlockDependencies(availableBlocks);

  const examples = useMemo(() => {
    return filteredBlocks.reduce<{
      [key: string]: NewBlock;
    }>((acc, block) => {
      acc[block._id || block.name] = {
        _id: uuid(),
        block: createBlockInstance(block, true),
      };

      return acc;
    }, {});
  }, [filteredBlocks]);

  function filterBlocks(block: any, tag: any) {
    // Block is contains tag and matches search
    if (
      block.tags?.includes(tag) &&
      block.blockName.toLowerCase().includes(debouncedSearch.toLowerCase())
    ) {
      // if content contains type (building profiles don't)
      // make sure the block is available for that content type.
      if (content.type) {
        return block.availableForContentTypes?.includes(content.type);
      }

      // if it doesnt just return true and show the filtered blocks.
      return true;
    }

    return false;
  }

  function tagExistForFilteredBlocks(tag: any) {
    // Block is contains tag and matches search
    for (const block of filteredBlocks) {
      if (block.tags?.includes(tag)) {
        // if content contains type (building profiles don't)
        // make sure the block is available for that content type.
        if (
          content.type &&
          block.availableForContentTypes?.includes(content.type)
        ) {
          return true;
        }
      }
    }

    return false;
  }

  function getTranslatableBlockName(name: string) {
    if (!name || name.length === 0) {
      return t(`web.admin.channel.content.layout.editor.block.block`);
    }

    const translationPrefix = 'web.admin.channel.content.layout.editor.block.';
    const concatenatedName = name.replace(/ /g, '');
    const translatableName =
      concatenatedName.charAt(0).toLowerCase() + concatenatedName.slice(1);

    const translatedBlockName = t(`${translationPrefix}${translatableName}`);

    if (translatedBlockName.includes(translationPrefix)) {
      return t('web.admin.channel.content.layout.editor.properties.label', {
        property: name,
      });
    }

    return translatedBlockName;
  }

  return (
    <menu className={styles.NewBlockMenu} data-test="contentBlocks">
      {content.type !== ContentTypeEnum.WorkOrder && (
        <Input
          testId="blockSearch"
          className={styles.searchBar}
          value={search}
          icon="search"
          placeholder={t(
            'web.admin.channel.content.layout.editor.blockMenu.search'
          )}
          onChange={val => setSearch(val)}
          showClear
        />
      )}
      {content.isInteractive && (
        <DataBlockMenu
          onNewBlockDragEnd={onNewBlockDragEnd}
          onNewBlockDragStart={onNewBlockDragStart}
          onNewBlockAdd={onNewBlockAdd}
          content={content}
        />
      )}
      {!collapsed &&
        tags.map(
          tag =>
            tagExistForFilteredBlocks(tag) && (
              <section key={tag}>
                <S mt={4} mb={1} variant="secondary">
                  {getTranslatableBlockName(tag)}
                </S>
                {filteredBlocks
                  .filter(block => filterBlocks(block, tag))
                  .map(block => (
                    <NewBlockMenuItem
                      key={block._id || block.name}
                      block={block}
                      examples={examples}
                      onNewBlockDragEnd={onNewBlockDragEnd}
                      onNewBlockDragStart={onNewBlockDragStart}
                      onNewBlockAdd={onNewBlockAdd}
                    />
                  ))}
              </section>
            )
        )}
    </menu>
  );
}

export function areEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.content._id === nextProps.content._id &&
    prevProps.content.isInteractive === nextProps.content.isInteractive &&
    prevProps.content.integration?._id === nextProps.content.integration?._id
  );
}

export default memo(NewBlockMenu, areEqual);
