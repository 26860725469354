import i18n, { InitOptions } from 'i18next';
import Pseudo from 'i18next-pseudo';

import { ENVIRONMENTS } from 'constants-activate';

import { DefaultLocale, SupportedLocaleEnum } from './i18n';
import caES from './locales/ca-ES/translation.json';
import deAT from './locales/de-AT/translation.json';
import deDE from './locales/de-DE/translation.json';
import enGb from './locales/en-GB/translation.json';
import enUS from './locales/en-US/translation.json';
import esES from './locales/es-ES/translation.json';
import esMX from './locales/es_MX/translation.json';
import frCA from './locales/fr-CA/translation.json';
import frFR from './locales/fr-FR/translation.json';
import itIT from './locales/it-IT/translation.json';
import jaJP from './locales/ja-JP/translation.json';
import nlNL from './locales/nl-NL/translation.json';
import ptBR from './locales/pt-BR/translation.json';
import ptPT from './locales/pt-PT/translation.json';
import thTH from './locales/th-TH/translation.json';
import zhHK from './locales/zh-HK/translation.json';

export type TranslationKeys = keyof typeof enUS;

const resources = {
  [SupportedLocaleEnum.English]: {
    translation: enUS,
  },
  de: {
    translation: deDE,
  },
  pt: {
    translation: ptPT,
  },
  [SupportedLocaleEnum.PortugueseBrazil]: {
    translation: ptBR,
  },
  fr: {
    translation: frFR,
  },
  [SupportedLocaleEnum.FrenchCanadian]: {
    translation: frCA,
  },
  es: {
    translation: esES,
  },
  [SupportedLocaleEnum.EnglishUK]: {
    translation: enGb,
  },
  [SupportedLocaleEnum.Italian]: {
    translation: itIT,
  },
  [SupportedLocaleEnum.ChineseTraditional]: {
    translation: zhHK,
  },
  [SupportedLocaleEnum.Dutch]: {
    translation: nlNL,
  },
  [SupportedLocaleEnum.GermanAustrian]: {
    translation: deAT,
  },
  [SupportedLocaleEnum.Thai]: {
    translation: thTH,
  },
  [SupportedLocaleEnum.Japanese]: {
    translation: jaJP,
  },
  [SupportedLocaleEnum.SpanishCatalan]: {
    translation: caES,
  },
  [SupportedLocaleEnum.SpanishSpain]: {
    translation: esES,
  },
  [SupportedLocaleEnum.SpanishMexico]: {
    translation: esMX,
  },
};

const isPseudolocalizationEnabled =
  process.env.APP_ENV === ENVIRONMENTS.LOCAL &&
  process.env.USE_PSEUDO !== 'false';

if (isPseudolocalizationEnabled) {
  i18n.use(
    new Pseudo({
      languageToPseudo: 'en-US',
    })
  );
}

export type TranslationValueType = {
  message: string;
  description: string;
};

export function getStringValueOfTranslation(
  value: string | TranslationValueType
) {
  return typeof value === 'object'
    ? (value as TranslationValueType).message
    : value;
}

const config: InitOptions = {
  fallbackLng: DefaultLocale,
  keySeparator: '>',
  nsSeparator: '|',
  resources,
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
  compatibilityJSON: 'v3',
  postProcess: [],
  returnedObjectHandler: (_: any, value: any, options: any) => {
    return i18n.services.interpolator.interpolate(
      getStringValueOfTranslation(value),
      options,
      options.lng,
      options
    );
  },
};

if (isPseudolocalizationEnabled) {
  (config.postProcess as string[]).push('pseudo');
}

if (process.env.APP_ENV === ENVIRONMENTS.LOCAL) {
  config.parseMissingKeyHandler = (key: string): string => {
    return `!!! NO TRANSLATION: ${key} !!!`;
  };
}

i18n.init(config).catch(err => {
  console.error('i18n failed with: ', err);
});

export default i18n;
