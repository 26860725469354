/**
 * README
 *
 * Do not use this file directly, use the useLocation hook instead to access
 * location, or the startWatching, isWatching etc. functions.
 *
 * Location and this helper will be provided by the LocationContext.Provider
 * at the top level of both the web app and mobile app.
 */
import { setHeader } from 'lane-shared/apollo';
import {
  distance,
  mapGeolocationPositionToLocationType,
} from 'lane-shared/helpers';
import { HEADER_GEO_LOCATION } from 'constants-activate';
import emitter, {
  EVENT_LOCATION,
  EVENT_LOCATION_ERROR,
  EVENT_LOCATION_STOPPED,
} from 'lane-shared/helpers/emitter';
import friendlyDistance, {
  KM_TO_MILES_CONVERSATION_RATIO,
  DISTANCE_UNIT_KM,
  DISTANCE_UNIT_MILE,
} from 'lane-shared/helpers/formatters/friendlyDistance';
import { LocationType } from 'lane-shared/types/LocationType';

import getUserLocale from './getUserLocale';

export default class Location {
  static _isWatching = false;

  static listener: number | null = null;

  static location: LocationType = {
    accuracy: null,
    availability: null,
    distance: 0,
    error: null,
    noLocation: true,
    latitude: 0,
    longitude: 0,
  };

  static isWatching() {
    return Location._isWatching;
  }

  static _onPosition(
    newPosition: GeolocationPosition, // eslint-disable-line no-undef
    disableLocationPrecision: Boolean
  ): void {
    // Get the user information and store it in apollo client cache
    const newCoordinates = mapGeolocationPositionToLocationType(newPosition);

    Location.location = {
      ...Location.location,
      ...newCoordinates,
      noLocation: false,
    };

    if (Location.location.latitude === 0 && Location.location.longitude === 0) {
      Location.location = {
        ...Location.location,
        noLocation: true,
        error: new Error('Failed to set location'),
      };

      return;
    }

    const lat = disableLocationPrecision
      ? newPosition.coords.latitude.toFixed(1)
      : newPosition.coords.latitude;
    const long = disableLocationPrecision
      ? newPosition.coords.longitude.toFixed(1)
      : newPosition.coords.longitude;

    setHeader(HEADER_GEO_LOCATION, `${long}, ${lat}`);

    Location._isWatching = true;

    emitter.emit(EVENT_LOCATION, Location.location);
  }

  /**
   * HTML5 geo-location works a bit differently than on a mobile device,
   * there is no extra step to request permission and then start watching
   * both are done at the same time.  We use this wrapper to standardize
   * across the two platforms.
   */
  static async _requestPermission(): Promise<boolean> {
    return new Promise(resolve => {
      navigator.geolocation.watchPosition(
        newPosition => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
          Location._onPosition(newPosition);
          resolve(true);
        },
        error => {
          resolve(error.code === error.PERMISSION_DENIED);
        }
      );
    });
  }

  static async requestPermission() {
    return Location._requestPermission();
  }

  static async isLocationGranted() {
    return Location._requestPermission();
  }

  static stopWatching() {
    if (Location.listener !== null) {
      navigator.geolocation.clearWatch(Location.listener);
    }

    Location._isWatching = false;
    emitter.emit(EVENT_LOCATION_STOPPED);
  }

  static async startWatching({ disableLocationPrecision }: any) {
    if (this._isWatching) {
      return;
    }

    Location.listener = navigator.geolocation.watchPosition(
      pos => Location._onPosition(pos, disableLocationPrecision),
      error => {
        Location.stopWatching();
        Location.location = {
          ...Location.location,
          noLocation: true,
          error,
        };
        emitter.emit(EVENT_LOCATION_ERROR, error);
      },
      { timeout: 5000, maximumAge: 5000, enableHighAccuracy: false }
    );
  }

  static getDistance({ latitude, longitude }: any) {
    if (!Location._isWatching) {
      return '';
    }

    const meters = distance(
      Location.location.latitude,
      Location.location.longitude,
      latitude,
      longitude
    );

    // If user locale is US, return miles, else return kms.
    if (getUserLocale().includes('-US')) {
      return friendlyDistance(
        meters * KM_TO_MILES_CONVERSATION_RATIO,
        DISTANCE_UNIT_MILE
      );
    }

    return friendlyDistance(meters, DISTANCE_UNIT_KM);
  }
}
