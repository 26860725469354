import { useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import ObjectTouchHelper from '../../helpers/ObjectTouchHelper';
import { ANALYTIC_KEYS } from 'constants-analytics';
import { EVENT_KEYS } from 'constants-events';
import { DocumentType } from '../../types/DocumentType';
import { Channel } from '../../types/ChannelType';

/**
 * Used for analytics tracking in lane-web and lane-mobile.
 */
export function useChannelAnalytics() {
  const analytics = useContext(AnalyticsContext);

  function trackChannelDetails(channel: DocumentType) {
    ObjectTouchHelper.touch(
      channel,
      ObjectTouchHelper.mapAction('details'),
      'Channel'
    );

    analytics.track(ANALYTIC_KEYS.ANALYTIC_CHANNEL_DETAILS, {
      channelId: channel?._id,
    });
  }

  function trackChannelView(channel: DocumentType) {
    ObjectTouchHelper.touch(
      channel,
      ObjectTouchHelper.mapAction('view'),
      'Channel'
    );

    analytics.track(ANALYTIC_KEYS.ANALYTIC_CHANNEL_VIEW, {
      channelId: channel?._id,
    });
  }

  function trackReservableManagerEnabled(channel: Channel) {
    // FIXME: Event being sent as analytic
    // @ts-ignore
    analytics.track(EVENT_KEYS.EVENT_CHANNEL_RESERVABLE_MANAGER_ENABLED, {
      isReservableManagerEnabledForChannel: true,
      channelId: channel._id,
      channelName: channel.name,
    });
  }

  return {
    trackChannelDetails,
    trackChannelView,
    trackReservableManagerEnabled,
  };
}
