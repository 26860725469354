import { PERMISSION_KEYS } from 'constants-permissions';

import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { EssensysProductInteractionType } from '../../../types/features/EssensysProductFeatureProperties';
import { FeatureNameEnum } from 'constants-content';
import { SecurityRuleTypeEnum } from '../../../types/properties/PropertySecurity';

const EssensysProduct: FeatureDefinition = {
  name: FeatureNameEnum.EssensysProduct,
  permission: PERMISSION_KEYS.PERMISSION_CONTENT_FEATURE_ESSENSYS,
  requiresInteraction: true,
  friendlyName: 'web.content.feature.essensys.friendlyName',
  description: 'web.content.feature.essensys.description',
  properties: {
    productInteractionType: {
      friendlyName: 'Transaction Type',
      description: 'The transaction type based on the Operate product.',
      type: 'String',
      default: EssensysProductInteractionType.PointOfSale,
      editable: false,
      showControl: false,
      isArray: false,
      forDisplay: false,
      hidden: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'In',
          value: Object.values(EssensysProductInteractionType),
        },
      ],
    },
    productId: {
      friendlyName:
        'web.content.feature.essensys.properties.productId.friendlyName',
      description:
        'web.content.feature.essensys.properties.productId.description',
      type: 'String',
      default: null,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
      editable: true,
    },
    syncName: {
      friendlyName:
        'web.content.feature.essensys.properties.syncName.friendlyName',
      description:
        'web.content.feature.essensys.properties.syncName.description',
      type: 'Boolean',
      default: true,
      isArray: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    syncPrice: {
      friendlyName:
        'web.content.feature.essensys.properties.syncPrice.friendlyName',
      description:
        'web.content.feature.essensys.properties.syncPrice.description',
      type: 'Boolean',
      default: true,
      isArray: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    ignorePublish: {
      friendlyName:
        'web.content.feature.essensys.properties.ignorePublish.friendlyName',
      description:
        'web.content.feature.essensys.properties.ignorePublish.description',
      type: 'Boolean',
      default: true,
      isArray: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    ignoreEndDate: {
      friendlyName:
        'web.content.feature.essensys.properties.ignoreEndDate.friendlyName',
      description:
        'web.content.feature.essensys.properties.ignoreEndDate.description',
      type: 'Boolean',
      default: true,
      isArray: false,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    productData: {
      type: 'JSON',
      friendlyName:
        'web.content.feature.essensys.properties.productData.friendlyName',
      description:
        'web.content.feature.essensys.properties.productData.description',
      default: null,
    },
  },
  interactionData: {
    bookingId: {
      friendlyName:
        'web.content.feature.essensys.interactionData.bookingId.friendlyName',
      description:
        'web.content.feature.essensys.interactionData.bookingId.description',
      type: 'String',
      validators: [],
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    accountId: {
      friendlyName:
        'web.content.feature.essensys.interactionData.accountId.friendlyName',
      description:
        'web.content.feature.essensys.interactionData.accountId.description',
      type: 'String',
      validators: [],
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    contactId: {
      friendlyName:
        'web.content.feature.essensys.interactionData.contactId.friendlyName',
      description:
        'web.content.feature.essensys.interactionData.contactId.description',
      type: 'String',
      validators: [],
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
    status: {
      friendlyName:
        'web.content.feature.essensys.interactionData.status.friendlyName',
      description:
        'web.content.feature.essensys.interactionData.status.description',
      type: 'String',
      secure: {
        create: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
        update: [
          {
            type: SecurityRuleTypeEnum.System,
          },
        ],
      },
    },
  },
};

export default EssensysProduct;
