import { MobilePermissionEnum } from 'constants-permissions';
import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

const GallagherDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Gallagher,
  hasCustomUI: true,
  friendlyName: 'Gallagher Access Control',
  description: `Defines the properties required for a Gallagher integration.`,
  category: IntegrationCategoryEnum.AccessControl,
  platforms: [PlatformEnum.Mobile],
  type: IntegrationTypeEnum.SDK,
  settings: {},
  properties: {
    supportPage: {
      friendlyName: 'Support Page',
      type: 'Content',
    },
    invitationBaseUrl: {
      friendlyName: 'Invitation Base URL',
      type: 'String',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  mobilePermissions: [
    MobilePermissionEnum.Bluetooth,
    MobilePermissionEnum.SecureDevice,
  ],
  // Google requires us to use specific language requesting background location access before we load the system prompts
  mobilePermissionsRequest:
    'To unlock doors, Workplace Access requires these device settings to be enabled, including accessing your location when the application is not in use:',
};

export default GallagherDefinition;
