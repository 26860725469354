import { ApolloQueryResult } from '@apollo/client';

import { LaneType } from 'common-types';
import { LocationType } from '../../types/LocationType';
import {
  PresetContentFilter,
  PresetContentSort,
  ContentGroupByEnum,
} from 'constants-content';
import { SearchOptions } from '../../types/filters/SearchOptions';
import { ContentTag } from 'lane-shared/types/content/ContentTag';
import { Channel } from 'lane-shared/types/ChannelType';

export type SectionChannelType = Pick<
  Channel,
  | '_id'
  | '_distance'
  | 'name'
  | 'slug'
  | 'type'
  | 'parent'
  | 'address'
  | 'profile'
>;

export type SectionType = {
  _id: LaneType.UUID;
  _updated: Date;
  filters: PresetContentFilter[];
  sorts: PresetContentSort[];
  channel: SectionChannelType;
  groupBy?: ContentGroupByEnum;
  sectionMetatags: any[];
  tagsOnSection?: ContentTag[];
  name?: string;
};

export type UseSectionQueryProps = {
  autoHide?: boolean;
  autoSearch?: boolean;
  sectionId: string | null | undefined;
  location?: LocationType;
  pollInterval?: number;
  defaultSearchOptions?: Partial<SearchOptions>;
  /** @deprecated todo: refactor this to replace its use with defaultSearchOptions */
  searchOptionsOverride?: SearchOptions;
};

export type GroupedContentType = { [key: string]: any[] };

export type UseSectionQueryReturn = {
  section: SectionType | null;
  sectionContent: any[];
  sectionContentGroups: GroupedContentType | null;
  channels: any[];
  searchOptions: SearchOptions | null;
  initialSearchOptions: SearchOptions | null;
  editingSearchOptions: SearchOptions | null;
  hasChannelSelector: boolean;
  hasChannelLocationsSelector: boolean;
  hasEventDateSelector: boolean;
  selectedChannel: SectionChannelType | null;
  updateSelectedChannel: (
    channel: SectionChannelType | null | undefined,
    blockId?: string
  ) => void;
  hasSearch: boolean;
  hasFilters: boolean;
  isHidden: boolean;
  fetchSectionContent: (
    variables?:
      | { id: string | null | undefined; searchOptions: SearchOptions | null }
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
  updateSearchOptions: (options: Partial<SearchOptions>) => void;
  resetSearchOptions: (resetSection?: SectionType | null | undefined) => void;
  applySearchOptions: () => void;
  error: Error | undefined;
  called: boolean;
  loading: boolean;
  contentLoading: boolean;
  sectionLoading: boolean;
};

export type UseSectionSearchOptionsProps = {
  sectionId: LaneType.UUID | null | undefined;
  section: SectionType | null | undefined;
  location: LocationType | null | undefined;
  channels: SectionChannelType[];
  autoApply: boolean;
  defaultSearchOptions?: Partial<SearchOptions>;
};

export type UseSectionSearchOptionsReturn = {
  searchOptions: SearchOptions | null;
  editingSearchOptions: SearchOptions | null;
  initialSearchOptions: SearchOptions | null;
  hasChannelSelector: boolean;
  hasChannelLocationsSelector: boolean;
  selectedChannel: SectionChannelType | null;
  updateSelectedChannel: (
    channel: SectionChannelType | null | undefined,
    blockId?: string | undefined
  ) => void;
  hasSearch: boolean;
  hasFilters: boolean;
  hasEventDateSelector: boolean;
  isSearchOptionsReady: boolean;
  updateSearchOptions: (options: Partial<SearchOptions>) => void;
  resetSearchOptions: (resetSection?: SectionType | null | undefined) => void;
  applySearchOptions: () => void;
};

export enum ChannelUpdateType {
  initial = 'initial',
  start = 'start',
}

export type ChannelTransactionData = {
  blockId?: string;
  channel: SectionChannelType | null;
};

export type ChannelTransactionState = {
  type: keyof typeof ChannelUpdateType;
  data: ChannelTransactionData | null;
};
