import getMicrosoftGraphClient from '../../services/microsoftGraphClient';
import Storage from '../Storage';
import { OAuthProvidersEnum } from 'constants-user';

const NO_ERROR = null;
const AZURE_AD_AUTH_STATE = `${OAuthProvidersEnum.AZURE_AD}:AUTH_STATE`;

type AuthorizeResult = {
  accessToken: string;
  accessTokenExpirationDate: string;
  authorizeAdditionalParameters?: { [name: string]: string };
  tokenAdditionalParameters?: { [name: string]: string };
  idToken: string;
  refreshToken: string;
  tokenType: string;
  scopes: string[];
  authorizationCode: string;
  codeVerifier?: string;
};

export default async function getAzureADUserPhoto(): Promise<Blob | undefined> {
  let authState: AuthorizeResult;

  try {
    authState = (await Storage.getItem(AZURE_AD_AUTH_STATE)) as AuthorizeResult;
    // FIXME: log error for datadog, missing stack trace
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (ex) {
    // The access token hasn't been saved. There's no user, we can return.
    return;
  }

  const microsoftGraphClient = getMicrosoftGraphClient(callback => {
    callback(NO_ERROR, authState.accessToken);
  });

  const photo = await microsoftGraphClient
    .api('/me/photo/$value')
    .version('beta')
    .get();

  return photo;
}
