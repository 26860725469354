import { PIN_LAYOUTS, PIN_STANDARD } from 'constants-content';
import { Primitive } from '../../../types/blocks/Primitive';

const SectionContentListBlock: Primitive = {
  name: 'SectionContentListBlock',
  friendlyName: 'Section Content List',
  isAvailableInEditor: true,
  isInput: false,
  isPrimitive: true,
  tags: ['Content'],
  properties: {
    header: {
      friendlyName: 'Header',
      type: 'String',
      validators: [
        {
          name: 'Max',
          value: 80,
        },
      ],
    },
    section: {
      type: 'Section',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    listView: {
      friendlyName: 'Pin View',
      type: 'String',
      default: PIN_STANDARD,
      validators: [
        {
          name: 'In',
          value: PIN_LAYOUTS,
        },
      ],
    },
    showFilters: {
      friendlyName: 'Show Search & Filtering',
      description:
        'Show search and filtering for this section (if it has been enabled on the section)',
      default: false,
      type: 'Boolean',
    },
    flexDirection: {
      type: 'FlexDirection',
      friendlyName: 'Layout',
    },
    perPage: {
      type: 'Number',
      friendlyName: 'Items per page',
      default: 5,
      validators: [
        {
          name: 'Min',
          value: 5,
        },
        {
          name: 'Max',
          value: 15,
        },
      ],
    },
    autoHide: {
      type: 'Boolean',
      friendlyName: 'Hide when no results',
      default: false,
    },
    showViewAll: {
      type: 'Boolean',
      friendlyName: 'Show View All',
      default: false,
    },
  },
  publishes: {
    // @ts-expect-error ts-migrate(2741) FIXME: Property 'action' is missing in type '{ type: "Eve... Remove this comment to see the full error message
    click: {
      type: 'Event',
    },
  },
};

export default SectionContentListBlock;
