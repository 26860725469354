import React, { useContext, useRef, useState } from 'react';

import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, generatePath } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getQueryString } from 'lane-shared/helpers';
import { useCreateDraft } from 'lane-shared/hooks';
import { ContentTypeEnum } from 'constants-content';

import ErrorMessage from '../../general/ErrorMessage';
import Loading from '../../general/Loading';
import DraftContentHeader from '../DraftContent/DraftContentHeader';
import DraftContentPublish from '../DraftContent/DraftContentPublish';
import DraftContentTargetingEdit from '../DraftContent/DraftContentTargetingEdit';
import { DraftContentWarnings } from '../DraftContent/DraftContentWarnings';
import DraftNoticeInfo from './DraftNoticeInfo';
import {
  steps,
  stepValidation,
  STEP_PUBLISH,
  STEP_TARGETING,
  STEP_DRAFT,
} from './draftNoticeSteps';
import useContentTargetingMissingModal from 'hooks/useContentTargetingMissingModal';

import styles from './DraftNotice.scss';
import { useDraftContentAnalytics } from 'lane-shared/hooks/analytics';

export default function DraftNotice({
  forCreate,
  forEdit,
  channel,
  onUndo,
  draft,
  liveContent,
}: any) {
  const { user } = useContext(UserDataContext);
  const history = useHistory();
  const location = useLocation();
  const fromRef = useRef((location as any).state?.from);
  const [query, goToUrl] = useQueryString({ step: STEP_DRAFT });
  const [errorSteps, setErrorSteps] = useState([]);
  const selectedStep = query.step || STEP_DRAFT;
  const {
    content,
    loading,
    error,
    validation,
    timeZone,
    hasChanged,
    validateStep,
    updateContent,
    updateTimeZone,
    addNotification,
    undoChanges,
    saveDraft,
    createDraft,
    publishDraft,
    unpublishContent,
    deleteDraft,
  } = useCreateDraft({
    contentType: ContentTypeEnum.Notice,
    stepValidation,
    step: selectedStep,
    draft,
    channel,
    forCreate,
    forEdit,
  });
  const { t } = useTranslation();

  const { bootstrapStepper, draftContentTracker } = useDraftContentAnalytics({
    draftContent: content,
  });
  const stepperTracker = bootstrapStepper(forCreate);

  const { showContentTargetingMissingModal } =
    useContentTargetingMissingModal(styles);

  async function validateAllSteps() {
    const errorSteps = [];

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i]!;

      if (!stepValidation[step]) {
        continue;
      }

      try {
        await stepValidation[step].validate(
          { ...content, isEvent: false },
          {
            abortEarly: false,
          }
        );
        // FIXME: Log error for datadog, missing stack trace
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (err) {
        errorSteps.push(i);
      }
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number[]' is not assignable to p... Remove this comment to see the full error message
    setErrorSteps(errorSteps);
  }

  async function goQuery(props: any) {
    validateAllSteps();

    if (!(await validateStep())) {
      return;
    }

    goToUrl(props);
  }

  async function onDelete() {
    try {
      await window.Alert.confirm({
        title: t('Delete this notice?'),
        message: t('Are you sure you want to delete {{contentName}}?', {
          contentName: content.name,
        }),
        confirmText: t('Delete'),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled.
      return;
    }

    if (await deleteDraft()) {
      history.push(`/l/channel/${channel.slug}/admin/content-center`);
    }
  }

  async function onUnpublishContent() {
    try {
      await window.Alert.confirm({
        title: t('Unpublish {{contentName}}?', { contentName: content.name }),
        message: t('Are you sure you want to unpublish {{contentName}}?', {
          contentName: content.name,
        }),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled
      return;
    }

    window.Alert.loading({
      title: t('Unpublishing…'),
    });

    if (await unpublishContent()) {
      onUndo();
      draftContentTracker.Update.Unpublish(content);
      window.Toast.show(
        t('{{contentName}} has been unpublished.', {
          contentName: content.name,
        })
      );
    }

    window.Alert.hide();
  }

  async function onSaveClicked() {
    if (forCreate) {
      const saved = await createDraft();

      if (saved) {
        history.push(
          generatePath(routes.channelAdminDraft, {
            id: channel.slug,
            draftId: saved._id,
          }) + getQueryString(query),
          { from: fromRef.current }
        );
      }
    } else {
      await saveDraft();
    }
  }

  async function onPublish() {
    try {
      // if there is no targeting set for content (no placements), do not allow publishing:
      if (!content?.placements?.length && !content?.audiences?.length) {
        showContentTargetingMissingModal();

        return;
      }

      await window.Alert.confirm({
        title: t('Send this notice?'),
        message: t(
          'Are you sure you want to publish {{contentName}}? This will make this notice live.',
          { contentName: content.name }
        ),
        confirmText: t('Publish'),
      });
      // FIXME: Log error for datadog, missing stack trace
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      // user cancelled.
      return;
    }

    window.Alert.loading({
      title: t('Publishing…'),
    });

    if (await publishDraft()) {
      draftContentTracker.Update.Publish(content);
      history.push(`/l/channel/${channel.slug}/admin/post/${content._id}`);
    }

    window.Alert.hide();
  }

  async function onUndoClicked() {
    await undoChanges();
    onUndo();
  }

  async function onCancel() {
    if (forEdit) {
      await undoChanges();
    }

    if (fromRef.current) {
      history.push(fromRef.current);
    } else {
      history.goBack();
    }
  }

  // wait until the content is setup to render.
  if (!content && error) {
    return <ErrorMessage error={error} />;
  }

  if (!content) {
    return <Loading />;
  }

  return (
    <div className={styles.DraftNotice}>
      <DraftContentHeader
        loading={loading}
        forEdit={forEdit}
        forCreate={forCreate}
        hasChanged={hasChanged}
        onUndoClicked={forEdit ? onUndoClicked : undefined}
        onSaveClicked={onSaveClicked}
        onUnpublishClicked={liveContent ? onUnpublishContent : undefined}
        onPublishClicked={forEdit ? onPublish : undefined}
        onCancelClicked={onCancel}
        onDeleteClicked={forEdit && !liveContent ? onDelete : undefined}
        onGoStep={goQuery}
        // @ts-expect-error ts-migrate(4104) FIXME: The type 'readonly ["Draft", "Targeting", "Publish... Remove this comment to see the full error message
        steps={steps}
        errorSteps={errorSteps}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number | boolean | Date | (string |... Remove this comment to see the full error message
        currentStep={query.step}
      />

      <ErrorMessage className={styles.errors} error={error} />
      <DraftContentWarnings hasChanged={hasChanged} {...content} />
      {selectedStep === STEP_DRAFT && (
        <DraftNoticeInfo
          onContentUpdated={updateContent}
          channel={channel}
          user={user}
          validation={validation}
          content={content}
        />
      )}

      {selectedStep === STEP_TARGETING && (
        <DraftContentTargetingEdit
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message
          user={user}
          hasChanged={hasChanged}
          content={content}
          channel={channel}
          onContentUpdated={updateContent}
          stepperTracker={stepperTracker}
        />
      )}

      {selectedStep === STEP_PUBLISH && (
        <div className={styles.contents}>
          <DraftContentPublish
            timeZone={timeZone}
            content={content}
            validation={validation}
            onContentUpdated={updateContent}
            onAddNotification={addNotification}
            liveContent={liveContent}
            updateTimezone={updateTimeZone}
            channel={channel}
            stepperTracker={stepperTracker}
          />
        </div>
      )}
    </div>
  );
}
