import { explodeFeatures } from 'lane-shared/helpers/features';
import { ContentType } from 'lane-shared/types/content/Content';
import { PaymentFeaturePaymentAmountType } from 'lane-shared/types/features/PaymentFeatureProperties';
import { PaymentTypeEnum } from 'constants-payments';

/*
 * Get the payment amount (for the PaymentType)
 *
 * * */
export default function getPaymentPrice(
  content: ContentType,
  paymentType: PaymentTypeEnum
): number | null {
  const { paymentFeature } = explodeFeatures(content?.features);
  const paymentAmountData: PaymentFeaturePaymentAmountType | undefined =
    paymentFeature?.amounts?.filter(amount => amount.type === paymentType)[0];
  const paymentAmount: number | undefined = paymentAmountData?.amount;

  // explicitly return the zero amount (0 is falsy in js)
  if (paymentAmount === 0) {
    return paymentAmount;
  }

  // valid number then return it
  if (Number.isFinite(paymentAmount)) return paymentAmount ?? null;

  // default to null, we got bad data so return null
  return null;
}
