import { useEffect, useMemo, useRef, useState } from 'react';

import gql from 'graphql-tag';

import { getClient } from '../apollo';
import { UserLoginProviderEnum } from 'constants-user';
import useUserLoginsQuery from './useUserLoginsQuery';

const essensysAccountQuery = gql`
  query EssensysAccounts($contactIds: [String!]!) {
    essensys {
      accounts(contactIds: $contactIds) {
        _id
        name
        balance
        currencyCode
      }
    }
  }
`;

export type InvoiceAccount = {
  _id: string;
  name: string;
  balance: number;
  currencyCode: string;
};

type EssensysAccountType = {
  essensys: {
    accounts: InvoiceAccount[];
  };
};

export default function useEssensysUserMetadata() {
  const [accounts, setAccounts] = useState<InvoiceAccount[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [, setAccountsError] = useState<Error | null>(null);
  const isCancelled = useRef<boolean>(false);

  const [userLogins, userLoginsLoading, userLoginsError] = useUserLoginsQuery();

  const essensysUserLogins = useMemo(() => {
    return userLogins.filter(
      userLogin => userLogin.provider === UserLoginProviderEnum.Essensys
    );
  }, [userLogins]);

  const isEssensysUser = essensysUserLogins.length !== 0;

  async function fetchAccountsFromContactIds(contactIds: string[]) {
    if (contactIds.length === 0) {
      return;
    }

    if (!isCancelled.current) {
      setLoading(true);
      setAccountsError(null);
    }

    try {
      const response = await getClient().query<
        EssensysAccountType,
        { contactIds: string[] }
      >({
        query: essensysAccountQuery,
        variables: {
          contactIds,
        },
        fetchPolicy: 'network-only',
      });

      if (!isCancelled.current) {
        setAccounts(response.data.essensys.accounts);
      }
    } catch (err) {
      setAccountsError(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    isCancelled.current = false;

    const contactIds = essensysUserLogins.map(eul => eul.key);

    fetchAccountsFromContactIds(contactIds);

    return () => {
      isCancelled.current = true;
    };
  }, [essensysUserLogins]);

  const isEssensysAdmin = Boolean(accounts?.length);

  return {
    isEssensysUser,
    isEssensysAdmin,
    loading: userLoginsLoading || loading,
    userLoginsError,
    accounts,
  } as const;
}
