import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from 'lane-shared/types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';

const Auth0SAMLIntegrationDefinition: IntegrationDefinition = {
  name: IntegrationProviderEnum.Auth0SAML,
  allowsMultipleInstances: true,
  hasCustomUI: true,
  friendlyName: 'Auth0 SAML As Identity Provider',
  getChannelIntegrationName(integrationData): string {
    return `Auth0 SAML - ${integrationData.settings.pageTitle}`;
  },
  description:
    'Defines properties for a SAML integration using Lane as the identity provider.',
  category: IntegrationCategoryEnum.Service,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.WebView,
  settings: {},
  properties: {
    pageTitle: {
      friendlyName: 'Page Title',
      type: 'String',
      description: 'The title of the page',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    description: {
      friendlyName: 'Page Description',
      type: 'LongText',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    serviceURL: {
      friendlyName: 'Service URL',
      type: 'Url',
      description: 'The SAML service provider service endpoint URL.',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
  mobilePermissions: [],
};

export { Auth0SAMLIntegrationDefinition };
