import { useEffect } from 'react';

import { useChannelAnalytics } from './useChannelAnalytics';
import { Channel } from '../../types/ChannelType';

export function useTrackChannelDetails(channel: Channel) {
  const { trackChannelDetails } = useChannelAnalytics();

  useEffect(() => {
    if (channel?._id) {
      trackChannelDetails(channel);
    }
  }, [channel?._id]);
}
