import { colors } from 'constants-colors';
import React from 'react';

export default function IconThumbtack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        fill="url(#paint0_linear)"
        d="M29.344 20.766l-.282-2.016h1.313a1.11 1.11 0 001.125-1.125v-4.5A1.14 1.14 0 0030.375 12h-12.75a1.11 1.11 0 00-1.125 1.125v4.5c0 .656.469 1.125 1.125 1.125h1.266l-.282 2.016C16.36 22.313 15 24.703 15 27.375c0 .656.469 1.125 1.125 1.125h6.75v5.015l.75 2.25c.094.329.61.329.703 0l.75-2.25c0-.046.047-.093.047-.14V28.5h6.75A1.11 1.11 0 0033 27.375c0-2.672-1.406-5.063-3.656-6.61zm-12 5.484c.375-1.781 1.687-3.281 3.375-4.078l.75-5.672H18.75v-2.25h10.5v2.25h-2.719l.75 5.672c1.64.797 2.953 2.297 3.328 4.078H17.344z"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          x2="47.973"
          y1="48"
          y2="49.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.laneGreen} />
          <stop offset="1" stopColor="#528FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
