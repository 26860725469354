// Permits importing from modules outside enforced boundaries
import React from 'react';

import { Route } from 'react-router-dom';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { hasPermission } from 'lane-shared/helpers';
import {
  FONT_AWESOME_SOLID,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { PERMISSION_KEYS } from 'constants-permissions';
import { SIDE_BAR_LINKS_ORDER } from 'lane-shared/helpers/constants/sidebarLinksOrder';
import { UserType } from 'lane-shared/types/User';

import {
  HardwareAccessLogsPageConfig,
  HardwareAddPageConfig,
  HardwareDetailsPageConfig,
  HardwareDevicesPageConfig,
  HardwareManagementPage,
  RelayDetailsPageConfig,
  RelayListPageConfig,
  HardwarePinCodesPageConfig,
  HardwareAddPinCodesPageConfig,
  HardwareDetailPinCodesPageConfig,
} from './pageConfig';
import {
  SidebarCategoryType,
  SidebarLinkType,
} from 'hooks/useChannelSidebarLinks';
import { Channel } from 'packages/lane-shared/types/ChannelType';

export const HardwareManagementRoutes = ({
  urlPath,
  channel,
  user,
}: {
  urlPath: string;
  channel?: Channel; // TODO: type me ChannelType
  user: UserType;
}): JSX.Element | null => {
  const hardwarePinManagementFlag = useFlag<boolean>(
    FeatureFlag.HardwarePinManagement,
    false
  );

  if (!channel?.settings?.hasHardwareManagementEnabled) return null;

  const hasAnyPermission = (permissions: string[]): Boolean => {
    return (
      user.isSuperUser ||
      (!!channel?._id && hasPermission(user.roles, permissions, channel?._id))
    );
  };

  if (
    !hasAnyPermission([
      PERMISSION_KEYS.PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE,
    ])
  ) {
    return null;
  }

  const entries: [string, HardwareManagementPage][] = [];

  entries.push([HardwareDevicesPageConfig.key, HardwareDevicesPageConfig]);
  entries.push([HardwareDetailsPageConfig.key, HardwareDetailsPageConfig]);
  entries.push([RelayDetailsPageConfig.key, RelayDetailsPageConfig]);
  entries.push([RelayListPageConfig.key, RelayListPageConfig]);

  if (hasAnyPermission([PERMISSION_KEYS.PERMISSION_ADMIN])) {
    entries.push([HardwareAddPageConfig.key, HardwareAddPageConfig]);
  }

  if (
    hardwarePinManagementFlag &&
    channel?.settings?.hasHardwarePinManagementEnabled &&
    hasAnyPermission([PERMISSION_KEYS.PERMISSION_PIN_MANAGEMENT_VIEW])
  ) {
    entries.push([HardwarePinCodesPageConfig.key, HardwarePinCodesPageConfig]);
  }

  if (
    hardwarePinManagementFlag &&
    channel?.settings?.hasHardwarePinManagementEnabled &&
    hasAnyPermission([
      PERMISSION_KEYS.PERMISSION_PIN_MANAGEMENT_CREATE_EDIT_DELETE,
    ])
  ) {
    entries.push([
      HardwareAddPinCodesPageConfig.key,
      HardwareAddPinCodesPageConfig,
    ]);

    entries.push([
      HardwareDetailPinCodesPageConfig.key,
      HardwareDetailPinCodesPageConfig,
    ]);
  }

  entries.push([
    HardwareAccessLogsPageConfig.key,
    HardwareAccessLogsPageConfig,
  ]);

  return (
    <>
      {entries.map(([key, { Page, path }]) => {
        return (
          <Route key={key} exact path={`${urlPath}/${path}`}>
            <Page channel={channel} hasAnyPermission={hasAnyPermission} />
          </Route>
        );
      })}
    </>
  );
};

const buildLinks = (
  url: string,
  channel: Channel,
  hardwarePinManagementFlag: boolean | undefined,
  user: UserType
): SidebarLinkType[] => {
  const links: SidebarLinkType[] = [];

  function hasAnyPermission(
    user: UserType,
    permissions: string[],
    channelId: string
  ) {
    return (
      user.isSuperUser ||
      (channelId && hasPermission(user.roles, permissions, channelId))
    );
  }

  if (
    !hasAnyPermission(
      user,
      [PERMISSION_KEYS.PERMISSION_PROPERTY_HARDWARE_VIEW_UPDATE],
      channel._id
    )
  ) {
    return links;
  }

  links.push({
    exact: true,
    route: `${url}/${HardwareDevicesPageConfig.path}`,
    name: HardwareDevicesPageConfig.name(),
    headerMessage: HardwareDevicesPageConfig.headerMessage(),
    order: HardwareDevicesPageConfig.order,
  });

  links.push({
    exact: true,
    route: `${url}/${HardwareAccessLogsPageConfig.path}`,
    name: HardwareAccessLogsPageConfig.name(),
    headerMessage: HardwareAccessLogsPageConfig.headerMessage(),
    order: HardwareAccessLogsPageConfig.order,
  });

  if (
    hardwarePinManagementFlag &&
    channel?.settings?.hasHardwarePinManagementEnabled &&
    hasAnyPermission(
      user,
      [PERMISSION_KEYS.PERMISSION_PIN_MANAGEMENT_VIEW],
      channel._id
    )
  ) {
    links.push({
      exact: true,
      route: `${url}/${HardwarePinCodesPageConfig.path}`,
      name: HardwarePinCodesPageConfig.name(),
      headerMessage: HardwarePinCodesPageConfig.headerMessage(),
      order: HardwarePinCodesPageConfig.order,
    });
  }

  return links;
};

export function hardwareManagementNavigation(
  url: string,
  channel: Channel,
  hardwarePinManagementFlag: boolean | undefined,
  user: UserType,
  t: (key: string) => string
): SidebarCategoryType {
  return {
    icon: 'tablet-alt',
    iconSet: ICON_SET_FONTAWESOME,
    iconType: FONT_AWESOME_SOLID,
    name: t('web.admin.hardwareManagement.navigation.headerMessage'),
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.HARDWARE_MANAGEMENT,
    links: buildLinks(url, channel, hardwarePinManagementFlag, user),
  };
}
