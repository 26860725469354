import { IntegrationProviderEnum } from 'constants-integrations';

const integrationProviders = Object.values(IntegrationProviderEnum);

/**
 * Give the data that is stored in an interaction, determine which, if any
 * integration maybe linked to it.
 *
 * @param interaction
 */
export default function getInteractionIntegrationProvider(
  interaction: any
): IntegrationProviderEnum | undefined {
  if (!interaction?.data) {
    return undefined;
  }

  return integrationProviders.find(value => !!interaction.data[`_${value}`]);
}
