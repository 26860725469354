import { isEmpty } from 'lodash';
import { ModuleCategoryEnum } from 'lane-shared/types/modules/modulesEnums';
import { ModuleDefinition } from 'lane-shared/types/modules/moduleDefinition';

const VERSION_2 = 2;
const VERSION_1 = 1;

export function getWorkorderModuleOOTBConfigurationVersion(
  channelModules: ModuleDefinition | ModuleDefinition[]
) {
  const channelModulesArray = Array.isArray(channelModules)
    ? channelModules
    : [channelModules];

  if (isEmpty(channelModulesArray)) {
    throw new Error('Channel modules not found.');
  }

  const workOrderModule = channelModulesArray.find(
    channelModule => channelModule.name === ModuleCategoryEnum.WorkOrders
  ) as ModuleDefinition;

  if (isEmpty(workOrderModule)) {
    throw new Error('Channel modules not found.');
  }

  return workOrderModule?.settings?._version || VERSION_1;
}

export function checkOotbConfigurationV2Enabled(
  channelModule: ModuleDefinition | ModuleDefinition[]
) {
  try {
    return (
      getWorkorderModuleOOTBConfigurationVersion(channelModule) === VERSION_2
    );
  } catch {
    return false;
  }
}
