import { v4 as uuid } from 'uuid';

import { BLOCK_LOCK_MODES } from 'constants-content';

export const REQUIRED_PAYMENT_BLOCK_NAME = 'Required Payment Block';
// NOTE: increment revision to have changes apply to existing content (on save)
export const REQUIRED_PAYMENT_BLOCK_REVISION = 1;

export default {
  _id: uuid(),
  lock: [BLOCK_LOCK_MODES.ALL],
  name: REQUIRED_PAYMENT_BLOCK_NAME,
  revision: REQUIRED_PAYMENT_BLOCK_REVISION,
  primitive: 'ViewBlock',
  children: [
    {
      _id: uuid(),
      primitive: 'RequirementsBlock',
      properties: {},
      style: {},
      subscribe: {
        click: {
          _bind: true,
          publish: 'click',
        },
      },
    },
    {
      _id: uuid(),
      primitive: 'PurchaseBlock',
      properties: {
        text: 'Purchase',
        isSubmit: true,
      },
      style: {
        flex: 1,
        width: '100%',
      },
      subscribe: {
        submit: {
          _bind: true,
          publish: 'submit',
        },
      },
    },
  ],
  style: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '2em',
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingBottom: '2em',
    gap: '1em',
  },
  publishes: {
    submit: {
      type: 'Event',
    },
    click: {
      type: 'Event',
    },
  },
};
