import { gql } from 'graphql-query-contracts';

export default gql(/* GraphQL */ `
  query getUserBuildingAccess($userId: UUID!) {
    userBuildingAccess(userId: $userId) {
      channelId
      channelName
      mappings {
        mappingId
        status
        mappedDate
        email
      }
      credentials {
        mapId
        cardNumber
        activatedAt
        revokedAt
        credentialType
        provider
        status
      }
    }
  }
`);
