import { useContext } from 'react';

import { AnalyticsContext } from '../../contexts';
import { channelCollectables, Collectable } from './collectables';
import { convertChannelArrayIntoChannelCollectableObjects } from './helpers/convertChannelArrayIntoChannelCollectableObjects';
import { ANALYTIC_KEYS_ARRAY } from 'constants-analytics';
import { Channel } from '../../types/ChannelType';

export const useUnderConstructionAnalytics = () => {
  const { track } = useContext(AnalyticsContext);

  const trackViewed = (step: string, collectable: Collectable | null) => {
    const key = ANALYTIC_KEYS_ARRAY.find(
      key => key === `UnderConstruction.View.${step}`
    );

    if (!key) return;

    track(key, { extraInformation: collectable });
  };

  const trackExited = (step: string, collectables: Collectable | null) => {
    const key = ANALYTIC_KEYS_ARRAY.find(
      key => key === `UnderConstruction.View.${step}`
    );

    if (!key) return;

    track(key, { extraInformation: collectables });
  };

  const underConstructionTracker = {
    View: {
      Index: ({ availableChannels }: { availableChannels: Channel[] }) => {
        trackViewed(
          'Index',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
    },
    Exit: {
      SelectedChannel: ({
        availableChannels,
        selectedChannel,
      }: {
        availableChannels: Channel[];
        selectedChannel: Channel;
      }) => {
        trackExited('SelectedChannel', {
          availableChannels:
            convertChannelArrayIntoChannelCollectableObjects(availableChannels),
          selectedChannel: channelCollectables(selectedChannel),
        });
      },
      Back: ({ availableChannels }: { availableChannels: Channel[] }) => {
        trackExited(
          'Back',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
      Profile: ({ availableChannels }: { availableChannels: Channel[] }) => {
        trackExited(
          'Profile',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
      Settings: ({ availableChannels }: { availableChannels: Channel[] }) => {
        trackExited(
          'Settings',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
      ChannelSwitcher: ({
        availableChannels,
      }: {
        availableChannels: Channel[];
      }) => {
        trackExited(
          'Settings',
          convertChannelArrayIntoChannelCollectableObjects(availableChannels)
        );
      },
      FindAnotherWorkplace: () => {
        trackExited('FindAnotherWorkplace', null);
      },
    },
  };

  return { underConstructionTracker };
};
