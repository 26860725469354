import { visitorManagementMenuItems } from 'lane-shared/domains/visitorManagement/helpers/visitorManagementContentBuilder';
import {
  ContentMenuTabsEnum,
  defaultNonInteractiveMenu,
  defaultInteractiveTemplateMenu,
  defaultInteractiveMenu,
  ContentTypeEnum,
} from 'constants-content';

type MenuTabFunctionMap<
  Key extends keyof typeof ContentTypeEnum,
  Value extends (
    isInteractive: boolean,
    forTemplate: boolean
  ) => ContentMenuTabsEnum[],
> = {
  [K in Key]: Value;
};

const menuTabsForContentTypeMap: Readonly<
  MenuTabFunctionMap<
    ContentTypeEnum,
    (isInteractive: boolean, forTemplate: boolean) => ContentMenuTabsEnum[]
  >
> = Object.freeze({
  [ContentTypeEnum.Static]: defaultMenuTabs,
  [ContentTypeEnum.Content]: defaultMenuTabs,
  [ContentTypeEnum.Notice]: defaultMenuTabs,
  [ContentTypeEnum.ScheduledContent]: defaultMenuTabs,
  [ContentTypeEnum.ScheduledNotice]: defaultMenuTabs,
  [ContentTypeEnum.Promotion]: defaultMenuTabs,
  [ContentTypeEnum.Perk]: defaultMenuTabs,
  [ContentTypeEnum.WorkOrder]: defaultMenuTabs,
  [ContentTypeEnum.VisitorManagement]: visitorManagementMenuItems,
});

function defaultMenuTabs(isInteractive: boolean, forTemplate: boolean) {
  return isInteractive
    ? menuTabsForInteractiveContent(forTemplate)
    : defaultNonInteractiveMenu;
}

function menuTabsForInteractiveContent(forTemplate: boolean) {
  return forTemplate ? defaultInteractiveTemplateMenu : defaultInteractiveMenu;
}

export function menuTabsForContentType(
  contentType: ContentTypeEnum,
  isInteractive: boolean,
  forTemplate: boolean
) {
  return menuTabsForContentTypeMap[contentType](isInteractive, forTemplate);
}
