import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';

const Shipping: FeatureDefinition = {
  superUserOnly: true,
  name: FeatureNameEnum.Shipping,
  friendlyName: 'Shipping',
  requiresInteraction: true,
  description:
    'This indicates this needs to be shipped, and that a user must enter shipping information, for it to be interacted with.',
  properties: {},
  interactionData: {
    address: {
      friendlyName: 'Shipping Address',
      type: 'Address',
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default Shipping;
