import { colors } from 'constants-colors';

export const invisibleColors = {
  background: 'transparent',
  primaryColor: '#000',
  onPressControlBackgroundColor: 'transparent',
  onPressControlTextColor: '#000',
  hover: '#F2F7FA',
  hoverText: '#000',
  borderColor: 'rgba(15, 18, 31, 0.24)',
  borderHover: colors.charcoal[4],
  dropdownColor: '#3b52ff',
  dropdownColorOpen: '#3b52ff',
};
