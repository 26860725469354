import React from 'react';

import { Icon } from '../Icon';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './ValidationMessage.scss';

type Props = {
  errors?: string[] | null;
  className?: string;
  style?: React.CSSProperties;
  iconType?: string;
  iconSet?: string;
  withoutIcon?: boolean;
  doTranslation?: boolean;
};

export default function ValidationMessage({
  errors,
  className,
  style,
  iconType,
  iconSet,
  withoutIcon,
  doTranslation = true,
}: Props) {
  const hasError = errors && Array.isArray(errors) && errors.length > 0;
  const { t } = useTranslation();

  if (!hasError) {
    return null;
  }

  return (
    <div
      className={cx(styles.ValidationMessage, styles.hasError, className)}
      style={style}
    >
      {errors?.map(error => (
        <span data-test="validation" key={error}>
          {withoutIcon ? null : (
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            <Icon name="exclamation-triangle" type={iconType} set={iconSet} />
          )}
          <p>{doTranslation ? t(error) : error}</p>
        </span>
      ))}
    </div>
  );
}
