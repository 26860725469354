import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import * as Sentry from '@sentry/browser';

import { AppContext, UserDataContext } from 'lane-shared/contexts';
import { commonInteractionQueries } from 'lane-shared/helpers/constants';
import { createUserContentInteraction } from 'lane-shared/helpers/content';
import { getDraftOnlinePlanInteraction } from 'lane-shared/helpers/interactions';
import { getPrimaryEmail } from 'lane-shared/helpers/user';
import OnlinePlanDatasetRecordValue from 'lane-shared/hooks/integrations/essensys/types/OnlinePlanDatasetRecordValue';
import useOperateAccountContactPairs from 'lane-shared/hooks/integrations/essensys/useOperateAccountContactPairs';
import useOperateOnlinePlanPaymentSettings from 'lane-shared/hooks/integrations/essensys/useOperateOnlinePlanPaymentSettings';
import useOperateOnlinePlanQuote from 'lane-shared/hooks/integrations/essensys/useOperateOnlinePlanQuote';
import useObjectDatasetRecordQuery from 'lane-shared/hooks/useObjectDatasetRecord';

import { useSignUpContext } from '../contexts/SignUpContext';
import { PlacePayDataResponse } from '../types/PlacePay';
import { ContentType } from '../types/content/Content';
import { PaymentProviderEnum } from 'constants-payments';
import useCollectPayment from './useCollectPayment';

type EssensysOnlinePlanPaymentFeatureConfirmationModalProps = {
  content: ContentType;
  interaction: any;
  onPaymentStart: (paymentData: PaymentProviderEnum) => void;
  onPaymentSuccess?: (paymentData: PlacePayDataResponse) => void;
  onPaymentFailed?: (err: Error) => void;
  onCreateInteractionFailed?: (err: Error) => void;
  onCreateInteractionSuccess?: (interaction: any) => void;
};

export default function useEssensysOnlinePlanPaymentFeatureConfirmationModal({
  content,
  interaction,
  onPaymentStart,
  onPaymentSuccess,
  onPaymentFailed,
  onCreateInteractionFailed,
  onCreateInteractionSuccess,
}: EssensysOnlinePlanPaymentFeatureConfirmationModalProps) {
  const { t } = useTranslation();

  // if public user signs up through this modal, their companyName gets saved for purchase
  const { companyName: signUpCompanyName } = useSignUpContext();

  const [error, setError] = useState<Error | undefined>();
  const [submittingInteraction, setSubmittingInteraction] =
    useState<boolean>(false);

  const objectDatasetRecordQuery =
    useObjectDatasetRecordQuery<OnlinePlanDatasetRecordValue>(content._id);

  const objectDatasetRecord =
    objectDatasetRecordQuery.data?.objectDatasetRecord;
  const datasetRecord = objectDatasetRecord?.datasetRecord;

  const {
    loading: operateAccountContactPairsLoading,
    accountContactPairs,
    accountContactPair,
  } = useOperateAccountContactPairs(datasetRecord?.value.location_id, {
    skip: !datasetRecord?.value.location_id,
  });

  const operateOnlinePlanPaymentSettings = useOperateOnlinePlanPaymentSettings({
    datasetRecordValue: datasetRecord?.value,
    Sentry,
  });

  const collectPayment = useCollectPayment({
    paymentAccount: operateOnlinePlanPaymentSettings.paymentAccount,
    collectPaymentOptions: {
      onPaymentStart,
    },
  });

  const suggestedCompanyName =
    accountContactPairs?.[0]?.account.clientname || signUpCompanyName;
  const app = useContext(AppContext);
  const { user } = useContext(UserDataContext);

  const primaryEmail: string | null = getPrimaryEmail(user?.logins);
  const [email, setEmail] = useState<string>(primaryEmail ?? '');
  const [companyName, setCompanyName] = useState<string>(
    suggestedCompanyName || `${user?.profile.name} via ${app.whitelabel.name}`
  );

  const {
    onlinePlanQuote,
    loading: onlinePlanQuoteLoading,
    error: onlinePlanQuoteError,
  } = useOperateOnlinePlanQuote(objectDatasetRecord?._id);

  const errors = [
    operateOnlinePlanPaymentSettings.error,
    objectDatasetRecordQuery.error,
    error,
  ].filter(Boolean) as Error[];

  const loading =
    objectDatasetRecordQuery.loading ||
    operateOnlinePlanPaymentSettings.loading ||
    operateAccountContactPairsLoading;

  const paymentAccount = operateOnlinePlanPaymentSettings.paymentAccount;
  const canMakePayment = Boolean(
    paymentAccount && collectPayment?.[paymentAccount.type]
  );
  const purchaseDisabled =
    operateOnlinePlanPaymentSettings.purchaseDisabled || !canMakePayment;

  useEffect(() => {
    // sometimes this gets loaded in while paymentAccount is still null
    objectDatasetRecordQuery.refetch();
  }, [paymentAccount?._id]);

  async function createOperateOnlinePlanInteraction() {
    setError(undefined);

    if (!paymentAccount) {
      setError(new Error(t('Payment account was not ready.')));

      return;
    }

    if (!collectPayment[paymentAccount.type]) {
      setError(
        new Error(
          t(
            'The current payment account type {{- paymentAccountType}} is not supported.',
            { paymentAccountType: paymentAccount.type }
          )
        )
      );

      return;
    }

    setSubmittingInteraction(true);

    const { getPayment } = collectPayment[paymentAccount.type]!;

    let paymentData;

    try {
      paymentData = await getPayment();
    } catch (err) {
      if (err instanceof Error) {
        setError(err);

        if (onPaymentFailed) {
          onPaymentFailed(err);
        }
      }

      setSubmittingInteraction(false);

      return;
    }

    if (onPaymentSuccess) {
      onPaymentSuccess(paymentData);
    }

    const PAYMENT_ACCOUNT_TYPE_DATA_FIELD = {
      [PaymentProviderEnum.Stripe]: 'stripeData',
      [PaymentProviderEnum.PlacePay]: 'placePayData',
    };

    const draftInteraction = getDraftOnlinePlanInteraction(interaction, {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      [PAYMENT_ACCOUNT_TYPE_DATA_FIELD[paymentAccount.type]]: paymentData,
      paymentAccountId: paymentAccount?._id,
      metadata: {
        email,
        companyName,
        contentId: content._id,
      },
    });

    let newInteraction;

    try {
      newInteraction = await createUserContentInteraction({
        refetchQueries: commonInteractionQueries,
        content,
        interaction: draftInteraction,
      });
    } catch (err) {
      if (err instanceof Error) {
        setError(err);

        if (onCreateInteractionFailed) {
          onCreateInteractionFailed(err);
        }
      }

      setSubmittingInteraction(false);

      return;
    }

    if (onCreateInteractionSuccess) {
      onCreateInteractionSuccess(newInteraction);
    }

    setSubmittingInteraction(false);
  }

  return {
    setPlacePayError: setError,
    accountContactPair,
    submittingInteraction,
    operateOnlinePlanPaymentSettings,
    email,
    setEmail,
    companyName,
    setCompanyName,
    onlinePlanQuote,
    onlinePlanQuoteLoading,
    onlinePlanQuoteError,
    errors,
    purchaseDisabled,
    loading,
    createOperateOnlinePlanInteraction,
    collectPayment,
  };
}
