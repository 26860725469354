import React, { memo, useContext, useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { useLazyQuery } from '@apollo/client';

import { ThemeContext } from 'lane-shared/contexts';
import { getDraftContent } from 'lane-shared/graphql/query';
import { IconSet } from 'lane-shared/helpers/constants/icons';
import { PlatformEnum } from 'constants-activate';

import ButtonStrip from 'components/general/ButtonStrip';
import ContentRenderer from 'components/lane/ContentRenderer';

import styles from './ContentPreview.scss';

const platformButtons = [
  {
    value: PlatformEnum.Mobile,
    name: 'Mobile',
    icon: 'mobile',
  },
  {
    value: PlatformEnum.Web,
    name: 'Web',
    icon: 'desktop',
  },
];

type Props = {
  className?: string;
  style?: React.CSSProperties;
  contentId?: string | null;
  tab?: {
    icon: string;
    iconSet: IconSet;
    iconWeight: string;
    label: string;
  };
};

function ContentPreview({ className, style, contentId, tab }: Props) {
  const { primary } = useContext(ThemeContext);
  const [platform, setPlatform] = useState<PlatformEnum>(PlatformEnum.Mobile);
  const [fetchDraftContent, draftContentResult] = useLazyQuery(
    getDraftContent,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: contentId,
      },
    }
  );

  useEffect(() => {
    if (contentId) {
      fetchDraftContent({ variables: { id: contentId } });
    }
  }, [contentId]);

  const content = draftContentResult?.data?.draftContent;

  return (
    <div className={cx(styles.ContentPreview, className)} style={style}>
      <ButtonStrip
        className={styles.buttonStrip}
        buttons={platformButtons}
        onClick={value => setPlatform(value)}
        selected={platform}
      />
      {content && platform === 'web' && (
        <div className={styles.webFrame}>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'forAdmin' is missing in type '{ content:... Remove this comment to see the full error message */}
          <ContentRenderer
            content={content}
            className={styles.contentRenderer}
          />
        </div>
      )}
      {content && platform === 'mobile' && (
        <div className={styles.phoneFrame}>
          <div className={styles.content}>
            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'forAdmin' is missing in type '{ content:... Remove this comment to see the full error message */}
            <ContentRenderer
              content={content}
              className={styles.contentRenderer}
            />
          </div>
          {tab && (
            <div className={styles.navBar}>
              <div className={styles.column}>
                <Icon
                  name={tab.icon}
                  set={tab.iconSet}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"fas" | "... Remove this comment to see the full error message
                  type={tab.iconWeight}
                  className={styles.icon}
                  style={{ color: primary }}
                />
                <span style={{ color: primary }}>{tab.label}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

// use memo here since ContentRenderer is expensive
export default memo(ContentPreview);
