import { useContext } from 'react';
import { sectionCollectables, Collectable } from './collectables';

import { AnalyticsContext } from '../../contexts';
import { ANALYTIC_KEYS, AnalyticKey } from 'constants-analytics';
import { ListOrFeedNameType, PreviewBlockType } from './useContentAnalytics';
import { SectionType } from '../../types/sections/SectionType';
import { SECTION_LISTS_OR_FEEDS_FRIENDLY_NAME } from 'constants-content';
import { useSimpleTrack } from '../useSimpleTrack';
import { hasCollectableData } from './helpers/hasCollectableData';

const eventMap = {
  preview: ANALYTIC_KEYS.ANALYTIC_SECTION_VIEW,
  details: ANALYTIC_KEYS.ANALYTIC_SECTION_DETAILS,
  navigate: ANALYTIC_KEYS.ANALYTIC_SECTION_NAVIGATE,
};

/**
 * Used for analytics tracking in mobile or web.
 */
export function useSectionAnalytics() {
  const { track } = useContext(AnalyticsContext);
  const simpleTrack = useSimpleTrack();

  const trackEvent = (event: AnalyticKey, collectable?: Collectable) => {
    if (hasCollectableData(collectable)) {
      track(event, collectable as Collectable);
    }
  };

  const trackMixpanelEvent = (
    event: AnalyticKey,
    collectable?: Collectable
  ) => {
    if (hasCollectableData(collectable)) {
      simpleTrack(event, collectable);
    }
  };

  const getFriendlyListOrFeedName = (listOrFeedName?: ListOrFeedNameType) => {
    return listOrFeedName
      ? SECTION_LISTS_OR_FEEDS_FRIENDLY_NAME[listOrFeedName]
      : undefined;
  };

  const sectionTracker = {
    View: {
      Preview: ({
        section,
        listOrFeedName,
        previewBlock,
      }: {
        section: Partial<SectionType>;
        listOrFeedName?: ListOrFeedNameType;
        previewBlock?: PreviewBlockType;
      }) => {
        trackEvent(eventMap.preview, {
          ...sectionCollectables(section),
          listOrFeedName: getFriendlyListOrFeedName(listOrFeedName),
          previewBlock,
        });
      },
      Details: (section: Partial<SectionType>) => {
        trackEvent(eventMap.details, sectionCollectables(section));
      },
      Navigate: ({
        section,
        listOrFeedName,
        previewBlock,
      }: {
        section: Partial<SectionType>;
        listOrFeedName?: ListOrFeedNameType;
        previewBlock?: PreviewBlockType;
      }) => {
        trackMixpanelEvent(eventMap.navigate, {
          ...sectionCollectables(section),
          listOrFeedName: getFriendlyListOrFeedName(listOrFeedName),
          previewBlock,
        });
      },
    },
  };

  return { sectionTracker };
}
