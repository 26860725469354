import { ActiveChannelTypeEnum } from 'constants-channel';
import { Channel } from '../../types/ChannelType';

/**
 * Determines if the provided channel is a parent channel.
 * A parent channel is defined as a channel that:
 * 1. Is of type `Property`.
 * 2. Is not a sub-channel (`isSub` is false).
 * 3. Does not have a `parent` property or `parentId` (i.e., it is not a child channel).
 *
 * @param channel - The channel object to be checked.
 * @returns True if the channel is a parent channel; otherwise, false.
 */
export function isParentChannel(channel: Channel) {
  return (
    channel.type === ActiveChannelTypeEnum.Property &&
    !channel.isSub &&
    !(channel.parent && channel.parentId)
  );
}
