import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { useQuery } from '@apollo/client';

import { getMetatag } from 'lane-shared/graphql/query';
import { getLibraryOptions } from 'lane-shared/helpers';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { DocumentType } from 'lane-shared/types/DocumentType';

import IconButton from 'components/general/IconButton';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import MetatagLibrary from 'components/lane/MetatagLibrary';

import styles from './MetatagSelectorButton.scss';

type OwnProps = {
  className?: string;
  style?: React.CSSProperties;
  metatagId: string | null;
  channel: Channel | null;
  isInvalid?: boolean;
  disabled: boolean;
  onMetatagSelected: (metatag: DocumentType | null) => void;
};

type Props = OwnProps;

export default function MetatagSelectorButton({
  className,
  style,
  metatagId = null,
  channel = null,
  isInvalid,
  disabled,
  onMetatagSelected = () => null,
}: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useQuery(getMetatag, {
    fetchPolicy: 'cache-and-network',
    skip: !metatagId,
    variables: {
      id: metatagId,
    },
  });

  const metatag = metatagId && data?.metatag;

  return (
    <>
      <div
        className={cx(styles.MetatagSelectorButton, className)}
        style={style}
        data-is-selected={!!metatagId}
        data-invalid={!!isInvalid}
        data-disabled={disabled}
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === Key.Enter && setIsOpen(true)}
        onClick={() => setIsOpen(true)}
      >
        {!metatag && (
          <>
            <Icon className={styles.search} name={t('search')} />
            <span>{t('Select a Metatag')}</span>
          </>
        )}
        {metatag && (
          <>
            <span className={styles.selectedValue}>
              {(metatag as any).name}
            </span>{' '}
            <IconButton
              className={styles.remove}
              inverted
              icon="times"
              onClick={e => {
                onMetatagSelected(null);
                e.stopPropagation();
              }}
            />
          </>
        )}
      </div>

      <ModalBackground
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        className={styles.background}
      >
        {isOpen && (
          <ResizableWindow
            name="MetatagSelectorButton"
            className={styles.window}
            defaultPosition={ResizableWindow.fullScreen()}
            onClose={() => setIsOpen(false)}
            showHeader
          >
            <MetatagLibrary
              onMetatagSelected={metatag => {
                onMetatagSelected(metatag);
                setIsOpen(false);
              }}
              libraries={getLibraryOptions({ channel })}
            />
          </ResizableWindow>
        )}
      </ModalBackground>
    </>
  );
}
