import { ChannelSettingsType, Channel } from '../../types/ChannelType';
import { convertToUUID } from 'uuid-encoding';

const REQUIRED_MULTI_LANGUAGE_CONFIGURATIONS_IN_CHANNEL_SETTINGS = [
  'enabledLanguagesForMembers',
  'multiLanguageEnabled',
];

const channelSettingsHasMultiLanguages = ({
  settings,
}: {
  settings?: ChannelSettingsType | null;
}) => {
  return REQUIRED_MULTI_LANGUAGE_CONFIGURATIONS_IN_CHANNEL_SETTINGS.every(
    requiredMultiLanguageConfigKey =>
      Object.keys(settings || {}).includes(requiredMultiLanguageConfigKey)
  );
};

/**
 * Will try to derive channel settings from model and channel props.
 * If unable to, will try to derive a channel id from model and channel props.
 * If not, will return null
 * This function will prioritize modelItem over channel when retrieving channel info.
 */
export const deriveChannelFromModelItem = ({
  modelItem,
  channels,
  channelSettingsCache = {},
  debugFunction,
  channelIdToDeriveSettingsFor,
}: {
  modelItem: any;
  channels: Channel[];
  channelSettingsCache: { [channelId: string]: ChannelSettingsType };
  debugFunction: (printable: string) => void;
  channelIdToDeriveSettingsFor: string;
}) => {
  if (!modelItem) return null;

  if (!channelIdToDeriveSettingsFor) return null;

  let modelItemChannelSettings;

  if (
    channelSettingsHasMultiLanguages({
      settings:
        (channelSettingsCache || {})[channelIdToDeriveSettingsFor || ''] ||
        modelItem?.channel?.settings,
    })
  ) {
    debugFunction(
      `---- setting channel settings using modelItem.channel.settings`
    );
    modelItemChannelSettings =
      (channelSettingsCache || {})[channelIdToDeriveSettingsFor || ''] ||
      modelItem?.channel?.settings;
  }

  if (
    !modelItemChannelSettings &&
    channelSettingsHasMultiLanguages({ settings: modelItem?.channelSettings })
  ) {
    debugFunction(
      `---- setting channel settings using modelItem.channelSettings`
    );
    modelItemChannelSettings = modelItem?.channelSettings;
  }

  const channelFromChannels = channels?.find(channel => {
    if (channel?._id) {
      return (
        convertToUUID(channel._id) ===
        convertToUUID(channelIdToDeriveSettingsFor)
      );
    }

    return false;
  });

  if (
    !modelItemChannelSettings &&
    channelSettingsHasMultiLanguages({
      settings: channelFromChannels?.settings,
    })
  ) {
    debugFunction(
      `---- setting channel settings using channelFromChannels.settings`
    );
    modelItemChannelSettings = channelFromChannels?.settings;
  }

  debugFunction(
    `-- found model item channel settings of ${JSON.stringify(
      modelItemChannelSettings
    )}`
  );

  if (modelItemChannelSettings) {
    debugFunction(`returning the found channel settings and id`);

    return {
      settings: modelItemChannelSettings,
      _id: channelIdToDeriveSettingsFor,
    };
  }

  return null;
};
