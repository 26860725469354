import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';
import './types/UnavailableDateAvailability';
import WeeklyAvailability from './types/WeeklyAvailability';

const TimeAvailability: FeatureDefinition = {
  description: 'web.content.features.timeAvailability.description',
  name: FeatureNameEnum.TimeAvailability,
  requiresInteraction: true,
  friendlyName: 'web.content.features.timeAvailability.friendlyName',
  properties: {
    availabilities: {
      type: 'WeeklyAvailability',
      friendlyName: 'Weekly Availability',
      description: 'This is the weekly availability',
      // The first availability will by default apply to all groups
      default: [
        {
          ...WeeklyAvailability.default,
          allGroupRoles: true,
        },
      ],
      isArray: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
    unavailableDates: {
      type: 'UnavailableDateAvailability',
      friendlyName: 'Unavailable Date Availability',
      description: 'These are the unavailable dates',
      default: [],
      isArray: true,
      validators: [
        {
          name: 'Required',
          value: true,
        },
      ],
    },
  },
};

export default TimeAvailability;
