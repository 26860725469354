import { useCallback } from 'react';

import { UserContentInteractionType } from 'lane-shared/types/UserContentInteraction';
import { SortByEnum } from 'lane-shared/types/blocks/Sort';
import { ContentType } from 'lane-shared/types/content/Content';
import { SortDirectionEnum } from 'constants-activate';

type SortInputType = UserContentInteractionType | ContentType;

type UseSortFnProps = {
  sortBy: SortByEnum | SortByEnum[];
  sortDirection: SortDirectionEnum;
};

type UseSortFnReturn = {
  sortFn: (a: SortInputType, b: SortInputType) => number;
};

// Unifying ContentInteractionType with liveDate and unpublishDate to avoid TS errors below
// Only intended for local use, do not export this type
type UnifiedSortBy =
  | (UserContentInteractionType & { liveDate: null; unpublishDate: null })
  | ContentType;

export default function useSortFn({
  sortBy,
  sortDirection,
}: UseSortFnProps): UseSortFnReturn {
  // Memoize the sort function
  const sortFn = useCallback(
    (firstSortItem: UnifiedSortBy, secondSortItem: UnifiedSortBy) => {
      // Sort values can only be date strings (right now)
      let dateA: number;
      let dateB: number;

      if (Array.isArray(sortBy)) {
        const { firstSortProperty, secondSortProperty } = sortBy.reduce<{
          firstSortProperty: SortByEnum | null;
          secondSortProperty: SortByEnum | null;
        }>(
          (sortProperties, sortByProperty) => {
            if (
              !sortProperties.firstSortProperty &&
              firstSortItem[sortByProperty]
            ) {
              sortProperties.firstSortProperty = sortByProperty;
            }

            if (
              !sortProperties.secondSortProperty &&
              secondSortItem[sortByProperty]
            ) {
              sortProperties.secondSortProperty = sortByProperty;
            }

            return sortProperties;
          },
          { firstSortProperty: null, secondSortProperty: null }
        );

        dateA = new Date(firstSortItem[firstSortProperty!] || 0).getTime();
        dateB = new Date(secondSortItem[secondSortProperty!] || 0).getTime();
      } else {
        dateA = new Date(firstSortItem[sortBy] || 0).getTime();
        dateB = new Date(secondSortItem[sortBy] || 0).getTime();
      }

      switch (sortDirection) {
        case SortDirectionEnum.Desc:
          return dateB - dateA;
        case SortDirectionEnum.Asc:
        default:
          return dateA - dateB;
      }
    },
    [sortBy, sortDirection]
  ) as UseSortFnReturn['sortFn'];

  return {
    sortFn,
  };
}
