import * as yup from 'yup';

import { CHANNEL_TYPE_KEYS } from 'constants-channel';

export default yup.object().shape({
  __typename: yup.string().strip(true),
  type: yup
    .string()
    .label('Type')
    .trim()
    .oneOf(Object.values(CHANNEL_TYPE_KEYS))
    .required(),
  parent: yup
    .object()
    .shape({
      _id: yup.string().trim().required(),
    })
    .nullable(),
});
