import { createContext } from 'react';

import { ApolloError } from '@apollo/client';

import { LaneType } from 'common-types';
import { UserType } from '../types/User';
import { PermissionKey } from 'constants-permissions';

export interface UserDataContextInterface {
  // the user object, if this is set we are logged in
  user: UserType | null;
  // the session id of the user login session
  sessionId: LaneType.UUID | null;
  // is a fetchUser query currently loading, note that this could
  // be different than isLoggingIn, as there is an poll interval set on
  // fetchUser
  loading: boolean;
  // was there an error from the last fetchUser attempt
  error: Error | ApolloError | null | undefined;
  // was there a route that was set before logging in (i.e. a redirect to
  // a url that the user was attempting to get to, or a deep link)
  route: string | null;
  // the current authToken if any exists
  authToken: string | null;
  // has the app initialized and setup for user data.
  isInitialized: boolean;
  // is the user currently attempting to login
  isLoggingIn: boolean;
  // is the user currently logged in
  isLoggedIn: boolean;
  // has the user attempted as least one login, this is useful for when the
  // app bootstraps on first load
  hasAttemptedLogin: boolean;
  // refetch the user.
  refetch: () => Promise<void>;
  // Authentication warnings  (i.e company included in invite could not be joined)
  authWarnings: string[] | null;
  setAuthWarnings: (
    warnings:
      | string[]
      | null
      | ((prevState: string[] | null) => string[] | null)
  ) => void;
  hasAnyPermission: (
    permissions: PermissionKey[],
    channelId?: string
  ) => boolean;
}

const UserDataContext = createContext<UserDataContextInterface>({
  user: null,
  sessionId: null,
  loading: false,
  error: null,
  route: null,
  authToken: null,
  isInitialized: false,
  isLoggingIn: false,
  isLoggedIn: false,
  hasAttemptedLogin: false,
  refetch: async () => {},
  authWarnings: null,
  setAuthWarnings: () => null,
  hasAnyPermission: () => false,
});

export default UserDataContext;
