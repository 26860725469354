import { IntegrationProviderEnum } from 'constants-integrations';
import { PaymentAccountType } from '../../types/payment/PaymentAccount';
import { PaymentProviderEnum } from 'constants-payments';

export default function filterPaymentAccount<
  T extends Partial<PaymentAccountType>,
>(paymentAccount: T, paymentProvider: PaymentProviderEnum): boolean {
  // filter out only Essensys linked payment processors.
  if (paymentProvider === PaymentProviderEnum.Essensys) {
    return (
      paymentAccount.paymentProcessor?.integrationProvider ===
      IntegrationProviderEnum.Essensys
    );
  }

  return true;
}
