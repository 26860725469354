import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading, Flex, S } from 'design-system-web';
import { H5 } from 'components/typography';
import { BaseChannelAccessRule } from 'domains/accessControl/components/access-configuration/DefaultAccess/components/BaseChannelAccessRule';
import Alert from 'components/lds/Alert';
import { DelegatedAccessRules } from 'domains/accessControl/components/access-configuration/DefaultAccess/components/DelegatedAccessRules';
import { Channel } from 'packages/lane-shared/types/ChannelType';
import { useFetchAccessRules } from 'domains/accessControl/hooks/useAccessRules/useFetchAccessRules';
import { useAccessGroups } from 'domains/accessControl/hooks/useAccessGroups';
import { useAccessRuleMobileAccess } from 'domains/accessControl/hooks/useAccessRules/useAccessRuleMobileAccess';
import { MobileAccessSetup } from './components/MobileAccessSetup';
import styles from './styles.scss';

export const DefaultAccess = ({ channel }: { channel: Channel }) => {
  const { t } = useTranslation();

  const {
    data: accessLocation,
    loading,
    error,
    refetch,
  } = useFetchAccessRules(channel?._id);

  const { accessGroups, loading: accessGroupsLoading } = useAccessGroups(
    channel?._id
  );

  const mobileAccess = useAccessRuleMobileAccess(
    accessLocation?.accessRule,
    accessLocation?.tenants
  );

  if (loading || accessGroupsLoading) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        className={styles.loadingContainer}
      >
        <Loading
          testId="default-access-spinner-icon"
          className={styles.loading}
        />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex direction="column" align="flex-start" justify="center">
        <Alert type="error">
          <S>{t('web.admin.accessConfiguration.defaultAccess.error')}</S>
        </Alert>
      </Flex>
    );
  }

  if (!accessLocation?.configuration?.integration) {
    return (
      <Flex direction="column" align="flex-start" justify="center">
        <Alert type="error">
          <S>{t('web.admin.accessConfiguration.defaultAccess.warning')}</S>
        </Alert>
      </Flex>
    );
  }

  return (
    <div data-test="default-access" className={styles.defaultAccessContainer}>
      <H5>{t('web.admin.accessConfiguration.defaultAccess.title')}</H5>
      <BaseChannelAccessRule
        accessRule={accessLocation?.accessRule}
        channel={channel}
        accessGroups={accessGroups}
        mobileAccess={mobileAccess}
        refetch={refetch}
      />
      {accessLocation?.tenants?.length > 0 && (
        <DelegatedAccessRules
          tenants={accessLocation?.tenants}
          channel={channel}
          accessGroups={accessGroups}
          baseChannelAccessRule={accessLocation?.accessRule}
          mobileAccess={mobileAccess}
          refetch={refetch}
        />
      )}
      <H5>
        {t(
          'web.admin.accessConfiguration.defaultAccess.mobileAccessSetup.sectionHeading'
        )}
      </H5>
      <MobileAccessSetup
        defaultAccessRule={accessLocation?.accessRule}
        tenants={accessLocation?.tenants}
        channel={channel}
        refetch={refetch}
      />
    </div>
  );
};
