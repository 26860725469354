/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useContext, useMemo, useState, memo } from 'react';

import { getBoundingClientRect } from 'helpers';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { ChannelsContext, ContentRendererContext } from 'lane-shared/contexts';
import RendererContext from 'lane-shared/contexts/RendererContext';
import { BLOCK_TAGS } from 'constants-content';
import { useContentTheme } from 'lane-shared/hooks';
import useContentRendererBlockContext from 'lane-shared/hooks/contentRenderer/useContentRendererBlockContext';

import BlockRenderer from 'components/lane/BlockRenderer';

import styles from './NewBlockMenuItem.scss';

// these are needed by the renderer. usually these would be actual values,
// but this is showing an example render.
const editMode = true;
const loading = false;
const disabled = false;
const interaction = {};
const dataValidation = null;
const submitAttempted = false;
const onInteractionUpdated = () => null;
const onSubmit = () => null;
const onLink = () => null;
const onClick = () => null;

function NewBlockMenuItem({
  block,
  examples,
  onNewBlockDragStart,
  onNewBlockDragEnd,
  onNewBlockAdd,
}: any) {
  const { t } = useTranslation();
  const { blocks } = useContext(RendererContext);
  const [dragging, setDragging] = useState(false);
  const [examplePosition, setExamplePosition] = useState({ top: 0, left: 0 });
  const [exampleBlockId, setExampleBlockId] = useState(null);
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'null' cannot be used as an index type.
  const example = examples[exampleBlockId];
  const theme = useContentTheme(example);
  const { blockContext, updateBlockContext } = useContentRendererBlockContext();
  const { primaryChannel } = useContext(ChannelsContext);

  const providerValue = useMemo(
    () => ({
      loading,
      disabled,
      content: example,
      interaction,
      dataValidation,
      submitAttempted,
      onInteractionUpdated,
      onSubmit,
      onLink,
      onClick,
      editMode,
      theme,
      blockContext,
      updateBlockContext,
    }),
    [example]
  );

  function onDragStart(e: any) {
    if (e.dataTransfer && e.dataTransfer.setDragImage) {
      const dragImage = e.target.querySelector('div.example');

      if (dragImage) {
        e.dataTransfer.setDragImage(dragImage, 0, 0);
      }
    }

    onNewBlockDragStart({ e, block, action: 'new' });
    setExampleBlockId(null);
    setDragging(true);
  }

  function onDragEnd(e: any) {
    setExampleBlockId(null);
    setDragging(false);
    onNewBlockDragEnd({ e, block, action: 'new' });
  }

  function onMouseLeave() {
    setExampleBlockId(null);
  }

  function onMouseEnter(e: any) {
    if (
      block.tags.includes(BLOCK_TAGS.CONTAINER) ||
      block.tags.includes(BLOCK_TAGS.SEPARATOR)
    ) {
      return;
    }

    const { top, left, width } = getBoundingClientRect(e.target);

    setExampleBlockId(block._id);
    setExamplePosition({ top, left: left + width + 26 });
  }

  function renderExample() {
    if (!example) {
      return null;
    }

    if (example.block?.properties?.channel && primaryChannel) {
      example.block.properties.channel._id = primaryChannel?._id;
    }

    return ReactDOM.createPortal(
      <div className="NewBlockMenuItemExample" style={examplePosition}>
        {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ loading: boolean; disabled: boolean; conte... Remove this comment to see the full error message */}
        <ContentRendererContext.Provider value={providerValue}>
          <BlockRenderer
            top={example.block}
            isTop
            example={example}
            blocks={blocks}
          />
        </ContentRendererContext.Provider>
      </div>,
      document.body
    );
  }

  function getTranslatableBlockName(name: string) {
    if (!name || name.length === 0) {
      return t(`web.admin.channel.content.layout.editor.block.block`);
    }

    const translationPrefix = 'web.admin.channel.content.layout.editor.block.';
    const concatenatedName = name.replace(/ /g, '');
    const translatableName =
      concatenatedName.charAt(0).toLowerCase() + concatenatedName.slice(1);

    const translatedBlockName = t(`${translationPrefix}${translatableName}`);

    if (translatedBlockName.includes(translationPrefix)) {
      return t('web.admin.channel.content.layout.editor.properties.label', {
        property: name,
      });
    }

    return translatedBlockName;
  }

  return (
    <>
      <label
        className={styles.NewBlockMenuItem}
        draggable
        role="button"
        tabIndex={0}
        onKeyPress={e =>
          e.key === Key.Enter && onNewBlockAdd({ block, action: 'new' })
        }
        onDoubleClick={() => onNewBlockAdd({ block, action: 'new' })}
        data-is-dragging={dragging}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOut={onMouseLeave}
        data-test={`${block.blockName} block`}
      >
        {getTranslatableBlockName(block.blockName || block.friendlyName)}
      </label>
      {renderExample()}
    </>
  );
}

export default memo(NewBlockMenuItem);
