import { DocumentType } from '../../../types/DocumentType';
import { MobilePermissionEnum } from 'constants-permissions';
import { PlatformEnum } from 'constants-activate';
import { IntegrationDefinition } from '../../../types/integrations/IntegrationDefinition';
import {
  IntegrationCategoryEnum,
  IntegrationProviderEnum,
  IntegrationTypeEnum,
} from 'constants-integrations';
import './types';
import { FloorMapType } from './types/FloorMap';

export enum FloorMapsGenerationStatusEnum {
  Created = 'Created',
  Processing = 'Processing',
  Failed = 'Failed',
  Ready = 'Ready',
  Restart = 'Restart',
}

export type FloorMapsSettingsType = {
  name: string;
  floorMaps: FloorMapType[];
  metatag: DocumentType;
  generationStatus: FloorMapsGenerationStatusEnum;
  generationStartedAt?: string | Date;
  generationFinishedAt?: string | Date;
};

const FloorMapsIntegration: IntegrationDefinition = {
  name: IntegrationProviderEnum.FloorMaps,
  hasCustomUI: true,
  friendlyName: 'Floor Map',
  description: '',
  allowsMultipleInstances: true,
  category: IntegrationCategoryEnum.FlexSpace,
  platforms: [PlatformEnum.Mobile, PlatformEnum.Web],
  type: IntegrationTypeEnum.Api,
  settings: {},
  properties: {
    name: {
      type: 'String',
      friendlyName: 'Name',
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Max',
          value: 64,
        },
      ],
    },
    metatag: {
      type: 'Metatag',
      friendlyName: 'Metatag',
      validators: [],
    },
    generationStatus: {
      type: 'String',
      friendlyName: 'Generation Status',
      editable: false,
      default: null,
      validators: [
        {
          name: 'In',
          value: Object.values(FloorMapsGenerationStatusEnum),
        },
      ],
    },
    generationStartedAt: {
      type: 'Date',
      friendlyName: 'Floor maps generation started at',
      editable: false,
      default: undefined,
    },
    generationFinishedAt: {
      type: 'Date',
      friendlyName: 'Floor maps generation finished at',
      editable: false,
      default: undefined,
    },
    floorMaps: {
      type: 'FloorMap',
      isArray: true,
      default: [],
      validators: [
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'ArrayMax',
          value: 16,
        },
      ],
    },
  },
  mobilePermissions: [MobilePermissionEnum.Location],
};

export default FloorMapsIntegration;
