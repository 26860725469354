import { FeatureNameEnum } from 'constants-content';

const FEATURES_WITH_LAYOUT = [
  FeatureNameEnum.GuestInvite,
  FeatureNameEnum.Menu,
  FeatureNameEnum.Reservable,
];

export default function isFeatureVisible(
  visibleFeatures: string[],
  featureType: FeatureNameEnum
) {
  return (
    visibleFeatures.includes(featureType) ||
    !FEATURES_WITH_LAYOUT.includes(featureType)
  );
}
