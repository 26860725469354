import React from 'react';

import {
  SortByEnum,
  SortByLabelToSchema,
  SortDirectionLabelToSchema,
} from 'lane-shared/types/blocks/Sort';

import Dropdown from './Dropdown';

import styles from './SortSelector.scss';
import { SortDirectionEnum } from 'constants-activate';

type Props = {
  value: any;
  onChange?: (value: any) => any;
};

function SortSelector({ value = {}, onChange = () => {} }: Props) {
  const handleChange = (key: any, updatedValue: any) => {
    onChange({
      ...value,
      [key]: updatedValue,
    });
  };

  return (
    <div className={styles.dropdownsContainer}>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdownLabel}>Sort By:</div>
        <Dropdown
          id="sortBy"
          testId="sortBy"
          className={styles.dropdown}
          value={value?.sortBy || SortByEnum.EndDate}
          items={SortByLabelToSchema}
          onValueChange={value => handleChange('sortBy', value)}
        />
      </div>
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdownLabel}>Sort Direction:</div>
        <Dropdown
          id="sortSelector"
          testId="sortSelector"
          className={styles.dropdown}
          value={value?.sortDirection || SortDirectionEnum.Asc}
          items={SortDirectionLabelToSchema}
          onValueChange={value => handleChange('sortDirection', value)}
        />
      </div>
    </div>
  );
}

export default SortSelector;
