import { v4 as uuid } from 'uuid';

import {
  ContentCategoryEnum,
  BLOCK_TYPES,
  SectionObjectEnum,
  SectionTypeEnum,
} from 'constants-content';
import { DEFAULT_BACKGROUND, DEFAULT_BACKGROUND_DARK } from '../constants';

export default function constructSection({
  channelId,
  type = SectionTypeEnum.Static,
}: {
  channelId: string;
  type?: SectionTypeEnum;
}): any {
  return {
    _id: uuid(),
    description: '',
    name: '',
    type,
    object: SectionObjectEnum.Content,
    query:
      type === SectionTypeEnum.Dynamic
        ? {
            contentCategories: [ContentCategoryEnum.Other],
            contentTypes: [],
            includeChildren: false,
          }
        : null,
    renderType: BLOCK_TYPES.LIST,
    tags: [],
    sectionMetatags: [],
    filters: [],
    sorts: [],
    color: DEFAULT_BACKGROUND,
    backgroundColor: DEFAULT_BACKGROUND_DARK,
    channel: {
      _id: channelId,
    },
  };
}
