import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import config from 'lane-shared/config';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'constants-flags';
import { ENVIRONMENTS } from 'constants-activate';
import { byOrder } from 'lane-shared/helpers/sort';
import getBlockKey from 'lane-shared/renderers/v5/getBlockKey';
import parseValue from 'lane-shared/renderers/v5/parseValue';
import * as Primitives from 'lane-shared/renderers/v5/primitives';
import { SectionContentListBlock } from 'lane-shared/renderers/v5/primitives';

import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';

import Property from '../../builder/properties/input/Property';
import ResizableWindow from '../../general/ResizableWindow';

import styles from './BlockPropertiesWindow.scss';

const DEFAULT_POSITION = {
  x: 0,
  y: 0,
  width: 200,
  height: 200,
};

const PROPERTY_TRANSLATION_KEY_MAP: { [key: string]: string } = {
  'Align Items': 'alignItems',
  'Animation Speed': 'animationSpeed',
  'Background Color': 'backgroundColor',
  'Background Image': 'backgroundImage',
  'Card Layout': 'cardLayout',
  'Container Direction': 'containerDirection',
  'Full Width Mode on Web': 'fullWidthModeOnWeb',
  'Hero Image Height': 'heroImageHeight',
  'Hero Images': 'heroImages',
  'Hide when no results?': 'hideWhenNoResults',
  'Hide when no results.': 'hideWhenNoResults',
  'Hide when no results': 'hideWhenNoResults',
  'Horizontal Padding': 'horizontalPadding',
  'Horizontal spacing between items': 'horizontalSpacingBetweenItems',
  'Hours of Operation': 'hoursOfOperation',
  'Interaction State': 'interactionState',
  'Items per page': 'itemsPerPage',
  'Justify Content': 'justifyContent',
  'Link to MP4 video': 'linkToMP4Video',
  'Link to thumbnail image': 'linkToThumbnailImage',
  'List View': 'listView',
  'Lottie source': 'lottieSource',
  'Minimum Height': 'minimumHeight',
  'Pin Layout': 'pinLayout',
  'Pin View': 'pinView',
  'Resize Mode': 'resizeMode',
  'Scroll Horizontally': 'scrollHorizontally',
  'Scroll Orientation': 'scrollOrientation',
  'Show Credits?': 'showCredits',
  'Show filter by Channel?': 'showFilterByChannel',
  'Show Filter?': 'showFilter',
  'Show Gradient': 'showGradient',
  'Show Marker': 'showMarker',
  'Show Printing Credits?': 'showPrintingCredits',
  'Show search & filters': 'showSearchAndFilters',
  'Show search box?': 'showSearchBox',
  'Show View All': 'showViewAll',
  'Show View More': 'showViewMore',
  'Sort Order': 'sortOrder',
  'Text Alignment': 'textAlignment',
  'Text Color': 'textColor',
  'Vertical padding': 'verticalPadding',
  'Vertical Padding': 'verticalPadding',
  'Vertical spacing between items': 'verticalSpacingBetweenItems',
  'Weather Location': 'weatherLocation',
  'YouTube Video ID': 'youTubeVideoId',
  Address: 'address',
  Channel: 'channel',
  Color: 'color',
  Content: 'content',
  Directory: 'directory',
  Guide: 'guide',
  Header: 'header',
  Height: 'height',
  Icon: 'icon',
  Image: 'image',
  Label: 'label',
  Layout: 'layout',
  Name: 'name',
  Person: 'person',
  Section: 'section',
  Size: 'size',
  Subtitle: 'subtitle',
  Team: 'team',
  Text: 'text',
  Title: 'title',
  Type: 'type',
  Width: 'width',
  Zoom: 'zoom',
};

const SECTION_CONTENT_LIST_LAYOUT_PROPERTY = 'layout';
const CAROUSEL = 'Carousel';
const GRID = 'Grid';

function BlockPropertiesWindow({
  user,
  channel,
  library,
  theme,
  blocks,
  block,
  onContentUpdated,
  onClose,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  position = DEFAULT_POSITION,
}: any) {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  const sectionUIMemberUpdate = useFlag(
    FeatureFlag.SectionUIMemberUpdate,
    false
  );

  // get the block definition. its either a primitive or a Block
  const Block = block.primitive
    ? // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      Primitives[block.primitive]
    : blocks[getBlockKey(block)];

  const isLocal = config.env === ENVIRONMENTS.LOCAL;

  let properties = Object.entries(
    block.primitive ? Block.properties : Block.def.properties
  ).filter(([, property]) => {
    // Note: Hack to support https://viewthespace.atlassian.net/browse/TM-17019
    if (
      sectionUIMemberUpdate &&
      Block.blockName === SectionContentListBlock.friendlyName &&
      ['FlexDirection', 'FlexWrap'].includes((property as any).type)
    ) {
      return false;
    }

    // giving a default showControl value as is not set on most blocks
    if ([undefined, null, true].includes((property as any).showControl)) {
      return true;
    }

    if ((property as any).showControl === false) {
      return false;
    }

    return parseValue({
      value: (property as any).showControl,
      props: block.properties,
      propDefs: Block.def.properties,
    });
  });

  // Note: Hack to support https://viewthespace.atlassian.net/browse/TM-17019
  if (
    sectionUIMemberUpdate &&
    Block.blockName === SectionContentListBlock.friendlyName
  ) {
    properties.push([
      SECTION_CONTENT_LIST_LAYOUT_PROPERTY,
      {
        type: 'String',
        _order: 6,
        validators: [
          {
            name: 'Required',
            value: true,
          },
          {
            name: 'In',
            value: [GRID, CAROUSEL],
          },
        ],
        friendlyName: 'Layout',
      },
    ]);
  }

  properties = properties.sort((a, b) => byOrder(a[1], b[1]));

  const name = isEditing
    ? block.name || ''
    : block.name || Block.blockName || Block.name;

  function focusName() {
    if (inputRef.current) {
      setIsEditing(true);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      setTimeout(() => inputRef.current.focus(), 10);
    }
  }

  function getTranslatablePropertyName(property: any, key: string) {
    const propertyName = property.friendlyName || property.name || key;
    const translationKey = PROPERTY_TRANSLATION_KEY_MAP[propertyName];

    if (translationKey) {
      return t(
        `web.admin.channel.content.layout.editor.property.${translationKey}`
      );
    }

    return t('web.admin.channel.content.layout.editor.properties.label', {
      property: propertyName,
    });
  }

  function isMultiLanguageProperty(
    currentKey: string,
    properties: [string, unknown][]
  ) {
    const key_l10n = `${currentKey}_l10n`;

    return !!properties.find(([key, _]) => key === key_l10n);
  }

  function mapOldSectionContentListLayoutPropertiesToNewOne(
    flexDirection: string,
    flexWrap: string
  ) {
    if (flexDirection === 'row' && flexWrap === 'wrap') {
      return GRID;
    }

    if (flexDirection === 'row' && flexWrap === 'wrap-reverse') {
      return GRID;
    }

    if (flexDirection === 'column' && flexWrap === 'wrap') {
      return GRID;
    }

    if (flexDirection === 'column' && flexWrap === 'wrap-reverse') {
      return GRID;
    }

    if (flexDirection === 'column' && flexWrap === 'nowrap') {
      return GRID;
    }

    if (flexDirection === 'row' && flexWrap === 'nowrap') {
      return CAROUSEL;
    }

    return '';
  }

  function mapNewSectionContentListLayoutPropertyToOldOnes(layout: string) {
    if (layout === CAROUSEL) {
      return {
        flexDirection: 'row',
        flexWrap: 'nowrap',
      };
    }

    if (layout === GRID) {
      return {
        flexDirection: 'row',
        flexWrap: 'wrap',
      };
    }

    return {};
  }

  return (
    <ResizableWindow
      className={styles.BlockPropertiesWindow}
      name="BlockFrameProperties"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: any, bar: any) => void' is not assignabl... Remove this comment to see the full error message
      onBarDoubleClick={(e: any, bar: any) => {
        if (bar === 'top') {
          focusName();
        }
      }}
      defaultPosition={{
        width: 400,
        left: 100,
        height: Math.min(window.innerHeight * 0.7, 800),
        top: 150,
      }}
    >
      <h1 className={styles.name}>
        <input
          ref={inputRef}
          type="text"
          value={
            t('web.admin.channel.content.layout.editor.properties.label', {
              property: name,
            }) as string
          }
          onChange={e => {
            block.name = e.target.value;
            onContentUpdated({});
          }}
        />
        <button
          className={styles.close}
          onClick={() => onClose()}
          aria-label="Close"
          data-test="closeResizableWindow"
        />
      </h1>
      <div className={styles.wrapper}>
        {properties.map(([key, property]) => {
          if (key.includes('_l10n')) return;

          const displayMultiLanguageProperty = isMultiLanguageProperty(
            key,
            properties
          );

          // Note: Hack to support https://viewthespace.atlassian.net/browse/TM-17019
          let propertyValue;

          if (
            sectionUIMemberUpdate &&
            Block.blockName === SectionContentListBlock.friendlyName &&
            key === SECTION_CONTENT_LIST_LAYOUT_PROPERTY
          ) {
            propertyValue = mapOldSectionContentListLayoutPropertiesToNewOne(
              block.properties.flexDirection,
              block.properties.flexWrap
            );
          } else {
            propertyValue = block.properties[key];
          }

          return displayMultiLanguageProperty ? (
            <MultiLanguageWrapper
              channel={channel}
              model={block.properties}
              column={key}
              className={styles.multiLanguageWrapper}
            >
              {({ labelMaker, onChangeUpdates, valueGetter }) => (
                <div key={key} className={styles.property}>
                  <label>
                    {labelMaker({
                      label: getTranslatablePropertyName(property, key),
                    })}
                  </label>
                  <Property
                    object={block.properties}
                    channel={channel}
                    library={library}
                    user={user._id}
                    theme={theme}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Property... Remove this comment to see the full error message
                    property={property}
                    value={valueGetter()}
                    onChange={value => {
                      const updates = onChangeUpdates(value);

                      for (const [key, value] of Object.entries(updates)) {
                        // set both the column and column_l10n properties:
                        block.properties[key] = value;
                      }

                      onContentUpdated({});
                    }}
                    onPropertyChange={({ key, value }) => {
                      block.properties[key] = value;
                      onContentUpdated({});
                    }}
                    propertyKey={key}
                  />
                </div>
              )}
            </MultiLanguageWrapper>
          ) : (
            <div key={key} className={styles.property}>
              <label>{getTranslatablePropertyName(property, key)}</label>
              <Property
                object={block.properties}
                channel={channel}
                library={library}
                user={user._id}
                theme={theme}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Property... Remove this comment to see the full error message
                property={property}
                value={propertyValue}
                onChange={value => {
                  // Note: Hack to support https://viewthespace.atlassian.net/browse/TM-17019
                  if (
                    sectionUIMemberUpdate &&
                    Block.blockName === SectionContentListBlock.friendlyName &&
                    key === SECTION_CONTENT_LIST_LAYOUT_PROPERTY
                  ) {
                    const { flexDirection, flexWrap } =
                      mapNewSectionContentListLayoutPropertyToOldOnes(value);

                    block.properties.flexDirection = flexDirection;
                    block.properties.flexWrap = flexWrap;
                  } else {
                    block.properties[key] = value;
                  }

                  onContentUpdated({});
                }}
                onPropertyChange={({ key, value }) => {
                  block.properties[key] = value;
                  onContentUpdated({});
                }}
                propertyKey={key}
              />
            </div>
          );
        })}
        {isLocal && (
          <p className={styles.debugInfo}>
            <strong>_id:</strong> {block._id}
          </p>
        )}
      </div>
    </ResizableWindow>
  );
}

export default BlockPropertiesWindow;
