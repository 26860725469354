import React from 'react';

import { ANGUS_ORDER_STATUS_COLOR } from 'constants-integrations';

import styles from './StatusIndicator.scss';

type StatusIndicatorProps = {
  status: keyof typeof ANGUS_ORDER_STATUS_COLOR;
};

export default function StatusIndicator({ status }: StatusIndicatorProps) {
  return (
    <div
      className={styles.statusIndicator}
      style={{ backgroundColor: ANGUS_ORDER_STATUS_COLOR[status] }}
    />
  );
}
