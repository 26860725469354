import { UserLoginProviderEnum } from './userLogin';

// todo: refactor duplicated consts to new TypeScript enums in
//  here packages/lane-shared/types/UserLogin.ts
export const OAUTH_PROVIDERS = {
  ESSENSYS: UserLoginProviderEnum.Essensys,
  LINKEDIN: UserLoginProviderEnum.LinkedIn,
  PARKWHIZ: UserLoginProviderEnum.ParkWhiz,
  MICROSOFT: UserLoginProviderEnum.Microsoft,
  PROXYCLICK: UserLoginProviderEnum.ProxyClick,
  SAFETRUST: UserLoginProviderEnum.Safetrust,
  AZURE_AD: UserLoginProviderEnum.AzureAD,
  GOOGLE: UserLoginProviderEnum.Google,
  OKTA: UserLoginProviderEnum.Okta,
  APPLE: UserLoginProviderEnum.Apple,
  Auth0: UserLoginProviderEnum.Auth0,
  NotDefined: UserLoginProviderEnum.NotDefined,
} as const;

export enum OAuthProvidersEnum {
  AZURE_AD,
  GOOGLE,
  OKTA,
  APPLE,
}

export const SUPPORTED_SSO_PROVIDERS = {
  GOOGLE: OAUTH_PROVIDERS.GOOGLE,
  MICROSOFT: OAUTH_PROVIDERS.AZURE_AD,
  OKTA: OAUTH_PROVIDERS.OKTA,
  APPLE: OAUTH_PROVIDERS.APPLE,
};

export const OAUTH_PROVIDERS_OBJECT = {
  LINKEDIN: {
    clientId: '775dk7req8k31q',
    redirectUri: OAUTH_PROVIDERS.LINKEDIN,
    scope: 'r_liteprofile r_emailaddress w_member_social',
  },
  MICROSOFT: {
    clientId: 'd816eae4-a5d8-413c-9f2e-95c82a7d94ad',
  },
  GOOGLE: {
    clientId:
      '578488863254-e1fao0n52d827eheb6fb53phmv5oh42t.apps.googleusercontent.com',
    redirectUri: OAUTH_PROVIDERS.GOOGLE,
  },
};

export const DEVICE_TOKEN_TYPES = {
  IOS: 'apns',
  ANDROID: 'gcm',
  WEB: 'web',
};

// secure User queries and only allow these fields.
export const PUBLIC_USER_FIELDS = [
  '_id',
  '_created',
  '_updated',
  '_createdBy',
  '_updatedBy',
  'status',
  'lastSeen',
  'lastLogin',
  'name',
  'profile',
  'logins',
  'guid',
];

export const LAST_SEEN_ONLINE_THRESHOLD = 10 * 60 * 1000;
export const SUCCESSFUL_PHONE_VERIFICATION = 'approved';

// Access Control Service User(Inviter) fields

export const ACCESS_CONTROL_INVITER = {
  // profileId: '25508631-68b8-4a81-a4ed-59e5e7acf531',
  // userId: 'bef7dcc4-6d0c-4f63-b4d6-580fd086569a',
  email: 'acsusersyncworker@vts.com',
  name: 'Access Control Service',
};

export enum UserStatusEnum {
  System = 'System',
  Active = 'Active',
  Disabled = 'Disabled',
  NotDefined = 'NotDefined',
}

export enum PersonUnitRelationshipEnum {
  OCCUPIES = 'OCCUPIES',
  MANAGES = 'MANAGES',
  OWNS = 'OWNS',
  LEASES = 'LEASES',
}
