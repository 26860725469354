import { IntegrationProviderEnum } from 'constants-integrations';
import { PropertyType } from '../../types/properties/Property';
import getIntegrationDefinition from '../integrations/getIntegrationDefinition';
import byOrder from '../sort/byOrder';
import { IntegrationColumnsType } from './types';

/**
 * Helper function to display the columns associated with what integration
 * a content has enabled.  Mostly used for display grid or tables of
 * interaction data on this content.
 */
export default function getContentIntegrationColumns(
  content: {
    integration?: {
      integration: {
        name: IntegrationProviderEnum;
      };
    };
  },
  /** this is not used yet, but may be used by custom formatters in the future */
  _opts?: {
    locale?: string;
    timeZone?: string;
  }
): IntegrationColumnsType {
  const integrationProvider = content?.integration?.integration?.name || null;

  let integrationColumns: [string, PropertyType][] = [];

  if (integrationProvider) {
    const integrationDefinition = getIntegrationDefinition(integrationProvider);

    if (integrationDefinition.interactionData) {
      integrationColumns = Object.entries(integrationDefinition.interactionData)
        .filter(([, property]) => property.forDisplay !== false)
        .sort(byOrder);
    }
  }

  return {
    integrationProvider,
    integrationColumns,
  };
}
