import { FeatureDefinition } from '../../../types/FeatureDefinition';
import { FeatureNameEnum } from 'constants-content';

// social options will have more in the future, like Like, Pin, etc.

const SocialOptions: FeatureDefinition = {
  superUserOnly: false,
  name: FeatureNameEnum.SocialOptions,
  requiresInteraction: false,
  friendlyName: 'web.content.features.socialOptions.friendlyName',
  description: 'web.content.features.socialOptions.description',
  properties: {
    showShare: {
      friendlyName:
        'web.content.features.socialOptions.properties.showShare.friendlyName',
      description:
        'web.content.features.socialOptions.properties.showShare.description',
      type: 'Boolean',
      editable: true,
      default: false,
    },
  },
};

export default SocialOptions;
