import React from 'react';

import { Route } from 'react-router-dom';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';

import { routes } from 'lane-shared/domains/userManagement/config';
import { getDisplayName, hasPermission } from 'lane-shared/helpers';

import { isChannelForCRE } from 'lane-shared/helpers/channel';
import { OFFICE_TYPES, ActiveChannelTypeEnum } from 'constants-channel';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { PERMISSION_KEYS } from 'constants-permissions';
import { SIDE_BAR_LINKS_ORDER } from 'lane-shared/helpers/constants/sidebarLinksOrder';
import { useFlag } from 'lane-shared/hooks';
import i18n from 'localization';
import { FeatureFlag } from 'constants-flags';
import { UserType } from 'lane-shared/types/User';

import { UserManagementUsersPage as Users } from '../../../pages/portal/admin/channel/users';
import { InviteNewUser } from '../../../pages/portal/admin/channel/users/view/InviteNewUser';
import {
  PermissionGroupsList,
  PermissionGroupCreate,
  PermissionGroupEdit,
  PermissionGroupView,
} from '../pages/permission-groups';
import { SidebarLinkType } from 'hooks/useChannelSidebarLinks';

import { TeamBulkInvite } from 'pages/portal/admin/channel/teamBulkInvite';
import { UserBulkAdd } from 'pages/portal/admin/channel/teamsBulkAdd';
import { Channel } from 'packages/lane-shared/types/ChannelType';

enum LinksOrder {
  Users,
  PermissionGroups,
  Teams,
}

const hasAnyPermission = (
  user: UserType,
  permissions: string[],
  channelId: string
) => user.isSuperUser || hasPermission(user.roles, permissions, channelId);

const canManageCompanyChannel = (
  channelType: ActiveChannelTypeEnum,
  specialPermissions: string[]
) =>
  OFFICE_TYPES.includes(channelType) &&
  specialPermissions.includes(
    PERMISSION_KEYS.PERMISSION_PROPERTY_MANAGE_COMPANY
  );

enum UserManagementPageName {
  Users = 'users',
  UsersInvite = 'usersInvite',
  UsersAdd = 'usersAdd',
  PermissionGroups = 'permissionGroups',
  PermissionGroupsView = 'permissionGroupsView',
  PermissionGroupsCreate = 'permissionGroupsCreate',
  PermissionGroupsEdit = 'permissionGroupsEdit',
  Teams = 'teams',
  TeamView = 'teamView',
  TeamsEdit = 'teamsEdit',
  TeamsCreate = 'teamsCreate',
}

type AdminChannelType = UseChannelForAdminQueryResponse['channel'];

type UserManagementPage = {
  key: UserManagementPageName;
  name: () => string;
  order: LinksOrder;
  Page: React.FC<{ channel: any } & { [key: string]: unknown }>; // TODO: ChannelType
  path: string;
  headerMessage?: string;
  additionalProps?: { [key: string]: unknown };
};

export const UsersPageConfig: UserManagementPage = {
  key: UserManagementPageName.Users,
  name: () => i18n.t('web.admin.sidebar.users.title'),
  order: LinksOrder.Users,
  Page: Users,
  path: routes.UsersList,
};

const UsersInvitePage: UserManagementPage = {
  key: UserManagementPageName.UsersInvite,
  name: () => i18n.t('web.admin.sidebar.permissionGroups.title'),
  order: LinksOrder.PermissionGroups,
  Page: InviteNewUser,
  path: routes.UsersInvite,
};

const BulkInviteUsersPage: UserManagementPage = {
  key: UserManagementPageName.UsersInvite,
  name: () => i18n.t('web.admin.sidebar.permissionGroups.title'),
  order: LinksOrder.PermissionGroups,
  Page: TeamBulkInvite,
  path: routes.UsersInvite,
};

const BulkAddUsersPage: UserManagementPage = {
  key: UserManagementPageName.UsersAdd,
  name: () => i18n.t('web.admin.sidebar.permissionGroups.title'),
  order: LinksOrder.PermissionGroups,
  Page: UserBulkAdd,
  path: routes.UsersAdd,
};

const PermissionGroupsListPageConfig: UserManagementPage = {
  key: UserManagementPageName.PermissionGroups,
  name: () => i18n.t('web.admin.sidebar.permissionGroups.title'),
  order: LinksOrder.PermissionGroups,
  Page: PermissionGroupsList,
  path: routes.PermissionGroupsList,
};

export const TeamsListPageConfig: UserManagementPage = {
  key: UserManagementPageName.Teams,
  name: () => i18n.t('web.admin.sidebar.teams.title'),
  order: LinksOrder.Teams,
  Page: PermissionGroupsList,
  path: routes.TeamsList,
};

const TeamEditPageConfig: UserManagementPage = {
  key: UserManagementPageName.TeamsEdit,
  name: () => i18n.t('web.admin.sidebar.teams.title'),
  order: LinksOrder.Teams,
  Page: PermissionGroupEdit,
  path: routes.TeamEdit,
};

const PermissionGroupViewPageConfig: UserManagementPage = {
  key: UserManagementPageName.PermissionGroupsView,
  name: () => i18n.t('web.admin.sidebar.permissionGroups.title'),
  order: LinksOrder.PermissionGroups,
  Page: PermissionGroupView,
  path: routes.PermissionGroupView,
};

const TeamViewPageConfig: UserManagementPage = {
  key: UserManagementPageName.TeamView,
  name: () => i18n.t('web.admin.sidebar.teams.title'),
  order: LinksOrder.PermissionGroups,
  Page: PermissionGroupView,
  path: routes.TeamView,
};

const TeamCreatePageConfig: UserManagementPage = {
  key: UserManagementPageName.TeamsCreate,
  name: () => i18n.t('web.admin.sidebar.permissionGroups.title'),
  order: LinksOrder.Teams,
  Page: PermissionGroupCreate,
  path: routes.TeamCreate,
};

const PermissionGroupCreatePageConfig: UserManagementPage = {
  key: UserManagementPageName.PermissionGroupsCreate,
  name: () => i18n.t('web.admin.sidebar.permissionGroups.title'),
  order: LinksOrder.PermissionGroups,
  Page: PermissionGroupCreate,
  path: routes.PermissionGroupCreate,
};

const PermissionGroupEditPageConfig: UserManagementPage = {
  key: UserManagementPageName.PermissionGroupsEdit,
  name: () => i18n.t('web.admin.sidebar.permissionGroups.title'),
  order: LinksOrder.PermissionGroups,
  Page: PermissionGroupEdit,
  path: routes.PermissionGroupEdit,
};

export const UserManagementPagesRoutes = ({
  urlPath,
  channel,
  user,
  specialPermissions,
}: {
  urlPath: string;
  channel: AdminChannelType;
  user: UserType;
  specialPermissions?: string[];
}): JSX.Element | null => {
  const isIntuitiveUserManagementEnabled = useFlag(
    FeatureFlag.IntuitiveUserManagement,
    false
  );

  if (!isIntuitiveUserManagementEnabled) return null;

  if (!channel) return null;

  const entries: [string, UserManagementPage][] = [];

  const channelIsOfficeExperience = isChannelForCRE(channel?.experienceType);

  if (
    hasAnyPermission(
      user,
      [PERMISSION_KEYS.PERMISSION_ADMIN, PERMISSION_KEYS.PERMISSION_USERS_READ],
      channel._id
    ) ||
    (specialPermissions &&
      canManageCompanyChannel(channel.type, specialPermissions))
  ) {
    entries.push([UsersPageConfig.key, UsersPageConfig]);
    entries.push([
      UsersInvitePage.key,
      channelIsOfficeExperience ? BulkInviteUsersPage : UsersInvitePage,
    ]);
    entries.push([BulkAddUsersPage.key, BulkAddUsersPage]);

    entries.push([
      PermissionGroupsListPageConfig.key,
      PermissionGroupsListPageConfig,
    ]);
    entries.push([
      PermissionGroupViewPageConfig.key,
      PermissionGroupViewPageConfig,
    ]);
    entries.push([TeamViewPageConfig.key, TeamViewPageConfig]);
    entries.push([
      PermissionGroupCreatePageConfig.key,
      PermissionGroupCreatePageConfig,
    ]);
    entries.push([
      PermissionGroupEditPageConfig.key,
      PermissionGroupEditPageConfig,
    ]);
    entries.push([TeamsListPageConfig.key, TeamsListPageConfig]);
    entries.push([TeamEditPageConfig.key, TeamEditPageConfig]);
    entries.push([TeamCreatePageConfig.key, TeamCreatePageConfig]);
  }

  return (
    <>
      {entries.map(([key, { Page, path, additionalProps }]) => {
        return (
          <Route key={key} exact path={`${urlPath}/${path}`}>
            <Page channel={channel} {...additionalProps} />
          </Route>
        );
      })}
    </>
  );
};

export const userManagementSidebarConfig = (
  url: string,
  channel: Channel,
  user: UserType,
  specialPermissions?: string[]
) => {
  const links: SidebarLinkType[] = [];

  const channelIsOfficeExperience = isChannelForCRE(channel?.experienceType);

  if (
    hasAnyPermission(
      user,
      [PERMISSION_KEYS.PERMISSION_ADMIN, PERMISSION_KEYS.PERMISSION_USERS_READ],
      channel._id
    ) ||
    (specialPermissions &&
      canManageCompanyChannel(channel.type, specialPermissions))
  ) {
    links.push({
      exact: true,
      route: `${url}/${UsersPageConfig.path}`,
      name: i18n.t('web.admin.sidebar.users.title'),
      order: LinksOrder.Users,
      headerMessage: i18n.t('web.admin.navigation.header.users', {
        displayName: getDisplayName(channel),
      }),
    });

    if (channelIsOfficeExperience) {
      links.push({
        exact: true,
        route: `${url}/${TeamsListPageConfig.path}`,
        name: i18n.t('web.admin.sidebar.teams.title'),
        order: LinksOrder.Teams,
        headerMessage: i18n.t('web.admin.navigation.header.teams', {
          displayName: getDisplayName(channel),
        }),
      });
    } else
      links.push({
        exact: true,
        route: `${url}/${PermissionGroupsListPageConfig.path}`,
        name: i18n.t('web.admin.sidebar.permissionGroups.title'),
        order: LinksOrder.PermissionGroups,
        headerMessage: i18n.t('web.admin.navigation.header.permissionGroups', {
          displayName: getDisplayName(channel),
        }),
      });
  }

  return {
    name: i18n.t('web.admin.sidebar.userManagement.title'),
    icon: 'user',
    iconSet: ICON_SET_FONTAWESOME,
    links,
    isCategory: true,
    order: SIDE_BAR_LINKS_ORDER.USER_MANAGEMENT,
  };
};
