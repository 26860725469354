import React from 'react';

import { colors } from 'constants-colors';

type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export default function ActivateLogo({
  className,
  color = colors.primary90,
  size = 87,
}: Props) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width={size}
      viewBox="0 0 456.9 403.8"
      style={{ background: 'new 0 0 456.9 403.8' }}
    >
      <style type="text/css">{`.st0{fill:${color};`}</style>
      <g clipPath="url(#clip1)">
        <g>
          <g>
            <polygon
              className="st0"
              points="228.6,87.1 272.9,116.5 305.7,94.8 228.6,43.5 228.5,43.6 151.3,94.9 184.1,116.6 228.5,87.1 			"
            />
            <polygon
              className="st0"
              points="228.5,180.8 94.6,96.8 94.6,152.9 228.5,236.8 228.5,236.8 362.4,152.8 362.4,96.8 			"
            />
          </g>
        </g>
        <g>
          <path
            className="st0"
            d="M80.3,340.8H51l-6.5,17.7H29.7l29.2-76h13.5l29.3,76H86.8L80.3,340.8z M75.9,328.6l-10.2-27.8l-10.2,27.8
			H75.9z"
          />
          <path
            className="st0"
            d="M102.6,331.1c0-15.6,10.7-28.2,27.8-28.2c13.8,0,25,8.3,26.2,20.8h-13.2c-1-5.4-6.3-9.3-12.6-9.3
			c-9.2,0-14.2,7.2-14.2,16.7c0,9.6,5.1,16.7,14.4,16.7c6.3,0,11.2-3.6,12.5-9H157c-1.7,11.9-12.4,20.5-26.5,20.5
			C113.4,359.4,102.6,346.8,102.6,331.1z"
          />
          <path
            className="st0"
            d="M183.5,287.3v16.5h15.2V315h-15.2v23.9c0,5.5,2.9,8.4,7.9,8.4h7.3v11.3h-8.8c-11.9,0-19.8-6.9-19.8-19.1
			v-24.4h-10.7v-11.2h10.7v-16.5L183.5,287.3L183.5,287.3z"
          />
          <path
            className="st0"
            d="M220,280.3v14.9h-14.3v-14.9H220z M206.1,303.8h13.4v54.7h-13.4V303.8z"
          />
          <path
            className="st0"
            d="M225.2,303.8h13.6l12.7,37.8l12.6-37.8h13.7l-20.1,54.7h-12.5L225.2,303.8z"
          />
          <path
            className="st0"
            d="M327.4,323.3v35.2h-13.1v-8.1c-2.3,5.4-8.6,9-16.4,9c-10.9,0-18.2-6.9-18.2-16.4c0-10.6,8.3-17.3,20.5-17.3
			h10.1c2.7,0,4-1.5,4-3.7c0-5.3-4.1-9.1-11.2-9.1s-11.3,4.5-11.5,9.3h-11.9c0.7-10.9,9.9-19.3,24.1-19.3
			C317.6,302.9,327.4,311,327.4,323.3z M314.2,335.4v-0.9H302c-5.6,0-9.4,3.1-9.4,7.9c0,4.2,3.5,7.1,8.5,7.1
			C309.4,349.5,314.2,343.6,314.2,335.4z"
          />
          <path
            className="st0"
            d="M355.1,287.3v16.5h15.2V315h-15.2v23.9c0,5.5,2.9,8.4,7.9,8.4h7.3v11.3h-8.8c-11.9,0-19.8-6.9-19.8-19.1
			v-24.4H331v-11.2h10.7v-16.5L355.1,287.3L355.1,287.3z"
          />
          <path
            className="st0"
            d="M427.3,340.3c-2.1,11.6-12.3,19.1-25.7,19.1c-17.9,0-27.9-14.1-27.9-28.4c0-14.4,8.9-28,26.8-28
			c18.3,0,26.6,13.4,26.6,26.4c0,1.8-0.1,3.5-0.2,4.6h-40.4c1,8.8,6.6,14.4,15.1,14.4c6.8,0,11.3-2.8,12.7-8h13V340.3z M386.8,325.1
			h26.9c-0.5-6.9-5.3-12.2-13.2-12.2C393,312.9,388.2,316.8,386.8,325.1z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={87} height={25} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
