import * as yup from 'yup';

import { FeatureNameEnum } from 'constants-content';

import { createShapeFromProperties } from '../properties';
import Features from '../renderers/v5/features';
import { SupportedSchema } from '../types/baseTypes/TypeBase';

const featureNames: FeatureNameEnum[] = [];
const featureValidation: Record<string, Record<string, SupportedSchema>> = {};

Object.entries(Features).map(([, feature]) => {
  featureNames.push(feature.name);
  featureValidation[feature.name] = createShapeFromProperties(
    feature.properties
  );
});

export default yup
  .object()
  .shape({
    __typename: yup.string().strip(true),
    name: yup.string().required().oneOf(featureNames),
  })
  .test(
    'valid-feature',
    '${path} does not contain valid data for Feature',
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    value => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      featureValidation[value.name].validateSync(value);
    }
  );
