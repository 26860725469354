// Permits importing from modules outside enforced boundaries
import React from 'react';

import { i18n } from 'lane-shared';

import { routes } from 'lane-shared/domains/hardwareManagement/config';
import { Channel } from 'packages/lane-shared/types/ChannelType';

import { HardwareAccessLogsPage } from '../pages/access-logs';
import { HardwareAddPage } from '../pages/devices/tabs/hardware/hardware-add';
import { HardwareDetailPage } from '../pages/devices/tabs/hardware/hardware-detail';
import { RelayDetailPage } from '../pages/devices/tabs/relay/relay-detail';
import { RelayListPage } from '../pages/devices/tabs/relay/relay-list';
import { DevicesPage } from '../pages/devices';
import {
  HardwareAddPinCodes,
  HardwarePinCodes,
  HardwareDetailPinCodes,
} from '../pages/pin-codes';

export enum HardwareLinkOrder {
  HardwareDevicesPage,
  HardwareList,
  HardwareAccessLogs,
  HardwareDetails,
  HardwareAdd,
  HardwarePinCodes,
  HardwareAddPinCodes,
  HardwareDetailPinCodes,
}

export enum RelayLinkOrder {
  RelayList,
  RelayDetails,
}

export type HardwareManagementPage = {
  key: string;
  name: () => string;
  headerMessage: () => string;
  order: HardwareLinkOrder | RelayLinkOrder;
  Page: React.FC<{
    channel: Channel;
    hasAnyPermission: (permissions: string[]) => Boolean;
  }>;
  path: string;
};

export const HardwareDevicesPageConfig: HardwareManagementPage = {
  key: 'devicesTabs',
  name: () => i18n.t('web.admin.hardwareManagement.navigation.hardwareDevices'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.headerMessage'),
  order: HardwareLinkOrder.HardwareDevicesPage,
  Page: DevicesPage,
  path: routes.hardwareDevices,
};

export const HardwareAccessLogsPageConfig: HardwareManagementPage = {
  key: 'hardwareAccessLogs',
  name: () => i18n.t('web.admin.hardwareManagement.navigation.accessLogs'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.headerMessage'),
  order: HardwareLinkOrder.HardwareAccessLogs,
  Page: HardwareAccessLogsPage,
  path: routes.hardwareAccessLogs,
};

export const HardwarePinCodesPageConfig: HardwareManagementPage = {
  key: 'hardwarePinCodes',
  name: () => i18n.t('web.admin.hardwareManagement.navigation.pinCodes'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.headerMessage'),
  order: HardwareLinkOrder.HardwarePinCodes,
  Page: HardwarePinCodes,
  path: routes.hardwarePinCodes,
};

export const HardwareAddPinCodesPageConfig: HardwareManagementPage = {
  key: 'hardwareAddPinCodes',
  name: () => i18n.t('web.admin.hardwareManagement.navigation.pinCodesAdd'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.pinCodes.headerMessage'),
  order: HardwareLinkOrder.HardwareAddPinCodes,
  Page: HardwareAddPinCodes,
  path: routes.hardwareAddPinCodes,
};

export const HardwareDetailsPageConfig: HardwareManagementPage = {
  key: 'hardwareDetails',
  name: () => i18n.t('web.admin.hardwareManagement.navigation.hardwareDetails'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.headerMessage'),
  order: HardwareLinkOrder.HardwareDetails,
  Page: HardwareDetailPage,
  path: routes.hardwareDetails,
};

export const HardwareAddPageConfig: HardwareManagementPage = {
  key: 'hardwareAdd',
  name: () => i18n.t('web.admin.hardwareManagement.navigation.hardwareAdd'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.headerMessage'),
  order: HardwareLinkOrder.HardwareAdd,
  Page: HardwareAddPage,
  path: routes.hardwareAdd,
};

export const RelayDetailsPageConfig: HardwareManagementPage = {
  key: 'relayDetails',
  name: () => i18n.t('web.admin.hardwareManagement.navigation.hardwareDetails'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.headerMessage'),
  order: RelayLinkOrder.RelayDetails,
  Page: RelayDetailPage,
  path: routes.relayDetails,
};

export const RelayListPageConfig: HardwareManagementPage = {
  key: 'relaylist',
  name: () => i18n.t('web.admin.hardware.management.navigation.relayList'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.headerMessage'),
  order: RelayLinkOrder.RelayList,
  Page: RelayListPage,
  path: routes.relayList,
};

export const HardwareDetailPinCodesPageConfig: HardwareManagementPage = {
  key: 'hardwareDetailPinCodes',
  name: () => i18n.t('web.admin.hardwareManagement.navigation.pinCodesDetail'),
  headerMessage: () =>
    i18n.t('web.admin.hardwareManagement.navigation.pinCodes.headerMessage'),
  order: HardwareLinkOrder.HardwareDetailPinCodes,
  Page: HardwareDetailPinCodes,
  path: routes.hardwareDetailPinCodes,
};
