import { useMemo } from 'react';

import { explodeFeatures } from 'lane-shared/helpers/features';
import { useFlag } from 'lane-shared/hooks';
import { CHANNEL_TYPE_KEYS } from 'constants-channel';
import { FeatureFlag } from 'constants-flags';
import { UserType } from 'lane-shared/types/User';
import { ContentType } from 'lane-shared/types/content/Content';
import {
  RequirementsEnum,
  RequirementsFeatureProperties,
} from 'lane-shared/types/features/RequirementsFeatureProperties';

import { UserLoginStatusEnum } from 'constants-user';
import useMyInteractionsOnContentsExist from '../useMyInteractionsOnContentsExist';

type HookParameters = {
  content?: ContentType | null;
  user?: UserType | null;
  isEditMode?: boolean;
  isAdminView?: boolean;
  isVerificationEmailSent?: boolean;
};

type HasMetRequirements = {
  areRequirementsLoaded: boolean;
  allRequirementsMet: boolean;
  fullNameRequirementMet: boolean;
  phoneNumberRequirementMet: boolean;
  profilePictureRequirementMet: boolean;
  companyNameRequirementMet: boolean;
  verifiedEmailRequirementMet: boolean;
  allContentInteractionRequirementsMet: boolean;
  contentInteractionsRequirementMet: Record<string, boolean>;
  refetchInteractionRequirementsMet: () => void;
  checkAllOtherRequirementsAreMet: (requirement: RequirementsEnum) => boolean;
  shouldShowRequirementsFlow: boolean;
  shouldDisableContentSubmission: boolean;
};

function hasMetFullNameRequirement(
  requirementsFeature: RequirementsFeatureProperties | undefined,
  user: HookParameters['user'],
  isEditMode?: HookParameters['isEditMode']
): boolean {
  if (isEditMode) {
    return !requirementsFeature?.fullNameRequired;
  }

  if (!requirementsFeature?.fullNameRequired) {
    return true;
  }

  return Boolean(user?.profile?.name || '');
}

function hasMetPhonenumberRequirement(
  requirementsFeature: RequirementsFeatureProperties | undefined,
  user: HookParameters['user'],
  isEditMode?: HookParameters['isEditMode']
): boolean {
  if (isEditMode) {
    return !requirementsFeature?.phoneNumberRequired;
  }

  if (!requirementsFeature?.phoneNumberRequired) {
    return true;
  }

  return Boolean(user?.profile?.phone || '');
}

function hasMetProfilepictureRequirement(
  requirementsFeature: RequirementsFeatureProperties | undefined,
  user: HookParameters['user'],
  isEditMode?: HookParameters['isEditMode']
): boolean {
  if (isEditMode) {
    return !requirementsFeature?.profilePictureRequired;
  }

  if (!requirementsFeature?.profilePictureRequired) {
    return true;
  }

  return Boolean(user?.profile?.image || '');
}

function hasMetCompanyNameRequirement(
  requirementsFeature: RequirementsFeatureProperties | undefined,
  user: HookParameters['user'],
  isEditMode?: HookParameters['isEditMode']
): boolean {
  if (isEditMode) {
    return !requirementsFeature?.companyNameRequired;
  }

  if (!requirementsFeature?.companyNameRequired) {
    return true;
  }

  return Boolean(
    user?.roles?.some(role =>
      [
        CHANNEL_TYPE_KEYS.Company,
        CHANNEL_TYPE_KEYS.Entertainment,
        CHANNEL_TYPE_KEYS.Restaurant,
        CHANNEL_TYPE_KEYS.Retail,
        CHANNEL_TYPE_KEYS.Service,
        CHANNEL_TYPE_KEYS.Professional,
        CHANNEL_TYPE_KEYS.Charity,
      ].includes(role.groupRole.channel?.type as any)
    )
  );
}

function hasMetVerifiedEmailRequirement(
  requirementsFeature: RequirementsFeatureProperties | undefined,
  user: HookParameters['user'],
  isEditMode?: HookParameters['isEditMode']
): boolean {
  if (isEditMode) {
    return !requirementsFeature?.verifiedEmailRequired;
  }

  if (!requirementsFeature?.verifiedEmailRequired) {
    return true;
  }

  return Boolean(
    user?.logins?.find(
      ({ isPrimary, status }) =>
        isPrimary && status === UserLoginStatusEnum.Verified
    )
  );
}

export default function useHasMetRequirements({
  content,
  user,
  isVerificationEmailSent,
  isEditMode,
  isAdminView,
}: HookParameters): HasMetRequirements {
  const { requirementsFeature } = useMemo(
    () => explodeFeatures(content?.features || []),
    [content?.features]
  );
  const { settings: channelSettings } = content?.channel || {};

  const effectiveRequirements: RequirementsFeatureProperties | undefined =
    channelSettings?.effectiveParentRequirements ||
    channelSettings?.requirements ||
    requirementsFeature;
  const {
    result: interactionRequirements,
    refetch,
    loading: isLoadingInteractions,
  } = useMyInteractionsOnContentsExist({
    contentIds:
      effectiveRequirements?.contentInteractionRequired
        ?.filter(item => Boolean(item.content))
        ?.map(({ content }) => content._id) || [],
  });
  const isVerifiedEmailRequirementEnabled = useFlag(
    FeatureFlag.EmailVerificationRequirement,
    false
  );

  const fullNameRequirementMet = hasMetFullNameRequirement(
    effectiveRequirements,
    user,
    isEditMode
  );
  const phoneNumberRequirementMet = hasMetPhonenumberRequirement(
    effectiveRequirements,
    user,
    isEditMode
  );
  const profilePictureRequirementMet = hasMetProfilepictureRequirement(
    effectiveRequirements,
    user,
    isEditMode
  );
  const companyNameRequirementMet = hasMetCompanyNameRequirement(
    effectiveRequirements,
    user,
    isEditMode
  );
  const verifiedEmailRequirementMet = isVerifiedEmailRequirementEnabled
    ? hasMetVerifiedEmailRequirement(effectiveRequirements, user, isEditMode)
    : true;

  const allContentInteractionRequirementsMet = isEditMode
    ? !effectiveRequirements?.contentInteractionRequired?.length
    : !isLoadingInteractions &&
      !Object.values(interactionRequirements).includes(false);

  const allRequirementsMet =
    fullNameRequirementMet &&
    phoneNumberRequirementMet &&
    profilePictureRequirementMet &&
    companyNameRequirementMet &&
    verifiedEmailRequirementMet &&
    allContentInteractionRequirementsMet;

  const areNonInteractiveRequirementsEnabled = useFlag(
    FeatureFlag.NonInteractiveRequirement,
    false
  );

  const isExternalUrl = Boolean(content?.externalUrl?.url);

  const shouldShowRequirementsFlow = Boolean(
    areNonInteractiveRequirementsEnabled &&
      requirementsFeature &&
      (isExternalUrl || !content?.isInteractive) &&
      !isAdminView &&
      !allRequirementsMet
  );

  /**
   *
   * @param requirement requirement to exclude
   * @returns boolean
   */
  function checkAllOtherRequirementsAreMet(
    requirement: RequirementsEnum
  ): boolean {
    // function to check if other requirements except the one passed in the argument are met.
    // it's useful for email verification / content interaction submit button so it can be enabled
    // for the user to click and verify their email once other requirements are met.

    const requirements: { [key in RequirementsEnum]: boolean } = {
      [RequirementsEnum.FullName]: fullNameRequirementMet,
      [RequirementsEnum.PhoneNumber]: phoneNumberRequirementMet,
      [RequirementsEnum.ProfilePicture]: profilePictureRequirementMet,
      [RequirementsEnum.CompanyName]: companyNameRequirementMet,
      [RequirementsEnum.VerifiedEmail]: verifiedEmailRequirementMet,
      [RequirementsEnum.ContentInteraction]:
        allContentInteractionRequirementsMet,
    };

    requirements[requirement] = true;

    return (
      Object.values(requirements).find(requirement => !requirement) ===
      undefined
    );
  }

  const shouldDisableContentSubmission = useMemo(() => {
    if (verifiedEmailRequirementMet) {
      return !allRequirementsMet;
    }

    return (
      !checkAllOtherRequirementsAreMet(RequirementsEnum.VerifiedEmail) ||
      !isVerificationEmailSent
    );
  }, [
    fullNameRequirementMet,
    phoneNumberRequirementMet,
    profilePictureRequirementMet,
    companyNameRequirementMet,
    verifiedEmailRequirementMet,
    allContentInteractionRequirementsMet,
    allRequirementsMet,
    isVerificationEmailSent,
  ]);

  return {
    areRequirementsLoaded: !isLoadingInteractions,
    allRequirementsMet,
    fullNameRequirementMet,
    phoneNumberRequirementMet,
    profilePictureRequirementMet,
    companyNameRequirementMet,
    verifiedEmailRequirementMet,
    allContentInteractionRequirementsMet,
    contentInteractionsRequirementMet: isEditMode
      ? {}
      : interactionRequirements,
    refetchInteractionRequirementsMet: refetch,
    checkAllOtherRequirementsAreMet,
    shouldShowRequirementsFlow,
    shouldDisableContentSubmission,
  };
}
