import { useQuery, NetworkStatus } from '@apollo/client';
import { useMemo } from 'react';
import { onboardingCompanies } from '../graphql/query';

const DEFAULT_PER_PAGE = 10;

type OnboardingCompanyQueryParams = {
  query?: string;
  presumedEmailDomain?: string;
  buildingId?: string | null;
  pageSize?: number;
};

type Edge = {
  cursor: string;
  node: {
    id: string;
    name: string;
    thumbnailUrl: string | null;
    matchesEmailDomain: boolean;
  };
};

export function useOnboardingCompanyQuery({
  query = '',
  presumedEmailDomain,
  buildingId,
  pageSize = DEFAULT_PER_PAGE,
}: OnboardingCompanyQueryParams) {
  const variables = useMemo(
    () => ({
      onboardingChannelFilter: {
        query,
        presumedEmailDomain,
        buildingId,
      },
      pageInfo: {
        first: pageSize,
        after: null,
      },
    }),
    [query, presumedEmailDomain, buildingId, pageSize]
  );

  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(
    onboardingCompanies,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      skip: !query && !presumedEmailDomain && !buildingId,
    }
  );

  const handleFetchMore = () => {
    if (data?.onboardingCompanies.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          ...variables,
          pageInfo: {
            first: pageSize,
            after: data.onboardingCompanies.pageInfo.endCursor,
          },
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const newEdges = fetchMoreResult.onboardingCompanies.edges;
          const existingEdges = prevResult.onboardingCompanies.edges;

          const combinedEdges = [
            ...existingEdges,
            ...newEdges.filter(
              (newEdge: Edge) =>
                !existingEdges.some(
                  (existingEdge: Edge) =>
                    existingEdge.node.id === newEdge.node.id
                )
            ),
          ];

          return {
            onboardingCompanies: {
              ...fetchMoreResult.onboardingCompanies,
              edges: combinedEdges,
              pageInfo: fetchMoreResult.onboardingCompanies.pageInfo,
            },
          };
        },
      });
    }
  };

  return {
    companies: data?.onboardingCompanies.edges || [],
    error,
    fetchMore: handleFetchMore,
    hasNextPage: data?.onboardingCompanies.pageInfo.hasNextPage || false,
    refetch,
    isInitialLoading: loading && networkStatus === NetworkStatus.loading,
    isFetchingMore: networkStatus === NetworkStatus.fetchMore,
  };
}
