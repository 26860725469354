import { Channel } from '../../../types/ChannelType';
import type { SectionType } from '../../../types/sections/SectionType';
import {
  PresetContentFilter,
  PresetContentFilterFriendlyName,
  PresetContentSort,
  PresetContentSortFriendlyName,
} from 'constants-content';

export const sectionCollectables = (
  section?: Partial<SectionType> & { channel?: Partial<Channel> }
) => {
  if (!section) return;

  return {
    sectionId: section._id || (section as any).id,
    tagsOnSection: section.tagsOnSection,
    tags: section.tags,
    type: section.type,
    filters: friendlyFilters(section.filters),
    sorts: friendlySorts(section.sorts),
    groupBy: section.groupBy,
    channelId: section.channel?._id,
    channelName: section.channel?.name,
  };
};

const friendlyFilters = (filters: PresetContentFilter[] | undefined) => {
  if (!filters) return;

  return filters.map(filter => PresetContentFilterFriendlyName[filter]);
};

const friendlySorts = (sorts: PresetContentSort[] | undefined) => {
  if (!sorts) return;

  return sorts.map(sort => PresetContentSortFriendlyName[sort]);
};
