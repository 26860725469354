import { PropertyType } from 'lane-shared/types/properties/Property';

export interface IWorkOrder {
  serviceRequestId: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  description: string;
  category: string;
  issue: string;
  location: string;
  floor: string;
  company: string;
  suite: string;
  extRefId: string;
  assignee: string;
  status: string;
  customQuestions: customQuestion[];
}

export interface customQuestion {
  Question: string;
  Answer: string;
}

export interface WorkOrderModuleCategoriesSettings {
  firendlyName: string;
  issues: string[];
  assigneeGroups?: string[];
  assignee?: string;
}

export interface WorkOrderModuleSettings {
  categories: WorkOrderModuleCategoriesSettings[];
  locations: string[];
  customQuestions?: PropertyType[];
  _version?: Number;
}

export enum WorkOrderType {
  ServiceRequest = 'ServiceRequest',
  Task = 'Task',
}
