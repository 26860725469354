import { DateTime } from 'luxon';

import { UserGroupRoleType } from '../../types/UserGroupRole';
import { ContentCategoryEnum } from 'constants-content';
import { PaymentAccountType } from '../../types/payment/PaymentAccount';
import { LONG_TIME } from '../constants/dates';

export default function isPaymentAccountValidClientSide({
  userRoles,
  paymentAccount,
  contentCategory,
  purchasePrice,
  timeZone,
}: {
  userRoles: UserGroupRoleType[];
  paymentAccount: PaymentAccountType;
  contentCategory: ContentCategoryEnum;
  purchasePrice: number;
  timeZone: string;
}) {
  // no rules, this is valid.
  if (!(paymentAccount.rules && paymentAccount.rules.length > 0)) {
    return true;
  }

  // as long as one rule is valid, it's ok.
  return paymentAccount.rules.some(rule => {
    if (
      !rule.allRoles &&
      !userRoles.some(userGroupRole =>
        rule.groupRole?.some(
          ruleGroupRole => ruleGroupRole._id === userGroupRole.groupRole?._id
        )
      )
    ) {
      // this user doesn't have the role this rule applies to, so this rule
      // effectively is then valid
      return true;
    }

    if (
      !rule.allContent &&
      !rule.contentCategories?.includes(contentCategory)
    ) {
      return false;
    }

    if (
      !rule.noSinglePurchaseLimit &&
      purchasePrice > rule.singlePurchaseLimit
    ) {
      return false;
    }

    // we can only check purchaseLimit server side. so skip checking that
    // on client.

    if (!rule.anyTimes) {
      const start = DateTime.fromFormat(rule.startTime, LONG_TIME, {
        zone: timeZone,
      });

      const end = DateTime.fromFormat(rule.startTime, LONG_TIME, {
        zone: timeZone,
      });

      const now = DateTime.local().setZone(timeZone);

      if (!(start <= now && end >= now)) {
        return false;
      }
    }

    // if we got this far, rule is valid.
    return true;
  });
}
