import { useState, useMemo, useCallback } from 'react';
import { BulkAction } from 'design-system-web';
import { useUserDataContext } from 'lane-shared/hooks';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_KEYS } from 'constants-permissions';
import type { BulkRemoveFromTeamModalProps } from '../view/modals/BulkRemoveFromTeamModal';

type Props = {
  channelId: string;
  teamName: string;
  selectedUsers: {
    id: string;
    userGroupRoleId: string;
  }[];
  onAction?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  onSuccess?: () => void;
};

export const useBulkRemoveFromTeamAction = ({
  channelId,
  teamName,
  selectedUsers,
  onAction,
  onCancel,
  onConfirm,
  onSuccess,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { user: loggedInUser } = useUserDataContext();

  const canPerformBulkAction: boolean =
    !!loggedInUser &&
    (loggedInUser.isSuperUser ||
      hasPermission(
        loggedInUser.roles,
        [
          PERMISSION_KEYS.PERMISSION_ADMIN,
          PERMISSION_KEYS.PERMISSION_USERS_DELETE,
        ],
        channelId
      ));

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleAction = useCallback(() => {
    setIsModalOpen(true);

    if (onAction) {
      onAction();
    }
  }, [onAction]);

  const action: BulkAction = useMemo(
    () => ({
      label:
        'web.admin.channel.permissionGroups.detail.bulkActions.removeFromTeam',
      onClick: handleAction,
    }),
    [handleAction]
  );

  const modalProps: BulkRemoveFromTeamModalProps = useMemo(
    () => ({
      teamName,
      selectedUsers,
      isOpen: isModalOpen,
      onClose: closeModal,
      onCancel,
      onConfirm,
      onSuccess,
    }),
    [
      teamName,
      selectedUsers,
      isModalOpen,
      closeModal,
      onCancel,
      onConfirm,
      onSuccess,
    ]
  );

  return useMemo(
    () => ({
      action,
      modalProps,
      canPerformAction: canPerformBulkAction,
    }),
    [action, modalProps, canPerformBulkAction]
  );
};
