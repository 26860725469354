import { UserLoginTypeEnum } from 'constants-user';
import getPrimaryLogin from './getPrimaryLogin';
import { UserLoginQueryFieldsType } from 'lane-shared/types/UserLogin';

export default function getPrimaryPhone(
  logins: UserLoginQueryFieldsType[]
): string | null {
  const login = getPrimaryLogin(logins, UserLoginTypeEnum.Phone);

  return login?.key || null;
}
