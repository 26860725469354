import { getClient } from '../apollo';
import { useSignUpContext } from '../contexts/SignUpContext';
import { SignUpContextType } from '../contexts/SignUpContext/SignUpContextType';
import {
  LoginNotExistsError,
  ContinuedAuthForbiddenError,
} from 'activate-errors';
import { getChannel } from '../graphql/query';
import { emitter, isThisError } from '../helpers';
import { OAUTH_PROVIDERS, UserLoginProviderEnum } from 'constants-user';
import { OAuthConfigType } from '../types/OAuthConfigType';
import { OAuthIdentity } from '../types/oauth';
import useSignInOAuth from './useSignInOAuth';

const NOT_REQUIRED = 'NOT_REQUIRED';

export type OAuthGetIdentityResponse = {
  // Display name
  preferredName: string;
  // Email address
  userPrincipalName: string;
};

type UseOAuthSignUpProps = {
  oAuthConfig?: OAuthConfigType;
  onResume?: () => void;
  onSuccess?: (signInData?: any) => void;
  onError?: (err: Error | null) => void;
  isEnterprise?: boolean;
  inviteId?: string;
};

type DoSignUpProps<Q, R, S> = {
  oAuthIdentityProps: Q;
  oAuthAccessTokenProps: R;
  oAuthGetIdentityProps: S;
  legacyOAuthProvider: LEGACY_OAUTH_PROVIDERS;
  loginProvider: UserLoginProviderEnum;
};

type UseOAuthSignUpResponse<Q, R, S> = {
  doSignUp: (props: DoSignUpProps<Q, R, S>) => Promise<void>;
};

type OAUTH_PROVIDER_KEYS = keyof typeof OAUTH_PROVIDERS;
type LEGACY_OAUTH_PROVIDERS = (typeof OAUTH_PROVIDERS)[OAUTH_PROVIDER_KEYS];

export default function useOAuthSignUp<Q, R, S, T>(
  getOAuthIdentity: (props: Q) => Promise<OAuthIdentity>,
  getAccessToken: (props: R) => Promise<T & { idToken: string }>,
  getSignUpIdentity: (props: S) => Promise<OAuthGetIdentityResponse>,
  {
    oAuthConfig,
    onSuccess = () => undefined,
    onError = () => undefined,
    onResume = () => undefined,
  }: UseOAuthSignUpProps
): UseOAuthSignUpResponse<Q, R, S> {
  const { signInOAuth } = useSignInOAuth();
  const { updateSignUp, inviteId } = useSignUpContext();

  // NOTE: The following method enforces a consistent approach for
  // those progressing through Sign Up / Log In flows regardless
  // of the Provider; and is accomplished by ensuring that
  // information fetch methods act consistently.
  async function doSignUp({
    oAuthIdentityProps,
    oAuthAccessTokenProps,
    oAuthGetIdentityProps,
    legacyOAuthProvider,
    loginProvider,
  }: DoSignUpProps<Q, R, S>): Promise<void> {
    try {
      const oAuthIdentity = await getOAuthIdentity(oAuthIdentityProps);
      const accessToken = await getAccessToken(oAuthAccessTokenProps);

      // NOTE: Flow has returned to our application, execute any required
      // UX actions.
      onResume();

      const { preferredName } = await getSignUpIdentity(oAuthGetIdentityProps);

      const { iss, sub } = oAuthIdentity;

      try {
        const signInResult = await signInOAuth({
          variables: {
            loginKey: `${iss}:${sub}`,
            password: accessToken.idToken,
            loginProvider,
          },
        });

        if (signInResult?.data?.signIn) {
          emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
            authToken: signInResult.data.signIn,
          });
          onSuccess(signInResult?.data?.signIn);
        } else {
          // NOTE: This shouldn't be possible, but apollo
          // suggests that there could be an `undefined`
          // response.
          throw new Error('Sign in could not be completed');
        }
      } catch (err) {
        if (
          // NOTE: This is a real person, we can add the needed information
          // to the SignUpContext and pass them to the next screen!
          // `NOT_REQUIRED` information will be extracted from their
          // OAuth `id_token` within the SignUp mutation.
          // NOTE: Only one such exception is expected to be present.
          isThisError(err, LoginNotExistsError) ||
          err?.graphQLErrors?.some((graphQLError: any) =>
            isThisError(graphQLError, LoginNotExistsError)
          )
        ) {
          const nextSignUp: Partial<SignUpContextType> = {
            name: preferredName,
            email: NOT_REQUIRED,
            password: accessToken.idToken,
            oAuth: {
              _id: oAuthConfig?._id,
              code: `${iss}:${sub}`,
              loginProvider: legacyOAuthProvider,
            },
          };

          // check to see if the error returned by the server includes a
          // hint for what channel we can join.
          const exceptionDetails = err?.graphQLErrors
            .map((gqle: any) => gqle.extensions.exception)
            .find(({ channelHints }: any) => channelHints);

          if (exceptionDetails?.channelHints?.length > 0 && !inviteId) {
            // use the first channel in the array for now.
            const channelId = exceptionDetails.channelHints[0];

            const { data } = await getClient().query({
              query: getChannel,
              variables: { id: channelId },
            });

            // use this as the parent company
            nextSignUp.parentCompany = data?.channel;

            if (nextSignUp.parentCompany) {
              nextSignUp.enterprise = true;
            }
          }

          updateSignUp(nextSignUp);

          // NOTE: `onSuccess` can actually be written to fail as well, in lieu of writing
          // error handling into that method (unless needed) this try/catch will cover for
          // unhandled errors.
          try {
            onSuccess();
          } catch (err) {
            onError(err);
          }
        } else {
          onError(err);
        }
      }
    } catch (err) {
      if (!isThisError(err, ContinuedAuthForbiddenError)) {
        onError(err);
      }
    }
  }

  return { doSignUp }; // Does both Sign Up & Login
}
