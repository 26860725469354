import i18next, { i18n } from 'i18next';
import Papa from 'papaparse';

import { appUrl, routes } from 'lane-shared/config';

import { FeatureInstance } from '../../types/FeatureInstance';
import { ContentTypeEnum } from 'constants-content';
import { IntegrationProviderEnum } from 'constants-integrations';
import {
  PropertiesInterface,
  PropertyType,
} from '../../types/properties/Property';
import propertyValueToCSVField from '../properties/propertyValueToCSVField';
import getContentFeatureColumns from './getContentFeatureColumns';
import getContentIntegrationColumns from './getContentIntegrationColumns';
import getContentStandardColumns from './getContentStandardColumns';
import { StandardColumnType } from './types';
import { Channel } from '../../types/ChannelType';

function getColumnCsvValue(
  key: string | null,
  column: PropertyType,
  i18nParam: i18n,
  featureName?: string
) {
  return `${i18nParam.t(column.friendlyName || column.name || key || '')} ${
    featureName ? ` - ${i18nParam.t(featureName)}` : ''
  }`;
}

export default function exportInteractionsToCSV(
  content: {
    type: ContentTypeEnum;
    channel: Channel;
    data: PropertiesInterface;
    features?: FeatureInstance[];
    integration?: {
      integration: {
        name: IntegrationProviderEnum;
      };
    };
  },
  interactions: any[],
  whitelabelUrl: string,
  opts?: {
    timeZone?: string;
    locale?: string;
  },
  i18n: i18n = i18next
) {
  const standardColumns: StandardColumnType[] = getContentStandardColumns(
    content,
    opts
  );

  const columns: [string, PropertyType][] = Object.entries(content?.data || {});

  // get the columns from features also.
  const featureColumns = getContentFeatureColumns(content, opts);

  const { integrationProvider, integrationColumns } =
    getContentIntegrationColumns(content, opts);

  const parseOptions = {
    fields: standardColumns
      .map(column => column.label)
      .concat(
        columns.map(
          ([key, column]) => column.friendlyName || column.name || key
        )
      )
      .concat(
        integrationColumns.map(([key, column]) =>
          getColumnCsvValue(key, column, i18n)
        )
      )
      .concat(
        featureColumns
          .map(feature =>
            feature.columns.map(column =>
              getColumnCsvValue(null, column, i18n, feature.friendlyName)
            )
          )
          .flat()
      ),
    data: interactions.map(interaction =>
      standardColumns
        .map(column => column.textFormatter!(interaction))
        .concat(
          columns.map(([key, column]) => {
            if (column.type === 'Attachment') {
              return new URL(
                routes.channelAdminInteraction
                  .replace(':id', content?.channel?.slug)
                  .replace(':interactionId', interaction?._id),
                whitelabelUrl || appUrl
              ).toString();
            }

            return propertyValueToCSVField({
              property: column,
              value: interaction.data[key],
              locale: opts?.locale,
              timeZone: opts?.timeZone,
              shouldEscape: false,
            });
          })
        )
        .concat(
          integrationColumns.map(([key, column]) =>
            column.textFormatter
              ? column.textFormatter(interaction.data, column)
              : propertyValueToCSVField({
                  property: column,
                  value: interaction.data[`_${integrationProvider}`][key],
                  locale: opts?.locale,
                  timeZone: opts?.timeZone,
                  shouldEscape: false,
                })
          )
        )
        .concat(
          featureColumns
            .map(feature =>
              feature.columns.map(column => {
                const featureData = interaction.features[feature.type];

                if (column?.textFormatter) {
                  return column.textFormatter(featureData, column);
                }

                return propertyValueToCSVField({
                  property: column,
                  value: featureData?.[column.name!],
                  locale: opts?.locale,
                  timeZone: opts?.timeZone,
                  shouldEscape: false,
                });
              })
            )
            .flat()
        )
    ),
  };

  return Papa.unparse(parseOptions);
}
