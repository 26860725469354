import { createContext } from 'react';

import { InviteContextProvider } from './InviteContextProvider';
import { useInviteContext } from './useInviteContext';
import { ApolloError } from '@apollo/client';
import { Channel } from '../../types/ChannelType';

export type InviteContextType = {
  inviteId?: string;
  email?: string;
  building?: Channel;
  company?: Channel;
  userName?: string;
  loading?: Boolean;
  setInviteId: (inviteId: string) => void;
  claimInvite: (
    inviteId: string,
    channelId: string
  ) => Promise<string | undefined> | undefined;
  clearInvite: () => void;
  claiming?: boolean;
  claimError: Error | null;
  error: ApolloError | undefined;
};

export const InviteContext = createContext<InviteContextType | undefined>(
  undefined
);

export { InviteContextProvider, useInviteContext };
