import { IntegrationProviderEnum } from 'constants-integrations';

export default function isShopifyBurstInteraction(
  interaction:
    | {
        data?: {
          _ShopifyBurst: any;
        };
      }
    | undefined
    | null
): boolean {
  return interaction?.data
    ? `_${IntegrationProviderEnum.ShopifyBurst}` in interaction.data
    : false;
}
